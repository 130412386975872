import { useSelector, useDispatch } from "react-redux";

import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { message } from "antd";
import { TranslateText } from "../../../utils/translation";
import {
  updateAddPartyDropdownVisible,
  updateNewPartytName,
} from "../../../reducer/expenseReducer";
import { createParty } from "../../../reducer/partyReducer";
import { partyObj } from "../../party/utils/utils";

import { ReactComponent as BoxCross } from "../../../assets/icons/boxCross.svg";

export default function AddParty({
  setPartyName,
  inBookingDetails,
  updateAttachPartyValue,
  inMobilePos,
  inAddPayment,
  updateInvoiceWithPartyId,
  setPartyId,
  partyType,
  posStyles,
  updatePayment,
}) {
  const { hotelDetails } = useSelector((store) => store.login);
  const { newPartyName } = useSelector((store) => store.expense);
  const { createPartyLoading } = useSelector((store) => store.party);

  const dispatch = useDispatch();

  const performReset = () => {
    dispatch(updateNewPartytName(""));
    dispatch(updateAddPartyDropdownVisible(false));
  };

  const handleAddParty = async () => {
    if (!newPartyName?.trim()?.length) {
      message.destroy(); // This is used to display only one message at a time
      message.warning("Display Name cannot be empty");
      return;
    }

    let updatedPartyObj = JSON.parse(JSON.stringify(partyObj));
    updatedPartyObj.displayName = newPartyName;
    updatedPartyObj.partyType = partyType;
    updatedPartyObj.hotelId = hotelDetails?.id;

    const res = await dispatch(createParty(updatedPartyObj));

    if (Boolean(res?.payload?.status === 200)) {
      if (posStyles) {
        setPartyName(res?.payload?.data?.displayName);
        setPartyId(res?.payload?.data?.id);
        performReset();
      }

      if (inBookingDetails) {
        dispatch(updateAttachPartyValue(res?.payload?.data?.displayName));
        performReset();
        return;
      }

      if (inMobilePos) {
        updateInvoiceWithPartyId(res?.payload?.data?.id);
        performReset();
        return;
      }

      if (inAddPayment) {
        setPartyName(res?.payload?.data?.displayName);
        performReset();
        return;
      }
      if (posStyles) {
        setPartyId(res?.payload?.data?.id);
        performReset();
        return;
      }
      setPartyName(res?.payload?.data?.displayName);
      performReset();
    }
  };

  return (
    <div
      className={`w-[100%] rounded-[10px] border-[#f2f2f2] border-[0.5px] shadow-dropdown absolute top-[-105%]  ${
        inMobilePos && "w-[94%] top-[-37px]"
      } ${
        inAddPayment && "w-[95%] top-[-10px] left-[50%] translate-x-[-50%]"
      } ${posStyles && "top-[-100%]"} z-[9999]`}
    >
      <div className="h-[43px] w-full bg-[#f2f2f2] rounded-t-[10px] flex items-center justify-between px-[15px]">
        <span className="text-[14px] leading-[21px] text-[#00000080]">
          <TranslateText textKey={"add_party"} namespace="booking" />
        </span>
        <BoxCross
          className="cursor-pointer"
          onClick={(e) => {
            dispatch(updateAddPartyDropdownVisible(false));
            e.stopPropagation();
          }}
        />
      </div>
      <div className="w-full rounded-b-[10px] bg-[#fff]">
        <div className="pt-[20px]">
          <div className="mb-[10px] px-[10px] flex flex-col gap-[12px]">
            <TextField
              label={
                <p>
                  <TranslateText textKey={"display_name"} namespace="booking" />

                  <span className="text-[#007FFF]">*</span>
                </p>
              }
              id="expenseAccount"
              size="small"
              onChange={(e) => dispatch(updateNewPartytName(e.target.value))}
              value={newPartyName}
              type="email"
              autoComplete="new-password"
              sx={{
                width: "100%",

                "& label": {
                  color: "#0000004D",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                },
                "& input": {
                  fontFamily: "Poppins",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  color: "#4D4D4D",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
                "& .MuiInputLabel-root": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "nunito",
                  color: "#808080",
                  fontSize: "0.75rem", // Font size when label is down
                },
                "& .MuiInputLabel-shrink": {
                  fontSize: "0.93rem", // Font size when label is up (focused)
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "nunito",
                },
              }}
            />
          </div>

          <p className="h-[0.5px] w-full bg-[#00000033] my-[20px]"></p>
          <div className="pr-[20px] flex items-center justify-end pb-[10px]">
            <div className="flex items-center gap-[15px]">
              <button
                className="w-[76px] h-[31px] rounded-[5px] bg-[#526EFF0D] flex items-center justify-center"
                onClick={(e) => {
                  dispatch(updateAddPartyDropdownVisible(false));
                  e.stopPropagation();
                }}
              >
                <span className="font-[500] text-[14px] leading-[20px] text-[#007FFF]">
                  <TranslateText textKey={"cancel"} namespace="common" />
                </span>
              </button>
              <button
                className="w-[76px] h-[31px] rounded-[5px] bg-[#007FFF] flex items-center justify-center"
                onClick={handleAddParty}
                disabled={createPartyLoading}
              >
                {createPartyLoading ? (
                  <CircularProgress style={{ color: "#ffffff" }} size={19} />
                ) : (
                  <span className="font-[500] text-[14px] leading-[20px] text-[#FFFFFF]">
                    <TranslateText textKey={"add"} namespace="common" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
