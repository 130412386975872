import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateIsBookingEngineBuiding } from "../../reducer/appReducer";

const BookingEngine = ({ hotelId }) => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);

  const [isLoading, setIsLoading] = useState(true);
  const [countdown, setCountdown] = useState(10);
  useEffect(() => {
    const timeout = setTimeout(() => {
      const interval = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);

      setTimeout(() => {
        setIsLoading(false);
        clearInterval(interval);

        // Redirect to external page after 12 seconds
        setTimeout(() => {
          const url = `${process.env.REACT_APP_HOTEL_WEBSITE_URL}/${hotelDetails?.id}`;
          window.open(url, "_blank");
          dispatch(updateIsBookingEngineBuiding(false));
          localStorage.setItem("be", true);
        }, 3000);
      }, 10000); // 10 seconds countdown
    }, 100); // 3 seconds delay before starting countdown

    return () => clearTimeout(timeout);
  }, [hotelDetails?.id]);

  return (
    <div className="min-h-screen flex justify-center items-center">
      {isLoading ? (
        <div className="flex items-center space-x-4">
          <svg
            className="animate-spin h-5 w-5 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.373A7.96 7.96 0 014 12H0c0 4.418 3.582 8 8 8v-4zM12 20a8 8 0 008-8h-4c0 3.627-2.346 6.717-5.607 7.795L12 20zm-3.393-2.795A7.96 7.96 0 014 12h-4c0 4.418 3.582 8 8 8v-4zM12 0v4c4.418 0 8 3.582 8 8h4c0-6.627-5.373-12-12-12z"
            ></path>
          </svg>
          <span className="text-blue-500 font-bold text-lg">
            Hold on tight! We're building your booking engine in T- {countdown}{" "}
            seconds!
          </span>
        </div>
      ) : (
        <div>
          {/* Your booking engine content goes here */}
          <h1 className="text-3xl font-bold text-green-600">
            Booking Engine Ready!
          </h1>
          <p className="text-lg text-gray-800">
            Redirecting to the booking engine...
          </p>
        </div>
      )}
    </div>
  );
};

export default BookingEngine;
