import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { useState, useEffect } from "react";

import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../../assets/images/svgs/CloseIcon.svg";
import IconButton from "@mui/material/IconButton";
import { TranslateText } from "../../../../utils/translation";

import { updateDisplayTakePaymentDrawer } from "../../../../reducer/posReducer/restaurant";
import CustomTextField from "../../../common/InputField/Input";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";
import { getNumericalValue } from "../../../../utils/helper";

export default function TakePaymentDrawer({ total, invoice, hotelId }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(total);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [transactionId, setTransactionId] = useState("");

  const [paymentModePartyId, setPaymentModePartyId] = useState("");
  const [methodToPartyId, setMethodToPartyId] = useState({});

  const { displayTakePaymentDrawer } = useSelector((store) => store.pos);
  const { menuDrawer } = useSelector((store) => store.app);
  const { paymentModesResponse, allPayments } = useSelector(
    (store) => store.payments
  );
  const { data: orders } = useSelector((store) => store.order);

  useEffect(() => {
    setTransactionId("");
    setAmount(total);
  }, [total]);

  useEffect(() => {
    if (Array.isArray(paymentModesResponse) && paymentModesResponse?.length) {
      let updatedObj = {};

      paymentModesResponse?.forEach((mode) => {
        const { method, partyId } = mode;
        updatedObj[method] = partyId;
      });
      setMethodToPartyId(updatedObj);
      setPaymentModePartyId(updatedObj["cash"]);
    }
  }, [paymentModesResponse]);

  const handleAmountChange = (index, value) => {
    let trimmedValue = getNumericalValue(value);
    setAmount(trimmedValue);
  };

  const handleModeOfPayment = (value) => {
    setPaymentMethod(value);
    setPaymentModePartyId(methodToPartyId[value]);
  };

  const handleTransactionCashier = (value) => setTransactionId(value);

  const { handleTakePayment } = useAllPosFunctions(setLoading);

  const handlePayment = () => {
    handleTakePayment(
      amount,
      hotelId,
      invoice,
      paymentMethod,
      paymentModePartyId,
      transactionId,
      orders,
      allPayments
    );
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: menuDrawer ? "33%" : "30%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",

          "& .MuiDrawer-root": {
            position: "absolute",
            marginleft: "auto",
            right: 0,
          },
          "& .MuiPaper-root": {
            position: "absolute",
            marginLeft: "auto",
          },
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={displayTakePaymentDrawer}
      CloseIcon={null}
      onClose={() => dispatch(updateDisplayTakePaymentDrawer(false))}
    >
      <div className="flex flex-col mx-3 gap-5 items-start my-4   ">
        <div className="flex flex-col justify-between w-[100%]">
          <div className="flex  justify-between w-[100%] gap-3 items-center ml-5 ">
            <h1>
            <TranslateText textKey={"take_payment"} namespace="pos" />

            </h1>
            <div className="flex my-2 gap-2  items-center mr-3">
              <IconButton
                onClick={() => dispatch(updateDisplayTakePaymentDrawer(false))}
              >
                <img
                  src={CloseIcon}
                  alt=""
                  sx={{ fontSize: "small", color: "#A7A7A7" }}
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-col  w-[100%] mb-[2rem]">
          <div className="mx-5  relative gap-5 flex justify-between flex-wrap">
            <div className="w-[100%] flex justify-between items-center ">
              <div className="w-[40%]">
                <CustomTextField
                  label="Payment"
                  id="payment"
                  isMandatory={false}
                  onChange={(value) => handleAmountChange(0, value)}
                  value={Boolean(amount) ? Math.ceil(amount) : ""}
                  type="text"
                />
              </div>

              <div className="w-[50%]  top-[-3px] left-[50%] my-[-1px] absolute  py-[4px] ">
                <FormControl
                  sx={{
                    width: "100%",

                    color: "#0000004D",
                    "& .MuiSelect-icon": {
                      color: "#2F80ED",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  size="small"
                >
                  <InputLabel
                    id="modePayment"
                    sx={{
                      color: "#808080",
                      fontSize: "0.75rem",
                      fontWeight: "300",
                      fontFamily: "nunito",
                    }}
                  >
                    
                    <TranslateText textKey={"mode"} namespace="pos" />

                  </InputLabel>
                  <Select
                    labelId="ModeLabel"
                    id="mode"
                    label="Mode"
                    sx={{
                      width: "100%",

                      color: "#808080",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                    }}
                    onChange={(e) => handleModeOfPayment(e.target.value)}
                    value={paymentMethod}
                  >
                    {paymentModesResponse?.map((mode) => {
                      const { method, paymentMode } = mode;
                      return (
                        <MenuItem
                          value={method}
                          sx={{
                            fontSize: "14px",
                            color: "#00000098",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                          }}
                        >
                          {paymentMode}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="w-[100%]  justify-center items-center">
              <CustomTextField
                label={
                  <div>
                    {paymentMethod === "cash" ? "Cashier" : "Transaction No"}
                  </div>
                }
                isMandatory={false}
                onChange={(value) => handleTransactionCashier(value)}
                value={Boolean(transactionId) ? transactionId : ""}
                type="text"
              />
            </div>
          </div>
        </div>
      </div>

      <button
        className="bg-[#007FFF] text-[#FFF] w-full h-[3rem]"
        onClick={loading ? undefined : handlePayment}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={18} color="inherit" />
        ) : (
          "Save Details"
        )}
      </button>
    </Drawer>
  );
}
