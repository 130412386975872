import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";

import { IoCloseSharp } from "react-icons/io5";
import { updateSelectedSteward } from "../../../../reducer/posReducer/restaurant";

export default function StewardDrawer({
  stewardDrawerVisible,
  setStewardDrawerVisible,
  setAddStewardDrawerVisible,
}) {
  const dispatch = useDispatch();

  const handleClose = () => setStewardDrawerVisible(false);

  const { response, activePosSlug } = useSelector((store) => store.posSetting);

  let stewardsList = response?.filter(
    (item) => item.slug === activePosSlug
  )?.[0]?.stewards;

  let stewards = Array.isArray(stewardsList) ? stewardsList : [];

  const getInitials = (name) => {
    if (!name) return "";

    return name
      ?.split(" ") // Split the name by spaces
      ?.map((word) => word?.charAt(0)?.toUpperCase()) // Take the first character of each word and convert it to uppercase
      ?.join("")
      ?.substring(0, 2); // Join the initials together
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "100%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={stewardDrawerVisible}
      onClose={() => handleClose()}
    >
      <div className="w-[100%] flex flex-col justify-center items-center bg-[#F2F4FC] ">
        <header className="flex  py-4 px-5 w-[100%] bg-[#FFF] justify-between items-center ">
          <h2 className="font-outfit font-[600] text-[1rem] text-[#000000]">
            Select Steward
          </h2>

          <div className="flex gap-4 items-center">
            <div
              className="font-outfit font-[500] text-[14px] leading-[21px] text-[#007FFF] p-2"
              onClick={() => {
                setStewardDrawerVisible(false);
                setAddStewardDrawerVisible(true);
              }}
            >
              Add new
            </div>
            <div
              className="bg-[#4D4D4D] w-[16px] h-[16px] rounded-[50%] flex justify-center items-center "
              onClick={handleClose}
            >
              <IoCloseSharp className="text-[10px] text-[#FFF] " />
            </div>
          </div>
        </header>

        <main className=" w-[90%] mt-[2rem] mb-[2rem] bg-[#FFF] p-2 rounded-[12px]">
          <div className={`w-[100%] overflow-auto no-scrollbar `}>
            {Array.isArray(stewards) &&
              stewards?.map((steward) => {
                return (
                  <div
                    className="p-2 w-[100%] flex justify-start items-center gap-3"
                    onClick={() => {
                      dispatch(updateSelectedSteward(steward));
                      handleClose();
                    }}
                  >
                    <div
                      className="w-[30px] h-[30px] bg-[#F36960] rounded-[50%] 
                    flex justify-center items-center text-[#FFF] font-outfit font-[600] text-[12px] leaidng-[15.12px] tracking-wide"
                    >
                      {getInitials(steward)}
                    </div>
                    <div className="font-outfit font-[500] text-[12px] leading-[15.12px] text-[#000]">
                      {steward}
                    </div>
                  </div>
                );
              })}
          </div>
        </main>
      </div>
    </Drawer>
  );
}
