import React, { useEffect, Suspense, lazy } from "react";

// import { Client } from "../../components/clients/Clients.js";
// import { MobileScreenClient } from "../../mobileComponents/mobileScreenClients/Index.js";

import { useSelector, useDispatch } from "react-redux";
import {
  getAllCustomers,
  getCustomerByPhone,
  getAllCustomersSearch,
  resetPageNo,
  updateResetState,
} from "../../reducer/clientsReducer";
import useStoreBookingDetailsLogic from "../../hooks/bookingCustomHook/useStoreBookingDetails.js";

const Client = lazy(() =>
  import("../../components/clients/Clients.js").then((module) => ({
    default: module.Client,
  }))
);
const MobileScreenClient = lazy(() =>
  import("../../mobileComponents/mobileScreenClients/Index.js").then(
    (module) => ({ default: module.MobileScreenClient })
  )
);
const ClientPage = () => {
  const { isMobileScreen } = useSelector((store) => store.appHeader);
  const dispatch = useDispatch();
  const {
    response,
    pageNumber,
    pageSize,
    sortingField,
    sortingDirection,
    infinateScroll,
  } = useSelector((store) => store.clients);

  const { hotelDetails } = useSelector((state) => state.login);
  const { globalsearchText } = useSelector((store) => store.appHeader);

  useEffect(() => {
    if (globalsearchText === "") {
      const payload = {
        pageNumber: 0,
        hotelId: hotelDetails?.id,
        pageSize: pageSize,
        infinateScroll: infinateScroll,
        sortingField: sortingField,
        sortingDirection: sortingDirection,
      };
      dispatch(resetPageNo());
      dispatch(getAllCustomers(payload));
    } else {
      const payload = {
        hotelId: hotelDetails?.id,
        pageNumber: 0,
        pageSize: pageSize,
        infinateScroll: infinateScroll,
        sortingField: sortingField,
        sortingDirection: sortingDirection,
        searchText: globalsearchText,
      };
      dispatch(getAllCustomersSearch(payload));
    }
  }, [globalsearchText, sortingDirection, sortingField]);

  useEffect(() => {
    const payload = {
      hotelId: hotelDetails?.id,
      pageNumber: pageNumber,
      pageSize: pageSize,
      infinateScroll: infinateScroll,
      sortingField: sortingField,
      sortingDirection: sortingDirection,
    };

    if (infinateScroll) dispatch(getAllCustomers(payload));
  }, [pageNumber, pageSize]); // add sorting field and sorting direction

  useEffect(() => {
    dispatch(
      getCustomerByPhone({
        hotelId: hotelDetails?.id,
        phone: response?.[0]?.phone,
      })
    );
  }, [response?.[0]]);

  useEffect(() => {
    dispatch(updateResetState());
    dispatch(
      getCustomerByPhone({
        hotelId: hotelDetails?.id,
        phone: response?.[0]?.phone,
      })
    );
    const payload = {
      hotelId: hotelDetails?.id,
      pageNumber: 0,
      pageSize: pageSize,
      infinateScroll: infinateScroll,
      sortingField: sortingField,
      sortingDirection: sortingDirection,
    };

    dispatch(getAllCustomers(payload));
  }, [hotelDetails?.id]);

  useStoreBookingDetailsLogic();

  return (
    <Suspense>
      <div>{isMobileScreen ? <MobileScreenClient /> : <Client />}</div>
    </Suspense>
  );
};

export default ClientPage;
