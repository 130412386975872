import React, { useEffect, useState } from "react";
import ManageStocksWrapper from "../wrapper/Index";
import { Select } from "antd";
import { useSelector } from "react-redux";

function MainContainer({ categories, setCategories }) {
  const categoryMenu = categories.map((categoryItem, index) => ({
    key: categoryItem + index,
    label: categoryItem,
    value: categoryItem,
  }));

  const handleCategoryChange = (value, option) => {};

  return (
    <div className="w-full px-6">
      <Select
        style={{ width: "100%" }}
        options={categoryMenu}
        size="large"
        placeholder="Select Category"
        defaultValue={{
          value: "Select Category",
          label: "Select Category",
        }}
        onChange={handleCategoryChange}
      />
    </div>
  );
}

function SideContainer() {
  return <></>;
}

function ReportLoss() {
  const categoriesData = useSelector((state) => state.categories.data);
  const [categories, setCategories] = useState(categoriesData);

  useEffect(() => {
    setCategories(categoriesData);
  }, [categoriesData]);

  return (
    <ManageStocksWrapper
      headerName="Report Loss"
      leftSideContent={
        <MainContainer categories={categories} setCategories={setCategories} />
      }
      rightSideContent={<SideContainer />}
    ></ManageStocksWrapper>
  );
}

export default ReportLoss;
