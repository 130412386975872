import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  deleteSingleAddOn,
  getAllAddOnByHotelId,
  getSingleAddOn,
  postAllAddOn,
  postSingleAddOn,
  updateSingleAddOn,
} from "../services/addOn";

const initialState = {
  loading: false,
  addOns: [],
  addOnsApiError: false,

  postAddOnLoading: false,
  postAddOnError: false,

  putAddOnLoading: false,
  putAddOnError: false,
};

export const getAllAddOns = createAsyncThunk(
  "addOns/getAllAddOns",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getAllAddOnByHotelId(payload);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const getAddOnsById = createAsyncThunk(
  "addOns/getAddOnsById",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getSingleAddOn(payload);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const postAddOn = createAsyncThunk(
  "addOns/postAddOn",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postSingleAddOn(payload);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const postMultipleAddOn = createAsyncThunk(
  "addOns/postMultipleAddOn",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postAllAddOn(payload);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const updateAddOn = createAsyncThunk(
  "addOns/updateAddOn",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await updateSingleAddOn(payload);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const deleteAddOn = createAsyncThunk(
  "addOns/deleteAddOn",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await deleteSingleAddOn(payload);
      return payload.id;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const addOnsSlice = createSlice({
  name: "addOns",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllAddOns.pending, (state, action) => {
      state.loading = true;
      state.addOnsApiError = false;
    });
    builder.addCase(getAllAddOns.fulfilled, (state, action) => {
      state.loading = false;
      state.addOns = action.payload;
      state.taxRateError = false;
    });
    builder.addCase(getAllAddOns.rejected, (state, action) => {
      state.loading = false;
      state.addOns = [];
      state.addOnsApiError = true;
    });

    builder.addCase(postAddOn.pending, (state, action) => {
      state.postAddOnLoading = true;
      state.postAddOnError = false;
    });
    builder.addCase(postAddOn.fulfilled, (state, action) => {
      state.addOns = Array.isArray(state.addOns)
        ? [action.payload, ...state.addOns]
        : [action.payload];
      state.postAddOnLoading = false;
      state.postAddOnError = false;
    });
    builder.addCase(postAddOn.rejected, (state, action) => {
      state.postAddOnLoading = false;
      state.postAddOnError = true;
    });

    builder.addCase(postMultipleAddOn.pending, (state, action) => {
      state.postAddOnLoading = true;
      state.postAddOnError = false;
    });
    builder.addCase(postMultipleAddOn.fulfilled, (state, action) => {
      state.addOns = action.payload;
      state.postAddOnLoading = false;
      state.postAddOnError = false;
    });
    builder.addCase(postMultipleAddOn.rejected, (state, action) => {
      state.postAddOnLoading = false;
      state.postAddOnError = true;
    });

    builder.addCase(updateAddOn.pending, (state, action) => {
      state.putAddOnLoading = true;
    });
    builder.addCase(updateAddOn.fulfilled, (state, action) => {
      state.putAddOnLoading = false;

      state.addOns = state.addOns?.map((Obj) =>
        Obj?.id === action?.payload?.id ? action.payload : Obj
      );
    });
    builder.addCase(updateAddOn.rejected, (state, action) => {
      state.putAddOnLoading = false;
    });

    builder.addCase(deleteAddOn.fulfilled, (state, action) => {
      state.addOns = state.addOns?.filter((Obj) => Obj?.id !== action.payload);
      message.destroy();
      message.success("AddOn removed successfully");
    });
    builder.addCase(deleteAddOn.rejected, (state, action) => {
      message.destroy();
      message.info("Something went wrong");
    });
  },
});

export default addOnsSlice.reducer;
