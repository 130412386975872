import { authedAxios } from "../../utils/customHttpHeader";

export const getUnitsService = async () => {
  const reponse = await authedAxios().get(
    `hotels/${localStorage.getItem("hotelid")}`
  );
  return reponse;
};

export const addNewUnitService = async (payload) => {
  const response = await authedAxios().patch(
    `hotels/${localStorage.getItem("hotelid")}`,
    payload
  );
  return response;
};

export const removeUnitService = async (payload) => {
  const reponse = await authedAxios().patch(
    `hotels/${localStorage.getItem("hotelid")}`,
    payload
  );
  return reponse;
};
