import React, { useState, useEffect } from "react";
import { LuCalendarDays } from "react-icons/lu";
import { Calendar, DatePicker } from "antd";
import {
  CHANNEL_MANAGER_DATE_FORMAT,
  GET_ISO_FORMAT,
  GET_TOMORROW_DATE,
} from "../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import { updateDates } from "../../reducer/channelManagerReducer";
import moment from "moment";
import dayjs from "dayjs";

const Dates = () => {
  const dispatch = useDispatch();
  const { selectedCheckInDate, selectedCheckOutDate } = useSelector(
    (store) => store.channelManager
  );

  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const [disabledMinCheckOutDate, setDisabledMinCheckOutDate] = useState();

  useEffect(() => {
    // When selectedCheckInDate changes, set the minimum check-out date
    if (selectedCheckInDate) {
      setDisabledMinCheckOutDate(selectedCheckInDate);
    }
  }, [selectedCheckInDate]);

  const dateFormat = "YYYY/MM/DD";

  const disabledCheckInDate = (current) => {
    const previousDay = moment().subtract(1, "days"); // Calculate the previous day
    return current.valueOf() < previousDay.valueOf(); // Disable if before the previous day
  };

  const onChangeStartDate = (date, dateString) => {
    const checkOutDate = moment(dateString, "YYYY/MM/DD")
      .add(1, "day")
      .format("YYYY/MM/DD");
    dispatch(
      updateDates({
        checkIn: dateString,
        checkOut: checkOutDate,
      })
    );
  };

  const onSelectStartDate = () => {
    setOpenCheckIn((prev) => !prev);
    setOpenCheckOut((prev) => !prev);
  };

  const onChangeEndDate = (date, dateString) => {
    dispatch(
      updateDates({
        checkOut: dateString,
      })
    );
  };

  const onSelectEndDate = () => {
    setOpenCheckOut((prev) => !prev);
  };

  return (
    <div className="w-full">
      <div className="flex items-center gap-2 border rounded-lg justify-between">
        <div className="flex w-[80%] items-center gap-1 cursor-pointer relative">
          <div
            className="flex w-[100%] relative"
            onClick={() => {
              setOpenCheckIn((prev) => !prev);
              setOpenCheckOut(false);
            }}
          >
            <h1 className="flex gap-1 absolute top-[-8px] left-2 bg-[#ffffff]">
              <span className="font-semibold text-xs text-slate-500 ">
                From
              </span>
              <LuCalendarDays className="font-semibold text-slate-500 " />
            </h1>

            <p className="text-sm font-bold p-2 ">
              {Boolean(selectedCheckInDate) ? (
                <>{CHANNEL_MANAGER_DATE_FORMAT(selectedCheckInDate)}</>
              ) : (
                <>{CHANNEL_MANAGER_DATE_FORMAT(new Date())}</>
              )}
            </p>
          </div>

          <DatePicker
            className="invisibles"
            style={{ border: "none", width: "0", height: "0", padding: "0" }}
            onChange={onChangeStartDate}
            onSelect={onSelectStartDate}
            suffixIcon={<></>}
            format={dateFormat}
            open={openCheckIn}
            allowClear={false}
            disabledDate={disabledCheckInDate}
            // defaultValue={selectedCheckInDate}
          />
        </div>

        <div className="border h-[30px]"></div>

        <div className="w-[80%] flex items-center gap-1 cursor-pointer relative">
          <div
            className="flex w-[100%] relative"
            onClick={() => {
              setOpenCheckOut((prev) => !prev);
              setOpenCheckIn(false);
            }}
          >
            <h1 className="flex gap-1 absolute top-[-8px] left-2 bg-[#ffffff]">
              <span className="font-semibold text-xs text-slate-500">To</span>
              <LuCalendarDays className="font-semibold text-slate-500 " />
            </h1>
            <p className="text-sm font-bold p-2">
              {
                <>
                  {Boolean(selectedCheckOutDate) ? (
                    <>{CHANNEL_MANAGER_DATE_FORMAT(selectedCheckOutDate)}</>
                  ) : (
                    <>
                      {CHANNEL_MANAGER_DATE_FORMAT(
                        new Date(GET_TOMORROW_DATE())
                      )}
                    </>
                  )}
                </>
              }
            </p>
          </div>

          <DatePicker
            className="invisibles"
            style={{ border: "none", width: "0", height: "0", padding: "0" }}
            onChange={onChangeEndDate}
            onSelect={onSelectEndDate}
            suffixIcon={<></>}
            format={dateFormat}
            open={openCheckOut}
            allowClear={false}
            disabledDate={(current) =>
              current && current < moment(disabledMinCheckOutDate)
            }
            // defaultValue={selectedCheckOutDate}
            value={dayjs(
              selectedCheckOutDate
                ? selectedCheckOutDate
                : moment().add(1, "days").format("YYYY/MM/DD")
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Dates;
