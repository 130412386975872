import * as React from "react";
import { Drawer } from "@mui/material";
import { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  CircularProgress,
  Autocomplete,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../../assets/images/svgs/CloseIcon.svg";
import IconButton from "@mui/material/IconButton";

import { updateDisplayCloseOrderDrawer } from "../../../../reducer/posReducer/restaurant";
import { updateAddPartyDropdownVisible } from "../../../../reducer/expenseReducer";
import AddParty from "../../../bookingDetailsV2/components/AddParty";
import { TranslateText } from "../../../../utils/translation";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";

export default function CloseOrderDrawer({
  total,
  invoice,
  tableOrderDetails,
  hotelId,
}) {
  const dispatch = useDispatch();

  const [type, setType] = useState("takePayment");
  const [subType, setSubType] = useState("room");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [roomId, setRoomId] = useState("");
  const [partyId, setPartyId] = useState("");
  const [partyName, setPartyName] = useState("");
  const [amount, setAmount] = useState(total);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [transactionId, setTransactionId] = useState("");
  const [paymentModePartyId, setPaymentModePartyId] = useState("");

  const [loading, setLoading] = useState(false);

  const { addPartyDropdownVisible } = useSelector((store) => store.expense);

  const { partyResponse } = useSelector((store) => store.party);
  const { displayCloseOrderDrawer, numOfRoomDetails } = useSelector(
    (store) => store.pos
  );
  const { data: orders } = useSelector((store) => store.order);
  const { menuDrawer } = useSelector((store) => store.app);

  const { paymentModesResponse, allPayments } = useSelector(
    (store) => store.payments
  );
  const [methodToPartyId, setMethodToPartyId] = useState({});

  useEffect(() => {
    setTransactionId("");
    setAmount(total);
  }, [total]);

  useEffect(() => {
    if (Array.isArray(paymentModesResponse) && paymentModesResponse?.length) {
      let updatedObj = {};
      paymentModesResponse?.forEach((mode) => {
        const { method, partyId } = mode;
        updatedObj[method] = partyId;
      });
      setMethodToPartyId(updatedObj);
      setPaymentModePartyId(updatedObj["cash"]);
    }
  }, [paymentModesResponse]);

  const handleRoomBill = (e, newInputValue) => {
    setSelectedRoom(newInputValue);
    const { bookingId, id } =
      numOfRoomDetails?.find((item) => item?.roomNum === newInputValue) || {};
    setRoomId(id);
    setBookingId(bookingId);
  };

  const {
    updateInvoiceWithRoomIdAndBookingId,
    updateInvoiceWithPartyId,
    handleTakePayment,
  } = useAllPosFunctions(setLoading);

  const handleAmountChange = (index, value) => {
    const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numeric values
    const trimmedValue = numericValue?.replace(/^0+/, ""); // Remove leading zeros

    setAmount(trimmedValue);
  };

  const handleModeOfPayment = (value) => setPaymentMethod(value);
  const handleTransactionCashier = (value) => setTransactionId(value);

  const handleSaveDetails = () => {
    if (type === "attachToRoomParty") {
      if (subType === "party") {
        updateInvoiceWithPartyId(
          partyId,
          allPayments,
          hotelId,
          total,
          invoice,
          orders,
          partyName,
          updateDisplayCloseOrderDrawer,
          updateAddPartyDropdownVisible
        );
      } else {
        updateInvoiceWithRoomIdAndBookingId(
          roomId,
          hotelId,
          bookingId,
          invoice,
          orders,
          selectedRoom,
          updateDisplayCloseOrderDrawer,
          updateAddPartyDropdownVisible,
          allPayments
        );
      }
    } else {
      handleTakePayment(
        amount,
        hotelId,
        invoice,
        paymentMethod,
        paymentModePartyId,
        transactionId
      );
    }
  };

  const handleSubTypeChange = (value) => {
    setSubType(value);
    setPartyId("");
    setPartyName("");
    setSelectedRoom("");
    setRoomId("");
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: menuDrawer ? "33%" : "30%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",

          "& .MuiDrawer-root": {
            position: "absolute",
            marginleft: "0",
            right: 0,
          },
          "& .MuiPaper-root": {
            position: "absolute",
            marginLeft: "0",
          },
        },
        "& .MuiDrawer-paper": {
          padding: 0,
          margin: 0,
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "0",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={displayCloseOrderDrawer}
      CloseIcon={null}
      onClose={() => dispatch(updateDisplayCloseOrderDrawer(false))}
    >
      <div className="flex flex-col  gap-5 items-start mx-3   ">
        {addPartyDropdownVisible && (
          <AddParty
            inPos={true}
            setPartyName={setPartyName}
            setPartyId={setPartyId}
            partyType="source"
          />
        )}
        <div className="flex flex-col justify-between w-[100%]">
          <div className="flex  justify-between w-[100%] gap-3 items-center ml-5 ">
            <h2 className="font-[400] text-[0.875rem] text-[#4D4D4D] font-nunito">
              <TranslateText textKey={"close_order"} namespace="pos" />
            </h2>

            <div className="flex my-2 gap-2  items-center mr-3">
              <IconButton
                onClick={() => dispatch(updateDisplayCloseOrderDrawer(false))}
              >
                <img
                  src={CloseIcon}
                  alt=""
                  sx={{ fontSize: "small", color: "#A7A7A7" }}
                />
              </IconButton>
            </div>
          </div>

          <div className="mb-3">
            <div className="flex items-center justify-center gap-[30px] ">
              <div className="flex items-center gap-[5px]">
                <div
                  className={`h-[16px] w-[16px] rounded-full border-[1px] flex items-center justify-center ${
                    type === "room" ? "border-[#007FFF]" : "border-[#808080]"
                  } cursor-pointer`}
                  onClick={() => setType("takePayment")}
                >
                  {type === "takePayment" && (
                    <div className="w-[8px] h-[8px] rounded bg-[#007FFF]"></div>
                  )}
                </div>
                <span className="font-nunito font-[500px] text-[13px] leading-[17.73px] text-[#4D4D4D]">
                  <TranslateText textKey={"take_payment"} namespace="pos" />
                </span>
              </div>
              <div className="flex items-center gap-[5px]">
                <div
                  className={`h-[16px] w-[16px] rounded-full border-[1px] flex items-center justify-center ${
                    type === "party" ? "border-[#007FFF]" : "border-[#808080]"
                  } cursor-pointer`}
                  onClick={() => setType("attachToRoomParty")}
                >
                  {" "}
                  {type === "attachToRoomParty" && (
                    <div className="w-[8px] h-[8px] rounded bg-[#007FFF]"></div>
                  )}
                </div>
                <span className="font-nunito font-[500px] text-[13px] leading-[17.73px] text-[#4D4D4D]">
                  <TranslateText
                    textKey={"attach_to_room_party"}
                    namespace="pos"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {" "}
        {type === "takePayment" ? (
          <div className="flex flex-col  w-[100%] mb-[2rem]">
            <div className="mx-5  relative gap-5 flex justify-between flex-wrap">
              <div className="w-[100%] flex  ">
                <div className="w-[50%]">
                  <TextField
                    label="Payment"
                    id="payment"
                    size="small"
                    onChange={(e) => handleAmountChange(0, e.target.value)}
                    value={Boolean(amount) ? Math.ceil(amount) : ""}
                    autoComplete="new-password"
                    type="number"
                    sx={{
                      width: "100%",

                      "& label": {
                        color: "#808080",
                        fontFamily: "Nunito",
                        fontSize: "0.625rem",
                        fontWeight: "300",
                        marginRight: 0,
                      },
                      "& input": {
                        fontFamily: "Poppins",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "0px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      },
                      "& .MuiInputLabel-root": {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "nunito",
                        color: "#808080",
                        fontSize: "0.75rem", // Font size when label is down
                      },
                      "& .MuiInputLabel-shrink": {
                        fontSize: "0.88rem", // Font size when label is up (focused)
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "nunito",
                      },
                    }}
                  />
                </div>

                <div className="w-[50%] top-[-3px] left-[50%] my-[-1px] absolute  py-[4px] ">
                  <FormControl
                    sx={{
                      width: "100%",

                      color: "#0000004D",
                      "& .MuiSelect-icon": {
                        color: "#2F80ED",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "0px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      },
                    }}
                    size="small"
                  >
                    <InputLabel
                      id="modePayment"
                      sx={{
                        color: "#808080",
                        fontSize: "0.75rem",
                        fontWeight: "300",
                        fontFamily: "nunito",
                      }}
                    >
                      <TranslateText textKey={"mode"} namespace="pos" />
                    </InputLabel>
                    <Select
                      labelId="ModeLabel"
                      id="mode"
                      label="Mode"
                      sx={{
                        width: "100%",

                        color: "#808080",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                      }}
                      onChange={(e) => handleModeOfPayment(e.target.value)}
                      value={paymentMethod}
                    >
                      {paymentModesResponse?.map((mode) => {
                        const { method, partyId, paymentMode } = mode;
                        return (
                          <MenuItem
                            value={method}
                            sx={{
                              fontSize: "14px",
                              color: "#00000098",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                            }}
                          >
                            {paymentMode}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="w-[100%]  justify-center items-center">
                <TextField
                  label={
                    <div>
                      {paymentMethod === "cash" ? "Cashier" : "Transaction No"}
                    </div>
                  }
                  id="transaction"
                  size="small"
                  onChange={(e) => handleTransactionCashier(e.target.value)}
                  value={Boolean(transactionId) ? transactionId : ""}
                  autoComplete="new-password"
                  sx={{
                    width: "100%",

                    "& label": {
                      color: "#808080",
                      fontFamily: "Nunito",
                      fontSize: "0.625rem",
                      fontWeight: "300",
                      marginRight: 0,
                    },
                    "& input": {
                      fontFamily: "Poppins",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputLabel-root": {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "nunito",
                      color: "#808080",
                      fontSize: "0.75rem", // Font size when label is down
                    },
                    "& .MuiInputLabel-shrink": {
                      fontSize: "0.93rem", // Font size when label is up (focused)
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "nunito",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex gap-2 w-[100%] items-center pl-5 justify-center">
              <div
                className="flex gap-0 w-[90px] h-[30px] border-[#D9D9D9] 
            border-[0.5px] rounded-[10px] mb-4"
              >
                <div
                  className={`w-[45px]  ${
                    subType === "party"
                      ? "bg-[#007FFF] text-[#FFF]"
                      : "text-[#808080] bg-[#FFF]"
                  } flex justify-center items-center h-[100%] font-nunito font-400 
              text-[12px] leading-[20px] cursor-pointer  rounded-tl-[10px] rounded-bl-[10px] `}
                  onClick={() => handleSubTypeChange("party")}
                >
                  <TranslateText textKey={"party"} namespace="pos" />
                </div>

                <div
                  className={`w-[45px] ${
                    subType === "room"
                      ? "bg-[#007FFF] text-[#FFF]"
                      : "text-[#808080] bg-[#FFF]"
                  } h-[100%] flex justify-center items-center rounded-tr-[10px] rounded-br-[10px] font-nunito font-400  
              text-[12px] leading-[20px] cursor-pointer `}
                  onClick={() => handleSubTypeChange("room")}
                >
                  {" "}
                  <TranslateText textKey={"room"} namespace="pos" />
                </div>
              </div>

              {subType === "room" ? (
                <div className="w-[100%] flex justify-center mb-5">
                  <Autocomplete
                    id="country-select-demo"
                    style={{
                      width: "80%",
                      padding: 0,
                      borderRadius: "10px",
                      color: "#808080",
                    }}
                    value={selectedRoom}
                    onInputChange={(event, newInputValue) => {
                      handleRoomBill(event, newInputValue);
                    }}
                    options={numOfRoomDetails?.map((item) => item.roomNum)}
                    sx={{
                      fontSize: "14px",
                      color: "#00000098",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      padding: 0,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Room No"
                        sx={{
                          borderRadius: "10px",
                          "& label.Mui-focused": {
                            marginX: 0,
                            paddingX: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                          "& input": {
                            fontFamily: "Poppins",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            color: "#4D4D4D",
                            // border: "1px solid black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                          },
                          "& .MuiInputLabel-root": {
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "start",
                            fontFamily: "nunito",
                            color: "#808080",
                            fontSize: "0.75rem", // Font size when label is down
                          },
                          "& .MuiInputLabel-shrink": {
                            fontSize: "0.93rem", // Font size when label is up (focused)
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "nunito",
                            paddingTop: "-5px",
                          },
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-[90%] flex justify-center mb-5">
                  <Autocomplete
                    id="country-select-demo"
                    style={{
                      width: "80%",
                      padding: 0,
                      borderRadius: "10px",
                      color: "#808080",
                    }}
                    value={partyName}
                    onInputChange={(event, newInputValue) => {
                      if (newInputValue === "ADD PARTY") {
                        dispatch(updateAddPartyDropdownVisible(true));
                        return;
                      }
                      setPartyName(newInputValue);
                      let id = partyResponse
                        ?.filter((Obj) => Obj?.displayName === newInputValue)
                        ?.map((Obj) => Obj?.id)[0];
                      setPartyId(id);
                    }}
                    options={[
                      "ADD PARTY",
                      ...partyResponse?.map((Obj) => Obj?.displayName),
                    ]}
                    sx={{
                      fontSize: "14px",
                      color: "#00000098",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      padding: 0,
                    }}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          color: option === "ADD PARTY" ? "#007fff" : "inherit",
                        }}
                      >
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Party Name"
                        sx={{
                          borderRadius: "10px",
                          "& label.Mui-focused": {
                            marginX: 0,
                            paddingX: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                          "& input": {
                            fontFamily: "Poppins",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            color: "#4D4D4D",
                            // border: "1px solid black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                          },
                          "& .MuiInputLabel-root": {
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "start",
                            fontFamily: "nunito",
                            color: "#808080",
                            fontSize: "0.75rem", // Font size when label is down
                          },
                          "& .MuiInputLabel-shrink": {
                            fontSize: "0.93rem", // Font size when label is up (focused)
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "nunito",
                            paddingTop: "-5px",
                          },
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <button
        className="bg-[#007FFF] text-[#FFF] w-full h-[3rem]"
        onClick={handleSaveDetails}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={16} color="inherit" />
        ) : (
          "Save Details"
        )}
      </button>
    </Drawer>
  );
}
