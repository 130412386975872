export const ImageSizeValidation = {
  lessThan500kb: "File size must be smaller than 500 kb",
  lessThan1mb: "File size must be smaller than 1 mb",
  lessThan10mb: "File size must be smaller than 10 mb",
  lessThan20mb: "File size must be smaller than 20 mb",
};

export const ErrorMessage = {
  invalidRoomType: "Either Room name already exists or invalid!",
};

export const defaultRoomTypes = [
  {
    type: "Executive",
    available: false,
  },
  {
    type: "Deluxe",
    available: false,
  },
  {
    type: "Honeymoon Suite",
    available: false,
  },
];
