import { authedAxios } from "../utils/customHttpHeader";

const createTableReservation = async (payload) => {
  const response = await authedAxios().post("/pos/reservations", payload);
  return response;
};

const getAllTableReservations = async (payload) => {
  const query = `?hotelId=${payload?.hotelId}&forDate=${payload?.date}`;
  const response = await authedAxios().get(`/pos/reservations` + query);
  return response;
};

export { createTableReservation, getAllTableReservations };
