import { useDispatch, useSelector } from "react-redux";
import { getBookingList } from "../../reducer/bookingReservationReducer";
import { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  updateChechInCheckOutDate,
  updateRoomStoredFromBookingCalendar,
  updateSelectedRoomWTypeAndNum,
} from "../../reducer/walkinReducer";
import {
  getHotelCheckInOutTime,
  getHotelCheckInOutTimeInAMPM,
} from "../../utils/hotelHelper";
import {
  updateBookingHotelId,
  updateShowMainBookingDetailsDrawer,
} from "../../reducer/bookingReducer";

const useBookingCalendar = (
  mainRef,
  headerRef,
  rightChildRef,
  cellWidth,
  setCellWidth,
  daysOfWeek,
  filterdRoomNoAndRoomType,
  setOpenRoomNo,
  daysForCompareWithBookingInfo
) => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);
  const { fromDate, toDate, filterTitle } = useSelector(
    (store) => store.bookingReservation
  );
  const { menuDrawer } = useSelector((store) => store.app);
  const { response } = useSelector((store) => store.rooms);
  const navigate = useNavigate();

  const { CheckInTime, CheckOutTime, CheckInTimeMin, CheckOutTimeMin } =
    getHotelCheckInOutTime(hotelDetails || {});

  const { CheckInTimeInAMPM, CheckoutTimeInAMPM } =
    getHotelCheckInOutTimeInAMPM(hotelDetails || {});

  // Scrolling Logic
  useEffect(() => {
    const handleScroll = (e) => {
      if (e?.target === headerRef?.current && mainRef.current)
        mainRef.current.scrollLeft = e?.target?.scrollLeft;
      else if (e?.target === mainRef?.current && headerRef?.current)
        headerRef.current.scrollLeft = e?.target?.scrollLeft;
    };

    const headerElement = headerRef?.current;
    const mainElement = mainRef?.current;

    if (headerElement && mainElement) {
      headerElement.addEventListener("scroll", handleScroll);
      mainElement.addEventListener("scroll", handleScroll);

      return () => {
        headerElement.removeEventListener("scroll", handleScroll);
        mainElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    performApi();
  }, [hotelDetails?.id, fromDate, toDate]);

  useEffect(() => {
    if (rightChildRef.current) {
      let currentWidth = rightChildRef.current.offsetWidth / daysOfWeek?.length;
      currentWidth = Math.max(172, currentWidth); // This will ensure min 172 px is assigned to each cell
      setCellWidth(currentWidth);
    }
  }, [hotelDetails?.id, menuDrawer, response, filterTitle, fromDate, toDate]);

  //   This is used to set which Room is open by default
  useEffect(() => {
    if (Boolean(filterdRoomNoAndRoomType)) {
      let keys = Object.keys(filterdRoomNoAndRoomType);
      setOpenRoomNo(keys);
    }
  }, [response]);

  const performApi = async () => {
    let payload = {
      hotelId: hotelDetails?.id,
      startDate: Boolean(fromDate)
        ? moment(fromDate).toISOString()
        : moment().toISOString(),
      endDate: Boolean(toDate)
        ? moment(toDate).toISOString()
        : moment().add(6, "days").toISOString(),
      statuses: ["checkedIn", "completed", "confirmed", "enquiry"],
      pageSize: 50,
    };
    dispatch(getBookingList(payload));
  };

  const getPreferredBooking = (bookings) => {
    return (
      bookings.find((booking) => booking?.status === "checkedIn") ||
      bookings.find((booking) => booking?.status === "confirmed") ||
      bookings.find((booking) => booking?.status === "completed") ||
      bookings.find((booking) => booking?.status === "enquiry")
    );
  };

  const scroll = (offSet) => {
    if (mainRef?.current) {
      mainRef.current.scrollLeft += offSet * cellWidth;
      mainRef.current.behavior = "smooth";
    }
  };

  const redirectBooking = (
    bookingDetails = null,
    index,
    currentRoomType,
    emptyRoomDetails
  ) => {
    if (Boolean(bookingDetails)) {
      dispatch(updateBookingHotelId(hotelDetails?.id));

      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: true,
          bookingId: bookingDetails?.bookingId,
          bookingType: bookingDetails?.status,
          newBookingId: bookingDetails?.newBookingId,
        })
      );
    } else if (index && currentRoomType && emptyRoomDetails) {
      let newSelectedCheckInHour = CheckInTime;
      let newSelectedCheckOutHours = CheckOutTime;

      let checkInDateOnly = moment(
        daysForCompareWithBookingInfo?.[index]
      ).format("DD MMM YYYY");

      let checkOutDateOnly = moment(daysForCompareWithBookingInfo[index])
        .add(1, "days")
        .format("DD MMM YYYY");

      // Create the date-time string with the format "DD MMM YYYY h:mm A"
      const dateTimeStringCheckIn = `${checkInDateOnly} ${newSelectedCheckInHour}:${CheckInTimeMin} ${CheckInTimeInAMPM}`;

      // Correct format for parsing
      const checkInDateAndTime = moment(
        dateTimeStringCheckIn,
        "DD MMM YYYY h:mm A"
      ).valueOf();

      const dateTimeStringCheckOut = `${checkOutDateOnly} ${newSelectedCheckOutHours}:${CheckOutTimeMin} ${CheckoutTimeInAMPM}`;

      // Parse the combined string using moment.js for consistent formatting

      // Correct format for parsing
      const checkOutDateAndTime = moment(
        dateTimeStringCheckOut,
        "DD MMM YYYY h:mm A"
      ).valueOf();

      dispatch(
        updateChechInCheckOutDate({
          checkIn: checkInDateAndTime,
        })
      );
      dispatch(
        updateChechInCheckOutDate({
          checkOut: checkOutDateAndTime,
        })
      );

      dispatch(
        updateSelectedRoomWTypeAndNum({
          selectedRoomType: currentRoomType,
          selectedRoomNumber: [emptyRoomDetails],
        })
      );

      dispatch(updateRoomStoredFromBookingCalendar(true));
      navigate("/dashboard/walkin");
    } else navigate("/dashboard/walkin");
  };

  const getOccupency = (day, roomType, roomArr, dayIndex) => {
    let storeBookingCountByDateAndRoomType = {};

    roomArr?.forEach((roomDetails) => {
      if (roomDetails?.bookingInfo[dayIndex]?.length > 0) {
        storeBookingCountByDateAndRoomType[day] = Boolean(
          storeBookingCountByDateAndRoomType[day]
        )
          ? storeBookingCountByDateAndRoomType[day] + 1
          : 1;
      }
    });

    return storeBookingCountByDateAndRoomType;
  };

  return {
    getPreferredBooking,
    scroll,
    redirectBooking,
    getOccupency,
  };
};

export default useBookingCalendar;
