import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadFiles } from "../services/scanUpload";

const initialState = {
  loading: false,
  response: [], // store  room list json data into it
  apiError: false,

  checkIn: null,
  checkOut: null,

  selectedCheckInDate: "",
  selectedCheckOutDate: "",

  selectedCheckInHours: 12,
  selectedCheckInMin: 0,
  selectedCheckInAMPM: "PM",

  selectedCheckOutHours: 12,
  selectedCheckOutMin: 0,
  selectedCheckOutAMPM: "PM",

  adults: 0,
  children: 0,
  mealPlan: "EP",

  uploadedFileUrl: [], // Store Ids of given customer

  primaryNumber: null,
  countryCode: "+91",
  phoneValue: "",
  primaryName: null,

  discount: 0,

  walkInEmailId: null,
  walkInAddress: null,
  walkInDesignation: null,
  walkInCompanyName: null,
  walkInCompanyGSTIN: null,
  selectedTravel: "",
  selectedFood: "",

  selectedBookingSources: "walkIn",
  remarks: null,

  arrivalFrom: null,
  destination: null,
  selectedPurposeOfTravel: "",

  selectedDob: null,
  selectedDoa: null,

  visibleRoomNumberModal: false,
  tempSelectedRoomWTypeAndNum: {},
  selectedRoomWTypeAndNum: {},

  roomTotal: 0,
  subTotal: 0,
  total: 0,
  balanceDue: 0,
  addOnsTotalWithoutTax: 0,
  addOnsTax: 0,

  nightsCount: 1,

  bookingSourceName: "",
  bookingSourcePartyId: "",
  bookingSourceType: "",

  showPaymentDrawer: false,
  showDiscountDrawer: false,
  showCheckInDrawer: false,

  referenceNo: "",
  postalCode: "",
  city: "",
  country: "",
  gender: "",

  discountInfo: {
    type: null,
    value: 0,
  },

  bookingStatus: null,
  visibleCalendar: false,

  roomPriceStructure: [],
  visibleAddonsDrawer: false,

  displayDob: false,
  displayDoa: false,

  paymentBreakUps: [],
  displayIdModal: false,

  taxIncludedInPriceLocalState: null,

  detailedPriceBreakup: [],
  displayDetaileBreakupModal: false,
  addPartyDropwownVisibleInGuestDetails: false,
  isPartyInputFocused: false,
  isPartyInputFocusedNew: false,

  createBookingLoading: false,
  roomsStoredFromBookingReservationCalendar: false,

  // Mobile Screen Specific State
  guestSectionVisible: false,

  showMobileNotificationDrawer: false,
  showSourcePartyDrawer: false,
  sourceType: "",
  displayName: "",
  partySearchString: "",
  isSourcePartyAttached: false,

  addOnsArray: [],
  roomAvailabiltyArray: [],
  filteredRoomAvailabilityArray: [],
};

export const upload = createAsyncThunk("upload", async (payload, action) => {
  try {
    const response = await uploadFiles(payload);
    return response;
  } catch (error) {
    return null;
  }
});

export const walkinSlice = createSlice({
  name: "walkin",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_46: () => initialState,
    updateAddPartyDropwownVisibleInGuestDetails: (state, action) => {
      state.addPartyDropwownVisibleInGuestDetails = action.payload;
    },

    updateCompanyDetails: (state, action) => {
      const { name, gst } = action.payload;
      state.walkInCompanyName = name;
      state.walkInCompanyGSTIN = gst;
    },

    updateIsSourcePartyAttached: (state, action) => {
      state.isSourcePartyAttached = action.payload;
    },

    updateDisplayName: (state, action) => {
      state.displayName = action.payload;
    },

    updateSourceType: (state, action) => {
      state.sourceType = action.payload;
    },

    updateShowSourcePartyDrawer: (state, action) => {
      state.showSourcePartyDrawer = action.payload;
    },

    updatePartySearchString: (state, action) => {
      state.partySearchString = action.payload;
    },

    updateBookingSourceName: (state, action) => {
      state.bookingSourceName = action.payload;
    },
    updateBookingSourcePartyId: (state, action) => {
      state.bookingSourcePartyId = action.payload;
    },
    updateBookingSourceType: (state, action) => {
      state.bookingSourceType = action.payload;
    },

    updateWalkinDetails: (state, action) => {
      state.loading = action.payload.loading;
      state.response = action.payload.response;
      state.apiError = action.payload.apiError;
    },

    updateSelectedRoomNumberForModify: (state, action) => {
      // it will come from modify booking page

      state.selectedRoomWTypeAndNum = action.payload;
      state.tempSelectedRoomWTypeAndNum = action.payload;
    },
    updateAdulsAndChildren: (state, action) => {
      if (action.payload.adults) {
        if (action.payload.type === "plus") {
          state.adults = state.adults + 1;
        } else {
          state.adults = state.adults - 1;
        }
      } else {
        if (action.payload.type === "plus") {
          state.children = state.children + 1;
        } else {
          state.children = state.children - 1;
        }
      }
    },

    updateAdults: (state, action) => {
      if (action.payload <= 0) state.adults = 0;
      else state.adults = action.payload;
    },
    updateChildren: (state, action) => {
      if (action.payload <= 0) state.children = 0;
      else state.children = action.payload;
    },

    updateUploadedUrl: (state, action) => {
      state.uploadedFileUrl = [...state.uploadedFileUrl, action.payload];
    },
    updateUploadedUrlFromModify: (state, action) => {
      state.uploadedFileUrl = action.payload;
    },
    removeUploadedUrl: (state, action) => {
      if (state.uploadedFileUrl.indexOf(action.payload) > -1) {
        let index = state.uploadedFileUrl.indexOf(action.payload);
        state.uploadedFileUrl.splice(index, 1);
      }
    },

    updateChechInCheckOutDate: (state, action) => {
      if (action.payload.checkIn) {
        state.checkIn = action.payload.checkIn;
      } else if (action.payload.checkOut) {
        state.checkOut = action.payload.checkOut;
      }
    },

    updateNightsCount: (state, action) => {
      let count = Boolean(action?.payload) ? action.payload : 1;
      state.nightsCount = count;
    },

    updatePrimaryNumber: (state, action) => {
      state.primaryNumber = action.payload;
    },
    updateCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    updatePhoneValue: (state, action) => {
      state.phoneValue = action.payload;
    },
    updatePrimaryName: (state, action) => {
      state.primaryName = action.payload;
    },
    updateDiscount: (state, action) => {
      state.discount = action.payload;
    },

    clearSelectedRoom: (state) => {
      state.selectedRoomWithType = {};
    },
    updateTotal: (state, action) => {
      state.total = action.payload;
    },
    updateBalanceDue: (state, action) => {
      state.balanceDue = action.payload;
    },
    updateBookingStatus: (state, action) => {
      state.bookingStatus = action.payload;
    },
    updateTaxIncludedInPrice: (state, action) => {
      state.taxIncludedInPriceLocalState = action.payload;
    },
    updateDetailedPriceBreakup: (state, action) => {
      state.detailedPriceBreakup = action.payload;
    },
    updateDisplayDetailedBreakupModal: (state, action) => {
      state.displayDetaileBreakupModal = action.payload;
    },
    updateRoomStoredFromBookingCalendar: (state, action) => {
      state.roomsStoredFromBookingReservationCalendar = action.payload;
    },

    updateAddOnsTotalWithoutTax: (state, action) => {
      state.addOnsTotalWithoutTax = action.payload;
    },
    updateAddOnsTax: (state, action) => {
      state.addOnsTax = action.payload;
    },
    refreshWalkinData: (state) => {
      state.bookingSourceName = "";
      state.bookingSourcePartyId = "";
      state.bookingSourceType = "";

      state.response = []; // store  room list json data into it
      state.apiError = false; // check api status
      state.selectedRoomType = null; // store the selected room type, exp: luxury, executive
      state.selectedRoomWithType = {}; // store the selected room with room type  in object format exp: {luxury:[101]}
      state.adults = 1; // select adults
      state.children = 0; // select childrens
      state.uploadedFileUrl = []; // after uploaded the file , store the file url,
      state.checkIn = null;
      state.checkOut = null;
      state.primaryNumber = null;
      state.countryCode = "+91";
      state.value = "";
      state.primaryName = null;
      state.tax = 0;
      state.primaryNumber = "";
      state.phoneValue = "";
      state.countryCode = "+91";

      state.walkInEmailId = null;
      state.walkInAddress = null;
      state.walkInDesignation = null;
      state.walkInCompanyName = null;
      state.walkInCompanyGSTIN = null;
      state.selectedTravel = "";
      state.selectedFood = "";
      state.selectedBookingSources = "walkIn";

      state.remarks = null;
      state.amount = 0;
      state.selectedDob = null;
      state.selectedDoa = null;
      state.visibleRoomNumberModal = false;
      state.selectedRoomWTypeAndNum = {};
      state.tempSelectedRoomWTypeAndNum = {}; // temp store selected room for further change
      state.mealPlan = "EP";

      state.roomTotal = 0;
      state.subTotal = 0;
      state.total = 0;
      state.balanceDue = 0;
      state.addOnsTotalWithoutTax = 0;
      state.addOnsTax = 0;

      state.arrivalFrom = null;
      state.destination = null;
      state.selectedPurposeOfTravel = "";
      state.nightsCount = 1;

      state.referenceNo = "";
      state.postalCode = "";
      state.country = "";
      state.gender = "";
      state.city = "";

      state.discountInfo = {
        type: null,
        value: 0,
      };
      state.discount = 0;

      state.bookingStatus = null;
      state.visibleCalendar = false;
      state.referenceNo = "";

      state.paymentBreakUps = [];
      state.detailedPriceBreakup = [];

      state.roomIds = [];
      state.roomPriceStructure = [];
      state.checkIn = null;
      state.checkOut = null;

      state.roomsStoredFromBookingReservationCalendar = false;
      state.addOnsArray = [];
    },

    updateEmailId: (state, action) => {
      state.walkInEmailId = action.payload;
    },
    updateAddress: (state, action) => {
      state.walkInAddress = action.payload;
    },
    updateCompanyName: (state, action) => {
      state.walkInCompanyName = action.payload;
    },
    updateCompanyGSTIN: (state, action) => {
      state.walkInCompanyGSTIN = action.payload;
    },
    updateDesignation: (state, action) => {
      state.walkInDesignation = action.payload;
    },

    updateMealPlan: (state, action) => {
      state.mealPlan = action.payload;
    },

    updateSelectedTravel: (state, action) => {
      state.selectedTravel = action.payload;
    },

    updateSelectedFood: (state, action) => {
      state.selectedFood = action.payload;
    },

    upadateSelectedBookingSources: (state, action) => {
      state.selectedBookingSources = action.payload;
    },

    upadateRemarks: (state, action) => {
      state.remarks = action.payload;
    },

    updateDateOfBirth: (state, action) => {
      if (action.payload.dob) {
        state.selectedDob = action.payload.dob;
      }
    },

    updateDateOfAnniversary: (state, action) => {
      if (action.payload.doa) {
        state.selectedDoa = action.payload.doa;
      }
    },
    updateDobAndDoa: (state, action) => {
      if (action.payload.dob) {
        state.selectedDob = action.payload.dob;
        state.selectedDoa = action.payload.doa;
      } else if (action.payload.doa) {
        state.selectedDoa = action.payload.doa;
      }
    },

    updateVisibleRoomNumberModal: (state, action) => {
      state.visibleRoomNumberModal = action.payload;
    },

    updateVisibleAddonsDrawer: (state, action) => {
      state.visibleAddonsDrawer = action.payload;
    },

    updateSelectedRoomWTypeAndNum: (state, action) => {
      const { selectedRoomType, selectedRoomNumber } = action.payload;

      // Merge the new room type and number into the existing data
      const updatedData = {
        ...state.selectedRoomWTypeAndNum,
        [selectedRoomType]: selectedRoomNumber,
      };

      // Filter out any entries where the room number array is empty
      const filteredRooms = Object.entries(updatedData)
        .filter(([_, roomNumbers]) => roomNumbers.length > 0)
        .reduce((acc, [roomType, roomNumbers]) => {
          acc[roomType] = roomNumbers;
          return acc;
        }, {});

      // Update the state with the filtered rooms
      state.tempSelectedRoomWTypeAndNum = filteredRooms;
      state.selectedRoomWTypeAndNum = filteredRooms;
    },

    saveSelectedRoomWTypeAndNum: (state, action) => {
      state.tempSelectedRoomWTypeAndNum = state.selectedRoomWTypeAndNum;
    },

    // after room number change in "tempSelectedRoomWTypeAndNum", save it in "selectedRoomWTypeAndNum"
    saveTempSelectedRoomWTypeAndNum: (state, action) => {
      state.selectedRoomWTypeAndNum = state.tempSelectedRoomWTypeAndNum;
    },

    updateTempSelectedRoomWTypeAndNum: (state, action) => {
      state.tempSelectedRoomWTypeAndNum = action.payload;
    },

    updateSubTotal: (state, action) => {
      state.subTotal = action.payload;
    },
    updateRoomTotal: (state, action) => {
      state.roomTotal = action.payload;
    },
    updateTotalTax: (state, action) => {
      state.tax = action.payload;
    },

    updateDiscountInfo: (state, action) => {
      state.discountInfo = action.payload;
    },
    updateRoomPriceStructure: (state, action) => {
      state.roomPriceStructure = action.payload;
    },
    updatePaymentBreakups: (state, action) => {
      state.paymentBreakUps = action.payload;
    },

    updateCreateBookingLoading: (state, action) => {
      state.createBookingLoading = action.payload;
    },

    updateCustomeWalkinDetails: (state, action) => {
      const customer = action.payload;
      state.walkInEmailId = customer?.email;

      state.primaryName = customer?.name;
      state.walkInAddress = customer?.address;
      state.uploadedFileUrl = Boolean(customer?.idImages)
        ? customer?.idImages
        : [];
      state.walkInDesignation = customer?.designation;

      state.selectedDob = customer?.dob;
      state.selectedDoa = customer?.doanniversary;
      state.gender = customer?.gender;
      state.postalCode = customer?.zipCode;
      state.city = customer?.city;
      state.country = customer?.country;

      if (
        state.selectedBookingSources === "company" &&
        state.bookingSourcePartyId
      )
        return;

      state.walkInCompanyName = customer?.company;
      state.walkInCompanyGSTIN = customer?.gstIN;
    },

    resetCustomeWalkinDetails: (state, action) => {
      state.uploadedFileUrl = [];

      state.walkInEmailId = "";
      state.primaryName = "";
      state.walkInAddress = null;
      state.uploadedFileUrl = [];
      state.walkInDesignation = null;

      state.selectedDob = null;
      state.selectedDoa = null;

      state.gender = "";
      state.postalCode = "";
      state.city = "";
      state.country = "";

      if (
        state.selectedBookingSources === "company" &&
        state.bookingSourcePartyId
      )
        return;

      state.walkInCompanyName = null;
      state.walkInCompanyGSTIN = null;
    },

    updateArrivalFrom: (state, action) => {
      state.arrivalFrom = action.payload;
    },
    updateDestination: (state, action) => {
      state.destination = action.payload;
    },
    updateSelectedPurposeOfTravel: (state, action) => {
      state.selectedPurposeOfTravel = action.payload;
    },

    displayCheckInDrawer: (state, action) => {
      state.showCheckInDrawer = !state.showCheckInDrawer;
    },
    displaypPaymentDrawer: (state, action) => {
      state.showPaymentDrawer = !Boolean(state.showPaymentDrawer);
    },
    displayDiscountDrawer: (state, action) => {
      state.showDiscountDrawer = !state.showDiscountDrawer;
    },
    updateReferenceNo: (state, action) => {
      state.referenceNo = action.payload;
    },
    updateGender: (state, action) => {
      state.gender = action.payload;
    },
    updatePostalCode: (state, action) => {
      state.postalCode = action.payload;
    },
    updateCity: (state, action) => {
      state.city = action.payload;
    },
    updateCountry: (state, action) => {
      state.country = action.payload;
    },
    updateVisibleCalendar: (state, action) => {
      state.visibleCalendar = action.payload;
    },
    updateDob: (state, action) => {
      state.displayDob = action.payload;
    },
    updateDoa: (state, action) => {
      state.displayDoa = action.payload;
    },

    updateSelectedCheckInDate: (state, action) => {
      state.selectedCheckInDate = action.payload;
    },
    updateSelectedCheckOutDate: (state, action) => {
      state.selectedCheckOutDate = action.payload;
    },
    updateSelectedCheckInHours: (state, action) => {
      if (action.payload < 0) return;
      let initialValue = String(action.payload);
      let finalValue = initialValue.replace(/^0+/, "");
      state.selectedCheckInHours = finalValue;
    },
    updateSelectedCheckInMin: (state, action) => {
      const minute = Math.max(0, Math.min(59, action.payload));
      let minuteString = minute.toString();
      if (minuteString.startsWith("0") && minuteString?.length !== 1)
        minuteString = minuteString.substring(1);

      state.selectedCheckInMin = minuteString;
    },
    updateSelectedCheckInAMPM: (state, action) => {
      state.selectedCheckInAMPM = action.payload;
    },
    updateSelectedCheckOutHours: (state, action) => {
      if (action.payload < 0) return;
      let initialValue = String(action.payload);
      let finalValue = initialValue.replace(/^0+/, "");

      state.selectedCheckOutHours = finalValue;
    },
    updateSelectedCheckOutMin: (state, action) => {
      const minute = Math.max(0, Math.min(59, action.payload));
      let minuteString = minute.toString();
      if (minuteString.startsWith("0") && minuteString?.length !== 1)
        minuteString = minuteString.substring(1);

      state.selectedCheckOutMin = minuteString;
    },
    updateSelectedCheckOutAMPM: (state, action) => {
      state.selectedCheckOutAMPM = action.payload;
    },
    updateDisplayIdModal: (state, action) => {
      state.displayIdModal = action.payload;
    },
    updateGuestSectionVisble: (state, action) => {
      state.guestSectionVisible = action.payload;
    },
    updateShowMobileNotificationDrawer: (state, action) => {
      state.showMobileNotificationDrawer = action.payload;
    },
    setIsPartyInputFocused: (state, action) => {
      state.isPartyInputFocused = action.payload;
    },
    setIsPartyInputFocusedNew: (state, action) => {
      state.isPartyInputFocusedNew = action.payload;
    },
    updateAddOnsArray: (state, action) => {
      state.addOnsArray = action.payload;
    },

    updateRoomAvailabiltyArray: (state, action) => {
      state.roomAvailabiltyArray = action.payload;
    },
    updateFilteredRoomAvailabiltyArray: (state, action) => {
      state.filteredRoomAvailabilityArray = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_46,
  updateWalkinDetails,
  clearSelectedRoom,
  saveSelectedRoomWithRoomNumber,
  updateAdulsAndChildren,
  updateUploadedUrl,

  updateChechInCheckOutDate,
  updatePrimaryNumber,
  updateCountryCode,
  updatePhoneValue,
  updatePrimaryName,
  updateDiscount,
  refreshWalkinData,

  updateAdults,
  updateChildren,

  updateSelectedRoomNumberForModify,
  updateUploadedUrlFromModify,
  removeUploadedUrl,

  updateEmailId,
  updateCompanyName,
  updateDesignation,
  updateAddress,
  updateCompanyGSTIN,
  updateSelectedTravel,
  updateSelectedFood,
  upadateSelectedBookingSources,
  upadateRemarks,
  upadateTransactionNo,

  updateCustomeWalkinDetails,
  resetCustomeWalkinDetails,

  updateDateOfBirth,
  updateDateOfAnniversary,
  updateDobAndDoa,
  updateMealPlan,

  updateVisibleRoomNumberModal,
  updateSelectedRoomWTypeAndNum,
  saveSelectedRoomWTypeAndNum,
  saveTempSelectedRoomWTypeAndNum,
  updateTempSelectedRoomWTypeAndNum,

  updateTotal,
  updateRoomTotal,
  updateSubTotal,
  updateArrivalFrom,
  updateDestination,
  updateSelectedPurposeOfTravel,
  updateNightsCount,

  displayCheckInDrawer,
  displayDiscountDrawer,
  displaypPaymentDrawer,

  updateReferenceNo,
  updatePostalCode,
  updateCity,
  updateCountry,
  updateGender,

  updateBalanceDue,
  updateDiscountInfo,

  updateBookingStatus,
  updateVisibleAddonsDrawer,
  updateVisibleCalendar,

  updateRoomPriceStructure,

  updateDoa,
  updateDob,

  updateSelectedCheckInDate,
  updateSelectedCheckOutDate,

  updateSelectedCheckInHours,
  updateSelectedCheckInMin,
  updateSelectedCheckInAMPM,

  updateSelectedCheckOutHours,
  updateSelectedCheckOutMin,
  updateSelectedCheckOutAMPM,

  updatePaymentBreakups,
  updateDisplayIdModal,
  updateTaxIncludedInPrice,

  updateGuestSectionVisble,
  updateBookingSourceName,
  updateBookingSourcePartyId,
  updateBookingSourceType,

  updateShowMobileNotificationDrawer,
  updateShowSourcePartyDrawer,
  updateSourceType,
  updateDisplayName,
  updatePartySearchString,
  updateIsSourcePartyAttached,

  updateCompanyDetails,

  updateDetailedPriceBreakup,
  updateDisplayDetailedBreakupModal,

  updateAddPartyDropwownVisibleInGuestDetails,
  setIsPartyInputFocused,
  setIsPartyInputFocusedNew,
  updateCreateBookingLoading,

  updateRoomStoredFromBookingCalendar,
  updateAddOnsArray,

  updateRoomAvailabiltyArray,
  updateFilteredRoomAvailabiltyArray,

  updateAddOnsTotalWithoutTax,
  updateAddOnsTax,
  updateTotalTax,
} = walkinSlice.actions;

export default walkinSlice.reducer;
