import React from "react";
import { Radio } from "antd";

const DiscountButton = ({ discountType, handleDiscountTypeChange }) => {
  return (
    <Radio.Group
      buttonStyle="outlined"
      className="flex flex-wrap gap-2"
      value={discountType}
      onChange={(e) => handleDiscountTypeChange(e.target.value)}
    >
      <Radio.Button value="percentage" className=" bg-[#F2F2F2]">
        Percentage
      </Radio.Button>
      <Radio.Button value="flat" className="bg-[#F2F2F2]">
        Flat
      </Radio.Button>
    </Radio.Group>
  );
};

export default DiscountButton;
