import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Input, Select } from "antd";
import CircularProgress from "@mui/material/CircularProgress";

import { postRecount } from "../../../reducer/ingredientsReducer";
import { getAllIngredients } from "../../../reducer/ingredientsReducer";
import { getAllItems } from "../../../reducer/getItemReducer";
import { updateActiveView } from "../../../reducer/manageStocksReducer";
import { CURRENCY_FORMAT, FOLIO_FORMAT } from "../../../utils/helper";
import { HEADER_ITEMS } from "../utils";

import { ReactComponent as CrossIcon } from "../../../assets/images/svgs/CrossIcon2.svg";
import { ReactComponent as EmptyPreview } from "../../../assets/icons/emptyReports.svg";
import { TranslateText } from "../../../utils/translation";

const Recount = () => {
  const { hotelDetails } = useSelector((store) => store.login);
  const { selectedPos } = useSelector((store) => store.manageStocks);
  const { data: items, loading: itemsLoading } = useSelector(
    (store) => store.getItems
  );
  const { data: ingredients, loading: ingredientsLoading } = useSelector(
    (store) => store.ingredients
  );

  const [recountItems, setRecountItems] = useState({});
  const [filteredList, setFilteredist] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [list, setList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isRestaurant = selectedPos?.type === "Restaurant";

  // if pos type is restaurant, then show list of ingredients, otherwise show items
  useEffect(() => {
    const isRestaurant = selectedPos?.type === "Restaurant";
    let updatedList = [];
    updatedList = isRestaurant ? ingredients : items;
    const allCategories = updatedList?.map(
      (obj) => obj[isRestaurant ? "type" : "category"]
    );
    const updatedCategories = [...new Set(allCategories)]?.map((category) => {
      return { value: category, label: category };
    });
    setList(updatedList);
    setCategories(updatedCategories);
  }, [ingredients, items, selectedPos]);

  // effect to filter out list of items based on searcha and category filter
  useEffect(() => {
    const isRestaurant = selectedPos?.type === "Restaurant";

    const updatedList = list
      ?.filter((obj) => {
        const category = isRestaurant ? obj?.type : obj?.category;
        return !categoryFilter ? true : categoryFilter === category;
      })
      ?.filter((obj) => obj?.name?.toLowerCase()?.includes(searchString));
    setFilteredist(updatedList);
  }, [list, searchString, categoryFilter]);

  const handleChangeQuantity = (id, method, value, obj) => {
    let updatedRecountItems = JSON.parse(JSON.stringify(recountItems));
    updatedRecountItems = {
      ...updatedRecountItems,
      [id]: { ...obj, newQuantity: "" },
    };

    console.log(method, "value");

    if (method === "input") {
      if (isNaN(value)) return;
      updatedRecountItems[id].newQuantity = value;
    } else {
      delete updatedRecountItems[id];
    }
    setRecountItems(updatedRecountItems);
  };

  const handleSave = async () => {
    const isRestaurant = selectedPos?.type === "Restaurant";
    const id = isRestaurant ? "ingredientId" : "itemId";
    const name = isRestaurant ? "ingredientName" : "itemName";

    let recountsArray = [];
    Object.keys(recountItems)?.forEach((key) => {
      const targetObj = recountItems[key];

      const previousCount = isRestaurant
        ? targetObj?.stockCount
        : targetObj?.stock;
      const updatedCount = isRestaurant
        ? targetObj?.newQuantity
        : targetObj?.newQuantity;

      const recountObj = {
        previousCount,
        updatedCount,
        differenceCount: updatedCount - previousCount,
        [id]: targetObj?.id,
        [name]: targetObj?.name,
      };
      recountsArray?.push(recountObj);
    });

    const payload = {
      hotelId: hotelDetails?.id,
      posId: selectedPos?.id,
      recounts: recountsArray,
    };

    setLoading(true);
    const res = await dispatch(postRecount(payload));
    setLoading(false);

    if (res?.payload?.status === 200) {
      setRecountItems({});
      setSearchString("");

      // call ingredient or item api to show updated list of data
      const selectedPosType = selectedPos?.type;
      const payload = { hotelId: hotelDetails?.id, posId: selectedPos?.id };

      if (selectedPosType === "Restaurant") {
        dispatch(getAllIngredients(payload));
      } else {
        dispatch(getAllItems(payload));
      }

      dispatch(updateActiveView("list"));
    }
  };

  if (itemsLoading || ingredientsLoading) {
    return (
      <div className="w-full h-full pr-[50px] pl-[20px] pb-[20px] bg-[#fff] flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-full h-full flex gap-[25px]">
      <div className="w-[65%] rounded-[10px] bg-[#fff]">
        <div className="w-full flex px-[20px] items-center justify-center gap-[10px] py-[20px]">
          <Input
            className="h-[36px] w-[80%]"
            placeholder={isRestaurant ? "Search ingredient" : "Seach item"}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value.toLowerCase())}
          />
          <Select
            className="h-[36px] w-[20%]"
            style={{ width: 155 }}
            value={categoryFilter}
            placeholder={"Select Category"}
            onChange={(value) => {
              setSearchString("");
              setCategoryFilter(value);
            }}
            options={[{ value: "", label: "All Categories" }, ...categories]}
          />
        </div>

        <div className="w-full h-[60px] bg-[#fff] border-b-[#7E8082] border-b-[1px] rounded-t-[10px] flex items-center">
          {HEADER_ITEMS?.filter((_, idx) => idx < 5)?.map((heading, idx) => {
            if (idx === 0 && isRestaurant)
              heading = (
                <TranslateText
                  textKey={"ingredient_name"}
                  namespace="manageStocks"
                />
              );
            if (idx === 4)
              heading = (
                <TranslateText
                  textKey={"recount_quantity"}
                  namespace="manageStocks"
                />
              );
            return (
              <div className="w-[20%] h-full flex items-center pl-[20px]">
                <span className="font-medium text-gray-500 uppercase tracking-wider leading-[1rem] text-[0.9rem]">
                  {heading}
                </span>
              </div>
            );
          })}
        </div>

        <div className="max-h-[62vh] w-full relative overflow-y-scroll">
          {Array.isArray(filteredList) && filteredList?.length ? (
            filteredList?.map((obj, idx) => {
              const isLastRow = idx === list?.length - 1;

              const coulmnsData = {
                id: obj?.id,
                name: obj?.name,
                category: isRestaurant ? obj?.type : obj?.category,
                unit: obj?.unit,
                price: obj?.price,
                quantity: isRestaurant ? obj?.stockCount : obj?.stock,
                total:
                  obj?.price * (isRestaurant ? obj?.stockCount : obj?.stock),
              };
              const { id, name, category, unit, price, quantity, total } =
                coulmnsData;

              // finding if the current item/ingredient is added in restock list or not
              const isRecount = recountItems?.hasOwnProperty(id);

              return (
                <div
                  key={id}
                  className={`w-full h-[45px] bg-[#fff] flex items-center border-b-[#00000040] border-b-[1px] ${
                    isLastRow && "rounded-b-[10px]"
                  }`}
                >
                  <div className="w-[20%] h-full flex items-center pl-[20px]">
                    <span className="font-nunito font-[500] text-[15px] leading-[21px] text-[#4D4D4D]">
                      {Boolean(name) ? name : "-"}
                    </span>
                  </div>

                  <div className="w-[20%] h-full flex items-center pl-[20px]">
                    <span className="font-nunito font-[500] text-[15px] leading-[21px] text-[#4D4D4D]">
                      {Boolean(category) ? category : "-"}
                    </span>
                  </div>

                  <div className="w-[20%] h-full flex items-center pl-[20px]">
                    <span className="font-nunito font-[500] text-[15px] leading-[21px] text-[#4D4D4D]">
                      {Boolean(unit) ? unit : "-"}
                    </span>
                  </div>

                  <div className="w-[20%] h-full flex items-center pl-[20px]">
                    <span className="font-nunito font-[500] text-[15px] leading-[21px] text-[#4D4D4D]">
                      {Boolean(price) ? CURRENCY_FORMAT(price) : 0}
                    </span>
                  </div>

                  <div className="w-[20%] h-full flex items-center pl-[20px]">
                    <Input
                      className="w-[50%]"
                      value={
                        isRecount ? recountItems?.[id]?.newQuantity : quantity
                      }
                      onChange={(e) =>
                        handleChangeQuantity(id, "input", e.target.value, obj)
                      }
                    />
                  </div>
                </div>
              );
            })
          ) : searchString || categoryFilter ? (
            <p className="ml-[20px] pt-[150px] font-[300] font-nunito">
              {`No ${
                isRestaurant ? "ingredients" : "items"
              } present for the selected filters. Please click here to `}
              <span
                className="cursor-pointer text-[#0000EE]"
                onClick={() => {
                  setSearchString("");
                  setCategoryFilter("");
                }}
              >
                <TranslateText textKey={"reset"} namespace="manageStocks" />
              </span>{" "}
              <TranslateText textKey={"filters"} namespace="manageStocks" />
            </p>
          ) : (
            <p className="ml-[20px] pt-[150px] font-[300] font-nunito">
              {`No ${isRestaurant ? "ingredients" : "items"} present. `}
            </p>
          )}
        </div>
      </div>
      <div className="w-[35%] px-[20px] h-full bg-[#f4f4f4] shadow-right relative">
        {Object.keys(recountItems)?.length ? (
          <div className="w-full h-full">
            <p className="pt-[20px] font-nunito text-[18px] leading-[27px] text-[#4d4d4d]">
              <TranslateText
                textKey={"review_recount"}
                namespace="manageStocks"
              />
            </p>
            <div className="mt-[30px] max-h-[42vh]">
              <div className="flex gap-[6px]">
                <div className="w-full h-[40px] flex">
                  <div className="h-full w-[35%] flex items-center justify-start pl-[10px]">
                    <span className="font-nunito text-[14px] leading-[21px] text-[#4d4d4d]">
                      <TranslateText
                        textKey={"item_name"}
                        namespace="manageStocks"
                      />
                    </span>
                  </div>
                  <div className="h-full w-[20%] flex items-center justify-start">
                    <span className="font-nunito text-[14px] leading-[21px] text-[#4d4d4d]">
                      <TranslateText
                        textKey={"previous"}
                        namespace="manageStocks"
                      />
                    </span>
                  </div>
                  <div className="h-full w-[20%] flex items-center justify-start">
                    <span className="font-nunito text-[14px] leading-[21px] text-[#4d4d4d]">
                      <TranslateText textKey={"new"} namespace="manageStocks" />
                    </span>
                  </div>
                  <div className="h-full w-[25%] flex items-center justify-start">
                    <span className="font-nunito text-[14px] leading-[21px] text-[#4d4d4d]">
                      <TranslateText
                        textKey={"difference"}
                        namespace="manageStocks"
                      />
                    </span>
                  </div>
                </div>
                <div className="invisible">
                  <CrossIcon />
                </div>
              </div>

              <div className="max-h-[42vh] overflow-y-scroll">
                {Object.values(recountItems)?.map((obj) => {
                  const { id, name, newQuantity } = obj;
                  const quantity = isRestaurant ? obj?.stockCount : obj?.stock;

                  return (
                    <div
                      key={id}
                      className="flex h-[33px] items-center gap-[6px] w-full mb-[10px]"
                    >
                      <div className="w-full h-[33px] bg-[#fff] flex">
                        <div className="h-full w-[35%] flex items-center justify-start pl-[10px]">
                          <span className="font-nunito text-[14px] leading-[21px] text-[#4d4d4d]">
                            {name}
                          </span>
                        </div>
                        <div className="h-full w-[20%] flex items-center justify-start">
                          <span className="font-nunito text-[14px] leading-[21px] text-[#4d4d4d]">
                            {quantity}
                          </span>
                        </div>
                        <div className="h-full w-[20%] flex items-center justify-start">
                          <span className="font-nunito text-[14px] leading-[21px] text-[#4d4d4d]">
                            {newQuantity}
                          </span>
                        </div>
                        <div className="h-full w-[25%] flex items-center justify-start">
                          <span className="font-nunito text-[14px] leading-[21px] text-[#4d4d4d]">
                            {FOLIO_FORMAT(newQuantity - quantity)}
                          </span>
                        </div>
                      </div>
                      <div>
                        <CrossIcon
                          className="cursor-pointer"
                          onClick={() =>
                            handleChangeQuantity(id, "delete", null, obj)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex w-full gap-[30px] absolute bottom-[15px] left-0 px-[20px]">
              <button
                className="w-[50%] rounded-[10px] h-[48px] bg-[#fff] flex items-center justify-center cursor-pointer combined-shadows"
                onClick={() => setRecountItems({})}
                disabled={loading}
              >
                <span className="font-[600] text-[18px] leading-[18px] text-[#007FFF]">
                  <TranslateText textKey={"clear"} namespace="manageStocks" />
                </span>
              </button>
              <button
                className="w-[50%] rounded-[10px] h-[48px] bg-[#007FFF] flex items-center justify-center cursor-pointer combined-shadows"
                onClick={handleSave}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#ffffff" }} size={22} />
                ) : (
                  <span className="font-[600] text-[18px] leading-[18px] text-[#FFF]">
                    <TranslateText textKey={"save"} namespace="manageStocks" />
                  </span>
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="h-full w-full flex items-center justify-center">
            <div className="flex flex-col items-center gap-[20px]">
              <EmptyPreview />
              <p className="font-nunito font-[600] text-[28px] leading-[42px] text-[#d9d9d9]">
                <TranslateText
                  textKey={"recount_details"}
                  namespace="manageStocks"
                />
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Recount;
