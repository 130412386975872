import TextField from "@mui/material/TextField";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";

const CustomTextField = ({
  label,
  isMandatory,
  value,
  onChange,
  type,
  validators,
  errorMessages,
  handleKeyPress,
  handleOnBlur,
  inputTextColor,
  labelTextColor,
  inputPadding,
  placeholder,
  inputRef,
  inputPaddingValue = "5px",
  labelFontSize,
  inputTextFontSize,
  labelTracking,
  height,
  isInputAdornmentAllowed = false,
  Icon,
}) => {
  return (
    <TextField
      ref={inputRef} // Renamed from 'ref' to 'inputRef' to avoid conflict with the reserved 'ref' prop
      label={label}
      name={label}
      id={label}
      placeholder={placeholder}
      validators={validators}
      errorMessages={errorMessages}
      size="small"
      onChange={(e) => onChange(e.target.value)}
      onBlur={handleOnBlur}
      value={value || ""}
      inputProps={{
        autoComplete: "new-password",
        form: { autoComplete: "off" },
        name: "random_name_" + Math.random().toString(36).substring(2, 10),
      }}
      autoComplete="off"
      type={type}
      required={isMandatory}
      onKeyDown={handleKeyPress}
      InputProps={{
        ...(isInputAdornmentAllowed && {
          endAdornment: (
            <InputAdornment position="end">
              <div className="bg-[#007FFF1A] w-[18px] h-[18px] rounded-[50%] flex justify-center items-center">
                <Icon className="text-[#007FFF]" />
              </div>
            </InputAdornment>
          ),
        }),
      }}
      sx={{
        width: "100%",
        "& label": {
          color: labelTextColor || "#808080",
          fontFamily: "outfit !important",
          padding: inputPadding ? inputPaddingValue : "",
          fontSize: labelFontSize || "0.75rem",
          letterSpacing: labelTracking || "",
        },
        "& input": {
          fontFamily: "outfit",
          fontSize: inputTextFontSize || "0.75rem",
          fontWeight: "400",
          color: inputTextColor || "#4D4D4D",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          padding: inputPadding ? inputPaddingValue : "",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height, // Applied height directly
        },
        "& .MuiInputLabel-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "outfit",
          color: labelTextColor || "#808080",
          fontSize: labelFontSize || "0.75rem",
        },
        "& .MuiInputLabel-shrink": {
          fontSize: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "outfit",
        },
      }}
    />
  );
};

export default CustomTextField;
