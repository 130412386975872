import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { TranslateText } from "../../../utils/translation";
import {
  updateDateOfIssue,
  updateDateOfExpire,
  updateDisplayPrimaryGuestDateIssue,
  updateDisplayPrimaryGuestDateExpire,
  updateDisplayPrimaryGuestDateArrival,
  updateDisplayPrimaryGuestDateDeparture,
} from "../../../reducer/bookingReducer";

const DateOfIssueExpire = () => {
  const dispatch = useDispatch();
  const {
    selectedDateOfIssue,
    selectedDateOfExpire,
    displayPrimaryGuestDateIssue,
    displayPrimaryGuestDateExpire,
  } = useSelector((store) => store.booking);

  const dateFormat = "YYYY/MM/DD";

  const onChangeDateOfIssue = (date, dateString) => {
    dispatch(
      updateDateOfIssue({
        dateOfIssue: dateString,
      })
    );
    dispatch(updateDisplayPrimaryGuestDateIssue(false));
  };

  const onChangeDateOfExpire = (date, dateString) => {
    dispatch(
      updateDateOfExpire({
        dateOfExpire: dateString,
      })
    );
    dispatch(updateDisplayPrimaryGuestDateExpire(false));
  };

  return (
    <div
      className=" flex  gap-5 w-[100%]  justify-between "
      onClick={(e) => e.stopPropagation()}
    >
      <div className="border border-gray-400 w-[50%] rounded-lg">
        <div className="flex  items-center  cursor-pointer relative px-2">
          <div
            className="flex"
            onClick={() => {
              dispatch(updateDisplayPrimaryGuestDateExpire(false));
              dispatch(updateDisplayPrimaryGuestDateArrival(false));
              dispatch(updateDisplayPrimaryGuestDateDeparture(false));
              dispatch(
                updateDisplayPrimaryGuestDateIssue(
                  !displayPrimaryGuestDateIssue
                )
              );
            }}
          >
            <h1 className="flex px-1  absolute top-[-8px] left-2 bg-[#ffffff]">
              <span className=" text-[0.625rem] text-[#808080] font-outfit font-[300] ">
                <TranslateText textKey={"date_of_issue"} namespace="booking" />
              </span>
            </h1>
            <div className="pl-1 pr-[5rem] py-1 mt-[4px] ">
              <span className="font-outfit  text-[0.75rem] text-[#4D4D4D] font-[400] ">
                {selectedDateOfIssue
                  ? moment(selectedDateOfIssue).format("D/MM/YYYY")
                  : moment().subtract(18, "year").format("D/MM/YYYY")}
              </span>
            </div>

            <div onClick={(e) => e.stopPropagation()}>
              <DatePicker
                className="invisibles"
                allowClear={false}
                onChange={onChangeDateOfIssue}
                value={dayjs(
                  Boolean(selectedDateOfIssue)
                    ? selectedDateOfIssue
                    : moment().subtract(18, "year").format("YYYY/MM/DD")
                )}
                style={{
                  borxder: "none",
                  width: "0",
                  height: "0",
                  padding: "0",
                }}
                suffixIcon={<></>}
                format={dateFormat}
                open={displayPrimaryGuestDateIssue}
                placement="bottomLeft"
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border border-gray-400  w-[50%] rounded-lg ">
        <div className=" flex items-center px-2 cursor-pointer relative">
          <div
            className="flex "
            onClick={() => {
              dispatch(updateDisplayPrimaryGuestDateIssue(false));
              dispatch(updateDisplayPrimaryGuestDateArrival(false));
              dispatch(updateDisplayPrimaryGuestDateDeparture(false));
              dispatch(
                updateDisplayPrimaryGuestDateExpire(
                  !displayPrimaryGuestDateExpire
                )
              );
            }}
          >
            <h1 className="flex  absolute top-[-8px] left-2 bg-[#ffffff]">
              <span className="text-[0.60rem] font-outfit text-[#00000080] px-[2px] mr-1 ">
                <TranslateText textKey={"date_of_expire"} namespace="booking" />
              </span>
            </h1>
            <div className="font-outfit pl-1 pr-[5rem] py-1 mt-[4px] text-[#404040] ">
              <span className="font-outfit  text-[0.75rem] text-[#4D4D4D] font-[400] ">
                {selectedDateOfExpire
                  ? moment(selectedDateOfExpire).format("D/MM/YYYY")
                  : moment().format("D/MM/YYYY")}
              </span>
            </div>

            <div onClick={(e) => e.stopPropagation()}>
              <DatePicker
                allowClear={false}
                onChange={onChangeDateOfExpire}
                style={{
                  border: "none",
                  width: "0",
                  height: "0",
                  padding: "0",
                }}
                value={dayjs(
                  Boolean(selectedDateOfExpire)
                    ? selectedDateOfExpire
                    : moment().format("YYYY/MM/DD")
                )}
                suffixIcon={<></>}
                format={dateFormat}
                open={displayPrimaryGuestDateExpire}
                size="small"
              />
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default DateOfIssueExpire;
