import moment from "moment";
import React from "react";
import {
  FOLIO_FORMAT,
  INVOICE_DATE_AND_TIME_FORMAT,
} from "../../../utils/helper";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { useSelector } from "react-redux";

const InvoiceStatement = (props) => {
  const { hotelDetails, hotelContentDetails } = useSelector(
    (store) => store.login
  );

  const showBankDataInInvoice = Boolean(hotelDetails?.showBankDetailsInInvoice);
  const { banksResponse } = useSelector((store) => store.bank);
  const bankDetails = Array.isArray(banksResponse)
    ? banksResponse?.filter((Obj) => Boolean(Obj?.defaultBankName))?.[0]
    : {};
  const qrCodUrl = bankDetails?.qrCodeUrl;

  const cancellationPolicyArray = Array.isArray(
    hotelContentDetails?.cancellationPoliciesText
  )
    ? hotelContentDetails?.cancellationPoliciesText?.slice(0, 3)
    : [];

  const { checkInTime, checkOutTime } = hotelDetails;
  const { response } = useSelector((store) => store.posSetting);

  const isPrint = props?.print;
  const isDownloadPdf = props?.download;
  let targetRef = isPrint ? props?.val : props?.targetRef;

  const invoiceId = props?.invoiceId;
  const customerObject = props?.customerDetails;
  const bookingObject = props?.bookingDetails;
  const paymentArray = Array.isArray(props?.paymentDetails)
    ? props?.paymentDetails
    : [];
  const invoiceArray = props?.invoiceDetails;
  const roomDetails = props?.roomDetails;

  const customerSection = [
    {
      title: "Name",
      value: Boolean(customerObject?.name) ? customerObject?.name : null,
    },
    {
      title: "Phone No",
      value: Boolean(customerObject?.phone) ? customerObject?.phone : null,
    },
    {
      title: "Email",
      value: Boolean(customerObject?.email) ? customerObject?.email : null,
    },
    {
      title: "Company Name",
      value: Boolean(customerObject?.company) ? customerObject?.company : null,
    },
    {
      title: "GSTIN",
      value: Boolean(customerObject?.gstIN) ? customerObject?.gstIN : null,
    },
    {
      title: "Address ",
      value: Boolean(customerObject?.address) ? customerObject?.address : null,
    },
  ];

  const bankSection = [
    {
      title: "Account Holder Name",
      value: Boolean(bankDetails?.accountHolderName)
        ? bankDetails?.accountHolderName?.toUpperCase()
        : null,
    },
    {
      title: "Bank Account Number",
      value: Boolean(bankDetails?.accountNumber)
        ? bankDetails?.accountNumber
        : null,
    },
    {
      title: "Bank Name",
      value: Boolean(bankDetails?.bankName)
        ? bankDetails?.bankName?.toUpperCase()
        : null,
    },
    {
      title: "IFSC Code",
      value: Boolean(bankDetails?.ifscCode)
        ? bankDetails?.ifscCode?.toUpperCase()
        : null,
    },
  ];

  const hotelInformation = [
    {
      title: Boolean(hotelDetails && hotelDetails?.name)
        ? hotelDetails?.name
        : null,
      Logo: hotelDetails?.logo,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.address)
        ? hotelDetails?.address
        : null,
      Logo: IoLocationSharp,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.contactNumber)
        ? hotelDetails?.contactNumber
        : null,
      Logo: FaPhoneAlt,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.emailAddress)
        ? hotelDetails?.emailAddress
        : null,
      Logo: MdEmail,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.website)
        ? hotelDetails?.website
        : null,
      Logo: TbWorld,
    },
  ];

  const statementTitleInformation = [
    {
      title: "Statement",
    },
    {
      title: Boolean(invoiceId) ? invoiceId : "",
    },
    {
      title: Boolean(hotelDetails?.gstIN)
        ? `GSTIN: ${hotelDetails?.gstIN}`
        : "",
    },
  ];

  let bookingSection = Boolean(props?.bookingDetails)
    ? [
        {
          title: "Room No / Plan",
          value: Boolean(roomDetails)
            ? `${roomDetails?.name} X ${roomDetails?.type} / ${bookingObject?.mealPlan}`
            : null,
        },
        {
          title: "Adult-Child",
          value: Boolean(bookingObject?.adults)
            ? `${bookingObject?.adults} - ${bookingObject?.children} `
            : null,
        },
        {
          title: "Check-in/Check-out",
          value: Boolean(bookingObject?.checkIn && bookingObject?.checkOut)
            ? `${moment(bookingObject?.checkIn)?.format(
                "D-MM-YYYY"
              )} | ${moment(bookingObject?.checkOut)?.format("D-MM-YYYY")}`
            : null,
        },
        {
          title: "Booking Source",
          value: Boolean(bookingObject?.source) ? bookingObject?.source : null,
        },
      ]
    : [];

  const priceBreakupHead = [
    { title: "DATE" },
    { title: "Bill/ Receipt" },
    { title: "Description" },
    { title: "Debit" },
    { title: "Credit" },
  ];

  let combinedPriceBreakup = [];

  let combinedArray = [...invoiceArray, ...paymentArray];

  combinedArray.sort((a, b) => {
    const dateA = a.createdAt || a.updatedAt || a.invoiceBilledDate || 0;
    const dateB = b.createdAt || a.updatedAt || b.invoiceBilledDate || 0;
    return dateA - dateB;
  });

  Array.isArray(combinedArray) &&
    combinedArray?.forEach((mainObj) => {
      // Check weather array is invoice or payment
      if (
        !mainObj?.hasOwnProperty("amount") &&
        !mainObj?.hasOwnProperty("paymentId") &&
        !mainObj?.hasOwnProperty("paymentNumber") &&
        mainObj?.hasOwnProperty("invoiceType")
      ) {
        let invoice = mainObj;

        let posName =
          invoice?.invoiceType === "pos"
            ? response
                ?.filter((Obj) => Obj?.id === invoice?.posId)?.[0]
                ?.name?.substring(0, 15)
            : "";
        let obj = {
          date: Boolean(invoice?.invoiceBilledDate)
            ? INVOICE_DATE_AND_TIME_FORMAT(invoice?.invoiceBilledDate)
                .formattedDate
            : "-",
          billVoucher: Boolean(invoice?.invoiceId)
            ? invoice?.invoiceId
            : "Tentative",
          description: Boolean(invoice?.invoiceType)
            ? Boolean(posName)
              ? `${invoice?.invoiceType?.toUpperCase()} (${posName})`
              : `${invoice?.invoiceType?.toUpperCase()}`
            : "-",
          debit: Boolean(invoice?.total) ? invoice?.total?.toFixed(2) : "-",
          credit: "N/A",
        };
        combinedPriceBreakup?.push(obj);
      } else {
        let payment = mainObj;
        let obj = {
          date: Boolean(payment.createdAt)
            ? INVOICE_DATE_AND_TIME_FORMAT(payment?.createdAt).formattedDate
            : "-",
          billVoucher: payment?.paymentId,
          description: Boolean(payment?.amount < 0)
            ? "Refund"
            : Boolean(payment?.method)
            ? `${payment?.method?.toUpperCase()}  ${
                Boolean(payment?.transactionNo)
                  ? `(${payment?.transactionNo})`
                  : ""
              } `
            : "-",
          debit: payment?.amount < 0 ? -payment?.amount : "N/A",
          credit: payment?.amount < 0 ? "N/A" : payment?.amount,
        };
        combinedPriceBreakup?.push(obj);
      }
    });

  let paymentReceived = 0;
  let totalBill = 0;
  let totalRefund = 0;
  let balanceDue = 0;

  Array.isArray(invoiceArray) &&
    invoiceArray
      ?.map((Obj) => Obj?.total)
      ?.filter((Obj) => Boolean(Obj))
      ?.map((total) => (totalBill += total));

  Array.isArray(paymentArray) &&
    paymentArray
      ?.map((obj) => obj.amount)
      ?.filter((Obj) => Obj < 0)
      .map((obj) => (totalRefund += -obj));

  Array.isArray(paymentArray) &&
    paymentArray
      ?.map((obj) => obj.amount)
      ?.filter((obj) => Boolean(obj) && obj > 0)
      ?.map((obj) => (paymentReceived += obj));

  balanceDue = totalBill - paymentReceived - totalRefund;

  let combinedPriceBreakupStartIndexAfter25 =
    combinedPriceBreakup?.length > 25 ? 26 : null;
  let combinedPriceBreakupEndIndexAfter25 =
    combinedPriceBreakup?.length > 26 ? combinedPriceBreakup?.length : null;

  return (
    <div
      className={`p-7  flex flex-col gap-[2rem] ${
        isDownloadPdf ? "absolute top-[400vh]   h-[100%] w-[60%] " : ""
      }  h-[100%] `}
      ref={targetRef}
    >
      <header className="flex justify-between">
        <div className="leftSection-hotelInformation flex flex-col gap-2 ">
          {hotelInformation?.map((Obj, index) => {
            return index === 0 ? (
              <div className=" flex gap-2 items-center">
                <div>
                  {Boolean(hotelDetails?.logo) ? (
                    <div className="w-[30px] h-[30px] rounded-[4px]">
                      <img
                        src={hotelDetails?.logo}
                        className="w-[100%] h-[100%] rounded-[4px]"
                        alt=""
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="font-outfit text-[#000] font-[600] text-[14px] leading-[21px]">
                  {hotelDetails?.name}
                </div>
              </div>
            ) : (
              Boolean(Obj?.title) && (
                <div className="flex gap-1 items-center" key={Obj.title}>
                  <div className="bg-[#EBEBEB] rounded-[100%] p-[2px]">
                    <Obj.Logo className="text-[#4D4D4D] text-[10px]" />
                  </div>
                  <div className="font-outfit text-[#1A1C21] font-[400] text-[10px] leading-[15px]">
                    {Obj?.title}
                  </div>
                </div>
              )
            );
          })}
        </div>

        <div className="rightSection-PaymentTitleInformation flex flex-col items-end">
          {statementTitleInformation?.map((Obj, index) => {
            return (
              <div
                className={` ${
                  index === 0
                    ? " text-[#000] font-[600] text-[20px] leading-[30px]"
                    : " text-[#4D4D4D] font-[400] text-[10px] leading-[15px]"
                } font-outfit`}
              >
                {Obj?.title}
              </div>
            );
          })}
        </div>
      </header>

      <section className="flex justify-between">
        <div className=""></div>
        <span className="h-[3rem] px-3 py-2 paymentInformation bg-[#7B8794] flex gap-3 justify-center items-center">
          <div className="flex flex-col gap-1 justify-start items-start">
            <div className="font-outfit font-[400] text-[8px] leading-[10.08px] text-[#FFF] uppercase">
              Total
            </div>
            <div className="font-outfit font-[500] text-[12px] leading-[15.12px] text-[#FFF]">
              &#8377;{FOLIO_FORMAT(totalBill)}
            </div>
          </div>

          <div className="w-[2px] h-[25px] text-[#FFF] bg-[#FFF]"></div>

          <div className="flex flex-col gap-1">
            <div className="font-outfit font-[400] text-[8px] leading-[10.08px] text-[#FFF] uppercase">
              Paid
            </div>
            <div className="font-outfit font-[500] text-[12px] leading-[15.12px] text-[#FFF]">
              &#8377;{FOLIO_FORMAT(paymentReceived)}
            </div>
          </div>

          <div className="w-[1px] h-[25px] text-[#FFF] bg-[#FFF]"></div>

          {Boolean(totalRefund) && (
            <>
              {" "}
              <div className="flex flex-col gap-1">
                <div className="font-outfit font-[400] text-[8px] leading-[10.08px] text-[#FFF] uppercase">
                  Refund
                </div>
                <div className="font-outfit font-[500] text-[12px] leading-[15.12px] text-[#FFF]">
                  &#8377;{FOLIO_FORMAT(totalRefund)}
                </div>
              </div>
              <div className="w-[1px] h-[25px] text-[#FFF] bg-[#FFF]"></div>{" "}
            </>
          )}

          <div className="flex flex-col gap-1">
            <div className="font-outfit font-[400] text-[8px] leading-[10.08px] text-[#FFF] uppercase">
              Due
            </div>
            <div className="font-outfit font-[500] text-[12px] leading-[15.12px] text-[#FFF]">
              &#8377;{FOLIO_FORMAT(balanceDue)}
            </div>
          </div>
        </span>
      </section>

      <section className="flex w-[100%] gap-[2rem] flex-col justify-center">
        <div className="billToSection flex flex-col gap-2">
          <div className="flex gap-2 w-[100%] items-center">
            <div className="font-outfit font-[500] text-[14px] w-[5%] whitespace-nowrap leading-[17.64px] text-[#000]">
              Bill To :
            </div>
            <div className="border-[#EBEBEB] border-[1px] h-[1px] w-[90%] mx-2"></div>
          </div>

          <div className="flex w-[100%] ">
            <div className="w-[50%] ">
              {customerSection?.map((Obj, index) => {
                return (
                  Boolean(Obj?.value) && (
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-2 w-[100%]">
                        <div className="font-outfit font-[400] text-[10px] leading-[18px] w-[39%] text-[#000]">
                          {Obj?.title}
                        </div>
                        <div className="font-outfit font-[500] text-[10px] leading-[18px] w-[1%] text-[#000]">
                          :
                        </div>
                        <div
                          className={` index === 0 ? "text-[#000]" : "text-[#4D4D4D]" } font-outfit font-[500] text-[10px] leading-[18px] w-[60%] `}
                        >
                          {Obj?.value}
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>

            {bookingSection?.length > 0 && (
              <div className="w-[50%]  flex flex-col  ">
                {bookingSection?.map((Obj, index) => {
                  return (
                    Boolean(Obj?.value) && (
                      <div className="flex flex-col gap-2  w-[80%]">
                        <div className="flex gap-2 w-[100%] justify-end self-end">
                          <div className="font-outfit font-[400] text-[10px] leading-[18px] w-[35%] whitespace-nowrap">
                            {Obj?.title}
                          </div>
                          <div className="font-outfit font-[500] text-[10px] leading-[18px] w-[1%]">
                            :
                          </div>
                          <div className="font-outfit font-[500] text-[10px] leading-[18px] w-[30%] whitespace-nowrap ">
                            {Obj?.value}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </section>

      <main className="priceBreakupSection">
        <div className="w-[100%] h-[1px] border-[#717171] border-[0.5px]" />
        <div className="flex justify-between w-[100%] my-2 px-4 py-2">
          {priceBreakupHead?.map((Obj, index) => {
            return (
              <div
                className={` ${
                  index === 0 || index === 1
                    ? "w-[20%]"
                    : index === 2
                    ? "w-[30%]"
                    : "w-[15%]"
                } font-inter font-[600] text-[10px] leading-[12.1px] tracking-wide text-[#000] uppercase`}
              >
                {Obj?.title}
              </div>
            );
          })}
        </div>
        <div className="w-[100%] h-[1px] border-[#717171] border-[0.5px]" />

        <div className="body w-[100%]">
          {combinedPriceBreakup?.slice(0, 35)?.map((Obj) => {
            return (
              <div className="flex justify-between px-4 py-2">
                <div className="w-[20%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                  {Obj?.date}
                </div>
                <div className="w-[20%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                  {Boolean(Obj?.billVoucher) ? Obj?.billVoucher : "Tentative"}
                </div>
                <div className="w-[30%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                  {Obj?.description}
                </div>
                <div className="w-[15%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                  {Obj?.debit}
                </div>
                <div className="w-[15%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                  {Obj?.credit}
                </div>
              </div>
            );
          })}
        </div>
      </main>

      {combinedPriceBreakup?.length > 35 && (
        <>
          <div className="page-break my-5" />
          <div className="body w-[100%]">
            {combinedPriceBreakup
              ?.slice(
                combinedPriceBreakupStartIndexAfter25,
                combinedPriceBreakupEndIndexAfter25
              )
              ?.map((Obj, index) => {
                return (
                  <div className="flex justify-between px-4 py-2">
                    <div className="w-[20%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                      {Obj?.date}
                    </div>
                    <div className="w-[20%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                      {Boolean(Obj?.billVoucher)
                        ? Obj?.billVoucher
                        : "Tentative"}
                    </div>
                    <div className="w-[30%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                      {Obj?.description}
                    </div>
                    <div className="w-[15%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                      {Obj?.debit}
                    </div>
                    <div className="w-[15%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                      {Obj?.credit}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}

      {showBankDataInInvoice ? (
        <div
          className="bankDetailsSection w-[100%] flex flex-col justify-start items-start"
          style={{
            break: true,
            pageBreakInside: "avoid",
            marginTop: "auto",
          }}
        >
          <div className="w-[50%] ">
            {bankSection?.map((Obj, index) => {
              return (
                Boolean(Obj?.value) && (
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 w-[100%]">
                      <div className="font-outfit font-[400] text-[8px] leading-[18px] w-[18ch] text-[#000]">
                        {Obj?.title} :
                      </div>
                      <div
                        className={` index === 0 ? "text-[#000]" : "text-[#4D4D4D]" } font-outfit font-[500] text-[8px] 
                        leading-[18px] w-[60%] tracking-[wider] `}
                      >
                        {Obj?.value}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>

          <div>
            {Boolean(qrCodUrl) ? (
              <img src={qrCodUrl} className="w-[150px] h-[150px]" alt="" />
            ) : (
              <> </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <footer
        style={{
          break: true,
          pageBreakInside: "avoid",
          marginTop: "auto",
        }}
        className={` ${
          isDownloadPdf ? "mt-[]" : ""
        }  footer absolute bottom-0  w-[100%]   font-[600] text-[11px] leading-[14px] px-[30px] mb-[1rem]  break-avoid content-to-print mt-5`}
      >
        <div className="mb-[15px]">
          <p className="font-outfit font-[500] text-[8px] leading-[13px]">
            Disclaimer: Checkin time {checkInTime} & Checkout time{" "}
            {checkOutTime} Noon | T&C Applied
          </p>

          <div className="flex flex-col gap-1">
            {cancellationPolicyArray?.map((Obj) => {
              return (
                <p className="font-outfit font-[500] text-[8px] leading-[13px]">
                  {Obj}
                </p>
              );
            })}
          </div>
        </div>

        <div className="border-[#AEAEAE] bprder-[0.6px] w-[100%]"></div>

        <div className="flex justify-between w-[100%] items-center">
          <p className="font-outfit  font-[500] text-[8px] leading-[10.08px]">
            Powered By <span className="text-[#2F80ED]">revivo</span>{" "}
            <span className="text-[#007FFF] ">HOTEL-X</span>
          </p>
          <p className="text-[#000000B2] font-outfit font-[400] text-[8px]">
            This is a computer generated Invoice, No Signature Needed
          </p>
        </div>
      </footer>
    </div>
  );
};

export default InvoiceStatement;
