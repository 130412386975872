import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../reducer/loginReducer";
import userItemsReducer from "../reducer/userItemsReducer";
import addItemReducer from "../reducer/addItemReducer";
import getItemReducer from "../reducer/getItemReducer";
import editItemReducer from "../reducer/editItemReducer";
import reviewOrderReducer from "../reducer/reviewOrderReducer";
import getAllOrdersReducer from "../reducer/getAllOrdersReducer";
import singleSelectedOrderReducer from "../reducer/singleSelectedOrderReducer";
import orderReducer from "../reducer/orderReducer";
import parkedOrderReducer from "../reducer/parkedOrderReducer";
import customerReducer from "../reducer/customerReducer";
import recountReducer from "../reducer/manageStocksReducer/recountReducer";
import bookingReducer from "../reducer/bookingReducer";
import roomsReducer from "../reducer/roomsReducer";
import channelManagerReducer from "../reducer/channelManagerReducer";
import walkinReducer from "../reducer/walkinReducer";
import alertReducer from "../reducer/alertReducer";
import suppliersReducer from "../reducer/manageStocksReducer/suppliersReducer";
import categoriesReducer from "../reducer/manageStocksReducer/categoriesReducer";
import unitsReducer from "../reducer/manageStocksReducer/unitsReducer";
import posReducer from "../reducer/posReducer/restaurant";
import appReducer from "../reducer/appReducer";
import invoiceAndPaymentReducer from "../reducer/invoiceAndPaymentReducer";
import clientsReducer from "../reducer/clientsReducer";
import editClientModelReducer from "../reducer/editClientModelReducer";
import reportReducer from "../reducer/reportReducer";
import dashboardReducer from "../reducer/dashboardReducer";
import uploadReducer from "../reducer/uploadReducer";
import BookingReservationReducer from "../reducer/bookingReservationReducer";
import ingredientsReducer from "../reducer/ingredientsReducer";
import reservationReducer from "../reducer/reservationReducer";
import posSettingReducer from "../reducer/posReducer/posSettingReducer";
import newBookingDetailsReducer from "../reducer/newBookingDetailsReducer";
import grcReducer from "../reducer/grcReducer";
import ratesAndAvailabilityReducer from "../reducer/ratesAndAvailabilityReducer";
import usersRoleReducer from "../reducer/usersRole";
import housekeepingReducer from "../reducer/housekeepingReducer";
import settingsReducer from "../reducer/settingsReducer";
import channelManagerSettingsReducer from "../reducer/channelManagerSettingsReducer";
import appHeaderReducer from "../reducer/appHeaderReducer";
import paymentReducer from "../reducer/paymentReducer";
import partyReducer from "../reducer/partyReducer";
import bankReducer from "../reducer/bankReducer";
import expenseReducer from "../reducer/expenseReducer";
import staffReducer from "../reducer/staffReducer";
import invoiceReducer from "../reducer/invoiceReducer";
import roomTypeReducer from "../reducer/roomTypeReducer";
import usersRestrictionReducer from "../reducer/userRestriction";
import partyTransactionsReducer from "../reducer/partyTransactionsReducer";
import manageStocksReducer from "../reducer/manageStocksReducer";
import taxReducer from "../reducer/taxReducer";
import addOnReducer from "../reducer/addOnReducer";

const store = configureStore({
  reducer: {
    app: appReducer,
    login: loginReducer,
    userItem: userItemsReducer,
    addItems: addItemReducer,
    getItems: getItemReducer,
    editItem: editItemReducer,
    reviewOrder: reviewOrderReducer,
    order: orderReducer,
    allOrders: getAllOrdersReducer,
    singleOrder: singleSelectedOrderReducer,
    parkOrder: parkedOrderReducer,
    customers: customerReducer,
    recount: recountReducer,
    booking: bookingReducer,
    rooms: roomsReducer,
    channelManager: channelManagerReducer,
    walkin: walkinReducer,
    alert: alertReducer,
    suppliers: suppliersReducer,
    categories: categoriesReducer,
    units: unitsReducer,
    pos: posReducer,
    invoiceAndPayment: invoiceAndPaymentReducer,
    clients: clientsReducer,
    editClientModel: editClientModelReducer,
    report: reportReducer,
    dashboard: dashboardReducer,
    upload: uploadReducer,
    bookingReservation: BookingReservationReducer,
    ingredients: ingredientsReducer,
    tableReservation: reservationReducer,
    posSetting: posSettingReducer,
    newBookingDetails: newBookingDetailsReducer,
    grc: grcReducer,
    ratesAndAvailability: ratesAndAvailabilityReducer,
    usersRole: usersRoleReducer,
    housekeeping: housekeepingReducer,
    settings: settingsReducer,
    cmSettings: channelManagerSettingsReducer,
    appHeader: appHeaderReducer,
    payments: paymentReducer,
    party: partyReducer,
    bank: bankReducer,
    expense: expenseReducer,
    staff: staffReducer,
    invoice: invoiceReducer,
    roomType: roomTypeReducer,
    usersRestriction: usersRestrictionReducer,
    partyTransactions: partyTransactionsReducer,
    manageStocks: manageStocksReducer,
    tax: taxReducer,
    addOns : addOnReducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
