import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postOccupancyBasedPlanRate } from "../../reducer/loginReducer";

const useGetMealPlanRate = (roomPriceStructure, mealPlan) => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);
  const roomTypes = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];

  useEffect(() => {
    getPlanRate(roomTypes, roomPriceStructure, mealPlan);
  }, [roomTypes, roomPriceStructure?.map((Obj) => Obj.rate).join(",")]);

  const getPlanRate = async (
    currentRoomTypeArray,
    currentRoomPriceStructure,
    currenMealPlan
  ) => {
    if (
      hotelDetails?.id &&
      Array.isArray(currentRoomPriceStructure) &&
      currentRoomPriceStructure?.length > 0 &&
      Array.isArray(currentRoomTypeArray) &&
      currentRoomTypeArray?.length > 0
    ) {
      const roomTypeIdByPrice = currentRoomTypeArray?.reduce((acc, obj) => {
        let rate = currentRoomPriceStructure?.find(
          (currentObj) =>
            currentObj?.type === obj?.name || currentObj?.name === obj?.name
        )?.rate;
        rate = rate ?? obj?.rate;

        acc[obj?._id] = +rate ?? 0;
        return acc;
      }, {});

      const payload = {
        hotelId: hotelDetails?.id,
        planOfPrice: Boolean(currenMealPlan) ? currenMealPlan : "EP",
        roomTypeIdByPrice: roomTypeIdByPrice,
      };
      dispatch(postOccupancyBasedPlanRate(payload));
    }
  };

  return { getPlanRate };
};

export default useGetMealPlanRate;
