import ManageStocksMainPage from "../../components/manageStocksV2/ManageStocksMainPage";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const { loggedInUserPermissions } = useSelector(
    (store) => store.usersRestriction
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedInUserPermissions?.includes("OWNER")) navigate("/dashboard");
  }, []);

  return (
    <div>
      <ManageStocksMainPage />
    </div>
  );
};

export default Index;
