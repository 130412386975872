import moment from "moment";
import { Progress } from "antd";

const GuestIdStructure2 = ({
  val,
  print,
  download,
  roomToGuest,
  response,
  showMainBookingDetailsDrawer,
  bookingDetails,
  selectedRoomId,
  selectedGuestIdx,
  guestIdLength,
  totalGuest,
}) => {
  const isDownloadPdf = Boolean(download) ? download : false;

  const filteredRooms = Object.entries(roomToGuest || {})?.filter((room) =>
    Boolean(selectedRoomId) ? room[0] === selectedRoomId : true
  );

  return (
    <main
      className={` flex flex-col gap-8 ${
        isDownloadPdf
          ? "absolute left-[-5000px] right-0 top-[-200vh] w-full"
          : ""
      }`}
      ref={val}
    >
      <header className="w-full h-[140px] bg-[#FBFBFB] border-b-[2px] border-b-[#F2F4FC] flex justify-between items-center px-[24px] mb-[0px]">
        <div className="flex items-start gap-[34px]">
          <div className="flex flex-col gap-[6px]">
            <p className="font-outfit text-[16px] leading-[24px] font-[500] text-[#222b45]">
              Booking Id:{" "}
              <span className="font-outfit text-[16px] leading-[24px] font-[600] text-[#222b45]">
                {showMainBookingDetailsDrawer.newBookingId}
              </span>
            </p>
            <p className="font-outfit text-[14px] leading-[24px] font-[500] text-[#222b45] text-left">
              Source:{" "}
              <span className="font-outfit text-[14px] leading-[24px] font-[600] text-[#007FFF]">
                {bookingDetails?.source?.toUpperCase()}
              </span>
            </p>
          </div>
          <div></div>
        </div>
        <div className="flex gap-[34px] items-start">
          <p className="font-outfit text-[14px] leading-[24px] font-[500] text-[#222b45] text-left">
            Check-In Date:{" "}
            <span className="font-outfit text-[14px] leading-[24px] font-[600] text-[#007FFF]">
              {moment(bookingDetails?.checkIn).format("DD-MM-YYYY")}
            </span>
          </p>{" "}
        </div>
      </header>

      <section className="flex flex-col gap-[24px] mt-[-30px]">
        {filteredRooms?.map((room, roomIdx) => {
          const [roomId, guestArray] = room || [];
          return (
            <div className="w-full flex flex-col gap-[24px]">
              {roomIdx === 0 && <p className="w-full h-[13px]"></p>}
              {guestArray
                ?.filter((_, idx) =>
                  Boolean(typeof selectedGuestIdx === "number")
                    ? idx === selectedGuestIdx
                    : true
                )
                ?.map((guest, guestIdx) => {
                  const { guestName, idImages } = guest || {};
                  return (
                    <div className="flex items-center justify-start gap-[12px] px-[14px]">
                      {idImages?.length ? (
                        <div className="flex gap-[12px] items-center">
                          <div className="flex gap-[12px] items-center">
                            <span></span>
                            <div className="flex items-center justify-center rounded-[12px] bg-[#8F9BB31A] w-[112px] h-[50px]">
                              <span className="font-outfit font-[600] text-[18px] leading-[22.68px] text-[#222B45]">
                                {Boolean(roomId)
                                  ? response?.find(({ id }) => id === roomId)
                                      ?.name
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="w-[274px] h-[56px]">
                            <input
                              className="w-full h-full border-[1px] border-[#EDF1F7] rounded-[12px] outline-none px-[15px] font-outfit text-[16px] leading-[24px] tracking-[0.5px] text-[#616161]"
                              //   placeholder="Guest name"
                              value={guestName}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* {idImages?.length ? (
                        <div className="w-[274px] flex gap-3 items-center mb-[16px]">
                          <div className="flex items-center justify-center rounded-lg bg-[#8F9BB31A] w-[112px] h-[50px]">
                            <span className="font-outfit font-semibold text-lg text-[#222B45]">
                              {Boolean(roomId)
                                ? response?.find(({ id }) => id === roomId)
                                    ?.name
                                : ""}
                            </span>
                          </div>
                          <div className="flex-grow">
                            <div className="w-full h-[50px] border border-[#EDF1F7] rounded-lg px-4 flex items-center">
                              <span className="font-outfit text-base text-[#616161]">
                                {guestName}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                      <div className="flex gap-[12px] items-center justify-start">
                        {idImages?.slice(0, 2)?.map((image, index) => (
                          <div
                            key={index}
                            className="flex flex-col gap-[24px] items-start justify-start w-[147px] h-[101.22px] relative rounded-[8px] cursor-pointer"
                          >
                            <img
                              src={image}
                              alt="rect"
                              id="viewer"
                              className="w-[100%] h-[100%] rounded-[8px]"
                            />

                            <div className="rounded-[50%] flex justify-center items-center absolute top-[-6px] right-[3px] z-[10] cursor-pointer"></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </section>
    </main>
  );
};

export default GuestIdStructure2;
