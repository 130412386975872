import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authedAxios } from "../utils/customHttpHeader";
import {
  getAllItemsService,
  getItemByIdService,
  getItems,
  patchItem,
  postItem,
} from "../services/getItems";
import { message } from "antd";

export const getAllItems = createAsyncThunk(
  "getItems/getAllItems",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getItems(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const createItem = createAsyncThunk(
  "Items/createItem",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postItem(payload);
      dispatch(updateIsAddItemDrawerOpen(false));
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const updateItem = createAsyncThunk(
  "Items/updateItem",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await patchItem(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getItemById = createAsyncThunk(
  "Items/getItemById",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getItemByIdService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  itemData: [],
  error: null,

  // create item

  createLoading: false,
  createError: false,

  selectedItem: {
    category: "Starter",
    unit: "plate",
    type: "veg",
    active: true,
    addOns: [{ name: "", price: null }],
  }, // selected item for edit

  action: "", // action will define wather edit or add item

  // add category & unit

  isAddItem: false,
  itemName: "",
  isAddItemDrawerOpen: false,
};

const getItemsSlice = createSlice({
  name: "getItems",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_23: () => initialState,

    updateItemData: (state, action) => {
      const index = state.itemData.findIndex(
        (item) => item._id === action.payload.itemId
      );
      if (index !== -1) {
        state.itemData[index] = {
          ...state.itemData[index],
          count: action.payload.count,
        };
      }
    },

    updateIsAddItem: (state, action) => {
      state.isAddItem = action.payload;
    },
    updateItemName: (state, action) => {
      state.itemName = action.payload;
    },

    resetItem: (state, action) => {
      state.isAddItem = "";
      state.itemName = "";
    },

    updateSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    clearSelectedItem: (state) => {
      state.selectedItem = {};
    },

    updateAction: (state, action) => {
      state.action = action.payload;
    },

    updateAddonList: (state, action) => {
      let addonList = Boolean(state.selectedItem?.addOns)
        ? state.selectedItem?.addOns
        : [];
      if (action.payload.increase) {
        let addOn = [...addonList];
        addOn.push({ name: "", price: null });
        state.selectedItem.addOns = addOn;
      } else {
        let newAddonList = addonList;
        newAddonList.splice(action.payload.key, 1);
        state.selectedItem.addOns = newAddonList;
      }
    },
    updateIsAddItemDrawerOpen: (state, action) => {
      state.isAddItemDrawerOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllItems.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(getAllItems.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllItems.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
      state.data = [];
    });

    builder.addCase(createItem.pending, (state) => {
      state.createLoading = true;
      state.createError = null;
    });
    builder.addCase(createItem.fulfilled, (state, action) => {
      message.success("Item inserted successfully");
      state.data = [...state.data, action.payload];
      state.createLoading = false;
      state.createError = null;

      state.selectedItem = {};
    });
    builder.addCase(createItem.rejected, (state, action) => {
      state.createError = true;
      state.createLoading = false;
      message.error("Something went wrong");
    });

    builder.addCase(updateItem.pending, (state) => {
      state.createLoading = true;
      state.createError = null;
    });
    builder.addCase(updateItem.fulfilled, (state, action) => {
      message.success("Item updated successfully");
      let updatedItem = state.data.map((item) => {
        if (item.id === action.payload?.id) {
          return action.payload;
        }
        return item;
      });
      state.data = updatedItem;
      state.createLoading = false;
      state.createError = null;
    });
    builder.addCase(updateItem.rejected, (state, action) => {
      message.error("Something went wrong");
      state.createError = true;
      state.createLoading = false;
    });

    builder.addCase(getItemById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getItemById.fulfilled, (state, action) => {
      state.itemData = [...state.itemData, action.payload];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getItemById.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_23,
  updateItemData,
  updateSelectedItem,
  updateAction,
  updateAddonList,
  updateIsAddItem,
  updateItemName,
  resetItem,
  clearSelectedItem,
  updateIsAddItemDrawerOpen,
} = getItemsSlice.actions;
export default getItemsSlice.reducer;
