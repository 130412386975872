import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../assets/images/svgs/CloseIcon.svg";
import { CircularProgress } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  updateShowExtraOptionDrawer,
  updateBookingDetails,
} from "../../../reducer/bookingReducer";

import Drawer from "@mui/material/Drawer";
import { message } from "antd";
import CustomTextField from "../../common/InputField/Input";

export default function ExtraOptionDrawer() {
  const { showExtraOptionDrawer, bookingDetails } = useSelector(
    (store) => store.booking
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const initialOptions = {
    foodChoice: "",
    modeOfTravel: "",
    remarks: "",
    arrival: "",
    destination: "",
    purposeOfVisit: "",
    referenceNo: "",
  };

  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    if (bookingDetails) {
      setOptions({
        ...initialOptions, // Ensure to merge with initialOptions to avoid undefined properties
        foodChoice: bookingDetails?.foodChoice || "",
        modeOfTravel: bookingDetails?.modeOfTravel || "",
        remarks: bookingDetails?.remarks || "",
        arrival: bookingDetails?.arrival || "",
        destination: bookingDetails?.destination || "",
        purposeOfVisit: bookingDetails?.purposeOfVisit || "",
        referenceNo: bookingDetails?.referenceNo || "",
      });
    }
  }, [bookingDetails]);

  const onSave = async () => {
    setLoading(true);
    const payload = {
      ...bookingDetails,
      foodChoice: Boolean(options?.foodChoice) ? options?.foodChoice : null,
      modeOfTravel: Boolean(options?.modeOfTravel)
        ? options?.modeOfTravel
        : null,
      remarks: options?.remarks,
      arrival: options?.arrival,
      destination: options?.destination,
      purposeOfVisit: Boolean(options?.purposeOfVisit)
        ? options?.purposeOfVisit
        : null,
      referenceNo: options?.referenceNo,
    };

    await dispatch(updateBookingDetails(payload));
    message.success("Data Successfully Updated");
    dispatch(updateShowExtraOptionDrawer(false));
    setLoading(false);
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "800px",
          // height: "60vh",
          paddingBottom: "5rem",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          marginTop: "3rem",
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          //   position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={showExtraOptionDrawer}
      onClose={() => dispatch(updateShowExtraOptionDrawer(false))}
    >
      <div className=" flex flex-col gap-5 mx-4 w-[95%]">
        <div className="flex mb-2  mt-2  w-[100%] justify-between items-center ">
          <h2 className="font-[400] text-[0.875rem] text-[#4D4D4D] font-nunito">
            Add ons
          </h2>

          <div>
            <img
              src={CloseIcon}
              alt=""
              style={{
                height: "1rem",
                width: "1rem",
              }}
              onClick={() => dispatch(updateShowExtraOptionDrawer(false))}
              className="cursor-pointer"
            />
          </div>
        </div>

        <div className="flex w-[100%] gap-4 justify-between  text-[15px] ">
          <FormControl
            sx={{
              width: "100%",
              color: "#0000004D",
              "& .MuiSelect-icon": {
                color: "#2F80ED",
              },
            }}
            size="small"
          >
            <InputLabel
              id="sourceInputLabel"
              sx={{
                // color: "#808080",
                fontSize: "0.75rem",
                fontWeight: "300",
                fontFamily: "nunito",
              }}
            >
              Food Choice
            </InputLabel>
            <Select
              labelId="bookingSourceSelectLabel"
              id="bookingSource"
              label="Source"
              sx={{
                width: "100%",
                borderRadius: "6px",
                color: "#808080",
                fontSize: "0.75rem",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
              value={options?.foodChoice || ""}
              onChange={(e) =>
                setOptions((prevOptions) => ({
                  ...prevOptions,
                  foodChoice: e.target.value,
                }))
              }
            >
              <MenuItem
                value="veg"
                sx={{
                  fontSize: "14px",
                  color: "#00000098",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                }}
              >
                Veg
              </MenuItem>

              <MenuItem
                value="nonVeg"
                sx={{
                  fontSize: "14px",
                  color: "#00000098",
                  fontFamily: "Poppins",
                }}
              >
                Non Veg
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            sx={{
              width: "100%",
              color: "#0000004D",
              "& .MuiSelect-icon": {
                color: "#2F80ED",
              },
            }}
            size="small"
          >
            <InputLabel
              id="sourceInputLabel"
              sx={{
                // color: "#808080",
                fontSize: "0.75rem",
                fontWeight: "300",
                fontFamily: "nunito",
              }}
            >
              Mode of Travel
            </InputLabel>
            <Select
              labelId="bookingSourceSelectLabel"
              id="bookingSource"
              label="Source"
              sx={{
                width: "100%",
                borderRadius: "6px",
                color: "#808080",
                fontSize: "0.75rem",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
              value={options?.modeOfTravel ? options?.modeOfTravel : ""}
              onChange={(e) => {
                setOptions((prevOptions) => ({
                  ...prevOptions,
                  modeOfTravel: e.target.value || null,
                }));
              }}
            >
              <MenuItem
                value="car"
                sx={{
                  fontSize: "14px",
                  color: "#00000098",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                }}
              >
                Car
              </MenuItem>

              <MenuItem
                value="train"
                sx={{
                  fontSize: "14px",
                  color: "#00000098",
                  fontFamily: "Poppins",
                }}
              >
                Train
              </MenuItem>

              <MenuItem
                value="bus"
                sx={{
                  fontSize: "14px",
                  color: "#00000098",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                }}
              >
                Bus
              </MenuItem>

              <MenuItem
                value="flight"
                sx={{
                  fontSize: "14px",
                  color: "#00000098",
                  fontFamily: "Poppins",
                }}
              >
                Flight
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <CustomTextField
          label="Remarks"
          isManadatory={false}
          value={options?.remarks}
          onChange={(value) => setOptions({ ...options, remarks: value })}
          type="text"
        />

        <div className="flex  w-[100%] gap-4 justify-center">
          <CustomTextField
            label="Arriving From"
            isManadatory={false}
            value={options?.arrival}
            onChange={(value) => setOptions({ ...options, arrival: value })}
            type="text"
          />
          <CustomTextField
            label="Destination"
            isManadatory={false}
            value={options?.destination}
            onChange={(value) => setOptions({ ...options, destination: value })}
            type="text"
          />
        </div>

        <FormControl
          sx={{
            width: "100%",
            color: "#0000004D",
            "& .MuiSelect-icon": {
              color: "#2F80ED",
            },
          }}
          size="small"
        >
          <InputLabel
            id="sourceInputLabel"
            sx={{
              // color: "#808080",
              fontSize: "0.75rem",
              fontWeight: "300",
              fontFamily: "nunito",
            }}
          >
            Purpose of Travel
          </InputLabel>
          <Select
            labelId="bookingSourceSelectLabel"
            id="bookingSource"
            label="Source"
            sx={{
              width: "100%",
              borderRadius: "6px",
              color: "#808080",
              fontSize: "0.75rem",
              fontWeight: "400",
              fontFamily: "Poppins",
            }}
            value={options?.purposeOfVisit ? options?.purposeOfVisit : ""}
            onChange={(e) =>
              setOptions({ ...options, purposeOfVisit: e.target.value })
            }
          >
            <MenuItem
              value="medical"
              sx={{
                fontSize: "14px",
                color: "#00000098",
                fontFamily: "Poppins",
                fontWeight: 400,
              }}
            >
              Medical
            </MenuItem>

            <MenuItem
              value="tourism"
              sx={{
                fontSize: "14px",
                color: "#00000098",
                fontFamily: "Poppins",
              }}
            >
              Tourism
            </MenuItem>

            <MenuItem
              value="business"
              sx={{
                fontSize: "14px",
                color: "#00000098",
                fontFamily: "Poppins",
                fontWeight: 400,
              }}
            >
              Business
            </MenuItem>

            <MenuItem
              value="other"
              sx={{
                fontSize: "14px",
                color: "#00000098",
                fontFamily: "Poppins",
              }}
            >
              Other
            </MenuItem>
          </Select>
        </FormControl>

        <CustomTextField
          label="Referred By"
          isManadatory={false}
          value={options?.referenceNo}
          onChange={(value) => setOptions({ ...options, referenceNo: value })}
          type="text"
        />

        {loading ? (
          <button
            onClick={onSave}
            className="text-[#FFF] py-3 bg-[#007FFF] capitalize absolute right-0 bottom-0 flex justify-center w-[100%] text-[1.125rem] font-[600] mx-auto"
            disabled={true}
          >
            <CircularProgress size={24} color="inherit" />
          </button>
        ) : (
          <button
            onClick={onSave}
            className="text-[#FFF] py-3 bg-[#007FFF] capitalize absolute right-0 bottom-0 flex justify-center w-[100%] text-[1.125rem] font-[600] mx-auto"
          >
            Save Changes
          </button>
        )}
      </div>
    </Drawer>
  );
}
