import { authedAxios } from "../utils/customHttpHeader";

export const getHotelDashboardService = async (payload) => {
  const { hotelId, startDate, endDate, hotelIds } = payload;
  let queryString;

  if (hotelId) {
    queryString = `dashboard?hotelId=${hotelId}`;
  } else {
    queryString = `dashboard?hotelIds=${hotelIds}`;
  }

  if (startDate) queryString += `&startDate=${startDate}`;
  if (endDate) queryString += `&endDate=${endDate}`;

  const response = await authedAxios().get(queryString);
  return response;
};
