import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { uploadFiles } from "../services/scanUpload";

export const upload = createAsyncThunk("upload", async (payload, action) => {
  try {
    const response = await uploadFiles(payload);
    return response;
  } catch (error) {
    return null;
  }
});

const initialState = {
  loading: false,
  response: [],
  apiError: false,
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_43: () => initialState,
    removeFile: (state, action) => {
      const url = action.payload;
      state.response = state.response.filter((item) => item !== url);
    },
    resetFile: (state, action) => {
      state.response = [];
    },
    updateFile: (state, action) => {
      state.response = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(upload.pending, (state, action) => {
      state.loading = true;
      state.apiError = false;
    });
    builder.addCase(upload.fulfilled, (state, action) => {
      let files = Boolean(state.response) ? state.response : [];
      state.loading = false;
      state.apiError = false;
    });
    builder.addCase(upload.rejected, (state, action) => {
      state.loading = false;
      state.apiError = true;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_43,
  removeFile,
  resetFile,
  updateFile,
  updateLoading,
} = uploadSlice.actions;
export default uploadSlice.reducer;
