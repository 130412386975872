import React from "react";
import { TextField } from "@mui/material";

export const Input = ({
  label,
  value,
  handleChange,
  name,
  className,
  type,
}) => {
  return (
    <TextField
      inputProps={{
        style: {
          height: "1rem",
        },
      }}
      sx={{
        width: "100%",
        background: "#ffff",
        "& label": {
          color: "#808080",
          fontFamily: "Nunito",
          fontSize: ".9rem",
        },

        "& input": {
          fontFamily: "Poppins",
          fontSize: ".9rem",
          fontWeight: "400",
          color: "#4D4D4D",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
        },
        "& .MuiInputLabel-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "nunito",
          fontSize: ".9rem",
          color: "#808080",
        },
      }}
      type={type}
      className={className}
      label={label}
      value={value}
      onChange={handleChange}
      name={name}
      variant="outlined"
      fullWidth
      margin="normal"
    />
  );
};
