import React, { useRef, useState } from "react";
import Heading from "./Heading";
import ReservationTable from "./components/ReservationTable";
import { useReactToPrint } from "react-to-print";
import Footer from "./components/Footer";
import { useDispatch } from "react-redux";
import {
  updateDisplayCustomCalendar,
  updateDisplayFilter,
} from "../../reducer/bookingReservationReducer";

const BookingReservationContainer = () => {
  const [print, setPrint] = useState(false);
  const dispatch = useDispatch();

  const componentRef = useRef(null);
  const printPDF = useReactToPrint({
    onAfterPrint: () => setPrint(false),
    content: () => componentRef.current,
    documentTitle: "BookingReservation",
  });

  const handleOnClick = () => {
    dispatch(updateDisplayCustomCalendar(false));
    dispatch(updateDisplayFilter(false));
  };

  return (
    <div
      className="bg-[#F2F4FC] min-h-[100vh] h-[100%] w-[100%]"
      onClick={handleOnClick}
    >
      <Heading />

      <div className="bg-[#F2F4FC] mt-[7.5rem] w-full max-h-full pt-[24px]  px-[24px] h-[40rem] overflow-y-auto relative">
        <div ref={componentRef}>
          <ReservationTable />
        </div>
      </div>

      <Footer
        handlePrint={printPDF}
        showPrint={print}
        setShowPrint={setPrint}
      />
    </div>
  );
};

export default BookingReservationContainer;
