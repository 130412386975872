import BookingReservationContainer from "../../components/BookingReservationV2/BookingReservationContainer";
import React, { useEffect } from "react";
import {
  updateDates,
  updateDisplayCustomCalendar,
  updateSelectedCheckInDate,
  updateSelectedCheckOutDate,
  updateDisplayFilter,
  updateSetFilterTitle,
} from "../../reducer/bookingReservationReducer";

import { useDispatch } from "react-redux";
import useStoreBookingDetailsLogic from "../../hooks/bookingCustomHook/useStoreBookingDetails";
import moment from "moment";

const BookingReservationPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        updateDates({
          from: moment().startOf("day"),
        })
      );
      dispatch(
        updateDates({
          to: moment().add(6, "day"),
        })
      );
      dispatch(updateSelectedCheckInDate(""));
      dispatch(updateSelectedCheckOutDate(""));

      dispatch(updateDisplayCustomCalendar(false));

      dispatch(updateDisplayFilter(false));
      dispatch(updateSetFilterTitle("Week"));
    };
  }, []);

  useStoreBookingDetailsLogic();

  return <BookingReservationContainer />;
};

export default BookingReservationPage;
