import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import {
  updateDisplayPrimaryGuestDoa,
  updateDisplayPrimaryGuestDob,
} from "../../../reducer/bookingReducer";
import { TranslateText } from "../../../utils/translation";
const DoaAndDob = ({ doa, setDoa, dob, setDob }) => {
  const dateFormat = "YYYY/MM/DD";
  const dispatch = useDispatch();
  const { displayPrimaryGuestDoa, displayPrimaryGuestDob } = useSelector(
    (store) => store.booking
  );

  const onChangeDob = (date, dateString) => {
    setDob(dateString);
    dispatch(updateDisplayPrimaryGuestDob(false));
  };

  const onChangeDoa = (date, dateString) => {
    setDoa(dateString);
    dispatch(updateDisplayPrimaryGuestDoa(false));
  };

  return (
    <div
      className=" flex  gap-5 w-[100%]  justify-between "
      onClick={(e) => e.stopPropagation()}
    >
      <div className="border border-gray-400  w-[50%] rounded-lg ">
        <div className=" flex items-center px-2 cursor-pointer relative">
          <div
            className="flex "
            onClick={() => {
              dispatch(updateDisplayPrimaryGuestDoa(false));
              dispatch(updateDisplayPrimaryGuestDob(!displayPrimaryGuestDob));
            }}
          >
            <h1 className="flex px-1  absolute top-[-8px] left-2 bg-[#ffffff]">
              <span className=" text-[0.625rem] text-[#808080] font-outfit font-[300] ">
                <TranslateText textKey={"dob"} namespace="booking" />
              </span>
            </h1>
            <div className="pl-1 pr-[5rem] py-1 mt-[4px] ">
              <span className="font-outfit  text-[0.75rem] text-[#4D4D4D] font-[400] ">
                {dob
                  ? moment(dob).format("D/MM/YYYY")
                  : moment().subtract(18, "year").format("D/MM/YYYY")}
              </span>
            </div>

            <div onClick={(e) => e.stopPropagation()}>
              <DatePicker
                className="invisibles"
                allowClear={false}
                onChange={onChangeDob}
                value={dayjs(
                  Boolean(dob)
                    ? dob
                    : moment().subtract(18, "year").format("YYYY/MM/DD")
                )}
                style={{
                  borxder: "none",
                  width: "0",
                  height: "0",
                  padding: "0",
                }}
                suffixIcon={<></>}
                format={dateFormat}
                open={displayPrimaryGuestDob}
                placement="bottomLeft"
                size="small"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="border border-gray-400  w-[50%] rounded-lg ">
        <div className=" flex items-center px-2 cursor-pointer relative">
          <div
            className="flex "
            onClick={() => {
              dispatch(updateDisplayPrimaryGuestDob(false));
              dispatch(updateDisplayPrimaryGuestDoa(!displayPrimaryGuestDoa));
            }}
          >
            <h1 className="flex  absolute top-[-8px] left-2 bg-[#ffffff]">
              <span className="text-[0.60rem] font-outfit text-[#00000080] px-[2px] mr-1 hidden lg:block">
                <TranslateText
                  textKey={"date_of_anniversary"}
                  namespace="booking"
                />
              </span>
              <span className="text-[0.60rem] font-outfit text-[#00000080] mr-1  lg:hidden">
                <TranslateText textKey={"doa"} namespace="booking" />
              </span>
            </h1>
            <div className="font-outfit pl-1 pr-[5rem] py-1 mt-[4px] text-[#404040] ">
              <span className="font-outfit  text-[0.75rem] text-[#4D4D4D] font-[400] ">
                {doa
                  ? moment(doa).format("D/MM/YYYY")
                  : moment().format("D/MM/YYYY")}
              </span>
            </div>

            <div onClick={(e) => e.stopPropagation()}>
              <DatePicker
                allowClear={false}
                onChange={onChangeDoa}
                style={{
                  border: "none",
                  width: "0",
                  height: "0",
                  padding: "0",
                }}
                value={dayjs(
                  Boolean(doa) ? doa : moment().format("YYYY/MM/DD")
                )}
                suffixIcon={<></>}
                format={dateFormat}
                open={displayPrimaryGuestDoa}
                size="small"
              />
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default DoaAndDob;
