import { useDispatch, useSelector } from "react-redux";
import {
  updateBookingListData,
  updateBookingDetails,
  updateInvoiceDetails,
  updateShowMainBookingDetailsDrawer,
  updateActiveTab,
  updateDisplayCheckInDrawer,
  updateShowGrcDrawer,
  updateShowFolioDrawer,
  updateShowRefundPaymentDrawer,
  postPaymentAndUpdateInvoiceDetails,
  updateShowAttachToPartyDrawer,
  updateAttachPartyString,
  updateAttachPartyValue,
  updateDisplayCheckOutDrawer,
  updateShowApplyDiscountDrawer,
  updateMobileBookingDetailsId,
  updateDisplayDeletePartyModal,
  updatePaymentDetails,
  updateDisplayCancelBookingModal,
  resetBookingDetails,
} from "../../reducer/bookingReducer";
import { updateBookingListData as updateReservationCalendarBookingListData } from "../../reducer/bookingReservationReducer";
import {
  bookingDetailsAddOnsCalculation,
  bookingPriceCalculation,
} from "../../utils/bookingHelper";

import { updateinvoice, postPayment } from "../../services/invoice";

import { message } from "antd";
import { updatePayment, deletePayment } from "../../reducer/paymentReducer";
import { updateActiveFooter } from "../../reducer/appHeaderReducer";

const useAllBookingFunction = (setLoading) => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);
  const { bookingResponse: bookingReservationResponse } = useSelector(
    (store) => store.bookingReservation
  );

  const {
    showMainBookingDetailsDrawer,
    bookingDetails,
    invoiceDetails,
    paymentDetails,
    detailedPriceBreakup,
    selectedRoomWTypeAndNum,
    roomPriceStructure,
    response: bookingResponse,
    nightsCount,
  } = useSelector((store) => store.booking);

  const roomTypes = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];
  const mandatoryCheckInDetailsDisabled =
    hotelDetails?.mandatoryCheckInDetailsDisabled;

  const bookingId = showMainBookingDetailsDrawer?.bookingId;
  const bookingType = showMainBookingDetailsDrawer?.bookingType;

  const handleCheckIn = async () => {
    setLoading(true);

    const arrival = Boolean(bookingDetails?.arrival)
      ? bookingDetails?.arrival
      : "";
    const destination = Boolean(bookingDetails?.destination)
      ? bookingDetails?.destination
      : "";
    const purposeOfVisit = Boolean(bookingDetails?.purposeOfVisit)
      ? bookingDetails?.purposeOfVisit
      : "";

    if (
      (Boolean(arrival) && Boolean(destination) && Boolean(purposeOfVisit)) ||
      (Boolean(mandatoryCheckInDetailsDisabled) &&
        mandatoryCheckInDetailsDisabled === true)
    ) {
      const updatedData = JSON.parse(JSON.stringify(bookingDetails));

      updatedData.status = "checkedIn";

      const data = await dispatch(updateBookingDetails(updatedData));

      const bookings = bookingReservationResponse?.bookings;

      if (!Boolean(data.error)) {
        let updatedBookingDetails = bookings?.map((Obj) =>
          Obj?.id === data?.payload?.id ? data?.payload : Obj
        );

        const newResponse = JSON.parse(
          JSON.stringify(bookingReservationResponse)
        );

        newResponse.bookings = updatedBookingDetails;

        await dispatch(updateReservationCalendarBookingListData(newResponse));

        dispatch(
          updateShowMainBookingDetailsDrawer({
            visible: false,
            bookingId: "",
            bookingType: "",
            newBookingId: "",
          })
        );
        dispatch(updateMobileBookingDetailsId(""));

        dispatch(updateActiveTab("ongoing"));
        dispatch(updateActiveFooter("Bookings"));

        setLoading(false);
        message.success("Successfully Checked In");
      } else {
        message.error(data.payload);
        setLoading(false);
      }
    } else {
      dispatch(updateDisplayCheckInDrawer());
      setLoading(false);
    }
  };

  const handleCheckOut = async () => {
    setLoading(true);
    const updatedData = JSON.parse(JSON.stringify(bookingDetails));

    updatedData.status = "completed";

    const bookings = bookingReservationResponse?.bookings;

    const data = await dispatch(updateBookingDetails(updatedData));

    if (Boolean(data.payload)) {
      let updatedBookingDetails = bookings?.map((Obj) =>
        Obj?.id === data?.payload?.id ? data?.payload : Obj
      );

      const newResponse = JSON.parse(
        JSON.stringify(bookingReservationResponse)
      );

      newResponse.bookings = updatedBookingDetails;

      await dispatch(updateReservationCalendarBookingListData(newResponse));

      dispatch(updateActiveTab("completed"));

      dispatch(updateMobileBookingDetailsId(""));

      message.success("Successfully Checked Out");
      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: showMainBookingDetailsDrawer?.newBookingId,
        })
      );
    } else {
      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: "",
        })
      );
      message.error("Something went wrong");
    }

    dispatch(updateActiveFooter("Bookings"));
    setLoading(false);
  };

  const handleUndoCheckIn = async () => {
    setLoading(true);

    const updatedData = JSON.parse(JSON.stringify(bookingDetails));

    updatedData.status = "confirmed";

    const data = await dispatch(updateBookingDetails(updatedData));
    const bookings = bookingReservationResponse?.bookings;

    if (data.payload) {
      let updatedBookingDetails = bookings?.map((Obj) =>
        Obj?.id === data?.payload?.id ? data?.payload : Obj
      );

      const newResponse = JSON.parse(
        JSON.stringify(bookingReservationResponse)
      );

      newResponse.bookings = updatedBookingDetails;

      dispatch(updateReservationCalendarBookingListData(newResponse));

      dispatch(updateActiveTab("upcoming"));

      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: showMainBookingDetailsDrawer?.newBookingId,
        })
      );
      dispatch(updateMobileBookingDetailsId(""));

      message.success("Booking Successfully Updated");
    } else {
      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: "",
        })
      );
      message.error("Something went wrong");
    }

    dispatch(updateActiveFooter("Bookings"));
    setLoading(false);
  };

  const handleConfirmBooking = async () => {
    setLoading(true);

    const updatedData = JSON.parse(JSON.stringify(bookingDetails));

    updatedData.status = "confirmed";

    await dispatch(updateBookingDetails(updatedData));
    dispatch(updateActiveTab("upcoming"));

    message.success("Booking Successfully Updated");

    dispatch(
      updateShowMainBookingDetailsDrawer({
        visible: false,
        bookingId: "",
        bookingType: "",
        newBookingId: showMainBookingDetailsDrawer?.newBookingId,
      })
    );

    dispatch(updateActiveFooter("Bookings"));
    dispatch(updateMobileBookingDetailsId(""));
    setLoading(false);
  };

  const handleBookingToBeCanceld = async () => {
    setLoading(true);

    const updatedData = JSON.parse(JSON.stringify(bookingDetails));
    updatedData.status = "cancelled";

    await dispatch(updateBookingDetails(updatedData));

    dispatch(updateDisplayCancelBookingModal());

    const bookingList = bookingResponse?.bookings;

    const newBookingList = bookingList?.filter(
      (booking) => booking.id !== updatedData.id
    );

    const newResponse = { ...bookingResponse, bookings: newBookingList };

    dispatch(updateBookingListData(newResponse));
    dispatch(updateReservationCalendarBookingListData(newResponse));
    message.destroy();
    message.success("Booking Cancelled");

    dispatch(updateActiveFooter("Bookings"));
    dispatch(resetBookingDetails());
    setLoading(false);
  };

  const handleBookingToBeMarkedNoShow = async () => {
    setLoading(true);
    const updatedData = JSON.parse(JSON.stringify(bookingDetails));

    updatedData.status = "noShow";

    await dispatch(updateBookingDetails(updatedData));
    const bookingList = bookingResponse?.bookings;

    const newBookingList = bookingList?.filter(
      (booking) => booking.id !== updatedData.id
    );
    const newResponse = { ...bookingResponse, bookings: newBookingList };
    dispatch(updateBookingListData(newResponse));
    dispatch(updateReservationCalendarBookingListData(newResponse));

    message.destroy();
    message.success("Booking marked as No Show");

    dispatch(updateActiveFooter("Bookings"));
    dispatch(resetBookingDetails());
    setLoading(false);
  };

  const handleFolio = () => {
    dispatch(
      updateShowMainBookingDetailsDrawer({
        visible: "false",
        bookingId: bookingId,
        bookingType: bookingType,
        newBookingId: showMainBookingDetailsDrawer?.newBookingId,
      })
    );
    dispatch(updateShowFolioDrawer(true));
  };

  const handleGrc = () => {
    dispatch(
      updateShowMainBookingDetailsDrawer({
        visible: "false",
        bookingId: bookingId,
        bookingType: bookingType,
        newBookingId: showMainBookingDetailsDrawer?.newBookingId,
      })
    );
    dispatch(updateShowGrcDrawer());
  };

  const refund = async (
    amount,
    setAmount,
    setReason,
    reason,
    paymentMode,
    methodToPartyId
  ) => {
    if (Boolean(amount)) {
      setLoading(true);

      let currentPaidValue = 0;
      let alreadyGivenRefund = 0;

      if (Array.isArray(paymentDetails)) {
        paymentDetails
          ?.map((Obj) => Obj?.amount)
          ?.filter((Obj) => Obj > 0)
          ?.map((Obj) => (currentPaidValue += Math.abs(Obj)));
      } else return false;

      if (Array.isArray(paymentDetails)) {
        paymentDetails
          ?.map((Obj) => Obj?.amount)
          ?.filter((Obj) => Obj < 0)
          ?.map((Obj) => (alreadyGivenRefund += Math.abs(Obj)));
      } else return false;

      const totalRefund = Math.abs(alreadyGivenRefund) + Math.abs(amount);

      if (totalRefund >= currentPaidValue) {
        message.info("Refund should not be greater than paid value");
        setAmount("");
        setReason("");

        setLoading(false);
        return false;
      }

      let roomInvoiceId = Array.isArray(invoiceDetails)
        ? invoiceDetails
            ?.filter((Obj) => Obj?.invoiceType === "roomBill")
            ?.map((Obj) => Obj?.id)?.[0]
        : "";

      const paymentPayload = {
        amount: -amount,
        bookingId: showMainBookingDetailsDrawer?.bookingId,
        hotelId: hotelDetails?.id,
        method: paymentMode,
        remarks: reason,
        invoiceId: roomInvoiceId,
        paymentModePartyId: methodToPartyId[paymentMode],
      };

      const invoiceIdArray = Array.isArray(invoiceDetails)
        ? invoiceDetails?.map((Obj) => Obj?.id)?.filter((Obj) => Boolean(Obj))
        : [];

      const invoicePayload = {
        hotelId: hotelDetails?.id,
        invoiceIds: invoiceIdArray,
      };

      const payload = {
        paymentPayload: paymentPayload,
        invoicePayload: invoicePayload,
      };

      dispatch(postPaymentAndUpdateInvoiceDetails(payload));

      dispatch(updateShowRefundPaymentDrawer());
      setAmount("");
      setReason("");

      setLoading(false);
    } else {
      message.info("Please enter amount");
    }
  };

  const handleAttachToParty = async (
    response,
    balanceDue,
    partyResponse,
    attachPartyValue,
    setLoading
  ) => {
    if (!partyResponse || !partyResponse?.length) {
      message.info("Please select party first");
      return;
    }

    setLoading(true);

    let roomInvoice = invoiceDetails?.find(
      (Obj) => Obj?.invoiceType === "roomBill"
    );

    let partyId = partyResponse?.find(
      (Obj) => Obj?.displayName === attachPartyValue
    )?.id;

    let updatedInvoice = JSON.parse(JSON.stringify(roomInvoice));
    updatedInvoice.partyId = partyId;

    const payload = {
      id: updatedInvoice?.id,
      hotelId: hotelDetails?.id,
      body: updatedInvoice,
    };

    const oldPayment = paymentDetails?.find((Obj) => Obj?.method === "btc");

    console.log(oldPayment);

    if (Boolean(oldPayment)) {
      const paymentPayload = {
        hotelId: hotelDetails?.id,
        message: "Updated",
        paymentObj: {
          ...oldPayment,
          paymentModePartyId: partyId,
        },
      };

      const data = await updateinvoice(payload);

      if (Boolean(data?.id)) {
        const updatedData = invoiceDetails?.map((item) => {
          if (item.id === data.id) {
            return data;
          } else return item;
        });

        const oldUpdatedPayment = await dispatch(updatePayment(paymentPayload));

        const res = await dispatch(updateInvoiceDetails(updatedData));

        if (res?.type) {
          const newPaymentArray = response?.payments?.map((Obj) =>
            Obj?.id === oldUpdatedPayment?.payload?.id
              ? oldUpdatedPayment?.payload
              : Obj
          );

          const oldUpdatedPaymentDetails = paymentDetails?.map((Obj) =>
            Obj?.id === oldUpdatedPayment?.payload?.id
              ? oldUpdatedPayment?.payload
              : Obj
          );
          const newResponse = { ...response, payments: newPaymentArray };

          dispatch(updatePaymentDetails(oldUpdatedPaymentDetails));
          dispatch(updateBookingListData(newResponse));

          dispatch(updateDisplayCheckOutDrawer(false));
          dispatch(updateShowAttachToPartyDrawer(false));
          dispatch(updateAttachPartyString(""));
          dispatch(updateAttachPartyValue(""));
        } else message.error("Attach to Party failed");
      }
    } else {
      const paymentPayload = {
        amount: balanceDue,
        bookingId: bookingDetails?.id,
        hotelId: hotelDetails?.id,
        invoiceId: updatedInvoice?.id,
        method: "btc",
        paymentModePartyId: partyId,
      };

      const data = await updateinvoice(payload);

      if (Boolean(data?.id)) {
        const updatedData = invoiceDetails?.map((item) => {
          if (item.id === data.id) return data;
          else return item;
        });

        const newPayment = await postPayment(paymentPayload);
        const res = await dispatch(updateInvoiceDetails(updatedData));

        if (res?.type) {
          const newPaymentArray = [...response.payments, newPayment];
          const newResponse = { ...response, payments: newPaymentArray };

          const updatedPaymentDetails = [...paymentDetails, newPayment];

          dispatch(updatePaymentDetails(updatedPaymentDetails));
          dispatch(updateBookingListData(newResponse));

          dispatch(updateDisplayCheckOutDrawer(false));
          dispatch(updateShowAttachToPartyDrawer(false));

          message.destroy();
          message.success("Party updated");

          dispatch(updateAttachPartyString(""));
          dispatch(updateAttachPartyValue(""));
        } else message.error("Attach to Party failed");
      }
    }

    setLoading(false);
  };

  const handleDeletePayment = async (
    id,
    isBookingDetailsDrawer,
    displayMessage,
    isPosComponent,
    currentPosInvoice
  ) => {
    message.destroy();
    setLoading(true);

    try {
      if (!id) {
        message.info("Something went wrong");
        setLoading(false);
        return;
      }

      const paymentPayload = {
        hotelId: hotelDetails?.id,
        id: id,
      };

      const deletedPaymentResponse = await dispatch(
        deletePayment(paymentPayload)
      );

      if (!deletedPaymentResponse?.payload?.data) {
        message.info("Something went wrong");
        setLoading(false);
        return;
      }

      if (isBookingDetailsDrawer) {
        // Filter out the deleted payment from the response
        const newPaymentArray = bookingResponse?.payments?.filter(
          (Obj) => Obj?.id !== paymentPayload?.id
        );

        // Update the payment details in the local state
        const updatedPaymentDetails = paymentDetails?.filter(
          (Obj) => Obj?.id !== paymentPayload?.id
        );

        const newResponse = { ...bookingResponse, payments: newPaymentArray };

        dispatch(updatePaymentDetails(updatedPaymentDetails));
        dispatch(updateBookingListData(newResponse));
      }

      if (isPosComponent) {
      }

      message.destroy();
      message.success(displayMessage);

      setLoading(false);
      dispatch(
        updateDisplayDeletePartyModal({ display: false, partyName: "" })
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLoading(false);
      message.error("An error occurred while deleting the attached party");
    }
  };

  const updateInvoicePricesAsDiscount = async (
    discountType,
    discountValue,
    setInitialRender
  ) => {
    setLoading(true);

    const discountInfo = {
      type: discountType,
      value: discountValue,
    };

    let roomInvoice = Array.isArray(invoiceDetails)
      ? invoiceDetails?.find((Obj) => Obj?.invoiceType === "roomBill")
      : null;

    if (
      Boolean(roomInvoice) &&
      Array.isArray(detailedPriceBreakup) &&
      detailedPriceBreakup?.length > 0 &&
      Array.isArray(roomPriceStructure) &&
      roomPriceStructure?.length &&
      roomTypes?.length
    ) {
      let taxInclusionInPrice = roomInvoice?.taxIncludedInPrice;

      let taxObjectArray = roomInvoice?.taxObjects;

      let oldAddOnsArray = roomInvoice?.priceBreakup?.filter(
        (Obj) => Obj?.type === "Addons"
      );

      oldAddOnsArray = Array.isArray(oldAddOnsArray)
        ? oldAddOnsArray?.[0]?.addOns
        : [];

      const alreadyPresentTaxArray = Array.isArray(roomInvoice?.taxObjects)
        ? roomInvoice.taxObjects
        : [];

      const result = bookingPriceCalculation(
        selectedRoomWTypeAndNum,
        roomPriceStructure,
        roomTypes,
        nightsCount || 1,
        discountInfo,
        [],
        detailedPriceBreakup,
        Boolean(taxInclusionInPrice),
        false,
        alreadyPresentTaxArray
      );

      let oldAddOnsCalcResult = bookingDetailsAddOnsCalculation(
        oldAddOnsArray,
        taxObjectArray
      );

      const priceBreakup = roomInvoice?.priceBreakup;

      let requiredPriceBrekupForDiscount = detailedPriceBreakup
        ?.reduce((acc, room) => {
          const existingRoom = acc.find((item) => item.name === room.type);

          if (existingRoom) {
            existingRoom.rate += room.rate;
            existingRoom.count += 1;
          } else {
            acc.push({ name: room.type, rate: room.rate, count: 1 });
          }

          return acc;
        }, [])
        .map((obj) => ({
          name: obj.name,
          rate: obj.rate / obj.count, // Calculate average rate
          count: obj.count,
        }));

      let newPriceBreakup = priceBreakup?.map((Obj) => {
        let currentCmbinedPriceBrekup = requiredPriceBrekupForDiscount?.find(
          (currObj) => Obj?.type === currObj?.name
        );

        let currentdiscount =
          (Obj?.rate / result?.roomTotal) * result?.discount;

        let discountPerNight =
          currentdiscount / nightsCount / currentCmbinedPriceBrekup?.count;

        discountPerNight = Boolean(currentdiscount) ? currentdiscount : 0;

        let discountedRate = Obj?.rate - discountPerNight ?? 0;

        return {
          ...Obj,
          discountedRate: discountedRate,
        };
      });

      const newInvoiceDetails = JSON.parse(JSON.stringify(roomInvoice));

      // Removing existing Room Tax but keeping addOn Tax as it is
      newInvoiceDetails.totalTax =
        oldAddOnsCalcResult?.totalAddOnsTax + result?.totalRoomTax;

      newInvoiceDetails.subTotal = result?.subTotal;
      newInvoiceDetails.total =
        result?.total + oldAddOnsCalcResult?.addOnsTotal;

      newInvoiceDetails.discountInfo = discountInfo;
      newInvoiceDetails.discount = result?.discount;

      newInvoiceDetails.priceBreakup = newPriceBreakup;
      newInvoiceDetails.discountInfo = discountInfo;

      const payload = {
        hotelId: newInvoiceDetails?.hotelId,
        id: newInvoiceDetails?.id,
        body: newInvoiceDetails,
      };

      const data = await updateinvoice(payload);

      if (Boolean(data?.id)) {
        const updatedData = invoiceDetails?.map((item) =>
          Boolean(item.id === data?.id) ? data : item
        );

        message.success("Discount Applied");
        await dispatch(updateInvoiceDetails(updatedData));
        Boolean(setInitialRender) && setInitialRender(true);
        dispatch(updateShowApplyDiscountDrawer());
      } else message.info("Something Went Wrong");
    } else message.info("Something Went Wrong");

    setLoading(false);
  };

  return {
    handleCheckIn,
    handleCheckOut,
    handleUndoCheckIn,
    handleFolio,
    handleGrc,
    handleConfirmBooking,
    refund,
    handleAttachToParty,
    updateInvoicePricesAsDiscount,
    handleDeletePayment,
    handleBookingToBeCanceld,
    handleBookingToBeMarkedNoShow,
  };
};

export default useAllBookingFunction;
