import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, message, Popconfirm } from "antd";
import {
  deleteTable,
  updateSingleTableData,
  updatesShowingTableModelForEdit,
} from "../../../../reducer/posReducer/posSettingReducer";
import { updateTablesData } from "../../../../reducer/posReducer/restaurant";
import { TranslateText } from "../../../../utils/translation";

const EditTableModal = () => {
  const dispatch = useDispatch();
  const { hotelDetails } = useSelector((store) => store.login);
  const { visibleTableModalForEdit, singleTableData } = useSelector(
    (store) => store.posSetting
  );

  const {
    updateTableDataLoading,
    allTables: allTablesData,
    tableDataApiError: tableApiError,
  } = useSelector((store) => store.pos);

  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    let tableName = singleTableData?.name;

    let isTableExist =
      allTablesData?.map((item) => item.name)?.indexOf(tableName) === -1;

    if (isTableExist) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        let payload = {
          ...singleTableData,
          hotelId: hotelDetails?.id,
        };

        dispatch(updateTablesData(payload));
        dispatch(
          updatesShowingTableModelForEdit({
            modal: false,
          })
        );
      }, 1000);
    } else {
      message.info(`Table ${tableName} is already present!`);
    }
  };

  const getData = (e) => {
    dispatch(
      updateSingleTableData({ ...singleTableData, name: e.target.value })
    );
  };

  const confirm = () => {
    let payload = {
      id: singleTableData?.id,
      hotelId: hotelDetails?.id,
    };

    dispatch(deleteTable(payload));

    dispatch(
      updatesShowingTableModelForEdit({
        modal: false,
      })
    );
  };
  const cancel = (e) => {};

  return (
    <>
      <Modal
        title="Edit Table"
        width={300}
        open={visibleTableModalForEdit}
        onOk={handleOk}
        onCancel={() =>
          dispatch(
            updatesShowingTableModelForEdit({
              modal: false,
            })
          )
        }
        footer={[
          <Popconfirm
            title="Delete the Table"
            description={`Are you sure to delete ${singleTableData?.name} Table?`}
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
            okType="danger"
          >
            <Button danger>
            <TranslateText textKey={"delete"} namespace="pos" />

            </Button>
          </Popconfirm>,

          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
            className="bg-blue-400 w-[5rem]"
          >
            
            <TranslateText textKey={"save"} namespace="pos" />

          </Button>,
        ]}
      >
        <form className="w-full max-w-sm mt-[1.5rem]">
          <div className="flex items-center mb-6">
            <div className="">
              <label className="block text-gray-500  mb-1 pr-4">
              <TranslateText textKey={"table"} namespace="pos" />

              </label>
            </div>
            <div className="">
              <input
                className="bg-gray-100 appearance-none border-[1px] border-gray-300 rounded w-full py-2 px-4 text-gray-700 
                leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                placeholder="Enter Table name"
                type="text"
                name="name"
                onChange={getData}
                value={singleTableData?.name}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditTableModal;
