import { authedAxios } from "../utils/customHttpHeader";

const invoiceDetailsById = async (payload, endpoint) => {
  const queryString = `?hotelId=${payload.hotelId}`;
  try {
    const response = await authedAxios().get(
      endpoint + "/" + payload.id + queryString
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const allInvoices = async (payload, endpoint) => {
  let url = `?hotelId=${payload.hotelId}`;

  if (payload.startDate) url += `&startDate=${payload.startDate}`;

  if (payload.endDate) url += `&endDate=${payload.endDate}`;

  if (payload.statuses) url += `&statuses=${payload.statuses}`;

  if (Boolean(payload.tableId)) url += `&sources=${payload.tableId}`;

  if (Boolean(payload.pageNumber)) url += `&pageNumber=${payload.pageNumber}`;
  else url += `&pageNumber=${0}`;

  if (Boolean(payload.pageSize)) url += `&pageSize=${payload.pageSize}`;

  if (payload.searchText) url += `&searchText=${payload.searchText}`;

  if (payload.sortingDirection)
    url += `&sortingDirection=${payload.sortingDirection}`;

  if (payload.sortingField) url += `&sortingField=${payload.sortingField}`;

  if (payload.posId && payload.posId.length > 0)
    url += `&posId=${payload.posId}`;

  if (payload.invoiceIds) url += `&invoiceIds=${[payload.invoiceIds]}`;

  if (payload.invoiceTypeList && Array.isArray(payload.invoiceTypeList))
    url += `&invoiceTypeList=${[payload.invoiceTypeList]}`;

  const response = await authedAxios().get(
    `${payload.searchText ? "/invoices/searchByText" : "/invoices"}` + url
  );
  return response;
};

const updateinvoice = async (payLoad) => {
  const payload = { ...payLoad, version: "0" };

  const path = `/invoices/${payload.id}?hotelId=${payload.hotelId}`;
  try {
    const response = await authedAxios().put(path, payload.body);
    return response.data;
  } catch (error) {
    return null;
  }
};

const postPayment = async (payload, endpoint) => {
  const path = `/payments`;

  const response = await authedAxios().post(path, payload);

  return response.data;
};

const invoiceDeletion = async (payload) => {
  try {
    const response = await authedAxios().delete(
      `invoices/${payload.id}?hotelId=${payload.hotelId}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export {
  invoiceDetailsById,
  postPayment,
  updateinvoice,
  invoiceDeletion,
  allInvoices,
};
