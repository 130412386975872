import { useSelector, useDispatch } from "react-redux";

import Drawer from "@mui/material/Drawer";
import { CircularProgress } from "@mui/material";
import { message } from "antd";
import { UpdatePosSettingForm } from "../../../../reducer/posReducer/posSettingReducer";
import { IoCloseSharp } from "react-icons/io5";
import CustomTextField from "../../../../components/common/InputField/Input";
import { useState } from "react";

const AddStewardDrawer = ({
  addStewardDrawerVisible,
  setAddStewardDrawerVisible,
  setStewardDrawerVisible,
  selectedPos,
}) => {
  const { isExistingSteward } = useSelector((store) => store.settings);
  const { updateLoading } = useSelector((store) => store.posSetting);
  const [stewardName, setStewardName] = useState("");
  const dispatch = useDispatch();

  const handleAddSteward = async () => {
    if (!Boolean(isExistingSteward.length)) {
      let updatedStewardsName;

      if (!stewardName?.trim()?.length) {
        message.warning("Name field cannot be empty");
        return;
      }

      if (selectedPos?.hasOwnProperty("stewards")) {
        if (selectedPos?.stewards?.includes(stewardName.trim())) {
          message.warning("Steward already exists");
          return;
        }

        updatedStewardsName = [
          ...new Set([...selectedPos?.stewards, stewardName]),
        ];
      } else updatedStewardsName = [stewardName];

      const res = await dispatch(
        UpdatePosSettingForm({ ...selectedPos, stewards: updatedStewardsName })
      );
    } else {
      if (isExistingSteward === stewardName.trim()) {
        message.warning("Entered name is same as previous name");
        return;
      }
      const updatedStewardsName = selectedPos?.stewards?.map((name) =>
        name === isExistingSteward ? stewardName : name
      );
      const res = await dispatch(
        UpdatePosSettingForm({ ...selectedPos, stewards: updatedStewardsName })
      );
    }
    handleClose();
  };

  const handleClose = () => {
    setAddStewardDrawerVisible(false);
    setStewardDrawerVisible(true);
    setStewardName("");
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "100%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",
          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",
          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={addStewardDrawerVisible}
      onClose={handleClose}
    >
      <div className="no-scrollbar bg-[#F2F4FC] ">
        <div className="px-[15px] pt-[15px]">
          <div className="flex w-full justify-between items-center mb-[15px]">
            <p className="font-outfit font-[600] text-[16px] leading-[24px] text-[#222222]">
              Add new Steward
            </p>
            <div
              className="bg-[#4D4D4D] w-[16px] h-[16px] rounded-[50%] flex justify-center items-center "
              onClick={handleClose}
            >
              <IoCloseSharp className="text-[10px] text-[#FFF] " />
            </div>
          </div>
        </div>

        <main className=" w-[90%] mx-auto mt-[2rem]  mb-[8rem] flex flex-col gap-2 p-3 py-4 bg-[#FFF]  rounded-[12px]">
          <div className="font-outfit font-[500] text-[14px] leading-[21px] text-[#222]">
            {" "}
            Steward Name
          </div>

          <CustomTextField
            placeholder="Name"
            label=""
            type="text"
            inputPadding={true}
            labelTextColor={"#8F9BB3"}
            onChange={(value) => setStewardName(value)}
            value={Boolean(stewardName) ? stewardName : ""}
          />
        </main>

        <footer className="bg-[#FFF] shadow-lg w-[100%] fixed bottom-0 py-4 flex justify-center shadow-mobile-footer items-center">
          {updateLoading ? (
            <button
              className="bg-[#007FFF] text-[#FFF] w-[80%]  text-[16px] leading-[24px] font-outfit  
          h-[3rem] font-[500] flex justify-center items-center rounded-[8px]"
            >
              <CircularProgress size={16} color="inherit" />
            </button>
          ) : (
            <button
              className="bg-[#007FFF] text-[#FFF] w-[80%]  text-[16px] leading-[24px] font-outfit  
          h-[3rem] font-[500] flex justify-center items-center rounded-[8px]"
              onClick={handleAddSteward}
            >
              Apply
            </button>
          )}
        </footer>
      </div>
    </Drawer>
  );
};

export default AddStewardDrawer;
