import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { addItem, removeItem } from "../../../../../reducer/userItemsReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  updateInstruction,
  updateSelectedItem,
} from "../../../../../reducer/posReducer/restaurant";
import { BsArrowReturnRight } from "react-icons/bs";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { CURRENCY_FORMAT } from "../../../../../utils/helper";
import { GET_ITEM_PRICE } from "../../../../../utils/posHelper";
function Card({ item, handleIsAddOnVisible, isAddOnVisible }) {
  const { selectedItem, tableOrder } = useSelector((store) => store.pos);
  const dispatch = useDispatch();

  const { activePosType } = useSelector((store) => store.posSetting);

  const inc = (id) => {
    dispatch(
      updateSelectedItem({
        id: id,
        itemCountType: "increment",
      })
    );
  };

  const dnc = (id) => {
    dispatch(
      updateSelectedItem({
        id: id,
        itemCountType: "decrement",
      })
    );
  };

  const addonInc = (id, addOnName) => {
    dispatch(
      updateSelectedItem({
        id: id,
        addonCountType: "increment",
        addOnItem: addOnName,
      })
    );
  };

  const addonDnc = (id, addOnName) => {
    dispatch(
      updateSelectedItem({
        id: id,
        addonCountType: "decrement",
        addOnItem: addOnName,
      })
    );
  };

  const handleCustomAddOnQuantity = (id, addOnName, customValue) => {
    let trimmedValue = customValue;

    // Trim initial zero
    if (trimmedValue.length > 1 && trimmedValue.startsWith("0")) {
      trimmedValue = trimmedValue.slice(1);
    }

    if (customValue < 0 || customValue == "-") {
      message.warning("Please enter value greater than zero ");
      return;
    }
    if (trimmedValue === "") {
      trimmedValue = 0;
    }

    dispatch(
      updateSelectedItem({
        id: id,
        addonCountType: "custom",
        addOnItem: addOnName,
        count: trimmedValue,
      })
    );
  };

  const handleQuantityChange = (id, customValue) => {
    if (customValue < 0 || customValue == "-") {
      message.warning("Please enter value greater than zero ");
      return;
    }
    dispatch(
      updateSelectedItem({
        id: id,
        itemCountType: "custom",
        count: customValue,
      })
    );
  };

  let price = GET_ITEM_PRICE(tableOrder.type, activePosType, item);

  return (
    <div className="w-[90%]  py-3 px-4 m-auto bg-[#ffffff] rounded-lg flex mb-2">
      <div className="w-[96%]">
        <div className="">
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-start ">
              <span title={item?.name} className="text-[16px] text-[#000000B2]">
                {item?.name?.length > 16
                  ? item?.name?.substring(0, 16) + "..."
                  : item?.name}
              </span>
              <span className="text-[16px] text-[#00000080] ">{" X "}</span>
              <span className="text-[16px] text-[#00000080] ">
                {item?.quantity}
              </span>

              <div className="m-0 p-0">
                {/* <input
                  type="number"
                  className=" flex justify-center items-center w-[5ch] border-b-[1px] border-b-[#0000004D] border-dashed text-[#4D4D4D] outline-none"
                  style={{
                    textAlign: "center",
                  }}
                  value={item.quantity}
                  onChange={(e) =>
                    handleQuantityChange(item?.id, e.target.value)
                  }
                /> */}
              </div>
            </div>

            <div>
              <div className="flex flex-col gap-1">
                <span className="text-[16px] font-medium text-[#000000B2]">
                  Rs {CURRENCY_FORMAT(item.quantity * +price)}
                </span>
              </div>
            </div>
          </div>

          {item?.addOns?.map((addonItem) => {
            return (
              <div className="mt-2">
                {Boolean(selectedItem[item.id][addonItem.name]) && (
                  <div className="flex justify-between ">
                    <div className="flex items-center">
                      <span className="mt-1">
                        <BsArrowReturnRight />
                      </span>
                      <span className="text-[14px] text-[#000000B2] ml-2 mr-2 ">
                        {addonItem.name}
                      </span>
                      <span className="text-[14px] text-[#00000080] ">
                        {" X "}
                      </span>

                      <div className="pl-2 text-[#4D4D4D] ">
                        {selectedItem[item.id][addonItem.name]}
                        {/* <input
                          type="number"
                          className=" flex justify-center items-center w-[5ch] border-b-[1px] border-b-[#0000004D] border-dashed text-[#4D4D4D] outline-none"
                          style={{
                            textAlign: "center",
                          }}
                          value={}
                          onChange={(e) => {
                            handleCustomAddOnQuantity(
                              item?.id,
                              addonItem.name,
                              e.target.value
                            );
                          }}
                        /> */}
                      </div>
                    </div>

                    <div>
                      <div className="flex flex-col gap-1">
                        <span className="text-[14px] font-medium text-[#000000B2]">
                          {CURRENCY_FORMAT(
                            addonItem.price *
                              selectedItem[item.id][addonItem.name]
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

          <div className="flex justify-between">
            <input
              placeholder="Add Instructions"
              size="small"
              style={{ width: "140px" }}
              bordered={false}
              onChange={(e) =>
                dispatch(
                  updateInstruction({
                    id: item.id,
                    instruction: e.target.value,
                  })
                )
              }
              className="border-b-2  hover:outline-none outline-none border-[#CCCCCC]-500 mt-2 text-sm block w-full  focus:border-transparent focus:ring-0 "
            />

            <div className="flex gap-4 mt-2">
              <Button
                className="flex justify-center items-center"
                size="small"
                shape=""
                icon={<MinusOutlined />}
                onClick={() => dnc(item.id)}
                style={{ borderRadius: "8px" }}
              />
              <Button
                className="flex justify-center items-center"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => inc(item.id)}
                style={{ borderRadius: "8px" }}
              />{" "}
            </div>
          </div>
        </div>

        {isAddOnVisible[item.id] && (
          <>
            <div className="mt-4 mb-2">
              <div>
                <span className="text-[16px] text-[#000000B2]">Add Ons</span>
              </div>
            </div>

            {item?.addOns?.map((addonItem) => {
              return (
                <div className="flex justify-between mb-2">
                  <div>
                    <span className="text-[14px] text-[#000000B2]">
                      {addonItem.name}
                    </span>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="flex gap-2 items-center">
                      <Button
                        className="flex justify-center items-center"
                        size="small"
                        shape=""
                        icon={<MinusOutlined />}
                        onClick={() => addonDnc(item.id, addonItem.name)}
                        style={{ borderRadius: "8px" }}
                      />
                      <Input
                        type="number"
                        style={{
                          width: "7ch",
                          borderColor: "#808080",
                          outline: "none",
                          textAlign: "center",

                          "&:focus": {
                            borderColor: "#2F80ED",
                          },
                          "&:outline": {
                            borderColor: "#2F80ED",
                          },
                        }}
                        className="   whitespace-nowrap max-w-[auto]  border-[0.5px] rounded-md border-[#808080] flex  font-[Poppins]  text-[#4D4D4D] font-[500] "
                        value={
                          Boolean(selectedItem[item.id][addonItem.name])
                            ? selectedItem[item.id][addonItem.name]
                            : 0
                        }
                        onChange={(e) =>
                          handleCustomAddOnQuantity(
                            item?.id,
                            addonItem.name,
                            e.target.value
                          )
                        }
                      />
                      {/* <input
                        type="number"
                        className=" flex justify-center items-center w-[5ch] border-b-[1px] border-b-[#0000004D] border-dashed text-[#4D4D4D] outline-none"
                        style={{
                          textAlign: "center",
                        }}
                        
                      /> */}
                      <Button
                        className="flex justify-center items-center"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => addonInc(item.id, addonItem.name)}
                        style={{ borderRadius: "8px" }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

      {/* if addon item exits */}

      {Boolean(item?.addOns?.length) && (
        <div className="text-right w-[4%] ml-2 ">
          {isAddOnVisible[item.id] ? (
            <MdKeyboardArrowUp
              onClick={() =>
                handleIsAddOnVisible(item.id, !Boolean(isAddOnVisible[item.id]))
              }
              className="text-[#2F80ED] bg-[#EAF2FD] rounded-full w-5 h-5 cursor-pointer"
            />
          ) : (
            <MdKeyboardArrowDown
              onClick={() =>
                handleIsAddOnVisible(item.id, !Boolean(isAddOnVisible[item.id]))
              }
              className="text-[#2F80ED] bg-[#EAF2FD] rounded-full w-5 h-5 cursor-pointer"
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Card;
