import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateDisplayDeletePartyModal } from "../../../reducer/bookingReducer";
import { CircularProgress } from "@mui/material";
import { IoWarningOutline } from "react-icons/io5";
import useAllBookingFunction from "../../../hooks/bookingCustomHook/useAllBookingFunction";

const DeletePartyModal = () => {
  const { displaydeletePartyModal, paymentDetails } = useSelector(
    (store) => store.booking
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { handleDeletePayment } = useAllBookingFunction(setLoading);

  const currentPaymentAttachedToThisParty = paymentDetails?.find(
    (Obj) => Obj?.method === "btc"
  );

  const handleOhk = async () => {
    handleDeletePayment(
      currentPaymentAttachedToThisParty?.id,
      true,
      "Party removed successfully"
    );
  };

  return (
    <Modal
      open={displaydeletePartyModal?.display}
      centered
      title=""
      width={"auto"}
      styles={{ mask: { backdropFilter: "blur(2px)" } }}
      onCancel={() => dispatch(updateDisplayDeletePartyModal())}
      footer={
        <div className="w-full flex gap-2 justify-center mt-5">
          <Button
            className="text-[1.1rem]  w-[11rem] h-[3rem] border-[#D0D5DD] text-[#808080] rounded-[0.5rem] font-[Poppins] font-[600] "
            onClick={() => dispatch(updateDisplayDeletePartyModal())}
          >
            NO
          </Button>
          <Button
            className="text-[#FFF] text-[1.1rem] rounded-xl  bg-[#007FFF] w-[11rem] h-[3rem]"
            onClick={handleOhk}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <div>YES</div>
            )}
          </Button>
        </div>
      }
    >
      <div className="rounded-[50%] p-3 bg-[#FFFAEB] flex justify-center items-center w-[48px] h-[48px]">
        <div className="rounded-[50%] p-3 bg-[#FEF0C7] flex justify-center items-center">
          <IoWarningOutline className="text-[#DC6803]" />
        </div>
      </div>

      <div className="text-[#222B45] font-outfit font-[500] text-[18px]  ">
        Are you sure you want to remove Party <br />(
        {displaydeletePartyModal?.partyName}) from this booking?
      </div>
    </Modal>
  );
};

export default DeletePartyModal;
