import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import moment from "moment";

import {
  getCMAvailabilityRate,
  postCMAvailabilityRatesBulkUpdate,
} from "../services/ratesAndAvailability";

export const getCMAvailabilityRates = createAsyncThunk(
  "cMAvailabilityRate",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getCMAvailabilityRate(payload);
      return fulfillWithValue({
        response: response.data,
        roomType: payload.roomType,
      });
    } catch (error) {
      message.error(error.message);
      return error;
    }
  }
);

export const CMAvailabilityRatesBulkUpdate = createAsyncThunk(
  "cMAvailabilityRateBulkUpdate",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postCMAvailabilityRatesBulkUpdate(payload);
      message.success("Updated successfully");
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: false,
  response: {},
  error: null,
  fromDate: moment().startOf("day"),
  toDate: moment().startOf("day").add(6, "days"),
  occupancyFilter: "",
  showSelectChannelsDrawer: false,
  selectedChannels: [],
  showSelectDaysDrawer: false,
  selectedDays: [],
  channelsList: [],
  roomPriceAndAvailability: {},
  openLeftFromDate: false,
  openLeftToDate: false,
  multipleDates: [
    {
      id: Math.random(),
      fromDate: moment().startOf("day"),
      toDate: moment().startOf("day").add(1, "days"),
      openfromDate: false,
      openToDate: false,
    },
  ],
  bulkUpdatePostData: false,
  isStopSaleActive: false,
  resetBulkUpdateInputs: false,
  editDetailsExpanded: false,
  expandedRoomTypes: [],

  showDynamicPricingRulesDrawer: false,
};

const ratesAndAvailabilitySlice = createSlice({
  name: "ratesAndAvailability",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_35: () => initialState,

    updateShowDynamicPricingRulesDrawer: (state, action) => {
      state.showDynamicPricingRulesDrawer = action.payload;
    },
    setOpenLeftFromDate: (state, action) => {
      state.openLeftFromDate = action.payload;
    },
    setOpenLeftToDate: (state, action) => {
      state.openLeftToDate = action.payload;
    },
    updateRatesAndAvailabilityGridViewDates: (state, action) => {
      if (action.payload.from) {
        state.fromDate = action.payload.from;
        state.toDate = action.payload.to;
      } else if (action.payload.to) {
        state.toDate = action.payload.to;
      }
    },
    updateOccupancyFilter: (state, action) => {
      if (state.occupancyFilter === action.payload) {
        state.occupancyFilter = "";
      } else {
        state.occupancyFilter = action.payload;
      }
    },

    updateSelectedChannels: (state, action) => {
      const updatedArr = [...state.selectedChannels];

      if (state.selectedChannels.includes(action.payload)) {
        const filteredArr = updatedArr.filter(
          (channel) => channel !== action.payload
        );
        state.selectedChannels = filteredArr;
      } else {
        updatedArr.push(action.payload);
        state.selectedChannels = updatedArr;
      }
    },

    updateShowSelectChannelsDrawer: (state, action) => {
      state.showSelectChannelsDrawer = !state.showSelectChannelsDrawer;
    },

    updateShowSelectDaysDrawer: (state, action) => {
      state.showSelectDaysDrawer = action.payload;
    },
    updateSelectedDays: (state, action) => {
      state.selectedDays = action.payload;
    },

    updateChannelsList: (state, action) => {
      state.channelsList = action.payload;
    },

    checkAllChannels: (state, action) => {
      if (action.payload) {
        state.selectedChannels = state.channelsList;
      } else {
        state.selectedChannels = [];
      }
    },
    updateRoomPriceAndAvailability: (state, action) => {
      const updatedObj = JSON.parse(
        JSON.stringify(state.roomPriceAndAvailability)
      );
      const { type, availability, price, active, invCode } = action.payload;

      if (updatedObj.hasOwnProperty(type)) {
        updatedObj[type].availability = availability;
        updatedObj[type].price = price;
        updatedObj[type].active = active;
        updatedObj[type].invCode = invCode;
      } else {
        updatedObj[type] = { availability, price, active, invCode };
      }
      state.roomPriceAndAvailability = updatedObj;
    },

    resetAllFeilds: (state, action) => {
      const updatedObj = JSON.parse(JSON.stringify(action.payload));
      Object.keys(updatedObj).forEach((roomType) => {
        Object.keys(updatedObj[roomType]).forEach((field) => {
          if (field !== "invCode") {
            updatedObj[roomType][field] = "";
          }
        });
      });
      state.roomPriceAndAvailability = updatedObj;
      state.isStopSaleActive = false;
      state.selectedChannels = [];
      state.selectedDays = [];
      state.multipleDates = [
        {
          id: Math.random(),
          fromDate: moment().startOf("day"),
          toDate: moment().startOf("day").add(1, "days"),
        },
      ];
      state.editDetailsExpanded = false;
    },

    addRemoveMultipleDates: (state, action) => {
      let updatedArr = [...state.multipleDates];
      if (action.payload.method === "add") {
        updatedArr.push({
          id: Math.random(),
          fromDate: moment().startOf("day"),
          toDate: moment().startOf("day").add(1, "days"),
        });
      } else if (action.payload.method === "remove") {
        const filteredArr = updatedArr.filter(
          (date) => date.id !== action.payload.id
        );
        updatedArr = [...filteredArr];
      }
      state.multipleDates = updatedArr;
    },
    updateMultipleDates: (state, action) => {
      const { id, from, to, multipleDatesState, openFrom, openTo } =
        action.payload;
      const updatedMultipleDates = multipleDatesState.map((dateObj) => {
        return dateObj.id === id
          ? {
              ...dateObj,
              fromDate: from,
              toDate: to,
              openfromDate: openFrom,
              openToDate: openTo,
            }
          : { ...dateObj, openfromDate: false, openToDate: false };
      });
      state.multipleDates = updatedMultipleDates;
    },

    closeAllDates: (state, action) => {
      const multipleDatesState = action.payload;
      const updatedMultipleDates = multipleDatesState.map((dateObj) => {
        return { ...dateObj, openfromDate: false, openToDate: false };
      });
      state.multipleDates = updatedMultipleDates;
      state.openLeftFromDate = false;
      state.openLeftToDate = false;
    },

    updateBulkUpdatePostData: (state, action) => {
      state.bulkUpdatePostData = action.payload;
    },
    updateIsStopSaleActive: (state, action) => {
      state.isStopSaleActive = action.payload;
    },
    updateResetBulkUpdateInputs: (state, action) => {
      state.resetBulkUpdateInputs = !state.resetBulkUpdateInputs;
    },
    setEditDetailsExpanded: (state, action) => {
      state.editDetailsExpanded = !state.editDetailsExpanded;
    },
    setExpandedRoomTypes: (state, action) => {
      const updatedArr = [...state.expandedRoomTypes];

      if (state.expandedRoomTypes.includes(action.payload)) {
        const filteredArr = updatedArr.filter(
          (type) => type !== action.payload
        );
        state.expandedRoomTypes = filteredArr;
      } else {
        updatedArr.push(action.payload);
        state.expandedRoomTypes = updatedArr;
      }
    },
    clearOldRatesAndAvailability: (state, action) => {
      state.response = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCMAvailabilityRates.pending, (state, action) => {
      state.loading = true;
      state.apiError = false;
    });
    builder.addCase(getCMAvailabilityRates.fulfilled, (state, action) => {
      state.loading = false;
      state.response = {
        ...state.response,
        [action.payload.roomType]: action.payload.response,
      };
      state.apiError = false;
    });
    builder.addCase(getCMAvailabilityRates.rejected, (state, action) => {
      state.loading = false;
      state.apiError = true;
      state.response = null;
    });
  },
});

export default ratesAndAvailabilitySlice.reducer;

export const {
  CLEAR_REDUX_STORE_35,
  updateRatesAndAvailabilityGridViewDates,
  updateOccupancyFilter,
  updateShowSelectChannelsDrawer,
  updateSelectedChannels,
  updateChannelsList,
  checkAllChannels,
  updateRoomPriceAndAvailability,
  addRemoveMultipleDates,
  updateMultipleDates,
  updateBulkUpdatePostData,
  updateIsStopSaleActive,
  updateResetBulkUpdateInputs,
  resetAllFeilds,
  setEditDetailsExpanded,
  setExpandedRoomTypes,
  closeAllDates,
  setOpenLeftFromDate,
  setOpenLeftToDate,
  updateShowSelectDaysDrawer,
  updateSelectedDays,
  updateShowDynamicPricingRulesDrawer,
  clearOldRatesAndAvailability,
} = ratesAndAvailabilitySlice.actions;
