import { authedAxios } from "../utils/customHttpHeader";

const getPartyTransactionsService = async (payload, endpoint) => {
  let url = endpoint;
  url += `?hotelId=${payload?.hotelId}`;

  if (payload?.partyId) url += `&partyId=${payload?.partyId}`;
  if (payload?.pageNumber) url += `&pageNumber=${payload?.pageNumber}`;
  if (payload?.pageSize) url += `&pageSize=${payload?.pageSize}`;
  if (payload?.sortingField) url += `&sortingField=${payload?.sortingField}`;
  if (payload?.startDate) url += `&startDate=${payload?.startDate}`;
  if (payload?.endDate) url += `&endDate=${payload?.endDate}`;
  if (payload?.sortingDirection)
    url += `&sortingDirection=${payload?.sortingDirection}`;

  const response = await authedAxios().get(url);
  return response;
};

const postPartyTransactionService = async (payload, endpoint) => {
  let url = endpoint;

  const response = await authedAxios().post(url, payload);
  return response;
};

const putPartyTransactionService = async (payload, endpoint) => {
  let url = endpoint + "/" + payload?.id;

  const response = await authedAxios().put(url, payload);
  return response;
};

export {
  getPartyTransactionsService,
  postPartyTransactionService,
  putPartyTransactionService,
};
