import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  createTableOrder,
  updateSelectedRider,
} from "../../../../reducer/posReducer/restaurant";
import { removeCategories } from "../../../../reducer/manageStocksReducer/categoriesReducer";
import { getPartyList } from "../../../../reducer/partyReducer";
import {
  getInvoiceById,
  getTableOrders,
  resetInvoicePostDataAndOrderData,
} from "../../../../reducer/orderReducer";

import Heading from "./Heading";
import SliderComponent from "./Slider";
import AllItems from "./AllItems";
import Footer from "./Footer";
import AddItem from "./AddItem";
import OrderDetails from "../orderDetails/Index";
import MultipleKOTScreen from "../multipleKOTScreen/Index";
import { ITEMS_TOTAL_AMOUNT } from "../../../../utils/posHelper";
import TakePaymentDrawer from "./TakePaymentDrawer";
import ApplyDiscountDrawer from "./DiscountDrawer";
import { CircularProgress } from "@mui/material";
import useStopRefreshLogic from "../../../../hooks/refreshHook/useRefreshHook";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";
import { getAllPayments } from "../../../../reducer/paymentReducer";
import usePosStorageLogic from "../../../../hooks/posCustomHook/usePosStorageLogic";

const NewOrderRevamped = () => {
  const dispatch = useDispatch();

  const { activePosId } = useSelector((store) => store.posSetting);
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);
  const { hotelDetails } = useSelector((store) => store.login);
  const { customerDetails, tableOrder } = useSelector((state) => state.pos);

  const [activeMenu, setActiveMenu] = useState("All Menu");

  const { allPayments } = useSelector((store) => store.payments);

  const {
    data: order,
    invoicePostData,
    invoicePostLoading,
  } = useSelector((store) => store.order);

  useEffect(() => {
    const payload = {
      hotelId: hotelDetails?.id,
      invoiceId: invoicePostData?.id,
      excludePaymentMethods: [],
    };

    if (invoicePostData?.id) dispatch(getAllPayments(payload));
  }, [invoicePostData]);

  const [addItemSectionVisible, setAddItemSectionVisible] = useState(false);
  const [orderDetailsScreenVisible, setOrderDetailScreenVisible] =
    useState(false);
  const [multipleKOTScreenVisible, setMultipleKOTScreenVsisible] =
    useState(false);

  useEffect(() => {
    dispatch(
      createTableOrder({
        ...tableOrder,
        name: customerDetails?.name,
        customerId: customerDetails?.id,
      })
    );
  }, [customerDetails]);

  useEffect(() => {
    dispatch(
      getPartyList({
        hotelId: hotelDetails?.id,
        pageSize: 100,
        pageNumber: 0,
      })
    );
  }, []);

  useEffect(() => {
    // will deselect the selected categoris like snack ,beverages
    return () => {
      dispatch(removeCategories());
      dispatch(updateSelectedRider(""));
      dispatch(resetInvoicePostDataAndOrderData());
    };
  }, []);

  const { total, priceBreakup, paid } = invoicePostData || {};

  // let paid = Array.isArray(allPayments)
  //   ? allPayments.reduce((total, Obj) => total + (Obj?.amount || 0), 0)
  //   : 0;

  let itemPrice = ITEMS_TOTAL_AMOUNT(priceBreakup);

  const { createBill } = useAllPosFunctions();

  useEffect(() => {
    if (Boolean(tableOrderDetails.invoiceId)) {
      dispatch(
        getInvoiceById({
          hotelId: hotelDetails?.id,
          id: tableOrderDetails?.invoiceId,
        })
      );
    } else {
      dispatch(resetInvoicePostDataAndOrderData());
    }

    // getInvoiceById
  }, [tableOrderDetails?.tableNumber, tableOrderDetails?.invoiceId]);

  useEffect(() => {
    if (
      invoicePostData?.status === "billed" ||
      invoicePostData?.status === "paid" ||
      invoicePostData?.status === "due"
    ) {
      setOrderDetailScreenVisible(false);
      setMultipleKOTScreenVsisible(true);
    }
  }, [invoicePostData]);

  useEffect(() => {
    let OrderLength = invoicePostData?.orders?.length;

    let IDS = "";

    invoicePostData?.orders?.forEach((item, i) => {
      if (i === OrderLength - 1) {
        IDS += item;
      } else {
        IDS += item + ",";
      }
    });

    let payload = {
      hotelId: hotelDetails?.id,
      roomId: tableOrderDetails?.roomId,
      tableId: tableOrderDetails?.tableId,
      ids: IDS,
      posId: activePosId,
    };

    if (OrderLength > 0) {
      dispatch(getTableOrders(payload));
    } else dispatch(resetInvoicePostDataAndOrderData());
  }, [
    invoicePostData?.id,
    tableOrderDetails?.tableNumber,
    tableOrderDetails?.invoiceId,
    activePosId,
  ]);

  useStopRefreshLogic();
  usePosStorageLogic();

  return (
    <div className="relative bg-[#F2F4FC] w-[100%] h-[100%] min-h-[100vh] ">
      {invoicePostLoading ? (
        <div className="w-[100%] min-h-[100%] h-[100vh] flex justify-center items-center">
          <CircularProgress size={32} color="inherit" />
        </div>
      ) : (
        <>
          {" "}
          <TakePaymentDrawer
            total={Boolean(paid) ? total - paid : total}
            invoice={invoicePostData}
            hotelId={hotelDetails?.id}
          />
          <ApplyDiscountDrawer
            itemPrice={itemPrice}
            discountInfo={invoicePostData?.discountInfo}
          />
          {addItemSectionVisible ? (
            <AddItem
              addItemSectionVisible={addItemSectionVisible}
              setAddItemSectionVisible={setAddItemSectionVisible}
            />
          ) : orderDetailsScreenVisible ? (
            <OrderDetails
              setOrderDetailScreenVisible={setOrderDetailScreenVisible}
              setMultipleKOTScreenVsisible={setMultipleKOTScreenVsisible}
            />
          ) : multipleKOTScreenVisible ? (
            <MultipleKOTScreen
              multipleKOTScreenVisible={multipleKOTScreenVisible}
              setMultipleKOTScreenVsisible={setMultipleKOTScreenVsisible}
              setOrderDetailScreenVisible={setOrderDetailScreenVisible}
              createBill={createBill}
            />
          ) : (
            <>
              <Heading
                addItemSectionVisible={addItemSectionVisible}
                setAddItemSectionVisible={setAddItemSectionVisible}
              />

              <div className="pt-[9.5rem] pb-[8rem]">
                <SliderComponent
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                />

                <AllItems
                  setAddItemSectionVisible={setAddItemSectionVisible}
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                />
              </div>

              <Footer
                orderDetailsScreenVisible={orderDetailsScreenVisible}
                setOrderDetailScreenVisible={setOrderDetailScreenVisible}
                order={order}
                setMultipleKOTScreenVsisible={setMultipleKOTScreenVsisible}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default NewOrderRevamped;
