import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updatePartySearchString } from "./expenseReducer";

import { message } from "antd";

import {
  getPartyService,
  postPartyService,
  putPartyService,
  postCountryStatesService,
  postStateCitiesService,
  getPartyByIdService
} from "../services/party";

export const getExpensePartyList = createAsyncThunk(
  "getExpensePartyList",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getPartyService(payload, `/party`);
      return response.data;
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const getPartyList = createAsyncThunk(
  "partyList",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getPartyService(payload, `/party`);
      return fulfillWithValue({
        data: response.data,
        pageNumber: payload.pageNumber,
        infiniteScrollDisabled: payload.infiniteScrollDisabled,
      });
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const createParty = createAsyncThunk(
  "createParty",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postPartyService(payload, `/party`);
      message.success("Party has been added");
      dispatch(updateAddPartyDrawerVisible(false));
      dispatch(updatePartyObject({}));
      dispatch(updatePartySearchString(response?.data?.displayName));
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const editParty = createAsyncThunk(
  "editParty",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await putPartyService(payload, `/party/${payload.id}`);
      message.success("Party has been updated");
      dispatch(updateAddPartyDrawerVisible(false));
      dispatch(updateInitialPartyObject(response.data));
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const postCountryStates = createAsyncThunk(
  "countryStates",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postCountryStatesService(
        payload,
        "https://countriesnow.space/api/v0.1/countries/states"
      );
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const postStateCities = createAsyncThunk(
  "stateCities",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postStateCitiesService(
        payload,
        "https://countriesnow.space/api/v0.1/countries/state/cities"
      );
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);
export const getPartyById = createAsyncThunk(
  "getPartyById",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getPartyByIdService(payload, `/party`);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

const initialState = {
  selectedPartyView: "vendor",
  addPartyDrawerVisible: false,
  stateFilterDropdownVisible: false,
  initialPartyObject: {},
  selectedStateFilter: [],

  // get party list
  partyResponse: [],
  partyLoading: false,
  partyError: null,

  // create party
  createPartyResponse: {},
  createPartyLoading: false,
  createPartyError: null,

  // edit party
  editPartyResponse: {},
  editPartyLoading: false,
  editPartyError: null,

  // for getting list of states for the selected country
  statesResponse: [],
  statesLoading: false,
  statesError: null,

  // for getting list of cities for the selected state
  citiesResponse: [],
  citiesLoading: false,
  citiesError: null,

  showCompanyBankDetailsVisible: "1",
  partyObject: {},

  // for autocomplete fields
  countrySearchString: "",
  selectedCountry: {
    code: "IN",
    label: "India",
    phone: "91",
  },
  currencySearchString: "",
  selectedCurrency: "",
  stateSearchString: "",
  selectedState: "",
  citySearchString: "",
  selectedCity: "",
  currencySearchString: "",
  selectedCurrency: "",

  // infinite scrolling
  pageNumber: 0,
  pageSize: 10,
  infinateScroll: false,
  infinateScrollLoading: false,

  uploadedFileUrl: [], // after uploaded the file , store the file url,

  //party type filter
  partyTypeFilter: "",
  partyTypeDropdownVisible: false,

  searchText: "",
  searchTextNew:"",

  // expense party list
  expensePartyList: [],
};

const partySlice = createSlice({
  name: "partySlice",
  initialState,

  reducers: {
    CLEAR_REDUX_STORE_33: () => initialState,

    updatePartyTypeFilter: (state, action) => {
      state.partyTypeFilter = action.payload;
    },

    updatePartyTypeDropdownVisible: (state, action) => {
      state.partyTypeDropdownVisible = action.payload;
    },

    resetPartyChangeFilter: (state, filter) => {
      state.initialPartyObject = {};
      state.selectedStateFilter = [];
    },
    updateSelectedPartyView: (state, action) => {
      state.selectedPartyView = action.payload;
    },

    updateAddPartyDrawerVisible: (state, action) => {
      state.addPartyDrawerVisible = action.payload;
    },
    updateShowCompanyBankDetailsVisible: (state, action) => {
      state.showCompanyBankDetailsVisible = action.payload;
    },
    updatePartyObject: (state, action) => {
      state.partyObject = action.payload;
    },

    updateCountrySearchString: (state, action) => {
      state.countrySearchString = action.payload;
    },
    updateSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    updateCurrencySearchString: (state, action) => {
      state.currencySearchString = action.payload;
    },
    updateSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
    updateStateSearchString: (state, action) => {
      state.stateSearchString = action.payload;
    },
    updateSelectedState: (state, action) => {
      state.selectedState = action.payload;
    },
    updateCitySearchString: (state, action) => {
      state.citySearchString = action.payload;
    },
    updateSelectedCity: (state, action) => {
      state.selectedState = action.payload;
    },
    updateInitialPartyObject: (state, action) => {
      state.initialPartyObject = action.payload;
    },
    updateStateFilterDropdownVisible: (state, action) => {
      state.stateFilterDropdownVisible = action.payload;
    },

    updateSelectedStateFilter: (state, action) => {
      if (action.payload === "clear") {
        state.selectedStateFilter = [];
        return;
      }
      let updatedSelectedStateFilter;
      if (state.selectedStateFilter.includes(action.payload)) {
        updatedSelectedStateFilter = state.selectedStateFilter.filter(
          (state) => state !== action.payload
        );
      } else {
        updatedSelectedStateFilter = [
          ...state.selectedStateFilter,
          action.payload,
        ];
      }
      state.selectedStateFilter = updatedSelectedStateFilter;
    },

    //file upload
    removeUploadedUrl: (state, action) => {
      if (state.uploadedFileUrl.indexOf(action.payload) > -1) {
        let index = state.uploadedFileUrl.indexOf(action.payload);
        state.uploadedFileUrl.splice(index, 1);
      }
    },
    updateUploadedUrl: (state, action) => {
      state.uploadedFileUrl = [...state.uploadedFileUrl, action.payload];
    },
    updateDisplayIdModal: (state, action) => {
      state.displayIdModal = action.payload;
    },

    resetState: (state, action) => {
      state.selectedPartyView = "vendor";
      state.addPartyDrawerVisible = false;
      state.stateFilterDropdownVisible = false;
      state.initialPartyObject = {};
      state.selectedStateFilter = [];
      state.showCompanyBankDetailsVisible = "1";
      state.partyObject = {};
      state.pageNumber = 0;
      state.pageSize = 10;
      state.infinateScroll = false;
      state.infinateScrollLoading = false;
      state.partyTypeFilter = "";
      state.partyTypeDropdownVisible = false;
      state.searchText = "";
      state.expensePartyList = [];
    },

    resetInfiniteScrolling: (state, action) => {
      if (action.payload === "resetPageNumber") {
        state.pageNumber = 0;
        return;
      }

      state.pageNumber = 0;
      state.pageSize = 10;
      state.infinateScroll = false;
      state.infinateScrollLoading = false;
    },

    resetInfiniteScrolling2: (state, action) => {
      state.pageNumber = 0;
      state.pageSize = 10;
      state.infinateScroll = false;
      state.infinateScrollLoading = false;
    },

    // Infinte Scrolling
    updateInFinateScroll: (state, action) => {
      state.infinateScroll = action.payload;
    },
    updateInFinateScrollLoading: (state, action) => {
      state.infinateScrollLoading = action.payload;
    },

    updatePageNo: (state, action) => {
      if (action.payload) {
        state.pageNumber = action.payload;
        return;
      }
      state.pageNumber = state.pageNumber + 1;
    },

    updateSearchText: (state, action) => {
      state.searchText = action.payload;
    },

    updateSearchTextNew: (state, action) => {
      state.searchTextNew = action.payload;
    },

    
  },

  extraReducers: (builder) => {
    // get party list
    builder.addCase(getPartyList.pending, (state, action) => {
      state.partyLoading = state.infinateScroll ? false : true;
      state.partyResponse = state.infinateScroll
        ? [...state.partyResponse]
        : [];
      state.partyError = false;
    });
    builder.addCase(getPartyList.fulfilled, (state, action) => {
      state.partyLoading = false;

      // if we do not want to implement infinite scrolling but want to implement searchText
      if (action.payload.infiniteScrollDisabled) {
        state.partyResponse = action.payload.data;
        return;
      }

      state.infinateScroll = action.payload?.data?.length > 0 ? true : false;

      state.infinateScrollLoading =
        action.payload?.data?.length > 0 ? true : false;

      const resData = Boolean(action.payload?.data)
        ? [...state.partyResponse, ...action.payload?.data]
        : [];

      state.partyResponse = Boolean(action.payload?.pageNumber)
        ? resData
        : action.payload?.data || [];

      // If search Text is there we will only store action.payload
      // state.partyResponse = action.payload;

      state.partyError = false;
    });
    builder.addCase(getPartyList.rejected, (state, action) => {
      state.partyLoading = false;
      state.partyError = action.payload;
      state.partyResponse = [];
    });

    // create party
    builder.addCase(createParty.pending, (state, action) => {
      state.createPartyLoading = true;
      state.createPartyError = false;
    });
    builder.addCase(createParty.fulfilled, (state, action) => {
      state.createPartyLoading = false;
      state.createPartyResponse = action.payload;
      state.partyResponse = [action.payload.data, ...state.partyResponse];
      state.createPartyError = null;
    });
    builder.addCase(createParty.rejected, (state, action) => {
      state.createPartyLoading = false;
      state.createPartyError = action.payload;
    });

    // edit party
    builder.addCase(editParty.pending, (state, action) => {
      state.editPartyLoading = true;
      state.editPartyError = false;
    });
    builder.addCase(editParty.fulfilled, (state, action) => {
      state.partyObject =
        state.selectedPartyView === "vendorExpense" ? state.partyObject : {};
      state.editPartyLoading = false;
      state.editPartyResponse = action.payload;
      state.partyResponse = state.partyResponse.map((party) =>
        party.id === action.payload.data.id ? action.payload.data : party
      );
      state.editPartyError = null;
    });
    builder.addCase(editParty.rejected, (state, action) => {
      state.editPartyLoading = false;
      state.editPartyError = action.payload;
    });

    // retrive list of states by post request
    builder.addCase(postCountryStates.pending, (state, action) => {
      state.statesLoading = true;
      state.statesError = false;
    });
    builder.addCase(postCountryStates.fulfilled, (state, action) => {
      state.statesLoading = false;
      state.statesResponse = action.payload?.data?.data?.states?.map(
        (state) => state?.name
      );
      state.statesError = null;
    });
    builder.addCase(postCountryStates.rejected, (state, action) => {
      state.statesLoading = false;
      state.statesError = action.payload;
    });

    // retrive list of states by post request
    builder.addCase(postStateCities.pending, (state, action) => {
      state.citiesLoading = true;
      state.citiesError = false;
    });
    builder.addCase(postStateCities.fulfilled, (state, action) => {
      state.citiesLoading = false;
      state.citiesResponse = action.payload?.data?.data;
      state.citiesError = null;
    });
    builder.addCase(postStateCities.rejected, (state, action) => {
      state.citiesLoading = false;
      state.citiesError = action.payload;
    });

    builder.addCase(getExpensePartyList.fulfilled, (state, action) => {
      state.expensePartyList = action.payload;
    });
  },
});

export default partySlice.reducer;
export const {
  CLEAR_REDUX_STORE_33,
  updateSelectedPartyView,
  updateAddPartyDrawerVisible,
  updateAddVendorDrawerVisible,
  updateShowCompanyBankDetailsVisible,
  updatePartyObject,
  updateCountrySearchString,
  updateSelectedCountry,
  updateCurrencySearchString,
  updateSelectedCurrency,
  updateInitialPartyObject,
  updateStateFilterDropdownVisible,
  updateSelectedStateFilter,
  updateStateSearchString,
  updateSelectedState,
  updateCitySearchString,
  updateSelectedCity,
  removeUploadedUrl,
  updateUploadedUrl,
  updateDisplayIdModal,
  resetState,
  resetPartyChangeFilter,
  updateInFinateScroll,
  updateInFinateScrollLoading,
  updatePageNo,
  resetInfiniteScrolling,
  resetInfiniteScrolling2,
  updatePartyTypeFilter,
  updatePartyTypeDropdownVisible,

  updateSearchText,
  updateSearchTextNew
} = partySlice.actions;
