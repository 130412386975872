import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteSupplierByIDService,
  getAllSuppliersService,
  updateSupplierByIdService,
} from "../../services/manageStocksServices/suppliers";
import { message } from "antd";

export const getAllSuppliers = createAsyncThunk(
  "suppliers/getAllSuppliers",
  async () => {
    try {
      const response = await getAllSuppliersService();
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteSupplier = createAsyncThunk(
  "suppliers/deleteSupplier",
  async (params) => {
    try {
      const response = await deleteSupplierByIDService(params);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
export const updateSupplier = createAsyncThunk(
  "suppliers/updateSupplier",
  async (payload) => {
    try {
      const response = await updateSupplierByIdService(payload);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_3: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSuppliers.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(getAllSuppliers.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getAllSuppliers.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
    builder.addCase(deleteSupplier.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteSupplier.fulfilled, (state, action) => {
      message.success("Supplier Removed");
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteSupplier.rejected, (state, action) => {
      message.error("Error in removing supplier");
      state.loading = false;
      state.error = true;
    });
    builder.addCase(updateSupplier.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateSupplier.fulfilled, (state, action) => {
      message.success("Supplier details updated");
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateSupplier.rejected, (state, action) => {
      message.error("Error in updating supplier");
      state.loading = false;
      state.error = true;
    });
  },
});

export const { CLEAR_REDUX_STORE_3 } = suppliersSlice.actions;
export default suppliersSlice.reducer;
