import React from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import moment from "moment";
import { CURRENCY_FORMAT } from "../../../utils/helper";

const GenerateInvoice = ({ tableOrderDetails, val, customer }) => {
  const { invoicePostData } = useSelector((store) => store.order);
  const { hotelDetails } = useSelector((store) => store.login);
  const { name, hsnSac, address, gstIN, contactNumber, emailAddress } =
    hotelDetails || {};
  const { activePosName } = useSelector((store) => store.posSetting);

  const {
    priceBreakup,
    invoiceBilledDate,
    createdAt,
    total,
    subTotal,
    discount,
    serviceCharge,
    invoiceId,
    taxObjects,
  } = invoicePostData || {};

  let itemPrice = 0;

  priceBreakup?.forEach((item) => {
    itemPrice += +item.rate * +item.count;

    item.addOns?.forEach((addOn) => {
      itemPrice += +addOn.price * addOn.count;
    });
  });

  let date = invoiceBilledDate ? invoiceBilledDate : createdAt;

  return (
    <div className="relative">
      <div className="left-0 right-0 w-full px-8 top-2  absolute " ref={val}>
        <div className="text-center">
          <p className="text-semibold text-2xl font-semibold capitalize">
            {name}
          </p>

          <p className="text-semibold font-semibold capitalize">
            {activePosName}
          </p>
          <p className="text-sm">{address}</p>
          <p className="text-sm">
            {Boolean(contactNumber) && (
              <span className="text-sm">Phone: {contactNumber}</span>
            )}
          </p>

          {Boolean(emailAddress) && (
            <p className="text-sm">Email: {emailAddress}</p>
          )}

          {Boolean(gstIN) && <p className="text-sm">GSTIN: {gstIN}</p>}

          {/* {Boolean(FSSAI) && <p>GSTIN: {FSSAI}</p>} */}

          {Boolean(hsnSac) && <p className="text-sm">HSN/SAc: {hsnSac}</p>}

          <p className="text-sm">Invoice</p>
          <p className="text-1xl">Dine In</p>
        </div>

        {tableOrderDetails?.name && (
          <div>
            <p> {tableOrderDetails?.name}</p>
          </div>
        )}

        {customer?.company && (
          <div>
            <p> Company: {customer?.company}</p>
          </div>
        )}

        {customer?.gstIN && (
          <div>
            <p> Company GSTIN: {customer?.gstIN}</p>
          </div>
        )}

        <hr className="border-dashed border-b-2 border-slate-600 my-2" />

        <div className="flex justify-between">
          <p> Invoice: {invoiceId}</p>
          <p>{priceBreakup?.length} times</p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm">
            {moment(date).format("Do MMM, YYYY h:mm a")}
          </p>
          <p className="text-sm">Cashier 1</p>
        </div>
        <hr className="border-dashed border-b-2 border-slate-600 my-2" />

        <div className="flex justify-between">
          <p className="w-[40%]">Item</p>
          <p className="w-[20%] text-center">Qty</p>
          <p className="w-[40%] text-right">Amt</p>
        </div>
        <div>
          {priceBreakup?.map((item, index) => {
            return (
              <div key={index} className="mb-2">
                <div className="flex justify-between">
                  <p className="w-[40%]">{item.type}</p>
                  <p className="w-[20%] text-center">{item.count}</p>
                  <p className="w-[40%] text-right">
                    {CURRENCY_FORMAT(+item.rate * +item.count)}
                  </p>
                </div>
                {item.addOns?.map((addon) => {
                  return (
                    <div key={addon.name} className="flex justify-between">
                      <div className="flex">
                        <span className="mt-1">
                          <BsArrowReturnRight className="mr-[2px]" />
                        </span>
                        <span>
                          {addon.name}X{addon.count}
                        </span>
                      </div>
                      <span>
                        {CURRENCY_FORMAT(+addon.price * +addon.count)}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <hr className="border-dashed border-b-2 border-slate-600 my-2" />

        <div className="w-full">
          <div className="flex justify-between mb-3 w-full">
            <p>Amount</p>
            <p>{CURRENCY_FORMAT(itemPrice)}</p>
          </div>
          {Boolean(discount) && (
            <div className="flex justify-between mb-2">
              <p>Discount</p>
              <p>{CURRENCY_FORMAT(discount)}</p>
            </div>
          )}

          <div className="flex justify-between mb-1 w-full">
            <p>Sub Total</p>
            <p>{CURRENCY_FORMAT(subTotal)}</p>
          </div>

          {taxObjects?.map((Obj) => {
            return (
              <div
                className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1"
                key={Obj?.id}
              >
                <div className="flex gap-2">
                  <p className="font-outfit text-[14px]">
                    {Obj?.taxName || "Tax"} ({Obj?.taxPercentage}%)
                  </p>
                </div>
                <p className="font-outfit text-[14px]">
                  {CURRENCY_FORMAT(Obj?.taxAmount)}
                </p>
              </div>
            );
          })}

          {Boolean(+serviceCharge) && (
            <div className="flex justify-between mb-2 w-full">
              <p>Extra charge</p>
              <p>{CURRENCY_FORMAT(serviceCharge)}</p>
            </div>
          )}

          <div className="flex justify-between mb-2 text-2xl w-full">
            <p>Bill Total</p>
            <p>{CURRENCY_FORMAT(total)}</p>
          </div>

          <hr className="border-dashed border-b-2 border-slate-600 my-2" />
        </div>

        <div className="flex justify-between">
          <p className="text-sm">Thank You!</p>
          <p className="text-sm">Powered By - Revivo</p>
        </div>
      </div>
    </div>
  );
};

export default GenerateInvoice;
