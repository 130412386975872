export const currencyConfig = {
  USD: { symbol: "$", position: "before" },
  EUR: { symbol: "€", position: "before" },
  GBP: { symbol: "£", position: "before" },
  INR: { symbol: "₹", position: "before" },
  JPY: { symbol: "¥", position: "before" },
  CNY: { symbol: "¥", position: "before" },
  AUD: { symbol: "A$", position: "before" },
  CAD: { symbol: "C$", position: "before" },
  CHF: { symbol: "CHF", position: "before" },
  NZD: { symbol: "NZ$", position: "before" },
  SEK: { symbol: "kr", position: "after" },
  NOK: { symbol: "kr", position: "after" },
  DKK: { symbol: "kr", position: "after" },
  ZAR: { symbol: "R", position: "before" },
  BRL: { symbol: "R$", position: "before" },
  RUB: { symbol: "₽", position: "after" },
  MXN: { symbol: "$", position: "before" },
  SGD: { symbol: "S$", position: "before" },
  HKD: { symbol: "HK$", position: "before" },
  MYR: { symbol: "RM", position: "before" },
  IDR: { symbol: "Rp", position: "before" },
  PHP: { symbol: "₱", position: "before" },
  THB: { symbol: "฿", position: "before" },
  VND: { symbol: "₫", position: "after" },
  KRW: { symbol: "₩", position: "before" },
  PLN: { symbol: "zł", position: "after" },
  HUF: { symbol: "Ft", position: "after" },
  CZK: { symbol: "Kč", position: "after" },
  SAR: { symbol: "﷼", position: "before" },
  AED: { symbol: "د.إ", position: "before" },
  TRY: { symbol: "₺", position: "before" },
  ILS: { symbol: "₪", position: "before" },
  EGP: { symbol: "£", position: "before" },
  KES: { symbol: "KSh", position: "before" },
};

export const getCurrentCurrency = (currency) => {
  return currencyConfig[currency] || currencyConfig["INR"];
};
