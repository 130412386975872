import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "antd";

import { updateIsAddItemDrawerOpen } from "../../../reducer/getItemReducer";

import AddItem from "../allItems/AddItem";

const AddItemDrawer = () => {
  const { isAddItemDrawerOpen } = useSelector((store) => store.getItems);

  const dispatch = useDispatch();

  return (
    <Drawer
      title={null}
      placement="right"
      onClose={() => dispatch(updateIsAddItemDrawerOpen(false))}
      open={isAddItemDrawerOpen}
      width={"400px"}
      closeIcon={null}
      bodyStyle={{ padding: "0px" }}
    >
      <AddItem />
    </Drawer>
  );
};
export default AddItemDrawer;
