import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateDisplayCancelBookingModal } from "../../../reducer/bookingReducer";
import { CircularProgress } from "@mui/material";
import useAllBookingFunction from "../../../hooks/bookingCustomHook/useAllBookingFunction";

const CancelBookingModal = () => {
  const { displayCancelBookingModal } = useSelector((store) => store.booking);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { handleBookingToBeCanceld } = useAllBookingFunction(setLoading);

  return (
    <Modal
      open={displayCancelBookingModal}
      centered
      title=""
      width={"auto"}
      styles={{ mask: { backdropFilter: "blur(2px)" } }}
      onCancel={() => dispatch(updateDisplayCancelBookingModal())}
      footer={
        <div className="w-full flex gap-2 justify-center mt-5">
          <Button
            className="text-[1.1rem]  w-[11rem] h-[3rem] border-[#D0D5DD] text-[#808080] rounded-[0.5rem] font-[Poppins] font-[600] "
            onClick={() => dispatch(updateDisplayCancelBookingModal())}
          >
            NO
          </Button>
          <Button
            className="text-[#FFF] text-[1.1rem] rounded-xl  bg-[#007FFF] w-[11rem] h-[3rem]"
            onClick={handleBookingToBeCanceld}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <div>YES</div>
            )}
          </Button>
        </div>
      }
    >
      <div className="font-nunito text-[1.125remrem] font-[600] text-[#4D4D4D]">
        Cancel Booking?
      </div>
      <div className="text-[#808080] font-nunito text-[0.875rem] font-[400]">
        By Clicking Yes your booking will be listed as cancelled.
      </div>
    </Modal>
  );
};

export default CancelBookingModal;
