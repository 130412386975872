import React, { useEffect, useState } from "react";
import room from "../../../../assets/icons/room.svg";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Rooms } from "./Rooms";
import { useDispatch, useSelector } from "react-redux";
import { Button, message } from "antd";
import { deleteMultipleRooms } from "../../../../services/rooms";
import NavigatingButtons from "../navigatingButtons";
import {
  saveAllRoomAsync,
  getAllRoomsAsyncThunk,
} from "../../../../reducer/roomsReducer";
import { updateHotelDetailsAsync } from "../../../../reducer/loginReducer";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Capacity = ({ incrementId, decrementId }) => {
  const [config, setConfig] = useState([]);
  const [deleteRooms, setDeleteRooms] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const { hotelDetails } = useSelector((store) => store.login);
  const rooms = useSelector((store) => store.rooms);
  const { response } = useSelector((store) => store.rooms);
  const dispatch = useDispatch();
  const [inputValueArray, setInputValueArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const roomTypes = hotelDetails?.roomTypes;

    const roomConfig = [];

    if (!!roomTypes?.length) {
      roomTypes?.forEach((roomType) => {
        roomConfig.push({
          ...roomType,
          roomNumbers: rooms?.response
            .map((room) => (room.type === roomType.name ? room.name : null))
            .filter((roomNumber) => roomNumber !== null), // Filter out null values

          isExpanded: true,
        });
      });
      const allRoomsUpdated = [];

      roomConfig?.forEach((room) => {
        room?.roomNumbers.forEach((roomNumber) =>
          allRoomsUpdated.push(roomNumber)
        );
      });
      setAllRooms(allRoomsUpdated);
      setConfig([...roomConfig]);
    }
  }, [response, hotelDetails]);

  const onRoomNumberChangeModified = (e, index, roomData) => {
    let value = "";
    if (Boolean(inputValueArray[index])) {
      value = inputValueArray[index]?.trim();
    }

    if (!roomData.rate) {
      message.warning("Please enter room rate!");
      return;
    }

    if (value !== "") {
      let roomNumbers = [];
      const inputArray = value.split(/[,\s.]+/);

      inputArray?.forEach((Item) => {
        const isRange = /-/.test(Item);

        if (isRange) {
          const responsArray = numberBetweenDash(Item);
          responsArray?.forEach((Obj) => roomNumbers?.push(Obj));
        } else roomNumbers?.push(Item);
      });

      const validatedRoomNumber = roomNumbers.filter(
        (roomNumber) => !allRooms.includes(roomNumber)
      );

      const updatedArray = [
        ...new Set([
          ...new Set(validatedRoomNumber),
          ...config[index].roomNumbers,
        ]),
      ];

      const newAllRooms = [...new Set([...allRooms, ...updatedArray])];

      setAllRooms(newAllRooms);

      config[index].roomNumbers = updatedArray;
      setConfig([...config]);
      e.target.value = "";
      inputValueArray[index] = "";
      setInputValueArray(inputValueArray);
    }

    onSave(roomData, index);
  };
  const onSave = async (roomData, index) => {
    if (!roomData.roomNumbers?.length) {
      message.warning("Please enter at least 1 room!");
      return;
    }
    setLoading(true);

    let alreadyCreatedRooms = [];
    alreadyCreatedRooms =
      rooms?.response &&
      rooms?.response
        .map((room) => (room.type === roomData.name ? room.name : null))
        .filter((roomNumber) => roomNumber !== null); // Filter out null values

    config[index] = {
      ...roomData,
      guestsAllowed: roomData.guestsAllowed,
      rate: roomData.rate,
      defaultOccupancy: roomData?.defaultOccupancy,
    };

    setConfig(config);

    const payload = {
      hotelId: hotelDetails?.id,
      body: {
        ...hotelDetails,
        roomTypes: config,
      },
    };
    dispatch(updateHotelDetailsAsync(payload));

    alreadyCreatedRooms?.length !== config[index].roomNumbers.length
      ? saveRoomNumbers(
          alreadyCreatedRooms,
          roomData.roomNumbers,
          roomData.name,
          index
        )
      : onRoomSaved(index);

    setLoading(false);
  };

  const saveRoomNumbers = async (
    alreadyCreatedRooms,
    roomData,
    name,
    index
  ) => {
    const roomsEarlier = alreadyCreatedRooms;

    const newRooms = [...roomData]
      .map((room) => {
        if (!roomsEarlier.includes(room)) {
          return {
            createdAt: new Date().toISOString(),
            hotelId: hotelDetails.id,
            id: null,
            name: room,
            status: "active",
            type: name,
            updatedAt: new Date().toISOString(),
          };
        } else return null;
      })
      .filter((item) => item !== null);

    await dispatch(saveAllRoomAsync(newRooms));
    onRoomSaved(index);
  };

  const numberBetweenDash = (inputString) => {
    // Split the string by hyphen
    const rangeArray = inputString.split("-").map(Number);

    if (
      rangeArray.length === 2 &&
      !isNaN(rangeArray[0]) &&
      !isNaN(rangeArray[1])
    ) {
      let start;
      let end;

      if (rangeArray[0] < rangeArray[1]) {
        start = rangeArray[0];
        end = rangeArray[1];
      } else {
        end = rangeArray[0];
        start = rangeArray[1];
      }

      // Generate an array of numbers between start and end (inclusive)
      const numbersInRange = Array.from(
        { length: end - start + 1 },
        (_, index) => start + index
      );

      return numbersInRange;
    }
  };

  const onRateEdited = (event, index) => {
    if (
      !Number.isNaN(Number(event.target.value)) &&
      Number(event.target.value > 0)
    )
      config[index].rate = Number(event.target.value);
    else {
      config[index].rate =
        event.target.value === "" ? null : config[index].rate;
    }
    setConfig([...config]);
    config[index].isExpanded = true;
  };

  const onCountChange = (event, index, key) => {
    if (key === "inc") {
      config[index].guestsAllowed = config[index].guestsAllowed + 1;
    } else if (key === "dec") {
      config[index].guestsAllowed =
        config[index].guestsAllowed > 1
          ? config[index].guestsAllowed - 1
          : config[index].guestsAllowed;
    }
    setConfig([...config]);
    config[index].isExpanded = true;
  };

  const onRoomSaved = (index) => {
    const resetInput = document.getElementById(`input-${config[index]?.name}`);
    resetInput.value = "";
    config[index] = { ...config[index], isExpanded: true };
    setConfig([...config]);
    getAllRoom();
  };

  const onRoomDeleted = async (roomNumbers, index) => {
    if (!roomNumbers) return;
    if (!response) return;
    setLoading(true);

    if (deleteRooms.length == 0) {
      message.error("Please select the Room which you want to delete");
      setLoading(true);
      return;
    }

    const filteredRooms = response?.filter((room) =>
      deleteRooms.includes(room.name)
    );

    const deletedRoomsId = filteredRooms?.map((room) => room?.id);
    const deletedRoomsName = filteredRooms?.map((room) => room?.name);

    {
      let deletepayload = {
        hotelId: hotelDetails?.id,
        data: deletedRoomsId,
      };
      const response = await deleteMultipleRooms(deletepayload);

      if (response.status == 200) {
        message.success("Room deleted Successfully");
        getAllRoom();
        setDeleteRooms([]);

        const newConfigObject = JSON.parse(JSON.stringify(config[index]));
        let updatedRoomNumber = newConfigObject?.roomNumbers?.filter(
          (roomNumber) => !deletedRoomsName.includes(roomNumber)
        );

        const newAllRooms = allRooms?.filter(
          (roomNumber) => !deletedRoomsName.includes(roomNumber.toString())
        );

        setAllRooms(newAllRooms);

        newConfigObject.roomNumbers = updatedRoomNumber;

        let updatedConfig = JSON.parse(JSON.stringify(config));
        updatedConfig[index] = newConfigObject;

        setConfig(updatedConfig);
      } else message.error("Something Went wrong!");
    }
    setLoading(false);
  };

  const selectDeleteRooms = (e, room) => {
    if (e.target.checked) {
      setDeleteRooms([...deleteRooms, room]);
    } else {
      let updatedRooms = [...deleteRooms]; // Create a copy of the deleteRooms array

      let index = updatedRooms.indexOf(room);
      if (index !== -1) {
        updatedRooms.splice(index, 1); // Remove the element using splice
        setDeleteRooms(updatedRooms); // Update the state with the modified array
      }
    }
  };

  const onConfirm = () => navigate("/confirmed");

  const getAllRoom = () =>
    dispatch(getAllRoomsAsyncThunk({ hotelId: hotelDetails?.id }));

  return (
    <div>
      <div className="flex flex-col justify-center gap-2 items-start">
        <h2 className="text-[2rem] mb-2 font-[600] font-[Poppins] uppercase text-[#4F4F4F] tracking-[0.07813rem]">
          Accomodation Capacity
        </h2>
      </div>
      <div className="mt-[10px] mb-[6rem]">
        {!!config?.length ? (
          config?.map((data, index) => {
            const { name, roomNumbers, rate, guestsAllowed } = data;
            return (
              <div
                className="mt-[20px] shadow-lg py-[45px] px-[24px] rounded-[10px]"
                key={`${name}/${index}`}
              >
                <div className="flex justify-between items-center">
                  <p className="text-2xl font-semibold text-[#4F4F4F]">
                    {`${name} (${roomNumbers?.length ?? 0})`}
                  </p>
                  {loading ? (
                    <div>
                      <CircularProgress size={16} />
                    </div>
                  ) : (
                    <Button
                      className="text-lg font-semibold text-[#fff] bg-[#007FFF]  rounded-[8px] py-5 px-5 
                      flex justify-center items-center"
                      onClick={(e) =>
                        onRoomNumberChangeModified(e, index, data)
                      }
                    >
                      Save
                    </Button>
                  )}
                </div>
                <div className="mt-[20px] flex justify-between items-center">
                  <div>
                    <p className="text-lg text-[#1d1d1d] mb-[14px]">
                      Enter room no. separated by comma
                    </p>
                    <div className="flex justify-start items-center bg-[#F4F4F4] p-[10px] rounded-[10px]">
                      <img src={room} alt="room" className="mr-[13px]" />
                      <input
                        value={
                          Boolean(inputValueArray[index])
                            ? inputValueArray[index]
                            : ""
                        }
                        onChange={(e) => {
                          let value = e.target.value;

                          let arrayState = JSON.parse(
                            JSON.stringify(inputValueArray)
                          );
                          arrayState[index] = value;
                          setInputValueArray(arrayState);
                        }}
                        placeholder="For Eg: 104 - 112, 124, 126"
                        className="w-full h-[35px] bg-transparent focus:border-0 focus:outline-0 rounded-[4px]"
                        id={`input-${name}`}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text-lg text-[#1d1d1d] mb-[14px]">
                      No. of Guests
                    </p>
                    <div className="flex justify-start items-center">
                      <span
                        className="text-[#007FFF] cursor-pointer flex justify-center items-center w-[32px] mr-[10px] h-[32px] bg-[#F0F0F0] rounded-full"
                        onClick={(e) => onCountChange(e, index, "dec")}
                      >
                        <FaMinus className="" />
                      </span>
                      <span
                        type="text"
                        className="w-[55px] text-center text-[#007FFF] font-semibold"
                      >
                        {guestsAllowed ?? 1}
                      </span>
                      <span
                        className="text-[#007FFF] cursor-pointer flex justify-center items-center w-[32px] h-[32px] bg-[#F0F0F0] rounded-full ml-[10px] "
                        onClick={(e) => onCountChange(e, index, "inc")}
                      >
                        <FaPlus className="" />
                      </span>
                    </div>
                  </div>
                  <div>
                    <p className="text-lg text-[#1d1d1d] mb-[14px]">
                      Base Price
                    </p>
                    <input
                      className="bg-[#F4F4F4] p-[10px] rounded-[10px]"
                      placeholder="INR"
                      value={rate ?? ""}
                      onChange={(e) => onRateEdited(e, index)}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between items-center mt-[32px]">
                    <p className="text-lg font-semibold text-[#1d1d1d]">
                      Rooms available
                    </p>

                    {loading ? (
                      <div>
                        <CircularProgress size={16} />
                      </div>
                    ) : (
                      <span
                        className="text-[#F36960] hover:cursor-pointer font-[Poppins] text-[1.125rem] font-[500] 
                      leading-[1.125rem] uppercase tracking-[0.07813rem]"
                        onClick={() => onRoomDeleted(roomNumbers, index)}
                      >
                        Delete
                      </span>
                    )}
                  </div>

                  <Rooms
                    rooms={roomNumbers}
                    roomType={name}
                    deleteRooms={deleteRooms}
                    selectDeleteRooms={selectDeleteRooms}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div>Please Select Room Type</div>
        )}
      </div>
      <NavigatingButtons
        currentId={3}
        incrementId={onConfirm}
        decrementId={decrementId}
      ></NavigatingButtons>
    </div>
  );
};

export default Capacity;
