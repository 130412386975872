import { Button } from "antd";
import React from "react";
import { HiPlusSm } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TiTick } from "react-icons/ti";

import { useDispatch } from "react-redux";
import { updateSelectedSteward } from "../../../../../../reducer/posReducer/restaurant";
import { TranslateText } from "../../../../../../utils/translation";

const Restaurant = ({
  tableOrderDetails,
  selectedSteward,
  isSteward,
  setIsSteward,
  hotelDetails,
  handleRider,

  styles,
  selectedRider,
  stewardList,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {!(
        tableOrderDetails?.type === "delivery" ||
        tableOrderDetails?.type === "pickup"
      ) && (
        <>
          <div className="flex mt-2">
            <div className="flex mr-4 flex-reverse flex-wrap ">
              {selectedSteward
                ?.filter((Obj) => Boolean(Obj))
                ?.map((item) => {
                  if (Boolean(item)) {
                    return (
                      <Button
                        className="flex bg-[#EAF2FD]  mr-2 mb-2 float-right"
                        shape="round"
                      >
                        <span>{item}</span>
                      </Button>
                    );
                  }
                })}
            </div>

            <div className=" w-[10rem]">
              {!isSteward ? (
                <Button
                  onClick={() => setIsSteward(true)}
                  className="flex bg-[#EAF2FD] text-[#2F80ED] border-[#2F80ED]"
                  shape="round"
                >
                  <span>  
                  <TranslateText textKey={"add_steward"} namespace="pos" />

                  </span>
                  <HiPlusSm className="mt-1 text-1xl" />
                </Button>
              ) : (
                <div
                  onBlur={() => setIsSteward(false)}
                  className="shadow p-4 absolute bg-white z-50 w-[11rem]  rounded-sm right-2"
                >
                  <h1
                    onClick={() => setIsSteward(false)}
                    className="flex text-primary justify-between text-sm cursor-pointer"
                  >
                    <span className=" w-[80%] "> 
                    <TranslateText textKey={"select_steward"} namespace="pos" />

                    </span>
                    <AiOutlinePlus className="mt-[2px]" />
                  </h1>
                  <hr className="mb-4 mt-2" />
                  <ul>
                    {stewardList?.map((item) => {
                      return (
                        <li
                          onClick={() => dispatch(updateSelectedSteward(item))}
                          key={item}
                          title={item}
                          className={`${
                            selectedSteward.indexOf(item) >= 0
                              ? "text-primary"
                              : ""
                          } py-2 mb-1 text-xs  hover:bg-[#2f80ed1a] rounded-sm cursor-pointer flex justify-between`}
                        >
                          {" "}
                          <div>
                            <span className="bg-[#F1F1F1] py-1 px-2 rounded-full border-[#0000000d] mr-1 capitalize">
                              {item?.split(" ")?.[0]?.[0]}
                            </span>{" "}
                            <span className="capitalize">
                              {item?.length > 8
                                ? item?.slice(0, 8) + "..."
                                : item}
                            </span>
                          </div>
                          <div className="flex justify-between">
                            {selectedSteward.indexOf(item) >= 0 && (
                              <span>
                                <TiTick />
                              </span>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {tableOrderDetails?.type === "delivery" && (
        <FormControl>
          <InputLabel>
          <TranslateText textKey={"select_rider"} namespace="pos" />

          </InputLabel>
          <Select
            className="w-[200px] h-[2.6rem] mt-2"
            value={selectedRider}
            label="Select Rider"
            onChange={(e) => handleRider(e.target.value)}
          >
            {stewardList?.map((item, key) => {
              return (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default Restaurant;
