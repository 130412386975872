import { useState } from "react";

const useDebounce = (callback, delay) => {
  const [timerId, setTimerId] = useState(null);

  return (...args) => {
    clearTimeout(timerId);
    const newTimerId = setTimeout(() => {
      callback(...args);
    }, delay);
    setTimerId(newTimerId);
  };
};

export default useDebounce;
