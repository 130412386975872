import BrandLogo from "../../../assets/images/svgs/Frame.svg";
import Logo from "../../../assets/logo/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveMenu } from "../../../reducer/appReducer";
import { useNavigate } from "react-router-dom";

const SideBarImage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menuDrawer } = useSelector((store) => store.app);
  const { displayOverlayingHamburgerMenu } = useSelector(
    (store) => store.appHeader
  );

  const home = () => {
    dispatch(
      updateActiveMenu({
        activeMenu: "home",
        pos: false,
      })
    );

    navigate("/dashboard");
  };

  return (
    <>
      {displayOverlayingHamburgerMenu ? (
        <div className="pt-[55px] pr-[20px] pl-[25px] cursor-pointer">
          <img
            onClick={() => home()}
            src={BrandLogo}
            className="h-[30px] md:h-[40px] lg:h-[50px]"
            alt="Revivo Brand "
          />
        </div>
      ) : (
        <div className="pt-[55px] pr-[20px] pl-[25px] cursor-pointer">
          <img
            onClick={() => home()}
            src={menuDrawer ? Logo : BrandLogo}
            className="h-[30px] md:h-[40px] lg:h-[50px]"
            alt="Revivo Brand "
          />
        </div>
      )}
    </>
  );
};
export default SideBarImage;
