import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false, // before api call
  apiError: false,
  showRoomNumberModal: false,
  showMainBookingDetailsDrawer: true,
  showPrimaryGuestDrawer: false,
  showAddonsDrawer: false,
  showTakePaymentDrawer: false,
  showApplyDiscountDrawer: false,
  showGrcDrawer: false,
  showFolio: false,
};

export const bookingDetailsSlice = createSlice({
  name: "bookingDetails",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_30: () => initialState,

    updateShowRoomNumberModal: (state, action) => {
      state.showRoomNumberModal = !state.showRoomNumberModal;
    },
    updateShowMainBookingDetailsDrawer: (state, action) => {
      state.showMainBookingDetailsDrawer = !state.showMainBookingDetailsDrawer;
    },
    updateShowPrimaryGuestDrawer: (state, action) => {
      state.showPrimaryGuestDrawer = !state.showPrimaryGuestDrawer;
    },
    updateShowAddonsDrawer: (state, action) => {
      state.showAddonsDrawer = !state.showAddonsDrawer;
    },
    updateShowTakePaymentDrawer: (state, action) => {
      state.showTakePaymentDrawer = !state.showTakePaymentDrawer;
    },
    updateShowApplyDiscountDrawer: (state, action) => {
      state.showApplyDiscountDrawer = !state.showApplyDiscountDrawer;
    },
    updateShowGrcDrawer: (state, action) => {
      state.showGrcDrawer = !state.showGrcDrawer;
    },
    updateShowFolioDrawer: (state, action) => {
      state.showFolio = !state.showFolio;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_30,
  updateShowRoomNumberModal,
  updateShowMainBookingDetailsDrawer,
  updateShowPrimaryGuestDrawer,
  updateShowAddonsDrawer,
  updateShowTakePaymentDrawer,
  updateShowApplyDiscountDrawer,
  updateShowGrcDrawer,
  updateShowFolioDrawer,
} = bookingDetailsSlice.actions;

export default bookingDetailsSlice.reducer;
