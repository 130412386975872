import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa6";
import {
  clearSelectedItem,
  createItem,
  updateAction,
  updateAddonList,
  updateSelectedItem,
  updateIsAddItemDrawerOpen,
} from "../../../../reducer/getItemReducer";

import SelectInput from "./SelectInput";
import CustomTextField from "../../../../components/common/InputField/Input";
import {
  ACTIVE_POS_DETAILS,
  FOOD_CHOICE_OBJ,
  FOOD_TYPE,
  ITEM_STATUS,
  POS_SERVICE_TYPE,
  POS_TYPE,
} from "../../../../utils/posHelper";
import { UpdatePosSettingForm } from "../../../../reducer/posReducer/posSettingReducer";
import { Switch, message } from "antd";
import { CircularProgress } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa6";
import CustomTaxModal from "../../../../components/common/components/CustomTaxModal";

const foodType = FOOD_TYPE;
const itemStatus = ITEM_STATUS;
const itemServiceType = POS_SERVICE_TYPE;

const AddItem = ({ addItemSectionVisible, setAddItemSectionVisible }) => {
  const { response, activePosType, activePosSlug, activePosId } = useSelector(
    (store) => store.posSetting
  );

  const { createLoading, selectedItem } = useSelector(
    (store) => store.getItems
  );

  const { gstRate } = ACTIVE_POS_DETAILS(response, activePosSlug);

  useEffect(() => {
    // service type
    let itemObj = selectedItem;
    let name = "serviceTypes";
    itemObj = {
      ...itemObj,
      [name]: itemServiceType,
      active: itemStatus[0],
      foodChoice: foodType[0],
      gstRate: gstRate,
    };

    dispatch(updateSelectedItem(itemObj));
  }, [selectedItem?.id]);

  let categories = response?.filter((item) => item.slug == activePosSlug)?.[0]
    ?.itemCategories;

  let units = response?.filter((item) => item.slug == activePosSlug)?.[0]
    ?.itemUnits;

  const [isAddon, setIsAddon] = useState(false);

  const { hotelDetails } = useSelector((store) => store.login);

  const dispatch = useDispatch();

  const handleItem = (e) => {
    let itemObj = selectedItem;

    let name = e.target.name;
    let value = e.target.value;

    itemObj = { ...itemObj, [name]: value };

    dispatch(updateSelectedItem(itemObj));
  };

  const handlePriceItem = (key, value) => {
    let itemObj = selectedItem;

    let pricesByType = Boolean(itemObj?.pricesByType)
      ? itemObj?.pricesByType
      : {};

    itemObj = {
      ...itemObj,
      pricesByType: { ...pricesByType, [key]: value },
    };

    dispatch(updateSelectedItem(itemObj));
  };

  const handleItemsAddon = (name, value, key) => {
    let addonList = selectedItem?.addOns;

    let newAddonList = addonList?.map((addon, addonKey) => {
      if (addonKey === key) {
        if (name == "addonName") return { ...addon, name: value };
        else return { ...addon, price: value };
      }
      return addon;
    });

    let payload = {
      ...selectedItem,
      addOns: newAddonList,
    };

    dispatch(updateSelectedItem(payload));
  };

  let isValidate =
    Boolean(selectedItem["name"]) && Boolean(selectedItem["category"]);

  if (POS_TYPE[0] == activePosType) {
    if (Array.isArray(selectedItem?.serviceTypes)) {
      selectedItem?.serviceTypes?.forEach((item) => {
        if (!Boolean(selectedItem?.pricesByType?.[item])) {
          isValidate = false;
        }
      });
    } else {
      isValidate = false;
    }
  } else {
    if (Boolean(selectedItem["price"])) isValidate = true;
    else isValidate = false;
  }

  // Boolean(selectedItem?.pricesByType?.["dinein"]);

  const handleValidation = async () => {
    if (!Boolean(selectedItem?.name)) {
      message.info("Please Enter item name..");
    } else if (!Boolean(selectedItem?.category)) {
      message.info("Please select the item category..");
    } else {
      let isItemPriceValid = true;

      if (POS_TYPE[0] == activePosType) {
        if (Array.isArray(selectedItem?.serviceTypes)) {
          selectedItem?.serviceTypes?.forEach((item) => {
            if (!Boolean(selectedItem?.pricesByType?.[item])) {
              isItemPriceValid = false;
            }
          });
        } else {
          isItemPriceValid = false;
        }
      } else {
        if (Boolean(selectedItem["price"])) {
          isValidate = true;
        } else {
          isValidate = false;
        }
      }

      let isAddonValid = Boolean(selectedItem?.addOns)
        ? selectedItem?.addOns?.every((item) => {
            if (Boolean(item?.name) && Boolean(item?.price)) {
              return true;
            } else {
              return false;
            }
          })
        : true;

      if (!Boolean(selectedItem["serviceTypes"])) {
        message.info("Please Select Service charge..");
      } else {
        if (!isItemPriceValid) {
          message.info("Please Enter item price..");
        } else if (!isAddonValid) {
          message.info("Please Enter addon name or addon price..");
        }
      }
    }
    handleSubmit();
  };

  const handleSubmit = async () => {
    let payload = {
      ...selectedItem,
      active: true,
      foodChoice: Object.keys(FOOD_CHOICE_OBJ).filter(
        (key) => FOOD_CHOICE_OBJ[key] === selectedItem.foodChoice
      )?.[0],
      gstRate: Boolean(selectedItem?.gstRate) ? selectedItem?.gstRate : gstRate,
      addOns: Boolean(selectedItem?.addOns) ? selectedItem?.addOns : null,
      hotelId: hotelDetails?.id,
      posId: activePosId,
    };

    window.scroll(0, 0);
    dispatch(createItem(payload));
    handleClose();
  };

  const handleAddItem = (itemName, type) => {
    if (type == "category") {
      createCategory(itemName);
    } else if (type === "unit") {
      createUnit(itemName);
    }
  };

  const createCategory = (itemName) => {
    let isCategoryExist = Array.isArray(categories)
      ? categories?.filter(
          (item) => item.toLowerCase() === itemName.toLowerCase()
        )
      : [];

    if (isCategoryExist?.length === 0) {
      let updatedCategory = Array.isArray(categories)
        ? [...categories, itemName]
        : [itemName];

      handleUpdate("itemCategories", updatedCategory, "Category");
    } else {
      message.info("Item already exist..");
    }
  };

  const createUnit = (itemName) => {
    let isUnitExist = Array.isArray(units)
      ? units?.filter((item) => item.toLowerCase() === itemName.toLowerCase())
      : [];

    if (isUnitExist?.length === 0) {
      let updatedUnit = Array.isArray(units)
        ? [...units, itemName]
        : [itemName];

      handleUpdate("itemUnits", updatedUnit, "Unit");
    } else {
      message.info("Item already exist..");
    }
  };

  const handleUpdate = (key, value, Field) => {
    let body = response?.filter((item) => item.slug === activePosSlug)?.[0];
    let payload = {
      ...body,
      [key]: value,
      id: activePosId,
      message: `${Field} has been addedd...`,
    };

    dispatch(UpdatePosSettingForm(payload));
  };

  const handleClose = () => {
    dispatch(updateAction(""));
    dispatch(clearSelectedItem());
    setAddItemSectionVisible(false);
    dispatch(updateIsAddItemDrawerOpen(false));
  };

  return (
    <div className="flex flex-col mt-4 px-4 justify-between relative max-h-screen overflow-y-auto no-scrollbar bg-[#F2F4FC]">
      <header className="flex fixed top-0 left-0 w-[100%] p-2 px-3 bg-[#FFF] z-[10] justify-between shadow-md font-outfit font-[500] text-[18px] leading-[27px] text-[#222222]">
        <div className="flex gap-2 items-center">
          <FaArrowLeft
            className="text-[#222222] text-[14px]"
            onClick={() => handleClose()}
          />
          Add Item
        </div>

        <div className="flex gap-2 items-center">
          <Switch
            className="bg-[#00000040]"
            // defaultChecked={selectedItem?.active === "Active"}
            checked={Boolean(selectedItem?.active === "Active" ? true : false)}
            onChange={(value) => {
              const updatedItem = {
                ...selectedItem,
                active: value ? "Active" : "Deactive",
              };

              dispatch(updateSelectedItem(updatedItem));
            }}
          />

          <div className="font-outfit font-[500] text-[14px] leading-[17.64px] text-[#222]">
            {" "}
            Active
          </div>
        </div>
      </header>

      <form
        className="flex flex-col gap-3 mt-[3rem] bg-[#F2F4FC]"
        onSubmit={handleSubmit}
      >
        <div className="font-outfit font-[500] text-[16px] leading-[20.16px] text-[#222222]">
          Item Details
        </div>

        <div className="bg-[#FFF] p-4 py-5 flex flex-col gap-6 rounded-[12px]">
          <CustomTextField
            label="Name"
            isManadatory={true}
            value={selectedItem["name"]}
            onChange={(value) => {
              let itemObj = selectedItem;

              itemObj = { ...itemObj, ["name"]: value };

              dispatch(updateSelectedItem(itemObj));
            }}
            type="text"
            inputPadding={true}
            labelTextColor={"#8F9BB3"}
          />

          <SelectInput
            label={"Select Item Category"}
            value={
              Boolean(selectedItem["category"]) ? selectedItem["category"] : []
            }
            multiple={false}
            menuItem={categories}
            addNewItem={true}
            handleAddItem={(itemName) => handleAddItem(itemName, "category")}
            name="category"
            handleChange={handleItem}
          />

          <SelectInput
            label={"Select Item Unit"}
            addNewItem={true}
            handleAddItem={(itemName) => handleAddItem(itemName, "unit")}
            value={Boolean(selectedItem["unit"]) ? selectedItem["unit"] : []}
            multiple={false}
            menuItem={units}
            name="unit"
            handleChange={handleItem}
          />

          <CustomTaxModal
            data={selectedItem}
            handleSaveChanges={() => {}}
            setAllInputData={updateSelectedItem}
            isAddNewTax={true}
            isDispatchUsedInsteadOfLoaclState={true}
          />

          {activePosType === POS_TYPE[0] && (
            <SelectInput
              label={"Select Item Type"}
              value={
                Boolean(selectedItem["foodChoice"])
                  ? selectedItem["foodChoice"]
                  : []
              }
              multiple={false}
              menuItem={foodType}
              name="foodChoice"
              handleChange={handleItem}
            />
          )}

          {activePosType === POS_TYPE[0] && (
            <SelectInput
              label={"Select item Service"}
              value={
                Boolean(selectedItem["serviceTypes"])
                  ? selectedItem["serviceTypes"]
                  : []
              }
              multiple={true}
              menuItem={itemServiceType}
              name="serviceTypes"
              handleChange={handleItem}
            />
          )}
        </div>

        <div className="flex flex-col gap-2 mt-2 ">
          <span className="font-outfit font-[500] text-[16px] leading-[20.16px] text-[#222222] mb-2 ">
            Pricing Details
          </span>

          <div className="bg-[#FFF] p-4 py-5 flex flex-col gap-6 rounded-[12px]">
            {activePosType === POS_TYPE[0] ? (
              <>
                {Boolean(selectedItem["serviceTypes"]) && (
                  <div className="flex flex-col gap-3">
                    {selectedItem["serviceTypes"]?.map((item, key) => {
                      return (
                        <div className="flex justify-between items-center mb-2">
                          <label className="font-outfit w-[30%] font-[500] text-[14px] leading-[21px] capitalize">
                            {item}
                          </label>
                          <div className="w-[70%]">
                            <CustomTextField
                              type="number"
                              placeholder="Price"
                              label=""
                              name={item}
                              value={selectedItem?.pricesByType?.[item]}
                              onChange={(value) => handlePriceItem(item, value)}
                              inputPadding={true}
                              labelTextColor={"#8F9BB3"}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="flex justify-between items-center mb-2">
                  <label className="font-outfit w-[30%] font-[500] text-[14px] leading-[21px] capitalize">
                    Price
                  </label>
                  <div className="w-[70%]">
                    <CustomTextField
                      type="number"
                      placeholder="price"
                      name="Price"
                      value={
                        Boolean(selectedItem["price"])
                          ? selectedItem["price"]
                          : ""
                      }
                      onChange={(value) => {
                        let itemObj = selectedItem;

                        itemObj = { ...itemObj, ["price"]: value };

                        dispatch(updateSelectedItem(itemObj));
                      }}
                      inputPadding={true}
                      labelTextColor={"#8F9BB3"}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2 w-[100%] mt-2 mb-[10rem] mx-auto bg-[#FFF] p-3 rounded-[8px]">
          <div
            className="flex items-center  justify-between "
            onClick={() => setIsAddon(!isAddon)}
          >
            <div className="font-outfit font-[500] text-[16px] leading-[20.16px] text-[#222]">
              {" "}
              Add-ons
            </div>

            {isAddon ? (
              <FaChevronUp className="text-[#007FFF]" />
            ) : (
              <FaChevronDown className="text-[#007FFF]" />
            )}
          </div>

          {Boolean(isAddon) && (
            <div className="flex flex-col gap-2 p-2">
              {selectedItem?.addOns?.map((pd, key) => {
                return (
                  <div className="flex flex-col gap-4  " key={key}>
                    <div className="flex justify-between items-center">
                      <CustomTextField
                        label="Name"
                        isManadatory={true}
                        value={pd.name}
                        onChange={(value) =>
                          handleItemsAddon("addonName", value, key)
                        }
                        className="w-[90%]"
                        type="text"
                        inputPadding={true}
                        labelTextColor={"#8F9BB3"}
                      />

                      <MdDelete
                        onClick={() =>
                          dispatch(
                            updateAddonList({
                              decrease: true,
                              key: key,
                            })
                          )
                        }
                        className="text-red-600  text-[18px] w-[10%] cursor-pointer"
                      />
                    </div>

                    <div className="flex justify-between items-center w-[100%]">
                      <label className="font-outfit font-[500] text-[14px] leading-[21px] text-[#222] w-[50%]">
                        Add-on Price
                      </label>
                      <CustomTextField
                        type="number"
                        className="w-[50%]"
                        placeholder="price"
                        name="addonPrice"
                        value={pd.price}
                        onChange={(value) =>
                          handleItemsAddon("handleItemsAddon", value, key)
                        }
                        inputPadding={true}
                        labelTextColor={"#8F9BB3"}
                      />
                    </div>
                  </div>
                );
              })}

              <div
                onClick={() =>
                  dispatch(
                    updateAddonList({
                      increase: true,
                    })
                  )
                }
                className="flex justify-start mt-1 cursor-pointer w-[95%] text-[#007FFF] font-outfit font-[500] text-[14px] leading-[17.64px]"
              >
                + Add new
              </div>
            </div>
          )}
        </div>
      </form>

      <footer className="bg-[#FFF] fixed bottom-0 w-[100%] left-0 shadow-mobile-footer flex justify-center items-center px-3 py-6 z-[50]">
        {createLoading ? (
          <div className="w-[95%] bg-[#007FFF] rounded-[12px] p-3 flex justify-center items-center font-outfit font-[500] text-[16px] leading-[24px] text-[#FFF]">
            <CircularProgress size={16} />
          </div>
        ) : (
          <div
            onClick={handleValidation}
            type="submit"
            className="w-[95%] bg-[#007FFF] rounded-[12px] p-3 flex justify-center items-center font-outfit font-[500] text-[16px] leading-[24px] text-[#FFF]"
          >
            ADD ITEM
          </div>
        )}
      </footer>
    </div>
  );
};

export default AddItem;
