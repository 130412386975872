import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as Reports } from "../../assets/images/mobileDashboardSetting/reports.svg";
import { ReactComponent as ChannelManager } from "../../assets/images/mobileDashboardSetting/channelManager.svg";
import { ReactComponent as Expense } from "../../assets/images/mobileDashboardSetting/expense.svg";
import { ReactComponent as HouseKeeping } from "../../assets/images/mobileDashboardSetting/housekeeping.svg";
import { ReactComponent as Invoice } from "../../assets/images/mobileDashboardSetting/invoice.svg";
import { ReactComponent as Party } from "../../assets/images/mobileDashboardSetting/party.svg";
import { ReactComponent as Clients } from "../../assets/images/mobileDashboardSetting/user.svg";
import { ReactComponent as BookingEngine } from "../../assets/images/mobileDashboardSetting/web.svg";

const disallowedEnterpriseSettings = [
  "Client",
  "Invoice",
  "Channel Manager",
  "Housekeeping",
  "Party",
  "Expense",
];

const Service = () => {
  const navigate = useNavigate();
  const { hotelDetails, isAllHotelsSelected } = useSelector(
    (store) => store.login
  );

  let websiteURL = `${process.env.REACT_APP_HOTEL_WEBSITE_URL}/${hotelDetails?.id}`;

  const serviceArray = [
    { title: "Client", icon: Clients, link: "/dashboard/clients" },
    {
      title: "Channel Manager",
      icon: ChannelManager,
      link: "/dashboard/channel-manager",
    },
    {
      title: "Housekeeping",
      icon: HouseKeeping,
      link: "/dashboard/house-keeping",
    },

    { title: "Reports", icon: Reports, link: "/dashboard/reports" },
    { title: "Party", icon: Party, link: "/dashboard/party" },
    { title: "Expense", icon: Expense, link: "/dashboard/expense" },

    { title: "Invoice", icon: Invoice, link: "/dashboard/invoice" },
    { title: "Booking Engine ", icon: BookingEngine, link: websiteURL },
  ];

  return (
    <div className="w-[100%] flex justify-center items-center">
      <div className="flex flex-col gap-3 bg-[#FFF] p-4 w-[90%] mx-4 rounded-[24px]">
        <header className="font-outfit font-[600] text-[16px] leading-[24px] tracking-[wider] text-[#222222]">
          Services
        </header>

        <main className=" flex flex-col gap-3  ">
          {serviceArray
            ?.filter(({ title }) =>
              isAllHotelsSelected
                ? !disallowedEnterpriseSettings.includes(title)
                : true
            )
            ?.map((Obj, index) => {
              return (
                <Link
                  target={index === serviceArray?.length - 1 ? "_blank" : ""}
                  to={
                    index === 0 || index === 6 || index === 7 ? Obj?.link : ""
                  }
                  onClick={
                    index === 0 || index === 6 || index === 7
                      ? () => navigate(Obj?.link)
                      : undefined
                  }
                  key={Obj?.tite}
                  className={` index !== serviceArray?.lngth - 1 ? "border-l-0 border-r-0 border-t-0 pb-2 " : "border-0 border-[#FFF]" } flex gap-4 justify-start items-center `}
                >
                  <div className="w-[34px] h-[34px] rounded-[50%] bg-[#E9F4FF] flex justify-center items-center">
                    <Obj.icon />
                  </div>
                  <div className="font-outfit font-[400] text-[14px] leading-[21px] text-[#8F9BB3]">
                    {Obj?.title}
                  </div>
                </Link>
              );
            })}
        </main>
      </div>
    </div>
  );
};

export default Service;
