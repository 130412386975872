import { authedAxios } from "../utils/customHttpHeader";

const getPartyService = async (payload, url) => {
  let endpoint = url + "?hotelId=" + payload?.hotelId;
  endpoint += "&sortingField=createdAt&sortingDirection=DESC";

  if (Boolean(payload?.partyTypes)) {
    endpoint += "&partyTypes=" + payload?.partyTypes;
  }
  if (Boolean(payload?.partyType)) {
    endpoint += "&partyType=" + payload?.partyType;
  }
  if (Boolean(payload?.pageNumber)) {
    endpoint += "&pageNumber=" + payload?.pageNumber;
  }
  if (Boolean(payload?.pageSize)) {
    endpoint += "&pageSize=" + payload?.pageSize;
  }

  if (Boolean(payload?.searchText)) {
    endpoint += "&searchText=" + payload?.searchText;
  }

  const response = await authedAxios().get(endpoint);
  return response;
};

const postPartyService = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

const postCountryStatesService = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

const postStateCitiesService = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

const putPartyService = async (payload, endpoint) => {
  const response = await authedAxios().put(endpoint, payload);
  return response;
};

const getPartyByIdService = async (payload, endpoint) => {
  let url = endpoint;

  url += `/${payload.id}`;
  url += `?hotelId=${payload.hotelId}`;

  const response = await authedAxios().get(url);
  return response;
};

export {
  getPartyService,
  postPartyService,
  putPartyService,
  postCountryStatesService,
  postStateCitiesService,
  getPartyByIdService,
};
