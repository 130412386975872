import moment from "moment";
import React from "react";
import { FOLIO_FORMAT, numberToWords } from "../../../utils/helper";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { CURRENCY_FORMAT } from "../../../utils/helper";
import { getCurrentCurrency } from "../../../utils/customCurrencyHelper";

const PaymentStructure = (props) => {
  const hotelDetails = Boolean(props?.hotelDetails) ? props?.hotelDetails : {};

  const isPrint = props?.print;
  const isDownloadPdf = Boolean(props?.download) ? props?.download : false;
  let targetRef = isPrint ? props?.val : props?.targetRef;

  const transactionNo = props?.transactionNo;
  const customerObject = props?.customerDetails;
  const paymentObject = props?.paymentDetails;
  const bookingObject = props?.bookingDetails;

  const amount = Boolean(paymentObject?.amount)
    ? Boolean(paymentObject?.amount < 0)
      ? -paymentObject?.amount
      : paymentObject?.amount
    : 0;

  const roomDetails = props?.roomDetails;

  let customerSection = [
    {
      title: "Name",
      value: Boolean(customerObject?.name) ? customerObject?.name : null,
    },
    {
      title: "Phone No",
      value: Boolean(customerObject?.phone) ? customerObject?.phone : null,
    },
    {
      title: "Email",
      value: Boolean(customerObject?.email) ? customerObject?.email : null,
    },
    {
      title: "Address ",
      value: Boolean(customerObject?.address) ? customerObject?.address : null,
    },
  ];

  customerSection = customerSection?.filter((Obj) => Boolean(Obj?.values));

  const hotelInformation = [
    {
      title: Boolean(hotelDetails && hotelDetails?.name)
        ? hotelDetails?.name
        : null,
      Logo: hotelDetails?.logo,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.address)
        ? hotelDetails?.address
        : null,
      Logo: IoLocationSharp,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.contactNumber)
        ? hotelDetails?.contactNumber
        : null,
      Logo: FaPhoneAlt,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.emailAddress)
        ? hotelDetails?.emailAddress
        : null,
      Logo: MdEmail,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.website)
        ? hotelDetails?.website
        : null,
      Logo: TbWorld,
    },
  ];

  const paymentTitleInformation = [
    {
      title: Boolean(paymentObject?.amount)
        ? Boolean(paymentObject?.amount < 0)
          ? "Refund Receipt"
          : "Payment Receipt"
        : "Payment Receipt",
    },
    {
      title: Boolean(paymentObject?.paymentId) ? paymentObject?.paymentId : "-",
    },
    {
      title: Boolean(paymentObject?.createdAt)
        ? moment(paymentObject?.createdAt)?.format("D MMM , YYYY")
        : "-",
    },
  ];

  const paymentSection = [
    {
      title: "Reference Number",
      value: Boolean(transactionNo) ? transactionNo : "-",
    },
    {
      title: "Payment Mode",
      value: Boolean(paymentObject?.method)
        ? `${paymentObject?.method?.toUpperCase()}  ${
            Boolean(paymentObject?.transactionNo)
              ? `(${paymentObject?.transactionNo})`
              : ""
          } `
        : "-",
    },
    {
      title: "Amount Recived in Words",
      value: Boolean(amount) ? numberToWords(Math.round(amount)) : "00",
    },
  ];

  let bookingSection = Boolean(props?.bookingDetails)
    ? [
        {
          title: "Room No / Plan",
          value: Boolean(roomDetails)
            ? `${roomDetails?.name} X ${roomDetails?.type} / ${bookingObject?.mealPlan}`
            : null,
        },
        {
          title: "Adult-Child",
          value: Boolean(bookingObject?.adults)
            ? `${bookingObject?.adults} - ${bookingObject?.children} `
            : null,
        },
        {
          title: "Check-in/Check-out",
          value: Boolean(bookingObject?.checkIn && bookingObject?.checkOut)
            ? `${moment(bookingObject?.checkIn)?.format(
                "D-MM-YYYY"
              )} | ${moment(bookingObject?.checkOut)?.format("D-MM-YYYY")}`
            : null,
        },
        {
          title: "Booking Source",
          value: Boolean(bookingObject?.source) ? bookingObject?.source : null,
        },
      ]
    : [];

  const paymentForHead = [
    { title: "INVOICE NO" },
    { title: "INVOICE DATE" },
    { title: "INVOICE AMOUNT" },
    { title: "PAYMENT AMOUNT" },
  ];

  const paymentForArray = Boolean(
    paymentObject && Array.isArray(paymentObject?.paymentPartRecordList)
  )
    ? paymentObject?.paymentPartRecordList
    : [];

  const { symbol, position } = getCurrentCurrency(hotelDetails?.currency);

  return (
    <div
      className={`p-7  flex flex-col gap-[2rem] ${
        isDownloadPdf
          ? "absolute left-[-5000px] right-0 top-[-200vh] w-[100%] "
          : ""
      }`}
      ref={targetRef}
    >
      <header className="flex justify-between">
        <div className="leftSection-hotelInformation flex flex-col gap-2 ">
          {hotelInformation?.map((Obj, index) => {
            return index === 0 ? (
              <div className=" flex gap-2 items-center">
                <div>
                  {Boolean(hotelDetails?.logo) ? (
                    <div className="w-[30px] h-[30px] rounded-[4px]">
                      <img
                        src={hotelDetails?.logo}
                        className="w-[100%] h-[100%] rounded-[4px]"
                        alt=""
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="font-outfit text-[#000] font-[600] text-[14px] leading-[21px]">
                  {hotelDetails?.name}
                </div>
              </div>
            ) : (
              Boolean(Obj?.title) && (
                <div className="flex gap-1 items-center" key={Obj.title}>
                  <div className="bg-[#EBEBEB] rounded-[100%] p-[2px]">
                    <Obj.Logo className="text-[#4D4D4D] text-[10px]" />
                  </div>
                  <div className="font-outfit text-[#1A1C21] font-[400] text-[10px] leading-[15px]">
                    {Obj?.title}
                  </div>
                </div>
              )
            );
          })}
        </div>

        <div className="rightSection-PaymentTitleInformation flex flex-col items-end">
          {paymentTitleInformation?.map((Obj, index) => {
            return (
              <div
                className={` ${
                  index === 0
                    ? " text-[#000] font-[600] text-[20px] leading-[30px]"
                    : " text-[#4D4D4D] font-[400] text-[10px] leading-[15px]"
                } font-outfit`}
              >
                {Obj?.title}
              </div>
            );
          })}
        </div>
      </header>

      <main className="flex w-[100%] gap-[2rem] flex-col justify-center">
        <div className="PymentSection flex items-center  border-l-[4px] bg-[#F1F7FF] border-[#007FFF] h-[90px] w-[100%] justify-between">
          <div className="leftSection flex flex-col gap-1 pl-2 w-[80%]">
            {Array.isArray(paymentSection) &&
              paymentSection?.map((Obj) => {
                return (
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 w-[100%]">
                      <div className="font-outfit font-[400] text-[10px] leading-[18px] w-[39%]">
                        {Obj?.title}
                      </div>
                      <div className="font-outfit font-[500] text-[10px] leading-[18px] w-[1%]">
                        :
                      </div>
                      <div className="font-outfit font-[500] text-[10px] leading-[18px] w-[60%] ">
                        {Obj?.value}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="rightSection border-[#007FFF] border-[1px] rounded-[8px] h-[90px] w-[135px] flex flex-col justify-center items-center px-2 flex-nowrap">
            <div className="text-[#4D4D4D] flex font-outfit font-[400] text-[10px] leading-[15px] whitespace-nowrap">
              {Boolean(paymentObject?.amount < 0)
                ? "Refund Amount"
                : "Amount Paid"}{" "}
              &nbsp;
              <span className="text-[#000] font-outfit font-[500] text-[10px] leading-[15px]">
                (
                {Boolean(paymentObject?.method)
                  ? paymentObject?.method?.toUpperCase()
                  : "-"}
                )
              </span>
            </div>
            <div className="text-[#007FFF] font-outfit font-[600] text-[20px] leading-[30px] ">
              {`${position === "before" ? symbol : ""} ${FOLIO_FORMAT(
                amount
              )} ${position === "after" ? ` ${symbol}` : ""}`}
            </div>
          </div>
        </div>

        {(customerSection?.length > 0 || bookingSection?.length > 0) && (
          <div className="paidBySection flex flex-col gap-2">
            <div className="font-outfit font-[500] text-[14px] leading-[17.64px] text-[#000]">
              Paid By
            </div>
            <div className="border-[#EBEBEB] border-[1px] w-[100%]"></div>

            <div className="flex w-[100%] ">
              <div className="w-[50%] ">
                {customerSection?.map((Obj, index) => {
                  return (
                    Boolean(Obj?.value) && (
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-2 w-[100%]">
                          <div className="font-outfit font-[400] text-[10px] leading-[18px] w-[39%] text-[#000]">
                            {Obj?.title}
                          </div>
                          <div className="font-outfit font-[500] text-[10px] leading-[18px] w-[1%] text-[#000]">
                            :
                          </div>
                          <div
                            className={` index === 0 ? "text-[#000]" : "text-[#4D4D4D]" } font-outfit font-[500] text-[10px] leading-[18px] w-[60%] `}
                          >
                            {Obj?.value}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>

              {bookingSection?.length > 0 && (
                <div className="w-[50%]  flex flex-col  ">
                  {bookingSection?.map((Obj, index) => {
                    return (
                      Boolean(Obj?.value) && (
                        <div className="flex flex-col gap-2  w-[80%]">
                          <div className="flex gap-2 w-[100%] justify-end self-end">
                            <div className="font-outfit font-[400] text-[10px] leading-[18px] w-[35%] whitespace-nowrap">
                              {Obj?.title}
                            </div>
                            <div className="font-outfit font-[500] text-[10px] leading-[18px] w-[1%]">
                              :
                            </div>
                            <div className="font-outfit font-[500] text-[10px] leading-[18px] w-[30%] whitespace-nowrap ">
                              {Obj?.value}
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </main>

      <footer className="w-[100%]">
        {Array.isArray(paymentForArray) && paymentForArray?.length > 0 && (
          <div className="paymentForSection flex flex-col gap-2 w-[100%]">
            <div className="font-outfit font-[500] text-[14px] leading-[17.4px] text-[#000]">
              Payment For{" "}
            </div>

            <div className="border-[1px] border-[#EBEBEB] w-[100%] h-[1px]"></div>

            <div className="head w-[100%] flex justify-between ">
              {paymentForHead?.map((Obj) => {
                return (
                  <div className="w-[20%] font-inter font-[600] text-[10px] leading-[12.1px] tracking-wide">
                    {Obj?.title}
                  </div>
                );
              })}
            </div>
            <div className="body w-[100%] flex flex-col gap-2">
              {paymentForArray?.map((Obj) => {
                return (
                  <div className="flex justify-between ">
                    <div className="w-[20%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                      {Obj?.invoiceNumber}
                    </div>
                    <div className="w-[20%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                      {Boolean(Obj?.invoiceBilledDate)
                        ? moment(Obj?.invoiceBilledDate)?.format("DD-MM-YYYY")
                        : "-"}
                    </div>
                    <div className="w-[20%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                      {CURRENCY_FORMAT(Obj?.invoiceTotal)}
                    </div>{" "}
                    <div className="w-[20%] font-outfit font-[500] text-[10px] leading-[15px] text-[#1A1C21]">
                      {CURRENCY_FORMAT(Obj?.paymentPartAmount)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </footer>
    </div>
  );
};

export default PaymentStructure;
