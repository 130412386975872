import React from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedItem } from "../../../../reducer/posReducer/restaurant";
import { GET_ITEM_PRICE } from "../../../../utils/posHelper";
import { RiTriangleFill } from "react-icons/ri";
import { FaCircle } from "react-icons/fa";

function ItemCard({ item, start, end, loading, error }) {
  const dispatch = useDispatch();

  const { selectedItem, tableOrder } = useSelector((store) => store.pos);

  const { activePosType } = useSelector((store) => store.posSetting);

  let price = GET_ITEM_PRICE(tableOrder.type, activePosType, item);

  const inc = (selectedItem) => {
    dispatch(
      updateSelectedItem({
        id: selectedItem.id,
        itemCountType: "increment",
      })
    );
  };

  const dnc = (selectedItem) => {
    dispatch(
      updateSelectedItem({
        id: selectedItem.id,
        itemCountType: "decrement",
      })
    );
  };

  return (
    <div
      className={`  ${start ? "rounded-t-[12px]" : ""} ${
        end ? "rounded-b-[12px]" : ""
      } w-[100%] bg-white py-2 px-3  items-center  
      flex justify-between`}
    >
      <div className=" flex flex-col gap-2 p-2  w-[70%]">
        <div className="flex items-center gap-1 ">
          <div
            className={` ${
              item?.foodChoice === "veg"
                ? "border-[#862D2E]"
                : "border-[#4AB200]"
            } flex justify-center items-center  border-[0.5px] rounded-[3px]
               bg-[#FFF] w-[12px] h-[12px]`}
          >
            {item.foodChoice === "veg" ? (
              <FaCircle className="text-[#4AB200] text-[5px] rounded-[50%]" />
            ) : (
              <RiTriangleFill className="text-[#862D2E] text-[6px]" />
            )}
          </div>

          <span className="text-[#222222] font-outfit font-[600] leading-[20.16px] text-[16px] break-words whitespace-normal">
            {item.name}
          </span>
        </div>

        <span className="text-[#4D4D4D]  pl-1 font-outfit font-[600] leading-[20.16px] text-[16px]">
          ₹ {price}
        </span>
      </div>

      <div className="h-full ">
        {Boolean(selectedItem[item.id] && selectedItem[item.id].count) ? (
          <div className="flex gap-4  items-center bg-[#007FFF] p-2  rounded-[8px] text-[#FFF]">
            <MinusOutlined onClick={() => dnc(item)} />

            <div>
              {selectedItem[item.id] !== undefined
                ? selectedItem[item.id].count
                : 0}
            </div>

            <PlusOutlined onClick={() => inc(item)} />
          </div>
        ) : (
          <div className="flex gap-4 bg-[#FFF]  items-center p-2  border-[1px] rounded-[8px] border-[#007FFF]">
            <MinusOutlined onClick={() => dnc(item)} />

            <div>
              {selectedItem[item.id] !== undefined
                ? selectedItem[item.id].count
                : 0}
            </div>

            <PlusOutlined onClick={() => inc(item)} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemCard;
