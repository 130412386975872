import React from "react";

const Index = () => {
  return (
    <div className="h-full flex justify-center mt-[25rem]">
      <div className="text-center">
        <h1 className="text-3xl font-bold">Coming soon</h1>
      </div>
    </div>
  );
};

export default Index;
