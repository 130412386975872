import React from "react";
import Heading from "./Heading";
import RunningOrder from "./RunningOrder";
import CustomerTableDetails from "./CustomerTableDetails";
import Footer from "./Footer";

const OrderDetails = ({
  setOrderDetailScreenVisible,
  setMultipleKOTScreenVsisible,
}) => {
  return (
    <div className="w-[100%] flex flex-col gap-2 h-[100%] overflow-y-auto  pb-[5rem]  ">
      <Heading setOrderDetailScreenVisible={setOrderDetailScreenVisible} />

      <RunningOrder />
      <CustomerTableDetails />

      <Footer
        setMultipleKOTScreenVsisible={setMultipleKOTScreenVsisible}
        setOrderDetailScreenVisible={setOrderDetailScreenVisible}
      />
    </div>
  );
};

export default OrderDetails;
