import * as React from "react";
import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";

import { TextField, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { updateDisplayDiscountDrawer } from "../../../../reducer/posReducer/restaurant";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { message } from "antd";
import DiscountButton from "./DiscountButton";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";
import { TranslateText } from "../../../../utils/translation";

export default function ApplyDiscountDrawer({
  discountInfo,
  itemPrice,
}) {
  const [loading, setLoading] = useState(false);
  const [discountAmount, setDiscountAmount] = useState();
  const [discountType, setDiscountType] = useState("percentage");

  const { displayDiscountDrawer } = useSelector((state) => state.pos);
  const { menuDrawer } = useSelector((store) => store.app);

  const dispatch = useDispatch();
  const handleDiscountTypeChange = (e) => setDiscountType(e);

  const {handleDiscount} = useAllPosFunctions();

  const handleDiscountValue = (value) => {
    // Parse the value to ensure it's a number
    let numericValue = parseFloat(value);
    numericValue = Boolean(numericValue) ? numericValue : 0;

    if (discountType === "percentage") {
      if (numericValue < 0) setDiscountAmount(0);
      else if (numericValue > 100) setDiscountAmount(100);
      else setDiscountAmount(numericValue);
    } else setDiscountAmount(numericValue);
  };
  
  const handleDiscountButton = () => {
    if (discountType !== "flat" && discountType !== "percentage") {
      message.info("Please Select Discount type");
      return;
    }

    handleDiscount(discountType,discountAmount,setLoading)
  };

  useEffect(() => {
    if (Boolean(discountInfo)) {
      setDiscountType(discountInfo?.type);
      setDiscountAmount(discountInfo?.value);
    }
  }, [displayDiscountDrawer]);

  useEffect(() => {
    if (Boolean(itemPrice && discountAmount)) {
      if (discountType === "flat") {
        let value = (itemPrice * discountAmount) / 100;
        setDiscountAmount(value);
      } else if (discountType === "percentage") {
        let discountPercent = (discountAmount * 100) / itemPrice;
        setDiscountAmount(discountPercent);
      }
    }
  }, [discountType]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: menuDrawer ? "33%" : "30%",
          height: "30%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={displayDiscountDrawer}
      onClose={() => dispatch(updateDisplayDiscountDrawer(false))}
    >
      <div className="flex flex-col mx-3 gap-5 items-start my-3 ">
        <div className="flex w-[100%] justify-between items-center ">
          <h2 className="font-[500] text-[1rem] text-[#4D4D4D] font-nunito">
            
            <TranslateText textKey={"select_discount"} namespace="pos" />

          </h2>

          <div className="flex  gap-5 items-center">
            <DiscountButton
              discountType={discountType}
              handleDiscountTypeChange={handleDiscountTypeChange}
            ></DiscountButton>

            <IconButton
              onClick={() => dispatch(updateDisplayDiscountDrawer(false))}
              sx={{ borderRadius: "5px", border: "1px solid #A7A7A7" }}
            >
              <CloseIcon sx={{ fontSize: "medium", color: "#A7A7A7" }} />
            </IconButton>
          </div>
        </div>

        <div className="w-[100%]  mt-2 flex items-center justify-center">
          <TextField
            label={
              discountType === "flat"
                ? "Enter Amount"
                : "Enter Discount Percentage"
            }
            id="discountAmount"
            size="small"
            type="Number"
            onChange={(e) => handleDiscountValue(e.target.value)}
            value={
              Boolean(discountAmount) ? Number(discountAmount?.toFixed(2)) : 0
            }
            sx={{
              width: "90%",
              marginX: "auto",

              "& label": {
                color: "#0000004D",
                fontFamily: "Poppins",
                fontSize: "15px",
              },
              "& input": {
                fontFamily: "Poppins",
                fontSize: "15px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
          />
        </div>
      </div>

      {loading ? (
        <button
          className="bg-[#007FFF] text-[#FFF] w-full h-[3rem] uppercase absolute bottom-0"
          disabled={true}
        >
          <CircularProgress size={16} color="inherit" />
        </button>
      ) : (
        <button
          className="bg-[#007FFF] text-[#FFF] w-full h-[3rem] uppercase absolute bottom-0"
          onClick={() => handleDiscountButton()}
        >
         
          <TranslateText textKey={"apply_discount"} namespace="pos" />

        </button>
      )}
    </Drawer>
  );
}
