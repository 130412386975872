import React from "react";
import moment from "moment";

import { FaPhoneAlt } from "react-icons/fa";
import { BiSolidMessageAltMinus } from "react-icons/bi";
import { useSelector } from "react-redux";
import {
  roomCountArrayObject,
  totalRoomsArray,
} from "../../../utils/bookingDetailsHelper";

import { CALCULATE_NIGHTS_COUNT } from "../../../utils/helper";

const GrcDrawerForPrint = ({
  bookingDetails,
  customerDetails,
  hotelDetails,
  paymentDetails,
  val,
}) => {
  const { response } = useSelector((store) => store.rooms); // get room list

  const roomCountArray = roomCountArrayObject(bookingDetails, response);
  const totalRooms = totalRoomsArray(roomCountArray);

  return (
    <div className=" absolute left-0 right-0 my-3 mx-8" ref={val}>
      <div className="relative h-screen">
        <div className=" mb-1 text-center text-lg text-[#000000] font-semibold	  font-nunito">
          GUEST REGISTRATION CARD
        </div>
        <div className=" font-nunito flex justify-between gap-9  text-sm">
          <div className="">
            <div className=" ">
              <span className="text-[#808080]  font-medium">Booking Id:</span>{" "}
              <span className="text-[#000000]  text-sm  font-semibold	 ">
                {customerDetails && customerDetails[0]?.id}
              </span>
            </div>
            <div className="font-medium">
              <span className="text-[#808080] ">Source:</span>{" "}
              <span className="text-[#000000] text-sm text-semibold capitalize">
                {bookingDetails && bookingDetails?.source}
                <span className="italic">
                  (Ref: {bookingDetails && bookingDetails?.referenceNo})
                </span>
              </span>
            </div>
          </div>

          <div className="text-[12px] font-thin text-[#4D4D4D] text-right">
            <div className="text-4xl font-semibold text-[#2F80ED] uppercase">
              {hotelDetails && hotelDetails?.name}
            </div>
            <p className="">{hotelDetails && hotelDetails?.city},</p>
            <p className="">{hotelDetails && hotelDetails?.address}</p>
            <p className="flex justify-end mt-1">
              <span className="mr-[2px] mt-[3px] text-sm text-[#4D4D4D]">
                <FaPhoneAlt />
              </span>
              <span className="mr-1 text-[#4D4D4D]">
                {hotelDetails?.contactNumber}
              </span>
              <span className="ml-1 mt-[4px] text-sm text-[#4D4D4D]">
                <BiSolidMessageAltMinus />
              </span>
              <span className="text-[#4D4D4D] ml-[2px]">
                {hotelDetails?.emailAddress}
              </span>
            </p>
          </div>
        </div>

        <div className="mt-2">
          <div className=" relative p-4 border rounded-xl   border-gray-300">
            <div
              className="absolute top-[-10px] bg-[#FFF] px-2 font-[600] text-sm left-[2rem] text-[#000000]  
       font-nunito uppercase"
            >
              {customerDetails && customerDetails[0]?.name}
            </div>

            <div className="w-full flex justify-between mt-1 mb-[2px]">
              <div className="w-[50%]">
                <div className="mx-4">
                  <div className=" my-1">
                    <span className="text-[#808080]  text-xs font-thin">
                      Ph no:
                    </span>
                    <span className="text-[#000000]  text-xs font-semibold	mx-1">
                      {customerDetails && customerDetails[0]?.phone}
                    </span>
                  </div>
                  <div className=" my-1">
                    <span className="text-[#808080]  text-xs font-thin">
                      Email:
                    </span>
                    <span className="text-[#000000]  text-xs font-semibold	mx-1">
                      {customerDetails && customerDetails[0]?.email}
                    </span>
                  </div>
                  <div className=" my-1">
                    <span className="text-[#808080]  text-xs font-thin">
                      Address:
                    </span>
                    <span className="text-[#000000]  text-xs font-semibold	mx-1">
                      {customerDetails && customerDetails[0]?.address}
                    </span>
                  </div>
                  <div className="my-1">
                    <span className="text-[#808080]  text-xs font-thin">
                      Gender:
                    </span>
                    <span className="text-[#000000]  text-xs font-semibold	mx-1">
                      {customerDetails && customerDetails[0]?.gender}
                    </span>
                  </div>
                  <div className="my-1 flex">
                    <span className="text-[#808080]  text-xs font-thin">
                      DOB:
                    </span>
                    <span className="text-[#000000]  text-xs font-semibold	mx-1">
                      {customerDetails && customerDetails[0]?.dob
                        ? moment(customerDetails?.dob).format("D MMM YYYY")
                        : ""}
                    </span>
                    <span className="text-[#000000]  text-xs font-semibold  mr-1">
                      -
                    </span>
                    <span className="text-[#808080]  text-xs font-thin ">
                      Anniversary:
                    </span>
                    <span className="text-[#000000]  text-xs font-semibold	mx-1">
                      {customerDetails && customerDetails[0]?.doanniversary
                        ? moment(customerDetails[0]?.doanniversary).format(
                            "D MMM YYYY"
                          )
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-[1px] h-[120px]  bg-gray-300 "></div>

              <div className="w-[50%]">
                <div className="mx-[2rem]">
                  <div className=" my-1">
                    <span className="text-[#808080] text-xs font-thin">
                      Company Name:
                    </span>
                    <span className="text-[#000000]  text-xs  font-semibold mx-1	 ">
                      {customerDetails && customerDetails[0]?.company}
                    </span>
                  </div>
                  <div className=" my-1">
                    <span className="text-[#808080] text-xs font-thin">
                      Designation:
                    </span>
                    <span className="text-[#000000]  text-xs  font-semibold mx-1	 ">
                      {customerDetails && customerDetails[0]?.designation}
                    </span>
                  </div>
                  <div className=" my-1">
                    <span className="text-[#808080] text-xs font-thin">
                      Company GSTIN:
                    </span>
                    <span className="text-[#000000]  text-xs  font-semibold mx-1	 ">
                      {customerDetails && customerDetails[0]?.gstIN}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" relative p-4 border rounded-xl  my-6  border-gray-300">
            <div
              className="absolute top-[-10px] bg-[#FFF] px-2  text-[#808080]  text-[0.65rem] font-thin left-[2rem] 
       font-nunito"
            >
              Foreign Nationals
            </div>

            <div className="w-full flex  my-[0.17rem] mx-1">
              <div className="w-[33%]">
                <div className=" my-1">
                  <span className="text-[#808080]  text-xs font-thin">
                    Nationality:
                  </span>
                  <span className="text-[#000000]  text-xs font-semibold	mx-1">
                    {customerDetails && customerDetails[0]?.nationality}
                  </span>
                </div>
                <div className=" my-1">
                  <span className="text-[#808080]  text-xs font-thin">
                    Passport No:
                  </span>
                  <span className="text-[#000000]  text-xs font-semibold	mx-1">
                    {customerDetails && customerDetails[0]?.passportNumber}
                  </span>
                </div>
                <div className=" my-1">
                  <span className="text-[#808080]  text-xs font-thin">
                    Address:
                  </span>
                  <span className="text-[#000000]  text-xs font-semibold	mx-1">
                    {customerDetails && customerDetails[0]?.address}
                  </span>
                </div>
                <div className="my-1">
                  <span className="text-[#808080]  text-xs font-thin">
                    Date of Issue:
                  </span>
                  <span className="text-[#000000]  text-xs font-semibold	mx-1">
                    {customerDetails &&
                    customerDetails[0]?.grcDetails?.dateOfIssue
                      ? moment(
                          customerDetails[0]?.grcDetails?.dateOfIssue
                        ).format("D MMM YYYY")
                      : ""}
                  </span>
                </div>
                <div className="my-1 flex">
                  <span className="text-[#808080]  text-xs font-thin">
                    Date of Expire:
                  </span>
                  <span className="text-[#000000]  text-xs font-semibold	mx-1">
                    {customerDetails &&
                    customerDetails[0]?.grcDetails?.dateOfExpire
                      ? moment(
                          customerDetails[0]?.grcDetails?.dateOfExpire
                        ).format("D MMM YYYY")
                      : ""}
                  </span>
                </div>
              </div>
              <div className="w-[1px]  h-[120px] bg-gray-300 "></div>

              <div className="w-[33%]">
                <div className="ml-[1rem]">
                  <div className=" my-1">
                    <span className="text-[#808080] text-xs font-thin">
                      Visa No:
                    </span>
                    <span className="text-[#000000]  text-xs  font-semibold mx-1	 ">
                      {customerDetails &&
                        customerDetails[0]?.grcDetails?.visaNumber}
                    </span>
                  </div>
                  <div className=" my-1">
                    <span className="text-[#808080] text-xs font-thin">
                      Visa Type:
                    </span>
                    <span className="text-[#000000]  text-xs  font-semibold mx-1	 ">
                      {customerDetails &&
                        customerDetails[0]?.grcDetails?.visaType}
                    </span>
                  </div>
                  <div className=" my-1">
                    <span className="text-[#808080] text-xs font-thin">
                      Date of Arrival:
                    </span>
                    <span className="text-[#000000]  text-xs  font-semibold mx-1	 ">
                      {customerDetails &&
                      customerDetails[0]?.grcDetails?.dateOfArrival
                        ? moment(
                            customerDetails[0]?.grcDetails?.dateOfArrival
                          ).format("D MMM YYYY")
                        : ""}
                    </span>
                  </div>
                </div>
              </div>

              <div className="w-[1px] h-[120px] bg-gray-300 "></div>

              <div className="w-[33%]">
                <div className="ml-[1rem]">
                  <div className=" my-1">
                    <span className="text-[#808080] text-xs font-thin">
                      Contact No in India:
                    </span>
                    <span className="text-[#000000]  text-xs  font-semibold mx-1	 ">
                      {customerDetails &&
                        customerDetails[0]?.grcDetails?.localContactNumber}
                    </span>
                  </div>
                  <div className=" my-1">
                    <span className="text-[#808080] text-xs font-thin">
                      If Yes, Company Name:
                    </span>
                    <span className="text-[#000000]  text-xs  font-semibold mx-1	 ">
                      {customerDetails &&
                        customerDetails[0]?.grcDetails?.companyName}
                    </span>
                  </div>
                  <div className=" my-1">
                    <span className="text-[#808080] text-xs font-thin">
                      Purpose Duration of Stay in India:
                    </span>
                    <span className="text-[#000000]  text-xs  font-semibold mx-1	 ">
                      {customerDetails &&
                        customerDetails[0]?.grcDetails?.purpose}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" relative p-4  border rounded-xl  my-6  border-gray-300">
            <div
              className="absolute top-[-10px] bg-[#FFF] px-2   text-[#808080]  text-[0.65rem] font-thin left-[2rem]  
       font-nunito"
            >
              Checkin Details
            </div>

            <div className="w-full mt-1 mb-0 mx-1 font-nunito">
              <div className="flex justify-between">
                <div>
                  <div className="text-xs font-medium  text-[#4D4D4D]">
                    Check in
                  </div>
                  <div className="text-base text-[#000000] my-1 font-semibold	 font-nunito">
                    {bookingDetails &&
                      moment(bookingDetails?.checkIn).format("ddd, D MMM YYYY")}
                  </div>
                  <div className="font-semibold text-[0.80rem]">
                    {bookingDetails &&
                      moment(bookingDetails?.checkIn).format("LT")}
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <div className="bg-gray-200 py-[0.20rem] px-3 rounded mx-2  text-[#000000] font-semibold	text-xs font-nunito">
                    {Boolean(
                      CALCULATE_NIGHTS_COUNT(
                        bookingDetails?.checkIn,
                        bookingDetails?.checkOut,
                        hotelDetails
                      )
                    )
                      ? CALCULATE_NIGHTS_COUNT(
                          bookingDetails?.checkIn,
                          bookingDetails?.checkOut,
                          hotelDetails
                        )
                      : "0 "}{" "}
                    Night
                  </div>
                </div>
                <div>
                  <div className="text-xs font-medium  text-[#4D4D4D]">
                    Check out
                  </div>
                  <div className="text-base text-[#000000] font-semibold my-1 font-nunito">
                    {bookingDetails &&
                      moment(bookingDetails?.checkOut).format(
                        "ddd, D MMM YYYY"
                      )}
                  </div>
                  <div className=" font-semibold text-[0.80rem]">
                    {bookingDetails &&
                      moment(bookingDetails?.checkOut).format("LT")}
                  </div>
                </div>
              </div>

              <hr className="mt-5"></hr>
              <div className="flex my-2 mx-[0.10rem]">
                <div className="w-[35%] ">
                  <div className="text-[#000000]  text-sm  font-semibold  mt-[5px]">
                    {roomCountArray.map((room, index) => {
                      return (
                        <div key={`${room.type}/${index}/${room.roomNum}`}>
                          {room.type} &nbsp; X {room.count}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-[60%] flex flex-wrap ">
                  {totalRooms?.map((roomNum) => (
                    <button className="bg-gray-200 py-[0.20rem] px-3 rounded mx-2  text-[#000000] font-semibold	 font-nunito">
                      {roomNum}
                    </button>
                  ))}
                </div>
              </div>

              <hr></hr>
              <div className="flex flex-wrap mt-3  mx-[0.10rem] mb-1">
                <div className="bg-gray-200 py-1 px-3 rounded ml-0 mr-2  my-[0.20rem] text-[#000000] font-semibold	 font-nunito ">
                  <span className="text-[#808080] text-xs font-extralight">
                    Food Choice:
                  </span>{" "}
                  <span className="text-[#000000]  text-xs font-semibold mx-1">
                    {bookingDetails && bookingDetails?.foodChoice}
                  </span>
                </div>

                <div className="bg-gray-200 py-1 px-3 rounded mx-2 my-[0.20rem] text-[#000000] font-semibold	 font-nunito ">
                  <span className="text-[#808080] text-xs font-extralight">
                    Mode of Travel:
                  </span>{" "}
                  <span className="text-[#000000]  text-xs  font-semibold mx-1">
                    {bookingDetails && bookingDetails?.modeOfTravel}
                  </span>
                </div>

                <div className="bg-gray-200 py-1 px-3 rounded mx-2 my-[0.20rem] text-[#000000] font-semibold	 font-nunito ">
                  <span className="text-[#808080] text-xs font-extralight">
                    Remarks:
                  </span>
                  <span className="text-[#000000]  text-xs  font-semibold mx-1">
                    {bookingDetails && bookingDetails?.remarks}
                  </span>
                </div>
              </div>
              <div className="flex flex-wrap mt-1 mx-[0.10rem] mb-0">
                <div className="bg-gray-200 py-1 px-3 rounded ml-0 mr-2 my-[0.20rem] text-[#000000] font-semibold	 font-nunito ">
                  <span className="text-[#808080] text-xs font-extralight">
                    Arrival:
                  </span>{" "}
                  <span className="text-[#000000]  text-xs  font-semibold mx-1">
                    {bookingDetails && bookingDetails?.arrival}
                  </span>
                </div>

                <div className="bg-gray-200 py-1 px-3 rounded mx-2 my-[0.20rem] text-[#000000] font-semibold	 font-nunito ">
                  <span className="text-[#808080] text-xs font-extralight">
                    Destination:
                  </span>{" "}
                  <span className="text-[#000000]  text-xs  font-semibold my-[0.20rem]">
                    {bookingDetails && bookingDetails?.destination}
                  </span>
                </div>

                <div className="bg-gray-200 py-1 px-3 rounded mx-2 my-[0.20rem] text-[#000000] font-semibold	 font-nunito ">
                  <span className="text-[#808080] text-xs font-extralight">
                    Purpose of Visit:
                  </span>{" "}
                  <span className="text-[#000000]  text-xs  font-semibold mx-1">
                    {bookingDetails && bookingDetails?.purposeOfVisit}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className=" relative p-4 border rounded-xl border-gray-300">
            <div
              className="absolute top-[-10px] bg-[#FFF] px-2   text-[#808080]  text-[0.65rem] font-thin  left-[2rem]  
       font-nunito"
            >
              Payment Details
            </div>
            <div className="flex flex-wrap mx-1 my-[0.10rem]">
              <div className="bg-gray-200 py-1 px-3 rounded mr-2 ml-0 text-[#000000] font-semibold	 font-nunito ">
                <span className="text-[#808080] text-xs font-extralight">
                  Amount:
                </span>{" "}
                <span className="text-[#000000]  text-xs  font-semibold mx-1">
                  {paymentDetails && (paymentDetails[0]?.amount ?? 0)}
                </span>
              </div>

              <div className="bg-gray-200 py-1 px-3 rounded mx-2  text-[#000000] font-semibold	 font-nunito ">
                <span className="text-[#808080] text-xs font-extralight">
                  Payment Mode:
                </span>{" "}
                <span className="text-[#000000]  text-xs  font-semibold mx-1">
                  {paymentDetails && paymentDetails[0]?.method}
                </span>
              </div>

              <div className="bg-gray-200 py-1 px-3 rounded mx-2  text-[#000000] font-semibold	 font-nunito ">
                <span className="text-[#808080] text-xs font-extralight">
                  Transaction No:
                </span>{" "}
                <span className="text-[#000000]  text-xs  font-semibold mx-1">
                  {paymentDetails && paymentDetails[0]?.transactionNo}
                </span>
              </div>
            </div>
          </div>

          <div className="w-full mx-1 my-5">
            <div className=" flex justify-center flex-wrap ">
              {customerDetails && customerDetails[0]?.idImages ? (
                customerDetails[0].idImages.map((image, index) => (
                  <>
                    <div className="w-[30%] h-auto mx-[1rem]" key={index}>
                      <img src={image} alt={`ID Image ${index + 1}`} />
                    </div>
                  </>
                ))
              ) : (
                <p className="font-semibold">No Id Images available</p>
              )}
            </div>
          </div>

          <div className="w-full my-8">
            <div className="flex justify-between text-lg  text-[#000000] font-normal my-2 font-nunito mb-3">
              <div className="flex font-nunito  mx-2">
                <div className="  font-[600] text-base">STAFF SIGNATURE:</div>
                <hr className="w-[10rem]  lg:w-[14rem] h-[1px] mt-5 ml-1 bg-gray-300 border-0 rounded" />
              </div>
              <div className=" flex font-nunito mx-2">
                <div className="font-[600] text-base">GUEST SIGNATURE:</div>
                <hr className="w-[10rem] lg:w-[14rem] h-[1px] ml-1 mt-5   bg-gray-300 border-0 rounded" />
              </div>
            </div>

            <p className="text-xs font-thin">
              Disclaimer : Checkin & Checkout time 12 Noon
            </p>
            <p className="text-xs font-thin  ">
              I hereby acknowledge that i am responsible for the full payment of
              the bills in the events, If it is not paid by Company,
              Organisation Or Person Indicated T&C Applied
            </p>
            <p className="text-xs font-thin text-center mt-2">
              Powered By <span className="font-bold">REVIVO HOTELX</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrcDrawerForPrint;
