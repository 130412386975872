import React, { useEffect } from "react";
import NewOrderRevamped from "../../components/pos/components/newOrderRevamped/Index";
import NewOrderRevampedMobileScreen from "../../mobileComponents/mobileScreenPos/components/NewOrder/Index";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateActiveMenu } from "../../reducer/appReducer";

function NewOrder() {
  const { tableOrder } = useSelector((store) => store.pos);
  const nagivate = useNavigate();
  const dispatch = useDispatch();

  const { isMobileScreen } = useSelector((store) => store.appHeader);

  useEffect(() => {
    // if no table order created , redirect to table view
    if (Object?.keys(tableOrder)?.length === 0) {
      nagivate(`/dashboard/`);
      dispatch(
        updateActiveMenu({
          activeMenu: "home",
          pos: false,
        })
      );
    }
  }, []);

  return (
    <div>
      {isMobileScreen ? <NewOrderRevampedMobileScreen /> : <NewOrderRevamped />}
    </div>
  );
}

export default NewOrder;
