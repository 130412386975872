import { authedAxios } from "../../utils/customHttpHeader";

export const getAllRecountItemService = async () => {
  const response = await authedAxios().get("recount");
  return response.data;
};

export const createRecountService = async (payload) => {
  const response = await authedAxios().post("recount", payload);
  return response.data;
};
