import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getNightAuditDate, setNightAudit } from "../services/hotel";

const initialState = {
  nightAuditDateLoading: false,
  nightAuditModalDisplay: false,
  organisationDropDownDisplay: false,
  quickActionDropDownDisplay: false,

  displayOverlayingHamburgerMenu: false,
  isMobileAndTabletScreen: false,
  isMobileScreen: false,

  activeFooter: "home",
  expandedHotelDetailsScreen: false,

  globalsearchText: "",

  currentNightAuditDate: "",
  currentNightAuditDateLoading: false,
  currentNightAuditDateError: false,
};

export const completeNightAuditDate = createAsyncThunk(
  "login/completeNightAuditDate",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await setNightAudit(payload);
      return fulfillWithValue(response?.data?.message);
    } catch (error) {
      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const getCurrentNightAuditDate = createAsyncThunk(
  "login/getCurrentNightAuditDate",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await getNightAuditDate(payload);
      return fulfillWithValue(response?.data);
    } catch (error) {
      throw rejectWithValue(error?.response);
    }
  }
);

export const appHeaderSlice = createSlice({
  name: "appHeader",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_9: () => initialState,

    updateNightAuditModalDisplay: (state, action) => {
      state.nightAuditModalDisplay = action.payload;
    },
    updateOrganisationDropDownDisplay: (state, action) => {
      state.organisationDropDownDisplay = action.payload;
    },
    updateQuickActionDropDownDisplay: (state, action) => {
      state.quickActionDropDownDisplay = action.payload;
    },
    updateDisplayOverLayingHamburgerMenu: (state, action) => {
      state.displayOverlayingHamburgerMenu = action.payload;
    },
    updateIsMobileAndTabletScreen: (state, action) => {
      state.isMobileAndTabletScreen = action.payload;
    },
    updateIsMobileScreen: (state, action) => {
      state.isMobileScreen = action.payload;
    },
    updateActiveFooter: (state, action) => {
      state.activeFooter = action.payload;
    },
    updateExpandedHotelDetailScreen: (state, action) => {
      state.expandedHotelDetailsScreen = action.payload;
    },
    updateGloabalSearchText: (state, action) => {
      state.globalsearchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(completeNightAuditDate.pending, (state, action) => {
      state.nightAuditDateLoading = true;
      message.info("Night Audit is in Progress");
      // state.error = null;
    });
    builder.addCase(completeNightAuditDate.fulfilled, (state, action) => {
      state.nightAuditDateLoading = false;
      message.success(action?.payload);
      let hotelId = action?.meta?.arg?.hotelId;
      getCurrentNightAuditDate({ hotelId: hotelId });
    });
    builder.addCase(completeNightAuditDate.rejected, (state, action) => {
      state.nightAuditDateLoading = false;
      message.error(action?.payload);
    });

    builder.addCase(getCurrentNightAuditDate.pending, (state, action) => {
      state.currentNightAuditDateLoading = true;
      state.currentNightAuditDateError = false;
    });
    builder.addCase(getCurrentNightAuditDate.fulfilled, (state, action) => {
      state.currentNightAuditDateLoading = false;
      state.currentNightAuditDateError = false;
      state.currentNightAuditDate = action.payload;
    });
    builder.addCase(getCurrentNightAuditDate.rejected, (state, action) => {
      state.currentNightAuditDateLoading = false;
      state.currentNightAuditDateError = true;
      state.currentNightAuditDate = "";
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_9,
  updateNightAuditModalDisplay,
  updateOrganisationDropDownDisplay,
  updateQuickActionDropDownDisplay,
  updateDisplayOverLayingHamburgerMenu,
  updateIsMobileAndTabletScreen,
  updateIsMobileScreen,

  updateActiveFooter,
  updateExpandedHotelDetailScreen,

  updateGloabalSearchText,
} = appHeaderSlice.actions;

export default appHeaderSlice.reducer;
