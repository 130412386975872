// import axios from "axios";
import { authedAxios } from "../utils/customHttpHeader";

export const sendUploadReq = async (endpoint, payload) => {
  const response = await authedAxios().post(endpoint, payload);
  return response.data;
};

const uploadFiles = async (payload) => {
  try {
    const response = await authedAxios().post("/file/upload/multi", payload, {
      onUploadProgress: (event) => {},
    });
    return response.data?.urls?.[0];
  } catch (error) {
    return null;
  }
};

export { uploadFiles };
