import { authedAxios } from "../utils/customHttpHeader";

export const placeOrParkOrder = async (payload) => {
  const response = await authedAxios().post("orders", payload);
  return response;
};

export const generateInvoice = async (payload) => {
  const response = await authedAxios().post("invoices", payload);
  return response;
};

export const updateOrderInvoice = async (payload) => {
  const response = await authedAxios().put(
    `invoices/${payload.id}?hotelId=${payload.hotelId}`,
    payload
  );
  return response;
};

export const getInvoiceByID = async (payload) => {
  const response = await authedAxios().get(
    `invoices/${payload.id}?hotelId=${payload.hotelId}`,
    payload
  );
  return response;
};

export const getInvoice = async (payload) => {
  let url;

  if (payload.hotelIds) {
    url = `invoices?hotelIds=${payload.hotelIds}`;
  } else {
    url = `invoices?hotelId=${payload.hotelId}`;
  }

  if (payload.posName) {
    url += `&posName=${payload.posName}`;
  }

  if (payload.posId) {
    url += `&posId=${payload.posId}`;
  }

  if (payload.statuses) {
    url += `&statuses=${payload.statuses}`;
  }

  if (payload.roomId) {
    url += `&roomId=${payload.roomId}`;
  }

  if (payload.tableId) {
    url += `&tableId=${payload.tableId}`;
  }

  if (payload.pageNumber || payload.pageNumber == 0) {
    url += `&pageNumber=${payload.pageNumber}`;
  }

  if (payload.pageSize) {
    url += `&pageSize=${payload.pageSize}`;
  }
  if (payload.searchText) {
    url += `&searchText=${payload.searchText}`;
  }

  const response = await authedAxios().get(url);
  return response;
};

export const updateOrderService = async (payload) => {
  const response = await authedAxios().patch(`orders/${payload.id}`, payload);
  return response.data;
};

export const getOrderService = async (payload) => {
  const response = await authedAxios().get(
    `orders?hotelId=${payload.hotelId}&posName=${payload.posName}`
  );
  return response;
};
