import Carousel from "nuka-carousel";

import slide1Img from "../../assets/images/svgs/slide1.svg";
import slide2Img from "../../assets/images/svgs/slide2.svg";
import slide3Img from "../../assets/images/svgs/slide3.svg";
import { TranslateText } from "../../utils/translation.js";
const CarouselSingle = () => {
  const sliderData = [
    {
      id: 1,
      img: slide1Img,
      title: (
        <TranslateText textKey={"automate_your_hotel"} namespace="login" />
      ),

      desc: (
        <TranslateText
          textKey={
            "control_and_maximise_your_revenues_and_bookings_like_never_before"
          }
          namespace="login"
        />
      ),
    },
    {
      id: 2,
      img: slide2Img,
      title: (
        <TranslateText
          textKey={"all_bookings_at_fingertip"}
          namespace="login"
        />
      ),
      desc: (
        <TranslateText
          textKey={"get_all_your_walk_in_and_online_bookings_at_one_place"}
          namespace="login"
        />
      ),
    },
    {
      id: 3,
      img: slide3Img,
      title: (
        <TranslateText
          textKey={"AUTO_OPTIMIZE_ROOM_STATUS"}
          namespace="login"
        />
      ),
      desc: (
        <TranslateText
          textKey={
            "Once_your_rooms_get_booked_any_where_or_checked_out_it_optimizes_every_where"
          }
          namespace="login"
        />
      ),
    },
  ];
  return (
    <Carousel
      autoplay={true}
      autoplayInterval={3000}
      wrapAround={true}
      renderCenterLeftControls={({ previousSlide }) => null}
      renderCenterRightControls={({ nextSlide }) => null}
    >
      {sliderData.map((item, index) => (
        <div key={index} className="carousel-single text-center">
          <img src={item.img} className="block mx-auto" alt={item.title} />
          <h2 className="text-4xl font-semibold uppercase">{item.title}</h2>
          <p className="text-2xl mt-[40px]">{item.desc}</p>
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselSingle;
