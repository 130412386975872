import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getAllPosSetting,
  createPosSetting,
  updatePosSetting,
  deletePos,
} from "../../services/posSetting";
import {  deleteTables } from "../../services/table";
import { message } from "antd";
import { resetItem } from "../getItemReducer";

// POst Api

export const createPosSettingForm = createAsyncThunk(
  "createPosSettingForm",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await createPosSetting(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

//Get All Api

export const getAllPosSettingForm = createAsyncThunk(
  "getAllPosSettingForm",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getAllPosSetting(payload);
      // const activeUSerPosId = getState()?.usersRestriction?.currentUserPosIds;
      // const updatedData = response?.data?.filter((Obj) =>
      //   activeUSerPosId?.includes(Obj?.id)
      // );
      return fulfillWithValue(response?.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// Put Api

export const UpdatePosSettingForm = createAsyncThunk(
  "updatePosSettingForm",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await updatePosSetting(payload);
      // if create create or unit from add item screen
      dispatch(resetItem());
     
      return fulfillWithValue({
        data: response.data,
        message: Boolean(payload?.message) ? payload?.message : null,
      });
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const DeletePos = createAsyncThunk(
  "deletePos",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await deletePos(payload);
      return fulfillWithValue({ ...response, ...payload });
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// get Api

export const deleteTable = createAsyncThunk(
  "deleteTable",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await deleteTables(payload);
      return fulfillWithValue(payload.id);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const createNewPos = createAsyncThunk(
  "createNewPos",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await createPosSetting(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: true, // before api call
  response: [],
  apiError: false,

  updateLoading: false,
  updateApiError: false,

  visibleAddTableModal: false,
  visibleTableModalForEdit: false,
  visibleAddStewardModal: false,
  singleTableData: null,
  activePosId: "",
  isPosDrawerActive: false,
  activePosType: "",
  activePosName: "",
  activePosSlug: "",
  activePosGST: 5,
  isPosExist: true,

  // new pos

  posApiLoading: false,
  posData: {},
  posApiError: false,
};

const posSettingSlice = createSlice({
  name: "posSetting",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_6: () => initialState,

    updatesShowingAddTableModel: (state, action) => {
      state.visibleAddTableModal = action.payload.modal;
    },

    updatesShowingTableModelForEdit: (state, action) => {
      state.visibleTableModalForEdit = action.payload.modal;
    },
    updatesShowingAddStewardModel: (state, action) => {
      state.visibleAddStewardModal = action.payload.modal;
    },

    updateSingleTableData: (state, action) => {
      state.singleTableData = action.payload;
    },

    updateActivePosId: (state, action) => {
      state.activePosId = action.payload.activePosId;
      state.activePosType = action.payload.activePosType;
      state.activePosName = action.payload.activePosName;
      state.activePosSlug = action.payload.activePosSlug;
      state.activePosGST = action.payload.activePosGST;
      state.isPosExist = true;
    },
    updateSelectedActivePos: (state, action) => {
      state.posData = action.payload;
    },
    updateIsPosActiveDrawer: (state, action) => {
      state.isPosDrawerActive = action.payload;
    },
    updateIsPosExist: (state, action) => {
      state.isPosExist = action.payload;
    },

    updatePosSettingData: (state, action) => {
      state.response = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createPosSettingForm.pending, (state, action) => {
      state.loading = true;
      state.response = [];
      state.apiError = false;
    });
    builder.addCase(createPosSettingForm.fulfilled, (state, action) => {
      state.loading = false;
      state.response.push(action.payload);
      state.apiError = false;
    });
    builder.addCase(createPosSettingForm.rejected, (state, action) => {
      state.loading = false;
      state.response = [];
      state.apiError = true;
    });

    builder.addCase(getAllPosSettingForm.pending, (state, action) => {
      state.loading = true;
      state.response = [];
      state.apiError = false;
    });
    builder.addCase(getAllPosSettingForm.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.apiError = false;

      // if pos array exist

      if (action?.payload?.length > 0) {
        state.activePosId = action?.payload?.[0]?.id;
        state.activePosType = action?.payload?.[0]?.type;
        state.activePosName = action?.payload?.[0]?.name;
        state.activePosSlug = action?.payload?.[0]?.slug;
        state.isPosExist = true;
      }
    });
    builder.addCase(getAllPosSettingForm.rejected, (state, action) => {
      state.loading = false;
      state.response = [];
      state.apiError = true;
    });

    builder.addCase(UpdatePosSettingForm.pending, (state, action) => {
      state.updateLoading = true;
      state.updateApiError = false;
    });
    builder.addCase(UpdatePosSettingForm.fulfilled, (state, action) => {
      if (action.payload.message) {
        message.success(action.payload.message);
      } else {
        message.success("Pos Setting updated!");
      }

      state.updateLoading = false;

      state.response = state.response?.map((item) => {
        if (item.id === action.payload?.data?.id) {
          return action.payload?.data;
        } else {
          return item;
        }
      });

      // action.payload?.navigate(
      //   `/dashboard/${action?.payload?.data?.slug}/pos-setting`
      // );

      state.updateApiError = false;
    });
    builder.addCase(UpdatePosSettingForm.rejected, (state, action) => {
      message.error("Something went Wrong!");
      state.updateLoading = false;
      state.updateApiError = true;
    });

    builder.addCase(createNewPos.pending, (state, action) => {
      state.posApiLoading = true;
      state.posApiError = false;
    });
    builder.addCase(createNewPos.fulfilled, (state, action) => {
      // allTablesData
      message.success("Pos has been created..");
      state.posApiLoading = false;
      if (state?.response?.length == 0) {
        state.activePosId = action?.payload?.id;
        state.activePosType = action?.payload?.type;
        state.activePosName = action?.payload?.name;
        state.activePosSlug = action?.payload?.slug;
        state.activePosGST = action?.payload?.gstRate;
      }
      state.response = [...state.response, action.payload];
      state.posApiError = false;
    });
    builder.addCase(createNewPos.rejected, (state, action) => {
      message.error("Something went wrong!");
      state.posApiLoading = false;
      state.posApiError = false;
    });

    builder.addCase(DeletePos.pending, (state, action) => {
      state.posApiLoading = true;
      state.posApiError = false;
    });
    builder.addCase(DeletePos.fulfilled, (state, action) => {
      message.success("Pos has been deleted..");
      state.posApiLoading = false;
      state.response = state.response.filter(
        (pos) => pos.id !== action.payload.id
      );
      state.posApiError = false;

      if (state?.response?.length == 0) {
        state.activePosId = "";
        state.activePosType = "";
        state.activePosName = "";
        state.activePosSlug = "";
        state.activePosGST = 5;
      }
    });
    builder.addCase(DeletePos.rejected, (state, action) => {
      message.error("Something went wrong!");
      state.posApiLoading = false;
      state.posApiError = false;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_6,
  updateIsPosExist,
  updatesShowingAddTableModel,
  updatesShowingTableModelForEdit,
  updatesShowingAddStewardModel,
  updateSingleTableData,
  updateActivePosId,
  updateIsPosActiveDrawer,
  updatePosSettingData,
  updateSelectedActivePos,
} = posSettingSlice.actions;

export default posSettingSlice.reducer;
