import React from "react";
import ManageStockHeader from "../header/Index";

function ManageStocksWrapper({
  headerName,
  previewTitle,
  leftSideContent,
  rightSideContent,
}) {
  return (
    <div className="flex h-full">
      <div className="w-[65%]">
        <ManageStockHeader headerTitle={headerName} />
        {leftSideContent}
      </div>
      <div
        className={`w-[35%] ${
          headerName === "Report Loss" ? "bg-[#FFFFFF]" : "bg-[#F4F4F4]"
        } h-full`}
      >
        <div className="flex flex-col p-6 h-full">
          <h1>{previewTitle}</h1>
          <div className="flex flex-col h-full">{rightSideContent}</div>
        </div>
      </div>
    </div>
  );
}

export default ManageStocksWrapper;
