import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";
import moment from "moment";
import { getHotelCheckInOutTime24hours } from "../../../utils/hotelHelper";
import { CircularProgress, Tooltip } from "@mui/material";
import { calculateNumberOfNights } from "../../../utils/bookingDetailsHelper";

import { PiCaretRightLight } from "react-icons/pi";
import { PiCaretLeftLight } from "react-icons/pi";
import { Button } from "antd";
import useBookingCalendar from "../../../hooks/bookingCustomHook/useBookingReservation";
import { getTotalBookingAmount } from "../../../utils/bookingReservationHelper";

const ReservationTable = () => {
  const { hotelDetails } = useSelector((store) => store.login);
  const { menuDrawer } = useSelector((store) => store.app);
  const { response } = useSelector((store) => store.rooms);
  const { isMobileAndTabletScreen } = useSelector((store) => store.appHeader);

  const headerRef = useRef(null);
  const mainRef = useRef(null);
  const rightChildRef = useRef(null);

  const [cellWidth, setCellWidth] = useState(172);
  const [isOpenRoomNo, setOpenRoomNo] = useState([]);

  const {
    fromDate,
    toDate,
    bookingResponse = [],
    loading,
  } = useSelector((store) => store.bookingReservation);

  const { CheckInTime24Hour, CheckOutTime24Hour } =
    getHotelCheckInOutTime24hours(hotelDetails);

  // Memoize room names
  const namesToFilter = useMemo(() => {
    const roomTypes = hotelDetails?.roomTypes ? hotelDetails.roomTypes : [];
    return roomTypes.map((item) => item.name);
  }, [hotelDetails?.roomTypes]);

  // Memoize days of the week and comparison dates
  const { daysOfWeek, daysForCompareWithBookingInfo } = useMemo(() => {
    const daysOfWeek = [];
    const daysForCompareWithBookingInfo = [];
    let startDate = moment(fromDate);
    const endDate = moment(toDate);

    while (startDate.isSameOrBefore(endDate, "day")) {
      daysOfWeek.push(startDate.format("ddd D "));
      const currDate = new Date(startDate).setHours(10, 10, 0, 0); // Combined setHours and setMinutes
      daysForCompareWithBookingInfo.push(currDate);
      startDate.add(1, "day");
    }

    return { daysOfWeek, daysForCompareWithBookingInfo };
  }, [fromDate, toDate]);

  // Memoize booking list data
  const bookingListData = useMemo(() => {
    if (!Array.isArray(bookingResponse?.bookings)) return [];
    return bookingResponse.bookings.map((item) => ({
      updatedBookingId: item?.id,
      newBookingId: item?.bookingId,
      id: item.id,
      customerName: item?.primaryGuestName || item?.name,
      customerPhone: item?.primaryGuestPhoneNumber || item?.phone,
      source: item?.source,
      roomId: item?.rooms,
      checkIn: item?.checkIn,
      checkOut: item?.checkOut,
      status: item?.status,
    }));
  }, [bookingResponse]);

  // Calculate position from left
  const positionFromLeftForCell = useCallback(
    (checkIn) => {
      const checkInTime = moment(checkIn).format("hh:mm A");
      const midnight = moment("12:00 AM", "hh:mm A");
      const checkInMoment = moment(checkInTime, "hh:mm A");

      const differenceInMinutes = checkInMoment.diff(midnight, "minutes");
      const percentageTimeDifference = differenceInMinutes / 1440;
      return parseFloat(percentageTimeDifference * cellWidth);
    },
    [cellWidth]
  );

  // Process room and booking data
  const { roomNoAndRoomType, RoomWithCount } = useMemo(() => {
    const countOnDay = {};
    const roomNoAndRoomType = {};
    const RoomWithCount = {};

    response?.forEach((room) => {
      if (room.status === "active") {
        RoomWithCount[room.type] = (RoomWithCount[room.type] || 0) + 1;

        const bookingArrayForRoom = daysForCompareWithBookingInfo.map((val) => {
          const bookingsForDateForRoom = bookingListData.filter((booking) => {
            const checkedInValue = moment(booking.checkIn).format("L");
            let checkedOutValue = moment(booking.checkOut);
            const checkOutTime = moment(booking.checkOut).format("HH");

            if (Number(checkOutTime) > Number(CheckOutTime24Hour))
              checkedOutValue = checkedOutValue.format("L");
            else
              checkedOutValue = checkedOutValue.subtract(1, "days").format("L");

            return (
              moment(val).format("L") >= checkedInValue &&
              moment(val).format("L") <= checkedOutValue &&
              booking.roomId?.includes(room.id)
            );
          });

          if (bookingsForDateForRoom.length > 0) {
            const formattedDay = moment(val).format("ddd D MMM");
            countOnDay[formattedDay] = (countOnDay[formattedDay] || 0) + 1;
          }

          return bookingsForDateForRoom?.map((booking) => ({
            newBookingId: booking?.newBookingId,
            bookingId: booking?.id,
            updatedBookingId: booking?.bookingId,
            customerName: booking?.customerName,
            customerPhone: booking?.customerPhone,
            source: booking?.source,
            status: booking?.status,
            checkIn: booking?.checkIn,
            checkOut: booking?.checkOut,
            destination: booking?.destination,
            foodChoice: booking?.foodChoice,
            arrival: booking?.arrival,
            purposeOfVisit: booking?.purposeOfVisit,
            remarks: booking?.remarks,
            idImages: booking?.idImages,
            totalAmount: getTotalBookingAmount(bookingResponse, booking?.id),
            nights: calculateNumberOfNights(
              booking.checkIn,
              booking.checkOut,
              hotelDetails
            ),
            positionFromLeft: positionFromLeftForCell(booking?.checkIn),
          }));
        });

        if (bookingArrayForRoom.length > 0) {
          roomNoAndRoomType[room.type] = roomNoAndRoomType[room.type] || [];
          roomNoAndRoomType[room.type].push({
            id: room.id,
            name: room.name,
            roomStatus: room.houseKeepingStatus,
            bookingInfo: bookingArrayForRoom,
            roomData: room,
          });
        } else {
          roomNoAndRoomType[room.type] = roomNoAndRoomType[room.type] || [];
          roomNoAndRoomType[room.type].push({
            id: room.id,
            name: room.name,
            roomStatus: room.houseKeepingStatus,
            bookingInfo: [],
            roomData: room,
          });
        }
      }
    });

    return { countOnDay, roomNoAndRoomType, RoomWithCount };
  }, [
    response,
    bookingListData,
    daysForCompareWithBookingInfo,
    CheckOutTime24Hour,
    positionFromLeftForCell,
    hotelDetails?.id,
  ]);

  // Filter room types
  let filteredRoomNoAndRoomType = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(roomNoAndRoomType).filter(([key]) =>
          namesToFilter.includes(key)
        )
      ),
    [roomNoAndRoomType, namesToFilter]
  );

  const visibleRoomNoAndBookingDetails = useCallback(
    (val) => {
      setOpenRoomNo((prev) =>
        isOpenRoomNo?.includes(val)
          ? prev.filter((Obj) => Obj !== val)
          : [...prev, val]
      );
    },
    [isOpenRoomNo]
  );

  const { getPreferredBooking, scroll, redirectBooking, getOccupency } =
    useBookingCalendar(
      mainRef,
      headerRef,
      rightChildRef,
      cellWidth,
      setCellWidth,
      daysOfWeek,
      filteredRoomNoAndRoomType,
      setOpenRoomNo,
      daysForCompareWithBookingInfo
    );

  return (
    <div
      className={` fixed top-[155px] right-[22px]  ${
        isMobileAndTabletScreen
          ? "left-[22px] "
          : menuDrawer
          ? "left-[102px] "
          : "left-[230px] "
      } overflow-x-auto 
    ] no-scrollbar::-webkit-scrollbar no-scrollbar   rounded-t-[16px] flex flex-col 
              gap-0`}
    >
      <div
        ref={headerRef}
        className={`headDiv fixed   no-scrollbar::-webkit-scrollbar no-scrollbar top-[155px] right-[22px] 
         ${
           isMobileAndTabletScreen
             ? "left-[22px] "
             : menuDrawer
             ? "left-[102px] "
             : "left-[230px] "
         }  flex overflow-x-auto`}
      >
        <div
          className=" justify-center items-center flex  min-w-[130px] h-[60px]   bg-[#7B8794]  
          text-[#FFF]  font-outfit font-[500]  text-[16px]  whitespace-nowrap leading-[24px]  rounded-tl-[12px] sticky left-0"
        >
          Room Type/No
        </div>

        <div
          ref={rightChildRef}
          className=" rounded-tr-[16px] h-[60px]   flex  
          w-[100%]  items-center  "
        >
          {daysOfWeek.map((Obj, index) => {
            const [day, date] = Obj.split(" ");

            return (
              <div
                style={{
                  minWidth: `${cellWidth}px`,
                }}
                key={index}
                className={` ${
                  daysOfWeek?.length - 1 === index ? "rounded-tr-[16px]  " : ""
                } text-center flex-1 h-[60px]   whitespace-normal  flex flex-col justify-center items-center bg-[#FFF] border`}
              >
                <div className="text-[#696969] font-outfit font-[400] text-[14px] leading-[17px] tracking-wider uppercase  ">
                  {day}
                </div>

                <div className="text-[#000000] font-outfit font-[500] text-[16px] leading-[20px] ">
                  {date}
                </div>
              </div>
            );
          })}

          <Button
            style={{ boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)" }}
            onClick={() => scroll(-1)}
            icon={
              <PiCaretLeftLight
                className="text-[18px] 
            text-[#007FFF]"
              />
            }
            className={`
            fixed 
            ${
              isMobileAndTabletScreen
                ? "left-[160px] "
                : menuDrawer
                ? "left-[250px] "
                : "left-[375px] "
            } 
            z-[100]  bg-[#FAFAFA] 
             w-[40px] h-[40px] rounded-[100%]   top-[10.2rem] 
          flex justify-center items-center`}
          />

          <Button
            style={{ boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)" }}
            onClick={() => scroll(+1)}
            icon={<PiCaretRightLight className="text-[20px] text-[#007FFF]" />}
            className={`
            fixed 
            ${
              isMobileAndTabletScreen
                ? "right-[25px] "
                : menuDrawer
                ? "right-[30px] "
                : "right-[37px] "
            } 
            z-[100]  bg-[#FAFAFA] 
             w-[40px] h-[40px] rounded-[100%]   top-[10.2rem] 
          flex justify-center items-center`}
          />
        </div>
      </div>

      {loading ? (
        <div className=" w-[100%] h-[100vh] flex justify-center items-center">
          {" "}
          <CircularProgress size={22} />
        </div>
      ) : (
        <div
          ref={mainRef}
          className={` mainContainer  h-[72vh] pb-[4rem] fixed top-[215px] right-[22px] ${
            isMobileAndTabletScreen
              ? "left-[22px] "
              : menuDrawer
              ? "left-[102px] "
              : "left-[230px] "
          } flex flex-col overflow-auto no-scrollbar::-webkit-scrollbar no-scrollbar`}
        >
          {Object.keys(filteredRoomNoAndRoomType)?.map((val, index) => {
            return (
              <div key={index} className="flex flex-col relative  w-[100%]">
                <div className=" flex sticky   top-0 left-0 z-[300] bg-[#F2F4FC] ">
                  <div
                    style={{
                      boxShadow: "#00000021 2.4px 2.4px 3.2px",
                    }}
                    className={` flex justify-start     pl-[10px] items-center cursor-pointer 
                   min-w-[130px] sticky left-0 bg-[#F5F7FA]  " 
                   py-[10px]   `}
                    onClick={() => visibleRoomNoAndBookingDetails(val)}
                  >
                    <Tooltip
                      title={val ? (val.length > 8 ? val : "") : ""}
                      className="font-outfit font-[500] text-[14px] leading-[21px] text-[#222B45] w-[80%] whitespace-nowrap "
                    >
                      {val
                        ? val.length > 8
                          ? `${val.substring(0, 8)}..`
                          : val
                        : ""}
                    </Tooltip>

                    <div className="w-[20%] flex justify-start ">
                      <div className="bg-[#007fff1a] rounded-[50%] flex justify-center items-center cursor-pointer w-[18px] h-[18px]">
                        {isOpenRoomNo?.includes(val) ? (
                          <FaAngleUp className="text-[#007FFF] text-[12px]" />
                        ) : (
                          <FaAngleDown className="text-[#007FFF] text-[12px]" />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-[100%]  flex  items-center ">
                    {daysOfWeek.map((day, dayIndex) => {
                      let Occupied = getOccupency(
                        day,
                        val,
                        filteredRoomNoAndRoomType[val],
                        dayIndex
                      );

                      let totalRoom = RoomWithCount?.[val];

                      let occupiedPercent = Math.round(
                        (Occupied[day] * 100) / totalRoom
                      );

                      return (
                        <div
                          style={{
                            minWidth: `${cellWidth}px`,
                          }}
                          className="font-outfit font-[500] bg-[#F5F7FA]  text-[#8F9BB3] text-[14px] leading-[21px] p-2 flex justify-center items-center flex-1"
                          key={dayIndex}
                        >
                          {Boolean(occupiedPercent)
                            ? occupiedPercent >= 100
                              ? 100
                              : occupiedPercent
                            : 0}
                          %
                        </div>
                      );
                    })}
                  </div>
                </div>

                {isOpenRoomNo?.includes(val) &&
                  filteredRoomNoAndRoomType?.[val]?.map((roomNo, index) => {
                    return (
                      <div className="flex w-[100%]" key={index}>
                        <div
                          style={{
                            boxShadow: "#00000021 2.4px 2.4px 3.2px",
                          }}
                          className="min-w-[130px] border-b-[1px] border-[#F2F4FC] z-[60]   pl-[10px] py-[20px] sticky left-0   bg-white"
                        >
                          {roomNo?.name}
                        </div>

                        <div className="flex w-[100%]">
                          {roomNo.bookingInfo?.map(
                            (bookingArrayForRoomForDate, index) => {
                              let currentBooking = getPreferredBooking(
                                bookingArrayForRoomForDate
                              );

                              let currentBookingId =
                                currentBooking?.newBookingId;

                              let previousBookingId =
                                index === 0
                                  ? null
                                  : getPreferredBooking(
                                      roomNo.bookingInfo?.[index - 1]
                                    )?.newBookingId;

                              let isBookingSameAsPreviousDay = Boolean(
                                currentBookingId === previousBookingId
                              );

                              const MS_IN_ONE_DAY = 1000 * 60 * 60 * 24;

                              const currentDateInMillis = fromDate
                                ? moment(fromDate).valueOf()
                                : moment().valueOf();

                              const lastDateInMillis = toDate
                                ? moment(toDate)?.endOf("day")?.valueOf()
                                : moment().valueOf();

                              const checkInDateInMillis = moment(
                                currentBooking?.checkIn
                              ).valueOf();

                              const checkOutDateInMillis = moment(
                                currentBooking?.checkOut
                              ).valueOf();

                              let CheckInDatePassed =
                                checkInDateInMillis < currentDateInMillis &&
                                checkOutDateInMillis <= lastDateInMillis;

                              let nights;
                              let left;

                              // Case 1: CheckIn and CheckOut date both lie within the container's fromDate and toDate
                              if (
                                checkInDateInMillis >= currentDateInMillis &&
                                checkOutDateInMillis <= lastDateInMillis
                              ) {
                                let diffInMillis =
                                  checkOutDateInMillis - checkInDateInMillis;

                                nights = diffInMillis / MS_IN_ONE_DAY;
                                left = `${currentBooking?.positionFromLeft}px`;

                                // Case 2: CheckIn Date is before the container's fromDate but CheckOut Date lies within the container
                              } else if (
                                checkInDateInMillis < currentDateInMillis &&
                                checkOutDateInMillis <= lastDateInMillis
                              ) {
                                let diffInMillis =
                                  checkOutDateInMillis - currentDateInMillis;
                                nights = diffInMillis / MS_IN_ONE_DAY;

                                left = `${0}px`;

                                // Case 3: CheckIn Date lies within the container but CheckOut date falls outside the container
                              } else if (
                                checkInDateInMillis >= currentDateInMillis &&
                                checkOutDateInMillis > lastDateInMillis
                              ) {
                                let diffInMillis =
                                  lastDateInMillis - checkInDateInMillis;
                                nights = diffInMillis / MS_IN_ONE_DAY;

                                left = `${currentBooking?.positionFromLeft}px`;

                                // Case 4: Both CheckIn and CheckOut dates lie outside the container's fromDate and toDate
                              } else if (
                                checkInDateInMillis < currentDateInMillis &&
                                checkOutDateInMillis > lastDateInMillis
                              ) {
                                let diffInMillis =
                                  lastDateInMillis - currentDateInMillis;
                                nights = diffInMillis / MS_IN_ONE_DAY;
                                left = `${0}px`;
                              }

                              let status = currentBooking?.status;
                              let custName = currentBooking?.customerName;

                              let todaysDate = moment().format("DD MMM YYYY");
                              let currentCellDate = moment(
                                daysForCompareWithBookingInfo[index]
                              ).format("DD MMM YYYY");

                              let todayDateBooking = Boolean(
                                todaysDate === currentCellDate
                              );

                              if (
                                currentCellDate !==
                                  moment(currentBooking?.checkIn)?.format(
                                    "DD MMM YYYY"
                                  ) &&
                                moment(currentBooking?.checkIn)?.format(
                                  "DD MMM YYYY"
                                ) >= moment(fromDate)?.format("DD MMM YYYY")
                              ) {
                                nights = 0;
                              }

                              // Calculate the minimum width of the cell
                              let minWidthidthOfThisCell =
                                nights * cellWidth + "px";

                              return bookingArrayForRoomForDate?.length > 0 &&
                                nights > 0 ? (
                                <div
                                  style={{
                                    minWidth: `${cellWidth}px`,
                                  }}
                                  onClick={() => redirectBooking(null)}
                                  className={` border-[1px] border-r-[0.5px] border-b-0 border-l border-t 
                                   border-[#F2F4FC] bg-[#FFF]   relative px-2 py-2 cursor-pointer flex-1`}
                                  key={index}
                                >
                                  <div
                                    style={{
                                      left: left,
                                    }}
                                    className={`${
                                      status === "confirmed"
                                        ? "bg-[#9747FF]"
                                        : status === "checkedIn"
                                        ? "bg-[#F36960]"
                                        : status === "completed"
                                        ? "bg-[#717171]"
                                        : `bg-[#F59E0B]`
                                    }  ${
                                      isBookingSameAsPreviousDay ||
                                      CheckInDatePassed
                                        ? "hidden"
                                        : "absolute top-0 h-[100%] rounded-r-[10px] w-[5px] z-[50]"
                                    }`}
                                  />

                                  <div
                                    onClick={(e) => {
                                      redirectBooking(currentBooking);
                                      e.stopPropagation();
                                    }}
                                    style={{
                                      position: "absolute",
                                      left: left,
                                      width: minWidthidthOfThisCell,
                                      minWidth: minWidthidthOfThisCell,
                                    }}
                                    className={`  ${
                                      status === "confirmed"
                                        ? "bg-[#E3D5FF]"
                                        : status === "checkedIn"
                                        ? "bg-[#FFE1DF]"
                                        : status === "completed"
                                        ? "bg-[#DBDDE1]"
                                        : `bg-[#FEF6E7]`
                                    } max-w-[1000px]  top-0 bottom-0 rounded-r-[6px] flex flex-col gap-1 p-2
                  ${isBookingSameAsPreviousDay ? "hidden" : "z-[30]"}`}
                                  >
                                    <div className="flex justify-between px-2 items-center">
                                      <p
                                        className={` ${
                                          status === "confirmed"
                                            ? "text-[#9747FF]"
                                            : status === "checkedIn"
                                            ? "text-[#F36960]"
                                            : status === "completed"
                                            ? "text-[#717171]"
                                            : "text-[#B45309]"
                                        } flex font-outfit whitespace-nowrap font-[600] ${
                                          nights < 1
                                            ? "text-[10px]"
                                            : "text-[16px]"
                                        }  leading-[24px]`}
                                      >
                                        {nights > 1
                                          ? custName.slice(0, 14)
                                          : custName?.length > 7
                                          ? custName.slice(0, 7)
                                          : custName}
                                      </p>

                                      <p
                                        className={`${
                                          status === "confirmed"
                                            ? "text-[#9747FF]"
                                            : status === "checkedIn"
                                            ? "text-[#F36960]"
                                            : status === "completed"
                                            ? "text-[#717171]"
                                            : "text-[#B45309]"
                                        } ${
                                          nights < 1
                                            ? "text-[8px]"
                                            : "text-[12px]"
                                        } font-outfit font-[500] capitalize`}
                                      >
                                        {Boolean(currentBooking?.source)
                                          ? currentBooking?.source?.substring(
                                              0,
                                              7
                                            )
                                          : ""}
                                      </p>
                                    </div>

                                    <div className="flex justify-between px-2 items-center">
                                      <div
                                        className={` ${
                                          status === "confirmed"
                                            ? "text-[#717171]"
                                            : status === "checkedIn"
                                            ? "text-[#717171]"
                                            : status === "completed"
                                            ? "text-[#717171]"
                                            : "text-[#717171]"
                                        } ${
                                          nights < 1
                                            ? "text-[8px]"
                                            : "text-[12px]"
                                        } font-outfit font-[400]  `}
                                      >
                                        Id -{" "}
                                        {nights >= 2
                                          ? currentBooking?.newBookingId
                                          : currentBooking?.newBookingId?.slice(
                                              -7
                                            )}
                                      </div>

                                      <div
                                        className={` text-[#F36960] font-outfit font-[400] ${
                                          nights < 1
                                            ? "text-[8px]"
                                            : "text-[12px]"
                                        } whitespace-nowrap`}
                                      >
                                        {currentBooking?.totalAmount}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    minWidth: `${cellWidth}px`,
                                  }}
                                  onClick={() =>
                                    redirectBooking(
                                      null,
                                      index,
                                      val,
                                      roomNo.roomData
                                    )
                                  }
                                  className={` px-2 py-2 border-[1px]
                border-r-[0.5px] flex-1  border-b-0 border-l border-t
                border-[#F2F4FC]  cursor-pointer uppercase
                font-outfit font-[700] text-[10px] leading-[12.68px] tracking-[5px] flex justify-center items-center
                ${
                  // background: #FF910008;

                  todayDateBooking
                    ? roomNo.roomData?.houseKeepingStatus === "clean"
                      ? "bg-[#FCFFF9] text-[#60B707] opacaity-[3]"
                      : "bg-[#FFF9F1] text-[#FF9100] "
                    : "bg-[#FFF]"
                } `}
                                  key={index}
                                >
                                  {todayDateBooking
                                    ? roomNo.roomData?.houseKeepingStatus
                                    : ""}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ReservationTable;
