import { StyleSheet } from "@react-pdf/renderer";

import { Font } from "@react-pdf/renderer";

Font.register({
  family: "Outfit",
  src: "https://fonts.gstatic.com/s/outfit/v2/RdTnFjFHeKkD_JtQKDPFQ98ItJFdA8JsnM9u.woff2",
});

const styles = StyleSheet.create({
  body: {
    flexDirection: "row",
    fontFamily: "Helvetica",
    fontSize: 8,
    padding: "10px",

    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  col: {
    padding: 10,
    boxSizing: "border-box",
  },
  col1: { flexBasis: "8.33%" },
  col2: { flexBasis: "16.66%" },
  col3: { flexBasis: "25%" },
  col4: { flexBasis: "33.33%" },
  col5: { flexBasis: "41.66%" },
  col6: {
    flexBasis: "50%",
  },
  col7: { flexBasis: "58.33%" },
  col8: { flexBasis: "66.66%" },
  col9: { flexBasis: "75%" },
  col10: { flexBasis: "83.33%" },
  col11: { flexBasis: "91.66%" },
  col12: { flexBasis: "100%" },

  imageContainer: {
    width: 30,
    height: 30,
    borderRadius: 4,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 4,
  },

  logoDiv: {
    width: "30px",
    height: "30px",
    borderRadius: "4px",
  },
  logo: {
    width: "100%",
    height: "100%",
    borderRadius: "4px",
  },

  icon: {
    width: "10px",
    height: "10px",
    borderRadius: "4px",
  },

  hotelName: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 14,
    marginLeft: "8px",
    marginTop: "6px",
  },
  title: {
    fontFamily: "Outfit",
    color: "#1A1C21",
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 15,
  },
  highlightedTitle: {
    fontFamily: "Outfit",
    color: "#F36960",
    fontWeight: 600,
    fontSize: 8,
    lineHeight: 15,
  },

  headerRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end", // Aligns children to the right horizontally
    alignItems: "flex-end", // Aligns children to the right vertically
    // Other header styles...
  },

  rightCenteredRow: {
    flexDirection: "row",
    justifyContent: "flex-end", // Aligns elements to the right within the row
    alignItems: "center", // Vertically aligns items in the center
  },
  invoiceTitle: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  defaultStyle: {
    fontSize: "8px",
    marginTop: "4px",
  },
  titleDanger: {
    color: "red",
    marginTop: "4px",
  },

  qrImage: {
    width: "180px",
    height: "180px",
  },

  posName: {
    marginTop: 10,
    fontFamily: "Outfit",
    fontSize: 8,
    color: "#4d4d4d",
    backgroundColor: "#EBEBEB",
    paddingHorizontal: 18,
    paddingVertical: 6,
  },

  footer: {
    position: "absolute",
    bottom: 35,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 8,
  },
  content: {
    flexGrow: 1,
  },
});

export default styles;
