import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";

const Heading = ({ setOrderDetailScreenVisible }) => {
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);

  return (
    <header className="flex bg-[#FFF] justify-between items-center p-3 pt-7 shadow-md fixed top-0 left-0 w-[100%] z-[20]">
      <div className="flex items-center gap-2">
        <div className="p-2 ">
          <IoIosArrowRoundBack
            onClick={() => setOrderDetailScreenVisible(false)}
            className="text-[30px]"
          />
        </div>
        <div className="flex gap-1 items-end">
          <div className="font-outfit font-[500] text-[18px] leading-[27px] text-[#222222]">
            {`Order for Table ${
              Boolean(tableOrderDetails?.tableNumber)
                ? tableOrderDetails?.tableNumber
                : ""
            }`}
          </div>
        </div>
      </div>
      <div className="font-outfit font-[500] text-[14px] leading-[21px] text-[#007FFF]">
        {/* Hold */}
      </div>
    </header>
  );
};

export default Heading;
