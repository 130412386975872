import React, { Suspense, lazy, useEffect, useRef } from "react";
// import DashBoard from "../../components/dashboard/index";
// import MobileDashBoard from "../../mobileComponents/mobileDashboard/index";
import { GET_FILTERED_DATES, GET_ISO_FORMAT } from "../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import { getHotelDashboard } from "../../reducer/dashboardReducer";
import { updateOrganisationDropDownDisplay } from "../../reducer/appHeaderReducer";
import Loading from "../../pages/loading";
const DashBoard = lazy(() => import("../../components/dashboard/index"));

const MobileDashBoard = lazy(() =>
  import("../../mobileComponents/mobileDashboard/index")
);

const Index = () => {
  const dispatch = useDispatch();
  const { title } = useSelector((store) => store.dashboard);

  const { hotelDetails, selectedHotels, isAllHotelsSelected } = useSelector(
    (store) => store.login
  );
  const { isMobileScreen } = useSelector((store) => store.appHeader);
  const { activeMenu } = useSelector((store) => store.app);

  const prevValuesRef = useRef({});

  useEffect(() => {
    const prevValues = prevValuesRef.current;
    const newValues = { title, selectedHotels, activeMenu };
    if (isAllHotelsSelected && selectedHotels.length == 1) return;
    if (
      haveValuesChanged(prevValues, newValues) ||
      !Object.keys(prevValues).length
    ) {
      // Update ref with new values
      prevValuesRef.current = newValues;

      // Common API call for Mobile and Desktop Component
      if (selectedHotels?.length) {
        const { calculatedStartDate, calculatedEndDate } =
          GET_FILTERED_DATES(title);

        const payload = {
          hotelIds: selectedHotels.toString(),
          startDate: GET_ISO_FORMAT(calculatedStartDate),
          endDate: GET_ISO_FORMAT(calculatedEndDate),
        };

        dispatch(getHotelDashboard(payload));
      }
    }
  }, [selectedHotels, title, activeMenu]);

  const haveValuesChanged = (prevValues, newValues) => {
    return (
      JSON.stringify(prevValues.selectedHotels) !==
        JSON.stringify(newValues.selectedHotels) ||
      prevValues.title !== newValues.title ||
      prevValues.hotelDetails !== newValues.hotelDetails ||
      prevValues.activeMenu !== newValues.activeMenu
    );
  };

  return (
    <Suspense fallback={<Loading />}>
      <div
        onClick={(e) => {
          dispatch(updateOrganisationDropDownDisplay(false));
        }}
      >
        {isMobileScreen ? <MobileDashBoard /> : <DashBoard />}
      </div>
    </Suspense>
  );
};

export default Index;
