import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrdersService } from "../services/getOrders";
import { updateOrderService } from "../services/getOrders";

import { message } from "antd";

export const getAllOrders = createAsyncThunk(
  "allOrders/getOrders",
  async (queryParams) => {
    const response = await getOrdersService(queryParams);
    return response;
  }
);

export const updateOrder = createAsyncThunk(
  "allOrders/updateOrder",
  async (payload) => {
    const response = await updateOrderService(payload);
    return response;
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const allOrdersSlice = createSlice({
  name: "allOrders",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_22: () => initialState,
    resetState: (state, action) => {
      state.loading = true;
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.data = [
        ...state.data,
        ...action.payload.filter(
          (order) => !state.data.some((data) => data.id === order.id)
        ),
      ];
      state.error = false;
    });
    builder.addCase(getAllOrders.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllOrders.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
    //
    builder.addCase(updateOrder.fulfilled, (state, action) => {
      if (action.payload.status === "cancelled") {
        // message.success("Order cancelled successfully");
      } else if (action.payload.status === "served") {
        message.success("Food served");
      }
      state.loading = false;
      state.data = state.data.map((order) =>
        order.id === action.payload.id ? action.payload : order
      );
      state.error = false;
    });
    builder.addCase(updateOrder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateOrder.rejected, (state, action) => {
      message.error("Error in updating order");
      state.loading = false;
      state.error = true;
    });
  },
});

export const { CLEAR_REDUX_STORE_22, resetState } = allOrdersSlice.actions;
export default allOrdersSlice.reducer;
