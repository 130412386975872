import moment from "moment";

export const TOKEN = window.localStorage.getItem("token");

export const isObject = (val) => {
  if (val === null) return false;

  return typeof val === "function" || typeof val === "object";
};

// TO CALCULATE SCREEN HEIGHT
var body = document.body,
  html = document.documentElement;
export const SCREEN_HEIGHT = Math.max(
  body.scrollHeight,
  body.offsetHeight,
  html.clientHeight,
  html.scrollHeight,
  html.offsetHeight
);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const days = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

export const PAYMENT_TYPE_BG_COLOR = {
  0: "#013F94",
  1: "#2F80ED",
  2: "#90CAF9",
  3: "#BDD8FE",
  4: "#CEEFF2",
};

export const paymentModeTypes = {
  upi: "UPI - Bank",
  card: "Card - Bank",
  bank: "Bank",
  cash: "Petty Cash",
};

export const paymentModes = [
  { name: "Cash", key: "cash" },
  { name: "UPI", key: "upi" },
  { name: "Card", key: "card" },
];

export const paymentModeLabel = {
  cash: "Cashier",
  upi: "Trn no",
  card: "Trn no",
  bank: "Trn no",
};

export const numberToWords = (amount) => {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const thousands = ["", "Thousand", "Million", "Billion", "Trillion"];
  let words = "";
  if (amount === 0) {
    return "Zero";
  }
  // Function to convert three digits
  function convertThreeDigits(num) {
    let result = "";
    let hundreds = Math.floor(num / 100);
    let remainder = num % 100;
    if (hundreds > 0) {
      result += ones[hundreds] + " Hundred ";
    }
    if (remainder > 10 && remainder < 20) {
      result += teens[remainder - 10] + " ";
    } else {
      let tensDigit = Math.floor(remainder / 10);
      let onesDigit = remainder % 10;
      if (tensDigit > 0) {
        result += tens[tensDigit] + " ";
      }
      if (onesDigit > 0) {
        result += ones[onesDigit] + " ";
      }
    }
    return result.trim();
  }
  let chunkCount = 0;
  while (amount > 0) {
    let chunk = amount % 1000;
    if (chunk !== 0) {
      let chunkWords = convertThreeDigits(chunk);
      words = chunkWords + " " + thousands[chunkCount] + " " + words;
    }
    amount = Math.floor(amount / 1000);
    chunkCount++;
  }
  return Boolean(words.trim()) ? words?.trim() + " Only" : "Zero";
};

export const FOLIO_FORMAT = (Num) => {
  let number = +Num || 0;

  // Check if the number has decimals
  if (Number.isInteger(number)) {
    return number?.toString(); // Display as a whole number
  } else if (Boolean(number)) {
    return number?.toFixed(2); // Display with two decimals
  }
};

// Function to capitalize the first letter of a string
export const capitalizeFirstLetter = (str) => {
  if (!str || !Boolean(str.length)) return str;
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const INVOICE_DATE_AND_TIME_FORMAT = (timestamp) => {
  const dateObject = new Date(timestamp);

  // (1) Get the date in the format "10 Jun 2023"
  const optionsDate = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = dateObject.toLocaleDateString("en-US", optionsDate);

  // (2) Get the time in the format "02:23 PM"
  const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };
  const formattedTime = dateObject.toLocaleTimeString("en-US", optionsTime);

  return { formattedDate, formattedTime };
};

export const GET_FILTERED_DATES = (filter) => {
  const today = new Date();
  let calculatedStartDate;
  let calculatedEndDate = new Date(today);
  calculatedEndDate.setDate(today.getDate() + 1);
  calculatedEndDate.setHours(0, 0, 0, 0);

  if (!Boolean(filter)) {
    calculatedStartDate = today.toISOString();
    return { calculatedStartDate, calculatedEndDate };
  }

  if (filter === "Today") {
    today.setHours(0, 0, 0, 0);
    calculatedStartDate = today.toISOString();
  } else if (filter === "Weekly") {
    calculatedStartDate = moment().startOf("week").add(1, "days");
    calculatedEndDate = moment().endOf("week").add(1, "days");
  } else if (filter === "Monthly") {
    calculatedStartDate = moment().startOf("month");
    calculatedEndDate = moment().endOf("month");
  }
  return { calculatedStartDate, calculatedEndDate };
};

export const BOOKING_LIST_DATE_FORMET = (value) => {
  const d = new Date(value);
  let date = d.getDate();
  let month = months[d.getMonth()];
  return `${date} ${month}`;
};

export const UPCOMING_DIRTY_DATE_FORMAT = () => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();
  return `${day}, ${month}, ${year}`;
};

export const LAST_INACIVE_DATE_FORMAT = (timestampInMs) => {
  const date = new Date(timestampInMs);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  const formattedDate = `${day}, ${month}, ${year}`;

  return formattedDate;
};

export const BOOKING_DETAILS_DATE_FORMET = (value) => {
  // fri,11 Nov 2022
  const d = Boolean(value) ? new Date(value) : new Date();
  let date = d.getDate();
  let day = days[d.getDay()];
  let month = months[d.getMonth()];
  let year = d.getFullYear();

  return `${day},  ${date} ${month} ${year}`;
};

export const CHANNEL_MANAGER_DATE_FORMAT = (value) => {
  const d = new Date(value);
  let date = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  return `${date}/${month}/${year}`;
};

export const DATE_PICKER_FORMAT = (value) => {
  const d = Boolean(value) ? new Date(value) : new Date();
  let date = d.getDate();
  let month = d.getMonth();
  let year = d.getFullYear();
  return `${date}/${month}/${year}`;
};

export const GET_YEAR_FULL = (value) => {
  // 23
  const d = Boolean(value) ? new Date(value) : new Date();
  let year = d.getFullYear();
  return year;
};

export const GET_DAY_IN_STRING = (value) => {
  // Mon
  const d = Boolean(value) ? new Date(value) : new Date();
  let dayInString = days[d.getDay() + 1];
  return dayInString;
};

export const INDIVIDUAL_GET_TODAY_DATE_WITH_ISO_FORMAT = () => {
  let dateobj = new Date();
  let dateISO = dateobj.toISOString();
  return dateISO;
};

export const BOOKING_DETAILS_TIME_FORMET = (date) => {
  const d = Boolean(date) ? new Date(date) : new Date();
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const CALCULATE_NIGHTS_COUNT = (date1, date2, hotelDetails) => {
  // when hotelDetails is not fetched, do not execute the function, it is giving error

  if (!hotelDetails || !Object.keys(hotelDetails)?.length) return;

  // if 24 hour formatenambled
  if (hotelDetails?.enableTwentyFourHourCheckIn) {
    const differenceInHours = moment(date2).diff(moment(date1), "hours");

    const differenceInMinutes = moment(date2).diff(moment(date1), "minutes");

    let differenceInDays = Math.floor(differenceInHours / 24);

    const remainingMinDiff = differenceInMinutes - differenceInDays * 24 * 60;

    // Checking if the difference exceeds a full day and an additional 30 minutes
    if (differenceInHours >= 24 && remainingMinDiff > 30) differenceInDays++;

    return Boolean(differenceInDays) ? differenceInDays : 1;
  } else {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const singleDayTime = 1000 * 60 * 60 * 24;
    const singleMinuteTime = 1000 * 60;
    const singleHourTime = 1000 * 60 * 60;

    const checkInTime = hotelDetails?.checkInTime;
    const checkOutTime = hotelDetails?.checkOutTime;

    const earlyCheckInTime = hotelDetails?.earlyCheckInTime
      ? hotelDetails?.earlyCheckInTime
      : "04:00";

    const lateCheckOutTime = hotelDetails?.lateCheckOutTime
      ? hotelDetails?.lateCheckOutTime
      : "04:00";

    let diffDays = 0;
    let defaultCheckInTimeOnStartDate = new Date(date1);
    defaultCheckInTimeOnStartDate = new Date(
      defaultCheckInTimeOnStartDate.setHours(checkInTime.split(":")[0])
    );
    defaultCheckInTimeOnStartDate = new Date(
      defaultCheckInTimeOnStartDate.setMinutes(
        Number(checkInTime.split(":")[1])
      )
    );

    const cutOffCheckInTimeOnStartDate = new Date(
      defaultCheckInTimeOnStartDate.getTime() -
        singleHourTime * earlyCheckInTime.split(":")[0] -
        singleMinuteTime * earlyCheckInTime.split(":")[1]
    );

    let defaultCheckOutTimeOnStartDate = new Date(date1);
    defaultCheckOutTimeOnStartDate = new Date(
      defaultCheckOutTimeOnStartDate.setHours(checkOutTime.split(":")[0])
    );
    defaultCheckOutTimeOnStartDate = new Date(
      defaultCheckOutTimeOnStartDate.setMinutes(checkOutTime.split(":")[1])
    );
    defaultCheckOutTimeOnStartDate = new Date(
      defaultCheckOutTimeOnStartDate.setSeconds(0)
    );
    defaultCheckOutTimeOnStartDate = new Date(
      defaultCheckOutTimeOnStartDate.setMilliseconds(0)
    );

    let defaultCheckoutTimeOnEndDate = new Date(date2);
    defaultCheckoutTimeOnEndDate = new Date(
      defaultCheckoutTimeOnEndDate.setHours(checkOutTime.split(":")[0])
    );
    defaultCheckoutTimeOnEndDate = new Date(
      defaultCheckoutTimeOnEndDate.setMinutes(checkOutTime.split(":")[1])
    );
    defaultCheckoutTimeOnEndDate = new Date(
      defaultCheckoutTimeOnEndDate.setSeconds(0)
    );
    defaultCheckoutTimeOnEndDate = new Date(
      defaultCheckoutTimeOnEndDate.setMilliseconds(0)
    );
    const cutOffCheckOutTimeOnEndDate = new Date(
      defaultCheckoutTimeOnEndDate.getTime() +
        singleHourTime * lateCheckOutTime.split(":")[0] +
        singleMinuteTime * lateCheckOutTime.split(":")[1]
    );

    let defaultCheckInTimeOnEndDate = new Date(date2);
    defaultCheckInTimeOnEndDate = new Date(
      defaultCheckInTimeOnEndDate.setHours(checkInTime.split(":")[0])
    );
    defaultCheckInTimeOnEndDate = new Date(
      defaultCheckInTimeOnEndDate.setMinutes(checkInTime.split(":")[1])
    );

    if (d1.getTime() < cutOffCheckInTimeOnStartDate.getTime()) {
      diffDays++;
    }

    diffDays += Math.floor(
      Math.abs(defaultCheckoutTimeOnEndDate - defaultCheckOutTimeOnStartDate) /
        singleDayTime
    );
    if (d2.getTime() > cutOffCheckOutTimeOnEndDate.getTime()) {
      diffDays++;
    }

    return diffDays;
  }
};

export const CALCULATE_CHECKID_DATE_CHECKOUT_DATE = (date1, date2) => {
  let oDateOne = new Date(date1);
  let oDateTwo = new Date(date2);
  if (oDateTwo - oDateOne >= 0) {
    return true;
  }
  return false;
};

export const CALCULATE_BACK_DATE = (date) => {
  let oDateOne = new Date(date);
  let oDateTwo = new Date();
  if (oDateTwo - oDateOne >= 0) {
    return true;
  }
  return false;
};

export const CURRENCY_FORMAT = (amount = null) => {
  if (Boolean(amount)) {
    return parseFloat(amount).toFixed(2);
  } else {
    let amount = 0;
    return amount.toFixed(2);
  }
};

export const GET_TODAY_DATE_WITH_ISO_FORMAT = () => {
  let dateobj = new Date();
  if (dateobj.getHours() < 12) {
    dateobj = dateobj.setHours(12);
    dateobj = new Date(dateobj).setMinutes(0);
    dateobj = new Date(dateobj).setMilliseconds(0);
    dateobj = new Date(new Date(dateobj).setSeconds(0));
  }
  let dateISO = dateobj.toISOString();
  return dateISO;
};

export const GET_TODAY_DATE_WITH_DEFAULT_TIME_WITH_ISO_FORMAT = () => {
  let dateobj = new Date();

  dateobj = dateobj.setHours(10);
  dateobj = new Date(dateobj).setMinutes(0);
  dateobj = new Date(dateobj).setMilliseconds(0);
  dateobj = new Date(new Date(dateobj).setSeconds(0));

  let dateISO = dateobj.toISOString();
  return dateISO;
};

export const GET_TOMORROW_DATE = () => {
  const today = new Date();
  // to return the date number(1-31) for the specified date
  let tomorrow = new Date();
  tomorrow = tomorrow.setDate(today.getDate() + 1);
  tomorrow = new Date(tomorrow).setHours(11);
  tomorrow = new Date(tomorrow).setMinutes(0);
  tomorrow = new Date(tomorrow).setSeconds(0);
  tomorrow = new Date(tomorrow).setMilliseconds(0);
  return tomorrow;
};

export const GET_ONE_YEAR_LATER_DATE_WITH_ISO_FORMAT = () => {
  let dateObj = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  let dateISO = dateObj.toISOString();
  return dateISO;
};

export const GET_THREE_MONTHS_EARLIER_DATE_WITH_ISO_FORMAT = () => {
  let dateObj = new Date();
  dateObj.setMonth(dateObj.getMonth() - 3);
  let dateISO = dateObj.toISOString();
  return dateISO;
};

export const GET_ISO_FORMAT = (value = null, search = null) => {
  let dateObj = Boolean(value) ? new Date(value) : new Date();
  let dateISO = dateObj.toISOString();
  return dateISO;
};

export const GET_CHANNEL_MANAGER_DATE_FORMAT = (value) => {
  let d = Boolean(value) ? new Date(value) : new Date();
  let date = d.getDate();
  if (date < 10) {
    date = "0" + date;
  }
  let month = d.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  let year = d.getFullYear();
  return `${year}-${month}-${date}`;
};

export const CLIENT_DATE_FORMAT = (value) => {
  let d = Boolean(value) ? new Date(value) : new Date();
  let date = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  return `${date}-${month}-${year}`;
};

export const INVOICE_TIME_DATE = (value = null) => {
  let date = Boolean(value) ? new Date(value) : new Date();
  return `${BOOKING_DETAILS_TIME_FORMET(date)} ${DATE_PICKER_FORMAT(date)}`;
};

export const INVOICE_TODAYS_DATE = (value = null) => {
  let date = new Date();
  return `${BOOKING_DETAILS_TIME_FORMET(date)} ${DATE_PICKER_FORMAT(date)}`;
};

export const GET_DAY = (value) => {
  // 15
  const d = Boolean(value) ? new Date(value) : new Date();
  let date = d.getDate();
  return date;
};

export const GET_MONTH = (value) => {
  // Sep
  const d = Boolean(value) ? new Date(value) : new Date();
  let month = months[d.getMonth()];
  return month;
};

export const GET_YEAR = (value) => {
  // 23
  const d = Boolean(value) ? new Date(value) : new Date();
  let year = d.getFullYear().toString().substr(2, 2);
  return year;
};

export const GET_YEAR_Full = (value) => {
  // 23
  const d = Boolean(value) ? new Date(value) : new Date();
  let year = d.getFullYear();
  return year;
};

export const TAX_PERCENTAGE = [0, 5, 12, 18, 28];
export const UPLOADED_IMAGE_SIZE = 10000000; // 10 MB
export const UPLOADED_LOGO_SIZE = 500000; // 500kB
export const SHOW_ALERT_TIME = 3000; // 3 second

export const CALCULATE_GST_FOR_ROOMS_MODIFIED = (
  selectedRoomWTypeAndNum,
  roomTypes,
  taxResponse
) => {
  const roomTypeArray = [];
  let taxRoomTypeWise = {};

  // Use forEach instead of map since the return value is not used.
  Object.keys(selectedRoomWTypeAndNum).forEach((Obj) =>
    roomTypeArray.push(Obj)
  );

  let totalGSTPercentage = 0;
  const roomTypeLength = Object.keys(selectedRoomWTypeAndNum)?.length;

  roomTypes?.forEach((roomType) => {
    if (!roomTypeArray?.includes(roomType?.name)) return;

    const currentTaxIdsArray = Array.isArray(roomType?.taxIds)
      ? roomType.taxIds
      : [];

    const currentTaxObjectArray = taxResponse?.filter((obj) =>
      currentTaxIdsArray.includes(obj?.id ?? obj?.taxId)
    );

    const currentTax = currentTaxObjectArray
      ?.map((obj) => {
        const taxPercentage = +obj?.taxPercentage;
        totalGSTPercentage += taxPercentage;
        return taxPercentage;
      })
      ?.filter((percentage) => Boolean(percentage)) // Filter out invalid tax percentages
      ?.reduce((total, percentage) => total + percentage, 0);

    if (roomType?.name) {
      taxRoomTypeWise[roomType.name] = currentTax;
    }
  });

  return {
    taxRoomTypeWise: taxRoomTypeWise,
    percent: roomTypeLength > 0 ? totalGSTPercentage / roomTypeLength : 0,
  };
};

export const defaultFallBackGSTForRoom = 12;

export const RESTAURANT_CGST = "2.5";
export const RESTAURANT_SGST = "2.5";

export const CALCULATE_GST_FOR_RESTAURANT_POS = (totalPrice, isGstInclude) => {
  let sgst = Boolean(isGstInclude) ? totalPrice * (+RESTAURANT_CGST / 100) : 0;
  let cgst = Boolean(isGstInclude) ? totalPrice * (+RESTAURANT_SGST / 100) : 0;
  return { sgst, cgst };
};

export const DEBBOUNCING_TIME = 300;

export const DAYS_BETWEEN_TWO_DATES = (day1, day2) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = day1;
  const secondDate = day2;
  const diffDays = Math.ceil(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays;
};

export const ENCODE_SLUG = (posName) => {
  if (posName) {
    return posName
      .toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  } else {
    return null;
  }
};

export const DECODE_SLUG = (posName) => {
  if (posName) {
    return posName.toLowerCase().replace("-", " ");
  }
  return null;
};

export const GET_CAPITALIZE_DATA = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const GET_UNIQUE_VALUE_FROM_ARRAY = (arr, val) => {
  return arr?.filter((item) => item !== val);
};

export const CLIENT_TIME_ZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const cleanAndCapitalize = (inputString) => {
  let cleanedString = inputString
    .replace(/^0+/, "") //  Remove leading zeros
    .replace(/[^\w\s]/gi, ""); //,commas, dots, special characters, and numbers

  // Capitalize the first letter
  cleanedString =
    cleanedString.charAt(0).toUpperCase() + cleanedString.slice(1);

  return cleanedString;
};

export const SCREEN_TYPE = ["mobile", "tablet", "desktop"];
// do not change the order, it will create problem in all the screens

export const activeSideBarName = (inputTag) => {
  if (
    inputTag === "bookings" ||
    inputTag === "walkin" ||
    inputTag === "bookingReservation"
  )
    return "Bookings";
  if (
    inputTag === "all-orders" ||
    inputTag === "table-view" ||
    inputTag === "new-order" ||
    inputTag === "close-order" ||
    inputTag === "kitchen-display-system"
  )
    return "POS";
  if (inputTag === "channel-manager") return "Channel Manager";
  if (inputTag === "clients") return "Clients";
  if (inputTag === "house-keeping") return "Housekeeping";
  if (inputTag === "reports") return "Reports";
  if (inputTag === "party") return "Party";
  if (inputTag === "expense") return "Expense";
  if (inputTag === "invoice") return "Invoice";
  if (inputTag === "settings") return "Settings";
  if (inputTag === "bank") return "Bank";
  if (inputTag === "payments") return "Payments";
  if (inputTag === "menu") return "Menu";
  if (inputTag === "manageStocks") return "Manage Stocks";
  else return "Dashboard";
};

export const activeFooterName = (inputTag) => {
  if (inputTag === "bookings") return "Bookings";
  if (inputTag === "all-orders") return "POS";
  if (inputTag === "table-view") return "-";
  if (inputTag === "table-view") return "-";
  if (inputTag === "new-order") return "-";

  if (inputTag === "channel-manager") return "Channel Manager";
  if (inputTag === "clients") return "Clients";
  if (inputTag === "house-keeping") return "Housekeeping";
  if (inputTag === "reports") return "Reports";
  if (inputTag === "party") return "Party";
  if (inputTag === "expense") return "Expense";
  if (inputTag === "invoice") return "Invoice";
  if (inputTag === "settings") return "Settings";
  if (inputTag === "bank") return "Bank";
  if (inputTag === "payments") return "Payment";
  if (inputTag === "menu") return "Menu";
  else return "Dashboard";
};

export const isSerachBarAlowedForThisPage = (title) => {
  if (
    title === "Bookings" ||
    title === "Clients" ||
    title === "Party" ||
    title === "Expense" ||
    title === "Invoice"
  )
    return true;
  else return false;
};

export const getInitials = (name) => {
  const parts = name?.split(" ");
  let initials = "";

  if (Array.isArray(parts)) {
    if (parts?.length === 1) {
      // If only one part, take the first character
      initials = parts?.[0].charAt?.(0);
    } else if (parts?.length > 1) {
      // If multiple parts, take the first character of the first part
      // and the first character of the last part
      initials = Boolean(parts?.[1]?.charAt?.(0))
        ? parts?.[0].charAt?.(0) + parts?.[1]?.charAt?.(0)
        : parts?.[0].charAt?.(0);
    }

    return Boolean(initials) ? initials?.toUpperCase() : ""; // Convert to uppercase if needed
  } else return "";
};

export const formatHugeNumber = (number) => {
  // To convert number from let say 120000 -> 1.2L and so on

  if (number >= 10000000) return (number / 10000000).toFixed(0) + " Cr+";
  else if (number >= 100000) return (number / 100000).toFixed(0) + " Lakh+";
  else if (number >= 1000) return (number / 1000).toFixed(0) + " K+";
  else return number;
};

export const customerDataFormat = (data) => {
  let customerData = data?.customer;
  let bookingArray = data?.bookings;

  let customerName = customerData?.name;
  let totalPayment = formatHugeNumber(customerData?.totalSpent);
  let lastVisit = bookingArray?.[bookingArray?.length - 1]?.createdAt;
  let customerSince = customerData?.createdAt;

  const duration = moment.duration(moment().diff(moment(customerSince)));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  let formattedDate = Boolean(customerSince)
    ? years === 0 && months === 0
      ? `${days} days`
      : years === 0 && months !== 0
      ? `${months} ${months === 1 ? "Month" : "Months"} `
      : `${years} ${years === 1 ? "Year" : "Years"} and ${months} ${
          months === 1 ? "Month" : "Months"
        }`
    : "";

  return {
    customerName: customerName,
    formattedDate: formattedDate,
    totalPayment: totalPayment,
    lastVisit: lastVisit,
  };
};

export const currenciesInitials = [
  "INR",
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "AUD",
  "CAD",
  "CHF",
  "CNY",
  "SEK",
  "NZD",
  "MXN",
  "SGD",
  "HKD",
  "NOK",
  "KRW",
  "TRY",
  "RUB",
  "INR",
  "BRL",
  "ZAR",
  "DKK",
  "PLN",
  "THB",
  "IDR",
  "HUF",
  "CZK",
  "ILS",
  "CLP",
  "PHP",
  "AED",
  "COP",
  "SAR",
  "MYR",
  "RON",
  "NGN",
  "ARS",
  "TWD",
  "KWD",
  "EGP",
  "QAR",
  "PKR",
  "VND",
  "UAH",
  "BDT",
  "IRR",
  "DZD",
  "KES",
  "MAD",
  "IQD",
  "OMR",
  "NPR",
  "TZS",
  "TND",
  "UGX",
  "GHS",
  "LKR",
  "MMK",
  "BHD",
  "JOD",
  "LBP",
  "YER",
  "KHR",
  "GTQ",
  "ETB",
  "NAD",
  "LYD",
  "AMD",
  "BGN",
  "XAF",
  "MOP",
  "TTD",
  "ZMW",
  "XOF",
  "MUR",
  "XCD",
  "GEL",
  "ALL",
  "XPF",
  "FJD",
  "MGA",
  "ISK",
  "SZL",
  "LRD",
  "BMD",
  "HTG",
  "MKD",
  "DJF",
  "SBD",
  "LSL",
  "SCR",
  "SRD",
  "BSD",
  "ANG",
  "TMT",
  "AZN",
  "CVE",
  "SVC",
  "AFN",
  "KPW",
  "MZN",
  "WST",
  "TJS",
  "KGS",
  "XDR",
  "TVD",
];

export const getNumericalValue = (initialValue) => {
  const numericValue = initialValue?.replace(/[^0-9]/g, ""); // Allow only numeric values
  const trimmedValue = numericValue?.replace(/^0+/, ""); // Remove leading zeros

  return trimmedValue;
};
