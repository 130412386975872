import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { useEffect } from "react";

const Index = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/dashboard");
  }, []);

  return (
    <div className="bg-primary h-screen flex justify-center items-center">
      <div className="text-center ">
        <h1 className="text-3xl text-yellow-400 font-bold">Opps</h1>
        <h2 className="text-white mt-4 text-2xl font-bold">
          404 - PAGE NOT FOUND
        </h2>
        <p className=" w-[80%] m-auto mt-3 text-white">
          {" "}
          The page you are looking for might have been removed had its name
          changed ot is temporarily unavailable.
        </p>

        <button
          onClick={() => navigate("/dashboard")}
          className="bg-yellow-400 hover:bg-white hover:text-yellow-400 text-white font-bold py-2 px-4 rounded mt-4"
        >
          <BsArrowLeftCircleFill className="mr-1 inline-block" />

          <span> Go back</span>
        </button>
      </div>
    </div>
  );
};
export default Index;
