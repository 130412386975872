import React from "react";
import { LeftArrow } from "../../../Icon1";
import { useNavigate } from "react-router-dom";

function ManageStockHeader({ headerTitle }) {
  const navigate = useNavigate();

  const handleGoBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="w-full flex items-center font-semibold p-6 gap-2">
      <div className="cursor-pointer" onClick={handleGoBackClick}>
        <LeftArrow />
      </div>
      <div className="text-[32px] text-[#545454]">{headerTitle}</div>
    </div>
  );
}

export default ManageStockHeader;
