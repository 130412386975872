import React, { useState } from "react";
import ManageStocksWrapper from "../wrapper/Index";
import { Button, Col, List, Row } from "antd";
import RemoveAddButton from "../button/removeAdd";
import AddItem from "./addItem/Index";
import { MdCurrencyRupee } from "react-icons/md";

const suppliersData = [
  {
    addressOne: "Delhi",
    addressTwo: "Dehradun",
    city: "Varanasi",
    email: "Demo@user",
    hotelId: "1234",
    id: "1",
    name: "Arun Plastics",
    panNumber: "KNJPS6666K",
    phone: "9918646464",
    pinCode: "110017",
    primaryContact: "Arun Solanki",
    state: "Uttar Pradesh",
  },
  {
    addressOne: "Delhi",
    addressTwo: "Dehradun",
    city: "Varanasi",
    email: "Demo@user",
    hotelId: "1234",
    id: "2",
    name: "Tarun Plastics",
    panNumber: "KNJPS6666K",
    phone: "9918646464",
    pinCode: "110017",
    primaryContact: "Arun Solanki",
    state: "Uttar Pradesh",
  },
  {
    addressOne: "Delhi",
    addressTwo: "Dehradun",
    city: "Varanasi",
    email: "Demo@user",
    hotelId: "1234",
    id: "3",
    name: "Varun Plastics",
    panNumber: "KNJPS6666K",
    phone: "9918646464",
    pinCode: "110017",
    primaryContact: "Arun Solanki",
    state: "Uttar Pradesh",
  },
  {
    addressOne: "Delhi",
    addressTwo: "Dehradun",
    city: "Varanasi",
    email: "Demo@user",
    hotelId: "1234",
    id: "4",
    name: "Narun Plastics",
    panNumber: "KNJPS6666K",
    phone: "9918646464",
    pinCode: "110017",
    primaryContact: "Arun Solanki",
    state: "Uttar Pradesh",
  },
];
const Tea = [
  {
    id: 1,
    name: "Tea Bags",
    count: 20,
    price: 30,
    sku: 400,
    category: "Beverages",
    unit: "",
  },
  {
    id: 2,
    name: "Chamomile Tea Bags",
    count: 10,
    price: 30,
    sku: 400,
    category: "Beverages",
    unit: "",
  },
  {
    id: 3,
    name: "Green Tea Bags",
    count: 90,
    price: 30,
    sku: 400,
    category: "Beverages",
    unit: "",
  },
];

function MainContainer({ setAddNewPreview }) {
  const handleAddNewItem = () => {
    setAddNewPreview((prev) => !prev);
  };

  return (
    <div className="px-6 flex flex-col justify-between gap-6 ">
      <div className="border rounded overflow-hidden">
        <div className="border-b-[1px]">
          <div className="text-[14px] text-[#4F4F4F] bg-[#F4F4F4] p-2 font-nunito">
            Mohan
          </div>
          <div className="flex justify-between text-[12px] bg-[#FAFAFA] items-center py-2 ">
            <span className="font-nunito pl-2">Saved Suppliers</span>
            <Button className="text-[12px]" type="link">
              Add New
            </Button>
          </div>
        </div>
        <div className="bg-[#FAFAFA] p-2">
          <List
            dataSource={suppliersData}
            renderItem={(supplier, index) => (
              <List.Item className=" text-[12px]">
                <div className="font-nunito">{supplier.name}</div>
                <div className="font-nunito">{supplier.phone}</div>
              </List.Item>
            )}
          />
        </div>
      </div>
      <div className="border rounded overflow-hidden">
        <div className="border-b-0">
          <div className="text-[14px] text-[#4F4F4F] bg-[#FFFFFF] p-2 font-nunito">
            Tea
          </div>
          <div className="flex justify-between text-[12px] bg-[#FAFAFA] items-center py-2 border-b">
            <span className="font-nunito pl-2">Saved Items</span>
            <Button
              onClick={handleAddNewItem}
              className="text-[12px]"
              type="link"
            >
              Add New
            </Button>
          </div>
          <div className="bg-[#FAFAFA] px-2">
            <List
              dataSource={Tea}
              renderItem={(teaObj) => (
                <List.Item className="text-[12px]">
                  <div className="font-nunito">{teaObj.name}</div>
                  <div className="font-nunito">
                    <RemoveAddButton id={teaObj.id} count={teaObj.count} />
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function SideContainer() {
  return (
    <div className="flex flex-col h-full justify-between pt-6">
      <div className="flex flex-col gap-2 ">
        {Tea.map((item) => (
          <Row className="w-full bg-[#ffffff] items-center p-1">
            <Col className="w-full font-nunito text-[12px] pl-1" span={8}>
              {item.name}
            </Col>
            <Col className="font-nunito text-[12px]" span={8}>
              <div className="w-full flex justify-end">
                <RemoveAddButton id={item.id} count={item.count} />
              </div>
            </Col>
            <Col className="font-nunito text-[12px]" span={8}>
              <div className="w-full flex items-center justify-end pr-2">
                <MdCurrencyRupee />
                {item.price}
              </div>
            </Col>
          </Row>
        ))}
      </div>
      <div className="w-full flex justify-around">
        <Button className="w-[153px] h-[52px] bg-[#FFFFFF] text-[18px] font-semibold text-[#007FFF] ">
          Save & New
        </Button>
        <Button className="w-[153px] h-[52px] bg-[#007FFF] text-[18px] font-semibold text-[#ffffff] ">
          Review
        </Button>
      </div>
    </div>
  );
}

function RestockItems() {
  const [addNewPreview, setAddNewPreview] = useState(false);
  return (
    <ManageStocksWrapper
      headerName="Restock items"
      leftSideContent={<MainContainer setAddNewPreview={setAddNewPreview} />}
      rightSideContent={!addNewPreview ? <SideContainer /> : <AddItem />}
      previewTitle={addNewPreview ? "" : "Overview"}
    ></ManageStocksWrapper>
  );
}

export default RestockItems;
