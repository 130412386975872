import moment from "moment";

function CalendarCell({
  current,
  data,
  currentMonth,
  currentSelectedDate,
  cellData,
}) {
  const outsideCellMonth =
    current["$M"] !== currentMonth ? "outside-month-cell" : "";

  const touchedDateCell =
    moment(current["$d"]).format("YYYY-MM-DD") === currentSelectedDate
      ? "touched-date-cell"
      : "";

  return (
    <div
      className={`h-[100%] w-full border flex flex-col justify-center gap-2 ${outsideCellMonth} ${touchedDateCell}`}
    >
      <div
        className={`flex justify-center items-center text-[20px] text-[#000000B2] font-semibold ${outsideCellMonth}`}
      >
        {current["$D"]}
      </div>
      <div
        className={`flex justify-center items-center text-[16px] text-[#00000080] font-light ${outsideCellMonth}`}
      >
        Rs {isNaN(data?.rate) ? "NA" : data?.rate}
      </div>
      <div
        className={`flex justify-between px-2 text-[14px] text-[#00000040] ${outsideCellMonth}`}
      >
        <span>B: {isNaN(data?.bookings) ? "NA" : data?.bookings}</span>
        <span>A: {isNaN(data?.availability) ? "NA" : data?.availability}</span>
      </div>
    </div>
  );
}
export default CalendarCell;
