import React, { useEffect, useState } from "react";

import hotelIcon from "../../../../assets/icons/hotel.svg";
import emailIcon from "../../../../assets/icons/email.svg";
import phoneIcon from "../../../../assets/icons/phone.svg";
import cityIcon from "../../../../assets/icons/city.svg";
import addressIcon from "../../../../assets/icons/address.svg";
import gstinIocn from "../../../../assets/icons/gstin.svg";
import { TiTimes } from "react-icons/ti";
import rect from "../../../../assets/images/svgs/rect.svg";

import { isFileSizeValid } from "../utils";
import { ImageSizeValidation } from "../constants";
import { uploadFiles } from "../../../../services/scanUpload";
import { createhotel, updatehotel } from "../../../../services/hotel";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateHotel } from "../../../../reducer/loginReducer";
import UploadFile from "../../upload";
import NavigatingButtons from "../navigatingButtons";
import { CircularProgress } from "@mui/material";

const PrimaryInfo = ({ incrementId, decrementId }) => {
  const { hotelDetails } = useSelector((store) => store.login);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [preview, setPreview] = React.useState(rect);
  const [imageLoc, setImageLoc] = React.useState(null);

  const [formData, setFormData] = useState([
    {
      id: 1,
      icon: hotelIcon,
      label: "Hotel Name",
      name: "hotel_name",
      value: "",
    },
    {
      id: 2,
      icon: emailIcon,
      label: "Email Address",
      name: "email",
      value: "",
    },
    {
      id: 3,
      icon: phoneIcon,
      label: "Contact Number",
      name: "contact",
      value: "",
    },
    {
      id: 4,
      icon: cityIcon,
      label: "City",
      name: "city",
      value: "",
    },
    {
      id: 5,
      icon: addressIcon,
      label: "Address",
      name: "address",
      value: "",
    },
    {
      id: 6,
      icon: gstinIocn,
      label: "GSTIN",
      name: "gst",
      value: "",
    },
  ]);

  const handleImageBeforeUpload = async (file) => {
    if (!isFileSizeValid(file, 10, ImageSizeValidation.lessThan10mb)) {
      message.error("File size must be lesser than 500 kb");
      setPreview(rect);
      setImageLoc(null);
      return false;
    } else return true;
  };

  const handleUpload = async (item) => {
    const file = item.file;
    const data = new FormData();
    data.append("file", file);

    let uploadedFile = await uploadFiles(data);

    if (uploadedFile !== null) {
      setPreview(uploadedFile);
      setImageLoc(uploadedFile);
    }
  };

  const addHotel = async (e) => {
    e.preventDefault();

    if (!isValid()) return;
    setLoading(true);

    const data = {
      name: formData[0].value,
      emailAddress: formData[1].value,
      contactNumber: formData[2].value,
      city: formData[3].value,
      address: formData[4].value,
      gst: formData[5].value,
      logo: imageLoc,
      onBoardingStep: 1,
    };

    if (Boolean(hotelDetails?.id)) {
      const payload = {
        hotelId: hotelDetails.id,
        body: {
          ...hotelDetails,
          ...data,
          hotelNo: hotelDetails.hotelNo,
          gstIN: formData[5].value,
        },
      };
      let response = await updatehotel(payload);

      if (Boolean(response)) {
        dispatch(updateHotel(response.data));
        incrementId();
      } else message.error("Something went wrong");
    } else {
      let response = await createhotel(data);

      if (Boolean(response)) {
        dispatch(updateHotel(response));
        incrementId();
      } else {
        message.error("Something went wrong");
      }
    }
    setLoading(false);
  };

  const isValid = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(formData[1]?.value)) return true;
    else {
      message.error("Invalid email Id");
      return false;
    }
  };

  useEffect(() => {
    setFormData([
      {
        id: 1,
        icon: hotelIcon,
        label: "Hotel Name",
        name: "hotel_name",
        value: hotelDetails?.name ?? "",
      },
      {
        id: 2,
        icon: emailIcon,
        label: "Email Address",
        name: "email",
        value: hotelDetails?.emailAddress ?? "",
      },
      {
        id: 3,
        icon: phoneIcon,
        label: "Contact Number",
        name: "contact",
        value: hotelDetails?.contactNumber ?? "",
      },
      {
        id: 4,
        icon: cityIcon,
        label: "City",
        name: "city",
        value: hotelDetails?.city ?? "",
      },
      {
        id: 5,
        icon: addressIcon,
        label: "Address",
        name: "address",
        value: hotelDetails?.address ?? "",
      },
      {
        id: 6,
        icon: gstinIocn,
        label: "GSTIN",
        name: "gstin",
        value: hotelDetails?.gstIN ?? "",
      },
    ]);
    if (Boolean(hotelDetails) && Boolean(hotelDetails?.logo)) {
      setImageLoc(hotelDetails?.logo);
      setPreview(hotelDetails?.logo);
    }
  }, [hotelDetails]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-5xl font-semibold uppercase text-[#4f4f4f]">
          Let’s Get started
        </h2>
      </div>

      <div className="grid md:grid-cols-3 grid-cols-1 mt-[40px] gap-40">
        <div className="col-span-2">
          <p className="text-2xl text-[#323F4B]">
            Enter Hotel details to start
          </p>

          <div className="mt-[56px]">
            <form onSubmit={addHotel}>
              {formData.map((item) => (
                <div
                  className="item mb-[20px] flex justify-between items-start"
                  key={item.name}
                >
                  <div className="flex w-1/3 justify-start items-center">
                    <img src={item.icon} alt="icon" className="mr-[13px]" />
                    <p className="text-lg text-[#4F4F4F] mr-[56px]">
                      {item.label}
                    </p>
                  </div>
                  <input
                    name={item.name}
                    type="text"
                    className="h-[55px] w-2/3 bg-[#FAFAFA] border border-[34F4F4F] rounded-[4px] p-2"
                    value={item?.value ?? ""}
                    required={item.name === "hotel_name"}
                    onChange={(e) => {
                      const updatedFormData = [...formData];
                      const index = updatedFormData.findIndex(
                        (formDataItem) => formDataItem.id === item.id
                      );
                      if (item.name === "contact") {
                        const phoneNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        const updatedPhoneNumber = phoneNumber.slice(0, 10); // Get first 10 digits

                        updatedFormData[index].value = updatedPhoneNumber;
                      } else updatedFormData[index].value = e.target.value;

                      setFormData(updatedFormData);
                    }}
                  />
                </div>
              ))}

              {loading ? (
                <div
                  className="w-[100%] bg-[#FFF] h-[7rem] fixed flex items-center  justify-end left-0 bottom-0 
                right-0 py-5  shadow-custom"
                >
                  <div className="flex gap-8 mr-[4%]">
                    <button
                      type="submit"
                      className="bg-[#007FFF] w-[15rem] h-[3.5rem] flex justify-center items-center  shadow-custom rounded-[0.5rem] font-[600] font-[Poppins] text-[1.125rem] uppercase text-[#FFF] cursor-pointer"
                      disabled={true}
                    >
                      <CircularProgress size={16} color={"inherit"} />
                    </button>
                  </div>
                </div>
              ) : (
                <NavigatingButtons
                  currentId={1}
                  decrementId={decrementId}
                ></NavigatingButtons>
              )}
            </form>
          </div>
        </div>

        <div className="col-span-1">
          <p className="text-lg uppercase text-black">Upload Company Logo</p>
          <p className="text-sm text-[#4f4f4f] mt-[13px]">
            Upto 10 mb, jpeg, png only
          </p>

          <div className="relative">
            <img
              key={preview}
              src={preview}
              alt="rect"
              id="viewer"
              className="my-[20px] w-[231px] h-[213px] object-cover"
            />
            {imageLoc && (
              <TiTimes
                className="absolute top-0 left-0"
                onClick={() => {
                  setPreview(rect);
                  setImageLoc(null);
                }}
              ></TiTimes>
            )}
          </div>

          <UploadFile
            handleImageBeforeUpload={handleImageBeforeUpload}
            handleUpload={handleUpload}
          ></UploadFile>
        </div>
      </div>
    </div>
  );
};

export default PrimaryInfo;
