import { Button } from "antd";
import React from "react";

function RemoveAddButton({
  count,
  handleCountDecrement,
  handleCountIncrement,
  id,
}) {
  return (
    <div className="w-[75px] h-[18px] flex justify-between items-center pr-2">
      <Button
        size="small"
        shape="circle"
        onClick={(e) => handleCountDecrement(e, id)}
        className="bg-[#f0f0f0]"
      >
        -
      </Button>
      <span className="flex items-center justify-center min-w-[30px]">
        {count}
      </span>
      <Button
        size="small"
        shape="circle"
        onClick={(e) => handleCountIncrement(e, id)}
        className="bg-[#2f80ed] text-[#ffffff]"
      >
        +
      </Button>
    </div>
  );
}

export default RemoveAddButton;
