import { useEffect } from "react";
import { useSelector } from "react-redux";

const useScriptLoader = () => {
  const { isChatActive } = useSelector((store) => store.app);

  useEffect(() => {
    // Function to create the first script

    const scriptFrame = document.getElementById("launcher-frame");
    const frameRoot = document.getElementById("freshworks-frame-wrapper");

    if (isChatActive) {
      if (Boolean(scriptFrame)) {
        scriptFrame.style.display = "block";
        if (Boolean(frameRoot)) {
          frameRoot.style.display = "block";
        }
      } else {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = `
            window.fwSettings = {
              widget_id: 1070000001593,
            };
            !(function () {
              if ("function" != typeof window.FreshworksWidget) {
                var n = function () {
                  n.q.push(arguments);
                };
                (n.q = []), (window.FreshworksWidget = n);
              }
            })();
          `;

        document.body.append(script);

        // Function to create the second script
        const addSecondScript = () => {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src =
            "https://ind-widget.freshworks.com/widgets/1070000001593.js";
          script.async = true;
          script.defer = true;
          document.body.appendChild(script);
        };

        // Add  scripts
        // addFirstScript();
        addSecondScript();
      }
    } else {
      if (Boolean(scriptFrame)) {
        scriptFrame.style.display = "none";

        if (Boolean(frameRoot)) {
          frameRoot.style.display = "none";
        }
      }
    }
  }, [isChatActive]);

  return null;
};

export default useScriptLoader;
