import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { useState, useEffect } from "react";

import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import { IoCloseSharp } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";
import { updateDisplayTakePaymentDrawer } from "../../../../reducer/posReducer/restaurant";
import CustomTextField from "../../../../components/common/InputField/Input";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";
import { Button } from "antd";
import { getNumericalValue } from "../../../../utils/helper";

export default function TakePaymentDrawer({ total, invoice, hotelId }) {
  const dispatch = useDispatch();

  const { data: orders } = useSelector((store) => store.order);

  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [transactionId, setTransactionId] = useState("");
  const [paymentModePartyId, setPaymentModePartyId] = useState("");
  const [methodToPartyId, setMethodToPartyId] = useState({});
  const [loading, setLoading] = useState(false);

  const { displayTakePaymentDrawer } = useSelector((store) => store.pos);
  const { paymentModesResponse, allPayments } = useSelector(
    (store) => store.payments
  );

  useEffect(() => {
    if (Array.isArray(paymentModesResponse) && paymentModesResponse?.length) {
      let updatedObj = {};
      paymentModesResponse?.forEach((mode) => {
        const { method, partyId } = mode;
        updatedObj[method] = partyId;
      });
      setMethodToPartyId(updatedObj);
      setPaymentModePartyId(updatedObj["cash"]);
    }
  }, [paymentModesResponse]);

  const handleAmountChange = (value) => {
    let trimmedValue = getNumericalValue(value);
    setAmount(trimmedValue);
  };

  const handleModeOfPayment = (value) => {
    setPaymentMethod(value);
    setPaymentModePartyId(methodToPartyId[value]);
  };

  const handleTransactionCashier = (value) => {
    setTransactionId(value);
  };

  const { handleTakePayment } = useAllPosFunctions(setLoading);

  const handlePayment = () => {
    handleTakePayment(
      amount,
      hotelId,
      invoice,
      paymentMethod,
      paymentModePartyId,
      transactionId,
      orders,
      allPayments
    );
  };

  useEffect(() => {
    setAmount(total);
  }, [displayTakePaymentDrawer]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "100%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",

          "& .MuiDrawer-root": {
            position: "absolute",
            marginleft: "auto",
            right: 0,
          },
          "& .MuiPaper-root": {
            position: "absolute",
            marginLeft: "auto",
          },
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={displayTakePaymentDrawer}
      CloseIcon={null}
      onClose={() => dispatch(updateDisplayTakePaymentDrawer(false))}
    >
      <div className="w-[100%] flex flex-col justify-center items-center bg-[#F2F4FC] ">
        <header className="flex  py-4 px-5 w-[100%] bg-[#FFF] justify-between items-center ">
          <h2 className="font-outfit font-[600] text-[1rem] text-[#000000]">
            Take Payment
          </h2>

          <div className="flex gap-4 items-center">
            <div
              className="bg-[#4D4D4D] w-[16px] h-[16px] rounded-[50%] flex justify-center items-center "
              onClick={() => dispatch(updateDisplayTakePaymentDrawer(false))}
            >
              <IoCloseSharp className="text-[10px] text-[#FFF] " />
            </div>
          </div>
        </header>

        <main className=" w-[90%] mt-[2rem] mb-[7rem] bg-[#FFF] p-2 py-4 rounded-[12px]">
          <div className="mx-5  relative flex flex-col gap-5 justify-between">
            <CustomTextField
              placeholder="Payment"
              label=""
              value={Boolean(amount) ? Math.ceil(amount) : ""}
              onChange={(value) => handleAmountChange(value)}
              type="text"
              inputPadding={true}
              labelTextColor={"#8F9BB3"}
            />

            <FormControl
              sx={{
                width: "100%",

                color: "#0000004D",
                "& .MuiSelect-icon": {
                  color: "#2F80ED",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
              size="small"
            >
              <Select
                labelId="ModeLabel"
                id="mode"
                // label="Mode"
                sx={{
                  width: "100%",

                  color: "#808080",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                }}
                onChange={(e) => handleModeOfPayment(e.target.value)}
                value={paymentMethod}
              >
                {paymentModesResponse?.map((mode) => {
                  const { method, partyId, paymentMode } = mode;
                  return (
                    <MenuItem
                      value={method}
                      sx={{
                        fontSize: "14px",
                        color: "#00000098",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                      }}
                    >
                      {paymentMode}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <CustomTextField
              placeholder={
                paymentMethod === "cash" ? "Cashier" : "Transaction No"
              }
              onChange={(value) => handleTransactionCashier(value)}
              value={Boolean(transactionId) ? transactionId : ""}
              type="text"
              inputPadding={true}
              labelTextColor={"#8F9BB3"}
            />
          </div>
        </main>

        <footer className="bg-[#FFF] shadow-lg w-[100%] fixed bottom-0 py-4 flex justify-center shadow-mobile-footer items-center">
          <Button
            className="bg-[#007FFF] text-[#FFF] w-[80%]  text-[16px] leading-[24px] font-outfit  
          h-[3rem] font-[500] flex justify-center items-center rounded-[8px]"
            onClick={() => !loading && handlePayment()}
          >
            {loading ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              " Apply"
            )}
          </Button>
        </footer>
      </div>
    </Drawer>
  );
}
