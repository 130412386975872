import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { verifyOTP } from "../services/login";
import { message } from "antd";
import {
  hotelContentDetails,
  hotelDetails,
  postHotelContent,
  updateHtlContent,
  updatehotel,
  postResetDynamicPricingService,
  postOccupancyRate,
} from "../services/hotel";
import { updateFirstLoading } from "./appReducer";

export const resetDynamicPricing = createAsyncThunk(
  "resetDynamicPricing",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "/hotels";
      const response = await postResetDynamicPricingService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const updateHotelDetailsAsync = createAsyncThunk(
  "login/updateHotelDetails",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await updatehotel(payload);

      let currentHotel = response?.data;
      let allHotel = getState((store) => store?.login)?.login?.allHotelDetails;
      let modifiedHotel = allHotel?.map((Obj) => {
        if (Obj?.id === currentHotel?.id) {
          return currentHotel;
        } else return Obj;
      });
      dispatch(updateAllHotelDetails(modifiedHotel));
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
        payload?.navigate("/login");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const updateHotelDetailsByRoomType = createAsyncThunk(
  "login/updateHotelDetailsByRoomType",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      return fulfillWithValue(payload);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
        payload?.navigate("/login");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const getHotelDetails = createAsyncThunk(
  "login/getHotelDetails",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await hotelDetails(null, "hotels");

      dispatch(updateFirstLoading());

      if (payload?.navigate && response?.data?.length > 0) {
        payload.navigate("/dashboard");
      }
      let storedHotelId = localStorage.getItem("hotelId");
      let currentHotel = {};
      if (Boolean(storedHotelId)) {
        currentHotel = response?.data?.filter(
          (Obj) => Obj?.id === storedHotelId
        )?.[0];
        if (currentHotel == {} || !Boolean(currentHotel)) {
          currentHotel = response?.data?.[0];
          localStorage.setItem("hotelId", response?.data?.[0]?.id);
        }
      } else {
        localStorage.setItem("hotelId", response?.data?.[0]?.id);
        currentHotel = response?.data?.[0];
      }

      dispatch(updateAllHotelDetails(response?.data));
      return fulfillWithValue(currentHotel);
    } catch (error) {
      // loading first time
      dispatch(updateFirstLoading());
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        localStorage.clear("hotelId");
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const getHotelContent = createAsyncThunk(
  "login/getHotelContent",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await hotelContentDetails(payload, "hotel-content");

      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const updateHotelContentDetails = createAsyncThunk(
  "login/updateHotelContentDetails",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await updateHtlContent(payload, "hotel-content");
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const postHotelContentDetails = createAsyncThunk(
  "login/postHotelContent",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await postHotelContent(payload, "hotel-content");
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const postOccupancyBasedPlanRate = createAsyncThunk(
  "login/postOccupancyBasedPlanRate",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await postOccupancyRate(
        payload,
        "hotels/occupancy-rate-plan"
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) localStorage.removeItem("token");

      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  hotelDetails: {},
  loading: false,
  apiError: false,
  changeMade: false,
  allHotelDetails: [],
  hotelContentDetails: {},
  bankDetails: {},
  countryCode: "",
  occupancyPlanRateLoading: false,
  occupancyPlanRate: {},
  occupancyPlanRateError: false,
  selectedHotels: [],
  isAllHotelsSelected: false,
  hotelsWithPermission: [],
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_29: () => initialState,

    updateHotelsWithPermission: (state, action) => {
      state.hotelsWithPermission = action.payload;
    },

    updateIsAllHotelsSelected: (state, action) => {
      state.isAllHotelsSelected = action.payload;
    },

    updateSelectedHotels: (state, action) => {
      state.selectedHotels = action.payload;
    },

    saveLoginData(state, action) {
      return action.payload;
    },
    updateHotelDetails(state, action) {
      state.hotelDetails =
        Boolean(action.payload?.hotels) && action.payload?.hotels.length > 0
          ? action.payload.hotels[0]
          : {};
      state.apiError = action.payload.apiError;
    },

    updateHotel: (state, action) => {
      state.hotelDetails = action.payload;
    },
    updateAllHotelDetails: (state, action) => {
      state.allHotelDetails = action?.payload;
    },
    updateBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateHotelDetailsAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateHotelDetailsAsync.fulfilled, (state, action) => {
      message.success("Data Successfully Updated");
      state.loading = false;
      state.hotelDetails = action.payload;
      state.error = null;
      state.changeMade = true;
    });

    builder.addCase(updateHotelDetailsByRoomType.fulfilled, (state, action) => {
      state.loading = false;
      state.hotelDetails = action.payload;
      state.error = null;
      state.changeMade = true;
    });

    builder.addCase(updateHotelDetailsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.hotelDetails = {};
      state.changeMade = false;
    });

    builder.addCase(getHotelDetails.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getHotelDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.hotelDetails = action.payload;
      state.error = null;
    });
    builder.addCase(getHotelDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.hotelDetails = {};
    });
    builder.addCase(getHotelContent.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getHotelContent.fulfilled, (state, action) => {
      state.loading = false;
      state.hotelContentDetails = action.payload;
      state.error = null;
    });
    builder.addCase(getHotelContent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.hotelContentDetails = {};
    });
    builder.addCase(updateHotelContentDetails.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateHotelContentDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.hotelContentDetails = action.payload;
      state.error = null;
    });
    builder.addCase(updateHotelContentDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(postHotelContentDetails.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postHotelContentDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.hotelContentDetails = action.payload;
      state.error = null;
    });
    builder.addCase(postHotelContentDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.hotelContentDetails = {};
    });
    builder.addCase(postOccupancyBasedPlanRate.pending, (state, action) => {
      state.occupancyPlanRateLoading = true;
      // state.occupancyPlanRate = action.payload;
      state.occupancyPlanRateError = null;
    });
    builder.addCase(postOccupancyBasedPlanRate.fulfilled, (state, action) => {
      state.occupancyPlanRateLoading = false;
      state.occupancyPlanRate = action.payload;
      state.occupancyPlanRateError = null;
    });
    builder.addCase(postOccupancyBasedPlanRate.rejected, (state, action) => {
      state.occupancyPlanRateLoading = false;
      state.occupancyPlanRate = {};
      state.occupancyPlanRateError = true;
    });
  },
});

export const verifyOtp = (data, navigator) => {
  return async (dispatch) => {
    const loginData = await verifyOTP(data, "login/verifyOTP"); // if login success

    // if login true
    if (Boolean(loginData)) {
      localStorage.setItem("token", loginData.token);
      localStorage.setItem("hotelId", loginData?.hotels?.[0]?.id);

      const { hotels } = loginData;

      if (Boolean(hotels) && hotels?.length > 0) {
        dispatch(saveLoginData(hotels?.[0]));

        // Storing all possible hotels for current user (Logged In Number)
        dispatch(updateAllHotelDetails(hotels));

        // Using Hotel at index 0 to do this
        dispatch(
          updateHotelDetails({
            hotels: hotels?.[0],
            apiError: false,
          })
        );
        navigator("/dashboard");
      } else navigator("/onBoarding");
    } else {
      // if login fails
      dispatch(
        updateHotelDetails({
          hotels: {},
          apiError: true,
        })
      );
    }
  };
};

export const {
  CLEAR_REDUX_STORE_29,
  saveLoginData,
  updateHotelDetails,
  updateHotel,
  updateAllHotelDetails,
  updateBankDetails,
  updateSelectedHotels,
  updateIsAllHotelsSelected,
  updateHotelsWithPermission,
} = loginSlice.actions;
export default loginSlice.reducer;
