import React, { useEffect, useState, useRef } from "react";
import { Drawer, Progress, message } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  updateDisplayIdDrawer,
  updateBookingDetails,
} from "../../../reducer/bookingReducer";
import { updateLoading } from "../../../reducer/uploadReducer";
import { CircularProgress } from "@mui/material";
import { TiTimes } from "react-icons/ti";
import { LazyLoadImage } from "react-lazy-load-image-component";
import UploadOtherId from "../components/UploadOtherId";

import { CloseOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import {
  bookingDetailsDrawerWidth,
  customCloseIconContainer,
} from "../../../utils/bookingDetailsHelper";

const IdDrawer = () => {
  const dispatch = useDispatch();

  const {
    showMainBookingDetailsDrawer,
    bookingDetails,
    displayIdDrawer,
    responseDetails,
    otherIDS,
  } = useSelector((store) => store.booking);

  const handleClose = () => dispatch(updateDisplayIdDrawer());

  const totalGuest =
    Number(bookingDetails?.adults) + Number(bookingDetails?.children);

  const guestIdLength =
    bookingDetails?.idImages?.length > totalGuest
      ? totalGuest
      : bookingDetails?.idImages?.length ?? 0;

  const [list, setList] = useState([]);
  const { loading } = useSelector((store) => store.upload);

  useEffect(() => {
    let newList =
      bookingDetails?.idImages &&
      bookingDetails.idImages.map((item) => item.url);

    if (Array.isArray(newList)) setList([...newList]);
    else setList([]);
  }, [bookingDetails]);

  const saveDetails = async () => {
    dispatch(updateLoading(true));
    const updatedList = list.map((item) => {
      return {
        type: "jpg",
        url: item,
      };
    });

    const payload = {
      ...responseDetails,
      ...bookingDetails,
      idImages: updatedList,
    };

    await dispatch(updateBookingDetails(payload));
    dispatch(updateLoading(false));
    message.success("Uploaded successfully");

    handleClose();
  };

  const handleUpload = (url, type) => {
    let newList = [...list];
    newList.push(url);

    setList(newList);
  };

  const handleRemoveUrl = (url, type) => {
    const updatedList = list.filter((item) => item !== url);
    setList(updatedList);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Drawer
      title={
        <div className="text-[1rem] flex  justify-between w-[full] font-nunito px-[-2]">
          <div>
            <div>
              <span className="text-[#808080] font-[600]">Booking Id:</span>{" "}
              <span className="text-[#4D4D4D] font-[500] text-xs  lg:text-lg">
                {showMainBookingDetailsDrawer.newBookingId}
              </span>
            </div>
            <div>
              <span className="text-[#808080] font-[600]">Source:</span>{" "}
              <span className="text-[#4D4D4D] font-[500]">
                {bookingDetails?.source}
              </span>
            </div>
          </div>
          <div className="flex gap-3 items-center pr-[2rem]">
            <div className="bg-[#F4F4F4] p-2 rounded-md text-[#4D4D4D] text-[0.75rem] font-[500] font-nunito">
              {bookingDetails?.adults ?? 0} Adults -{" "}
              {bookingDetails?.children ?? 0} Minors
            </div>

            <Progress
              type="circle"
              percent={(guestIdLength / totalGuest) * 100}
              format={(percent) => `${guestIdLength} /${totalGuest}`}
              size="small"
              strokeWidth={12}
              strokeColor={"#2F80ED"}
              className="text-[#D9D9D9] text-sm bg-transparent ml-2"
            />

            <UploadOtherId
              handleUpload={handleUpload}
              type="othersID"
              gallery={otherIDS}
              handleRemoveUrl={(url, type) => handleRemoveUrl(url, type)}
              position={true}
            />
          </div>
        </div>
      }
      closeIcon={<CloseOutlined style={customCloseIconContainer} />}
      closable={true}
      mask={true}
      onClose={() => handleClose()}
      open={displayIdDrawer}
      width={bookingDetailsDrawerWidth}
    >
      <div>
        <button
          onClick={handlePrint}
          className=" border-[1px] border-[#007FFF] rounded-[0.3125rem] flex justify-center items-center 
          px-[0.875rem] py-[0.4375rem] 
      text-[#007FFF] bg-[#FFF] cursor-pointer whitespace-nowrap mb-5"
        >
          Print Id
        </button>

        {list &&
          (list?.length > 0 ? (
            <div className="shadow  h-auto relative p-4 border rounded-xl border-[#00000015]">
              <div
                className="absolute top-[-10px] bg-[#FFF] px-2 font-[400]  left-[2rem] text-[#4D4D4D] 
      text-[0.875rem] font-nunito "
              >
                Other Guest IDs ({Array.isArray(list) && list?.length})
              </div>
              <div>
                <div className="flex gap-2 flex-wrap" ref={componentRef}>
                  {list &&
                    list?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="bg-slate-100 rounded p-3 mt-3 w-[30%] h-[30%] relative mr-3"
                        >
                          <TiTimes
                            onClick={() => handleRemoveUrl(item, index)}
                            className="float-right cursor-pointer absolute right-2 top-2 text-1xl bg-slate-400 rounded"
                          />

                          <LazyLoadImage
                            className="w-full h-full "
                            alt="hotel"
                            src={item} // use normal <img> attributes as props
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          ))}
      </div>

      <button
        className="bg-[#007FFF] absolute right-0 bottom-0 text-[#FFF] w-[100%] h-[3rem]"
        onClick={() => saveDetails()}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <div> SAVE DETAILS</div>
        )}
      </button>
    </Drawer>
  );
};

export default IdDrawer;
