import * as React from "react";
import { useState } from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateDisplayAttachPartyDrawer } from "../../../../reducer/posReducer/restaurant";
import { updateAddPartyDropdownVisible } from "../../../../reducer/expenseReducer";
import AddParty from "../../../../components/bookingDetailsV2/components/AddParty";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";

export default function AttachRoomParty() {
  const dispatch = useDispatch();

  const { invoicePostData: invoice } = useSelector((store) => store.order);
  const { hotelDetails } = useSelector((store) => store.login);
  const hotelId = hotelDetails?.id;
  const { allPayments } = useSelector((store) => store.payments);

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState("room");

  const [partyName, setPartyName] = useState("");
  const { partyResponse } = useSelector((store) => store.party);

  const { numOfRoomDetails } = useSelector((store) => store.pos);

  const { data: orders, invoicePostData } = useSelector((store) => store.order);

  const { addPartyDropdownVisible } = useSelector((store) => store.expense);

  const { updateInvoiceWithRoomIdAndBookingId, updateInvoiceWithPartyId } =
    useAllPosFunctions(setLoading);

  const handleRoomBill = async (e, newInputValue) => {
    const { bookingId, id } =
      numOfRoomDetails?.find((item) => item?.roomNum === newInputValue) || {};

    await updateInvoiceWithRoomIdAndBookingId(
      id,
      hotelId,
      bookingId,
      invoice,
      orders,
      newInputValue,
      updateDisplayAttachPartyDrawer
    );
  };

  const handleInvoiceWithParty = async (partyId) => {
    updateInvoiceWithPartyId(
      partyId,
      allPayments,
      hotelId,
      invoicePostData?.total,
      invoice,
      orders,
      partyName,
      updateDisplayAttachPartyDrawer,
      updateAddPartyDropdownVisible
    );
  };

  const handleTypeChange = (value) => {
    setType(value);
    setPartyName("");
  };

  return (
    <div
      className="flex flex-col w-[95%] mx-auto p-3 py-5 mb-[3rem]  rounded-b-[12px] border-[1.5px] bg-[#E9F4FF] border-[#007FFF]
     gap-5 items-start relative"
    >
      {addPartyDropdownVisible && (
        <AddParty
          setPartyId={() => {}}
          inMobilePos={true}
          setPartyName={setPartyName}
          partyType="source"
          updateInvoiceWithPartyId={handleInvoiceWithParty}
        />
      )}

      <div className="flex justify-between w-[100%] pl-1">
        <div className="flex w-[100%] gap-3 items-center ">
          <div>
            <div className="flex items-center justify-center gap-[30px] ">
              <div className="flex items-center gap-[5px]">
                <div
                  className={`h-[16px] w-[16px] rounded-full border-[1px] flex items-center justify-center ${
                    type === "room" ? "border-[#007FFF]" : "border-[#808080]"
                  } cursor-pointer`}
                  onClick={() => handleTypeChange("room")}
                >
                  {type === "room" && (
                    <div className="w-[8px] h-[8px] rounded bg-[#007FFF]"></div>
                  )}
                </div>
                <span className="font-nunito font-[500px] text-[13px] leading-[17.73px] text-[#4D4D4D]">
                  Room
                </span>
              </div>
              <div className="flex items-center gap-[5px]">
                <div
                  className={`h-[16px] w-[16px] rounded-full border-[1px] flex items-center justify-center ${
                    type === "party" ? "border-[#007FFF]" : "border-[#808080]"
                  } cursor-pointer`}
                  onClick={() => handleTypeChange("party")}
                >
                  {" "}
                  {type === "party" && (
                    <div className="w-[8px] h-[8px] rounded bg-[#007FFF]"></div>
                  )}
                </div>
                <span className="font-nunito font-[500px] text-[13px] leading-[17.73px] text-[#4D4D4D]">
                  Party
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {type === "room" ? (
        <div className="w-[100%] flex justify-center mb-5">
          <Autocomplete
            id="country-select-demo"
            style={{
              width: "95%",
              padding: 0,
              borderRadius: "10px",
              color: "#808080",
            }}
            onInputChange={(event, newInputValue) => {
              handleRoomBill(event, newInputValue);
            }}
            options={numOfRoomDetails?.map((item) => item.roomNum)}
            sx={{
              fontSize: "14px",
              color: "#00000098",
              fontFamily: "Poppins",
              fontWeight: 400,
              padding: 0,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Room No"
                sx={{
                  borderRadius: "10px",
                  "& label?.Mui-focused": {
                    marginX: 0,
                    paddingX: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  "& input": {
                    fontFamily: "Poppins",
                    fontSize: "0.75rem",
                    fontWeight: "400",
                    color: "#4D4D4D",
                    // border: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    fontFamily: "nunito",
                    color: "#808080",
                    fontSize: "0.75rem", // Font size when label is down
                  },
                  "& .MuiInputLabel-shrink": {
                    fontSize: "0.93rem", // Font size when label is up (focused)
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "nunito",
                    paddingTop: "-5px",
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
      ) : (
        <div className="w-[100%] flex justify-center mb-5">
          <Autocomplete
            id="partyAuto"
            style={{
              width: "95%",
              padding: 0,
              borderRadius: "10px",
              color: "#808080",
            }}
            value={Boolean(partyName) ? partyName : ""}
            onInputChange={(event, newInputValue) => {
              if (newInputValue === "ADD PARTY") {
                dispatch(updateAddPartyDropdownVisible(true));
                return;
              }
              setPartyName(newInputValue);

              let id = partyResponse
                ?.filter((Obj) => Obj?.displayName === newInputValue)
                ?.map((Obj) => Obj?.id)?.[0];

              handleInvoiceWithParty(id);
            }}
            options={[
              "ADD PARTY",
              ...(partyResponse
                ?.filter((Obj) => Boolean(Obj?.displayName))
                ?.map((Obj) => Obj?.displayName) ?? []),
            ]}
            sx={{
              fontSize: "14px",
              color: "#00000098",
              fontFamily: "Poppins",
              fontWeight: 400,
              padding: 0,
            }}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  color: option === "ADD PARTY" ? "#007fff" : "inherit",
                }}
              >
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Party Name"
                sx={{
                  borderRadius: "10px",
                  "& label?.Mui-focused": {
                    marginX: 0,
                    paddingX: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  "& input": {
                    fontFamily: "Poppins",
                    fontSize: "0.75rem",
                    fontWeight: "400",
                    color: "#4D4D4D",
                    // border: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    fontFamily: "nunito",
                    color: "#808080",
                    fontSize: "0.75rem", // Font size when label is down
                  },
                  "& .MuiInputLabel-shrink": {
                    fontSize: "0.93rem", // Font size when label is up (focused)
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "nunito",
                    paddingTop: "-5px",
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
