import React, { useEffect } from "react";
import Dates from "./Dates";
import Rooms from "./Rooms";
import Channels from "./Channels";
import ShowHistory from "./ShowHistory";
import { useSelector, useDispatch } from "react-redux";
import {
  refreshState,
  updateApiStatus,
  updateApiSuccessModal,
  updateApiSuccessModalData,
  updateRate,
  updateAvailableRooms,
} from "../../reducer/channelManagerReducer";
import {
  CALCULATE_CHECKID_DATE_CHECKOUT_DATE,
  GET_CHANNEL_MANAGER_DATE_FORMAT,
  GET_TOMORROW_DATE,
} from "../../utils/helper";
import { postChannelManager } from "../../services/channelManager";
import { Checkbox } from "antd";

const Index = () => {
  const {
    selectedChannelType,
    selectedRoomType,
    selectedChannel,
    selectedCheckInDate,
    selectedCheckOutDate,
    availableRoom,
    rate,
    cmInvCode,
    apiLoading,
  } = useSelector((store) => store.channelManager);
  const { hotelDetails } = useSelector((store) => store.login);
  const dispatch = useDispatch();

  // date
  let CheckInDate = Boolean(selectedCheckInDate)
    ? selectedCheckInDate
    : new Date();
  let CheckOutDate = Boolean(selectedCheckOutDate)
    ? selectedCheckOutDate
    : GET_TOMORROW_DATE();

  // validate

  const isValidate =
    CALCULATE_CHECKID_DATE_CHECKOUT_DATE(CheckInDate, CheckOutDate) &&
    (Boolean(availableRoom) || Boolean(+rate)) &&
    Boolean(selectedChannel);

  const isStopSaleDisabled = !(
    CALCULATE_CHECKID_DATE_CHECKOUT_DATE(CheckInDate, CheckOutDate) &&
    Boolean(selectedRoomType)
  );
  useEffect(() => {
    // redux refresh when come to the page
    refreshChannelManagerReduxStore();
  }, []);

  const refreshChannelManagerReduxStore = () => {
    dispatch(refreshState());
  };

  const onSubmit = () => {
    let checkInDate = GET_CHANNEL_MANAGER_DATE_FORMAT(CheckInDate);
    let checkOutDate = GET_CHANNEL_MANAGER_DATE_FORMAT(CheckOutDate);
    const data = {
      availability: availableRoom,
      channelCodes: selectedChannel,
      startDate: checkInDate,
      endDate: checkOutDate,
      hotelId: hotelDetails?.id,
      invCode: cmInvCode,
      price: rate,
    };

    // if validation return true , perform api

    if (isValidate) {
      // perform api
      performApi(data);
    }
  };

  const performApi = async (data) => {
    // loading phase
    dispatch(
      updateApiStatus({
        loading: true,
        apiError: false,
        errorMsg: "",
      })
    );
    // success phase

    let response = await postChannelManager(data, `cm/updateAvailability`);

    if (Boolean(response)) {
      dispatch(
        updateApiStatus({
          loading: false,
          apiError: false,
          errorMsg: "",
        })
      );
      // show modal with success message
      displayedSuccessData(data);

      // refresh data from succssfull api calls

      // dispatch(refreshState());
    } else {
      // failure phase

      dispatch(
        updateApiStatus({
          loading: false,
          apiError: true,
          errorMsg: "",
        })
      );
      // show modal with failure message
      dispatch(updateApiSuccessModal());
    }
  };
  const onStopSaleClick = () => {
    let checkInDate = GET_CHANNEL_MANAGER_DATE_FORMAT(CheckInDate);
    let checkOutDate = GET_CHANNEL_MANAGER_DATE_FORMAT(CheckOutDate);
    dispatch(updateAvailableRooms(0));
    const data = {
      availability: 0,
      channelCodes: selectedChannel,
      startDate: checkInDate,
      endDate: checkOutDate,
      hotelId: hotelDetails?.id,
      invCode: cmInvCode,
    };
    performApi(data);
  };

  const displayedSuccessData = (data) => {
    dispatch(
      updateApiSuccessModalData({
        showRevisedRate: +rate,
        showRoomType: selectedRoomType,
        showCheckInDate: CheckInDate,
        showCheckOutDate: CheckOutDate,
        showChannel: selectedChannel,
        showChannelType: selectedChannelType,
        showAvailability: data.availability,
      })
    );
    // open modal
    dispatch(updateApiSuccessModal());
  };

  return (
    <div>
      <div className="flex px-8 justify-between gap-8">
        <div className="flex-1">
          <Dates />

          <div className="flex mt-4 relative justify-between items-center">
            <input
              min={0}
              onChange={(e) => dispatch(updateRate(e.target.value))}
              type="number"
              name="discount"
              placeholder="Enter Amount"
              className="mt-3 text-gray-900 rounded-lg p-2.5 cursor-pointer w-[50%] border"
              defaultValue={null}
            />
            <h1 className="mt-[1rem] text-[#00000080] text-[16px] absolute top-[-16px] left-[15px] bg-[#ffffff]">
              Rate
            </h1>
            <div className="mt-3">
              <label className="mt-1 text-[#00000080] text-[16px]">
                Auto Pricing
              </label>
              <Checkbox className="ml-2" />
            </div>
          </div>
        </div>
        <div className="flex-1">
          <Rooms />
        </div>
        <div className="flex-1">
          <Channels />
        </div>
      </div>

      <div className="pt-3 px-8">
        {isValidate ? (
          <>
            {apiLoading ? (
              <button
                onClick={onSubmit}
                className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md w-[20%]"
              >
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span> loading
                </div>
              </button>
            ) : (
              <>
                <button
                  className="w-[10%] py-2 border rounded-md mr-6 text-[#F36960] border-[#F36960] font-normal "
                  disabled={isStopSaleDisabled}
                  onClick={onStopSaleClick}
                >
                  Stop Sale
                </button>
                <button
                  onClick={onSubmit}
                  className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md w-[20%]"
                >
                  Apply Changes
                </button>
              </>
            )}
          </>
        ) : (
          <>
            <button
              className="w-[10%] py-2 border rounded-md mr-6 text-[#F36960] border-[#F36960] font-normal "
              disabled={isStopSaleDisabled}
              onClick={onStopSaleClick}
            >
              Stop Sale
            </button>

            <button
              disabled={true}
              className="bg-blue-300 text-white font-bold py-2 px-4 rounded-md w-[20%]"
            >
              Apply Changes
            </button>
          </>
        )}
      </div>

      <ShowHistory />
    </div>
  );
};

export default Index;
