import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  updateSelectedPos,
  resetState,
} from "../../reducer/manageStocksReducer";
import { getAllIngredients } from "../../reducer/ingredientsReducer";
import { getAllItems } from "../../reducer/getItemReducer";

import OrganisationDropDown from "../appHeader/OrganisationDropDown";
import NightAuditConfirmationModal from "../appHeader/NightAuditConfirmationModal";
import AppHeader from "../appHeader/Index";
import Header from "./components/Header";
import Content from "./components/Content";

const ManageStocksMainPage = () => {
  const { hotelDetails } = useSelector((store) => store.login);
  const { organisationDropDownDisplay } = useSelector(
    (store) => store.appHeader
  );
  const { response } = useSelector((store) => store.posSetting);
  const { selectedPos } = useSelector((store) => store.manageStocks);

  const dispatch = useDispatch();

  // reset state
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  // setting the first pos as selected POS
  useEffect(() => {
    if (Array.isArray(response) && response?.length) {
      let targetPos = {};
      targetPos = response?.find(
        ({ type }) => type === "Restaurant" || type === "Retail"
      );
      dispatch(updateSelectedPos(targetPos));
    }
  }, [response]);

  // ingredient api is called in case of restaurant
  // items api is called in case of other POS Types
  useEffect(() => {
    if (!hotelDetails?.id || !selectedPos?.type) return;

    const selectedPosType = selectedPos?.type;
    const payload = { hotelId: hotelDetails?.id, posId: selectedPos?.id };

    if (selectedPosType === "Restaurant") {
      dispatch(getAllIngredients(payload));
    } else {
      dispatch(getAllItems(payload));
    }
  }, [selectedPos, hotelDetails]);

  return (
    <div className="w-full">
      <AppHeader />
      <NightAuditConfirmationModal />
      {organisationDropDownDisplay && <OrganisationDropDown />}
      <div className="bg-[#F2F4FC] w-full h-[93.68vh] mt-[52px] overflow-hidden">
        <Header />
        <Content />
      </div>
    </div>
  );
};

export default ManageStocksMainPage;
