import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateActiveFooter,
  updateExpandedHotelDetailScreen,
} from "../../reducer/appHeaderReducer";
import {
  resetBookingDetails,
  updateDisplayRoomDrawer,
  updateGuestSectionVisible,
  updateMobileBookingDetailsId,
  updateRoomSectionVisible,
  updateShowPrimaryGuestDrawer,
  updateViewBillSectionVisible,
} from "../../reducer/bookingReducer";
import { updateGuestSectionVisble as updateWalkinGuestSectionVisible } from "../../reducer/walkinReducer";
import { useNavigate } from "react-router-dom";

const useMobileNavigation = () => {
  const { isMobileScreen } = useSelector((store) => store.appHeader);
  const navigate = useNavigate();

  const { guestSectionVisible, roomSectionVisible, viewBillSectionVisible } =
    useSelector((store) => store.booking);

  const dispatch = useDispatch();

  useEffect(() => {
    const handlePopState = (event) => {
      // This will run for only mobile component

      if (isMobileScreen) handleMobileNavigation();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleMobileNavigation = () => {
    const currentUrl = window.location.href;

    let segments = currentUrl?.split("/");
    let lastSegment = segments?.pop();

    if (lastSegment === "dashboard") {
      dispatch(updateExpandedHotelDetailScreen(false));
    } else if (lastSegment === "bookings") {
      if (viewBillSectionVisible) dispatch(updateViewBillSectionVisible(false));
      else if (guestSectionVisible) {
        dispatch(updateGuestSectionVisible(false));
        dispatch(
          updateShowPrimaryGuestDrawer({
            visible: "",
            bookingId: "",
            customerType: "",
            customerId: "",
          })
        );
      } else if (roomSectionVisible) {
        dispatch(updateRoomSectionVisible(false));
        dispatch(updateDisplayRoomDrawer());
      } else {
        dispatch(updateActiveFooter("Bookings"));
        dispatch(updateMobileBookingDetailsId(""));
        dispatch(resetBookingDetails());
      }
    } else if (lastSegment === "walkin") {
      dispatch(updateWalkinGuestSectionVisible(false));
    } else if (lastSegment === "all-orders") {
    } else if (lastSegment === "clients") {
      navigate("/dashboard/menu");
    }
  };
};

export default useMobileNavigation;
