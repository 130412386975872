import React from "react";
import { useSelector } from "react-redux";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";
import { POS_TYPE } from "../../../../utils/posHelper";

const Footer = ({
  setMultipleKOTScreenVsisible,
  setOrderDetailScreenVisible,
}) => {
  const { activePosType } = useSelector((store) => store.posSetting);

  const handleRetailBill = () => {
    handleKOT("inProgress", true);
  };

  const { handleKOT } = useAllPosFunctions();

  return (
    <footer className="fixed flex gap-4 justify-center items-center bottom-0 w-[100%] bg-[#FFF] py-5 px-2    shadow-mobile-footer  z-[20] ">
      {activePosType === POS_TYPE[3] ? (
        <button
          className="bg-[#FFF] border-[#007FFF] border-[1px] w-[95%] text-[#007FFF] font-outfit font-[500] text-[16px] leading-[24px] flex justify-center items-center rounded-[8px] py-3 "
          onClick={handleRetailBill}
        >
          Bill
        </button>
      ) : (
        <button
          className="bg-[#FFF] border-[#007FFF] border-[1px] w-[95%] text-[#007FFF] font-outfit font-[500] text-[16px] leading-[24px] flex justify-center items-center rounded-[8px] py-3 "
          onClick={() =>
            handleKOT(
              "inProgress",
              false,
              setOrderDetailScreenVisible,
              setMultipleKOTScreenVsisible
            )
          }
        >
          {activePosType === POS_TYPE[0] ? "New KOT" : "New Order"}
        </button>
      )}
    </footer>
  );
};

export default Footer;
