import React from "react";
// import PosForms from "./PosForms";
import Header from "./Header";
import AddTableModal from "./model/AddTableModal";
import EditTableModal from "./model/EditTableModal";
import AddStewardModel from "./model/AddStewardModel";

const PosSettingContainer = () => {
  return (
    <>
      <div className="p-4">
        <Header />
      </div>

      {/* Model */}
      <AddTableModal />
      <EditTableModal />
      <AddStewardModel />
    </>
  );
};

export default PosSettingContainer;
