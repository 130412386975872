import React, { lazy, Suspense } from "react";
import Loading from "../../pages/loading";
import { useSelector, useDispatch } from "react-redux";
import { updateDisplayFilter } from "../../reducer/usersRole";

const ProfileMainPage = lazy(() => import("../../components/profile"));

const Index = () => {
  const dispatch = useDispatch();

  const { displayFilter } = useSelector((store) => store.usersRole);

  const handleOnClick = () =>
    displayFilter ? dispatch(updateDisplayFilter(false)) : undefined;


  return (
    <Suspense fallback={<Loading />}>
      <div
        className="bg-[#F2F4FC] min-h-[100vh] h-[100%] w-full"
        onClick={handleOnClick}
      >
        <ProfileMainPage />
      </div>
    </Suspense>
  );
};

export default Index;
