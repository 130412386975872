import React, { useState, useRef } from "react";
import { GoPencil } from "react-icons/go";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import { FaEdit } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import {
  updatesShowingAddStewardModel,
  UpdatePosSettingForm,
  updatePosSettingData,
} from "../../../reducer/posReducer/posSettingReducer";
import { FaTimesCircle } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
const Steward = ({
  setPosData,
  posData,
  handlePosData,
  selectedStewardKey,
  setSelectedStewardKey,
  setIsStewardAdded,
  isStewardAdded,
}) => {
  const [editSteward, setEditSteward] = useState("");
  const dispatch = useDispatch();
  const { hotelDetails } = useSelector((store) => store.login);
  const { response, activePosSlug } = useSelector((store) => store.posSetting);

  let stewardList = Boolean(posData["stewards"]) ? posData["stewards"] : [];

  const [editValue, setEditValue] = useState();

  const inputRef = useRef();

  const handleClick = (item) => {
    setEditSteward(item);
    setTimeout(() => {
      inputRef.current.focus();
    }, 1);
  };

  const handleSteward = (value, key) => {
    setIsStewardAdded(false);
    if (Boolean(value)) {
      if (Boolean(key) || key == 0) {
        setSelectedStewardKey(key);
        let updatedStewardVal = stewardList?.map((catVal, catKey) => {
          if (catKey === key) {
            return value;
          }
          return catVal;
        });

        setPosData({ ...posData, stewards: updatedStewardVal });
      } else {
        if (Array.isArray(stewardList) && stewardList.indexOf(value) == -1) {
          let updatedStewardVal = [...stewardList, value];
          let updatedStewardData = response?.map((item, key) => {
            if (item.slug === activePosSlug) {
              return { ...item, stewards: updatedStewardVal };
            } else {
              return item;
            }
          });
          dispatch(updatePosSettingData(updatedStewardData));

          setPosData({
            ...posData,
            stewards: updatedStewardVal,
            stewardName: "",
          });
        }
      }
    }
  };

  const handleStewardDelete = (key) => {
    let updatedStewardVal = stewardList?.filter((unitVal, unitKey) => {
      return unitKey !== key;
    });

    setPosData({
      ...posData,
      stewards: updatedStewardVal,
      stewardName: "",
    });
  };

  return (
    <>
      <div className="flex mt-4">
        <h1 className="mr-6  font-medium text-gray-700 text-2xl dark:text-white">
          Steward
        </h1>
        <div className="w-[63%] bg-[#00000026] h-[2px] mt-4"></div>
      </div>

      <div className="flex flex-wrap mt-3 ml-[1rem] ">
        <div className="flex flex-wrap">
          <div className="mt-2 flex mr-2 mb-2 flex-wrap">
            {stewardList?.map((val, key) => {
              return (
                <div className="relative mr-2 mb-2">
                  <input
                    onClick={(e) => handleSteward(e.target.value, key)}
                    onChange={(e) => handleSteward(e.target.value, key)}
                    onBlur={() => setSelectedStewardKey(null)}
                    onKeyDown={(e) =>
                      e.key == "Enter" ? setSelectedStewardKey(null) : null
                    }
                    value={val}
                    type="text"
                    className=" h-[2.8rem] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {!(selectedStewardKey === key) && (
                    <>
                      <FaEdit className="absolute right-4 top-3" />
                      <FaTimesCircle
                        onClick={() => handleStewardDelete(key)}
                        className="absolute right-[-2px] top-[-5px] cursor-pointer"
                      />
                    </>
                  )}
                </div>
              );
            })}
          </div>

          <div className="mt-2 flex mr-2 ">
            {isStewardAdded ? (
              <input
                onChange={(e) => handlePosData(e)}
                onBlur={() => {
                  handleSteward(posData["stewardName"], null);
                }}
                onKeyDown={(e) =>
                  e.key == "Enter"
                    ? handleSteward(posData["stewardName"], null)
                    : null
                }
                name="stewardName"
                type="text"
                className=" bg-gray-50 border border-gray-300 h-[2.8rem] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            ) : (
              <>
                <Button
                  onClick={() => setIsStewardAdded(true)}
                  className="flex mt-3 "
                >
                  <FaPlus className="mr-1 mt-1" /> <span>Add Steward</span>
                </Button>
              </>
            )}
          </div>
        </div>

        {/* {response &&
          response
            ?.filter((item) => item.type == "Restaurant")
            .map((item) =>
              item.stewards.map((steward, index) => {
                return (
                  <div className="flex mr-4 flex-wrap ">
                    {editSteward !== steward ? (
                      <Button
                        className="flex text-base bg-gray-200 mr-2 mb-2 float-right rounded-sm"
                        key={index}
                      >
                        <span>{steward}</span>
                        <span
                          className=" ml-[1.75rem] mt-1"
                          onClick={() => handleClick(steward)}
                        >
                          <GoPencil />
                        </span>
                      </Button>
                    ) : (
                      <input
                        ref={inputRef}
                        className="appearance-none block bg-gray-200  text-base  border
                 border-gray-300 rounded py-[2px] mb-2 px-3 mr-2  focus:outline-none focus:bg-white
                  focus:border-blue-500"
                        type="text"
                        name="stewards"
                        defaultValue={steward}
                        onChange={(e) => setEditValue(e.target.value)}
                        onBlur={() => {
                          const payload = {
                            id: item.id,
                            stewards: [...item.stewards, editValue],
                          };
                          dispatch(UpdatePosSettingForm(payload));
                        }}
                        onFocus={() => {
                         
                        }}
                      />
                    )}
                  </div>
                );
              })
            )}

        <Button
          className="flex bg-gray-200  text-base mr-2 mb-2 float-right rounded-sm"
          onClick={() =>
            dispatch(
              updatesShowingAddStewardModel({
                modal: true,
              })
            )
          }
        >
          <span>Add Steward</span>
          <span className="mx-1"> +</span>
        </Button> */}
      </div>
    </>
  );
};

export default Steward;
