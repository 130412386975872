import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { deleteOrders } from "../../../../reducer/orderReducer";
import { GET_ITEM_PRICE } from "../../../../utils/posHelper";
import ItemCard from "../../ItemCard";
import { RiDeleteBin6Line } from "react-icons/ri";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";

export default function EditKotDrawer({
  open,
  setOpen,
  runningOrders,
  runningInvoice,
}) {
  const { hotelDetails } = useSelector((store) => store.login);
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);
  const dispatch = useDispatch();

  const { activePosType } = useSelector((store) => store.posSetting);
  const { selectedOrderItemCountMap, selectedOrderItem } = useSelector(
    (store) => store.pos
  );
  const { loading, invoicePostData } = useSelector((store) => store.order);
  const { data: allItems } = useSelector((store) => store.getItems);

  let itemCountMap = {};
  let selectedItemWithQuantity = [];
  let priceBreakUp = [];
  let selectedItemObject = {}; // selected item to get the price for service type

  Object.keys(selectedOrderItemCountMap)?.forEach((id) => {
    allItems?.forEach((item) => {
      if (item.id === id && selectedOrderItemCountMap[id].count !== 0) {
        selectedItemObject = item;

        selectedItemWithQuantity.push({
          ...item,
          quantity: selectedOrderItemCountMap[id].count,
        });

        itemCountMap[id] = {
          count: selectedOrderItemCountMap[id].count,
          instruction: Boolean(selectedOrderItemCountMap[id].instruction)
            ? selectedOrderItemCountMap[id].instruction
            : null,
        };

        let addOn = [];

        // calculate item addon price
        item.addOns?.forEach((addonItem) => {
          if (
            Boolean(selectedOrderItemCountMap[id]?.addOns?.[addonItem.name])
          ) {
            itemCountMap[id] = {
              ...itemCountMap[id],
              addOns: {
                [addonItem?.name]:
                  selectedOrderItemCountMap[id]?.addOns?.[addonItem.name],
              },
            };

            addOn.push({
              name: addonItem.name,
              price: addonItem.price,
              count: selectedOrderItemCountMap[id]?.addOns?.[addonItem.name],
            });
          }
        });

        priceBreakUp.push({
          id: item.id,
          type: item.name,
          count: +selectedOrderItemCountMap[id].count,
          rate: GET_ITEM_PRICE(
            tableOrderDetails.type,
            activePosType,
            selectedItemObject
          ),
          instruction: item.instruction,
          addOns: addOn.length > 0 ? addOn : null,
        });
      }
    });
  });

  const confirm = (id, item = null) => {
    const payload = {
      isInvoiceDelete: runningOrders?.length > 1 ? false : true,
      orderId: id,
      deletedOrderObect: item,
      runningOrders: runningOrders,
      runningInvoice: runningInvoice,
      hotelId: hotelDetails?.id,
      status: item.status,
    };

    dispatch(deleteOrders(payload));
  };

  const { handleKOTEdit } = useAllPosFunctions();
  const handleOk = () => {
    handleKOTEdit(selectedOrderItem, priceBreakUp, itemCountMap, handleCancel);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const { id, status } = selectedOrderItem || {};

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "100%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",

          "& .MuiDrawer-root": {
            position: "absolute",
            marginleft: "auto",
            right: 0,
          },
          "& .MuiPaper-root": {
            position: "absolute",
            marginLeft: "auto",
          },
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={open}
      CloseIcon={null}
      onClose={() => setOpen(false)}
    >
      <div className="w-[100%] flex flex-col justify-center items-center bg-[#F2F4FC] ">
        <header className="flex  py-4 px-5 w-[100%] bg-[#FFF] justify-between items-center ">
          <h2 className="font-outfit font-[600] text-[1rem] text-[#000000]">
            KOT
          </h2>

          <div className="flex gap-4 items-center">
            <div>
              {" "}
              {invoicePostData?.status == "inProgress" && (
                <RiDeleteBin6Line
                  className="text-[#F36960]  text-[20px]"
                  onClick={() => confirm(id, selectedOrderItem)}
                />
              )}
            </div>

            <div
              className="bg-[#4D4D4D] w-[16px] h-[16px] rounded-[50%] flex justify-center items-center "
              onClick={() => setOpen(false)}
            >
              <IoCloseSharp className="text-[10px] text-[#FFF] " />
            </div>
          </div>
        </header>

        <main className=" w-[90%] flex flex-col gap-2 mt-[2rem] mb-[7rem] bg-[#FFF] p-2 py-4 rounded-[12px]">
          {selectedItemWithQuantity?.map((item) => (
            <ItemCard key={item.id} item={item} status={status} />
          ))}
        </main>

        {invoicePostData?.status == "inProgress" && (
          <footer className="bg-[#FFF] shadow-lg w-[100%] fixed bottom-0 py-4 flex justify-center shadow-mobile-footer items-center">
            <>
              {status == "inProgress" ? (
                <div
                  className="w-[95%] bg-[#007FFF] rounded-[12px] p-4 flex justify-center items-center text-[#FFF] font-outfit font-[500] text-[16px] leading-[17.64px]"
                  onClick={handleOk}
                >
                  Save
                </div>
              ) : status === "held" ? (
                <div
                  className="w-[95%] bg-[#007FFF] rounded-[12px] p-4 flex justify-center items-center text-[#FFF] font-outfit font-[500] text-[16px] leading-[17.64px]"
                  loading={loading}
                  onClick={handleOk}
                >
                  Resume
                </div>
              ) : (
                <></>
              )}
            </>
          </footer>
        )}
      </div>
    </Drawer>
  );
}
