import { useSelector } from "react-redux";

import { Select } from "antd";
import { TranslateText } from "../../../utils/translation";

const Header = () => {
  const { inActiveRooms } = useSelector((store) => store.rooms);

  return (
    <header className="shadow-housekeeping-header h-[68px] w-full flex items-center justify-between pl-[30px] pr-[50px] bg-[#ffffff]">
      <p className="w-[14.6%] font-[600] text-[22px] leading-[33px] text-[#545454]">
        <TranslateText textKey={"housekeeping"} namespace="housekeeping" />
      </p>
      <div className="w-[64.54%] flex items-center justify-between pl-[31%]">
        {/* <div className="flex items-center gap-[10px]">
          <p className="text-[14px] leading-[21px] text-[#808080]">Group:</p>
          <Select className="h-[34px] w-[134px] rounded-[10px] " />
        </div> */}
        <div className="flex items-center gap-[38px]">
          <div className="flex items-center gap-[16px]">
            <div>
              <p className="font-[300] text-[10px] leading-[15px] text-[#808080]">
                <TranslateText
                  textKey={"out_of_order"}
                  namespace="housekeeping"
                />
              </p>
              <p className="font-[500] text-[14px] leading-[21px] text-[#F36960]">
                {`${inActiveRooms?.length}`}
                <TranslateText textKey={"rooms"} namespace="housekeeping" />
              </p>
            </div>
            {/* <div>
              <p className="font-[300] text-[10px] leading-[15px] text-[#808080]">
                Critical Area:
              </p>
              <p className="font-[500] text-[14px] leading-[21px] text-[#4d4d4d]">
                Room 202
              </p>
            </div> */}
          </div>
          {/* <div className="flex items-center gap-[16px]">
            <div>
              <p className="font-[300] text-[10px] leading-[15px] text-[#808080]">
                Open Ticket:
              </p>
              <p className="font-[500] text-[14px] leading-[21px] text-[#41C588]">
                10
              </p>
            </div>
            <div>
              <p className="font-[300] text-[10px] leading-[15px] text-[#808080]">
                Delayed Tickets:
              </p>
              <p className="font-[500] text-[14px] leading-[21px] text-[#F36960]">
                10
              </p>
            </div>
          </div> */}
        </div>
        {/* <button className="shadow-show-more h-[31px] w-[127px] rounded-[5px] bg-[#2F80ED] flex items-center justify-center">
          <span className="text-[16px] leading-[24px] text-[#ffffff]">
            View Tickets
          </span>
        </button> */}
      </div>
    </header>
  );
};
export default Header;
