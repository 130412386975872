import React from "react";
import { useSelector } from "react-redux";
import { Suspense, lazy } from "react";
import Loading from "../loading";
const MobileScreenAllOrders = lazy(() =>
  import("../../mobileComponents/mobileScreenPos/allOrders/Index")
);
const AllOrders = lazy(() => import("../../components/pos/allOrders/index"));

export const POS = () => {
  const { isMobileScreen } = useSelector((store) => store.appHeader);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <div className="bg-[#F2F4FC] w-[100vw] h-[100vh]">
          {isMobileScreen ? <MobileScreenAllOrders /> : <AllOrders />}
        </div>
      </Suspense>
    </>
  );
};

export default POS;
