import React from "react";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDisplayOverLayingHamburgerMenu,
  updateGloabalSearchText,
} from "../../reducer/appHeaderReducer";
import { updateActiveMenu } from "../../reducer/appReducer";
import SideBarImage from "../../components/sidebar/SideBarImage";
import NavigationBarList from "../../components/sidebar/NavigationBarList";
import { isSerachBarAlowedForThisPage } from "../../utils/helper";

const HamburgerDrawer = ({ setSearchBarAllowed }) => {
  const customHeaderStyles = {
    display: "none",
  };
  const customBodyStyles = {
    padding: "0",
    paddingBottom: "0px",
  };
  const dispatch = useDispatch();

  const { displayOverlayingHamburgerMenu } = useSelector(
    (store) => store.appHeader
  );

  const handleClose = () =>
    dispatch(updateDisplayOverLayingHamburgerMenu(false));

  const dashBoardHandler = (dashboardNavigationObject) => {
    let searchBarAll = isSerachBarAlowedForThisPage(
      dashboardNavigationObject.title
    );
    setSearchBarAllowed(Boolean(searchBarAll));
    dispatch(updateGloabalSearchText(""));
    dispatch(
      updateActiveMenu({
        activeMenu: dashboardNavigationObject.title,
        pos: false,
      })
    );
  };

  return (
    <Drawer
      closeIcon={<></>}
      closable={true}
      onClose={handleClose}
      open={displayOverlayingHamburgerMenu}
      headerStyle={customHeaderStyles}
      bodyStyle={customBodyStyles}
      placement={"left"}
      width="30%"
      mask={true}
      style={{ zIndex: 10000 }}
    >
      <div
        className="bg-[#007FFF] w-[100%] h-[100vh] "
        onClick={() => dispatch(updateDisplayOverLayingHamburgerMenu(false))}
      >
        <SideBarImage />
        <NavigationBarList dashboardSelectHandler={dashBoardHandler} />
      </div>
    </Drawer>
  );
};

export default HamburgerDrawer;
