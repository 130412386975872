import { authedAxios } from "../utils/customHttpHeader";

const postChannelManager = async (payload, endpoint) => {
  try {
    const response = await authedAxios().post(endpoint, payload);
    return response.data;
  } catch (error) {
    return null;
  }
};

const getRoomsData = async (endpoint, queryParams) => {
  try {
    const response = await authedAxios().get(endpoint, {
      params: {
        ...queryParams,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export { postChannelManager, getRoomsData };
