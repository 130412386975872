import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBookingDetailsData,
  updateCustomerDetails,
  updatePaymentDetails,
  updateInvoiceDetails,
  updateBookingDetailsEditable,
  updateSelectedRoomNumberForModify,
  updateSelectedCheckInDateTime,
  updateSelectedCheckOutDateTime,
  updateMealPlan,
  updateNightsCount,
  updatetaxIncludedInPriceLocalState,
  updateRoomPriceStructure,
  updateRoomDetails,
} from "../../reducer/bookingReducer";
import { bookingDetailsById } from "../../services/booking";
import { calculateNumberOfNights } from "../../utils/bookingDetailsHelper";
import {
  CURRENCY_FORMAT,
  GET_ISO_FORMAT,
  GET_TODAY_DATE_WITH_ISO_FORMAT,
  GET_TOMORROW_DATE,
} from "../../utils/helper";
import { getRoomsAvailability } from "../../services/walkin";

const useStoreBookingDetailsLogic = () => {
  const dispatch = useDispatch();

  const { hotelDetails, occupancyPlanRate } = useSelector(
    (store) => store.login
  );
  const {
    showMainBookingDetailsDrawer,
    bookingDetails,
    invoiceDetails,
    mobileBookingDetailsId,
  } = useSelector((store) => store.booking);
  const { response } = useSelector((store) => store.rooms);

  const rooms = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];

  const bookingId = Boolean(showMainBookingDetailsDrawer?.bookingId)
    ? showMainBookingDetailsDrawer?.bookingId
    : mobileBookingDetailsId;

  // Api Hitting for Booking details for both screen
  useEffect(() => {
    if (Boolean(hotelDetails?.id) && Boolean(bookingId)) performApi();
  }, [bookingId, mobileBookingDetailsId]);

  useEffect(() => {
    storeData();
  }, [bookingDetails, response, invoiceDetails]);

  const performApi = async () => {
    const bookingDetailsPayload = {
      id: bookingId,
      hotelId: hotelDetails.id,
    };

    dispatch(updateInvoiceDetails([]));
    dispatch(updateBookingDetailsEditable({}));
    dispatch(updateCustomerDetails({}));
    dispatch(updatePaymentDetails());

    dispatch(
      updateBookingDetailsData({
        loadingDetails: true,
        responseDetails: {},
        responseDetailsApiError: false,
      })
    );

    const response = await bookingDetailsById(
      bookingDetailsPayload,
      "bookings"
    );

    if (Boolean(response.data)) {
      // api success phase
      dispatch(
        updateBookingDetailsData({
          loadingDetails: false,
          responseDetails: response.data,
          responseDetailsApiError: false,
        })
      );
      let bookingDetails = Boolean(response.data)
        ? response?.data?.bookingDetails
        : {};
      dispatch(updateBookingDetailsEditable(bookingDetails));

      let customerDetails = Boolean(response.data)
        ? response?.data?.customerDetails
        : {};
      dispatch(updateCustomerDetails(customerDetails));

      let invoiceDetails = Boolean(response.data)
        ? response?.data?.invoiceDetails
        : [];
      dispatch(updateInvoiceDetails(invoiceDetails));

      let paymentDetails = Boolean(response.data)
        ? response?.data?.paymentDetails
        : [];
      dispatch(updatePaymentDetails(paymentDetails));
    } else {
      dispatch(
        updateBookingDetailsData({
          loadingDetails: false,
          responseDetails: {},
          responseDetailsApiError: true,
        })
      );
    }
  };

  const storeData = async () => {
    const payload = {
      bookingId: bookingDetails?.id,
      startDate: Boolean(bookingDetails?.checkIn)
        ? GET_ISO_FORMAT(bookingDetails?.checkIn)
        : GET_TODAY_DATE_WITH_ISO_FORMAT(),
      endDate: Boolean(bookingDetails?.checkOut)
        ? GET_ISO_FORMAT(bookingDetails?.checkOut)
        : GET_ISO_FORMAT(GET_TOMORROW_DATE()),
      hotelId: hotelDetails?.id,
    };

    let currentPriceBreakup = Array.isArray(invoiceDetails)
      ? invoiceDetails?.find((Obj) => Obj?.invoiceType === "roomBill")
          ?.priceBreakup
      : [];

    let data = await getRoomsAvailability(payload, "bookings/availability");

    if (Boolean(data)) dispatch(updateRoomDetails({ response: data }));

    const namesToFilter = Array.isArray(rooms)
      ? rooms?.map((item) => item.name)
      : [];

    const updatedData = Array.isArray(data)
      ? data?.filter((item) => namesToFilter?.includes(item._id))
      : [];

    let localRoomPriceStructure = updatedData?.map((obj) => {
      const roomDetails = rooms.find((room) => room?.name === obj._id);

      const currentPriceBreakupObj = currentPriceBreakup?.find(
        (Obj) => Obj?.type === obj?._id
      );

      let defaultOccupancy = Boolean(roomDetails?.defaultOccupancy)
        ? roomDetails?.defaultOccupancy
        : 2;

      let rate = Boolean(currentPriceBreakupObj && currentPriceBreakupObj?.rate)
        ? currentPriceBreakupObj?.baseRate || currentPriceBreakupObj?.rate
        : occupancyPlanRate?.[roomDetails?._id]?.[bookingDetails?.mealPlan]?.[
            defaultOccupancy
          ];

      const roomStructureObject = {
        type: obj?._id,
        rate: Boolean(rate)
          ? CURRENCY_FORMAT(rate)
          : Boolean(roomDetails?.rate)
          ? CURRENCY_FORMAT(roomDetails?.rate)
          : 0,
        rooms: obj?.rooms,
        availability: obj?.availability,
        breakfastPrice: roomDetails ? Number(roomDetails?.breakfastPrice) : 0,
        mealPrice: roomDetails ? Number(roomDetails?.mealPrice) : 0,
        defaultOccupancy: Boolean(roomDetails?.defaultOccupancy)
          ? roomDetails?.defaultOccupancy
          : 2,
      };

      return roomStructureObject;
    });

    dispatch(updateSelectedCheckInDateTime(bookingDetails?.checkIn));
    dispatch(updateSelectedCheckOutDateTime(bookingDetails?.checkOut));
    dispatch(updateMealPlan(bookingDetails?.mealPlan));

    if (Boolean(bookingDetails)) {
      let selectedRoomTypeWithRoom = {};
      response.forEach((item) => {
        let rooms = bookingDetails?.rooms;
        rooms?.map((room) => {
          if (item.id === room) {
            selectedRoomTypeWithRoom[item.type] =
              selectedRoomTypeWithRoom[item.type] !== undefined
                ? [...selectedRoomTypeWithRoom[item.type], item]
                : [item];
          }
        });
      });

      dispatch(updateSelectedRoomNumberForModify(selectedRoomTypeWithRoom));
      const night = calculateNumberOfNights(
        bookingDetails?.checkIn,
        bookingDetails?.checkOut,
        hotelDetails
      );

      let roomInvoice = invoiceDetails?.find(
        (Obj) => Obj.invoiceType === "roomBill"
      );

      const taxInclusion = Array.isArray(invoiceDetails)
        ? roomInvoice?.taxIncludedInPrice
        : false;

      dispatch(updateRoomPriceStructure(localRoomPriceStructure));
      dispatch(updateNightsCount(night));
      dispatch(updatetaxIncludedInPriceLocalState(taxInclusion));
    }
  };
};

export default useStoreBookingDetailsLogic;
