import React from "react";
import { TranslateText } from "../../../../../utils/translation";
const CustomButton = ({ active, setActive }) => {
  return (
    <div
      onClick={(e) => setActive(e.target.value)}
      className="flex button-group bg-[#F0F3F7] rounded-lg w-[4.75rem] h-[2.5rem]"
    >
      <button
        value="AM"
        className={`${
          active === "AM"
            ? "border border-[#007FFF]  rounded-lg text-[#007FFF]"
            : "text-[#808080]"
        }   w-[2.7rem] h-[99%] `}
      >
        <TranslateText textKey={"am"} namespace="common" />
      </button>
      <button
        value="PM"
        className={`${
          active === "PM"
            ? "border border-[#007FFF]  rounded-lg text-[#007FFF]"
            : "text-[#808080]"
        }  w-[2.7rem] h-[99%]`}
      >
        <TranslateText textKey={"pm"} namespace="common" />
      </button>
    </div>
  );
};

export default CustomButton;
