import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearSelectedItem,
  updateAction,
} from "../../../../reducer/getItemReducer";

import { Button } from "antd";
import { GoPlus } from "react-icons/go";
import { IoMdArrowBack } from "react-icons/io";
import { TranslateText } from "../../../../utils/translation";

function PosHeader({ posHeader, from }) {
  const { action } = useSelector((store) => store.getItems);
  const { activePosType } = useSelector((store) => store.posSetting);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addItem = () => {
    if (action == "add") dispatch(updateAction("add"));
    else {
      dispatch(updateAction("add"));
      dispatch(clearSelectedItem());
    }
  };

  return (
    <div className="flex gap-2 justify-between items-center ml-1 px-4">
      <div>
        <span className="text-[32px] text-[#545454] font-semibold flex">
          {" "}
          <IoMdArrowBack
            className="mr-2 cursor-pointer mt-2"
            onClick={() => navigate(-1)}
          />
          {posHeader}
          {Boolean(activePosType) && (
            <span className="text-sm ml-2 mt-4">({activePosType})</span>
          )}
        </span>
      </div>

      {from == "itemList" && (
        <Button
          onClick={() => addItem()}
          className="bg-primary flex "
          type="primary"
        >
          <GoPlus className="text-semibold mt-[2px]" />
          <span>
          <TranslateText
        textKey={"add_item"}
        namespace="pos"
      /> 
          </span>
        </Button>
      )}
    </div>
  );
}

export default PosHeader;
