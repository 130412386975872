import { Button, message } from "antd";
import { useEffect, useState } from "react";
import CustomInput from "./CustomInput";
import CustomButton from "./CustomButton";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { calculateNumberOfNights } from "../../../../../utils/bookingDetailsHelper";
import { TranslateText } from "../../../../../utils/translation";
import {
  updateVisibleBookingDetailsCalendar,
  updateSelectedCheckInDateTime,
  updateSelectedCheckOutDateTime,
  updateNightsCount,
  updateChangeDateBoolean,
} from "../../../../../reducer/bookingReducer";

const CustomFooter = ({
  selectedCheckInDate,
  setSelectedCheckInDate,
  selectedCheckOutDate,
  setSelectedCheckOutDate,
}) => {
  const [selectedCheckInHours, setSelectedCheckInHours] = useState(12);
  const [selectedCheckInMin, setSelectedCheckInMin] = useState(0);
  const [selectedCheckInAMPM, setSelectedCheckInAMPM] = useState("PM");

  const [selectedCheckOutHours, setSelectedCheckOutHours] = useState(12);
  const [selectedCheckOutMin, setSelectedCheckOutMin] = useState(0);
  const [selectedCheckOutAMPM, setSelectedCheckOutAMPM] = useState("PM");

  const {
    selectedCheckInDateTime,
    selectedCheckOutDateTime,
    changeDateBoolean,
  } = useSelector((store) => store.booking);
  const { hotelDetails } = useSelector((store) => store.login);

  const dispatch = useDispatch();

  useEffect(() => {
    resetToOriginal();
  }, [selectedCheckInDateTime, selectedCheckOutDateTime]);

  const resetToOriginal = () => {
    // Function to extract hours, minutes, and time of day from ISO string
    const extractTime = (isoString) => {
      const date = new Date(isoString);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";

      const adjustedHours = hours % 12 || 12; // Convert 0 to 12-hour format

      return {
        hours: String(adjustedHours),
        minutes: String(minutes),
        ampm,
      };
    };

    if (selectedCheckInDateTime && selectedCheckOutDateTime) {
      setSelectedCheckInDate(selectedCheckInDateTime);
      setSelectedCheckOutDate(selectedCheckOutDateTime);

      const checkInTime = extractTime(selectedCheckInDateTime);
      setSelectedCheckInHours(checkInTime.hours);
      setSelectedCheckInMin(checkInTime.minutes);
      setSelectedCheckInAMPM(checkInTime.ampm);

      const checkOutTime = extractTime(selectedCheckOutDateTime);
      setSelectedCheckOutHours(checkOutTime.hours);
      setSelectedCheckOutMin(checkOutTime.minutes);
      setSelectedCheckOutAMPM(checkOutTime.ampm);
    } else {
      const today = moment(); // Get today's date using moment
      setSelectedCheckInDate(today.format("YYYY-MM-DD"));
      setSelectedCheckOutDate(moment().add(1, "days").format("YYYY/MM/DD"));

      // Reset to default values
      setSelectedCheckInHours(Number(12));
      setSelectedCheckInMin(Number(0));
      setSelectedCheckInAMPM("PM");

      setSelectedCheckOutHours(Number(12));
      setSelectedCheckOutMin(Number(0));
      setSelectedCheckOutAMPM("PM");
    }
  };

  const handleCancel = (e) => {
    resetToOriginal();
    dispatch(updateVisibleBookingDetailsCalendar(false));
  };

  const handleApply = (e) => {
    let newSelectedCheckInHour = selectedCheckInHours;
    let newSelectedCheckInMin = selectedCheckInMin;

    let newSelectedCheckOutHours = selectedCheckOutHours;
    let newSelectedCheckOutMin = selectedCheckOutMin;

    if (newSelectedCheckInHour < 1) newSelectedCheckInHour = 1;
    if (newSelectedCheckInHour > 12) newSelectedCheckInHour = 12;

    if (newSelectedCheckInMin < 0) newSelectedCheckInMin = 0;
    if (newSelectedCheckInMin > 60) newSelectedCheckInMin = 60;

    if (newSelectedCheckOutHours < 1) newSelectedCheckOutHours = 1;
    if (newSelectedCheckOutHours > 12) newSelectedCheckOutHours = 12;

    if (newSelectedCheckOutMin < 0) newSelectedCheckOutMin = 0;
    if (newSelectedCheckOutMin > 60) newSelectedCheckOutMin = 60;

    setSelectedCheckInHours(newSelectedCheckInHour);
    setSelectedCheckInMin(newSelectedCheckInMin);

    setSelectedCheckOutHours(newSelectedCheckOutHours);
    setSelectedCheckOutMin(newSelectedCheckOutMin);

    const dateTimeStringCheckIn = `${moment(selectedCheckInDate).format(
      "YYYY-MM-DD"
    )} ${newSelectedCheckInHour}:${newSelectedCheckInMin} ${selectedCheckInAMPM} `;

    // Parse the combined string using moment.js for consistent formatting
    const checkInDateAndTime = moment(
      dateTimeStringCheckIn,
      "YYYY-MM-DD h:mm A"
    ).toISOString();

    const dateTimeStringCheckOut = `${moment(selectedCheckOutDate).format(
      "YYYY-MM-DD"
    )} ${newSelectedCheckOutHours}:${newSelectedCheckOutMin} ${selectedCheckOutAMPM}`;

    // Parse the combined string using moment.js for consistent formatting
    const checkOutDateAndTime = moment(
      dateTimeStringCheckOut,
      "YYYY-MM-DD h:mm A"
    ).toISOString();

    const night = calculateNumberOfNights(
      checkInDateAndTime,
      checkOutDateAndTime,
      hotelDetails
    );

    dispatch(updateSelectedCheckInDateTime(checkInDateAndTime));
    dispatch(updateSelectedCheckOutDateTime(checkOutDateAndTime));
    dispatch(updateNightsCount(night));
    dispatch(updateVisibleBookingDetailsCalendar(false));
    dispatch(updateChangeDateBoolean(!changeDateBoolean));

    message.destroy();
    message.success("Date Updated");
  };

  return (
    <footer
      className="flex flex-col gap-2 my-0 "
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className="upperPart flex justify-between my-4 ">
        <div className="leftPart flex gap-2">
          <div className="text-[#808080] text-[#0.4rem] ">
            <TranslateText textKey={"check-in"} namespace="booking" /> :
          </div>

          <CustomInput
            time={selectedCheckInHours}
            setTime={setSelectedCheckInHours}
            min={1}
            max={12}
          ></CustomInput>
          <span>:</span>
          <CustomInput
            time={selectedCheckInMin}
            setTime={setSelectedCheckInMin}
            min={0}
            max={60}
          ></CustomInput>
          <CustomButton
            active={selectedCheckInAMPM}
            setActive={setSelectedCheckInAMPM}
          ></CustomButton>
        </div>

        <div className="rightPart flex gap-1 ">
          <p className="text-[#808080] text-[#0.75rem]">
            {" "}
            <TranslateText textKey={"check-out"} namespace="booking" />:
          </p>

          <CustomInput
            time={selectedCheckOutHours}
            setTime={setSelectedCheckOutHours}
            min={1}
            max={12}
          ></CustomInput>
          <span>:</span>
          <CustomInput
            time={selectedCheckOutMin}
            setTime={setSelectedCheckOutMin}
            min={0}
            max={60}
          ></CustomInput>
          <CustomButton
            active={selectedCheckOutAMPM}
            setActive={setSelectedCheckOutAMPM}
          ></CustomButton>
        </div>
      </div>

      <hr />

      <div className="lowerPart flex justify-between">
        <div className="flex font-nunito font-[400] text-[0.875rem] text-[#4D4D4D] ">
          <div>
            <span className="font-nunito">
              {selectedCheckInDate
                ? moment(selectedCheckInDate).format("D")
                : moment().format("D")}
              ,
            </span>
            <span className="ml-[2px] font-nunito">
              {selectedCheckInDate
                ? moment(selectedCheckInDate).format("MMM")
                : moment().format("MMM")}
            </span>
            <span className=" ml-[2px] font-nunito">
              {selectedCheckInDate
                ? moment(selectedCheckInDate).format("YYYY")
                : moment().format("YYYY")}
            </span>
          </div>
          <div>&nbsp;-&nbsp;</div>
          <div className="flex ">
            <span className="font-nunito">
              {selectedCheckOutDate
                ? moment(selectedCheckOutDate).add("days").format("D")
                : moment().add(1, "days").format("D")}
              ,
            </span>
            <span className="ml-[3px] font-nunito">
              {selectedCheckOutDate
                ? moment(selectedCheckOutDate).format("MMM")
                : moment().format("MMM")}
            </span>
            <span className=" ml-[3px] font-nunito">
              {selectedCheckOutDate
                ? moment(selectedCheckOutDate).format("YYYY")
                : moment().format("YYYY")}
            </span>
          </div>
        </div>

        <div className="flex font-nunito font-[400] text-[0.875rem] text-[#4D4D4D] ">
          <div className="font-nunito">
            {selectedCheckInHours < 10
              ? `0${selectedCheckInHours}`
              : selectedCheckInHours}
            :
            {selectedCheckInMin < 10
              ? `0${selectedCheckInMin}`
              : selectedCheckInMin}
            {selectedCheckInAMPM}
          </div>

          <div>&nbsp;-&nbsp;</div>

          <div className="font-nunito">
            {selectedCheckOutHours < 10
              ? `0${selectedCheckOutHours}`
              : selectedCheckOutHours}
            :
            {selectedCheckOutMin < 10
              ? `0${selectedCheckOutMin}`
              : selectedCheckOutMin}
            {selectedCheckOutAMPM}
          </div>
        </div>

        <div className="flex gap-3">
          <Button
            className="text-[#007FFF] border border-[#007FFF] rounded-md py-[0.3125rem] px-[0.9375rem]"
            onClick={handleCancel}
          >
            <TranslateText textKey={"close"} namespace="common" />
          </Button>
          <Button
            type="primary"
            className="text-[#FFF] bg-[#007FFF] rounded-md  py-[0.3125rem] px-[0.9375rem]"
            onClick={handleApply}
          >
            <TranslateText textKey={"apply"} namespace="common" />
          </Button>
        </div>
      </div>
    </footer>
  );
};

export default CustomFooter;
