import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, Upload } from "antd";
import { FiUpload } from "react-icons/fi";
import { useReactToPrint } from "react-to-print";
import CircularProgress from "@mui/material/CircularProgress";

import { updateDisplayGuestIdModal } from "../../../reducer/bookingReducer";

import { ReactComponent as Home } from "../../../assets/icons/home.svg";
import { ReactComponent as Close } from "../../../assets/icons/closeCircle.svg";
import { ReactComponent as RemoveImg } from "../../../assets/icons/removeImg.svg";

import GuestIdStructure2 from "../../common/Template/GuestIdStructure2";

const GuestIdModal = ({
  selectedRoomId,
  setSelectedRoomId,
  selectedGuestIdx,
  setSelectedGuestIdx,
  roomToGuest,
  imageArray,
  guestName,
  removeImage,
  response,
  handlePhotoSectionImageUpload,
  handleImageBeforeUpload,
}) => {
  const [print, setPrint] = useState(false);

  const { displayGuestIdModal, bookingDetails, showMainBookingDetailsDrawer } =
    useSelector((store) => store.booking);

  const componentRef = useRef(null);

  const dispatch = useDispatch();

  const printInvoicePDF = useReactToPrint({
    onAfterPrint: () => setPrint(false),
    content: () => componentRef.current,
    documentTitle: "GuestID",
  });

  const printInvoice = async () => {
    setPrint(true);
    setTimeout(() => {
      printInvoicePDF();
      setPrint(false);
    }, 0);
  };

  const handleOnCancel = () => {
    dispatch(updateDisplayGuestIdModal());
    setSelectedRoomId(null);
    setSelectedGuestIdx(null);
  };

  const roomIds = Object.keys(roomToGuest);
  const guestsArray = roomToGuest[selectedRoomId];

  const handleClickNext = () => {
    if (selectedGuestIdx === roomToGuest[selectedRoomId]?.length - 1) {
      setSelectedRoomId(roomIds?.[roomIds?.indexOf(selectedRoomId) + 1]);
      setSelectedGuestIdx(0);
    } else if (true) {
      setSelectedGuestIdx(selectedGuestIdx + 1);
    }
  };

  const handleClickBack = () => {
    if (selectedGuestIdx === 0) {
      setSelectedRoomId(roomIds?.[roomIds?.indexOf(selectedRoomId) - 1]);
      setSelectedGuestIdx(0);
    } else if (true) {
      setSelectedGuestIdx(selectedGuestIdx - 1);
    }
  };

  const isFirstGuest = () =>
    roomIds?.indexOf(selectedRoomId) === 0 && selectedGuestIdx === 0;

  const isLastGuest = () =>
    roomIds?.indexOf(selectedRoomId) === roomIds?.length - 1 &&
    selectedGuestIdx === roomToGuest?.[selectedRoomId]?.length - 1;

  const getRoomName = (roomId) =>
    response?.find(({ id }) => id === roomId)?.name || "";

  return (
    <>
      <Modal
        sx={{ zIndex: 100000 }}
        open={displayGuestIdModal}
        centered
        title=""
        onCancel={handleOnCancel}
        footer={null}
        closeIcon={null}
        width={"880px"}
        className="custom-modal"
        zIndex={100000}
      >
        <main className="h-[585px] w-full relative">
          <section className="w-full px-[24px] pt-[36px]">
            <div className="w-full flex justify-end">
              <Close
                className="cursor-pointer mb-[34px]"
                onClick={handleOnCancel}
              />
            </div>
            <div className="w-full flex gap-[24px] mb-[34px]">
              <div className="h-[60px] w-[125px] rounded-[12px] bg-[#8F9BB31A] flex items-center justify-center shrink-0">
                <div className="flex items-center gap-[6px]">
                  <Home />
                  <span className="font-outfit font-[500] text-[16px] leading-[20.16px] text-[#222B45]">
                    {getRoomName(selectedRoomId)}
                  </span>
                </div>
              </div>
              <input
                className="w-full h-[56px] border-[1px] border-[#EDF1F7] rounded-[12px] outline-none px-[15px] font-outfit text-[16px] leading-[24px] tracking-[0.5px] text-[#616161] cursor-default"
                placeholder="Guest name"
                value={guestName}
                readOnly={true}
                // value={guestName}
                // readOnly={
                //   Boolean(bookingDetails?.name) &&
                //   Boolean(guestName) &&
                //   bookingDetails?.name === guestName
                // }
                // onChange={(e) =>
                //   handleChangeGuestName(roomId, guestIdx, e.target.value)
                // }
              />
              <Upload
                multiple={true}
                customRequest={(item) =>
                  handlePhotoSectionImageUpload(
                    item,
                    selectedRoomId,
                    selectedGuestIdx
                  )
                }
                beforeUpload={(file) => handleImageBeforeUpload(file, 10)}
                showUploadList={false}
                accept=".png,.jpeg,"
                className="w-[137px] cursor-pointer"
              >
                <div
                  className={`w-[137px] h-[60px] rounded-[12px] bg-[#E9F4FF] flex items-center justify-center`}
                >
                  <div className="flex items-center gap-[6px]">
                    <FiUpload color="#007FFF" />
                    <span className="font-outfit font-[500] text-[14px] leading-[17.64px] text-[#007FFF]">
                      Upload ID
                    </span>
                  </div>
                </div>
              </Upload>
            </div>
            <div className="w-full h-[277.67px] flex items-center justify-center gap-[24px]">
              {imageArray?.slice(0, 2)?.map((url, idx) => {
                return (
                  <div
                    key={url}
                    className={`flex grow-0 flex-col gap-[24px] items-start justify-start ${
                      imageArray.length > 1 ? "items-start" : "items-center"
                    } w-full h-full rounded-[8px]`}
                  >
                    <div
                      className={`${
                        imageArray.length > 1 ? "w-full" : "w-1/2"
                      } h-full relative flex justify-center`}
                    >
                      <img
                        src={url}
                        alt="Guest Id"
                        id="viewer"
                        className="w-full h-full rounded-[8px]"
                      />
                      <div
                        className="top-[-6px] right-[3px] absolute z-[10] cursor-pointer"
                        onClick={() =>
                          removeImage(url, selectedRoomId, selectedGuestIdx)
                        }
                      >
                        <RemoveImg
                          className="cursor-pointer"
                          width="20px"
                          height="20px"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              {imageArray?.length === 0 && (
                <p className="font-outfit font-[500] text-[16px] leading-[20px] text-[#222222]">
                  No Guest ID uploaded
                </p>
              )}
            </div>
          </section>

          <footer className="w-full h-[96px] absolute bottom-0 flex items-center justify-between px-[24px] custom-shadow">
            <button
              className="h-[48px] w-[175px] rounded-[8px] flex items-center justify-center bg-[#007fff]"
              onClick={printInvoice}
              disabled={print}
            >
              {print ? (
                <CircularProgress style={{ color: "#007FFF" }} size={21} />
              ) : (
                <span className="font-outfit font-[500] text-[16px] leading-[24px] text-[#fff]">
                  Print
                </span>
              )}
            </button>
            <span></span>
            <div className="flex gap-[10px]">
              <button
                className={`w-[175px] h-[48px] rounded-[8px] flex items-center justify-center ${
                  isFirstGuest() ? "border-[#8F9BB3]" : "border-[#007fff]"
                } border-[1px]`}
                onClick={handleClickBack}
                disabled={isFirstGuest()}
              >
                <span
                  className={`font-outfit font-[500] text-[16px] leading-[24px] ${
                    isFirstGuest() ? "text-[#8F9BB3]" : "text-[#007FFF]"
                  } `}
                >
                  Back
                </span>
              </button>

              <button
                className={`w-[175px] h-[48px] rounded-[8px] flex items-center justify-center border-[1px] ${
                  isLastGuest() ? "border-[#8F9BB3]" : "border-[#007FFF]"
                } `}
                onClick={handleClickNext}
                disabled={isLastGuest()}
              >
                <span
                  className={`font-outfit font-[500] text-[16px] leading-[24px] ${
                    isLastGuest() ? "text-[#8F9BB3]" : "text-[#007FFF]"
                  }`}
                >
                  Next
                </span>
              </button>
            </div>
          </footer>
        </main>
      </Modal>

      {print && (
        <GuestIdStructure2
          val={componentRef}
          print={true}
          download={false}
          roomToGuest={roomToGuest}
          response={response}
          showMainBookingDetailsDrawer={showMainBookingDetailsDrawer}
          bookingDetails={bookingDetails}
          selectedRoomId={selectedRoomId}
          selectedGuestIdx={selectedGuestIdx}
        />
      )}
    </>
  );
};

export default GuestIdModal;
