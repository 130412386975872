import { Collapse } from "antd";
import React from "react";
import Dates from "./Dates";
import Index from "./index";
import { CaretRightOutlined } from "@ant-design/icons";

function UpdateRoom({ setExpanded, expanded }) {
  return expanded ? (
    <Collapse
      bordered={false}
      activeKey={expanded ? ["1"] : []}
      expandIcon={({ isActive }) => (
        <div className="w-[20px] h-[20px] bg-[#2F80ED1A] flex items-center justify-center rounded-full">
          <CaretRightOutlined
            onClick={() => setExpanded(!expanded)}
            style={{ color: "#2F80ED" }}
            rotate={isActive ? 90 : -90}
          />
        </div>
      )}
    >
      <Collapse.Panel
        header={
          <div className="w-full flex items-center justify-evenly">
            <span className="w-[18%] font-semibold text-[18px] text-[#000000B2]">
              Update Rooms
            </span>
            <hr className="flex justify-start w-[90%] h-[0px] border border-slate-100 mr-9" />
            <div className="mr-8">{/* <Dates /> */}</div>
          </div>
        }
        key="1"
        collapsible="icon"
        style={{ alignItems: "center" }}
      >
        <Index />
      </Collapse.Panel>
    </Collapse>
  ) : (
    <div></div>
  );
}

export default UpdateRoom;
