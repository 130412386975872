import React from "react";
import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateShowTakePaymentDrawer,
  postPaymentAndUpdateInvoiceDetails,
} from "../../../reducer/bookingReducer";

import CloseIcon from "../../../assets/images/svgs/CloseIcon.svg";
import { useEffect } from "react";
import { message } from "antd";
import CustomTextField from "../../common/InputField/Input";

export default function TakePaymentDrawer() {
  const { hotelDetails } = useSelector((store) => store.login);
  const {
    showMainBookingDetailsDrawer,
    showTakePaymentDrawer,
    paymentDetails,
    invoiceDetails,
  } = useSelector((store) => store.booking);
  const { paymentModesResponse } = useSelector((store) => store.payments);

  const dispatch = useDispatch();

  const [amount, setAmount] = React.useState(Boolean);
  const [paymentMode, setPaymentMode] = React.useState("cash");
  const [transactionNo, setTransactionNo] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [methodToPartyId, setMethodToPartyId] = useState({});
  const [paymentModePartyId, setPaymentModePartyId] = useState("");

  useEffect(() => {
    if (Array.isArray(paymentModesResponse) && paymentModesResponse?.length) {
      let updatedObj = {};
      paymentModesResponse?.forEach((mode) => {
        const { method, partyId } = mode;
        updatedObj[method] = partyId;
      });
      setMethodToPartyId(updatedObj);
      setPaymentModePartyId(updatedObj["cash"]);
    }
  }, [paymentModesResponse]);

  const addPayment = async () => {
    if (Boolean(amount)) {
      setLoading(true);

      let roomInvoiceId = Array.isArray(invoiceDetails)
        ? invoiceDetails
            ?.filter((Obj) => Obj?.invoiceType === "roomBill")
            ?.map((Obj) => Obj?.id)?.[0]
        : "";

      const paymentPayload = {
        amount: amount,
        bookingId: showMainBookingDetailsDrawer?.bookingId,
        hotelId: hotelDetails?.id,
        method: paymentMode,
        transactionNo: transactionNo,
        invoiceId: roomInvoiceId,
        paymentModePartyId,
      };

      const invoiceIdArray = Array.isArray(invoiceDetails)
        ? invoiceDetails?.map((Obj) => Obj?.id)?.filter((Obj) => Boolean(Obj))
        : [];

      const invoicePayload = {
        hotelId: hotelDetails?.id,
        invoiceIds: invoiceIdArray,
      };

      const payload = {
        paymentPayload: paymentPayload,
        invoicePayload: invoicePayload,
      };

      await dispatch(postPaymentAndUpdateInvoiceDetails(payload));
      await dispatch(updateShowTakePaymentDrawer());
      await setLoading(false);
    } else {
      message.info("Please enter amount");
    }
  };

  useEffect(() => {
    if (Boolean(invoiceDetails && invoiceDetails?.length > 0)) {
      const localPayemnts = paymentDetails
        ?.map((item) => item.amount)
        ?.filter((Obj) => Boolean(Obj))
        ?.filter((Obj) => Obj > 0)
        ?.reduce((total, num) => total + num, 0);

      const localRefund = paymentDetails
        ?.map((item) => item.amount)
        ?.filter((Obj) => Boolean(Obj))
        ?.filter((Obj) => Obj < 0)
        ?.reduce((total, num) => total + -num, 0);

      let totalAmount = invoiceDetails
        ?.map((item) => item.total)
        ?.filter((Obj) => Boolean(Obj))
        ?.reduce((total, num) => total + num, 0);

      let balanceDue = totalAmount - localPayemnts + localRefund;

      if (balanceDue >= 0) {
        let updatedBalanceDue = balanceDue?.toFixed(0);
        setAmount(updatedBalanceDue);
      } else setAmount("");
    }
  }, [paymentDetails, invoiceDetails]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "800px",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",
          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",
          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={showTakePaymentDrawer}
      onClose={
        loading
          ? undefined
          : () => {
              dispatch(updateShowTakePaymentDrawer());
            }
      }
    >
      <div className="flex flex-col mx-3 gap-5 items-start my-2  ">
        <div className="flex w-[100%] justify-between items-center px-3 py-3 ">
          <h2 className="font-[500] text-[1rem] text-[#4D4D4D] font-nunito">
            Take Payment
          </h2>

          <div
            onClick={
              loading
                ? undefined
                : () => dispatch(updateShowTakePaymentDrawer())
            }
            className="cursor-pointer"
          >
            <img src={CloseIcon} alt="" />
          </div>
        </div>

        <div className="flex flex-col mx-5 mb-3 gap-3 items-center w-[100%]">
          <div className="flex  w-[90%] mx-5 gap-7 justify-center">
            <div className="w-[50%]">
              <CustomTextField
                label="Amount"
                isManadatory={true}
                value={amount}
                onChange={(value) => setAmount(value)}
                type="text"
              />
            </div>

            <FormControl sx={{ width: "45%", color: "#0000004D" }} size="small">
              <InputLabel
                id="paymentMode"
                sx={{ color: "#0000004D", fontSize: "15px" }}
              >
                Payment Mode
              </InputLabel>

              <Select
                labelId="SelectPaymentLabel"
                id="selectPayment"
                label="Payment Mode "
                value={Boolean(paymentMode) ? paymentMode : ""}
                onChange={(e) => {
                  setPaymentMode(e.target.value);
                  setPaymentModePartyId(methodToPartyId[e.target.value]);
                }}
                sx={{
                  borderRadius: "6px",
                  fontSize: "15px",
                  "& .MuiSelect-icon": {
                    color: "#2F80ED",
                  },
                }}
              >
                {paymentModesResponse?.map((mode) => {
                  const { method, partyId, paymentMode } = mode;
                  return (
                    <MenuItem
                      value={method}
                      sx={{
                        fontSize: "14px",
                        color: "#00000098",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                      }}
                    >
                      {paymentMode}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div className="w-[89%]">
            <CustomTextField
              label={paymentMode === "cash" ? "Cashier" : "Transaction No"}
              isManadatory={false}
              value={transactionNo}
              onChange={(value) => setTransactionNo(value)}
              type="text"
            />
          </div>
        </div>
      </div>

      <button
        className={`bg-[#007FFF] capitalize text-[#FFF] w-full h-[3rem] `}
        onClick={() => addPayment()}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <div>Take Payment</div>
        )}
      </button>
    </Drawer>
  );
}
