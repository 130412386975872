import axios from "axios";
import { authedAxios } from "../utils/customHttpHeader";

const saveAllRoomType = async (payload) => {
  const response = await authedAxios().post("/roomType", payload);
  return response;
};

const putRoomTypeService = async (payload) => {

  const response = await authedAxios().put("/roomType", payload);
  return response;
};

const deleteRoomTypeService = async (payload) => {
  const response = await authedAxios().delete(
    `/roomType/${payload?.hotelId}/${payload?.roomTypeId}`
  );
  return response;
};

export { saveAllRoomType, putRoomTypeService, deleteRoomTypeService };
