import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { customerDetailsById } from "../../../services/customer";
import { getTables } from "../../../services/hotel";

import {
  getNetSalesAndDiscountService,
  getRefundService,
  getOrdersCountService,
  getOrderSourcesService,
  getItemsCountService,
  getPaymentsByTypeService,
  getRepeatingGuestsService,
} from "../../../services/posDashboard";
import { message } from "antd";
import { addTables, updateTables, deleteTables } from "../../../services/table";

export const getCustomerById = createAsyncThunk(
  "getCustomerById",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await customerDetailsById(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getAllTables = createAsyncThunk(
  "getAllTables",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getTables(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// net sales and discount thunk

export const getNetSalesAndDiscount = createAsyncThunk(
  "getNetSalesAndDiscount",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getNetSalesAndDiscountService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// repeating guests thunk
export const getRepeatingGuests = createAsyncThunk(
  "getRepeatingGuests",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getRepeatingGuestsService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// refund thunk
export const getRefund = createAsyncThunk(
  "getRefund",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getRefundService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// orders count thunk

export const getOrdersCount = createAsyncThunk(
  "getOrdersCount",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getOrdersCountService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// order sources thunk
export const getOrderSources = createAsyncThunk(
  "getOrderSources",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getOrderSourcesService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// items count thunk
export const getItemsCount = createAsyncThunk(
  "getItemsCount",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getItemsCountService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// payments by type thunk
export const getPaymentsByType = createAsyncThunk(
  "getPaymentsByType",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getPaymentsByTypeService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// create table
export const createTables = createAsyncThunk(
  "createTables",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await addTables(payload);

      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// update table
export const updateTablesData = createAsyncThunk(
  "updateTablesData",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await updateTables(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const deleteTable = createAsyncThunk(
  "deleteTable",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await deleteTables(payload);
      return fulfillWithValue(payload.id);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  items: [],
  selectedItem: {}, // selected item  {"id":count}
  tableOrder: {},

  // customer details api
  loadingCustomerApi: false,
  customerDetails: {},
  customerApiError: false,

  // table api
  loadingTableData: false,
  allTables: [],
  tableDataApiError: false,

  updateTableDataLoading: false,
  updateTableError: false,

  // room api
  loadingAllRoomData: false,
  allRooms: [], // room id with customer id
  allRoomsApiError: false,
  numOfRoomDetails: [],
  selectedSteward: [],
  kotModal: false,

  selectedOrderItem: {}, // whole object item select for update
  selectedOrderItemCountMap: {}, // selected item for modify [only price break up portion]

  searchItemText: "",
  filteredFoodType: "",

  priceBreakup: [], // after order placed , if seller want to modify order

  activeTab: "open", // all order page

  selectedRider: "",

  deliveryPickup: false,

  // transfer table
  isTransferTableActive: false,
  selectedTableFrom: "",
  selectedTableTo: "",
  tranferTableInvoiceId: "", // define which invoice will be updated
  orderSourcesView: "Amount", //pos dashboard
  bestSellerView: "Items", //pos dashboard
  leastSellerView: "Items", //pos dashboard
  selectedTimeline: "Today", //pos

  // net sales and discount api
  netSalesAndDiscountLoading: false,
  netSalesAndDiscountData: {},
  netSalesAndDiscountError: null,

  // repeating guests api
  repeatingGuestsLoading: false,
  repeatingGuestsData: 0,
  repeatingGuestsError: null,

  // refund guests api
  refundLoading: false,
  refundData: 0,
  refundError: null,

  // orders count api
  ordersCountLoading: false,
  ordersCountData: 0,
  ordersCountError: null,

  // order sources api
  orderSourcesLoading: false,
  orderSourcesData: {},
  orderSourcesError: null,

  // items count api
  itemsCountLoading: false,
  itemsCountData: {},
  itemsCountError: null,

  // payments by type api
  paymentsByTypeLoading: false,
  paymentsByTypeData: {},
  paymentsByTypeError: null,

  // exclude gst

  excludeGST: true,
  activePosService: "", // like restaurant,room,pickup,delivery

  displayAttachPartyDrawer: false,
  displayTakePaymentDrawer: false,
  displayCloseOrderDrawer: false,
  displayDiscountDrawer: false,
};

const posSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_5: () => initialState,
    createTableOrder: (state, action) => {
      const details = action.payload;
      state.tableOrder = details;
    },
    addItems: (state, action) => {
      const items = action.payload;
      state.items = items;
    },

    updateExcludeGST: (state, action) => {
      state.excludeGST = action.payload;
    },

    updateSelectedItem: (state, action) => {
      let id = action.payload.id;
      let itemCountType = action.payload.itemCountType;
      let addonCountType = action.payload.addonCountType;
      let addOnItem = action.payload.addOnItem;
      let selectedItemObj = state.selectedItem;
      let customValue = action?.payload?.count;

      if (selectedItemObj[id] !== undefined) {
        let itemCount = Boolean(selectedItemObj[id].count)
          ? selectedItemObj[id].count
          : 0;

        // if item  count type increase

        if (itemCountType === "increment") {
          itemCount++;
        } else if (itemCountType === "decrement") {
          // unit item count type is 0
          if (itemCount >= 1) {
            itemCount--;
          }
        } else if (itemCountType === "custom") {
          itemCount = customValue;
        }
        // // if addon exist

        if (Boolean(addOnItem)) {
          let addOnCount = Boolean(selectedItemObj[id][addOnItem])
            ? selectedItemObj[id][addOnItem]
            : 0;

          // if item  count type increase
          if (addonCountType === "increment") {
            addOnCount++;
          } else if (addonCountType === "decrement") {
            // unit item count type is 0
            if (addOnCount >= 1) {
              addOnCount--;
            }
          } else if (addonCountType === "custom") {
            addOnCount = customValue;
          }
          selectedItemObj[id] = {
            ...selectedItemObj[id],
            count: itemCount,
            [addOnItem]: addOnCount,
          };
        } else {
          // if addon not exist
          selectedItemObj[id] = {
            ...selectedItemObj[id],
            count: itemCount,
          };
        }

        // if item count 0 , remove item from obect

        if (
          selectedItemObj[id].count === 0 ||
          selectedItemObj[id].count === ""
        ) {
          delete selectedItemObj[id];
        }
      } else {
        if (itemCountType === "increment") {
          selectedItemObj[id] = {
            count: 1,
          };
        } else if (itemCountType === "decrement") {
          selectedItemObj[id] = {
            count: 0,
          };
        } else if (itemCountType === "custom") {
          selectedItemObj[id] = {
            count: customValue,
          };
        }
      }
      state.selectedItem = selectedItemObj;
    },

    updateDeliveryPickup: (state, action) => {
      state.deliveryPickup = action.payload;
    },

    updateSelectedRider: (state, action) => {
      state.selectedRider = action.payload;
    },
    updatePriceBreakUp: (state, action) => {
      state.priceBreakup = action.payload;
    },
    updateSelectedOderItem: (state, action) => {
      state.selectedOrderItem = action.payload;
    },
    updateItemSearch: (state, action) => {
      state.searchItemText = action.payload;
    },

    updateFilteredFoodType: (state, action) => {
      if (state.filteredFoodType === action.payload) {
        state.filteredFoodType = "";
      } else {
        state.filteredFoodType = action.payload;
      }
    },

    updateSelectedOrderItemCountMap: (state, action) => {
      let type = action.payload.type;

      if (type === "whole") {
        state.selectedOrderItemCountMap = action?.payload?.data;
      } else {
        let id = action.payload.id;
        let itemCountType = action.payload.itemCountType;
        let addonCountType = action.payload.addonCountType;
        let addOnItem = action.payload.addOnItem;
        let selectedItemObj = state.selectedOrderItemCountMap;
        let customValue = action?.payload?.count;

        if (selectedItemObj[id] !== undefined) {
          let itemCount = Boolean(selectedItemObj[id].count)
            ? selectedItemObj[id].count
            : 0;

          // if item  count type increase

          if (itemCountType === "increment") {
            itemCount++;
          } else if (itemCountType === "decrement") {
            // unit item count type is 0
            if (itemCount >= 1) {
              itemCount--;
            }
          } else if (itemCountType === "custom") {
            itemCount = customValue;
          }
          // // if addon exist

          if (Boolean(addOnItem)) {
            let addOnCount = Boolean(selectedItemObj[id]["addOns"][addOnItem])
              ? selectedItemObj[id]["addOns"][addOnItem]
              : 0;

            // if item  count type increase
            if (addonCountType === "increment") {
              addOnCount++;
            } else if (addonCountType === "decrement") {
              // unit item count type is 1
              if (addOnCount >= 1) {
                addOnCount--;
              }
            }

            selectedItemObj[id] = {
              ...selectedItemObj[id],
              count: itemCount,
              addOns: {
                [addOnItem]: addOnCount,
              },
            };
          } else {
            // if addon not exist
            selectedItemObj[id] = {
              ...selectedItemObj[id],
              count: itemCount,
            };
          }

          // if item count 0 , remove item from obect

          if (selectedItemObj[id].count === 0 && itemCountType !== "custom") {
            // delete selectedItemObj[id];
          }
        } else {
          if (itemCountType === "increment") {
            selectedItemObj[id] = {
              count: 1,
            };
          } else if (itemCountType === "custom") {
            selectedItemObj[id] = {
              count: customValue,
            };
          }
        }

        state.selectedOrderItemCountMap = selectedItemObj;
      }
    },

    updateSelectedSteward: (state, action) => {
      state.selectedSteward = [action.payload];
    },

    clearSelectedSteward: (state) => {
      state.selectedSteward = [];
    },

    updateInstruction: (state, action) => {
      let selectedItemObj = state.selectedItem;

      if (selectedItemObj[action.payload.id] !== undefined) {
        selectedItemObj[action.payload.id] = {
          ...selectedItemObj[action.payload.id],
          instruction: action.payload.instruction,
        };
      }
      state.selectedItem = selectedItemObj;
    },

    updatedTables: (state, action) => {
      state.loadingTableData = action.payload.loading;
      state.allTables = action.payload.data;
      state.tableDataApiError = action.payload.apiError;
    },

    resetCustomerDetails: (state) => {
      state.loadingCustomerApi = false;
      state.customerDetails = {};
      state.customerApiError = false;
    },

    updateCustomerDetails: (state, action) => {
      state.loadingCustomerApi = false;
      state.customerDetails = Boolean(action.payload) ? action.payload : {};
      state.customerApiError = false;
    },

    updatedHotelRooms: (state, action) => {
      state.loadingAllRoomData = action.payload.loading;
      state.allRooms = action.payload.data;
      state.allRoomsApiError = action.payload.apiError;
    },

    resetPos: (state, action) => {
      state.selectedItem = {};
    },

    updateKotModal: (state, action) => {
      state.kotModal = action.payload;
    },

    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
      state.pageNumber = 0;
    },

    updateTransferTableStatus: (state, action) => {
      state.isTransferTableActive = action.payload;

      if (state.selectedTableFrom) {
        state.selectedTableFrom = "";
      }

      if (state.selectedTableTo) {
        state.selectedTableTo = "";
      }
    },
    updateSelectedTableToTransfer: (state, action) => {
      if (action.payload.isTableFrom) {
        if (state.selectedTableFrom == action.payload.selectedTableFrom) {
          // if same table click twice, table gets deselected..
          state.selectedTableFrom = "";
          state.tranferTableInvoiceId = "";
        } else {
          state.selectedTableFrom = action.payload.selectedTableFrom;
          state.tranferTableInvoiceId = action.payload.tranferTableInvoiceId;
        }
      } else {
        state.selectedTableTo = action.payload.selectedTableTo;
      }
    },
    refreshTransferTable: (state, action) => {
      state.isTransferTableActive = false;
      state.selectedTableFrom = "";
      state.selectedTableTo = "";
      state.tranferTableInvoiceId = ""; // define which invoice will be updated
    },
    updateOrderSourcesView: (state, action) => {
      state.orderSourcesView = action.payload;
    },
    updateBestSellerView: (state, action) => {
      state.bestSellerView = action.payload;
    },
    updateLeastSellerView: (state, action) => {
      state.leastSellerView = action.payload;
    },
    updateSelectedTimeline: (state, action) => {
      state.selectedTimeline = action.payload;
    },

    updateActivePosService: (state, action) => {
      state.activePosService = action.payload;
    },
    updateDisplayAttachPartyDrawer: (state, action) => {
      state.displayAttachPartyDrawer = action.payload;
    },
    updateDisplayTakePaymentDrawer: (state, action) => {
      state.displayTakePaymentDrawer = action.payload;
    },
    updateDisplayCloseOrderDrawer: (state, action) => {
      state.displayCloseOrderDrawer = action.payload;
    },
    updateDisplayDiscountDrawer: (state, action) => {
      state.displayDiscountDrawer = action.payload;
    },
    updateNumOfRoomDetails: (state, action) => {
      state.numOfRoomDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerById.pending, (state, action) => {
      state.loadingCustomerApi = true;
      state.customerDetails = {};
      state.customerApiError = false;
    });
    builder.addCase(getCustomerById.fulfilled, (state, action) => {
      state.loadingCustomerApi = false;
      state.customerDetails = action.payload;
      state.customerApiError = false;
    });
    builder.addCase(getCustomerById.rejected, (state, action) => {
      state.loadingCustomerApi = false;
      state.customerDetails = {};
      state.customerApiError = true;
    });

    builder.addCase(getAllTables.pending, (state, action) => {
      state.loadingTableData = true;
      state.allTables = [];
      state.tableDataApiError = false;
    });
    builder.addCase(getAllTables.fulfilled, (state, action) => {
      state.loadingTableData = false;
      state.allTables = action.payload;
      state.tableDataApiError = false;
    });
    builder.addCase(getAllTables.rejected, (state, action) => {
      state.loadingTableData = false;
      state.allTables = [];
      state.tableDataApiError = true;
    });

    builder.addCase(createTables.pending, (state, action) => {
      state.loadingTableData = true;
      state.tableDataApiError = false;
    });
    builder.addCase(createTables.fulfilled, (state, action) => {
      state.loadingTableData = false;
      // state.allTablesData.push(action.payload);
      message.success("Table has been added..");
      state.allTables = [...state.allTables, action.payload];
      state.tableDataApiError = false;
    });
    builder.addCase(createTables.rejected, (state, action) => {
      state.loadingTableData = false;
      state.tableDataApiError = true;
    });

    builder.addCase(updateTablesData.pending, (state, action) => {
      state.updateTableDataLoading = true;
      state.updateApiError = false;
    });
    builder.addCase(updateTablesData.fulfilled, (state, action) => {
      // allTablesData

      message.success("Table has been updated..");
      state.updateTableDataLoading = false;
      state.allTables = state.allTables?.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      state.updateApiError = false;
    });
    builder.addCase(updateTablesData.rejected, (state, action) => {
      message.error("Something went wrong!");
      state.updateTableDataLoading = false;
      state.updateApiError = true;
    });

    builder.addCase(deleteTable.pending, (state, action) => {
      state.updateTableDataLoading = true;
      state.updateApiError = false;
    });
    builder.addCase(deleteTable.fulfilled, (state, action) => {
      // allTablesData
      message.success("Table has been Deleted..");
      state.updateTableDataLoading = false;
      state.allTables = state.allTables?.filter(
        (item) => item.id !== action.payload
      );
      state.updateApiError = false;
    });
    builder.addCase(deleteTable.rejected, (state, action) => {
      message.error("Something went wrong!");
      state.updateTableDataLoading = false;
      state.updateApiError = true;
    });

    builder.addCase(getNetSalesAndDiscount.pending, (state, action) => {
      state.netSalesAndDiscountLoading = true;
      state.netSalesAndDiscountData = {};
      state.netSalesAndDiscountError = false;
    });
    builder.addCase(getNetSalesAndDiscount.fulfilled, (state, action) => {
      state.netSalesAndDiscountLoading = false;
      state.netSalesAndDiscountData = action.payload;
      state.netSalesAndDiscountError = false;
    });
    builder.addCase(getNetSalesAndDiscount.rejected, (state, action) => {
      state.netSalesAndDiscountLoading = false;
      state.netSalesAndDiscountData = {};
      state.netSalesAndDiscountError = true;
    });
    builder.addCase(getRepeatingGuests.pending, (state, action) => {
      state.repeatingGuestsLoading = true;
      state.repeatingGuestsData = null;
      state.repeatingGuestsError = false;
    });
    builder.addCase(getRepeatingGuests.fulfilled, (state, action) => {
      state.repeatingGuestsLoading = false;
      state.repeatingGuestsData = action.payload;
      state.repeatingGuestsError = false;
    });
    builder.addCase(getRepeatingGuests.rejected, (state, action) => {
      message.error(action.payload);
      state.repeatingGuestsLoading = false;
      state.repeatingGuestsData = null;
      state.repeatingGuestsError = true;
    });
    builder.addCase(getRefund.pending, (state, action) => {
      state.refundLoading = true;
      state.refundData = null;
      state.refundError = false;
    });
    builder.addCase(getRefund.fulfilled, (state, action) => {
      state.refundLoading = false;
      state.refundData = action.payload;
      state.refundError = false;
    });
    builder.addCase(getRefund.rejected, (state, action) => {
      state.refundLoading = false;
      state.refundData = null;
      state.refundError = true;
    });
    builder.addCase(getOrdersCount.pending, (state, action) => {
      state.ordersCountLoading = true;
      state.ordersCountData = null;
      state.ordersCountError = false;
    });
    builder.addCase(getOrdersCount.fulfilled, (state, action) => {
      state.ordersCountLoading = false;
      state.ordersCountData = action.payload;
      state.ordersCountError = false;
    });
    builder.addCase(getOrdersCount.rejected, (state, action) => {
      state.ordersCountLoading = false;
      state.ordersCountData = null;
      state.ordersCountError = true;
    });
    builder.addCase(getOrderSources.pending, (state, action) => {
      state.orderSourcesLoading = true;
      state.orderSourcesData = {};
      state.orderSourcesError = false;
    });
    builder.addCase(getOrderSources.fulfilled, (state, action) => {
      state.orderSourcesLoading = false;
      state.orderSourcesData = action.payload;
      state.orderSourcesError = false;
    });
    builder.addCase(getOrderSources.rejected, (state, action) => {
      state.orderSourcesLoading = false;
      state.orderSourcesData = {};
      state.orderSourcesError = true;
    });
    builder.addCase(getItemsCount.pending, (state, action) => {
      state.itemsCountLoading = true;
      state.itemsCountData = {};
      state.itemsCountError = false;
    });
    builder.addCase(getItemsCount.fulfilled, (state, action) => {
      state.itemsCountLoading = false;
      state.itemsCountData = action.payload;
      state.itemsCountError = false;
    });
    builder.addCase(getItemsCount.rejected, (state, action) => {
      state.itemsCountLoading = false;
      state.itemsCountData = {};
      state.itemsCountError = true;
    });
    builder.addCase(getPaymentsByType.pending, (state, action) => {
      state.paymentsByTypeLoading = true;
      state.paymentsByTypeData = {};
      state.paymentsByTypeError = false;
    });
    builder.addCase(getPaymentsByType.fulfilled, (state, action) => {
      state.paymentsByTypeLoading = false;
      state.paymentsByTypeData = action.payload;
      state.paymentsByTypeError = false;
    });
    builder.addCase(getPaymentsByType.rejected, (state, action) => {
      state.paymentsByTypeLoading = false;
      state.paymentsByTypeData = {};
      state.paymentsByTypeError = true;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_5,
  addItems,
  createTableOrder,
  updatedTables,
  updatedHotelRooms,
  updateSelectedItem,
  resetCustomerDetails,
  updateInstruction,
  updateCustomerDetails,
  updateDeliveryPickup,
  resetPos,
  updatePriceBreakUp,
  updateSelectedSteward,
  clearSelectedSteward,
  updateKotModal,
  updateSelectedOderItem,
  updateSelectedOrderItemCountMap,
  updateItemSearch,
  updateFilteredFoodType,
  updateActiveTab,
  updateSelectedRider,
  updateTransferTableStatus,
  updateSelectedTableToTransfer,
  refreshTransferTable,
  updateOrderSourcesView,
  updateBestSellerView,
  updateLeastSellerView,
  updateSelectedTimeline,
  updateExcludeGST,
  updateActivePosService,
  updateDisplayAttachPartyDrawer,
  updateDisplayTakePaymentDrawer,
  updateDisplayCloseOrderDrawer,
  updateDisplayDiscountDrawer,
  updateNumOfRoomDetails,
} = posSlice.actions;

export default posSlice.reducer;
