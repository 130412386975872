import React, { useCallback, useState } from "react";
import { Upload, message } from "antd";
import verifyIcon from "../../../assetsV2/icons/Group 18042.png";

import { TiTimes } from "react-icons/ti";
import { UPLOADED_IMAGE_SIZE } from "../../../utils/helper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { uploadFiles } from "../../../services/scanUpload";
import { CircularProgress } from "@mui/material";
import { TranslateText } from "../../../utils/translation";
const UploadId = ({ id, setId, custom }) => {
  const [uploadedFile, setUploadedFiles] = useState({});
  const [loading, setLoading] = useState(false);

  const handleBeforeUpload = (file) => {
    if (file.size > UPLOADED_IMAGE_SIZE) {
      message.info("File size must be lesser than 10 MB");
      return false;
    }
    return true;
  };

  const handleChange = useCallback((info) => {
    let File = info.file;

    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }, []);

  const uploadFile = async (options) => {
    const { onSuccess, onError, file } = options;
    const frmData = new FormData();
    frmData.append("file", file);
    setLoading(true);

    try {
      const getFileObject = (progress) => {
        return {
          name: file.name,
          uid: file.uid,
          progress: progress,
        };
      };

      if (file.size <= UPLOADED_IMAGE_SIZE) {
        let response = await uploadFiles(frmData);

        if (Boolean(response)) {
          const prevData = Boolean(id && Array.isArray(id)) ? [...id] : [];
          prevData.push(response);
          setId(prevData);
          setLoading(false);
          message.success("Id uploaded successfully");
        }
      } else {
        message.info("File size must be lesser than 10 MB");
      }
    } catch (error) {
      message.error("something went wrong!");
    }
    setLoading(false);
  };

  const handleRemoveUrl = (url, urlIndex) => {
    // remove file from uploadedFile state object and update state
    const updatedFilesObj = {};
    for (let i in uploadedFile) {
      if (url !== uploadedFile[i].name) {
        updatedFilesObj[i] = uploadedFile[i];
      }
    }
    setUploadedFiles(updatedFilesObj);

    let idCopy = [...id];

    if (idCopy?.indexOf(url) > -1) {
      let index = idCopy.indexOf(url);
      idCopy.splice(index, 1);
      setId(idCopy);
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="border rounded-lg py-0 border-gray-300 w-[100%] flex justify-center items-center px-2 
        h-[2rem]"
        >
          <CircularProgress size={18} />
        </div>
      ) : (
        <>
          {!Boolean(id?.length > 0) ? (
            <Upload
              multiple={true}
              customRequest={uploadFile}
              onChange={handleChange}
              beforeUpload={handleBeforeUpload}
              showUploadList={false}
              accept=".png,.jpeg"
            >
              {!custom ? (
                <div className="text-[0.875rem] font-[500] font-nunito text-[#007FFF] cursor-pointer">
                  <TranslateText textKey={"upload_id"} namespace="booking" />
                </div>
              ) : (
                <div
                  className=" h-[2.1rem] flex justify-center items-center border-[1px] border-gray-400  text-[0.875rem] font-[400] leading-[1.3125rem] cursor-pointer text-[#2F80ED] 
            font-[Poppins] rounded-lg text-center"
                >
                  <TranslateText textKey={"attach_id"} namespace="booking" />
                </div>
              )}
            </Upload>
          ) : (
            <div className="flex flex-wrap w-[100%]">
              <div className="flex  border rounded-lg py-0 border-gray-300 w-[100%]">
                <div className=" items-center  cursor-pointer relative w-[100%]">
                  <div className="flex text-[#404040] ">
                    <h1 className="flex  absolute top-[-8px] left-2 bg-[#ffffff]">
                      <span className=" text-[0.70rem] text-[#41C588] flex">
                        <TranslateText
                          textKey={"id_verified"}
                          namespace="booking"
                        />

                        <span className="text-[#41C588] mr-1 text-xs mx-1">
                          <img src={verifyIcon} alt="" />
                        </span>
                      </span>
                    </h1>
                    <div
                      className="font-light flex mt-[0.60rem] px-2 text-[#404040] bg-[#FAFAFA] divide-x-2 
                h-[100%] w-[100%] rounded-lg"
                    >
                      <span className="text-sm py-1 text-[#555454]  flex h-[100%]">
                        {id?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="bg-slate-100 rounded relative mx-1"
                            >
                              <TiTimes
                                onClick={() => handleRemoveUrl(item, index)}
                                className="float-right cursor-pointer absolute left-3 bottom-2 text-sm text-[red] bg-slate-400 rounded"
                              />

                              <LazyLoadImage
                                className="w-3 lg:w-6 h-4"
                                alt="hotel"
                                src={item} // use normal <img> attributes as props
                              />
                            </div>
                          );
                        })}
                      </span>
                      <Upload
                        multiple={true}
                        customRequest={uploadFile}
                        onChange={handleChange}
                        beforeUpload={handleBeforeUpload}
                        showUploadList={false}
                        accept=".png,.jpeg"
                      >
                        <span className="text-sm py-1 px-2 text-[#555454] ">
                          <TranslateText textKey={"Edit"} namespace="common" />
                        </span>
                      </Upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UploadId;
