import { useState, useEffect, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllOrders, resetState } from "../../reducer/getAllOrdersReducer";
import { getAllTables } from "../../reducer/posReducer/restaurant";
import { getAllItems } from "../../reducer/getItemReducer";
import Loading from "../../pages/loading";
// import OrderItemsCountByCategory from "../../components/pos/kitchenDisplaySystem/orderItemsCountByCategory";
// import Orders from "../../components/pos/kitchenDisplaySystem/orders";

import ovenAndCook from "../../assets/images/svgs/ovenAndCook.svg";
import { getInvoices } from "../../reducer/orderReducer";
import { CircularProgress } from "@mui/material";

const OrderItemsCountByCategory = lazy(() =>
  import("../../components/pos/kitchenDisplaySystem/orderItemsCountByCategory")
);
const Orders = lazy(() =>
  import("../../components/pos/kitchenDisplaySystem/orders")
);

const KitchenDisplaySystem = () => {
  const [itemsName, setItemsName] = useState({});
  const [itemsCategory, setItemsCategory] = useState({});

  const { hotelDetails } = useSelector((store) => store.login);
  const { data, loading } = useSelector((store) => store.allOrders);
  const { allTables } = useSelector((store) => store.pos);
  const { data: itemsData } = useSelector((store) => store.getItems);

  const { activePosId } = useSelector((store) => store.posSetting);

  const dispatch = useDispatch();

  useEffect(() => {
    if (hotelDetails?.id && activePosId) {
      dispatch(resetState());
      dispatch(
        getAllOrders({
          hotelId: hotelDetails?.id,
          pageNumber: 0,
          pageSize: 100,
          statusList: "inProgress,ready",
        })
      );
      dispatch(getAllTables({ hotelId: hotelDetails?.id, posId: activePosId }));
      dispatch(getAllItems({ hotelId: hotelDetails?.id, posId: activePosId }));
    }
  }, [hotelDetails?.id, activePosId]);

  useEffect(() => {
    if (itemsData) {
      const itemsNameMap = itemsData.reduce((acc, curr) => {
        acc[curr?.id] = {
          name: curr?.name,
          category: curr?.category,
          foodChoice: curr?.foodChoice,
        };

        return acc;
      }, {});
      setItemsName(itemsNameMap);
    }
  }, [itemsData]);

  useEffect(() => {
    if (data) {
      const updatedItemsCategory = {};
      Array.isArray(data) &&
        data
          .filter(
            (order) => order.status === "inProgress" || order.status === "ready"
          )
          ?.forEach((item) => {
            const itemCountMap = item.itemCountMap;

            for (const itemId in itemCountMap) {
              if (itemCountMap.hasOwnProperty(itemId)) {
                const count = itemCountMap[itemId].count;
                if (updatedItemsCategory.hasOwnProperty(itemId))
                  updatedItemsCategory[itemId] += count;
                else updatedItemsCategory[itemId] = count;
              }
            }
          });
      setItemsCategory(updatedItemsCategory);
    }
  }, [data]);

  useEffect(() => {
    let invoicePayload = {
      statuses: "inProgress",
      posId: activePosId,
      hotelId: hotelDetails?.id,
      pageNumber: 0,
      pageSize: 100,
    };
    if (Boolean(activePosId)) dispatch(getInvoices(invoicePayload));

    return () => {
      dispatch(resetState());
    };
  }, []);

  const updatedData = data?.filter(
    (order) => order.status === "inProgress" || order.status === "ready"
  );

  return (
    <Suspense fallback={<Loading />}>
      <div className="bg-[#FAFAFA] w-[100%] min-h-[100vh] h-[100%] ">
        <div className="pt-[1rem] mt-[3rem] bg-[#FAFAFA] w-[100%] h-[100%]   ">
          {loading ? (
            <>
              <CircularProgress size={32} />
            </>
          ) : Array.isArray(updatedData) && updatedData?.length > 0 ? (
            <>
              <div className="pl-[22px]  w-[100%] flex">
                <OrderItemsCountByCategory
                  hotelDetails={hotelDetails}
                  data={data}
                  allTables={allTables}
                  itemsData={itemsData}
                  itemsCategory={itemsCategory}
                  itemsName={itemsName}
                />

                <Orders
                  hotelDetails={hotelDetails}
                  data={data}
                  allTables={allTables}
                  itemsData={itemsData}
                  itemsName={itemsName}
                />
              </div>
            </>
          ) : (
            <div className="flex flex-col justify-center items-center h-[90vh]">
              <div className="w-[80%] h-[90%] bg-[#FFF] rounded-[20px] flex flex-col items-center justify-center  gap-[2rem]">
                <div className="flex flex-col   justify-center items-center">
                  <div className="font-inter  font-[600] text-[34px] leading-[68px] ">
                    Currently!!
                  </div>
                  <div
                    className="font-inter  font-[500] text-[20px] leading-[40px]
                 text-[#808080]"
                  >
                    There are no items to serve.
                  </div>
                </div>

                <div className="img-containe flex gap-2  justify-center items-center">
                  <div>
                    <img src={ovenAndCook} alt="" />
                  </div>
                </div>

                <div className="font-[Poppins] font-[500] text-[14px] leading-[21px] ">
                  Powered By{" "}
                  <span
                    className="font-[Poppins] font-[600] text-[14px] leading-[21px]
                 text-[#2F80ED] "
                  >
                    REVIVO HOTELX
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Suspense>
  );
};

export default KitchenDisplaySystem;
