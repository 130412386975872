import { authedAxios } from "../utils/customHttpHeader";

const getAllTax = async (payload, endpoint) => {
  let url = `${endpoint}?hotelId=${payload.hotelId}`;

  const response = await authedAxios().get(url);
  return response;
};

const postMultipleTax = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

const postSingleTax = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

const putSingleTax = async (payload, endpoint) => {
  const response = await authedAxios().put(endpoint, payload);
  return response;
};

const deleteSingleTax = async (endpoint) => {
  const response = await authedAxios().delete(endpoint);
  return response;
};

export { getAllTax, postSingleTax, putSingleTax, deleteSingleTax,postMultipleTax };
