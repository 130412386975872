import React, { useState, useRef } from "react";
import Drawer from "@mui/material/Drawer";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateDisplayInvoiceDrawer } from "../../../reducer/bookingReducer";
import { ReactComponent as PrinterIcon } from "../../../assets/icons/printerIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/downloadIcon.svg";
import { useReactToPrint } from "react-to-print";
import { usePDF } from "react-to-pdf";
import NewInvoiceStructure from "../../common/Template/NewInvoiceStructure";
import { CircularProgress } from "@mui/material";

const ViewInvoiceDrawer = () => {
  const { displayInvoiceDrawer } = useSelector((store) => store.booking);
  const { hotelDetails, hotelContentDetails } = useSelector(
    (store) => store.login
  );

  const qrCodUrl = hotelContentDetails?.bankDetails?.qrCodeUrl;

  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [print, setPrint] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const { editBookingAllowed } = useSelector((store) => store.usersRestriction);

  const { response: posData } = useSelector((store) => store.posSetting);
  const roomDetails = displayInvoiceDrawer?.roomDetails
    ? displayInvoiceDrawer?.roomDetails
    : {};

  const handleClose = () => {
    dispatch(
      updateDisplayInvoiceDrawer({
        display: false,
        bookingDetails: {},
        invoiceDetails: [],
        paymentDetails: [],
        customerDetails: {},
        response: [],
        roomDetails: [],
        posNames: [],
      })
    );
  };

  const printPDF = useReactToPrint({
    onAfterPrint: () => setPrint(false),
    content: () => componentRef.current,
    documentTitle: "Invoice",
  });

  const action = async () => {
    setPrint(true);
    setTimeout(() => {
      printPDF();
      setPrint(false);
    }, 0);
  };

  const { toPDF, targetRef } = usePDF({
    filename: "Invoice.pdf",
    scale: 1, // Adjust the scale as needed
    options: {
      format: "A4", // Specify the page size (e.g., "letter", "A4")
    },
  });

  const handleGeneratePDF = async () => {
    await setPdfLoading(true);
    await toPDF();
    await setPdfLoading(false);
  };

  let roomInvoice = Array.isArray(displayInvoiceDrawer?.updatedInvoiceDetails)
    ? displayInvoiceDrawer?.updatedInvoiceDetails?.find(
        (Obj) => Obj?.invoiceType === "roomBill"
      )
    : {};

  const mySet = new Set();
  mySet.add(displayInvoiceDrawer?.invoiceId);

  let currentInvoice = displayInvoiceDrawer?.updatedInvoiceDetails?.find(
    (Obj) => Obj?.id === displayInvoiceDrawer?.invoiceId
  );

  return (
    <Drawer
      closeIcon={null}
      onClose={handleClose}
      open={displayInvoiceDrawer?.display}
      title={null}
      PaperProps={{
        sx: {
          width: "800px",
          height: "100vh",
          top: "auto",
          bottom: 0,
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "fixed",
          left: 0,
          right: 0,
          width: "30%",
        },
        "& .MuiPaper-root": {
          position: "absolute",
          marginLeft: "auto",
        },
      }}
      anchor={"right"}
      bodyStyle={{ overflowY: "auto" }}
      width={"60%"}
    >
      <div className="flex flex-col border-b-[1px] border-black p-3">
        <div className="flex justify-between items-center">
          <div></div>
          <div className="flex gap-3 items-center ">
            {editBookingAllowed && (
              <>
                {print ? (
                  <CircularProgress size={16} />
                ) : (
                  <PrinterIcon onClick={action} className="cursor-pointer" />
                )}

                {pdfLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  <DownloadIcon
                    onClick={handleGeneratePDF}
                    className="cursor-pointer"
                  />
                )}
              </>
            )}

            <CloseOutlined
              onClick={handleClose}
              className=" customCloseIconContainer"
            />
          </div>
        </div>
      </div>

      {displayInvoiceDrawer?.display ? (
        <NewInvoiceStructure
          targetRef={targetRef}
          hotelDetails={hotelDetails}
          bookingDetails={displayInvoiceDrawer?.bookingDetails}
          invoiceDetails={displayInvoiceDrawer?.updatedInvoiceDetails?.filter(
            (Obj) => Obj?.id === displayInvoiceDrawer?.invoiceId
          )}
          invoiceId={currentInvoice?.invoiceId}
          invoiceNum={currentInvoice?.invoiceNum}
          paymentDetails={displayInvoiceDrawer?.paymentDetails}
          checkedInvoiceId={mySet}
          customerDetails={displayInvoiceDrawer?.customerDetails?.[0]}
          response={displayInvoiceDrawer?.response}
          roomInvoice={roomInvoice}
          roomDetails={roomDetails}
          allSelected={false}
          isSingleInvoice={true}
          print={false}
          download={false}
          isComponent={true}
          posData={posData}
          qrCodUrl={qrCodUrl}
        />
      ) : (
        <></>
      )}

      {print && (
        <NewInvoiceStructure
          hotelDetails={hotelDetails}
          bookingDetails={displayInvoiceDrawer?.bookingDetails}
          invoiceDetails={displayInvoiceDrawer?.updatedInvoiceDetails?.filter(
            (Obj) => Obj?.id === displayInvoiceDrawer?.invoiceId
          )}
          invoiceId={currentInvoice?.invoiceId}
          invoiceNum={currentInvoice?.invoiceNum}
          paymentDetails={displayInvoiceDrawer?.paymentDetails}
          checkedInvoiceId={mySet}
          customerDetails={displayInvoiceDrawer?.customerDetails?.[0]}
          response={displayInvoiceDrawer?.response}
          roomDetails={roomDetails}
          val={componentRef}
          roomInvoice={roomInvoice}
          isSingleInvoice={true}
          allSelected={false}
          print={true}
          download={false}
          posData={posData}
          qrCodUrl={qrCodUrl}
        />
      )}
      {pdfLoading && (
        <NewInvoiceStructure
          targetRef={targetRef}
          hotelDetails={hotelDetails}
          bookingDetails={displayInvoiceDrawer?.bookingDetails}
          invoiceDetails={displayInvoiceDrawer?.updatedInvoiceDetails?.filter(
            (Obj) => Obj?.id === displayInvoiceDrawer?.invoiceId
          )}
          paymentDetails={displayInvoiceDrawer?.paymentDetails}
          checkedInvoiceId={mySet}
          invoiceId={currentInvoice?.invoiceId}
          invoiceNum={currentInvoice?.invoiceNum}
          customerDetails={displayInvoiceDrawer?.customerDetails?.[0]}
          response={displayInvoiceDrawer?.response}
          roomDetails={roomDetails}
          val={componentRef}
          roomInvoice={roomInvoice}
          allSelected={false}
          isSingleInvoice={true}
          print={false}
          download={true}
          isSingleDownload={true}
          posData={posData}
          qrCodUrl={qrCodUrl}
        />
      )}
    </Drawer>
  );
};

export default ViewInvoiceDrawer;
