import Home from "../assetsV2/SideBarImage/Dashbaord.svg";
import Booking from "../assetsV2/SideBarImage/Booking.svg";
import Pos from "../assetsV2/SideBarImage/POS.svg";
import ChannelManager from "../assetsV2/SideBarImage/Channel manager.svg";
import Clients from "../assetsV2/SideBarImage/Clients.svg";
import HouseKeeping from "../assetsV2/SideBarImage/Housekeeping.svg";
import Reports from "../assetsV2/SideBarImage/Reports.svg";
import Party from "../assetsV2/SideBarImage/Party.svg";
import PaymentsSideBarIcon from "../assetsV2/SideBarImage/Payments.svg";
import Expense from "../assetsV2/SideBarImage/Expense.svg";
import Invoice from "../assetsV2/SideBarImage/Invoice.svg";
import ManageStocks from "../assetsV2/SideBarImage/stocks.svg";

import stocks from "../assets/images/svgs/dashboard-sidebar-images/manageStocks.svg";
import Repair from "../assets/images/svgs/dashboard-sidebar-images/Repair.svg";
import Transaction from "../assets/images/svgs/dashboard-sidebar-images/Transaction.svg";
import WalkIn from "../assets/images/svgs/dashboard-sidebar-images/WalkIn.svg";
import Settings from "../assets/images/svgs/dashboard-sidebar-images/Settings.svg";
import Globe from "../assets/images/svgs/dashboard-sidebar-images/globe.svg";
import { TranslateText } from "../utils/translation";

export const DASHBOARD_NAVIGATION_OPTIONS = [
  {
    id: "1",
    title: "Dashboard",
    img: Home,
    defaultImg: Home,
    link: "",
    jsx: "Home",
    translatedTitle: <TranslateText textKey={"dashboard"} namespace="menu" />,
  },
  {
    id: "2",
    title: "Bookings",
    img: Booking,
    defaultImg: Booking,
    link: "bookings",
    translatedTitle: <TranslateText textKey={"bookings"} namespace="menu" />,
  },
  {
    id: "3",
    title: "POS",
    img: Pos,
    defaultImg: Pos,
    link: "all-orders",
    translatedTitle: <TranslateText textKey={"pos"} namespace="menu" />,
  },
  {
    id: "5",
    title: "Channel Manager",
    img: ChannelManager,
    defaultImg: ChannelManager,
    link: "channel-manager",
    translatedTitle: (
      <TranslateText textKey={"channel_manager"} namespace="menu" />
    ),
  },
  {
    id: "6",
    title: "Clients",
    img: Clients,
    defaultImg: Clients,
    link: "clients",
    translatedTitle: <TranslateText textKey={"clients"} namespace="menu" />,
  },
  {
    id: "8",
    title: "Housekeeping",
    img: HouseKeeping,
    defaultImg: HouseKeeping,
    link: "house-keeping",
    translatedTitle: (
      <TranslateText textKey={"housekeeping"} namespace="menu" />
    ),
  },
  {
    id: "11",
    title: "Reports",
    img: Reports,
    defaultImg: Reports,
    link: "reports",
    translatedTitle: <TranslateText textKey={"reports"} namespace="menu" />,
  },
  {
    id: "12",
    title: "Ledger",
    img: Party,
    defaultImg: Party,
    link: "party",
    translatedTitle: <TranslateText textKey={"ledger"} namespace="menu" />,
  },
  {
    id: "13",
    title: "Payments",
    img: PaymentsSideBarIcon,
    defaultImg: PaymentsSideBarIcon,
    link: "payments",
    translatedTitle: <TranslateText textKey={"payments"} namespace="menu" />,
  },
  {
    id: "14",
    title: "Expense",
    img: Expense,
    defaultImg: Expense,
    link: "expense",
    translatedTitle: <TranslateText textKey={"expense"} namespace="menu" />,
  },
  {
    id: "15",
    title: "Invoice",
    img: Invoice,
    defaultImg: Invoice,
    link: "invoice",
    translatedTitle: <TranslateText textKey={"invoice"} namespace="menu" />,
  },
  {
    id: "16",
    title: "Manage Stocks",
    img: ManageStocks,
    defaultImg: ManageStocks,
    link: "manageStocks",
    translatedTitle: (
      <TranslateText textKey={"manage_stocks"} namespace="menu" />
    ),
  },
  {
    id: "17",
    title: "Booking Engine",
    img: Globe,
    defaultImg: Globe,
    jsx: "booking-engine",
    link: "booking-engine",
    translatedTitle: (
      <TranslateText textKey={"booking_engine"} namespace="menu" />
    ),
  },
];

export const DASHBOARD_RESTAURANT_POS_OPTION = [
  {
    id: "0",
    title: "Dashboard",
    img: Home,
    defaultImg: Home,
    link: "/",
    jsx: "",
  },
  {
    id: "1",
    title: "New Order",
    img: Transaction,
    defaultImg: Transaction,
    link: "/table-view",
  },
  {
    id: "2",
    title: "All Orders",
    img: WalkIn,
    defaultImg: WalkIn,
    link: "/all-orders",
    jsx: "all-orders",
  },

  {
    id: "4",
    title: "All Items",
    img: ChannelManager,
    defaultImg: ChannelManager,
    link: "/all-items",
    jsx: "all-items",
  },

  {
    id: "6",
    title: "KDS",
    img: stocks,
    defaultImg: stocks,
    link: "/kitchen-display-system",
    jsx: "kds",
  },
  {
    id: "7",
    title: "Pos Setting",
    img: Repair,
    link: "/pos-settings",
    defaultImg: Repair,
  },
];

export const DASHBOARD_LAUNDRY_POS_OPTION = [
  {
    id: "0",
    title: "Dashboard",
    img: Home,
    defaultImg: Home,
    link: "/",
    jsx: "",
  },
  {
    id: "1",
    title: "New Order",
    img: Transaction,
    defaultImg: Transaction,
    link: "/table-view",
  },
  {
    id: "2",
    title: "All Orders",
    img: WalkIn,
    defaultImg: WalkIn,
    link: "/all-orders",
    jsx: "all-orders",
  },

  {
    id: "4",
    title: "All Items",
    img: ChannelManager,
    defaultImg: ChannelManager,
    link: "/all-items",
    jsx: "all-items",
  },
  {
    id: "7",
    title: "Pos Setting",
    img: Repair,
    link: "/pos-settings",
    defaultImg: Repair,
  },
];

export const DASHBOARD_BANQUET_POS_OPTION = [
  {
    id: "0",
    title: "Dashboard",
    img: Home,
    defaultImg: Home,
    link: "/",
    jsx: "",
  },
  {
    id: "1",
    title: "New Order",
    img: Transaction,
    defaultImg: Transaction,
    link: "/new-order",
  },
  {
    id: "2",
    title: "All Orders",
    img: WalkIn,
    defaultImg: WalkIn,
    link: "/all-orders",
    jsx: "all-orders",
  },

  {
    id: "4",
    title: "All Items",
    img: ChannelManager,
    defaultImg: ChannelManager,
    link: "/all-items",
    jsx: "all-items",
  },
  {
    id: "7",
    title: "Pos Setting",
    img: Repair,
    link: "/pos-settings",
    defaultImg: Repair,
  },
];

export const DASHBOARD_NAVIGATION_OPTIONS_WITH_POS_TOP = [
  {
    id: "1",
    title: "Home",
    img: Home,
    defaultImg: Home,
    link: "",
    jsx: "Home",
  },
];

export const DASHBOARD_NAVIGATION_OPTIONS_WITH_POS_MENU = [
  {
    id: "3",
    title: "Menu",
    img: ChannelManager,
    link: "pos/menu-settings",
    defaultImg: ChannelManager,
  },
];

export const DASHBOARD_NAVIGATION_OPTIONS_WITH_POS_BOTTOM = [
  {
    id: "7",
    title: "Table View / New Order",
    img: Transaction,
    defaultImg: Transaction,
    link: "pos/table-view",
  },
  {
    id: "5",
    title: "KDS",
    img: stocks,
    defaultImg: stocks,
    link: "pos/kitchen-display-system",
    jsx: "kds",
  },
  {
    id: "3",
    title: "All Items",
    img: ChannelManager,
    defaultImg: ChannelManager,
    link: "pos/all-items",
    jsx: "all-items",
  },
  {
    id: "2",
    title: "Pos Setting",
    img: Settings,
    defaultImg: Settings,
    link: "pos/pos-setting",
    jsx: "pos-setting",
  },

  {
    id: "8",
    title: "Dashboard",
    img: ChannelManager,
    defaultImg: ChannelManager,
    link: "pos/pos-dashboard",
    jsx: "all-items",
  },
];

export const TAX_OPTIONS = [
  {
    id: 1,
    value: "NA",
  },
  {
    id: 2,
    value: 5,
  },
  {
    id: 3,
    value: 10,
  },
  {
    id: 4,
    value: 18,
  },
  {
    id: 5,
    value: 24,
  },
];

export const DiscountService = [
  {
    id: "Discount",
    placeholder: "Enter Discount Amount",
  },
  {
    id: "Service",
    placeholder: "Enter Service Charge",
  },
];

export const SelectEconomyRooms = [
  {
    id: 1,
    roomNumber: 101,
    selected: false,
  },
  {
    id: 2,
    roomNumber: 102,
    selected: false,
  },
  {
    id: 3,
    roomNumber: 103,
    selected: false,
  },
  {
    id: 4,
    roomNumber: 104,
    selected: false,
  },
  {
    id: 5,
    roomNumber: 105,
    selected: false,
  },
  {
    id: 6,
    roomNumber: 106,
    selected: false,
  },
  {
    id: 7,
    roomNumber: 107,
    selected: false,
  },
  {
    id: 8,
    roomNumber: 108,
    selected: false,
  },
  {
    id: 9,
    roomNumber: 109,
    selected: false,
  },
  {
    id: 10,
    roomNumber: 110,
    selected: false,
  },
  {
    id: 11,
    roomNumber: 111,
    selected: false,
  },
  {
    id: 12,
    roomNumber: 112,
    selected: false,
  },
  {
    id: 13,
    roomNumber: 113,
    selected: false,
  },
  {
    id: 14,
    roomNumber: 114,
    selected: false,
  },
  {
    id: 15,
    roomNumber: 115,
    selected: false,
  },
  {
    id: 16,
    roomNumber: 116,
    selected: false,
  },
  {
    id: 17,
    roomNumber: 117,
    selected: false,
  },
  {
    id: 18,
    roomNumber: 118,
    selected: false,
  },
  {
    id: 19,
    roomNumber: 119,
    selected: false,
  },
  {
    id: 20,
    roomNumber: 120,
    selected: false,
  },
];

export const rooms = [
  {
    id: 1,
    roomNumber: 101,
  },
  {
    id: 2,
    roomNumber: 102,
  },
  {
    id: 3,
    roomNumber: 103,
  },
  {
    id: 4,
    roomNumber: 104,
  },
  {
    id: 5,
    roomNumber: 105,
  },
];

export const posOrderButtons = [
  {
    id: 1,
    title: "All Orders",
    name: "allOrders",
  },
  {
    id: 2,
    title: "Items",
    name: "items",
  },
];

export const ManageStockOptions = [
  {
    id: 1,
    title: "Recount Items",
    selected: false,
    route: "recount",
  },
  {
    id: 2,
    title: "Restock Items",
    selected: false,
    route: "restock",
  },
  {
    id: 3,
    title: "Suppliers",
    selected: false,
    route: "suppliers",
  },
  {
    id: 4,
    title: "Add/remove Categories",
    selected: false,
    route: "add-remove-categories",
  },
  {
    id: 5,
    title: "Add/remove Units",
    selected: false,
    route: "add-remove-units",
  },
  {
    id: 6,
    title: "Report Loss",
    selected: false,
    route: "report-loss",
  },
  {
    id: 7,
    title: "Add/remove Ingredient Categories",
    selected: false,
    route: "add-remove-categories",
  },
];
