import { useEffect } from "react";
import { ConfigProvider, DatePicker, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDisplayCustomCalendar,
  updateSelectedCheckInDate,
  updateSelectedCheckOutDate,
  updateDates,
} from "../../../reducer/bookingReservationReducer";

const CustomCalendar = () => {
  const { RangePicker } = DatePicker;

  const dispatch = useDispatch();
  const {
    selectedCheckInDate,
    selectedCheckOutDate,

    displayCustomCalendar,
  } = useSelector((store) => store.bookingReservation);

  const onSelectDate = (momentObj) => {
    const checkInDate = momentObj[0]?.startOf("days")?.toISOString();
    const checkOutDate = momentObj[1]?.endOf("days")?.toISOString();

    dispatch(updateSelectedCheckInDate(checkInDate));
    dispatch(updateSelectedCheckOutDate(checkOutDate));

    dispatch(
      updateDates({
        from: checkInDate,
      })
    );
    dispatch(
      updateDates({
        to: checkOutDate,
      })
    );

    message.success("Dates  Updated");
    dispatch(updateDisplayCustomCalendar(false));
  };

  useEffect(() => {
    if (!selectedCheckInDate && !selectedCheckOutDate) {
      dispatch(updateSelectedCheckInDate(moment()));
      dispatch(updateSelectedCheckOutDate(moment().add(1, "days")));
    }
  }, []);

  return (
    <div
      onClick={(e) => {
        // dispatch(updateDisplayCustomCalendar(!displayCustomCalendar));
        e.stopPropagation();
      }}
      className="absolute top-0"
    >
      <ConfigProvider
        theme={{
          components: {
            DatePicker: {
              cellHeight: 40,
              cellWidth: 42,
              cellActiveWithRangeBg: "#E9F4FF",
              // padding:"1000px",
            },
          },
        }}
      >
        <RangePicker
          picker="date"
          style={{
            border: "none",
            width: "0",
            height: "0",
            position: "absolute",
            padding: "0px",
          }}
          showNow={false}
          suffixIcon={<></>}
          format="MMMMYYYY"
          open={displayCustomCalendar}
          value={[dayjs(selectedCheckInDate), dayjs(selectedCheckOutDate)]}
          separator={false}
          onChange={onSelectDate}
          allowClear={false}
        />
      </ConfigProvider>
    </div>
  );
};

export default CustomCalendar;
