import { authedAxios } from "../utils/customHttpHeader";

const getAllAddOnByHotelId = async (payload) => {
  let url = `/addOns?hotelId=${payload.hotelId}`;

  const response = await authedAxios().get(url);
  return response;
};


const getSingleAddOn = async (payload) => {
  let url = `/addOns/${payload?.id}?hotelId=${payload.hotelId}`;

  const response = await authedAxios().get(url);
  return response;
};

const updateSingleAddOn = async (payload) => {
  let url = `/addOns/${payload?.hotelId}`;

  const response = await authedAxios().put(url, payload);
  return response;
};

const deleteSingleAddOn = async (payload) => {
  let url = `/addOns/${payload?.id}?hotelId=${payload.hotelId}`;

  const response = await authedAxios().delete(url);
  return response;
};

const postSingleAddOn = async (payload) => {
  let url = `/addOns`;

  const response = await authedAxios().post(url, payload);
  return response;
};

const postAllAddOn = async (payload) => {
  let url = `/addOns/upsert`;

  const response = await authedAxios().post(url, payload);
  return response;
};

export {
  postSingleAddOn,
  deleteSingleAddOn,
  updateSingleAddOn,
  getSingleAddOn,
  postAllAddOn,
  getAllAddOnByHotelId
};
