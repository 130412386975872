import React from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import moment from "moment";
import { TranslateText } from "../../../../utils/translation";

const GenerateKOT = ({ val, tableOrderDetails, order, latestOrder }) => {
  // const { latestOrder } = useSelector((store) => store.order);
  const { hotelDetails } = useSelector((store) => store.login);
  const { data: allItems } = useSelector((store) => store.getItems);
  const { activePosName } = useSelector((store) => store.posSetting);

  let { allTables, allRooms } = useSelector((store) => store.pos);

  let selectedItemWithQuantity = [];

  let itemObject = latestOrder?.itemCountMap || {};

  let steward = latestOrder?.stewardNames?.[0];

  let table = Boolean(latestOrder?.tableId)
    ? allTables.filter((item) => item.id === latestOrder?.tableId)?.[0]?.name
    : allRooms.filter((item) => item.id === latestOrder?.roomId)?.[0]?.roomNum;

  Object.keys(itemObject)?.forEach((id) => {
    allItems?.forEach((item) => {
      if (item.id === id) {
        let addon = [];

        item.addOns?.forEach((adon) => {
          if (itemObject[id]?.addOns?.[adon.name]) {
            addon.push({
              name: adon.name,
              price: adon.price,
              count: itemObject[id]?.addOns?.[adon.name],
            });
          }
        });

        selectedItemWithQuantity.push({
          name: item.name,
          count: itemObject[id]?.count,
          print: item.price,
          addOns: addon,
          instruction: itemObject[id]?.instruction,
        });
      }
    });
  });

  return (
    <div className="p-10 absolute left-0 right-0" ref={val}>
      <div className="text-center">
        <p className="text-semibold text-2xl font-semibold capitalize">
          {hotelDetails?.name}
        </p>
        <p className="text-semibold font-semibold capitalize">
          {activePosName}
        </p>

        <p>
          {/* <span> 30, Sep, 2023 </span> */}
          <span>{moment(latestOrder?.createdAt).format("Do MMM, YYYY")}</span>
          <span className="ml-2">
            {moment(latestOrder?.createdAt).format("h:mm a")}
          </span>{" "}
        </p>
        <div className="flex justify-between text-center m-auto mt-2">
          <p className="font-semibold text-sm">
            <TranslateText textKey={"kot_no"} namespace="pos" />

            {order?.tokenNum}
          </p>
        </div>
        <div className="flex justify-between text-center m-auto mt-2">
          {/* <p className="font-semibold text-sm">KOT no :18</p> */}
          <p className="text-sm ">
            <TranslateText textKey={"table"} namespace="pos" />
            {table}
          </p>
        </div>
        <div className="flex justify-between text-center m-auto mt-2">
          <p className="text-sm ">
            {" "}
            <TranslateText textKey={"no_of_pax"} namespace="pos" />
            {tableOrderDetails?.numberOfPeople}
          </p>
        </div>
      </div>
      <hr className="border-dashed border-b-2 border-slate-600 my-2" />

      <div className="flex justify-between">
        <p className="capitalize">{steward}</p>
        <p>
          {selectedItemWithQuantity.length}
          <TranslateText textKey={"items"} namespace="pos" />
        </p>
      </div>
      <hr className="border-dashed border-b-2 border-slate-600 my-2" />

      <div className="flex justify-between">
        <p className="w-[50%]">
          <TranslateText textKey={"item"} namespace="pos" />
        </p>
        <p className="w-[40%]">
          <TranslateText textKey={"note"} namespace="pos" />
        </p>
        <p className="w-[10%]">
          <TranslateText textKey={"qty"} namespace="pos" />
        </p>
      </div>
      <div>
        {selectedItemWithQuantity?.map((item, index) => {
          return (
            <div key={index}>
              <div className="flex justify-between">
                <p className="w-[50%]">{item.name}</p>
                <p className="w-[40%]">{item.instruction}</p>
                <p className="w-[10%]">{item.count}</p>
              </div>
              {item.addOns?.map((addon) => {
                return (
                  <div className="flex">
                    <span className="mt-1">
                      <BsArrowReturnRight className="mr-[2px]" />
                    </span>
                    <span>
                      {addon.name}X{addon.count}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <hr className="border-dashed border-b-2 border-slate-600 my-2" />

      <p className="text-sm">
        <TranslateText textKey={"prepare_by"} namespace="pos" />{" "}
        <span>
          {moment(latestOrder?.createdAt).format("Do MMM, YYYY h:mm a")}
        </span>{" "}
      </p>
    </div>
  );
};

export default GenerateKOT;
