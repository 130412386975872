import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateActiveFooter } from "../../../../reducer/appHeaderReducer";
import { MdOutlinePrint } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import GenerateInvoice from "../GenerateInvoice";

import {
  getCustomerById,
  updateActiveTab,
} from "../../../../reducer/posReducer/restaurant";

const Heading = ({
  setMultipleKOTScreenVsisible,
  setOrderDetailScreenVisible,
}) => {
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);
  const navigate = useNavigate();

  const { invoicePostData, data: order } = useSelector((store) => store.order);
  const { hotelDetails } = useSelector((store) => store.login);
  const { customerDetails, selectedItem } = useSelector((store) => store.pos);

  const dispatch = useDispatch();
  const [printInvoice, setPrintInvoice] = useState(false);
  const componentInvoiceRef = useRef(null);
  const handleInvoicePrint = (value) => setPrintInvoice(value);

  const { activePosSlug } = useSelector((store) => store.posSetting);

  let status = invoicePostData?.status;

  let customerId = "";
  order?.forEach((item) => {
    if (Boolean(item?.customer)) customerId = item?.customer;
  });

  useEffect(() => {
    if (Boolean(customerId)) {
      dispatch(
        getCustomerById({
          id: customerId,
          hotelId: hotelDetails?.id,
        })
      );
    }
  }, [customerId, selectedItem]);

  const printInvoicePDF = useReactToPrint({
    onAfterPrint: () => handleInvoicePrint(false),
    content: () => componentInvoiceRef.current,
    documentTitle: "Kot",
  });

  const triggerPrint = async () => {
    await setPrintInvoice(true);
    await printInvoicePDF();
    await setPrintInvoice(false);
  };

  return (
    <header className="fixed top-0 w-[100%] flex justify-between p-3 px-4 pt-7 items-center shadow-md bg-[#FFF]">
      <div className="flex gap-2 items-center">
        <div className="p-2 ">
          <IoIosArrowRoundBack
            onClick={() => {
              if (status === "inProgress") {
                setMultipleKOTScreenVsisible(false);
              } else {
                setMultipleKOTScreenVsisible(false);
                setOrderDetailScreenVisible(false);
                dispatch(
                  updateActiveTab(
                    status === "paid" || status === "due" ? "close" : "open"
                  )
                );
                dispatch(updateActiveFooter("POS"));
                navigate(`/dashboard/${activePosSlug}/all-orders`);
              }
            }}
            className="text-[30px]"
          />
        </div>

        <div className="flex gap-1 items-end">
          <div className="font-outfit font-[500] text-[18px] leading-[27px] text-[#222222]">
            {`Order for Table ${
              Boolean(tableOrderDetails?.tableNumber)
                ? tableOrderDetails?.tableNumber
                : ""
            }`}
          </div>
        </div>
      </div>

      {status === "inProgress" ? (
        <div
          className="flex gap-1 items-center"
          onClick={() => setMultipleKOTScreenVsisible(false)}
        >
          <FaPlus className="text-[#007FFF]" />

          <div className="font-outfit font-[500] text-[16px] leading-[24px] text-[#007FFF]">
            New Order
          </div>
        </div>
      ) : (
        <div className=" p-2">
          <MdOutlinePrint
            className="text-[#007FFF] text-[22px] "
            onClick={triggerPrint}
            onMouseEnter={() => setPrintInvoice(true)}
            onMouseLeave={() => setPrintInvoice(false)}
          />

          {printInvoice && (
            <GenerateInvoice
              print={printInvoice}
              val={componentInvoiceRef}
              tableOrderDetails={tableOrderDetails}
              customer={customerDetails}
            />
          )}
        </div>
      )}
    </header>
  );
};

export default Heading;
