import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { GoPlusCircle } from "react-icons/go";
import { MdDelete } from "react-icons/md";
import { Button, message } from "antd";

import {
  clearSelectedItem,
  createItem,
  updateAction,
  updateAddonList,
  updateSelectedItem,
  updateIsAddItemDrawerOpen,
} from "../../../reducer/getItemReducer";
import LoadingButton from "../../common/button/LoadingButton";
import SelectInput from "./SelectInput";
import { Input } from "./Input";
import {
  ACTIVE_POS_DETAILS,
  FOOD_CHOICE_OBJ,
  FOOD_TYPE,
  ITEM_STATUS,
  POS_SERVICE_TYPE,
  POS_TYPE,
} from "../../../utils/posHelper";
import { UpdatePosSettingForm } from "../../../reducer/posReducer/posSettingReducer";
import CustomTaxModal from "../../common/components/CustomTaxModal";
import { TranslateText } from "../../../utils/translation";


const foodType = FOOD_TYPE;

const itemStatus = ITEM_STATUS;

const itemServiceType = POS_SERVICE_TYPE;

function AddItem() {
  const { response, activePosName, activePosType, activePosSlug, activePosId } =
    useSelector((store) => store.posSetting);

  const { createLoading, createError, selectedItem } = useSelector(
    (store) => store.getItems
  );

  const { gstRate } = ACTIVE_POS_DETAILS(response, activePosSlug);

  useEffect(() => {
    // service type
    let itemObj = selectedItem;
    let name = "serviceTypes";
    itemObj = {
      ...itemObj,
      [name]: itemServiceType,
      active: itemStatus[0],
      foodChoice: foodType[0],
      gstRate: gstRate,
    };

    dispatch(updateSelectedItem(itemObj));
  }, [selectedItem?.id]);

  let categories = response?.filter((item) => item.slug == activePosSlug)?.[0]
    ?.itemCategories;

  let units = response?.filter((item) => item.slug == activePosSlug)?.[0]
    ?.itemUnits;

  // itemUnits,itemCategories

  const [isAddon, setIsAddon] = useState(true);

  const { hotelDetails } = useSelector((store) => store.login);

  const dispatch = useDispatch();

  const handleItem = (e) => {
    let itemObj = selectedItem;

    let name = e.target.name;
    let value = e.target.value;

    itemObj = { ...itemObj, [name]: value };

    dispatch(updateSelectedItem(itemObj));
  };

  const handlePriceItem = (e) => {
    let itemObj = selectedItem;

    let pricesByType = Boolean(itemObj?.pricesByType)
      ? itemObj?.pricesByType
      : {};

    let key = [e.target.name];

    itemObj = {
      ...itemObj,
      pricesByType: { ...pricesByType, [key]: e.target.value },
    };

    dispatch(updateSelectedItem(itemObj));
  };

  const handleItemsAddon = (e, key) => {
    let name = e.target.name;
    let value = e.target.value;

    let addonList = selectedItem?.addOns;

    let newAddonList = addonList?.map((addon, addonKey) => {
      if (addonKey === key) {
        if (name == "addonName") {
          return { ...addon, name: value };
        } else {
          return { ...addon, price: value };
        }
      }
      return addon;
    });

    let payload = {
      ...selectedItem,

      addOns: newAddonList,
    };

    dispatch(updateSelectedItem(payload));
  };

  let isValidate =
    Boolean(selectedItem["name"]) && Boolean(selectedItem["category"]);

  if (POS_TYPE[0] == activePosType) {
    if (Array.isArray(selectedItem?.serviceTypes)) {
      selectedItem?.serviceTypes?.forEach((item) => {
        if (!Boolean(selectedItem?.pricesByType?.[item])) {
          isValidate = false;
        }
      });
    } else {
      isValidate = false;
    }
  } else {
    if (Boolean(selectedItem["price"])) {
      isValidate = true;
    } else {
      isValidate = false;
    }
  }

  // Boolean(selectedItem?.pricesByType?.["dinein"]);

  let isAddonValid = Boolean(selectedItem?.addOns)
    ? selectedItem?.addOns?.every((item) => {
        if (Boolean(item?.name) && Boolean(item?.price)) {
          return true;
        } else {
          return false;
        }
      })
    : true;

  const handleValidation = async () => {
    if (!Boolean(selectedItem?.name)) {
      message.info("Please Enter item name..");
    } else if (!Boolean(selectedItem?.category)) {
      message.info("Please select the item category..");
    } else {
      let isItemPriceValid = true;

      if (POS_TYPE[0] == activePosType) {
        if (Array.isArray(selectedItem?.serviceTypes)) {
          selectedItem?.serviceTypes?.forEach((item) => {
            if (!Boolean(selectedItem?.pricesByType?.[item])) {
              isItemPriceValid = false;
            }
          });
        } else {
          isItemPriceValid = false;
        }
      } else {
        if (Boolean(selectedItem["price"])) {
          isValidate = true;
        } else {
          isValidate = false;
        }
      }

      let isAddonValid = Boolean(selectedItem?.addOns)
        ? selectedItem?.addOns?.every((item) => {
            if (Boolean(item?.name) && Boolean(item?.price)) {
              return true;
            } else {
              return false;
            }
          })
        : true;

      if (!Boolean(selectedItem["serviceTypes"])) {
        message.info("Please Select Service charge..");
      } else {
        if (!isItemPriceValid) {
          message.info("Please Enter item price..");
        } else if (!isAddonValid) {
          message.info("Please Enter addon name or addon price..");
        }
      }
    }
  };

  const handleSubmit = async () => {
    let payload = {
      ...selectedItem,
      active: true,
      foodChoice: Object.keys(FOOD_CHOICE_OBJ).filter(
        (key) => FOOD_CHOICE_OBJ[key] === selectedItem.foodChoice
      )?.[0],
      gstRate: Boolean(selectedItem?.gstRate) ? selectedItem?.gstRate : gstRate,
      addOns: Boolean(selectedItem?.addOns) ? selectedItem?.addOns : null,
      hotelId: hotelDetails?.id,
      posId: activePosId,
    };

    window.scroll(0, 0);
    dispatch(createItem(payload));
  };

  const handleAddItem = (itemName, type) => {
    if (type == "category") {
      createCategory(itemName);
    } else if (type === "unit") {
      createUnit(itemName);
    }
  };

  const createCategory = (itemName) => {
    let isCategoryExist = Array.isArray(categories)
      ? categories?.filter(
          (item) => item.toLowerCase() === itemName.toLowerCase()
        )
      : [];

    if (isCategoryExist?.length === 0) {
      let updatedCategory = Array.isArray(categories)
        ? [...categories, itemName]
        : [itemName];

      handleUpdate("itemCategories", updatedCategory, "Category");
    } else {
      message.info("Item already exist..");
    }
  };
  const createUnit = (itemName) => {
    let isUnitExist = Array.isArray(units)
      ? units?.filter((item) => item.toLowerCase() === itemName.toLowerCase())
      : [];

    if (isUnitExist?.length === 0) {
      let updatedUnit = Array.isArray(units)
        ? [...units, itemName]
        : [itemName];

      handleUpdate("itemUnits", updatedUnit, "Unit");
    } else {
      message.info("Item already exist..");
    }
  };

  const handleUpdate = (key, value, Field) => {
    let body = response?.filter((item) => item.slug === activePosSlug)?.[0];
    let payload = {
      ...body,
      [key]: value,
      id: activePosId,
      message: `${Field} has been addedd...`,
    };

    dispatch(UpdatePosSettingForm(payload));
  };

  const handleClose = () => {
    dispatch(updateAction(""));
    dispatch(clearSelectedItem());
    dispatch(updateIsAddItemDrawerOpen(false));
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <h1 className="text-[24px] text-[#323F4B] font-600">Add Item</h1>
        <p
          onClick={() => handleClose()}
          className="text-primary text-sm font-semibold mt-2 cursor-pointer"
        >
          
          <TranslateText textKey={"close"} namespace="pos" />

        </p>
      </div>

      <form className="flex flex-col gap-12 mt-10" onSubmit={handleSubmit}>
        <div>
          <div className="mb-3">
            <Input
              name="name"
              className="bg-white"
              value={Boolean(selectedItem["name"]) ? selectedItem["name"] : ""}
              handleChange={handleItem}
              label="Enter item name "
            />
          </div>

          <div className="mb-5">
            <SelectInput
              label={"Select Item Category"}
              value={
                Boolean(selectedItem["category"])
                  ? selectedItem["category"]
                  : []
              }
              multiple={false}
              menuItem={categories}
              addNewItem={true}
              handleAddItem={(itemName) => handleAddItem(itemName, "category")}
              name="category"
              handleChange={handleItem}
            />
          </div>

          <div className=" mb-1">
            <SelectInput
              label={"Select Item Unit"}
              addNewItem={true}
              handleAddItem={(itemName) => handleAddItem(itemName, "unit")}
              value={Boolean(selectedItem["unit"]) ? selectedItem["unit"] : []}
              multiple={false}
              menuItem={units}
              name="unit"
              handleChange={handleItem}
            />
          </div>

          <div className="mb-4 mt-6">
            <CustomTaxModal
              data={selectedItem}
              handleSaveChanges={() => {}}
              setAllInputData={updateSelectedItem}
              isAddNewTax={true}
              isDispatchUsedInsteadOfLoaclState={true}
            />
          </div>

          {activePosType === POS_TYPE[0] && (
            <div className="mb-3 mt-6">
              <SelectInput
                label={"Select Item Type"}
                value={
                  Boolean(selectedItem["foodChoice"])
                    ? selectedItem["foodChoice"]
                    : []
                }
                multiple={false}
                menuItem={foodType}
                name="foodChoice"
                handleChange={handleItem}
              />
            </div>
          )}

          <div>
            <SelectInput
              label={"Select Item status"}
              value={
                Boolean(selectedItem["active"]) ? selectedItem["active"] : []
              }
              multiple={false}
              menuItem={itemStatus}
              name="active"
              handleChange={handleItem}
            />
          </div>

          {activePosType === POS_TYPE[0] && (
            <div className="mt-6">
              <SelectInput
                label={"Select item Service"}
                value={
                  Boolean(selectedItem["serviceTypes"])
                    ? selectedItem["serviceTypes"]
                    : []
                }
                multiple={true}
                menuItem={itemServiceType}
                name="serviceTypes"
                handleChange={handleItem}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2 ">
          <span className="text-[16px] text-[#4f4f4f]">
          <TranslateText textKey={"pricing_details"} namespace="pos" />

          </span>

          <hr />

          <div className="flex flex-col gap-2 p-2">
            {activePosType === POS_TYPE[0] ? (
              <>
                {Boolean(selectedItem["serviceTypes"]) && (
                  <div>
                    {selectedItem["serviceTypes"]?.map((item, key) => {
                      return (
                        <div className="flex justify-between items-center mb-2">
                          <label>
                          <TranslateText textKey={"price"} namespace="pos" />

                            {" "}
                            <span className="font-semibold text-xs">
                              ({item})
                            </span>
                          </label>
                          <input
                            type="number"
                            className="bg-white px-4 py-2 w-[122px]"
                            placeholder="price"
                            name={item}
                            value={selectedItem?.pricesByType?.[item]}
                            onChange={(e) => handlePriceItem(e)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="flex justify-between items-center mb-2">
                  <label>
                  <TranslateText textKey={"price"} namespace="pos" />

                     </label>
                  <input
                    type="number"
                    className="bg-white px-4 py-2 w-[122px]"
                    placeholder="price"
                    name="price"
                    value={
                      Boolean(selectedItem["price"])
                        ? selectedItem["price"]
                        : ""
                    }
                    onChange={(e) => handleItem(e)}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2 pb-[3rem]">
          <div className="flex justify-between">
            <h1 className="text-[16px] text-[#4f4f4f]">
            <TranslateText textKey={"addon_details"} namespace="pos" />

            </h1>
          </div>

          <hr />
          <div className="flex flex-col gap-2 p-2">
            {selectedItem?.addOns?.map((pd, key) => {
              return (
                <>
                  <div className="flex justify-between">
                    <Input
                      name="addonName"
                      type="text"
                      className="bg-white w-[95%]"
                      handleChange={(e) => handleItemsAddon(e, key)}
                      label="Enter AddOn name "
                      value={pd.name}
                    />

                    <MdDelete
                      onClick={() =>
                        dispatch(
                          updateAddonList({
                            decrease: true,
                            key: key,
                          })
                        )
                      }
                      className="text-red-600 mt-[2rem] text-[18px] w-[5%] cursor-pointer"
                    />
                  </div>

                  <div className="flex justify-between items-center w-[95%]">
                    <label className="pl-1">
                    <TranslateText textKey={"addon_price"} namespace="pos" />

                    </label>
                    <input
                      type="number"
                      className="bg-white px-4 py-2 w-[122px]"
                      placeholder="price"
                      name="addonPrice"
                      value={pd.price}
                      onChange={(e) => handleItemsAddon(e, key)}
                    />
                  </div>
                </>
              );
            })}

            <div
              onClick={() =>
                dispatch(
                  updateAddonList({
                    increase: true,
                  })
                )
              }
              className="flex justify-end mt-1 cursor-pointer w-[95%]"
            >
              <GoPlusCircle className="text-primary text-2xl" />
            </div>
          </div>
        </div>

        <div className="fixed bottom-0 right-0 w-[400px] ">
          {createLoading ? (
            <LoadingButton bg={"bg-blue-400"} text="text-white" width="100%" />
          ) : (
            <div>
              {isValidate && isAddonValid ? (
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  className="bg-blue-500 text-[#ffffff] w-[100%] h-[52px] text-[18px] font-600 rounded"
                >
                  
                  <TranslateText textKey={"add_item"} namespace="pos" />

                </Button>
              ) : (
                <Button
                  onClick={handleValidation}
                  type="submit"
                  className="bg-blue-200 text-[#ffffff] w-[100%] h-[52px] text-[18px] font-600 rounded"
                >
                  <TranslateText textKey={"add_item"} namespace="pos" />

                </Button>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddItem;
