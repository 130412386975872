import React, { useEffect, lazy, Suspense } from "react";
import Loading from "../../pages/loading";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllInvoices,
  updateVisibleCalendar,
  updateResetState,
} from "../../reducer/invoiceReducer";
import { GET_ISO_FORMAT } from "../../utils/helper";

const Invoice = lazy(() => import("../../components/invoice/Index"));
const MobileScreenInvoice = lazy(() =>
  import("../../mobileComponents/mobileScreenInvoice/Index")
);

const Index = () => {
  const dispatch = useDispatch();
  const { hotelDetails } = useSelector((store) => store.login);

  const { isMobileScreen, globalsearchText } = useSelector(
    (store) => store.appHeader
  );

  const { visibleCalendar } = useSelector((store) => store.invoice);
  const applyDateFilter = () => {
    if (visibleCalendar) dispatch(updateVisibleCalendar(false));
  };

  const {
    initialDate,
    finalDate,
    pageNumber,
    infinateScroll,
    serviceSelectedArray,
  } = useSelector((store) => store.invoice);

  useEffect(() => {
    let posId = [];
    let invoiceTypeList = [];

    if (serviceSelectedArray && serviceSelectedArray.length) {
      serviceSelectedArray?.map((Obj) => {
        if (Obj?.type === "roomBill") invoiceTypeList.push("roomBill");
        else {
          posId?.push(Obj.id);
          invoiceTypeList?.push("pos");
        }
      });
    }

    const payload = {
      hotelId: hotelDetails?.id,
      pageNumber: 0,
      infinateScroll: infinateScroll,
      posId: posId,
      invoiceTypeList: invoiceTypeList,
      searchText: globalsearchText,
    };
    if(Boolean(initialDate)) {
      payload['startDate'] = GET_ISO_FORMAT(initialDate);   
    }
    if(Boolean(finalDate)) {
      payload['endDate'] = GET_ISO_FORMAT(finalDate);
    }
    performApi(payload);
  }, [initialDate, finalDate, serviceSelectedArray, globalsearchText]);

  useEffect(() => {
    let posId = [];
    let invoiceTypeList = [];
    if (serviceSelectedArray && serviceSelectedArray.length) {
      serviceSelectedArray?.map((Obj) => {
        if (Obj?.type === "roomBill") {
          invoiceTypeList.push("roomBill");
        } else {
          posId?.push(Obj.id);
          invoiceTypeList?.push("pos");
        }
      });
    }

    const payload = {
      startDate: initialDate,
      endDate: finalDate,
      hotelId: hotelDetails?.id,
      pageNumber: pageNumber,
      infinateScroll: infinateScroll,
      posId: posId,
      invoiceTypeList: invoiceTypeList,
      searchText: globalsearchText,
    };
    if (infinateScroll) performApi(payload);
  }, [pageNumber]);

  useEffect(() => {
    dispatch(updateResetState());

    const payload = {
      hotelId: hotelDetails?.id,
      pageNumber: 0,
      infinateScroll: infinateScroll,
    };
    performApi(payload);
  }, [hotelDetails?.id]);

  const performApi = (payload) => dispatch(getAllInvoices(payload));

  return (
    <Suspense fallback={<Loading />}>
      <div className="h-[100%]">
        {isMobileScreen ? (
          <MobileScreenInvoice />
        ) : (
          <div className="h-[100%]" onClick={() => applyDateFilter()}>
            <Invoice />
          </div>
        )}
      </div>
    </Suspense>
  );
};

export default Index;
