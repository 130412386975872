import React from "react";
import moment from "moment";
import { CALCULATE_NIGHTS_COUNT, FOLIO_FORMAT } from "../../../utils/helper";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { TbWorld } from "react-icons/tb";

import styles from "./styles"; // Import your styles object
import {
  constructCombinedPriceBreakup,
  requiredRoomInvoiceBreakupFormat,
} from "../../../utils/invoiceHelper";
import { getCurrentCurrency } from "../../../utils/customCurrencyHelper";

// Invoice Document Component
const InvoiceDocument = (props) => {
  const hotelDetails = props?.hotelDetails;
  const bankDetails = props?.bankDetails;

  const cancellationPolicyArray = Array.isArray(
    props?.hotelContentDetails?.cancellationPoliciesText
  )
    ? props?.hotelContentDetails?.cancellationPoliciesText?.slice(0, 3)
    : [];

  const { symbol, position } = getCurrentCurrency(hotelDetails?.currency);

  const showBankDataInInvoice = Boolean(hotelDetails?.showBankDetailsInInvoice);
  const isSingleInvoice = Boolean(props?.isSingleInvoice)
    ? props?.isSingleInvoice
    : false;

  console.log(props?.qrCodUrl);

  const roomInvoice = props?.roomInvoice ?? {};

  const invoiceId = props?.invoiceId;
  const customerObject = props?.customerDetails;
  const bookingObject = props?.bookingDetails;
  const paymentArray = Array.isArray(props?.paymentDetails)
    ? props?.paymentDetails
    : [];

  let invoiceDetails = props?.invoiceDetails;
  let isCmIdPresent = Boolean(bookingObject?.cmId);

  let nightsCount = CALCULATE_NIGHTS_COUNT(
    bookingObject?.checkIn,
    bookingObject?.checkOut,
    hotelDetails
  );

  let invoiceArray = requiredRoomInvoiceBreakupFormat(
    invoiceDetails,
    isCmIdPresent,
    nightsCount ?? 1
  );

  const roomDetails = props?.roomDetails;
  const posData = props?.posData;

  if (!Array.isArray(invoiceArray)) return;

  const bankSection = [
    {
      title: "Account Holder Name",
      value: Boolean(bankDetails?.accountHolderName)
        ? bankDetails?.accountHolderName?.toUpperCase()
        : null,
    },
    {
      title: "Bank Account Number",
      value: Boolean(bankDetails?.accountNumber)
        ? bankDetails?.accountNumber
        : null,
    },
    {
      title: "Bank Name",
      value: Boolean(bankDetails?.bankName)
        ? bankDetails?.bankName?.toUpperCase()
        : null,
    },
    {
      title: "IFSC Code",
      value: Boolean(bankDetails?.ifscCode)
        ? bankDetails?.ifscCode?.toUpperCase()
        : null,
    },
  ];

  const customerSection = [
    {
      title: "Name",
      value: Boolean(customerObject?.name) ? customerObject?.name : null,
    },
    {
      title: "Phone No",
      value: Boolean(customerObject?.phone) ? customerObject?.phone : null,
    },
    {
      title: "Email",
      value: Boolean(customerObject?.email) ? customerObject?.email : null,
    },
    {
      title: "Company Name",
      value: Boolean(customerObject?.company) ? customerObject?.company : null,
    },
    {
      title: "GSTIN",
      value: Boolean(customerObject?.gstIN) ? customerObject?.gstIN : null,
    },
    {
      title: "Address ",
      value: Boolean(customerObject?.address) ? customerObject?.address : null,
    },
  ];

  const hotelInformation = [
    {
      title: Boolean(hotelDetails && hotelDetails?.name)
        ? hotelDetails?.name
        : null,
      Logo: hotelDetails?.logo,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.address)
        ? hotelDetails?.address?.substring(0, 150)
        : null,
      Logo: IoLocationSharp,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.contactNumber)
        ? hotelDetails?.contactNumber
        : null,
      Logo: FaPhoneAlt,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.emailAddress)
        ? hotelDetails?.emailAddress
        : null,
      Logo: MdEmail,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.website)
        ? hotelDetails?.website
        : null,
      Logo: TbWorld,
    },
  ];

  let bookingSection = Boolean(
    props?.bookingDetails && Object?.keys(props?.bookingDetails)?.length > 0
  )
    ? [
        {
          title: "Room No / Plan",
          value: Boolean(roomDetails)
            ? `${roomDetails?.name} X ${roomDetails?.type} / ${bookingObject?.mealPlan}`
            : null,
        },
        {
          title: "Adult-Child",
          value: Boolean(bookingObject?.adults)
            ? `${bookingObject?.adults} - ${bookingObject?.children} `
            : null,
        },
        {
          title: "Check-in/Check-out",
          value: Boolean(bookingObject?.checkIn && bookingObject?.checkOut)
            ? `${moment(bookingObject?.checkIn)?.format(
                "D-MM-YYYY"
              )} | ${moment(bookingObject?.checkOut)?.format("D-MM-YYYY")}`
            : null,
        },
        {
          title: "Booking Source",
          value: Boolean(bookingObject?.source) ? bookingObject?.source : null,
        },
      ]
    : [];

  const priceBreakupHead = [
    { title: " # Item & Description ", width: "30%" },
    { title: "HSN/SAC", width: "15%" },
    { title: "Rate", width: "15%" },
    { title: "Count", width: "15%" },
    { title: "Nights", width: "15%" },
    { title: "Discount", width: "15%" },
    { title: "Tax", width: "15%" },
    { title: "Total", width: "15%" },
  ];

  let combinedPriceBreakup = [];

  combinedPriceBreakup = constructCombinedPriceBreakup(
    invoiceArray,
    posData,
    nightsCount || 1
  );

  let paymentReceived = 0;
  let totalBill = 0;
  let totalRefund = 0;
  let balanceDue = 0;
  let discount = 0;
  let subTotal = 0;
  let totalAddons = 0;

  invoiceArray?.forEach((Obj) => {
    subTotal += Obj?.subTotal;
    totalBill += Obj?.total;
    discount += Obj?.discount;
    paymentReceived += Obj?.paid;
    totalRefund += Obj?.refund;
  });

  let addOns = roomInvoice?.priceBreakup?.find((Obj) => Obj?.type === "Addons");

  if (addOns)
    addOns?.addOns?.forEach((Obj) => (totalAddons += +Obj?.price * Obj?.count));

  if (!isSingleInvoice) {
    paymentReceived = 0;
    totalRefund = 0;

    Array.isArray(paymentArray) &&
      paymentArray
        ?.map((obj) => obj.amount)
        ?.filter((Obj) => Obj < 0)
        .map((obj) => (totalRefund += -obj));

    Array.isArray(paymentArray) &&
      paymentArray
        ?.map((obj) => obj.amount)
        ?.filter((Obj) => Obj > 0)
        .map((obj) => (paymentReceived += obj));
  }

  balanceDue = FOLIO_FORMAT(totalBill - paymentReceived + totalRefund);

  const invoiceTitleInformation = [
    {
      title: "INVOICE",
    },
    {
      title: Boolean(invoiceId) ? invoiceId : "Tentative",
    },
    {
      title: Boolean(hotelDetails?.gstIN)
        ? `GSTIN: ${hotelDetails?.gstIN}`
        : "",
    },
    {
      title: `Payment Due: ${`${position === "before" ? symbol : ""} ${
        Boolean(balanceDue) ? balanceDue : "00"
      } ${position === "after" ? `${symbol}` : ""}`}`,
    },
    {
      title: Boolean(roomInvoice?.createdAt)
        ? moment(roomInvoice?.createdAt)?.format("DD MMM, YYYY")
        : "",
    },
  ];

  let hotelLogo = hotelDetails?.logo;
  let posName = Boolean(props?.posObj) ? props?.posObj?.name : "";

  const { checkInTime, checkOutTime } = hotelDetails;

  // if summarized feild is present in hotel invoice settings, and we are at the "ALL" tab, then alter the combined array
  if (props?.isSummarized) {
    // first filtering out invoices which are not of type roomBill(they have hsn "996331")
    combinedPriceBreakup = combinedPriceBreakup?.filter(
      (obj) => obj.hsn == 996311
    );

    props?.invoiceDetails?.forEach((invoice) => {
      if (invoice?.invoiceType == "pos") {
        let obj = {
          description: Boolean(invoice?.invoiceId) ? invoice?.invoiceId : "-",
          count: "-",
          rate: FOLIO_FORMAT(invoice?.subTotal),
          taxRate: FOLIO_FORMAT(invoice?.sgst + invoice?.cgst),
          amount: FOLIO_FORMAT(invoice?.total),
          hsn: "",
        };
        combinedPriceBreakup.push(obj);
      }
    });
  }

  let currentTaxObject = [];

  invoiceArray?.forEach((Obj) => {
    currentTaxObject = [...currentTaxObject, ...(Obj?.taxObjects || [])];
  });

  let updatedTaxObject = [];
  let taxMap = new Map();

  currentTaxObject?.forEach((Obj) => {
    const existingTax = taxMap.get(Obj?.taxName);
    if (existingTax) existingTax.taxAmount += Obj?.taxAmount;
    else taxMap.set(Obj?.taxName, { ...Obj });
  });

  updatedTaxObject = Array.from(taxMap.values());

  let finalTaxObject = updatedTaxObject?.map((Obj) => {
    return {
      title: `${Obj?.taxName} (${Obj?.taxPercentage}%)`,
      value: Obj?.taxAmount,
    };
  });

  let calculationArray = [
    { title: "Amount", value: subTotal + (discount ?? 0) },
    { title: "Discount", value: discount ?? 0 },
    { title: "Sub Total", value: subTotal },
    { title: "Add-ons", value: totalAddons },
    ...finalTaxObject,
    { title: "Total", value: totalBill ?? 0 },
    { title: "Payment Received", value: paymentReceived ?? 0 },
    { title: "Refund", value: totalRefund ?? 0 },
    { title: "Payment Due", value: +balanceDue ?? 0 },
  ];

  calculationArray = calculationArray
    ?.filter((Obj) => typeof Obj.value === "number" && !isNaN(Obj.value))
    ?.filter((Obj) => Obj?.value);

  calculationArray = calculationArray.map((Obj, idx) => {
    return { ...Obj, value: Obj.value?.toFixed(2) };
  });

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col6}>
              {invoiceTitleInformation?.map((Obj, index) => {
                return (
                  <View
                    style={
                      index === 0
                        ? styles.invoiceTitle
                        : index === 3
                        ? styles.titleDanger
                        : styles.defaultStyle
                    }
                  >
                    <Text>{Obj?.title}</Text>
                  </View>
                );
              })}

              {posName && (
                <Text className="mt-[10px] font-outfit text-[10px] leading-[10.08px] text-[#4d4d4d] bg-[#EBEBEB] px-[18px] py-[6px]">{`POS - ${posName}`}</Text>
              )}
            </View>

            <View
              style={[styles.col6, styles.headerRight]}
              className="border border-black"
            >
              {hotelInformation?.map((Obj, index) => {
                return index === 0 ? (
                  <View style={styles.row}>
                    <View>
                      {Boolean(hotelLogo) ? (
                        <View style={styles.logoDiv}>
                          <Image src={hotelLogo} style={styles.logo} alt="" />
                        </View>
                      ) : (
                        <></>
                      )}
                    </View>
                    <View>
                      <Text style={styles.hotelName}>{hotelDetails?.name}</Text>
                    </View>
                  </View>
                ) : (
                  Boolean(Obj?.title) && (
                    <View
                      style={{ ...styles.rightCenteredRow }}
                      key={Obj.title}
                    >
                      <View>
                        <Image src={Obj.Logo} style={styles.icon} alt="icon" />
                      </View>
                      <View>
                        <Text> {Obj?.title}</Text>
                      </View>
                    </View>
                  )
                );
              })}
            </View>
          </View>

          <View>
            <View
              style={{
                ...styles.row,
                marginTop: "15px",
              }}
            >
              {customerSection?.length > 0 ||
                (bookingSection?.length > 0 && (
                  <View className="flex gap-2 w-[100%] items-center">
                    <View className="font-outfit font-[500] text-[14px] w-[5%] whitespace-nowrap leading-[17.64px] text-[#000]">
                      <Text> Bill To :</Text>
                    </View>
                    <View className="border-[#EBEBEB] border-[1px] h-[1px] w-[90%] mx-2"></View>
                  </View>
                ))}

              <View style={styles.row}>
                <View style={styles.col6}>
                  {customerSection?.map((Obj, index) => {
                    return (
                      Boolean(Obj?.value) && (
                        <View style={{ ...styles.row, marginTop: "6px" }}>
                          <View style={styles.col5}>
                            <Text> {Obj?.title}</Text>
                          </View>
                          <View style={styles.col1}>
                            <Text> :</Text>
                          </View>
                          <View style={styles.col6}>
                            <Text> {Obj?.value}</Text>
                          </View>
                        </View>
                      )
                    );
                  })}
                </View>

                {bookingSection?.length > 0 && (
                  <View style={{ ...styles.col6 }}>
                    {bookingSection?.map((Obj, index) => {
                      return (
                        Boolean(Obj?.value) && (
                          <View style={{ ...styles.row, marginTop: "6px" }}>
                            <View style={styles.col5}>
                              <Text>{Obj?.title}</Text>
                            </View>
                            <View style={styles.col1}>
                              <Text>:</Text>
                            </View>
                            <View style={styles.col6}>
                              <Text>{Obj?.value}</Text>
                            </View>
                          </View>
                        )
                      );
                    })}
                  </View>
                )}
              </View>
            </View>
          </View>

          <View>
            <View
              style={{
                ...styles.row,
                marginTop: "20px",
                backgroundColor: "#F6F8FC",
              }}
            >
              {priceBreakupHead?.map((Obj, idx) => {
                const dynamicStyle = idx === 0 ? styles.col6 : styles.col3;
                return (
                  <View style={{ ...dynamicStyle, padding: "10px 0px" }}>
                    <Text>{Obj?.title}</Text>
                  </View>
                );
              })}
            </View>
            <View>
              {combinedPriceBreakup?.map((Obj, index) => {
                return (
                  <View
                    style={{
                      ...styles.row,
                      paddingTop: "10px",
                    }}
                  >
                    <View
                      style={{
                        ...styles.col6,
                        ...styles.row,
                        paddingRight: "10px",
                      }}
                      className="whitespace-wrap"
                    >
                      <Text style={{ marginRight: 5 }}>{index + 1}.</Text>
                      <Text style={{ marginRight: 5 }}>
                        {index + 1} &nbsp; {Obj?.description}
                      </Text>
                    </View>

                    <View style={styles.col3}>
                      <Text>{Obj?.hsn}</Text>
                    </View>

                    <View style={styles.col3}>
                      <Text>{Obj?.rate}</Text>
                    </View>

                    <View style={styles.col3}>
                      <Text>{Obj?.count}</Text>
                    </View>

                    <View style={styles.col3}>
                      <Text>{Obj?.nightsCount}</Text>
                    </View>
                    <View style={styles.col3}>
                      <Text>{Obj?.discount}</Text>
                    </View>

                    <View style={styles.col3}>
                      <Text>{Obj?.taxRate}</Text>
                    </View>
                    <View style={styles.col3}>
                      <Text>{Obj?.amount}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>

          <View
            style={{
              ...styles.row,
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <View
              style={{
                ...styles.col6,
              }}
            >
              {calculationArray?.map((Obj) => {
                return (
                  <View style={{ ...styles.row, margin: "4px 0px" }}>
                    <Text
                      style={{
                        ...styles.col6,
                      }}
                    >
                      {Obj?.title}
                    </Text>
                    <Text
                      style={{
                        ...styles.col6,
                        textAlign: "right",
                        fontSize: "8px",
                      }}
                    >
                      {Obj?.value}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>

          {showBankDataInInvoice ? (
            <View
              style={{
                marginTop: "10px",
              }}
            >
              <View
                style={{
                  ...styles.row,
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <View
                  style={{
                    ...styles.col2,
                  }}
                >
                  <Text
                    style={{
                      fontSize: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Bank Info :
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.col8,
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#EBEBEB",
                    marginTop: "7px",
                  }}
                ></View>
              </View>

              <View>
                {bankSection?.map((Obj, index) => {
                  return (
                    Boolean(Obj?.value) && (
                      <View style={{ ...styles.row, margin: "6px 0" }}>
                        <View style={styles.col3}>
                          <Text> {Obj?.title} </Text>
                        </View>
                        <View>
                          <Text> :</Text>
                        </View>
                        <View>
                          <Text>{Obj?.value}</Text>
                        </View>
                      </View>
                    )
                  );
                })}
              </View>

              <View
                style={{
                  marginTop: "10px",
                }}
              >
                {Boolean(props?.qrCodUrl) ? (
                  <Image src={props?.qrCodUrl} style={styles.qrImage} alt="" />
                ) : (
                  <> </>
                )}
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>

        <View style={styles.footer}>
          <View>
            <Text
              style={{
                fontSize: "8px",
              }}
            >
              Disclaimer: Checkin time {checkInTime} & Checkout time{" "}
              {checkOutTime} Noon | T&C Applied
            </Text>
          </View>

          <View style={styles.row}>
            <View style={styles.col8}>
              <Text
                style={{
                  textAlign: "center",
                  marginTop: "8px",
                  fontSize: "8px",
                }}
              >
                This is a computer generated Invoice, No Signature Needed
              </Text>
            </View>

            <View style={styles.col4}>
              <View
                style={{ ...styles.row, marginTop: "8px", fontSize: "8px" }}
              >
                <Text>Powered By revivo</Text>
                <Text
                  style={{
                    color: "#0A78FB",
                    marginLeft: "2px",
                  }}
                >
                  HOTEL-X
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceDocument;
