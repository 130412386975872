import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { editItemService } from "../services/editItem";

export const editItem = createAsyncThunk(
  "editItems/editItem",
  async (payload) => {
    try {
      const response = editItemService(payload);
      return response;
    } catch (err) {
      return err;
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const editItemsSlice = createSlice({
  name: "editUserItem",
  initialState,
  reducers: { CLEAR_REDUX_STORE_20: () => initialState },
  extraReducers: (builder) => {
    builder.addCase(editItem.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(editItem.fulfilled, (state, action) => {
      message.success("Item Updated Successfully!");
      state.data = [...state.data, action.payload];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(editItem.rejected, (state, action) => {
      message.error("Error in updating item!");
      state.error = action.error.message;
      state.loading = false;
      state.data = [];
    });
  },
});

export const { CLEAR_REDUX_STORE_20 } = editItemsSlice.actions;

export default editItemsSlice.reducer;
