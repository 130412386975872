import axios from "axios";
import { authedAxios } from "../utils/customHttpHeader";

const getPayments = async (payload) => {
  let url = `/payments/?hotelId=${payload.hotelId}`;

  if (payload.invoiceId) url += `&invoiceId=${payload.invoiceId}`;

  if (payload.startDate) url += `&startDate=${payload.startDate}`;

  if (payload.endDate) url += `&endDate=${payload.endDate}`;

  if (Boolean(payload.pageNumber)) {
    url += `&pageNumber=${payload.pageNumber}`;
  } else {
    url += `&pageNumber=${0}`;
  }

  if (Boolean(payload.pageSize)) {
    url += `&pageSize=${payload.pageSize}`;
  }

  if (payload.searchText) {
    url += `&searchText=${payload.searchText}`;
  }
  if (payload.sortingDirection) {
    url += `&sortingDirection=${payload.sortingDirection}`;
  }
  if (payload.sortingField) {
    url += `&sortingField=${payload.sortingField}`;
  }

  if (Boolean(payload?.partyIds)) {
    url += `&partyIds=${payload.partyIds}`;
  }

  if (Array.isArray(payload?.excludePaymentMethods)) {
    url += `&excludePaymentMethods=`;
  }

  const response = await authedAxios().get(url);
  return response;
};

const createPayment = async (payload) => {
  const response = await authedAxios().post("/payments", payload);
  return response;
};

const deleteAll = async (payload) => {
  const response = await axios.delete(
    `https://dev.revivotech.in/hotelx/payments/deleteAll?hotelId=${payload?.hotelId}&&paymentIds=${payload?.paymentIds}`
  );
  return response;
};

const multiPaymentUpdate = async (payload) => {
  const response = await authedAxios().put(`/payments/updateAll`, payload);
  return response;
};

const getPaymentById = async (payload) => {
  const response = await authedAxios().get(
    `/payments/${payload?.id}?hotelId=${payload?.hotelId}`
  );
  return response;
};

const getPaymentModesService = async (payload, endpoint) => {
  let url = endpoint;
  url += `?hotelId=${payload?.hotelId}`;

  const response = await authedAxios().get(url);
  return response;
};

const putPaymentService = async (endpoint, payload) => {
  const { hotelId, paymentObj } = payload;

  let url = endpoint;

  url += `/${paymentObj?.id}`;
  url += `?hotelId=${hotelId}`;

  const response = await authedAxios().put(url, paymentObj);
  return response;
};

const deletePaymentService = async (endpoint, payload) => {
  const { hotelId, id } = payload;
  let url = endpoint;

  url += `/${id}`;
  url += `?hotelId=${hotelId}`;

  const response = await authedAxios().delete(url);
  return response;
};

export {
  getPayments,
  createPayment,
  getPaymentById,
  deleteAll,
  multiPaymentUpdate,
  getPaymentModesService,
  putPaymentService,
  deletePaymentService,
};
