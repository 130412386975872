import React from "react";

export const Rooms = (props) => {
  const { roomTypes, onChange } = props;

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-10 mb-[100px]">
      {roomTypes?.map((room, index) => {
        return (
          <div className="checkbox-holder h-[4rem]" key={room?.type}>
            <input
              name={room?.type}
              id={room?.type}
              key={room?.type}
              type="checkbox"
              onChange={() => onChange(room)}
              className="hidden"
            />
            <label
              htmlFor={room?.type}
              className={`text-2xl block w-full  rounded-[0.5rem] border pl-5 py-3 hover:cursor-pointer ${
                !!room.available
                  ? "text-[#ffffff] bg-[#7E8082] border-[#A3A8AE] font-[600] text-[1.5rem] leading-6"
                  : "text-[#4F4F4F] bg-[#F4F4F4] border-[#4f4f4f] text-[1.5rem] font-[400] leading-6"
              }`}
            >
              {room.type}
            </label>
          </div>
        );
      })}
    </div>
  );
};
