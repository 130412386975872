import React from "react";
import { useSelector } from "react-redux";
import { FaArrowRight } from "react-icons/fa";

import { GET_ITEM_PRICE } from "../../../../utils/posHelper";

import "react-international-phone/style.css";

const Footer = ({
  orderDetailsScreenVisible,
  setOrderDetailScreenVisible,
  order,
  setMultipleKOTScreenVsisible,
}) => {
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);

  const { activePosType } = useSelector((store) => store.posSetting);

  const { selectedItem } = useSelector((store) => store.pos);

  const { data: allItems } = useSelector((store) => store.getItems);

  // seleceted food item with quantity

  let selectedItemWithQuantity = [];
  let itemCountMap = {};
  let itemTotalAmount = 0;
  let totalItem = 0;

  Object.keys(selectedItem)?.forEach((id) => {
    allItems?.forEach((item) => {
      if (item.id === id && selectedItem[id].count !== 0) {
        selectedItemWithQuantity.push({
          ...item,
          quantity: selectedItem[id].count,
        });

        itemTotalAmount +=
          GET_ITEM_PRICE(tableOrderDetails.type, activePosType, item) *
          selectedItem[id].count;

        itemCountMap[id] = {
          count: selectedItem[id].count,
          instruction: Boolean(selectedItem[id].instruction)
            ? selectedItem[id].instruction
            : null,
        };

        // calculate item addon price
        item.addOns?.forEach((addonItem) => {
          if (Boolean(selectedItem[id][addonItem.name])) {
            //  price * qty
            itemTotalAmount +=
              addonItem.price * selectedItem[id][addonItem.name];

            itemCountMap[id] = {
              ...itemCountMap[id],
              addOns: {
                ...itemCountMap?.addOns,
                [addonItem?.name]: selectedItem[id][addonItem.name],
              },
            };
          }
        });
      }
    });
  });

  for (const key in itemCountMap) {
    if (itemCountMap.hasOwnProperty(key)) {
      totalItem += itemCountMap[key].count;
    }
  }

  let orderLength = Array.isArray(order) ? order?.length : 0;

  return (
    <>
      {Boolean(totalItem) ? (
        <footer className=" bg-[#FFF] fixed bottom-0 w-[100%] shadow-mobile-footer flex justify-center items-center px-3 py-6">
          <div
            className="w-[95%] bg-[#007FFF] rounded-[12px] p-4 flex justify-between items-center"
            onClick={() => setOrderDetailScreenVisible(true)}
          >
            <div className="text-[#FFF] font-outfit font-[500] text-[14px] leading-[17.64px]">
              {Boolean(totalItem) ? totalItem : 0} Item | ₹{itemTotalAmount}
            </div>
            <div className="w-[20px] h-[20px] rounded-[50%] bg-[#FFF] flex justify-center items-center">
              <FaArrowRight className="text-[10px] text-[#007FFF]" />
            </div>
          </div>
        </footer>
      ) : Boolean(orderLength) ? (
        <footer className=" bg-[#FFF] fixed bottom-0 w-[100%] shadow-mobile-footer flex justify-center items-center px-3 py-6">
          <div
            className="w-[95%] bg-[#007FFF] rounded-[12px] text-[#FFF] p-4 flex justify-center items-center 
            font-outfit font-[500] text-[16px] leading-[24px]"
            onClick={() => setMultipleKOTScreenVsisible(true)}
          >
            All KOT
          </div>
        </footer>
      ) : (
        <></>
      )}
    </>
  );
};

export default Footer;
