import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoomsAsyncThunk } from "../../reducer/roomsReducer";
import { getHotelDetails } from "../../reducer/loginReducer";
import { updateIsOnline } from "../../reducer/appReducer";
import Offline from "../../pages/offline";
import { getAllPosSettingForm } from "../../reducer/posReducer/posSettingReducer";
import ErrorBoundary from "../ErrorBoundary";
import {
  getHotelWiseUserPermissions,
  getLoggedInUserPermission,
} from "../../reducer/userRestriction";
import { useLocation } from "react-router-dom";
import useRestriction from "../../hooks/protectedRoute/useRestriction";
import useMobileNavigation from "../../hooks/protectedRoute/useMobileNavigation";
import { getTax } from "../../reducer/taxReducer";
import { getAllAddOns } from "../../reducer/addOnReducer";
import { getPaymentModes } from "../../reducer/paymentReducer";
import { getBanks } from "../../reducer/bankReducer";

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    hotelDetails: HotelDetail,
    allHotelDetails,
    isAllHotelsSelected,
    selectedHotels,
  } = useSelector((store) => store.login);
  const { isOnline, activeMenu } = useSelector((store) => store.app);
  const { loggedInUserPermissions, currentUserPosIds, allRoles } = useSelector(
    (store) => store.usersRestriction
  );
  let location = useLocation();
  let segments = location.pathname.split("/");
  let lastSegment = segments.pop();
  const token = window.localStorage.getItem("token");

  useEffect(() => {
    window.scroll(0, 0);
    if (window.localStorage.getItem("token")) dispatch(getHotelDetails());

    checkOnline();
  }, []);

  useEffect(() => {
    if (HotelDetail?.id) {
      const allHotelIds = allHotelDetails?.map(({ id }) => id)?.toString();

      dispatch(getAllAddOns({ hotelId: HotelDetail?.id }));
      dispatch(getTax({ hotelId: HotelDetail?.id }));
      dispatch(getLoggedInUserPermission({ hotelId: HotelDetail?.id }));
      dispatch(getAllPosSettingForm(HotelDetail?.id));

      dispatch(getBanks({ hotelId: HotelDetail?.id }));

      dispatch(
        getHotelWiseUserPermissions({
          hotelIds: allHotelIds,
          phone: localStorage.getItem("phone"),
        })
      );
    }
    checkOnline();
  }, [HotelDetail?.id, allHotelDetails?.map(({ id }) => id).toString()]);

  useEffect(() => {
    const selectedHotelIds = selectedHotels?.toString();

    if (isAllHotelsSelected && selectedHotelIds?.length) {
      dispatch(getAllRoomsAsyncThunk({ hotelIds: selectedHotelIds }));
    } else if (HotelDetail?.id) {
      dispatch(getAllRoomsAsyncThunk({ hotelId: HotelDetail?.id }));
    }
  }, [selectedHotels?.toString(), isAllHotelsSelected, activeMenu]);

  useEffect(() => {
    if (HotelDetail?.id) {
      dispatch(getPaymentModes({ hotelId: HotelDetail?.id }));
    }
  }, [HotelDetail]);

  const checkOnline = () => {
    window.addEventListener(
      "load",
      function (e) {
        if (navigator.onLine) dispatch(updateIsOnline(true));
        else dispatch(updateIsOnline(false));
      },
      false
    );

    window.addEventListener(
      "online",
      function (e) {
        dispatch(updateIsOnline(true));
      },
      false
    );

    window.addEventListener(
      "offline",
      function (e) {
        dispatch(updateIsOnline(false));
      },
      false
    );
  };

  useRestriction();
  useMobileNavigation();

  if (!isOnline) return <Offline />;

  if (
    Array.isArray(loggedInUserPermissions) &&
    loggedInUserPermissions?.length > 0
  ) {
    if (
      (lastSegment === "bookings" || lastSegment === "bookingReservation") &&
      !(
        loggedInUserPermissions?.includes("BOOKING_VIEW") ||
        loggedInUserPermissions?.includes("BOOKING_CREATE") ||
        loggedInUserPermissions?.includes("BOOKING_UPDATE") ||
        loggedInUserPermissions?.includes("BOOKING_DELETE") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    )
      navigate("/dashboard");

    if (
      lastSegment === "walkin" &&
      !loggedInUserPermissions?.includes("BOOKING_CREATE")
    )
      navigate("/dashboard");

    if (
      lastSegment === "channel-manager" &&
      !(
        loggedInUserPermissions?.includes("RATES_AVAILABILITY_UPDATE") ||
        loggedInUserPermissions?.includes("RATES_AVAILABILITY_VIEW") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    )
      navigate("/dashboard");

    if (
      lastSegment === "clients" &&
      !(
        loggedInUserPermissions?.includes("CLIENT_VIEW") ||
        loggedInUserPermissions?.includes("CLIENT_UPDATE") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    )
      navigate("/dashboard");

    if (
      lastSegment === "house-keeping" &&
      !(
        loggedInUserPermissions?.includes("HOUSEKEEPING_MANAGE") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    )
      navigate("/dashboard");

    if (
      (lastSegment === "all-orders" ||
        lastSegment === "table-view" ||
        lastSegment === "new-order") &&
      currentUserPosIds?.length <= 0 &&
      !loggedInUserPermissions?.includes("OWNER")
    )
      navigate("/dashboard");

    if (
      lastSegment === "settings" &&
      !(
        loggedInUserPermissions?.includes("HOTEL_UPDATE") ||
        loggedInUserPermissions?.includes("HOTEL_VIEW") ||
        loggedInUserPermissions?.includes("USERS_VIEW") ||
        loggedInUserPermissions?.includes("USERS_MANAGE") ||
        loggedInUserPermissions?.includes("RATES_AVAILABILITY_UPDATE") ||
        loggedInUserPermissions?.includes("RATES_AVAILABILITY_VIEW") ||
        loggedInUserPermissions?.includes("ROOM_CREATE") ||
        loggedInUserPermissions?.includes("ROOM_UPDATE") ||
        loggedInUserPermissions?.includes("ROOM_VIEW") ||
        loggedInUserPermissions?.includes("OWNER") ||
        loggedInUserPermissions?.includes("POS_CREATE") ||
        loggedInUserPermissions?.includes("POS_UPDATE") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    )
      navigate("/dashboard");

    if (
      lastSegment === "payments" &&
      !(
        loggedInUserPermissions?.includes("PAYMENT_VIEW") ||
        loggedInUserPermissions?.includes("PAYMENT_CREATE") ||
        loggedInUserPermissions?.includes("PAYMENT_UPDATE") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    )
      navigate("/dashboard");

    if (
      lastSegment === "expense" &&
      !(
        loggedInUserPermissions?.includes("EXPENSE_VIEW") ||
        loggedInUserPermissions?.includes("EXPENSE_CREATE") ||
        loggedInUserPermissions?.includes("EXPENSE_UPDATE") ||
        loggedInUserPermissions?.includes("EXPENSE_DELETE") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    )
      navigate("/dashboard");

    if (
      lastSegment === "invoice" &&
      !(
        loggedInUserPermissions?.includes("INVOICE_VIEW") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    )
      navigate("/dashboard");

    if (
      lastSegment === "manageStocks" &&
      !(
        loggedInUserPermissions?.includes("STOCKS_VIEW") ||
        loggedInUserPermissions?.includes("STOCKS_UPDATE") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    ) {
      navigate("/dashboard");
    }

    if (
      lastSegment === "party" &&
      !(
        loggedInUserPermissions?.includes("PARTY_VIEW") ||
        loggedInUserPermissions?.includes("PARTY_CREATE") ||
        loggedInUserPermissions?.includes("PARTY_UPDATE") ||
        loggedInUserPermissions?.includes("PARTY_DELETE") ||
        loggedInUserPermissions?.includes("OWNER")
      )
    ) {
      navigate("/dashboard");
    }
  }

  if (!HotelDetail && Boolean(token)) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }

  if (loggedInUserPermissions?.length > 0) {
    return Boolean(token) ? (
      <ErrorBoundary>{children}</ErrorBoundary>
    ) : (
      <div className="flex justify-center items-center   w-[100vw] h-[100vh]">
        {" "}
        Something went wrong{" "}
      </div>
    );
  }
};

export default ProtectedRoute;
