import React, { useEffect, useState } from "react";
import { defaultRoomTypes } from "../constants";
import { Rooms } from "./RoomType";
import { isRoomTypeValid } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { updateHotel } from "../../../../reducer/loginReducer";
import NavigatingButtons from "../navigatingButtons";
import { updatehotel } from "../../../../services/hotel";
import { message } from "antd";
import { CircularProgress } from "@mui/material";
import {
  deleteMultipleRooms,
  getAllRooms,
  getInactiveRooms,
  updateRoom,
} from "../../../../services/rooms";
import { updateRoomList } from "../../../../reducer/roomsReducer";

const AccomodationDetails = ({ decrementId, incrementId }) => {
  const [roomTypes, setRoomTypes] = useState([...defaultRoomTypes]);
  const { hotelDetails } = useSelector((store) => store.login);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const rooms = useSelector((store) => store.rooms);
  const [inactiveRooms, setInactiveRooms] = useState([]);

  useEffect(() => {
    if (hotelDetails?.roomTypes) {
      const rooms = hotelDetails.roomTypes
        ?.filter(
          (item) =>
            !defaultRoomTypes.some(
              (defaultItem) => defaultItem.type === item.name
            )
        )
        .map((room) => ({
          type: room.name,
          available: true,
        }));

      const updatedRooms = [...roomTypes, ...rooms].map((room) => ({
        ...room,
        available: hotelDetails.roomTypes.some(
          (type) => type.name === room.type
        ),
      }));

      setRoomTypes(updatedRooms);
    }
    getInactiveRoomsFunction();
  }, []);

  const getInactiveRoomsFunction = async () => {
    const payload = { hotelId: hotelDetails?.id };
    const response = await getInactiveRooms(payload);
    setInactiveRooms(response?.data);
  };

  const addRoomType = (e) => {
    e.preventDefault();
    const roomType = e.target.roomType.value;

    if (!isRoomTypeValid(roomType, roomTypes)) return;

    const newRooms = [...roomTypes, { type: roomType, available: true }];

    setRoomTypes(newRooms);
    e.target.roomType.value = null;
  };

  const onRoomSelected = (room) => {
    const index = roomTypes?.findIndex((item) => item.type === room.type);
    if (index !== -1) {
      roomTypes[index].available = !roomTypes[index].available;
      setRoomTypes([...roomTypes]);
    }
  };

  const updateAccomodation = async (e) => {
    setLoading(true);
    e.preventDefault();

    let newlyAddedRoomType = [];
    let removedRoomType = roomTypes
      ?.filter((Obj) => !Obj.available)
      ?.map((Obj) => Obj.type);

    const roomsCreatedEarlier = Boolean(hotelDetails && hotelDetails?.roomTypes)
      ? hotelDetails.roomTypes
      : [];

    newlyAddedRoomType = roomTypes
      ?.filter(
        (Obj) => !roomsCreatedEarlier.some((room) => room.name === Obj.type)
      )
      .filter((Obj) => Obj.available)
      .map((Obj) => Obj.type);

    let updatedRooms = roomsCreatedEarlier.filter((room) =>
      roomTypes?.some(
        (roomType) => roomType?.type === room?.name && roomType?.available
      )
    );

    roomTypes?.forEach((roomType) => {
      const roomExists = roomsCreatedEarlier.some(
        (room) => room.name === roomType.type
      );
      const roomAlreadyAdded = updatedRooms.some(
        (updatedRoom) => updatedRoom.name === roomType.type
      );

      if (roomType?.available && !roomExists && !roomAlreadyAdded) {
        updatedRooms.push({ name: roomType.type });
      }
    });

    let deleteRoomIds = [];
    let newRoomIdsObject = [];

    removedRoomType?.forEach((roomType) => {
      rooms?.data?.map((Obj) => {
        if (Obj.type === roomType) {
          deleteRoomIds?.push(Obj?.id);
        }
      });
    });

    newlyAddedRoomType?.forEach((roomType) => {
      inactiveRooms?.map((Obj) => {
        if (Obj.type === roomType) {
          newRoomIdsObject?.push({ ...Obj, status: "active" });
        }
      });
    });

    let deletepayload = {
      hotelId: hotelDetails?.id,
      data: deleteRoomIds,
    };

    // Make Room inactive for Deleted Room Type
    await deleteMultipleRooms(deletepayload);

    updateAsyncFunction();
    // Assuming the surrounding function is asynchronous
    async function updateAsyncFunction() {
      for (const roomObj of newRoomIdsObject) {
        await updateRoom(roomObj);
      }
    }

    const payload = {
      hotelId: hotelDetails?.id,
      body: {
        ...hotelDetails,
        roomTypes: updatedRooms,
      },
    };
    let response = await updatehotel(payload);

    if (Boolean(response)) {
      dispatch(updateHotel(response.data));
      message.success("Room Type Updated");
      performApi();
      incrementId();
    } else {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  const performApi = async () => {
    const payload = { hotelId: hotelDetails?.id };
    const { data } = await getAllRooms(payload, "rooms");

    if (data !== null) {
      // api success phase
      dispatch(
        updateRoomList({
          loading: false,
          response: data,
          apiError: false,
        })
      );
    } else {
      // api success phase
      dispatch(
        updateRoomList({
          loading: false,
          response: [],
          apiError: true,
        })
      );
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-[2rem] font-[600] font-[Poppins] tracking-[0.07813rem] uppercase text-[#4f4f4f]">
          Select Accomodation
        </h2>
      </div>

      <form
        onSubmit={addRoomType}
        className="flex justify-between items-center shadow-lg py-[45px] px-[24px] rounded-[10px]"
      >
        <input
          type="text"
          placeholder="Add new room type"
          name="roomType"
          className="w-[100%] border-[1px] border-[#E5E5E5] rounded-[10px] p-[20px] mr-5 outline-none"
        />
        <button
          type="submit"
          className="bg-[#007FFF] w-[13rem] text-white text-lg font-semibold uppercase px-[50px] h-full py-[18px] rounded-[10px] cursor-pointer"
        >
          Add
        </button>
      </form>

      <form onSubmit={updateAccomodation} className="mt-[20px]">
        <p className="text-[24px] text-[#000000] my-[45px]">
          Tap to select Room types
        </p>

        <Rooms roomTypes={roomTypes} onChange={onRoomSelected} />

        {loading ? (
          <div className="w-[100%] bg-[#FFF] h-[7rem] fixed flex items-center  justify-end left-0 bottom-0 right-0 py-5  shadow-custom">
            <div className="flex gap-8 mr-[4%]">
              <button
                type="submit"
                className="w-[15rem] h-[3.5rem] flex justify-center items-center  text-[#007FFF] font-[600] rounded-[0.5rem] shadow-custom uppercase text-[1.125rem] cursor-pointer"
                disabled={true}
              >
                <CircularProgress size={16} />
              </button>
              <button
                type="submit"
                className="bg-[#007FFF] w-[15rem] h-[3.5rem] flex justify-center items-center  shadow-custom rounded-[0.5rem] font-[600] font-[Poppins] text-[1.125rem] uppercase text-[#FFF] cursor-pointer"
                disabled={true}
              >
                <CircularProgress size={16} color={"inherit"} />
              </button>
            </div>
          </div>
        ) : (
          <NavigatingButtons
            currentId={2}
            incrementId={updateAccomodation}
            decrementId={decrementId}
          ></NavigatingButtons>
        )}
      </form>
    </div>
  );
};

export default AccomodationDetails;
