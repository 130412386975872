import { authedAxios } from "../utils/customHttpHeader";

const getChannelsListService = async (payload, endpoint) => {
  const response = await authedAxios().get(endpoint);
  return response.data;
};

const getChannelMappingService = async (payload, endpoint) => {
  const response = await authedAxios().get(endpoint);
  return response.data;
};

const getChannelManagerListService = async (endpoint) => {
  const response = await authedAxios().get(endpoint);
  return response;
};

const deleteChannelService = async (endpoint) => {
  const response = await authedAxios().delete(endpoint);
  return response.data;
};

const postChannelService = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response.data;
};

const putChannelService = async (payload, endpoint) => {
  const response = await authedAxios().put(endpoint, payload);
  return response.data;
};
const postTestConnectionService = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response.data;
};

const postFirstTimeMappingService = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response.data;
};

const getIFrameTokenService = async (payload) => {
  const endpoint = `/api/v1/auth/one_time_token`;
  const data = {
    one_time_token: {
      property_id: payload?.cmId,
      username: payload?.name,
    },
  };
  // let config = {
  //   headers: {
  //     "user-api-key": payload?.cmToken,
  //   },
  // };

  const response = await authedAxios(null, payload?.cmToken).post(
    endpoint,
    data
  );
  return response;
};

export {
  getChannelsListService,
  postTestConnectionService,
  putChannelService,
  postChannelService,
  deleteChannelService,
  getChannelManagerListService,
  getChannelMappingService,
  postFirstTimeMappingService,
  getIFrameTokenService,
};
