import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Recount from "./Recount";
import Restock from "./Restock";
import ListView from "./ListView";

const Content = () => {
  const { activeView } = useSelector((store) => store.manageStocks);

  return (
    <div className="w-full h-[91.25%] pl-[30px] pt-[25px] pb-[20px]">
      {activeView === "list" ? (
        <ListView />
      ) : activeView === "recount" ? (
        <Recount />
      ) : (
        <Restock />
      )}
    </div>
  );
};

export default Content;
