import React, { useContext, useEffect, useState } from "react";

import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { SelectedItemContext } from "../../../Index";
import { GoPlus } from "react-icons/go";
import { CiCirclePlus } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { TranslateText } from "../../../utils/translation";

import {
  updateAction,
  updateSelectedItem,
} from "../../../reducer/getItemReducer";

function OrderPreview() {
  const userItemsState = useSelector((state) => state.userItem);

  const dispatch = useDispatch();

  const [reviewSaleData, setReviewSaleData] = useState();
  // const [isItemSelected, setIsItemSelected] = useState(false);

  const [open, setOpen] = useState(true);

  const showModal = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   setIsItemSelected(!isItemSelected);
  // }, [userItemsState.items.length === 0]);

  const handleSelectedItem = () => {
    // setIsItemSelected((prev) => (reviewSaleTouched === true ? false : true));
  };

  const handleReviewSale = () => {
    // setReviewSaleData(userItemsState.items);
    // setReviewSaleTouched((prev) => !prev);
  };

  const addItem = () => {
    dispatch(updateAction("add"));
    dispatch(updateSelectedItem({}));
  };

  return (
    <div className=" flex flex-col justify-between items-center relative min-h-screen">
      {/* <Button className="bg-primary" type="primary" shape="circle">
        <GoPlus className="text-2xl text-center" /> <p>Add Item</p>
      </Button> */}

      <div className="absolute   bottom-10 h-screen flex items-center">
        <div
          onClick={() => addItem()}
          className="text-center  flex cursor-pointer"
          type="primary"
        >
          <FaPlus className=" text-primary   mt-1  font-semibold" />
          <h1 className="text-primary font-semibold">
          <TranslateText textKey={"add_item"} namespace="pos" />

            </h1>
        </div>
      </div>

      <div className="mb-10 rounded-none flex gap-6"></div>
    </div>
  );
}

export default OrderPreview;
