function formatLatestDay() {
  const currentDate = new Date();
  const options = {
    weekday: "long",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formattedLatestDay = currentDate.toLocaleDateString("en-IN", options);

  return formattedLatestDay;
}

export const latestDayFormatted = formatLatestDay();

export function decodeTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = {
    weekday: "long",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-IN", options);
  return formattedDate;
}
