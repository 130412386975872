import { authedAxios } from "../utils/customHttpHeader";

const getCMAvailabilityRate = async (payload) => {
  const queryString = `cm/availabilityRate?hotelId=${payload.hotelId}&startDate=${payload.startDate}&endDate=${payload.endDate}&roomType=${payload.roomType}`;
  const response = await authedAxios().get(queryString);
  return response;
};

const postCMAvailabilityRatesBulkUpdate = async (payload) => {
  const queryString = "cm/availabilityRate/bulkUpdate";
  const response = await authedAxios().post(queryString, payload);
  return response;
};

export { getCMAvailabilityRate, postCMAvailabilityRatesBulkUpdate };
