import { ErrorMessage } from "./Constants";
import { message } from "antd";

export const isFileSizeValid = (file, allowedSize, errorMsg) => {
  if (!file) return;
  const size = (file.size / 1024 / 1024).toFixed(2);
  if (size > allowedSize) return false;

  return true;
}; 

export const isRoomTypeValid = (roomType, roomTypes) => {
  if (roomType === "" || roomTypes?.some((i) => i.type === roomType)) {
    message.error(ErrorMessage.invalidRoomType);
    return false;
  }
  return true;
};

export const getSelected = (data, selectionKey, returnKey) => {
  const selectedValues = [];
  if (!data) return null;

  data?.forEach((item) => {
    if (!!item[selectionKey]) selectedValues.push(item[returnKey]);
  });

  return selectedValues;
};

export const zoneOffset = [
  { label: "(UTC-12:00) International Date Line West", value: "-12:00" },
  { label: "(UTC-11:00) Coordinated Universal Time-11", value: "-11:00" },
  { label: "(UTC-10:00) Hawaii", value: "-10:00" },
  { label: "(UTC-09:00) Alaska", value: "-09:00" },
  { label: "(UTC-08:00) Pacific Time (US & Canada)", value: "-08:00" },
  { label: "(UTC-07:00) Mountain Time (US & Canada)", value: "-07:00" },
  { label: "(UTC-06:00) Central Time (US & Canada)", value: "-06:00" },
  { label: "(UTC-05:00) Eastern Time (US & Canada)", value: "-05:00" },
  { label: "(UTC-04:00) Atlantic Time (Canada)", value: "-04:00" },
  { label: "(UTC-03:30) Newfoundland", value: "-03:30" },
  { label: "(UTC-03:00) Buenos Aires", value: "-03:00" },
  { label: "(UTC-02:00) Mid-Atlantic", value: "-02:00" },
  { label: "(UTC-01:00) Azores", value: "-01:00" },
  { label: "(UTC+00:00) Greenwich Mean Time", value: "+00:00" },
  { label: "(UTC+01:00) Central European Time", value: "+01:00" },
  { label: "(UTC+02:00) Eastern European Time", value: "+02:00" },
  { label: "(UTC+03:00) Moscow, St. Petersburg", value: "+03:00" },
  { label: "(UTC+03:30) Tehran", value: "+03:30" },
  { label: "(UTC+04:00) Abu Dhabi, Muscat", value: "+04:00" },
  { label: "(UTC+04:30) Kabul", value: "+04:30" },
  { label: "(UTC+05:00) Islamabad, Karachi", value: "+05:00" },
  { label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: "+05:30" },
  { label: "(UTC+05:45) Kathmandu", value: "+05:45" },
  { label: "(UTC+06:00) Almaty, Novosibirsk", value: "+06:00" },
  { label: "(UTC+06:30) Yangon (Rangoon)", value: "+06:30" },
  { label: "(UTC+07:00) Bangkok, Hanoi, Jakarta", value: "+07:00" },
  { label: "(UTC+08:00) Beijing, Hong Kong, Singapore", value: "+08:00" },
  { label: "(UTC+09:00) Tokyo, Seoul", value: "+09:00" },
  { label: "(UTC+09:30) Adelaide, Darwin", value: "+09:30" },
  { label: "(UTC+10:00) Brisbane, Sydney", value: "+10:00" },
  { label: "(UTC+11:00) Solomon Islands, New Caledonia", value: "+11:00" },
  { label: "(UTC+12:00) Auckland, Wellington", value: "+12:00" },
  { label: "(UTC+13:00) Nuku'alofa", value: "+13:00" },
];
