import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { IoAddOutline } from "react-icons/io5";
import { Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateItemName } from "../../../../reducer/getItemReducer";
import { updateIsAddItem } from "../../../../reducer/appReducer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectInput({
  label,
  multiple,
  menuItem,
  value,
  name,
  handleChange,
  addNewItem,
  handleAddItem,
  width,
  color,
  background,
  fontSize,
  fontWeight,
  borderRadius,
}) {
  const dispatch = useDispatch();
  const { itemName } = useSelector((store) => store.getItems);
  const { isAddedItem } = useSelector((store) => store.app);
  let List = Array.isArray(menuItem) ? menuItem : [];

  return (
    <div>
      <FormControl
        sx={{
          width: "100%",
          background: "#ffff",
          "& label": {
            color: "#808080",
            fontFamily: "Nunito",
            fontSize: "0.625rem",
            fontWeight: "300",
          },
          "& input": {
            fontFamily: "Poppins",
            fontSize: "0.75rem",
            fontWeight: "400",
            color: "#4D4D4D",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },
          "& .MuiInputLabel-root": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: ".9rem",
            fontFamily: "nunito",
            background: "transparent",
            color: "#808080",
          },
        }}
      >
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={multiple}
          value={value}
          name={name}
          onChange={(e) =>
            !Boolean(isAddedItem == name) ? handleChange(e) : null
          }
          input={<OutlinedInput label={label} />}
          renderValue={multiple ? (selected) => selected.join(", ") : false}
          MenuProps={MenuProps}
          className="h-[3rem] py-1 relative"
        >
          {!(isAddedItem == name) && Array.isArray(menuItem) ? (
            List?.map((name) => (
              <MenuItem key={name} value={name}>
                {multiple && <Checkbox checked={value.indexOf(name) > -1} />}
                <ListItemText primary={name} />
              </MenuItem>
            ))
          ) : (
            <></>
          )}

          {Boolean(addNewItem) ? (
            <div className="bg-[#F4F4F4] py-4">
              {isAddedItem == name ? (
                <div>
                  <div className="px-3 ">
                    <div className="flex">
                      <input
                        onChange={(e) =>
                          dispatch(updateItemName(e.target.value))
                        }
                        className="px-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.2 dark:bg-gray-700 dark:border-gray-600 focus:border-gray-600 focus:outline-none dark:placeholder-gray-400 dark:text-white"
                        placeholder={`Enter ${name} name`}
                        onBlur={(e) => updateItemName(itemName)}
                        value={itemName}
                      />

                      {Boolean(itemName) ? (
                        <Button
                          onClick={() => handleAddItem(itemName)}
                          className="ml-2"
                        >
                          Add
                        </Button>
                      ) : (
                        <Button disabled={true} className="ml-2">
                          Add
                        </Button>
                      )}
                    </div>

                    <div
                      onClick={() => dispatch(updateIsAddItem(""))}
                      className=" text-primary text-xs py-2 mr-2 cursor-pointer text-right"
                    >
                      close
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => dispatch(updateIsAddItem(name))}
                  className="flex ml-2 cursor-pointer"
                >
                  {" "}
                  <IoAddOutline className="mr-1 mt-1 text-primary" />{" "}
                  <span className="text-primary"> Add New</span>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </Select>
      </FormControl>
    </div>
  );
}
