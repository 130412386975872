import React, { useEffect } from "react";

import ItemCard from "../itemsCard/ItemCard";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllItems } from "../../../../reducer/getItemReducer";
import { POS_SERVICE_TYPE, POS_TYPE } from "../../../../utils/posHelper";
import { ReactComponent as OvenAndCook } from "../../../../assets/images/svgs/ovenAndCook.svg";
import Loader from "../../../../components/common/Loader/Index";

const AllItems = ({ setAddItemSectionVisible, activeMenu, setActiveMenu }) => {
  const dispatch = useDispatch();
  const { tableOrder } = useSelector((state) => state.pos);
  const { activePosId, activePosType } = useSelector(
    (store) => store.posSetting
  );
  const { hotelDetails } = useSelector((store) => store.login);

  let allItems = useSelector((state) =>
    Boolean(state?.getItems?.data) ? state?.getItems.data : []
  );
  const { loading: itemLoading, error: itemError } = useSelector(
    (store) => store.getItems
  );
  const { searchItemText, filteredFoodType } = useSelector(
    (store) => store.pos
  );

  useEffect(() => {
    let serviceTypes =
      tableOrder?.type === "table" ? POS_SERVICE_TYPE[0] : tableOrder?.type;

    dispatch(
      getAllItems({
        hotelId: hotelDetails.id,
        posId: activePosId,
        serviceTypes: activePosType === POS_TYPE[0] ? serviceTypes : null,
      })
    );
  }, [hotelDetails?.id, activePosId]);

  allItems = Array.isArray(allItems)
    ? allItems?.filter((item) => item.active)
    : [];

  let categoryFilteredItems =
    activeMenu === "All Menu"
      ? allItems
      : allItems?.filter((item) => item?.category === activeMenu);

  let filterItems = Boolean(searchItemText)
    ? categoryFilteredItems?.filter((item) => {
        return (
          item.name?.toLowerCase().includes(searchItemText.toLowerCase()) ||
          item.category?.toLowerCase().includes(searchItemText.toLowerCase())
        );
      })
    : categoryFilteredItems;

  filterItems = Boolean(filteredFoodType)
    ? filterItems.filter((item) => {
        if (filteredFoodType === "veg") {
          return item.cuisineType === "veg";
        } else {
          return item.cuisineType === "nonVeg";
        }
      })
    : filterItems;

  return (
    <div className="pt-[1.5rem] px-5">
      <div className="flex flex-col overflow-y-auto  h-[80vh] pb-[10rem]">
        {itemLoading ? (
          <Loader
            width="100%"
            height="3rem"
            noOfContainer={10}
            flexCol={true}
            gap={8}
            marginTop="1rem"
          />
        ) : itemError == false ? (
          <div className="text-center py-5 text-red-500">
            Something went wrong!
          </div>
        ) : (
          <>
            {filterItems?.length > 0 ? (
              <>
                {filterItems?.map((item, idx) => {
                  return (
                    <div className="flex flex-col items-center  justify-center ">
                      <ItemCard
                        key={item.id}
                        item={item}
                        start={Boolean(idx === 0)}
                        end={Boolean(idx === filterItems?.length - 1)}
                      />
                      {idx !== filterItems?.length - 1 && (
                        <div className="border-[0.6px] border-[#EBEBEB] w-[80%] mx-3" />
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="bg-[#FFF] flex flex-col items-center justify-center gap-[2rem] rounded-[12px] h-[80vh]">
                <div className="font-outfit font-[700] text-[24px] leading-[48px] text-[#000]">
                  {" "}
                  Currently!!
                </div>
                <OvenAndCook className="text-[140px] w-[140px] h-[140px]" />
                <div className="font-outfit font-[500] text-[20px] leading-[40px] text-[#4D4D4D]">
                  There are no items to serve.
                </div>
                <div
                  className="bg-[#007FFF] text-[#FFF] rounded-[8px] flex justify-center items-center py-[12px] px-[24px]
                font-outfit font-[500] text-[16px] leading-[24px]"
                  onClick={() => setAddItemSectionVisible(true)}
                >
                  Add Item
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AllItems;
