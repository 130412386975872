import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveMenu } from "../../reducer/appReducer";
import { Button } from "antd";
import { CircularProgress } from "@mui/material";

export const EmptyPosScreen = () => {
  const [localLoading, setLocalLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activePosSlug, response } = useSelector((store) => store.posSetting);

  const redirect = () => {
    dispatch(updateActiveMenu({ activeMenu: "settings" }));
    navigate("/dashboard/settings");
  };

  useEffect(() => {
    if (Array.isArray(response) && response.length > 0)
      navigate(`/dashboard/${activePosSlug}/all-orders`);
    else setLocalLoading(false);
  }, [activePosSlug, response, navigate]);

  if (localLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <CircularProgress size={32} />
      </div>
    );
  }

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="text-center flex flex-col items-center justify-center">
        <h1 className="text-3xl text-[#222850] font-bold">Oops!</h1>
        <h2 className="text-red-500 mt-4 text-2xl font-bold">
          You have no Active Pos
        </h2>
        <p className="text-[#222850] w-[80%] m-auto mt-3">
          Navigate to the <span className="font-semibold">settings page</span>{" "}
          and click on 'Point of Sale' to create new POS. Or contact
          <span className="font-bold"> support@revivotech.in</span> or{" "}
          <span className="font-bold">+91 8800750756</span>
        </p>

        <Button
          onClick={redirect}
          className="bg-primary mt-5 text-white font-bold rounded"
        >
          <BsArrowLeftCircleFill className="mr-1 inline-block" />
          <span>Go to settings</span>
        </Button>
      </div>
    </div>
  );
};
