import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { addItems } from "../../../../reducer/posReducer/restaurant";
import { getAllItems } from "../../../../reducer/getItemReducer";
import { POS_SERVICE_TYPE, POS_TYPE } from "../../../../utils/posHelper";

const SliderComponent = ({ activeMenu, setActiveMenu }) => {
  const dispatch = useDispatch();
  const { tableOrder } = useSelector((state) => state.pos);
  const { activePosId, activePosType } = useSelector(
    (store) => store.posSetting
  );
  const { hotelDetails } = useSelector((store) => store.login);

  let allItems = useSelector((state) =>
    Boolean(state?.getItems?.data) ? state?.getItems.data : []
  );

  useEffect(() => {
    let serviceTypes =
      tableOrder?.type === "table" ? POS_SERVICE_TYPE?.[0] : tableOrder?.type;

    dispatch(
      getAllItems({
        hotelId: hotelDetails.id,
        posId: activePosId,
        serviceTypes: activePosType === POS_TYPE?.[0] ? serviceTypes : null,
      })
    );
  }, [hotelDetails?.id, activePosId]);

  allItems = Array.isArray(allItems)
    ? allItems?.filter((item) => item.active)
    : [];

  const { activePosSlug, response } = useSelector((store) => store.posSetting);

  const categories = response?.filter(
    (item) => item.slug === activePosSlug
  )?.[0]?.itemCategories;

  let Categories = categories?.length > 0 ? ["All Menu", ...categories] : [];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1024, // Desktop and above
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600, // Mobile and below
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          touchMove: true,
          dots: true,
        },
      },
    ],
  };

  const handleMenu = (menu) => {
    setActiveMenu(menu);

    const filteredItemsByCategory = allItems?.filter(
      (item) => item.category?.toLowerCase() === menu?.toLowerCase()
    );

    dispatch(
      menu?.toLowerCase() === "all menu"
        ? addItems(allItems)
        : addItems(filteredItemsByCategory)
    );
  };

  return (
    <div>
      <Slider {...settings} className=" flex overflow-hidden">
        {Categories?.map((item) => {
          return (
            <div
              onClick={() => handleMenu(item)}
              className={`${
                activeMenu === item
                  ? "bg-[#2F80ED] text-[#FFF] font-[500]"
                  : "text-[#222222] bg-[#FFF] font-[400]"
              } font-outfit text-[16px] leading-[20.16px] py-[5px]  px-[15px]  rounded-[8px]  
              text-center  whitespace-nowrap
              mb-[5px] cursor-pointer shadow-md`}
            >
              {item?.length > 14 ? item?.substring(0, 15) + ".." : item}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SliderComponent;
