import moment from "moment";
import React, { useMemo } from "react";
import { CALCULATE_NIGHTS_COUNT, FOLIO_FORMAT } from "../../../utils/helper";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { useSelector } from "react-redux";
import { getCurrentCurrency } from "../../../utils/customCurrencyHelper";
import {
  constructCombinedPriceBreakup,
  requiredRoomInvoiceBreakupFormat,
} from "../../../utils/invoiceHelper";

const NewInvoiceStructure = (props) => {
  const { hotelDetails, hotelContentDetails } = useSelector(
    (store) => store.login
  );

  const showBankDataInInvoice = Boolean(hotelDetails?.showBankDetailsInInvoice);

  const { banksResponse } = useSelector((store) => store.bank);
  const bankDetails = Array.isArray(banksResponse)
    ? banksResponse?.filter((Obj) => Boolean(Obj?.defaultBankName))?.[0]
    : {};
  const qrCodUrl = bankDetails?.qrCodeUrl;

  const cancellationPolicyArray = Array.isArray(
    hotelContentDetails?.cancellationPoliciesText
  )
    ? hotelContentDetails?.cancellationPoliciesText?.slice(0, 3)
    : [];

  const { checkInTime, checkOutTime } = hotelDetails;
  const { symbol, position } = getCurrentCurrency(hotelDetails?.currency);

  const isPrint = props?.print;
  const isDownloadPdf = props?.download;
  let targetRef = isPrint ? props?.val : props?.targetRef;

  const isSingleInvoice = Boolean(props?.isSingleInvoice)
    ? props?.isSingleInvoice
    : false;

  const roomInvoice = props?.roomInvoice ?? {};
  const invoiceId = props?.invoiceId;

  const customerObject = props?.customerDetails;
  const bookingObject = props?.bookingDetails;
  const paymentArray = Array.isArray(props?.paymentDetails)
    ? props?.paymentDetails
    : [];

  let isCmIdPresent = Boolean(bookingObject?.cmId);

  let invoiceDetails = props?.invoiceDetails;

  const nightsCount = useMemo(
    () =>
      CALCULATE_NIGHTS_COUNT(
        bookingObject?.checkIn,
        bookingObject?.checkOut,
        hotelDetails
      ),
    [bookingObject?.checkIn, bookingObject?.checkOut, hotelDetails]
  );

  const invoiceArray = useMemo(
    () =>
      requiredRoomInvoiceBreakupFormat(
        invoiceDetails,
        isCmIdPresent,
        nightsCount ?? 1
      ),
    [invoiceDetails, isCmIdPresent, nightsCount]
  );

  const roomDetails = props?.roomDetails;
  const isSingleDownload = Boolean(props?.isSingleDownload)
    ? props?.isSingleDownload
    : false;

  const posData = props?.posData;

  // if (!Array.isArray(invoiceArray)) return;

  const bankSection = [
    {
      title: "Account Holder Name",
      value: Boolean(bankDetails?.accountHolderName)
        ? bankDetails?.accountHolderName?.toUpperCase()
        : null,
    },
    {
      title: "Bank Account Number",
      value: Boolean(bankDetails?.accountNumber)
        ? bankDetails?.accountNumber
        : null,
    },
    {
      title: "Bank Name",
      value: Boolean(bankDetails?.bankName)
        ? bankDetails?.bankName?.toUpperCase()
        : null,
    },
    {
      title: "IFSC Code",
      value: Boolean(bankDetails?.ifscCode)
        ? bankDetails?.ifscCode?.toUpperCase()
        : null,
    },
  ];

  const customerSection = [
    {
      title: "Name",
      value: Boolean(customerObject?.name) ? customerObject?.name : null,
    },
    {
      title: "Phone No",
      value: Boolean(customerObject?.phone) ? customerObject?.phone : null,
    },
    {
      title: "Email",
      value: Boolean(customerObject?.email) ? customerObject?.email : null,
    },
    {
      title: "Company Name",
      value: Boolean(customerObject?.company) ? customerObject?.company : null,
    },
    {
      title: "GSTIN",
      value: Boolean(customerObject?.gstIN) ? customerObject?.gstIN : null,
    },
    {
      title: "Address ",
      value: Boolean(customerObject?.address) ? customerObject?.address : null,
    },
  ];

  const hotelInformation = [
    {
      title: Boolean(hotelDetails && hotelDetails?.name)
        ? hotelDetails?.name
        : null,
      Logo: hotelDetails?.logo,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.address)
        ? hotelDetails?.address?.substring(0, 150)
        : null,
      Logo: IoLocationSharp,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.contactNumber)
        ? hotelDetails?.contactNumber
        : null,
      Logo: FaPhoneAlt,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.emailAddress)
        ? hotelDetails?.emailAddress
        : null,
      Logo: MdEmail,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.website)
        ? hotelDetails?.website
        : null,
      Logo: TbWorld,
    },
  ];

  let bookingSection = Boolean(
    props?.bookingDetails && Object?.keys(props?.bookingDetails)?.length > 0
  )
    ? [
        {
          title: "Room No / Plan",
          value: Boolean(roomDetails)
            ? `${roomDetails?.name} X ${roomDetails?.type} / ${bookingObject?.mealPlan}`
            : null,
        },
        {
          title: "Adult-Child",
          value: Boolean(bookingObject?.adults)
            ? `${bookingObject?.adults} - ${bookingObject?.children} `
            : null,
        },
        {
          title: "Check-in/Check-out",
          value: Boolean(bookingObject?.checkIn && bookingObject?.checkOut)
            ? `${moment(bookingObject?.checkIn)?.format(
                "D-MM-YYYY"
              )} | ${moment(bookingObject?.checkOut)?.format("D-MM-YYYY")}`
            : null,
        },
        {
          title: "Booking Source",
          value: Boolean(bookingObject?.source) ? bookingObject?.source : null,
        },
      ]
    : [];

  const priceBreakupHead = [
    { title: " # Item & Description ", width: "30%" },
    { title: "HSN/SAC", width: "15%" },
    { title: "Rate", width: "15%" },
    { title: "Count", width: "15%" },
    { title: "Nights", width: "15%" },
    { title: "Discount", width: "15%" },
    { title: "TAX", width: "15%" },
    { title: "TOTAL", width: "15%" },
  ];

  let combinedPriceBreakup = [];

  combinedPriceBreakup = constructCombinedPriceBreakup(
    invoiceArray,
    posData,
    nightsCount || 1
  );

  let paymentReceived = 0;
  let totalBill = 0;
  let totalRefund = 0;
  let balanceDue = 0;
  let discount = 0;
  let subTotal = 0;
  let totalAddons = 0;

  invoiceArray?.forEach((Obj) => {
    subTotal += Obj?.subTotal;
    totalBill += Obj?.total;
    discount += Obj?.discount;
    paymentReceived += Obj?.paid;
    totalRefund += Obj?.refund;
  });

  let addOns = roomInvoice?.priceBreakup?.find((Obj) => Obj?.type === "Addons");

  if (addOns)
    addOns?.addOns?.forEach((Obj) => (totalAddons += +Obj?.price * Obj?.count));

  if (!isSingleInvoice) {
    paymentReceived = 0;
    totalRefund = 0;

    Array.isArray(paymentArray) &&
      paymentArray
        ?.map((obj) => obj.amount)
        ?.filter((Obj) => Obj < 0)
        .map((obj) => (totalRefund += -obj));

    Array.isArray(paymentArray) &&
      paymentArray
        ?.map((obj) => obj.amount)
        ?.filter((Obj) => Obj > 0)
        .map((obj) => (paymentReceived += obj));
  }

  balanceDue = FOLIO_FORMAT(totalBill - paymentReceived + totalRefund);

  const invoiceTitleInformation = [
    {
      title: "INVOICE",
    },
    {
      title: Boolean(invoiceId) ? invoiceId : "Tentative",
    },
    {
      title: Boolean(hotelDetails?.gstIN)
        ? `GSTIN: ${hotelDetails?.gstIN}`
        : "",
    },
    {
      title: `Payment Due: ${`${position === "before" ? symbol : ""} ${
        Boolean(balanceDue) ? balanceDue : "00"
      } ${position === "after" ? `${symbol}` : ""}`}`,
    },
    {
      title: Boolean(roomInvoice?.createdAt)
        ? moment(roomInvoice?.createdAt)?.format("DD MMM, YYYY")
        : "",
    },
  ];

  let combinedPriceBreakupStartIndexAfter25 =
    combinedPriceBreakup?.length > 25 ? 26 : null;
  let combinedPriceBreakupEndIndexAfter25 =
    combinedPriceBreakup?.length > 26 ? combinedPriceBreakup?.length : null;

  let hotelLogo = hotelDetails?.logo;

  let posName = Boolean(props?.posObj) ? props?.posObj?.name : "";

  // if summarized feild is present in hotel invoice settings, and we are at the "ALL" tab, then alter the combined array
  if (props?.isSummarized && !props?.isSingleInvoice) {
    // first filtering out invoices which are not of type roomBill
    combinedPriceBreakup = combinedPriceBreakup?.filter(
      (obj) => obj.invoiceType == "roomBill"
    );

    props?.invoiceDetails?.forEach((invoice) => {
      const posSacCode = posData?.find(
        (Obj) => Obj?.id === invoice?.posId
      )?.sac;

      if (invoice?.invoiceType == "pos") {
        let obj = {
          description: Boolean(invoice?.invoiceId) ? invoice?.invoiceId : "-",
          count: "-",
          rate: FOLIO_FORMAT(invoice?.subTotal),
          taxRate: FOLIO_FORMAT(invoice?.sgst + invoice?.cgst),
          amount: FOLIO_FORMAT(invoice?.total),
          hsn: posSacCode,
        };
        combinedPriceBreakup.push(obj);
      }
    });
  }

  let currentTaxObject = [];

  invoiceArray?.forEach((Obj) => {
    currentTaxObject = [...currentTaxObject, ...(Obj?.taxObjects || [])];
  });

  let updatedTaxObject = [];
  let taxMap = new Map();

  currentTaxObject?.forEach((Obj) => {
    const existingTax = taxMap.get(Obj?.taxName);
    if (existingTax) existingTax.taxAmount += Obj?.taxAmount;
    else taxMap.set(Obj?.taxName, { ...Obj });
  });

  updatedTaxObject = Array.from(taxMap.values());

  let finalTaxObject = updatedTaxObject?.map((Obj) => {
    return {
      title: `${Obj?.taxName} (${Obj?.taxPercentage}%)`,
      value: Obj?.taxAmount,
    };
  });

  let calculationArray = [
    { title: "Amount", value: subTotal + (discount ?? 0) },
    { title: "Discount", value: discount ?? 0 },
    { title: "Sub Total", value: subTotal },
    { title: "Add-ons", value: totalAddons },
    ...finalTaxObject,
    { title: "Total", value: totalBill ?? 0 },
    { title: "Payment Received", value: paymentReceived ?? 0 },
    { title: "Refund", value: totalRefund ?? 0 },
    { title: "Payment Due", value: +balanceDue ?? 0 },
  ];

  calculationArray = calculationArray
    ?.filter((Obj) => typeof Obj.value === "number" && !isNaN(Obj.value))
    ?.filter((Obj) => Obj?.value);

  calculationArray = calculationArray.map((Obj, idx) => {
    return { ...Obj, value: Obj.value?.toFixed(2) };
  });

  return (
    <div
      id="invoice"
      className={`p-7  flex flex-col gap-[2rem] ${
        isDownloadPdf ? "absolute top-[400vh] w-[60%] " : "w-[100%]"
      } ${isSingleDownload ? "w-[60vw]" : ""} `}
      ref={targetRef}
    >
      <div>
        <header className="flex justify-between mb-3">
          <div className="leftSection-hotelInformation flex flex-col gap-1 ">
            {invoiceTitleInformation?.map((Obj, index) => {
              return (
                <div
                  className={`${
                    index === 0
                      ? " text-[#000] font-[600] text-[20px]"
                      : " text-[#4D4D4D] font-[400] text-[10px] "
                  } ${
                    index === 3 ? "text-[#F36960] font-[600] " : ""
                  } font-outfit`}
                >
                  {Obj?.title}
                </div>
              );
            })}

            {posName && (
              <p className="mt-[10px] font-outfit text-[10px]  text-[#4d4d4d] bg-[#EBEBEB] px-[18px] py-[6px]">{`POS - ${posName}`}</p>
            )}
          </div>

          <div className="rightSection-PaymentTitleInformation flex flex-col items-end">
            {hotelInformation?.map((Obj, index) => {
              return index === 0 ? (
                <div className=" flex gap-2 items-center">
                  <div>
                    {Boolean(hotelLogo) ? (
                      <div className="w-[30px] h-[30px] rounded-[4px]">
                        <img
                          src={hotelLogo}
                          className="w-[100%] h-[100%] rounded-[4px]"
                          alt=""
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="font-outfit text-[#000] font-[600] text-[14px] leading-[21px]">
                    {hotelDetails?.name}
                  </div>
                </div>
              ) : (
                Boolean(Obj?.title) && (
                  <div className="flex gap-1 items-center" key={Obj.title}>
                    <div
                      className={`${
                        isDownloadPdf ? "mt-[8px]" : ""
                      } bg-[#EBEBEB] rounded-[100%] p-[2px]`}
                    >
                      <Obj.Logo className="text-[#4D4D4D] text-[10px]" />
                    </div>
                    <div className="font-outfit text-[#1A1C21] font-[400] text-[10px] leading-[15px]">
                      {Obj?.title}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </header>

        <section
          className={` ${
            isDownloadPdf ? "mb-4" : ""
          } flex w-[100%] gap-[2rem] flex-col justify-center`}
        >
          <div className="billToSection flex flex-col gap-2">
            <div className="flex gap-2 w-[100%] items-center">
              <div className="font-outfit font-[600] text-[10px] w-[5%] whitespace-nowrap leading-[17.64px] text-[#5E6470]">
                Bill To :
              </div>
              <div className="border-[#EBEBEB] border-[1px] h-[1px] w-[90%] mx-2"></div>
            </div>

            <div className="flex w-[100%] ">
              <div className="w-[50%] ">
                {customerSection?.map((Obj, index) => {
                  return (
                    Boolean(Obj?.value) && (
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-2 w-[100%] font-outfit text-[10px] leading-[18px]">
                          <div className=" font-[400]  w-[12ch] whitespace-nowrap    text-[#5E6470]">
                            {Obj?.title} :
                          </div>
                          <div className=" text-[#222B45]  font-[500] w-[60%]">
                            {Obj?.value}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>

              {bookingSection?.length > 0 && (
                <div className="w-[50%]  flex flex-col  ">
                  {bookingSection?.map((Obj, index) => {
                    return (
                      Boolean(Obj?.value) && (
                        <div className="flex flex-col gap-2  w-[80%]">
                          <div className="font-outfit text-[10px] leading-[18px] flex gap-2 w-[100%] justify-end self-end">
                            <div className=" font-[400]  w-[35%] whitespace-nowrap text-[#5E6470]">
                              {Obj?.title} :
                            </div>
                            <div className=" font-[500]  w-[30%] text-[#222B45] whitespace-nowrap ">
                              {Obj?.value}
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>

        <main className="priceBreakupSection mt-2">
          {/* <div className="w-[100%] h-[1px] border-[#717171] border-[0.5px]" /> */}
          <div className="flex justify-between items-start bg-[#F6F8FC] w-[100%] my-2 px-4 py-2">
            {priceBreakupHead?.map((Obj, idx) => {
              return (
                <div
                  className={` w-[${Obj?.width}] 
                   font-outfit font-[600] text-[8px] leading-[12.1px] tracking-wide
              text-[#5E6470] uppercase `}
                >
                  {Obj?.title}
                </div>
              );
            })}
          </div>

          <div className="body w-[100%]">
            {combinedPriceBreakup?.slice(0, 25)?.map((Obj, index) => {
              return (
                <div className="flex justify-between px-4 py-2 font-outfit font-[500] text-[8px] leading-[12px] text-[#222B45]">
                  <div className="w-[30%]">
                    {index + 1} &nbsp;
                    {Obj?.description}
                  </div>

                  <div className="w-[15%] ">{Obj?.hsn}</div>

                  <div className="w-[15%] pl-1">{Obj?.rate}</div>

                  <div className="w-[15%] pl-2">{Obj?.count}</div>
                  <div className="w-[15%] pl-2">{Obj?.nightsCount}</div>

                  <div className="w-[15%] pl-2 ">{Obj?.discount}</div>

                  <div className="w-[15%] ">{Obj?.taxRate}</div>
                  <div className="w-[15%] ">{Obj?.amount}</div>
                </div>
              );
            })}
          </div>
        </main>

        {combinedPriceBreakup?.length > 25 && (
          <>
            <div className="page-break my-5" />
            <div className="body w-[100%]">
              {combinedPriceBreakup
                ?.slice(
                  combinedPriceBreakupStartIndexAfter25,
                  combinedPriceBreakupEndIndexAfter25
                )
                ?.map((Obj, index) => {
                  return (
                    <div className="flex justify-between px-4 py-2 font-outfit font-[500] text-[8px] leading-[12px] text-[#222B45]">
                      <div className="w-[30%]">
                        {index + 1} &nbsp;
                        {Obj?.description}
                      </div>

                      <div className="w-[15%] ">{Obj?.hsn}</div>

                      <div className="w-[15%] pl-1">{Obj?.rate}</div>

                      <div className="w-[15%] pl-2">{Obj?.count}</div>
                      <div className="w-[15%] pl-2">{Obj?.nightsCount}</div>

                      <div className="w-[15%] pl-2 ">{Obj?.discount}</div>

                      <div className="w-[15%] ">{Obj?.taxRate}</div>
                      <div className="w-[15%] ">{Obj?.amount}</div>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        <div
          className={`calculations w-full flex justify-end pr-[30px] my-5 pt-3`}
          style={{
            break: true,
            pageBreakInside: "avoid",
            marginTop: "auto",
          }}
        >
          <div className="w-[40%]  mb-5">
            {calculationArray?.map((Obj) => {
              return (
                <div
                  className="flex text-[10px] font-outfit leading-[12px] 
                text-[#222B45] w-full justify-between mb-[10px]"
                >
                  <p className=" ">{Obj?.title}</p>
                  <p className="">{Obj?.value}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {showBankDataInInvoice ? (
        <div
          className="bankDetailsSection w-[100%] flex flex-col justify-start items-start"
          style={{
            break: true,
            pageBreakInside: "avoid",
            marginTop: "auto",
          }}
        >
          <div className="w-[50%] ">
            {bankSection?.map((Obj, index) => {
              return (
                Boolean(Obj?.value) && (
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 w-[100%]">
                      <div className="font-outfit font-[400] text-[8px] leading-[18px] w-[18ch] text-[#000]">
                        {Obj?.title} :
                      </div>
                      <div
                        className={` index === 0 ? "text-[#000]" : "text-[#4D4D4D]" } font-outfit font-[500] text-[8px] 
                        leading-[18px] w-[60%] tracking-[wider] `}
                      >
                        {Obj?.value}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>

          <div>
            {Boolean(qrCodUrl) ? (
              <img src={qrCodUrl} className="w-[150px] h-[150px]" alt="" />
            ) : (
              <> </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="content-to-print mt-5">
        <footer
          id="footer"
          style={{
            break: true,
            pageBreakInside: "avoid",
            marginTop: "auto",
          }}
          className={` ${
            isPrint ? "" : ""
          } footer absolute   w-[100%] font-[600] text-[11px] leading-[14px] px-[10px] mb-[10px]  break-avoid bottom-0`}
        >
          <div className="mb-[5px]">
            <p className="font-outfit text-[#222B45]  font-[400] text-[8px] leading-[13px]">
              <span className="font-[500]"> Disclaimer:</span> Checkin time{" "}
              {checkInTime} & Checkout time {checkOutTime} Noon | T&C Applied
            </p>

            <div className="flex flex-col gap-1">
              {cancellationPolicyArray?.map((Obj) => {
                return (
                  <p className="font-outfit font-[500] text-[8px] leading-[13px]">
                    {Obj}
                  </p>
                );
              })}
            </div>
          </div>

          <div className="border-[#AEAEAE] bprder-[0.6px] w-[100%]"></div>

          <div class="grid grid-cols-3 gap-4">
            <div>
              <p className="font-outfit  font-[500] text-[8px] leading-[10.08px]">
                Powered By <span className="text-[#2F80ED]">revivo</span>{" "}
                <span className="text-[#007FFF] ">HOTEL-X</span>
              </p>
            </div>
            <div>
              <p className="text-[#000000B2] font-outfit font-[400] text-[8px]">
                This is a computer generated Invoice, No Signature Needed
              </p>
            </div>
            <div></div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default NewInvoiceStructure;
