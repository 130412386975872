export const SORT_SELECTED_ROOM_TYPE_AND_NUMBER_OBJECT_BY_ROOM_PRICE = (
  roomPriceStructure,
  selectedRoomWTypeAndNum,
  source = null
) => {
  // start---> sort object from lowest room price to higest room price  to occupy

  let sortedRoomPriceStructure = Array.isArray(roomPriceStructure)
    ? [...roomPriceStructure]?.sort((a, b) => a.rate - b.rate)
    : [];

  // Create a new object with room types sorted based on the sorted rates
  const sortedSelectedRoomWTypeAndNum = {};

  sortedRoomPriceStructure.forEach((item) => {
    const roomType = Boolean(source == "bookingDetails")
      ? item?.type
      : item.name;

    if (selectedRoomWTypeAndNum[roomType]?.length > 0) {
      sortedSelectedRoomWTypeAndNum[roomType] =
        selectedRoomWTypeAndNum[roomType];
    }
  });

  return { sortedSelectedRoomWTypeAndNum };

  // end---> sort object from lowest room price to higest room price  to occupy
};
