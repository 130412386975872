import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Dropdown from "antd/es/dropdown/dropdown";
import { IoIosArrowDown } from "react-icons/io";

import {
  updateSelectedPos,
  updateActiveView,
  updateManageStocksDropdownVisible,
} from "../../../reducer/manageStocksReducer";
import { TranslateText } from "../../../utils/translation";

const ChangeSelectedPosDropdown = () => {
  const { manageStocksDropdownVisible } = useSelector(
    (store) => store.manageStocks
  );
  const { response, loading } = useSelector((store) => store.posSetting);
  const { selectedPos } = useSelector((store) => store.manageStocks);

  const dispatch = useDispatch();

  const handleManageStocksDropdownChange = (visible) => {
    dispatch(updateManageStocksDropdownVisible(visible));
  };

  const handleChangeSelectedPos = (pos) => {
    dispatch(updateSelectedPos(pos));
    dispatch(updateActiveView("list"))
    dispatch(updateManageStocksDropdownVisible(false));
  };

  return (
    <Dropdown
      trigger={["click"]}
      onOpenChange={handleManageStocksDropdownChange}
      open={manageStocksDropdownVisible}
      dropdownRender={() => (
        <div className="w-[270px] bg-[#fff] rounded-[10px]">
          {Array.isArray(response) ? (
            <div className="w-full">
              {response
                ?.filter(
                  ({ type }) => type === "Restaurant" || type === "Retail"
                )
                ?.map((pos) => {
                  const { name, id } = pos;
                  return (
                    <div
                      key={id}
                      onClick={() => handleChangeSelectedPos(pos)}
                      className={`w-full h-[40px] flex items-center justify-start cursor-pointer pl-[16px] ${
                        selectedPos?.id === id ? "bg-[#DFEEFD]" : ""
                      }`}
                    >
                      <span>{name}</span>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div>
              <p>
              <TranslateText textKey={"no_pos_available"} namespace="manageStocks" />

              </p>
            </div>
          )}
        </div>
      )}
    >
      <div
        className="flex flex-nowrap justify-center items-center text-[15px] lg:text-[18px]  xl:text-[22px] 
            leading-[29px] lg:leading-[33px] font-[600]  cursor-pointer text-[#545454] h-[4rem] "
      >
        <div className="flex">
          {selectedPos?.name}
          <span className="text-sm mt-2 ml-1">({selectedPos?.type})</span>
          <IoIosArrowDown className="text-primary mt-1" />
        </div>
      </div>
    </Dropdown>
  );
};

export default ChangeSelectedPosDropdown;
