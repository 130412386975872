import React, { useEffect, useState } from "react";
import { Button, Drawer } from "antd";
import CheckIn from "../components/CheckIn";
import ExtraOption from "../components/ExtraOptions";
import Payments from "../components/Payments";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  updateShowTakePaymentDrawer,
  updateDisplayNoShowModal,
  updateDisplayCancelBookingModal,
  updateShowApplyDiscountDrawer,
  resetBookingDetails,
  updateShowRefundPaymentDrawer,
  updateShowAttachToPartyDrawer,
  updateDisplayDeletePartyModal,
} from "../../../reducer/bookingReducer";
import ConfirmationModalNoShow from "../Modal/ConfirmationModalNoShow";
import DeletePartyModal from "../Modal/DeletePartyModal";
import CancelBookingModal from "../Modal/CancelBookingModal";
import CheckInDrawer from "./CheckInDrawer";
import { CircularProgress } from "@mui/material";
import { FaUndo } from "react-icons/fa";
import RefundIcon from "../../../assets/images/svgs/Refund.svg";
import { getPartyById } from "../../../reducer/partyReducer";
import AddOnsContainer from "../components/AddOnsContainer";
import { bookingDetailsDrawerWidth } from "../../../utils/bookingDetailsHelper";
import useGetMealPlanRate from "../../../hooks/bookingCustomHook/useGetPlanRate";
import useAllBookingFunction from "../../../hooks/bookingCustomHook/useAllBookingFunction";

import { FaLink } from "react-icons/fa6";
import { BiEdit } from "react-icons/bi";
import { GoTrash } from "react-icons/go";
import EditPaymentDrawer from "../../common/components/UpdatePaymentDrawer/EditPaymentDrawer";

const MainBookingDetailsDrawer = () => {
  const dispatch = useDispatch();

  const {
    showMainBookingDetailsDrawer,
    responseDetails,
    bookingDetails,
    loadingDetails,
    invoiceDetails,
    paymentDetails,
    bookingHotelId,
    roomPriceStructure,
    mealPlan,
  } = useSelector((store) => store.booking);

  const { editBookingAllowed, deleteBookingAllowed } = useSelector(
    (store) => store.usersRestriction
  );

  const bookingType = showMainBookingDetailsDrawer?.bookingType;
  const [loading, setLoading] = useState(false);
  const [attachedPartyName, setAttachedPartyName] = useState("");

  const { isAllHotelsSelected } = useSelector((store) => store.login);

  const { hotelDetails } = useSelector((store) => store.login);

  const {
    handleCheckIn,
    handleCheckOut,
    handleUndoCheckIn,
    handleFolio,
    handleGrc,
    handleConfirmBooking,
  } = useAllBookingFunction(setLoading);

  useGetMealPlanRate(roomPriceStructure, mealPlan);

  const handleClose = () => dispatch(resetBookingDetails());

  let isPosBillAttached = Array.isArray(invoiceDetails)
    ? Boolean(
        invoiceDetails?.filter((obj) => obj.invoiceType === "pos")?.length > 0
      )
    : false;

  let isPartyAttached = paymentDetails?.find(
    (payment) => payment?.method === "btc"
  );

  useEffect(() => {
    let isPartyAttached = paymentDetails?.find(
      (payment) => payment?.method === "btc"
    );

    setAttachedPartyName("");

    async function performApi(isPartyAttached) {
      if (
        isPartyAttached &&
        isPartyAttached?.paymentModePartyId &&
        bookingHotelId
      ) {
        const res = await dispatch(
          getPartyById({
            hotelId: bookingHotelId,
            id: isPartyAttached?.paymentModePartyId,
          })
        );
        if (res?.payload?.status == 200) {
          setAttachedPartyName(res?.payload?.data?.displayName);
        }
      }
    }

    performApi(isPartyAttached);
  }, [paymentDetails]);

  const hideElement = {
    display: `${isAllHotelsSelected && "none"}`,
  };

  let isCmIdPresent = Boolean(bookingDetails?.cmId);
  const allowEditingAfterCheckout = hotelDetails?.allowEditingAfterCheckout;

  let isBookingAlterationAllowed = Boolean(
    editBookingAllowed &&
      (bookingType !== "completed" || allowEditingAfterCheckout) &&
      !isAllHotelsSelected
  );

  return (
    <Drawer
      title={
        loadingDetails ? (
          <></>
        ) : (
          <div className="text-[1rem] font-nunito bg-[#FFF] no-scrollbar::-webkit-scrollbar no-scrollbar">
            <div>
              <span className="text-[#808080] font-[500] text-[1rem] ">
                Booking Id:
              </span>
              {showMainBookingDetailsDrawer?.newBookingId ? (
                <span className="text-[#4D4D4D] font-[600]  font-nunito text-[1rem]">
                  {showMainBookingDetailsDrawer?.newBookingId}
                </span>
              ) : (
                <span className="text-[#808080] font-[600]  font-[Poppins] ">
                  {" "}
                  Id not generated
                </span>
              )}
            </div>

            <div>
              <span className="text-[#808080] font-[500] text-[0.875rem">
                Source:
              </span>{" "}
              <span className="text-[#4D4D4D] font-nunito font-[600] text-[0.875rem] capitalize">
                {responseDetails?.bookingDetails?.source ?? "Walk In"}
              </span>
            </div>

            <div>
              <span className="text-[#808080] font-[500] text-[0.875rem">
                Meal Plan:
              </span>{" "}
              <span className="text-[#4D4D4D] font-nunito font-[600] text-[0.875rem] capitalize">
                {responseDetails?.bookingDetails?.mealPlan ?? "EP"}
              </span>
            </div>
          </div>
        )
      }
      closeIcon={<></>}
      closable={true}
      onClose={handleClose}
      open={showMainBookingDetailsDrawer?.visible}
      width={bookingDetailsDrawerWidth}
      extra={
        loadingDetails ? (
          <></>
        ) : (
          <div className="flex justify-center items-center  flex-col lg:flex-row flex-wrap gap-2 mr-2 lg:mr-5 bg-[#FFF]">
            <Button
              onClick={handleFolio}
              className="bg-[#526EFF] bg-opacity-5 border-none rounded-lg text-[#007FFF] font-medium text-[14px]"
            >
              View Bills
            </Button>
            <Button
              onClick={handleGrc}
              className="bg-[#526EFF] bg-opacity-5 border-none rounded-lg text-[#007FFF] font-medium text-[14px]"
            >
              GRC
            </Button>
            <CloseOutlined
              onClick={handleClose}
              className=" customCloseIconContainer"
            />
          </div>
        )
      }
      mask={true}
    >
      {loadingDetails ? (
        <div className="w-[100%] h-[100%] flex justify-center items-center">
          <CircularProgress size={32} sx={{ color: "#007FFF" }} />
        </div>
      ) : (
        <div className="no-scrollbar::-webkit-scrollbar no-scrollbar">
          <div
            className=" flex w-[100%]  flex-col gap-3 pb-10 mb-10 overflow-auto 
          no-scrollbar::-webkit-scrollbar no-scrollbar"
          >
            <CheckIn className="w-[90%]" bookingType={bookingType} />

            {isBookingAlterationAllowed ? <AddOnsContainer /> : <></>}
            <ExtraOption bookingType={bookingType} />
            <ConfirmationModalNoShow />
            <DeletePartyModal />
            <CancelBookingModal />
            <CheckInDrawer />

            {showMainBookingDetailsDrawer?.visible ? (
              <EditPaymentDrawer
                isBookingListDrawer={true}
                drawerWidth={"800px"}
              />
            ) : (
              <></>
            )}
          </div>

          {(showMainBookingDetailsDrawer?.bookingType === "upcoming" ||
            showMainBookingDetailsDrawer?.bookingType === "confirmed" ||
            showMainBookingDetailsDrawer?.bookingType === "new") && (
            <div className=" absolute  right-[-1px] bottom-[-1px] flex flex-col items-center  gap-2 w-[100%]  text-[1.125rem] font-[600] bg-[#FFF] border border-t-[#00000015] rounded-t-[0.9375rem]">
              <Payments
                bookingType={bookingType}
                isCmIdPresent={isCmIdPresent}
              />
              <>
                {editBookingAllowed && deleteBookingAllowed && (
                  <>
                    <div
                      className="w-[100%] flex justify-center gap-3  pt-2"
                      style={hideElement}
                    >
                      {isCmIdPresent ? (
                        <></>
                      ) : (
                        <button
                          className="text-[0.875rem] bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                          onClick={() =>
                            dispatch(updateShowApplyDiscountDrawer())
                          }
                        >
                          {Array.isArray(invoiceDetails) &&
                          invoiceDetails?.filter(
                            (Obj) => Obj?.invoiceType === "roomBill"
                          )?.[0]?.discount
                            ? " Edit Discount"
                            : " Apply Discount"}
                        </button>
                      )}

                      <button
                        className="text-[0.875rem] bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                        onClick={() => dispatch(updateDisplayNoShowModal())}
                        hideElement
                      >
                        No Show
                      </button>

                      <button
                        className="text-[0.875rem] flex gap-2 justify-center items-center bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                        onClick={() =>
                          dispatch(updateShowRefundPaymentDrawer())
                        }
                        style={hideElement}
                      >
                        <img src={RefundIcon} alt="" />
                        Refund
                      </button>

                      <button
                        className="text-[0.875rem] text-[#F36960] font-[500] bg-[#526EFF0D] px-2 py-2 rounded-md"
                        onClick={() =>
                          dispatch(updateDisplayCancelBookingModal())
                        }
                      >
                        Cancel Booking
                      </button>
                    </div>

                    <div className=" w-[100%] " style={hideElement}>
                      <button
                        className="w-[50%] bg-[#EAF2FD] py-2 text-[#007FFF] "
                        onClick={() => dispatch(updateShowTakePaymentDrawer())}
                      >
                        TAKE PAYMENT
                      </button>
                      {loading ? (
                        <button className="w-[50%] text-[#FFF] py-2  bg-[#007FFF]">
                          <CircularProgress size={16} color="inherit" />
                        </button>
                      ) : (
                        <>
                          <button
                            className="w-[50%] text-[#FFF] py-2  bg-[#007FFF] "
                            onClick={handleCheckIn}
                          >
                            CHECK-IN
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}

                {editBookingAllowed && !deleteBookingAllowed && (
                  <>
                    <div className="w-[100%] flex justify-center gap-3  pt-2">
                      {isCmIdPresent ? (
                        <></>
                      ) : (
                        <button
                          className="text-[0.875rem] bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                          onClick={() =>
                            dispatch(updateShowApplyDiscountDrawer())
                          }
                        >
                          {Array.isArray(invoiceDetails) &&
                          invoiceDetails?.filter(
                            (Obj) => Obj?.invoiceType === "roomBill"
                          )?.[0]?.discount
                            ? " Edit Discount"
                            : " Apply Discount"}
                        </button>
                      )}

                      <button
                        className="text-[0.875rem] flex gap-2 justify-center items-center bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                        onClick={() =>
                          dispatch(updateShowRefundPaymentDrawer())
                        }
                        style={hideElement}
                      >
                        <img src={RefundIcon} alt="" />
                        Refund
                      </button>
                    </div>

                    <div className=" w-[100%] " style={hideElement}>
                      <button
                        className="w-[50%] bg-[#EAF2FD] py-2 text-[#007FFF] "
                        onClick={() => dispatch(updateShowTakePaymentDrawer())}
                      >
                        TAKE PAYMENT
                      </button>
                      {loading ? (
                        <button className="w-[50%] text-[#FFF] py-2  bg-[#007FFF]">
                          <CircularProgress size={16} color="inherit" />
                        </button>
                      ) : (
                        <>
                          <button
                            className="w-[50%] text-[#FFF] py-2  bg-[#007FFF] "
                            onClick={handleCheckIn}
                          >
                            CHECK-IN
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}

                {!editBookingAllowed && deleteBookingAllowed && (
                  <>
                    <div
                      className="w-[100%] flex justify-center gap-3  pt-2"
                      hideElement
                    >
                      <button
                        className="text-[0.875rem] bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                        onClick={() => dispatch(updateDisplayNoShowModal())}
                      >
                        No Show
                      </button>
                      <button
                        className="text-[0.875rem] text-[#F36960] font-[500] bg-[#526EFF0D] px-2 py-2 rounded-md"
                        onClick={() =>
                          dispatch(updateDisplayCancelBookingModal())
                        }
                      >
                        Cancel Booking
                      </button>
                    </div>

                    <div className=" w-[100%] " hideElement>
                      <button
                        className="w-[100%] text-[#FFF] py-2  bg-[#007FFF] "
                        onClick={() => dispatch(updateShowTakePaymentDrawer())}
                      >
                        TAKE PAYMENT
                      </button>
                    </div>
                  </>
                )}

                {!editBookingAllowed && !deleteBookingAllowed && (
                  <div className="w-[100%]">
                    {" "}
                    <button
                      className="w-[100%] text-[#FFF] py-2  bg-[#007FFF] "
                      onClick={() => dispatch(updateShowTakePaymentDrawer())}
                      style={hideElement}
                    >
                      TAKE PAYMENT
                    </button>
                  </div>
                )}
              </>
            </div>
          )}

          {(showMainBookingDetailsDrawer?.bookingType === "checkedIn" ||
            showMainBookingDetailsDrawer?.bookingType === "ongoing") && (
            <div className=" absolute  right-[-1px] bottom-[-1px] flex flex-col gap-2 w-[100%] items-center text-[1.125rem] font-[600] bg-[#FFF] border border-t-[#00000015] rounded-t-[0.9375rem]">
              <Payments
                bookingType={bookingType}
                isCmIdPresent={isCmIdPresent}
              />
              <>
                {editBookingAllowed && (
                  <>
                    <div className="flex gap-3">
                      {isPartyAttached ? (
                        <div
                          className="text-xs flex gap-2 justify-center items-center 
                        bg-[#E5F3F3] text-[#119199] font-outfit font-[500] p-2 rounded-[8px]"
                        >
                          <FaLink />

                          <span className="pr-2">
                            Bill Attached to{" "}
                            {attachedPartyName?.substring(0, 15)}{" "}
                          </span>

                          <BiEdit
                            className="text-[#007FFF] cursor-pointer  h-[18px] w-[18px]"
                            onClick={() =>
                              dispatch(updateShowAttachToPartyDrawer(true))
                            }
                            style={hideElement}
                          />

                          <GoTrash
                            className="cursor-pointer text-[#FE594E] h-[18px] w-[18px]"
                            style={hideElement}
                            onClick={() =>
                              dispatch(
                                updateDisplayDeletePartyModal({
                                  display: true,
                                  partyName: attachedPartyName,
                                })
                              )
                            }
                          />
                        </div>
                      ) : (
                        <button
                          className="text-[0.875rem] flex gap-2 justify-center items-center bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                          onClick={() =>
                            dispatch(updateShowAttachToPartyDrawer(true))
                          }
                          style={hideElement}
                        >
                          Attach to Party
                        </button>
                      )}

                      {isCmIdPresent ? (
                        <></>
                      ) : (
                        <button
                          className="text-[0.875rem] bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                          onClick={() =>
                            dispatch(updateShowApplyDiscountDrawer())
                          }
                        >
                          {Array.isArray(invoiceDetails) &&
                          invoiceDetails?.find(
                            (Obj) => Obj?.invoiceType === "roomBill"
                          )?.discount
                            ? " Edit Discount"
                            : " Apply Discount"}
                        </button>
                      )}

                      <button
                        className="text-[0.875rem] flex gap-2 justify-center items-center bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                        onClick={() =>
                          dispatch(updateShowRefundPaymentDrawer())
                        }
                        style={hideElement}
                      >
                        <img src={RefundIcon} alt="" />
                        Refund
                      </button>

                      {isPosBillAttached ? (
                        <></>
                      ) : loading ? (
                        <div
                          className="text-[12px] flex items-center gap-2 text-[#808080] font-[400] font-[Poppins]
                   bg-[#526EFF0D] px-3 py-2 rounded-md cursor-pointer leading-[20px]"
                        >
                          {" "}
                          <CircularProgress size={16} color="inherit" />
                        </div>
                      ) : (
                        <div
                          className="text-[12px] flex items-center gap-2 text-[#808080] font-[400] font-[Poppins]
                   bg-[#526EFF0D] px-3 py-2 rounded-md cursor-pointer leading-[20px]"
                          onClick={handleUndoCheckIn}
                          style={hideElement}
                        >
                          <FaUndo
                            color="#808080"
                            className="font-[300] text-[11px]"
                          />

                          <p> Undo Checkin</p>
                        </div>
                      )}
                    </div>

                    <div className=" w-[100%] " style={hideElement}>
                      <button
                        className="w-[50%] bg-[#EAF2FD] py-2 text-[#007FFF] "
                        onClick={() => dispatch(updateShowTakePaymentDrawer())}
                      >
                        TAKE PAYMENT
                      </button>
                      {loading ? (
                        <button className="w-[50%] text-[#FFF] py-2  bg-[#007FFF]">
                          <CircularProgress size={16} color="inherit" />
                        </button>
                      ) : (
                        <>
                          <button
                            className="w-[50%] text-[#FFF] py-2  bg-[#007FFF] "
                            onClick={handleCheckOut}
                            disabled={loading}
                          >
                            CHECK-OUT
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}
                {!editBookingAllowed && (
                  <div className="w-[100%] flex justify-center gap-3  pt-2">
                    <button
                      className="w-[100%]  py-3 text-[#FFF] bg-[#007FFF] "
                      onClick={() => dispatch(updateShowTakePaymentDrawer())}
                      style={hideElement}
                    >
                      TAKE PAYMENT
                    </button>
                  </div>
                )}
              </>
            </div>
          )}

          {showMainBookingDetailsDrawer?.bookingType === "completed" && (
            <>
              <div
                className=" absolute  right-[-1px] bottom-[-8px] flex flex-col gap-2 w-[100%] pl-[-2rem]  
              items-center text-[1.125rem] font-[600] bg-[#FFF] border border-b-0 border-l-0 border-t-[#00000015] rounded-t-[0.9375rem] mb-2  "
              >
                <Payments
                  bookingType={bookingType}
                  isCmIdPresent={isCmIdPresent}
                />
                {editBookingAllowed && (
                  <div className="w-[100%] flex justify-center gap-5">
                    {isPartyAttached ? (
                      <div
                        className="text-xs flex gap-2 justify-center items-center 
                        bg-[#E5F3F3] text-[#119199] font-outfit font-[500] p-2 rounded-[8px]"
                      >
                        <FaLink />

                        <span className="pr-2">
                          Bill Attached to {attachedPartyName?.substring(0, 15)}{" "}
                        </span>

                        <BiEdit
                          className="text-[#007FFF] cursor-pointer  h-[18px] w-[18px]"
                          onClick={() =>
                            dispatch(updateShowAttachToPartyDrawer(true))
                          }
                          style={hideElement}
                        />

                        <GoTrash
                          className="cursor-pointer text-[#FE594E] h-[18px] w-[18px]"
                          style={hideElement}
                          onClick={() =>
                            dispatch(
                              updateDisplayDeletePartyModal({
                                display: true,
                                partyName: attachedPartyName,
                              })
                            )
                          }
                        />
                      </div>
                    ) : (
                      <button
                        className="text-[0.875rem] flex gap-2 justify-center items-center bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                        onClick={() =>
                          dispatch(updateShowAttachToPartyDrawer(true))
                        }
                        style={hideElement}
                      >
                        Attach to Party
                      </button>
                    )}

                    <button
                      className="text-[0.875rem] flex gap-2 justify-center items-center bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                      onClick={() => dispatch(updateShowRefundPaymentDrawer())}
                      style={hideElement}
                    >
                      <img src={RefundIcon} alt="" />
                      Refund
                    </button>
                  </div>
                )}
                <button
                  className="w-[100%]  py-3 text-[#FFF] bg-[#007FFF] "
                  onClick={() => dispatch(updateShowTakePaymentDrawer())}
                  style={hideElement}
                >
                  TAKE PAYMENT
                </button>
              </div>
            </>
          )}

          {showMainBookingDetailsDrawer?.bookingType === "enquiry" && (
            <div className=" absolute  right-[-1px] bottom-[-1px] flex flex-col gap-2 w-[100%] items-center text-[1.125rem] font-[600] bg-[#FFF] border border-t-[#00000015] rounded-t-[0.9375rem]">
              <Payments
                bookingType={bookingType}
                isCmIdPresent={isCmIdPresent}
              />

              {editBookingAllowed && deleteBookingAllowed && (
                <>
                  <div
                    className="w-[100%] flex justify-center gap-3  pt-2"
                    style={hideElement}
                  >
                    {isCmIdPresent ? (
                      <></>
                    ) : (
                      <button
                        className="text-[0.875rem] bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                        onClick={() =>
                          dispatch(updateShowApplyDiscountDrawer())
                        }
                      >
                        {Array.isArray(invoiceDetails) &&
                        invoiceDetails?.filter(
                          (Obj) => Obj?.invoiceType === "roomBill"
                        )?.[0]?.discount
                          ? " Edit Discount"
                          : " Apply Discount"}
                      </button>
                    )}
                    <button
                      className="text-[0.875rem] bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                      onClick={() => dispatch(updateDisplayNoShowModal())}
                    >
                      No Show
                    </button>
                    <button
                      className="text-[0.875rem] flex gap-2 justify-center items-center bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                      onClick={() => dispatch(updateShowRefundPaymentDrawer())}
                    >
                      <img src={RefundIcon} alt="" />
                      Refund
                    </button>
                    <button
                      className="text-[0.875rem] text-[#F36960] font-[500] bg-[#526EFF0D] px-2 py-2 rounded-md"
                      onClick={() =>
                        dispatch(updateDisplayCancelBookingModal())
                      }
                    >
                      Cancel Booking
                    </button>
                  </div>
                  <div className=" w-[100%] " style={hideElement}>
                    <button
                      className="w-[50%] bg-[#EAF2FD] py-2 text-[#007FFF] "
                      onClick={() => dispatch(updateShowTakePaymentDrawer())}
                    >
                      TAKE PAYMENT
                    </button>
                    {loading ? (
                      <button className="w-[50%] text-[#FFF] py-2  bg-[#007FFF]">
                        <CircularProgress size={16} color="inherit" />
                      </button>
                    ) : (
                      <>
                        <button
                          className="w-[50%] text-[#FFF] py-2  bg-[#007FFF] "
                          onClick={handleConfirmBooking}
                        >
                          Confirm Booking
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}

              {editBookingAllowed && !deleteBookingAllowed && (
                <>
                  <div className="w-[100%] flex justify-center gap-3  pt-2">
                    {isCmIdPresent ? (
                      <></>
                    ) : (
                      <button
                        className="text-[0.875rem] bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                        onClick={() =>
                          dispatch(updateShowApplyDiscountDrawer())
                        }
                      >
                        {Array.isArray(invoiceDetails) &&
                        invoiceDetails?.find(
                          (Obj) => Obj?.invoiceType === "roomBill"
                        )?.discount
                          ? " Edit Discount"
                          : " Apply Discount"}
                      </button>
                    )}

                    <button
                      className="text-[0.875rem] flex gap-2 justify-center items-center bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                      onClick={() => dispatch(updateShowRefundPaymentDrawer())}
                      style={hideElement}
                    >
                      <img src={RefundIcon} alt="" />
                      Refund
                    </button>
                  </div>
                  <div className=" w-[100%] " style={hideElement}>
                    <button
                      className="w-[50%] bg-[#EAF2FD] py-2 text-[#007FFF] "
                      onClick={() => dispatch(updateShowTakePaymentDrawer())}
                    >
                      TAKE PAYMENT
                    </button>
                    {loading ? (
                      <button className="w-[50%] text-[#FFF] py-2  bg-[#007FFF]">
                        <CircularProgress size={16} color="inherit" />
                      </button>
                    ) : (
                      <>
                        <button
                          className="w-[50%] text-[#FFF] py-2  bg-[#007FFF] "
                          onClick={handleConfirmBooking}
                        >
                          Confirm Booking
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}

              {!editBookingAllowed && deleteBookingAllowed && (
                <>
                  <div
                    className="w-[100%] flex justify-center gap-3  pt-2"
                    style={hideElement}
                  >
                    <button
                      className="text-[0.875rem] bg-[#526EFF0D] font-[500] text-[#007FFF] px-2 py-2 rounded-md"
                      onClick={() => dispatch(updateDisplayNoShowModal())}
                    >
                      No Show
                    </button>
                    <button
                      className="text-[0.875rem] text-[#F36960] font-[500] bg-[#526EFF0D] px-2 py-2 rounded-md"
                      onClick={() =>
                        dispatch(updateDisplayCancelBookingModal())
                      }
                    >
                      Cancel Booking
                    </button>
                  </div>
                  <div className=" w-[100%] " style={hideElement}>
                    <button
                      className="w-[100%] text-[#FFF] py-2  bg-[#007FFF] "
                      onClick={() => dispatch(updateShowTakePaymentDrawer())}
                    >
                      TAKE PAYMENT
                    </button>
                  </div>
                </>
              )}

              {!editBookingAllowed && !deleteBookingAllowed && (
                <div className="w-[100%]" style={hideElement}>
                  {" "}
                  <button
                    className="w-[100%] text-[#FFF] py-2  bg-[#007FFF] "
                    onClick={() => dispatch(updateShowTakePaymentDrawer())}
                  >
                    TAKE PAYMENT
                  </button>
                </div>
              )}
            </div>
          )}

          {showMainBookingDetailsDrawer?.bookingType === "noShow" && (
            <div className=" absolute  right-[-1px] bottom-[-1px] flex flex-col justify-center items-center  gap-2 w-[100%]  text-[1.125rem] mb-4 border-b-0 font-[600] bg-[#FFF] border border-t-[#00000015] rounded-t-[0.9375rem]">
              <Payments
                bookingType={bookingType}
                isCmIdPresent={isCmIdPresent}
              />{" "}
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default MainBookingDetailsDrawer;
