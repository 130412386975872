import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as SearchBar } from "../../../../assets/images/svgs/SearchBar.svg";

import {
  resetPos,
  updateItemSearch,
} from "../../../../reducer/posReducer/restaurant";

const Heading = ({ addItemSectionVisible, setAddItemSectionVisible }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);
  const { searchItemText } = useSelector((store) => store.pos);

  return (
    <div
      className="fixed top-0 justify-center items-center  flex flex-col w-[100%] bg-[#FFF] z-[20]  
    pb-4 border-b-[1px] border-[#EEEEEE] shadow-lg"
    >
      <header className="w-[100%] flex justify-between p-3 px-4 pt-8 items-center">
        <div className="flex gap-2 items-center">
          <IoIosArrowRoundBack
            onClick={() => {
              dispatch(resetPos());
              navigate(-1);
            }}
            className="text-[32px]"
          />

          <div className="flex gap-1 items-end">
            <div className="font-outfit font-[500] text-[18px] leading-[27px] text-[#222222]">
              {`Order for Table ${
                Boolean(tableOrderDetails?.tableNumber)
                  ? tableOrderDetails?.tableNumber
                  : ""
              }`}
            </div>
          </div>
        </div>

        <div
          className="flex gap-1 items-center"
          onClick={() => setAddItemSectionVisible(true)}
        >
          <FaPlus className="text-[#007FFF]" />

          <div className="font-outfit font-[500] text-[16px] leading-[24px] text-[#007FFF]">
            Add Item
          </div>
        </div>

        {/* <BsThreeDotsVertical className="text-[28px] p-1" /> */}
      </header>
      <div className="flex  w-[90%] rounded-[32px] relative items-center bg-[#FFF]">
        <SearchBar className="z-[10] text-[20px] w-[20px] h-[20px] absolute top-[11px] left-4 text-[#B3B3B3]" />

        <input
          value={searchItemText}
          onChange={(e) => dispatch(updateItemSearch(e.target.value))}
          placeholder="Search item by Category and Name"
          className="bg-[#F5F7FA]  p-3 rounded-[32px]  w-full pl-[3rem]  focus:outline-none text-sm 
          border-0 "
        />
      </div>
    </div>
  );
};

export default Heading;
