import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import {
  updateDates,
  updateDisplayCustomCalendar,
  updateDisplayFilter,
  updateSetFilterTitle,
} from "../../reducer/bookingReservationReducer";
import { FaListUl } from "react-icons/fa6";
import { HiViewGrid } from "react-icons/hi";
import moment from "moment";
import CustomCalendar from "./components/CustomCalendar";
import { TranslateText } from "../../utils/translation";
const Heading = () => {
  const dispatch = useDispatch();
  const { createBookingAllowed } = useSelector(
    (store) => store.usersRestriction
  );
  const { isAllHotelsSelected } = useSelector((store) => store.login);

  let navigate = useNavigate();

  const { openFilter, filterTitle } = useSelector(
    (store) => store.bookingReservation
  );

  const redirect = () => navigate("/dashboard/walkin");
  const { menuDrawer } = useSelector((store) => store.app);
  const { isMobileAndTabletScreen } = useSelector((store) => store.appHeader);

  const handleFilterChange = (currTitle) => {
    if (currTitle?.toLowerCase() === filterTitle?.toLowerCase()) {
      dispatch(updateDisplayFilter(false));
      return;
    }

    if (currTitle === "today") {
      dispatch(
        updateDates({
          from: moment().startOf("day"),
        })
      );
      dispatch(
        updateDates({
          to: moment().add(1, "day"),
        })
      );
      dispatch(updateSetFilterTitle("Today"));
    } else if (currTitle === "week") {
      dispatch(
        updateDates({
          from: moment().startOf("day"),
        })
      );
      dispatch(
        updateDates({
          to: moment().add(6, "day"),
        })
      );
      dispatch(updateSetFilterTitle("Week"));
    } else if (currTitle === "month") {
      dispatch(
        updateDates({
          from: moment().startOf("month"),
        })
      );
      dispatch(
        updateDates({
          to: moment().endOf("month"),
        })
      );

      dispatch(updateSetFilterTitle("Month"));
    } else {
      // Custom Date
      // You can add logic here to handle custom date ranges
      dispatch(updateDisplayCustomCalendar(true));

      dispatch(updateSetFilterTitle("Custom"));
    }

    dispatch(updateDisplayFilter(false));
  };

  const [isToggled, setIsToggled] = useState(true);

  const handleClick = () => {
    setIsToggled(!isToggled);

    setTimeout(() => {
      navigate("/dashboard/bookings");
    }, 100); // Delay to match the transition duration
  };

  return (
    <div
      className={`  fixed  top-[3rem]  z-[30] right-0    ${
        isMobileAndTabletScreen
          ? "left-[1px] "
          : menuDrawer
          ? "left-[5rem] "
          : "left-[13rem] "
      } flex justify-between items-center px-[1.5rem] py-[0.6rem] shadow-md bg-[#FFF]`}
    >
      <div className="flex items-center gap-[40px]">
        <div className="font-outfit font-[600] text-[22px] leading-[33px] text-[#545454]  ">
          <TranslateText textKey={"room_calendar"} namespace="booking" />
        </div>

        <div
          onClick={(e) => {
            dispatch(updateDisplayFilter(!openFilter));
            e.stopPropagation();
          }}
          className={` ${
            openFilter ? "border-b-0 rounded-t-[8px]" : "rounded-[8px]"
          } flex items-center gap-[72px] py-[10px] px-[16px] border-[1.6px] border-[#EBEBEB]   relative cursor-pointer w-[10rem]`}
        >
          <span className="font-outfit font-[500] text-[14px] leading-[20px] text-[#007FFF]">
            {" "}
            {filterTitle}
          </span>

          {openFilter ? (
            <FaChevronUp className="text-[#007FFF] text-[14px]" />
          ) : (
            <FaChevronDown className="text-[#007FFF] text-[14px]" />
          )}

          <CustomCalendar />

          {openFilter && (
            <div
              onClick={(e) => e.stopPropagation()}
              className={` absolute border-[1.6px] border-[#EBEBEB] border-t-0 left-[-1px]  
          top-[2.5rem]  w-[10rem]  flex flex-col justify-start items-start rounded-b-[6px] bg-[#FFF]`}
            >
              {["today", "week", "month", "custom date"]?.map((Obj) => {
                return (
                  <div
                    className="font-outfit font-[400] cursor-pointer text-[14px] leading-[18px] text-[#333333] capitalize whitespace-nowrap 
                    px-[20px] py-[15px]"
                    key={Obj}
                    onClick={() => handleFilterChange(Obj)}
                  >
                    {Obj}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="flex gap-6 items-center h-[4rem]">
        {createBookingAllowed && !isAllHotelsSelected && (
          <div
            type="primary"
            className="flex items-center gap-[8px] px-[12px] py-[10px] rounded-[6px] shadow-sm bg-[#007FFF] cursor-pointer"
            onClick={redirect}
          >
            <div className="font-outfit font-[400] text-[16px] leading-[20px] text-[#FFF]">
              {" "}
              <TranslateText textKey={"create_booking"} namespace="booking" />
            </div>
            <FaPlus className="text-[#FFF]" />
          </div>
        )}
        {!isAllHotelsSelected && (
          <div
            className="toggleButton bg-[#EBEBEB] border-[5px] border-[#EBEBEB] rounded-[20.8px] cursor-pointer flex items-center justify-between"
            onClick={handleClick}
            style={{ width: "82px", height: "42px", position: "relative" }} // Adjust width and height as needed
          >
            <div
              className={`p-[9px] transition-transform duration-300 ease-in-out`}
              style={{
                transform: isToggled ? "translateX(36px)" : "translateX(0)",
              }}
            >
              <FaListUl className="text-[#4A576FB2] text-[18px]" />
            </div>
            <div
              className={`rounded-[50%] p-[9px] transition-transform duration-300 ease-in-out`}
              style={{
                background: "linear-gradient(90deg, #F7BE2B 0%, #F537F9 100%)",
                transform: isToggled ? "translateX(-38px)" : "translateX(0)",
              }}
            >
              <HiViewGrid className="text-[#FFF] text-[18px]" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Heading;
