import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { TranslateText } from "../../../utils/translation";
import {
  updateDateOfArrival,
  updateDateOfDeparture,
  updateDisplayPrimaryGuestDateArrival,
  updateDisplayPrimaryGuestDateDeparture,
  updateDisplayPrimaryGuestDateIssue,
  updateDisplayPrimaryGuestDateExpire,
} from "../../../reducer/bookingReducer";

const ArrivalAndDurationDate = () => {
  const dispatch = useDispatch();

  const {
    selectedDateOfArrival,
    selectedDateOfDeparture,
    displayPrimaryGuestDateArrival,
    displayPrimaryGuestDateDeparture,
  } = useSelector((store) => store.booking);

  const dateFormat = "YYYY/MM/DD";

  const onChangeDateOfArrival = (date, dateString) => {
    dispatch(
      updateDateOfArrival({
        dateOfArrival: dateString,
      })
    );
    dispatch(updateDisplayPrimaryGuestDateArrival(false));
  };

  const onChangeDateOfDeparture = (date, dateString) => {
    dispatch(
      updateDateOfDeparture({
        dateOfDeparture: dateString,
      })
    );
    dispatch(updateDisplayPrimaryGuestDateDeparture(false));
  };

  return (
    <div
      className="flex gap-5 w-[100%]  justify-between"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="border border-gray-400 w-[50%] rounded-lg">
        <div className="flex items-center cursor-pointer relative px-2">
          <div
            className="flex"
            onClick={() => {
              dispatch(updateDisplayPrimaryGuestDateExpire(false));
              dispatch(
                updateDisplayPrimaryGuestDateArrival(
                  !displayPrimaryGuestDateArrival
                )
              );
              dispatch(updateDisplayPrimaryGuestDateDeparture(false));
              dispatch(updateDisplayPrimaryGuestDateIssue(false));
            }}
          >
            <h1 className="flex px-1 absolute top-[-8px] left-2 bg-[#ffffff]">
              <span className="text-[0.625rem] text-[#808080] font-outfit font-[300]">
                <TranslateText
                  textKey={"date_of_arrival"}
                  namespace="booking"
                />
              </span>
            </h1>
            <div className="pl-1 pr-[5rem] py-1 mt-[4px]">
              <span className="font-outfit text-[0.75rem] text-[#4D4D4D] font-[400]">
                {selectedDateOfArrival
                  ? moment(selectedDateOfArrival).format("D/MM/YYYY")
                  : moment().subtract(18, "year").format("D/MM/YYYY")}
              </span>
            </div>
          </div>

          <div onClick={(e) => e.stopPropagation()}>
            <DatePicker
              className="invisibles"
              allowClear={false}
              onChange={onChangeDateOfArrival}
              value={dayjs(
                selectedDateOfArrival
                  ? selectedDateOfArrival
                  : moment().subtract(18, "year").format("YYYY/MM/DD")
              )}
              style={{
                border: "none",
                width: "0",
                height: "0",
                padding: "0",
              }}
              suffixIcon={<></>}
              format={dateFormat}
              open={displayPrimaryGuestDateArrival}
              placement="bottomLeft"
              size="small"
            />
          </div>
        </div>
      </div>

      <div className="border border-gray-400 w-[50%] rounded-lg">
        <div className="flex items-center px-2 cursor-pointer relative">
          <div
            className="flex"
            onClick={() => {
              dispatch(updateDisplayPrimaryGuestDateIssue(false));
              dispatch(updateDisplayPrimaryGuestDateArrival(false));
              dispatch(
                updateDisplayPrimaryGuestDateDeparture(
                  !displayPrimaryGuestDateDeparture
                )
              );
              dispatch(updateDisplayPrimaryGuestDateExpire(false));
            }}
          >
            <h1 className="flex absolute top-[-8px] left-2 bg-[#ffffff]">
              <span className="text-[0.60rem] font-outfit text-[#00000080] px-[2px] mr-1">
                <TranslateText
                  textKey={"date_of_departure"}
                  namespace="booking"
                />
              </span>
            </h1>
            <div className="font-outfit pl-1 pr-[5rem] py-1 mt-[4px] text-[#404040]">
              <span className="font-outfit text-[0.75rem] text-[#4D4D4D] font-[400]">
                {selectedDateOfDeparture
                  ? moment(selectedDateOfDeparture).format("D/MM/YYYY")
                  : moment().format("D/MM/YYYY")}
              </span>
            </div>
          </div>

          <div onClick={(e) => e.stopPropagation()}>
            <DatePicker
              allowClear={false}
              onChange={onChangeDateOfDeparture}
              style={{
                border: "none",
                width: "0",
                height: "0",
                padding: "0",
              }}
              value={dayjs(
                selectedDateOfDeparture
                  ? selectedDateOfDeparture
                  : moment().format("YYYY/MM/DD")
              )}
              suffixIcon={<></>}
              format={dateFormat}
              open={displayPrimaryGuestDateDeparture}
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArrivalAndDurationDate;
