import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { useState, useEffect } from "react";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import verifiedSymbol from "../../../assets/images/pngs/VerifiedSymbol.svg";
import CloseIcon from "../../../assets/images/svgs/CloseIcon.svg";

import {
  updateDisplayCheckInDrawer,
  updateShowMainBookingDetailsDrawer,
  updateBookingDetails,
  updateCustomerDetails,
  updateActiveTab,
  resetState,
} from "../../../reducer/bookingReducer";
import { message } from "antd";
import UploadId from "../components/UploadId";
import { putCustomer } from "../../../services/customer";
import CustomTextField from "../../common/InputField/Input";
import { updateBookingListData as updateReservationCalendarBookingListData } from "../../../reducer/bookingReservationReducer";
import { bookingDetailsDrawerWidth } from "../../../utils/bookingDetailsHelper";

export default function CheckInDrawer() {
  const { hotelDetails } = useSelector((store) => store.login);

  const dispatch = useDispatch();

  const { displayCheckInDrawer, bookingDetails, customerDetails } = useSelector(
    (store) => store.booking
  );

  const [arrival, setArrival] = useState("");
  const [dest, setDest] = useState("");
  const [purpose, setPurpose] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState([]);

  const { bookingResponse: bookingReservationResponse } = useSelector(
    (store) => store.bookingReservation
  );

  useEffect(() => {
    if (bookingDetails) performApi();
  }, [bookingDetails]);

  const handleClose = () => {
    performApi();
    dispatch(updateDisplayCheckInDrawer());
  };

  const performApi = () => {
    setArrival(Boolean(bookingDetails?.arrival) ? bookingDetails?.arrival : "");
    setDest(
      Boolean(bookingDetails?.destination) ? bookingDetails?.destination : ""
    );
    setPurpose(
      Boolean(bookingDetails?.purposeOfVisit)
        ? bookingDetails?.purposeOfVisit
        : ""
    );
    setId(customerDetails?.idImages);
  };

  const handleCheckIn = async () => {
    setLoading(true);

    const arrivalData = Boolean(arrival) ? arrival : "";
    const destinationData = Boolean(dest) ? dest : "";
    const purposeOfVisitData = Boolean(purpose) ? purpose : "";

    if (
      Boolean(arrivalData) &&
      Boolean(destinationData) &&
      Boolean(purposeOfVisitData) &&
      Boolean(bookingDetails)
    ) {
      const updatedData = JSON.parse(JSON.stringify(bookingDetails));

      updatedData.status = "checkedIn";

      updatedData.arrival = arrival;
      updatedData.destination = dest;
      updatedData.purposeOfVisit = purpose;

      updatedData.status = "checkedIn";

      const data = await dispatch(updateBookingDetails(updatedData));

      const bookings = bookingReservationResponse?.bookings;

      if (!Boolean(data.error)) {
        let updatedBookingDetails = bookings?.map((Obj) =>
          Obj?.id === data?.payload?.id ? data?.payload : Obj
        );

        const newResponse = JSON.parse(
          JSON.stringify(bookingReservationResponse)
        );

        newResponse.bookings = updatedBookingDetails;

        await dispatch(updateReservationCalendarBookingListData(newResponse));

        dispatch(updateDisplayCheckInDrawer());
        dispatch(
          updateShowMainBookingDetailsDrawer({
            visible: false,
            bookingId: "",
            bookingType: "",
            newBookingId: "",
          })
        );
        dispatch(resetState());
        dispatch(updateActiveTab("ongoing"));

        setLoading(false);

        message.destroy();
        message.success("Successfully Checked In");
      } else {
        dispatch(updateDisplayCheckInDrawer());
        dispatch(
          updateShowMainBookingDetailsDrawer({
            visible: false,
            bookingId: "",
            bookingType: "",
            newBookingId: "",
          })
        );
        setLoading(false);

        message.destroy();
        message.error(data.payload);
      }
    } else {
      message.warning("Please fill all the required data ");
    }
  };

  useEffect(() => {
    const updateId = async () => {
      if (customerDetails && Boolean(id) && id?.length > 0) {
        const newDetails = JSON.parse(JSON.stringify(customerDetails?.[0]));
        newDetails.idImages = id ?? [];

        const payload = {
          hotelId: hotelDetails?.id,
          id: customerDetails?.id,
          ...newDetails,
        };

        const data = await putCustomer(payload);

        let updatedData = customerDetails?.map((Obj) => {
          if (Obj?.id !== data?.id) return Obj;
          else return data;
        });

        dispatch(updateCustomerDetails(updatedData));
      }
    };

    updateId();
  }, [id]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "800px",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",

          "& .MuiDrawer-root": {
            position: "absolute",
            marginleft: "auto",
            right: 0,
          },
          "& .MuiPaper-root": {
            position: "absolute",
            marginLeft: "auto",
          },
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={displayCheckInDrawer}
      onClose={() => dispatch(updateDisplayCheckInDrawer())}
    >
      <div className="flex flex-col mx-3 gap-5 items-start my-4   ">
        <div className="flex w-[100%] justify-between items-center ">
          <h2 className="font-[400] text-[0.875rem] text-[#4D4D4D] font-nunito">
            Checkin
          </h2>

          <div className="flex gap-1 items-center cursor-pointer">
            {Boolean(id) && id?.length !== 0 ? (
              <div className="flex gap-1 items-center">
                <p className="text-[#41C588] whitespace-nowrap font-nunito text-[0.875rem] font-[500]">
                  ID Verified
                </p>
                <img src={verifiedSymbol} alt="" className="mr-2" />
              </div>
            ) : (
              <p className="text-[#007FFF] font-nunito text-[0.875rem] font-[500] mr-2 cursor-pointer">
                <UploadId id={id} setId={setId}></UploadId>
              </p>
            )}

            <div
              className="cursor-pointer"
              onClick={() => handleClose()}
              sx={{
                fontSize: "small",
                borderRadius: "10px",
                border: "1px solid #A7A7A7",
                cursor: "pointer",
              }}
            >
              <img src={CloseIcon} alt="" />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center w-[100%] gap-5 mb-5">
          <div className="flex  w-[92%] gap-4 justify-center ">
            <CustomTextField
              label="Arriving From"
              isManadatory={true}
              onChange={(value) => setArrival(value)}
              value={arrival}
              type="text"
            />

            <CustomTextField
              label="Destination"
              isManadatory={true}
              onChange={(value) => setDest(value)}
              value={dest}
              type="text"
            />
          </div>

          <div className="w-[92%]">
            <FormControl
              sx={{
                width: "100%",

                "& label": {
                  color: "#8F9BB3",
                  fontFamily: "Outfit",
                  fontSize: "16px",
                  padding: "5px",
                },
                "& input": {
                  fontFamily: "Outfit",
                  fontSize: "0.75rem",
                  fontWeight: "400",

                  color: "#8F9BB3",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", // These should be strings
                },
                "& .MuiInputLabel-root": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "nunito",
                  color: "#8F9BB3",
                  fontSize: "0.75rem", // Font size when label is down
                },
                "& .MuiInputLabel-shrink": {
                  fontSize: "0.93rem", // Font size when label is up (focused)
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "nunito",
                },
              }}
              size="small"
            >
              <InputLabel id="bookingSourceSelectLabel">
                Purpose of Travel
              </InputLabel>
              <Select
                labelId="bookingSourceSelectLabel"
                id="bookingSource"
                label="Purpose of Travel"
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  color: "#000",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  fontFamily: "Outfit",
                }}
                value={purpose}
                defaultValue={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              >
                <MenuItem
                  value="business"
                  sx={{
                    fontSize: "14px",
                    color: "#00000098",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                  }}
                >
                  Business
                </MenuItem>

                <MenuItem
                  value="medical"
                  sx={{
                    fontSize: "14px",
                    color: "#00000098",
                    fontFamily: "Poppins",
                  }}
                >
                  Medical
                </MenuItem>

                <MenuItem
                  value="tourism"
                  sx={{
                    fontSize: "14px",
                    color: "#00000098",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                  }}
                >
                  Tourism
                </MenuItem>

                <MenuItem
                  value="other"
                  sx={{
                    fontSize: "14px",
                    color: "#00000098",
                    fontFamily: "Poppins",
                  }}
                >
                  Other
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <button
        className="bg-[#007FFF] text-[#FFF] w-full h-[3rem]"
        onClick={() => handleCheckIn()}
      >
        SAVE DETAILS
      </button>
    </Drawer>
  );
}
