import { ErrorMessage } from "./constants";

export const isFileSizeValid = (file, allowedSize, errorMsg) => {
  if (!file) return;
  const size = (file.size / 1024 / 1024).toFixed(2);
  if (size > allowedSize) {
    return false;
  }
  return true;
};

export const isRoomTypeValid = (roomType, roomTypes) => {
  if (roomType === "" || roomTypes?.some((i) => i.type === roomType)) {
    alert(ErrorMessage.invalidRoomType);
    return false;
  }
  return true;
};

export const getSelected = (data, selectionKey, returnKey) => {
  const selectedValues = [];
  if (!data) return null;

  data?.forEach((item) => {
    if (!!item[selectionKey]) selectedValues.push(item[returnKey]);
  });

  return selectedValues;
};
