import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceChargeAmount: 0,
  discountAmount: {
    discount: 0,
    typeOfDiscount: "",
  },
  customTaxAmount: {
    notes: "",
    customTax: 0,
  },
  taxes: 0,
};

const reviewOrderSlice = createSlice({
  name: "reviewOrder",
  initialState,

  reducers: {
    CLEAR_REDUX_STORE_38: () => initialState,

    addServiceTaxAmount: (state, action) => {
      let newAmount = Number(action.payload);
      return { ...state, serviceChargeAmount: newAmount };
    },
    addDiscountAmount: (state, action) => {
      let newAmount = Number(action.payload.discountAmount);
      let newTypeOfDiscount = action.payload.typeOfDiscount;
      return {
        ...state,
        discountAmount: {
          ...state.discountAmount,
          discount: newAmount,
          typeOfDiscount: newTypeOfDiscount,
        },
      };
    },
    addCustomTaxAmount: (state, action) => {
      let newAmount = Number(action.payload.customTaxAmount);
      let newNotes = action.payload.notes;
      return {
        ...state,
        customTaxAmount: {
          ...state.customTaxAmount,
          notes: newNotes,
          customTax: newAmount,
        },
      };
    },
    addTaxes: (state, action) => {
      let newTaxes = action.payload;
      return { ...state, taxes: newTaxes };
    },
  },
});

export const {
  CLEAR_REDUX_STORE_38,
  addServiceTaxAmount,
  addDiscountAmount,
  addCustomTaxAmount,
  addTaxes,
} = reviewOrderSlice.actions;
export default reviewOrderSlice.reducer;
