export const HOTEL_DEFAULT_CHECKOUT_TIME = 11;
export const HOTEL_DEFAULT_CHECKIN_TIME = 12;

export const getHotelCheckInOutTime = (hotelDetails) => {
  let { checkInTime, checkOutTime } = hotelDetails || {};

  let CheckInTime = checkInTime?.split(":")?.[0] || HOTEL_DEFAULT_CHECKIN_TIME;

  let CheckOutTime =
    checkOutTime?.split(":")?.[0] || HOTEL_DEFAULT_CHECKOUT_TIME;

  CheckInTime = convertTo12HourFormat(CheckInTime)?.hour;
  CheckOutTime = convertTo12HourFormat(checkOutTime)?.hour;

  let CheckInTimeMin = checkInTime?.split(":")?.[1] || 0;
  let CheckOutTimeMin = checkOutTime?.split(":")?.[1] || 0;

  return { CheckInTime, CheckOutTime, CheckInTimeMin, CheckOutTimeMin };
};

export const getHotelCheckInOutTimeInAMPM = (hotelDetails) => {
  let { checkInTime, checkOutTime } = hotelDetails || {};
  checkInTime = checkInTime?.split(":")?.[0] || HOTEL_DEFAULT_CHECKIN_TIME;
  checkOutTime = checkOutTime?.split(":")?.[0] || HOTEL_DEFAULT_CHECKOUT_TIME;

  let CheckInTimeInAMPM = convertTo12HourFormat(checkInTime).ampm;
  let CheckoutTimeInAMPM = convertTo12HourFormat(checkOutTime).ampm;

  return { CheckInTimeInAMPM, CheckoutTimeInAMPM };
};

export function convertTo12HourFormat(time) {
  // Convert string to number
  time = parseInt(time);

  // Check if input is valid
  if (isNaN(time) || time < 0 || time > 23) {
    return "Invalid input";
  }

  // Convert to 12-hour format
  let hour12 = time % 12 || 12; // % 12 ensures 12:00 stays 12

  // Determine if it's AM or PM
  let period = time < 12 ? "AM" : "PM";

  // Return the result
  return { hour: hour12, ampm: period };
}

export const getHotelCheckInOutTime24hours = (hotelDetails) => {
  let { checkInTime, checkOutTime } = hotelDetails || {};
  let CheckInTime24Hour =
    checkInTime?.split(":")?.[0] || HOTEL_DEFAULT_CHECKIN_TIME;
  let CheckOutTime24Hour =
    checkOutTime?.split(":")?.[0] || HOTEL_DEFAULT_CHECKOUT_TIME;

  return { CheckInTime24Hour, CheckOutTime24Hour };
};
