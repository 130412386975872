import React, { useEffect, useState } from "react";
import { Drawer, message, Input } from "antd";
import moment from "moment";

import UploadId from "../components/UploadId";
import DateOfIssueExpire from "../components/DateOfIssueExpire";
import ArrivalAndDurationDate from "../components/ArrivalAndDurationDate";
import { DEBBOUNCING_TIME } from "../../../utils/helper";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DoaAndDob from "../../bookingDetailsV2/components/DoaAndDob";

import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  updateShowPrimaryGuestDrawer,
  updateBookingDetails,
  updateDateOfArrival,
  updateDateOfDeparture,
  updateDateOfIssue,
  updateDateOfExpire,
  updateCustomerDetails,
  updateBookingListData,
  updateDisplayPrimaryGuestDoa,
  updateDisplayPrimaryGuestDob,
  updateDisplayPrimaryGuestDateArrival,
  updateDisplayPrimaryGuestDateDeparture,
  updateDisplayPrimaryGuestDateIssue,
  updateDisplayPrimaryGuestDateExpire,
} from "../../../reducer/bookingReducer";
import { updateAddPartyDropdownVisible } from "../../../reducer/expenseReducer";
import { getPartyList } from "../../../reducer/partyReducer";
import AddPartyDropdown from "../../expense/dropdowns/AddPartyDropdown";

import {
  customerDetailsById,
  customerDetailsByPhone,
  postCustomer,
  putCustomer,
} from "../../../services/customer";
import { CircularProgress } from "@mui/material";
import GenderButton from "../components/GenderButton";
import CustomTextField from "../../common/InputField/Input";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { setIsPartyInputFocused } from "../../../reducer/walkinReducer";
import {
  resetInfiniteScrolling,
  updateSearchText,
} from "../../../reducer/partyReducer";
import { updateBookingListData as updateReservationCalendarBookingListData } from "../../../reducer/bookingReservationReducer";
import {
  bookingDetailsDrawerWidth,
  customCloseIconContainer,
} from "../../../utils/bookingDetailsHelper";

const AddPrimaryGuestDrawer = () => {
  const dispatch = useDispatch();

  const { hotelDetails, isAllHotelsSelected } = useSelector(
    (store) => store.login
  );

  const {
    showPrimaryGuestDrawer,
    showMainBookingDetailsDrawer,
    bookingDetails,
    selectedDateOfArrival,
    selectedDateOfDeparture,
    selectedDateOfIssue,
    selectedDateOfExpire,
    responseDetails,
    response,
    bookingHotelId,
    invoiceDetails,
  } = useSelector((store) => store.booking);

  const { isPartyInputFocused } = useSelector((store) => store.walkin);

  const { partyResponse, searchText } = useSelector((store) => store.party);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneValue, setPhoneValue] = useState("");
  const [id, setId] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [dob, setDob] = useState("");
  const [doa, setDoa] = useState("");
  const [refrenceNo, setReferenceNo] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [companyGstin, setCompanyGstin] = useState("");

  const [customerObject, setCustomerObject] = useState({});

  // foreign guest
  const [nationality, setNationality] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [visaNo, setVisaNo] = useState("");
  const [localContactNo, setlocalContactNo] = useState("");
  const [foreignCompanyName, setforeignCompanyName] = useState("");
  const [purposeOfStay, setPurposeOfStay] = useState("");
  const [visaType, setVisaType] = useState("");

  const [showCompanyDetails, setShowComanyDetails] = useState(false);
  const [showForeignNatinalDetails, setShowForeignNationalDetails] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [customerDataLoading, setCustomerDataLoading] = useState(false);

  const [invoiceIds, setInvoiceIds] = useState([]);
  const [roomBillInvoiceId, setRoomBillInvoiceId] = useState("");

  const [fetchedName, setFetchedName] = useState("");

  const customerDetails = responseDetails?.customerDetails;

  useEffect(() => {
    if (Array.isArray(invoiceDetails)) {
      const updatedInvoiceIds = invoiceDetails?.map(({ id }) => id);
      setInvoiceIds(updatedInvoiceIds);

      // finding room bill invoice id
      const upadtedRoomBillInvoiceId = invoiceDetails?.find(
        ({ invoiceType }) => invoiceType == "roomBill"
      );
      setRoomBillInvoiceId(upadtedRoomBillInvoiceId);
    }
  }, [invoiceDetails]);

  const editPrimaryGuest = async (customerPayload) => {
    if (!isValid()) return;

    setLoading(true);

    const payload = {
      id: showPrimaryGuestDrawer?.customerId,
      address: address,
      city: city,
      company: companyName,
      country: country,
      designation: designation,
      doanniversary: Boolean(doa) ? moment(doa).toISOString() : null,
      dob: Boolean(dob) ? moment(dob).toISOString() : null,
      email: email,
      gender: Boolean(gender) ? gender : null,
      gstIN: companyGstin,
      hotelId: hotelDetails?.id,
      idImages: id,
      name: name,
      nationality: nationality,
      passportNumber: passportNo,
      phone: phoneNumber,

      contactDetails: {
        countryCode: countryCode,
        phoneNumber: phoneNumber,
      },
      grcDetails: {
        bookingId: showPrimaryGuestDrawer?.bookingId,
        companyName: foreignCompanyName,
        dateOfArrival: Boolean(selectedDateOfArrival)
          ? moment(selectedDateOfArrival).toISOString()
          : null,
        dateOfDeparture: Boolean(selectedDateOfDeparture)
          ? moment(selectedDateOfDeparture).toISOString()
          : null,
        dateOfExpire: Boolean(selectedDateOfExpire)
          ? moment(selectedDateOfExpire).toISOString()
          : null,
        dateOfIssue: Boolean(selectedDateOfIssue)
          ? moment(selectedDateOfIssue).toISOString()
          : null,
        localContactNumber: localContactNo,
        purpose: purposeOfStay,
        visaNumber: visaNo,
        visaType: visaType,
      },
      zipCode: postalCode,
    };

    let data;

    if (customerPayload?.id) {
      return await putCustomer({ ...customerPayload, invoiceIds });
    } else {
      data = await putCustomer({ ...payload, invoiceIds });
    }

    let updatedCustomerDetails = customerDetails?.map((Obj) =>
      Obj?.id === data?.id ? data : Obj
    );

    dispatch(updateCustomerDetails(updatedCustomerDetails));

    const newResponse = JSON.parse(JSON.stringify(response));
    const index = newResponse?.customers?.findIndex(
      (Obj) => Obj.id === data.id
    );

    const bookingIndex = newResponse?.bookings?.findIndex(
      (Obj) => Obj.bookingId === showMainBookingDetailsDrawer?.newBookingId
    );

    // for updating primary guest name and number in booking data
    const currentPrimaryGuestName =
      newResponse?.bookings?.[bookingIndex]?.["primaryGuestName"] || "";

    if (fetchedName === currentPrimaryGuestName) {
      newResponse.bookings[bookingIndex]["primaryGuestName"] = data?.name;
      newResponse.bookings[bookingIndex]["primaryGuestPhoneNumber"] =
        data?.phone;
      setFetchedName(data?.name);
    }

    newResponse.customers[index] = data;

    await dispatch(updateBookingListData(newResponse));
    await dispatch(updateReservationCalendarBookingListData(newResponse));

    message.destroy();
    message.success("Data Successfully Edited");

    setLoading(false);
    handleClose();
  };

  const addNewPrimaryGuest = async () => {
    if (!isValid()) return;

    setLoading(true);

    const payload = {
      address: address,
      city: city,
      company: companyName,
      country: country,
      designation: designation,
      doanniversary: Boolean(doa) ? moment(doa).toISOString() : null,
      dob: Boolean(dob) ? moment(dob).toISOString() : null,
      email: email,
      gender: Boolean(gender) ? gender : null,
      gstIN: companyGstin,
      hotelId: hotelDetails?.id,
      idImages: id,
      name: name,
      nationality: nationality,
      passportNumber: passportNo,
      phone: phoneNumber,

      contactDetails: {
        countryCode: countryCode,
        phoneNumber: phoneNumber,
      },

      grcDetails: {
        bookingId: showPrimaryGuestDrawer?.bookingId,
        companyName: foreignCompanyName,
        dateOfArrival: Boolean(selectedDateOfArrival)
          ? moment(selectedDateOfArrival).toISOString()
          : null,
        dateOfDeparture: Boolean(selectedDateOfDeparture)
          ? moment(selectedDateOfDeparture).toISOString()
          : null,
        dateOfExpire: Boolean(selectedDateOfExpire)
          ? moment(selectedDateOfExpire).toISOString()
          : null,
        dateOfIssue: Boolean(selectedDateOfIssue)
          ? moment(selectedDateOfIssue).toISOString()
          : null,
        localContactNumber: localContactNo,
        purpose: purposeOfStay,
        visaNumber: visaNo,
        visaType: visaType,
      },
      zipCode: postalCode,
    };

    let data;
    if (Boolean(customerObject?.id)) {
      data = await editPrimaryGuest({ ...customerObject, ...payload });
    } else {
      data = await postCustomer(payload);

      // after posting the customer, also send put request with invoice is in params
      if (Boolean(data?.id)) {
        data = await putCustomer({ ...data, invoiceIds });
      }
    }

    if (Boolean(data?.id)) {
      const newPrimaryGuestId = data?.id;

      const newBookingDetails = JSON.parse(JSON.stringify(bookingDetails));
      newBookingDetails?.guests.push(newPrimaryGuestId);

      const updatedCustomerDetails = [...customerDetails, data];

      await dispatch(updateBookingDetails(newBookingDetails));
      await dispatch(updateCustomerDetails(updatedCustomerDetails));

      message.success("Data  Successfully Updated");

      setLoading(false);
      handleClose();
    } else {
      message.error("Something Went wrong");
      setLoading(false);
    }
  };

  const handleClose = () => {
    refreshData();

    dispatch(
      updateShowPrimaryGuestDrawer({
        visible: false,
        bookingId: showMainBookingDetailsDrawer.bookingId,
        customerType: "",
      })
    );
  };

  const refreshData = () => {
    setCustomerObject({});

    setPhoneNumber("");
    setCountryCode("+91");
    setPhoneValue("");
    setId([]);
    setName("");
    setFetchedName("");
    setEmail("");
    setGender("");
    setAddress("");
    setCountry("");
    setCity("");
    setPostalCode("");

    setDoa("");
    setDob("");
    setReferenceNo("");
    setCompanyName("");
    setDesignation("");

    setCompanyGstin("");
    setNationality("");
    setPassportNo("");
    setVisaNo("");
    setlocalContactNo("");
    setforeignCompanyName("");
    setPurposeOfStay("");
    setVisaType("");

    dispatch(updateDateOfArrival({ dateOfArrival: null }));
    dispatch(updateDateOfDeparture({ dateOfDeparture: null }));

    dispatch(updateDateOfIssue({ dateOfIssue: null }));
    dispatch(updateDateOfExpire({ dateOfExpire: null }));

    setShowComanyDetails(false);
    setShowForeignNationalDetails(false);
  };

  const updateData = (data) => {
    const currentCustomerGrcDetails = Array.isArray(customerDetails)
      ? customerDetails?.filter(
          (Obj) => Obj.id === showPrimaryGuestDrawer?.customerId
        )?.[0]
      : {};

    setCustomerObject(data);
    setPhoneNumber(data?.contactDetails?.phoneNumber);
    setCountryCode(data?.contactDetails?.countryCode);
    setPhoneValue(
      data?.contactDetails?.countryCode + data?.contactDetails?.phoneNumber
    );

    setId(data?.idImages);
    setName(data?.name);
    setEmail(data?.email);
    setGender(data?.gender);
    setAddress(data?.address);
    setCountry(data?.country);
    setCity(data?.city);
    setPostalCode(data?.zipCode);

    setDoa(data?.doanniversary);
    setDob(data?.dob);
    setCompanyName(data?.company);
    setDesignation(data?.designation);

    setCompanyGstin(data?.gstIN);
    setNationality(data?.nationality);

    setPassportNo(data?.passportNumber);
    setVisaNo(currentCustomerGrcDetails?.grcDetails?.visaNumber);
    setlocalContactNo(
      currentCustomerGrcDetails?.grcDetails?.localContactNumber
    );
    setforeignCompanyName(currentCustomerGrcDetails?.grcDetails?.companyName);
    setPurposeOfStay(currentCustomerGrcDetails?.grcDetails?.purpose);
    setVisaType(currentCustomerGrcDetails?.grcDetails?.visaType);

    dispatch(
      updateDateOfArrival({
        dateOfArrival: currentCustomerGrcDetails?.grcDetails?.dateOfArrival,
      })
    );
    dispatch(
      updateDateOfDeparture({
        dateOfDeparture: currentCustomerGrcDetails?.grcDetails?.dateOfDeparture,
      })
    );

    dispatch(
      updateDateOfIssue({
        dateOfIssue: currentCustomerGrcDetails?.grcDetails?.dateOfIssue,
      })
    );
    dispatch(
      updateDateOfExpire({
        dateOfExpire: currentCustomerGrcDetails?.grcDetails?.dateOfExpire,
      })
    );
    setCustomerDataLoading(false);
  };

  const isValid = () => {
    if (!Boolean(phoneNumber)) {
      message.error("Please enter customer number");
      return false;
    } else if (!Boolean(name)) {
      message.error("Please enter customer name!");
      return false;
    } else return true;
  };

  useEffect(() => {
    if (showPrimaryGuestDrawer?.customerType === "existing") performApi();
  }, [showPrimaryGuestDrawer?.customerId, bookingHotelId]);

  useEffect(() => {
    let getData = null;

    getData = setTimeout(() => {
      let phoneLength = Boolean(phoneNumber)
        ? phoneNumber?.toString()?.length
        : 0;

      if (phoneLength >= 7) {
        fetchCustomerDetails();
      }
    }, DEBBOUNCING_TIME);

    return () => clearTimeout(getData);
  }, [phoneNumber]);

  const fetchCustomerDetails = async () => {
    // if phone number is same, then no need to call the API again
    if (customerObject?.contactDetails?.phoneNumber === phoneNumber) return;

    let payload = {
      // hotelId: hotelDetails?.id,
      hotelId: bookingHotelId,
      phone: phoneNumber,
      countryCode: countryCode,
    };

    let response = await customerDetailsByPhone(payload);
    const data = response?.data?.customer;

    if (Boolean(data)) {
      updateData(data);
      setFetchedName(data?.name);
    } else {
      setCustomerObject({});
    }
  };

  const performApi = async () => {
    setCustomerDataLoading(true);

    const payload = {
      hotelId: bookingHotelId,
      id: showPrimaryGuestDrawer?.customerId,
      invoiceId: roomBillInvoiceId?.id,
    };
    const { data } = await customerDetailsById(payload);
    updateData(data);
    setFetchedName(data?.name);
  };

  const handlePhoneChange = (value, country) => {
    const parts = country.inputValue.split(" ");
    let countryCode = parts?.[0];

    const joinedString = parts
      ?.slice(1)
      ?.map((str) => str?.replace(/\D/g, "")) // Remove non-numeric characters
      ?.join("");

    setPhoneNumber(joinedString);
    setCountryCode(countryCode);
    setPhoneValue(value);
  };

  const placeholderText = `${phoneNumber ? phoneNumber.length : 0}`;

  const resetSourceNameFields = () => {
    dispatch(resetInfiniteScrolling("resetPageNumber"));
    setCompanyName("");
  };

  useEffect(() => {
    const payload = {
      // hotelId: hotelDetails?.id,
      hotelId: bookingHotelId,
      pageSize: 100,
      searchText: searchText,
      infiniteScrollDisabled: true,
      partyTypes: "source,vendor",
    };
    dispatch(getPartyList(payload));
  }, [searchText]);

  return (
    <Drawer
      title={
        <div className="text-[1rem] font-nunito px-[-2]">
          <div>
            <span className="text-[#808080] font-[500] font-nunito text-[1rem]">
              Booking Id:
            </span>{" "}
            {showMainBookingDetailsDrawer?.newBookingId ? (
              <span className="text-[#4D4D4D] font-[600]  font-nunito text-[1rem]">
                {" "}
                {showMainBookingDetailsDrawer?.newBookingId}
              </span>
            ) : (
              <span className="text-[#808080] font-[600]  font-[Poppins] ">
                {" "}
                Id not generated
              </span>
            )}
          </div>
          <div>
            <span className="text-[#808080] font-[500] text-[0.875rem] font-nunito">
              Source:
            </span>{" "}
            <span className="text-[#4D4D4D] font-[600] font-nunito text-[0.875rem] capitalize">
              {bookingDetails?.source}
            </span>
          </div>
        </div>
      }
      closeIcon={<CloseOutlined style={customCloseIconContainer} />}
      closable={true}
      onClose={() => handleClose()}
      open={showPrimaryGuestDrawer.visible}
      width={bookingDetailsDrawerWidth}
    >
      <div
        onClick={() => {
          dispatch(setIsPartyInputFocused(false));
          dispatch(updateDisplayPrimaryGuestDoa(false));
          dispatch(updateDisplayPrimaryGuestDob(false));

          dispatch(updateDisplayPrimaryGuestDateArrival(false));
          dispatch(updateDisplayPrimaryGuestDateDeparture(false));

          dispatch(updateDisplayPrimaryGuestDateIssue(false));
          dispatch(updateDisplayPrimaryGuestDateExpire(false));
        }}
      >
        {customerDataLoading ? (
          <div className="w-full  h-screen flex justify-center items-center">
            <CircularProgress size={32} />
          </div>
        ) : (
          <>
            <div className="  relative pl-3   ">
              <div className="font-[400]   text-[#4D4D4D] text-[0.875rem] font-nunito">
                Add Primary Guest
              </div>

              <form className={`mt-3  relative mb-10`}>
                <div className="flex gap-5  pl-4 w-[100%]  justify-between">
                  <div
                    className="relative w-[49%] no-scrollbar"
                    style={{ zIndex: "100000" }}
                  >
                    <PhoneInput
                      defaultCountry="in"
                      value={phoneValue}
                      onChange={handlePhoneChange}
                      required={true}
                      forceDialCode={true}
                    />

                    <div
                      className="text-[0.625rem] font-nunito font-[300] text-[#808080] absolute right-2 top-1/2 transform -translate-y-1/2"
                      style={{ visibility: phoneValue ? "visible" : "hidden" }}
                    >
                      {placeholderText}
                    </div>
                  </div>

                  <div className="w-[50%]">
                    <UploadId id={id} setId={setId} custom={true} />
                  </div>
                </div>

                <div className="flex gap-5 pl-4  mt-6">
                  <CustomTextField
                    label="Name"
                    isManadatory={true}
                    value={name}
                    onChange={(value) => setName(value)}
                    type="text"
                  />

                  <CustomTextField
                    label="Email Id"
                    isManadatory={false}
                    value={email}
                    onChange={(value) => setEmail(value)}
                    type="email"
                  />
                </div>
                <div className="flex  pl-4  w-[100%] justify-center items-center mt-6 ">
                  <GenderButton gender={gender} setGender={setGender} />
                </div>

                <div className="mt-6 pl-4">
                  <CustomTextField
                    label="Address"
                    isManadatory={false}
                    value={address}
                    onChange={(value) => setAddress(value)}
                    type="text"
                  />
                </div>

                <div className="flex justify-between pl-4 gap-5 w-[100%] mt-6">
                  <CustomTextField
                    label="Country"
                    isManadatory={false}
                    value={country}
                    onChange={(value) => setCountry(value)}
                    type="text"
                  />

                  <CustomTextField
                    label="City"
                    isManadatory={false}
                    value={city}
                    onChange={(value) => setCity(value)}
                    type="text"
                  />

                  <CustomTextField
                    label="Postal Code"
                    isManadatory={false}
                    value={postalCode}
                    onChange={(value) => setPostalCode(value)}
                    type="text"
                  />
                </div>

                <div className="w-[100%] flex pl-4  mt-6 gap-5 justify-between items-center">
                  <div className="w-[68%]">
                    <DoaAndDob
                      doa={doa}
                      dob={dob}
                      setDoa={setDoa}
                      setDob={setDob}
                    />
                  </div>

                  <div className="w-[32%]">
                    <CustomTextField
                      label="Reference No"
                      isManadatory={false}
                      value={refrenceNo}
                      onChange={(value) => setReferenceNo(value)}
                      type="text"
                    />
                  </div>
                </div>

                <hr className="mt-[2.25rem] mb-[1rem]"></hr>

                <div className="mt-6 mb-5 ">
                  {showCompanyDetails ? (
                    <>
                      <div className="flex justify-between items-center">
                        <div
                          className="font-nunito font-semibold leadind-[19.2px] text-sm text-[#4D4D4D] cursor-pointer"
                          onClick={() =>
                            setShowComanyDetails((state) => !state)
                          }
                        >
                          Company Details
                        </div>
                        <div
                          onClick={() =>
                            setShowComanyDetails((state) => !state)
                          }
                        >
                          <ArrowDropUpIcon
                            fontSize="large"
                            className="text-[#2F80ED] cursor-pointer"
                          ></ArrowDropUpIcon>
                        </div>
                      </div>
                      <div className="flex mt-3 pl-4 gap-5 justify-between w-[100%]">
                        <div className="relative w-full">
                          <Input
                            autoComplete="new-password"
                            placeholder="Company Name"
                            className="w-full font-outfit border-[#00000033] border-[1px] h-[34px] text-[#4D4D4D]"
                            allowClear={{
                              clearIcon: (
                                <CloseOutlined
                                  onClick={resetSourceNameFields}
                                />
                              ),
                            }}
                            onClick={(e) => {
                              dispatch(setIsPartyInputFocused(true));
                              dispatch(
                                resetInfiniteScrolling("resetPageNumber")
                              );
                              dispatch(updateSearchText(""));
                              e.stopPropagation();
                            }}
                            onChange={(e) =>
                              dispatch(updateSearchText(e.target.value))
                            }
                            onKeyDown={(e) => {
                              if (e.keyCode === 8 && companyName) {
                                resetSourceNameFields();
                              }
                            }}
                            value={
                              Boolean(companyName) ? companyName : searchText
                            }
                          />
                          <div
                            className={`w-full h-[150px] overflow-y-scroll bg-[#fff] z-[99999] border-[#cfcfcf] rounded-[10px] shadow-md absolute ${
                              !isPartyInputFocused && "invisible"
                            }`}
                          >
                            <div
                              className="w-full h-[38px] bg-[#fff] flex items-center justify-start pl-[10px] cursor-pointer border-b-[1px] border-b-[#f2f2f2]"
                              onClick={(e) => {
                                dispatch(updateAddPartyDropdownVisible(true));
                                dispatch(setIsPartyInputFocused(false));
                                resetSourceNameFields();
                                e.stopPropagation();
                              }}
                            >
                              <span className="text-[13px] leading-[18px] text-[#007fff]">
                                ADD COMPANY
                              </span>
                            </div>
                            {partyResponse?.map((party) => {
                              const {
                                id,
                                displayName,
                                sourceType,
                                companyName,
                                companyDetails,
                              } = party;
                              return (
                                <div
                                  key={id}
                                  className="w-full h-[38px] bg-[#fff] flex items-center justify-start pl-[10px] border-b-[1px] border-b-[#f2f2f2] cursor-pointer"
                                  onClick={(e) => {
                                    setCompanyName(displayName);
                                    dispatch(updateSearchText(""));
                                    dispatch(setIsPartyInputFocused(false));
                                    e.stopPropagation();
                                  }}
                                >
                                  <span className="text-[13px] leading-[18px] text-[#00000098]">
                                    {displayName}
                                  </span>
                                </div>
                              );
                            })}

                            <div className="w-full h-[38px] bg-[#fff] flex items-center justify-center pl-[10px] border-b-[1px] border-b-[#f2f2f2]">
                              <span className="text-[13px] leading-[18px] text-[#00000098]">
                                End of the list
                              </span>
                            </div>
                          </div>
                        </div>
                        <AddPartyDropdown
                          inCustomerDetails={true}
                          partyType="source"
                          setCompanyName={setCompanyName}
                        />

                        <CustomTextField
                          label="Designation"
                          isManadatory={false}
                          value={designation}
                          onChange={(value) => setDesignation(value)}
                          type="text"
                        />
                      </div>

                      <div className="pl-4 mt-6">
                        <CustomTextField
                          label="Company GSTIN"
                          isManadatory={false}
                          value={companyGstin}
                          onChange={(value) => setCompanyGstin(value)}
                          type="text"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-between items-center">
                        <div
                          className="font-nunito font-semibold leadind-[19.2px] text-sm text-[#4D4D4D] cursor-pointer"
                          onClick={() =>
                            setShowComanyDetails((state) => !state)
                          }
                        >
                          Company Details
                        </div>
                        <div
                          onClick={() =>
                            setShowComanyDetails((state) => !state)
                          }
                        >
                          <ArrowRightIcon
                            fontSize="large"
                            className="text-[#2F80ED] cursor-pointer"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {showForeignNatinalDetails ? (
                  <>
                    <div className="mt-6 mb-4 ">
                      <div className="flex justify-between">
                        <div
                          className="font-nunito font-[400]  text-[0.875rem] text-[#4D4D4D] cursor-pointer"
                          onClick={() =>
                            setShowForeignNationalDetails((state) => !state)
                          }
                        >
                          Foreign National
                        </div>
                        <div
                          onClick={() =>
                            setShowForeignNationalDetails((state) => !state)
                          }
                        >
                          <ArrowDropUpIcon
                            fontSize="large"
                            className="text-[#2F80ED] cursor-pointer"
                          />
                        </div>
                      </div>
                      <div className="flex gap-5 pl-4  mt-6">
                        <CustomTextField
                          label="Nationality"
                          isManadatory={false}
                          value={nationality}
                          onChange={(value) => setNationality(value)}
                          type="text"
                        />
                        <CustomTextField
                          label="Passport No"
                          isManadatory={false}
                          value={passportNo}
                          onChange={(value) => setPassportNo(value)}
                          type="text"
                        />
                      </div>

                      <div className="w-[100%]   pl-4  mt-7  ">
                        <DateOfIssueExpire />
                      </div>
                    </div>

                    <div className="mt-6 mb-6 ">
                      <div className="font-nunito font-[400]  text-[0.875rem] text-[#4D4D4D]">
                        Visa Details
                      </div>

                      <div className="flex pl-4 mt-[1.3rem] gap-5  w-[100%]">
                        <CustomTextField
                          label="Visa No"
                          isManadatory={false}
                          value={visaNo}
                          onChange={(value) => setVisaNo(value)}
                          type="text"
                        />
                        <CustomTextField
                          label="Visa Type"
                          isManadatory={false}
                          value={visaType}
                          onChange={(value) => setVisaType(value)}
                          type="text"
                        />

                        <CustomTextField
                          label="Local Contact No"
                          isManadatory={false}
                          value={localContactNo}
                          onChange={(value) => setlocalContactNo(value)}
                          type="text"
                        />
                      </div>

                      <div className="flex justify-between pl-4 gap-5 mt-[1.3rem]  w-[100%]">
                        <div className="w-[32%]">
                          <CustomTextField
                            label="Purpose Of Stay"
                            isManadatory={false}
                            value={purposeOfStay}
                            onChange={(value) => setPurposeOfStay(value)}
                            type="text"
                          />
                        </div>

                        <div className="w-[68%]">
                          <ArrivalAndDurationDate />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-between">
                    <div
                      className="font-nunito font-semibold leadind-[19.2px] text-sm text-[#4D4D4D] cursor-pointer"
                      onClick={() =>
                        setShowForeignNationalDetails((state) => !state)
                      }
                    >
                      Foreign National
                    </div>
                    <div
                      onClick={() =>
                        setShowForeignNationalDetails((state) => !state)
                      }
                    >
                      <ArrowRightIcon
                        fontSize="large"
                        className="text-[#2F80ED] cursor-pointer"
                      ></ArrowRightIcon>
                    </div>
                  </div>
                )}
              </form>
            </div>
            {showPrimaryGuestDrawer.customerType === "existing" ? (
              <div>
                {loading ? (
                  <button
                    className="text-[#FFF] py-2  bg-[#007FFF] capitalize absolute  right-0 bottom-0 flex justify-center  w-[100%]  
                    text-[1.125rem] font-[600] px-auto  z-10"
                    disabled={true}
                  >
                    <CircularProgress size={24} color="inherit" />
                  </button>
                ) : (
                  <button
                    className="text-[#FFF] py-2  bg-[#007FFF] capitalize absolute  right-0 bottom-0 flex justify-center  w-[100%] 
                     text-[1.125rem] font-[600] px-auto  z-10"
                    onClick={editPrimaryGuest}
                    style={{ display: `${isAllHotelsSelected && "none"}` }}
                  >
                    Save Primary Guest
                  </button>
                )}
              </div>
            ) : (
              <div>
                {loading ? (
                  <button
                    className="text-[#FFF] py-2  bg-[#007FFF] capitalize absolute  right-0 bottom-0 flex justify-center  w-[100%] 
                     text-[1.125rem] font-[600] px-auto  z-10 "
                    disabled={true}
                  >
                    <CircularProgress size={24} color="inherit" />
                  </button>
                ) : (
                  <button
                    className="text-[#FFF] py-2  bg-[#007FFF] capitalize absolute  right-0 bottom-0 flex justify-center  w-[100%]
                      text-[1.125rem] font-[600] px-auto  z-10"
                    onClick={addNewPrimaryGuest}
                    style={{ display: `${isAllHotelsSelected && "none"}` }}
                  >
                    Add Primary Guest
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default AddPrimaryGuestDrawer;
