import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const updateFirstLoading = createAsyncThunk(
  "app/updateFirstLoading",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    return fulfillWithValue(true);
  }
);

const initialState = {
  activeMenu: "home",
  isOnline: true,
  pos: false,
  menuDrawer: true,
  isHeaderSticky: false,
  loading: false, // it is for stoping another action while fetching data
  isAddedItem: "", // it is for checking,  item insert or not
  firstLoading: false,
  isBookingEngineBuiding: false,
  isBookingEngineBuilt: false,
  screen: "", // screen will define as desktop, tab or mobile

  isChatActive: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_10: () => initialState,
    updateActiveMenu: (state, action) => {
      state.activeMenu = action.payload.activeMenu;
      state.pos = action.payload.pos;
    },

    updateIsChatActive: (state, action) => {
      state.isChatActive = action.payload;
    },

    updateMenuDrawer: (state, action) => {
      state.menuDrawer = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },

    updateIsOnline: (state, action) => {
      state.isOnline = action.payload;
    },
    updateStickyHeader: (state, action) => {
      state.isHeaderSticky = action.payload;
    },
    updateIsAddItem: (state, action) => {
      state.isAddedItem = action.payload;
    },
    redirectToPage: (state, action) => {
      state.activeMenu = action.payload.activeMenu;
      //  action.payload.navigate(action.payload.path);
    },

    updateIsBookingEngineBuiding: (state, action) => {
      state.isBookingEngineBuiding = action.payload;
    },
    updateScreen: (state, action) => {
      state.screen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFirstLoading.fulfilled, (state, action) => {
      state.firstLoading = true;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_10,
  updateActiveMenu,
  updateMenuDrawer,
  updateIsOnline,
  updateStickyHeader,
  updateLoading,
  updateIsAddItem,
  redirectToPage,
  updateIsChatActive,
  updateIsBookingEngineBuiding,
  updateScreen,
} = appSlice.actions;
export default appSlice.reducer;
