import React, { useState } from "react";
import CarouselSingle from "../../components/shared/carouselSingle";
import thumb from "../../assets/images/svgs/thumb.svg";
import { Link } from "react-router-dom";
import OtpModal from "../../components/modal/otpModal";
import { sendOtp } from "../../services/login";
import Loader from "../loading";
import { getAllRoomsAsyncThunk } from "../../reducer/roomsReducer";
import { getHotelDetails } from "../../reducer/loginReducer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateFirstLoading } from "../../reducer/appReducer";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { updateCountryCode } from "../../reducer/walkinReducer";

import { TranslateText } from "../../utils/translation.js";

const Login = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const nagivate = useNavigate();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [value, setValue] = useState("");
  const { hotelDetails, selectedHotels, isAllHotelsSelected } = useSelector(
    (store) => store.login
  );
  const { countryCode } = useSelector((store) => store.walkin);
  const { firstLoading } = useSelector((store) => store.app);

  useEffect(() => {
    // check token is there or not , if not redirect to login page
    performApi();
  }, []);

  useEffect(() => {
    const selectedHotelIds = selectedHotels?.toString();

    if (isAllHotelsSelected && selectedHotels?.length)
      dispatch(getAllRoomsAsyncThunk({ hotelIds: selectedHotelIds }));
    else if (hotelDetails?.id)
      dispatch(getAllRoomsAsyncThunk({ hotelId: hotelDetails?.id }));
  }, [selectedHotels?.toString(), isAllHotelsSelected]);

  const performApi = async () => {
    let token = localStorage.getItem("token");

    const payload = {
      navigate: nagivate,
    };
    if (Boolean(token)) {
      dispatch(getHotelDetails(payload));
    } else dispatch(updateFirstLoading());
  };

  const handlePhoneChange = (value, country) => {
    const parts = country.inputValue.split(" ");
    let countryCode = parts?.[0];

    const joinedString = parts
      ?.slice(1)
      ?.map((str) => str?.replace(/\D/g, "")) // Remove non-numeric characters
      ?.join("");

    setPhone(joinedString);
    setValue(value);

    dispatch(updateCountryCode(countryCode));
  };

  const sendOTP = async (e) => {
    localStorage.removeItem("token");
    localStorage.removeItem("hotelId");
    e.preventDefault();

    localStorage.setItem("phone", phone);
    try {
      const data = {
        countryCode: countryCode,
        phone,
      };

      const sendOtpData = await sendOtp(data, "login/sendOTP");
      if (sendOtpData.status >= 200 && sendOtpData.status <= 304) {
        setDisplayModal(true);
      }
    } catch (error) {
      return error;
    }
  };

  return !firstLoading ? (
    <Loader />
  ) : (
    <>
      {displayModal && <OtpModal setDisplayModal={setDisplayModal} />}
      <div className="login-page relative py-[60px] min-h-screen">
        <div className="bg-[#F5F5F5] absolute w-1/2 right-0 top-0 h-full"></div>
        <div className="custom-container relative z-10">
          <div className="flex md:flex-row flex-col justify-between items-center md:gap-14">
            <div className="md:w-1/2 w-full hidden md:block">
              <CarouselSingle />
            </div>
            <div className="md:w-1/2 w-full p-14">
              <div className="text-center">
                <img
                  src={thumb}
                  className="block mx-auto mb-[20px] h-[100px]"
                  alt="thumb"
                />
                <h2 className="sm:text-2xl md:text-3xl text-4xl font-[500] text-[#333333] mb-[60px] ">
                  <TranslateText
                    textKey={"welcome_to_revivo_HMS"}
                    namespace="login"
                  />
                </h2>
              </div>

              <p className="sm:text-[18px] md:text-[20px] text-[22px] mb-[17px]">
                <TranslateText
                  textKey={"enter_your_mobile_no"}
                  namespace="login"
                />
              </p>
              <p className="font-bold text-lg mb-[20px]">
                <TranslateText
                  textKey={"you_will_recieve_a_4_digit_code_to_verify_next"}
                  namespace="login"
                />
              </p>

              <form onSubmit={sendOTP}>
                <div className="w-[90%]">
                  <PhoneInput
                    defaultCountry="in"
                    value={value}
                    onChange={handlePhoneChange}
                    required={true}
                  />
                </div>
                <div className="flex justify-start items-center mt-[46px]">
                  <input type="checkbox" className="checkbox" id="agree" />
                  <label htmlFor="agree" className="text-[#333333] ml-[10px]">
                    <TranslateText
                      textKey={"i_agree_to_the"}
                      namespace="login"
                    />
                    <Link href="/" className="text-[#007FFF]">
                      <TranslateText
                        textKey={"terms_&_conditions"}
                        namespace="login"
                      />
                    </Link>
                  </label>
                </div>
                <button className="btn-primary w-[90%] mt-[60px] text-2xl font-semibold rounded-[8px]">
                  <TranslateText textKey={"login"} namespace="login" />
                </button>
              </form>
              <p className="font-bold text-xl text-black mt-[50px]">
                <TranslateText
                  textKey={"dont_have_an_account_yet"}
                  namespace="login"
                />
                <Link to="/signup" className="text-[#007FFF]">
                  <TranslateText textKey={"sign_up"} namespace="login" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
