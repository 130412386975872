import React from "react";

function NameInitial({ itemObj }) {
  return (
    <div className="relative bg-[#BBD8FF] w-[35px] h-[35px] rounded-full">
      <div className="bg-[#BBD8FF] rounded-full"></div>
      <span className="absolute top-[20%] left-[25%]">
        {itemObj?.name.charAt(0) +
          itemObj?.name.charAt(itemObj?.name?.indexOf(" ") + 1)}
      </span>
    </div>
  );
}

export default NameInitial;
