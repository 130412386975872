import { Button, Input, Space } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateSupplier } from "../../../../../reducer/manageStocksReducer/suppliersReducer";

const AddEditSupplierDetails = ({ data, setData, setEdit }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(data[0]);
  const [addressParts, setAddressParts] = useState(
    formData.addressOne.split(",").concat(formData.addressTwo.split(","))
  );

  const handleAddressChange = (index, value) => {
    const newAddressParts = [...addressParts];
    newAddressParts[index] = value;
    setAddressParts(newAddressParts);

    setFormData((prevFormData) => ({
      ...prevFormData,
      addressOne: newAddressParts[0],
      addressTwo: newAddressParts[1],
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("addressOne_part")) {
      const index = parseInt(
        name.split("_")[1].charAt(name.split("_")[1].length - 1)
      );
      handleAddressChange(index, value);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    setData([formData]);
    setEdit(false);
    dispatch(updateSupplier(formData));
  };

  return (
    <section className="h-full">
      <div className="h-full flex flex-col justify-between">
        <div>
          <div>
            <Space>
              <p className="text-[24px]">Edit Supplier Details</p>
            </Space>
          </div>
          <div className="w-full rounded-md flex flex-col gap-6 py-10 items-center">
            <Input
              name="name"
              className="h-[44px]"
              value={formData.name}
              placeholder={formData.name}
              onChange={(e) => handleChange(e)}
            />
            <Input
              name="addressOne_part0"
              className="h-[44px]"
              value={addressParts[0]}
              placeholder="Address line 1"
              onChange={(e) => handleChange(e)}
            />
            <Input
              name="addressOne_part1"
              className="h-[44px]"
              value={addressParts[1]}
              placeholder="Address line 2"
              onChange={(e) => handleChange(e)}
            />
            <div className="flex gap-2">
              <Input
                name="city"
                className="h-[44px]"
                value={formData.city}
                placeholder="City"
                onChange={(e) => handleChange(e)}
              />
              <Input
                name="state"
                className="h-[44px]"
                placeholder="State"
                value={formData.state}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <Input
              name="pinCode"
              className="h-[44px] w-1/2 mr-[51%] ml-0"
              value={formData.pinCode}
              placeholder="Pincode"
              onChange={(e) => handleChange(e)}
            />
            <Input
              name="phone"
              className="h-[44px]"
              value={formData.phone}
              placeholder="Phone Number"
              onChange={(e) => handleChange(e)}
            />
            <Input
              name="email"
              className="h-[44px]"
              value={formData.email}
              placeholder="Email"
              onChange={(e) => handleChange(e)}
            />
            <Input
              name="primaryContact"
              className="h-[44px]"
              placeholder="Primary Contact"
              value={formData.primaryContact}
              onChange={(e) => handleChange(e)}
            />
            <Input
              name="panNumber"
              className="h-[44px]"
              value={formData.panNumber}
              placeholder="Pan Number"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <Button
          onClick={() => handleSubmit()}
          type="primary"
          block
          className="bg-[#007FFF] text-[18px] font-semibold w-[320px] h-[46px] rounded-md mt-"
        >
          SAVE
        </Button>
      </div>
    </section>
  );
};

export default AddEditSupplierDetails;
