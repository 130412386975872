import { authedAxios } from "../../utils/customHttpHeader";

export const getCategoriesService = async (payload) => {
  const reponse = await authedAxios().get(`hotels/${payload.hotelId}`);
  return reponse;
};

export const addNewCategoryService = async (payload) => {
  const response = await authedAxios().patch(
    `hotels/${localStorage.getItem("hotelid")}`,
    payload
  );
  return response;
};

export const removeCategoryService = async (payload) => {
  const reponse = await authedAxios().patch(
    `hotels/${localStorage.getItem("hotelid")}`,
    payload
  );
  return reponse;
};


export const fetchCategories = async (payload) => {
  const response = await authedAxios().get(`hotels/${payload}`);
  return response;
};

export const addCategoryService = async (payload) => {
  const { id } = payload;
  const response = await authedAxios().put(`hotels/${id}`, payload);
  return response;
};
