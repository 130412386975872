import React, { useEffect, useState } from "react";
import { Button, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import { bookingDetailsDrawerWidth } from "../../../utils/bookingDetailsHelper";
import { CircularProgress } from "@mui/material";
import useAllAddOnFunction from "../../../hooks/bookingCustomHook/useAllAddonFunction";
import {
  updateBookingListData,
  updateInvoiceDetails,
  updateShowAddonsDrawer,
} from "../../../reducer/bookingReducer";
import { updateBookingListData as updateBookingReservationListData } from "../../../reducer/bookingReservationReducer";
import CustomTaxModal from "../../common/components/CustomTaxModal";
import CustomTextField from "../../common/InputField/Input";
import { updateDisplayEditRoomTypeCustomTaxModal } from "../../../reducer/settingsReducer";

const AddOnsDrawer = () => {
  const dispatch = useDispatch();

  const { showAddonsDrawer, invoiceDetails, response } = useSelector(
    (store) => store.booking
  );
  const [loading, setLoading] = useState(false);

  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const {
    initializeBookingDetailsAddOnArray,
    handleShowCount,
    handleInputChange,
    handleAddNewAddons,
    handleDeleteExistingAddOns,
    handleAddOnSearch,
    applyAddonsBookingDetais,
  } = useAllAddOnFunction();

  useEffect(() => {
    initializeBookingDetailsAddOnArray(
      invoiceDetails,
      setOriginalData,
      setFilteredData,
      showAddonsDrawer
    );
  }, [showAddonsDrawer]);

  useEffect(() => {
    handleAddOnSearch(originalData, setFilteredData, searchText);
  }, [searchText]);

  const handleClose = () => {
    dispatch(updateShowAddonsDrawer(false));
    dispatch(updateDisplayEditRoomTypeCustomTaxModal(false));
  };

  return (
    <Drawer
      width={bookingDetailsDrawerWidth}
      anchor={"right"}
      open={showAddonsDrawer}
      onClose={() => handleClose()}
      closeIcon={null}
    >
      <div
        className="w-[100%] relative h-[100%]"
        onClick={() => dispatch(updateDisplayEditRoomTypeCustomTaxModal(false))}
      >
        <div
          className={`p-4 px-6 flex fixed  w-[800px]  right-0 top-[1vh] flex-col gap-[18px] mb-[12px]`}
        >
          <div className="flex justify-between items-center">
            <IoMdCloseCircle className="cursor-pointer" onClick={handleClose} />
            <Button
              type="default"
              className="font-outfit font-[500] text-[16px] leading-[28px] text-[#007FFF] cursor-pointer"
              onClick={() =>
                handleAddNewAddons(setOriginalData, setFilteredData)
              }
            >
              Add New
            </Button>
          </div>
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Add-ons"
            className="border-[1px] border-[#EDF1F7] outline-none  bg-[#F5F7FA] text-[#8F9BB3] font-outfit font-[500] text-[16px] w-[100%] h-[48px] rounded-[8px]  p-2 pl-3"
          />
        </div>

        <main className="flex flex-col fixed pt-[5px] top-[10vh] overflow-y-auto w-[800px] right-0 gap-3 pl-[1rem] h-[60vh] mb-[6rem] mt-[5rem]">
          {filteredData?.map((obj, idx) => {
            let count = originalData?.find(
              (mainObj) => mainObj?.uniqueIdx === obj?.uniqueIdx
            )?.count;

            return (
              <div key={obj._id} className="flex gap-[12px] items-center">
                <div className="w-[30%]">
                  <CustomTextField
                    label="Item Name"
                    isMandatory={true}
                    value={obj?.name}
                    onChange={(value) =>
                      handleInputChange(
                        originalData,
                        filteredData,
                        setOriginalData,
                        setFilteredData,
                        value,
                        obj?.uniqueIdx,
                        "name"
                      )
                    }
                    type="text"
                  />
                </div>

                <div className="w-[20%]">
                  <CustomTextField
                    label="Amount"
                    isMandatory={true}
                    value={obj?.price}
                    onChange={(value) =>
                      handleInputChange(
                        originalData,
                        filteredData,
                        setOriginalData,
                        setFilteredData,
                        value,
                        obj?.uniqueIdx,
                        "price"
                      )
                    }
                    type="number"
                  />
                </div>

                <div className="w-[30%]">
                  <CustomTaxModal
                    data={obj}
                    handleSaveChanges={undefined}
                    setAllInputData={undefined}
                    isAddNewTax={false}
                    setOriginalData={setOriginalData}
                    setFilteredData={setFilteredData}
                    mainIndex={obj?.uniqueIdx}
                    isBookingTaxModal={true}
                  />
                </div>

                {Boolean(count) ? (
                  <div className="gap-2 py-[2px] px-[4px] rounded-[6px] flex justify-center items-center bg-[#FFF] border-[1px] border-[#2F80ED]">
                    <Button
                      icon={<FaMinus className="text-[16px] text-[#2F80ED]" />}
                      type="default"
                      className="border-0 bg-[#FFF] flex justify-center items-center text-[20px] font-outfit"
                      onClick={() =>
                        handleShowCount(
                          originalData,
                          filteredData,
                          setOriginalData,
                          setFilteredData,
                          "decrease",
                          obj?.uniqueIdx
                        )
                      }
                    />

                    <div className="font-outfit font-[500] text-[#2F80ED] text-[14px]">
                      {count || 0}
                    </div>

                    <Button
                      icon={<FaPlus className="text-[16px] text-[#2F80ED]" />}
                      type="default"
                      className="border-0 bg-[#FFF] flex justify-center items-center text-[20px] font-outfit"
                      onClick={() =>
                        handleShowCount(
                          originalData,
                          filteredData,
                          setOriginalData,
                          setFilteredData,
                          "increase",
                          obj?.uniqueIdx
                        )
                      }
                    />
                  </div>
                ) : (
                  <div
                    className="cursor-pointer border-[1px] border-[#007FFF] font-outfit font-[500] text-[14px] py-[8px] px-[35px] leading-[21px] bg-[#007FFF0F] rounded-[6px]"
                    onClick={() =>
                      handleShowCount(
                        originalData,
                        filteredData,
                        setOriginalData,
                        setFilteredData,
                        "increase",
                        obj?.uniqueIdx
                      )
                    }
                  >
                    Add
                  </div>
                )}

                <AiOutlineCloseCircle
                  className="cursor-pointer text-[24px] text-[#8F9BB3]"
                  onClick={() =>
                    handleDeleteExistingAddOns(
                      originalData,
                      filteredData,
                      setOriginalData,
                      setFilteredData,
                      obj?.uniqueIdx,
                      obj?.id
                    )
                  }
                />
              </div>
            );
          })}
        </main>

        <div
          className={` absolute w-[100%] bottom-0 right-0 flex items-center p-3 bg-[#FFF] shadow-md justify-center shadow-mobile-footer`}
        >
          {loading ? (
            <CircularProgress size={18} />
          ) : (
            <button
              type="submit"
              onClick={() =>
                applyAddonsBookingDetais(
                  setLoading,
                  originalData,
                  response,
                  invoiceDetails,
                  updateInvoiceDetails,
                  updateBookingListData,
                  updateBookingReservationListData,
                  updateShowAddonsDrawer
                )
              }
              className=" w-[95%] py-[14px] text-lg font-semibold text-center 
            rounded-[10px] shadow-custom uppercase bg-[#007FFF] text-[#FFF]"
            >
              Apply
            </button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default AddOnsDrawer;
