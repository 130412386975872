export const ITEMS_TOTAL_AMOUNT = (priceBreakup) => {
  let itemPrice = 0;

  priceBreakup?.forEach((item) => {
    itemPrice += item?.rate * +item.count;

    let addOnPrice = 0;

    Array.isArray(item?.addOns) &&
      item.addOns
        ?.filter((Obj) => Obj?.price && Obj?.count)
        ?.forEach((addOn) => {
          addOnPrice += +addOn.price * addOn.count;
        });

    itemPrice = Boolean(addOnPrice) ? itemPrice + addOnPrice : itemPrice;
  });

  return Boolean(itemPrice) ? +itemPrice : 0;
};

export const POS_TYPE = [
  "Restaurant",
  "Banquet",
  "Laundry",
  "Retail",
  "Salon",
  "Spa",
]; // do not change the sequence , it will cause messive error if change

export const POS_SERVICE_TYPE = ["dineIn", "room", "delivery", "pickup"]; // do not change the sequence, it will cause messive error if change

export const FOOD_TYPE = ["Veg", "Non veg"]; // do not change the sequence

export const ITEM_STATUS = ["Active", "Deactive"];

// item price maight be vary for service type, like restaurant there will be  (dineIn price, room price , delivery price & pickup price)
// and for rest service type , there will be one price
export const GET_ITEM_PRICE = (serviceType, posType, item) => {
  let price = 0;

  // if service type is not present
  if (!Boolean(serviceType)) {
    if (posType === POS_TYPE[0]) {
      let servicePrice = 0;

      if (item?.pricesByType) {
        let key = Object.keys(item?.pricesByType)?.[0];
        servicePrice = item?.pricesByType[key];
      }
      return +servicePrice;
    } else {
      return +item?.price;
    }
  } else {
    if (posType === POS_TYPE[0]) {
      let itemService =
        serviceType === "table" ? POS_SERVICE_TYPE[0] : serviceType;
      price = item?.pricesByType?.[itemService];
    } else {
      price = item?.price;
    }

    return Boolean(+price) ? +price : 0;
  }
};

export const ACTIVE_POS_DETAILS = (allPos, activePosSlug) => {
  let {
    gstRate,
    ingredientCategories,
    id,
    ingredientUnits,
    itemCategories,
    itemUnits,
    name,
    sac,
    serviceTypes,
    stewards,
    type,
  } = allPos?.filter((item) => item.slug === activePosSlug)?.[0] || {};

  return {
    gstRate,
    ingredientCategories,
    id,
    ingredientUnits,
    itemCategories,
    itemUnits,
    name,
    sac,
    serviceTypes,
    stewards,
    type,
  };
};

export const FOOD_CHOICE_OBJ = {
  veg: "Veg",
  nonVeg: "Non veg",
};

export const POS_SERVICE_TYPE_OBJ = {
  dineIn: "DineIn",
  room: "Room",
  delivery: "Delivery",
  pickup: "Pickup",
};

export const ITEM_STATUS_OBJ = {
  active: "Active",
  deactive: "In-active",
};

export const GST_LIST = ["0", "5", "12", "18", "28"];

export const POS_EXCLUDING_STATUS_CALCULATION = ["held", "cancelled"];
