import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaTimesCircle } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { createNewPos } from "../../../reducer/posReducer/posSettingReducer";

import Steward from "./Steward";
import { Button, message } from "antd";
import { FaEdit } from "react-icons/fa";
import { GST_LIST, POS_TYPE } from "../../../utils/posHelper";
import { updateActiveMenu } from "../../../reducer/appReducer";

const PosForms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hotelDetails } = useSelector((store) => store.login);
  const { posApiLoading } = useSelector((store) => store.posSetting);

  const posTypes = POS_TYPE;

  const [setIsPosUpdate] = useState("");

  const [isItemUnitAdded, setIsItemUnitAdded] = useState(false);
  const [isItemCatAdded, setIsItemCatAdded] = useState(false);
  const [isStewardAdded, setIsStewardAdded] = useState(false);

  const [selectedPosUnitKey, setSelectPosUnitKey] = useState(null);
  const [selectedPosCategoryKey, setSelectedPosCategoryKey] = useState(null);
  const [selectedStewardKey, setSelectedStewardKey] = useState(null);

  const [posData, setPosData] = useState({});

  let posUnits = Boolean(posData["itemUnits"]) ? posData["itemUnits"] : [];

  let posCategories = Boolean(posData["itemCategories"])
    ? posData["itemCategories"]
    : [];

  useEffect(() => {
    setPosData({ ...posData, type: POS_TYPE[0] });
  }, []);

  const handlePosData = (e) => {
    setPosData({ ...posData, [e.target.name]: e.target.value });
  };

  const handlePosUnit = (value, key, action) => {
    setIsItemUnitAdded(false);
    if (!value) {
      return null;
    }

    if (Boolean(key) || key == 0) {
      if (Boolean(value)) {
        setIsPosUpdate(key);
      }
      setSelectPosUnitKey(key);
      let updatedUnitVal = posUnits?.map((unitVal, unitKey) => {
        if (unitKey === key) {
          return value;
        }
        return unitVal;
      });
      setPosData({ ...posData, itemUnits: updatedUnitVal });
    } else {
      if (Array.isArray(posUnits) && posUnits.indexOf(value) == -1) {
        let updatedUnitVal = [...posUnits, value];
        setPosData({ ...posData, itemUnits: updatedUnitVal, unitName: "" });
      }
    }
  };

  const handlePosUnitDelete = (key) => {
    let updatedUnitVal = posUnits?.filter((unitVal, unitKey) => {
      return unitKey !== key;
    });

    setPosData({ ...posData, itemUnits: updatedUnitVal, unitName: "" });
  };

  const handlePosCategory = (value, key) => {
    setIsItemCatAdded(false);
    if (!value) {
      return null;
    }

    if (Boolean(key) || key == 0) {
      setSelectedPosCategoryKey(key);

      let updatedCatVal = posCategories?.map((catVal, catKey) => {
        if (catKey === key) {
          return value;
        }
        return catVal;
      });

      setPosData({ ...posData, itemCategories: updatedCatVal });
    } else {
      if (Array.isArray(posCategories) && posCategories.indexOf(value) == -1) {
        let updatedCatVal = [...posCategories, value];

        setPosData({ ...posData, itemCategories: updatedCatVal, catName: "" });
      }
    }
  };

  const handlePosCategoryDelete = (key) => {
    let updatedCatVal = posCategories?.filter((unitVal, unitKey) => {
      return unitKey !== key;
    });

    setPosData({ ...posData, itemCategories: updatedCatVal });
  };

  const submitPosData = async () => {
    let name = posData["name"];

    if (Boolean(name)) {
      let payload = {
        ...posData,
        hotelId: hotelDetails?.id,
        type: Boolean(posData["type"]) ? posData["type"] : POS_TYPE[0],
        gstRate: Boolean(posData["gstRate"]) ? posData["gstRate"] : GST_LIST[1],
      };

      let response = await dispatch(createNewPos(payload));

      if (response?.payload?.id) {
        setPosData({});
        navigate(`/dashboard/${response?.payload?.slug}`);

        dispatch(
          updateActiveMenu({
            activeMenu: "dashboard",
            pos: true,
          })
        );
      }
    } else {
      message.error("Pos name is required");
    }
  };

  return (
    <form className="w-[80%] m-auto mt-[2rem] ">
      <div className=" shadow p-4 relative max-h-[75vh] overflow-y-auto">
        <div className=" mb-5">
          <p className="block mb-2  font-medium text-gray-700 text-2xl dark:text-white">
            Pos type  
          </p>

          <select
            required
            className="w-full mt-1 bg-gray-50 p-2 border  border-gray-300  rounded-lg"
            name="type"
            onChange={(e) => handlePosData(e)}
            placeholder="Select Pos-type"
            value={posData["type"]}
          >
            {posTypes.map((posType) => {
              return (
                <option key={posType} value={posType}>
                  {posType}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-5">
          <label className="block mb-2 font-medium text-gray-700 text-2xl dark:text-white ">
            Pos name
          </label>

          <input
            required
            type="text"
            placeholder="Enter Pos name"
            onChange={(e) => handlePosData(e)}
            name="name"
            value={Boolean(posData["name"]) ? posData["name"] : ""}
            // value={selectedItem["name"]}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5
   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          />
        </div>
        <div className="mb-5">
          <label className="block mb-2 font-medium text-gray-700 text-2xl dark:text-white ">
            Sac
          </label>

          <input
            type="text"
            id="base-input"
            placeholder="Enter sac"
            onChange={(e) => handlePosData(e)}
            value={Boolean(posData["sac"]) ? posData["sac"] : ""}
            name="sac"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5
               dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          />
        </div>

        <div className="mb-5">
          <label className="block mb-2 font-medium text-gray-700 text-2xl dark:text-white">
            Default Gst Rate (percentage)
          </label>

          <>
            <select
              id="gst"
              name="gstRate"
              onChange={handlePosData}
              value={
                Boolean(posData["gstRate"]) ? posData["gstRate"] : GST_LIST[1]
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option selected> Select items Gst</option>
              {GST_LIST?.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item} <span className="ml-[2px]">%</span>
                  </option>
                );
              })}
            </select>
          </>
        </div>

        <div className="mb-5">
          <div className="rounded p-2 max-h-[40rem] overflow-y-auto">
            <div className="block mb-2 font-medium text-gray-700 text-2xl dark:text-white">
              Item Units
            </div>
            <hr />

            <div className="flex flex-wrap">
              <div className="mt-2 flex mr-2 mb-2 flex-wrap">
                {posUnits?.map((val, key) => {
                  return (
                    <div className="relative mr-2 mb-2">
                      <input
                        onClick={(e) => handlePosUnit(e.target.value, key)}
                        onChange={(e) => handlePosUnit(e.target.value, key)}
                        onBlur={(e) => setSelectPosUnitKey(null)}
                        onKeyDown={(e) =>
                          e.key == "Enter" ? setSelectPosUnitKey(null) : null
                        }
                        value={val}
                        type="text"
                        className=" h-[2.8rem] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                      {!(selectedPosUnitKey === key) && (
                        <>
                          <FaEdit className="absolute right-4 top-3" />
                          <FaTimesCircle
                            onClick={() => handlePosUnitDelete(key)}
                            className="absolute right-[-2px] top-[-5px] cursor-pointer"
                          />
                        </>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="mt-2 flex mr-2 ">
                {isItemUnitAdded ? (
                  <input
                    onChange={(e) => handlePosData(e)}
                    onBlur={() => {
                      handlePosUnit(posData["unitName"], null);
                    }}
                    onKeyDown={(e) =>
                      e.key == "Enter"
                        ? handlePosUnit(posData["unitName"], null)
                        : null
                    }
                    name="unitName"
                    type="text"
                    className=" bg-gray-50 border border-gray-300 h-[2.8rem] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                ) : (
                  <>
                    <Button
                      onClick={() => setIsItemUnitAdded(true)}
                      className="flex mt-3 "
                    >
                      <FaPlus className="mr-1 mt-1" />{" "}
                      <span>Add Item Unit</span>
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="block mb-2 font-medium text-gray-700 text-2xl dark:text-white ">
            Item Categories
          </div>

          <div className="flex flex-wrap">
            <div className="mt-2 flex mr-2 mb-2 flex-wrap">
              {posCategories?.map((val, key) => {
                return (
                  <div className="relative mr-2 mb-2">
                    <input
                      onClick={(e) => handlePosCategory(e.target.value, key)}
                      onChange={(e) => handlePosCategory(e.target.value, key)}
                      onBlur={() => setSelectedPosCategoryKey(null)}
                      onKeyDown={(e) =>
                        e.key == "Enter"
                          ? setSelectedPosCategoryKey(null)
                          : null
                      }
                      value={val}
                      type="text"
                      className=" h-[2.8rem] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    {!(selectedPosCategoryKey === key) && (
                      <>
                        <FaEdit className="absolute right-4 top-3" />
                        <FaTimesCircle
                          onClick={() => handlePosCategoryDelete(key)}
                          className="absolute right-[-2px] top-[-5px] cursor-pointer"
                        />
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="mt-2 flex mr-2 ">
              {isItemCatAdded ? (
                <input
                  onChange={(e) => handlePosData(e)}
                  onBlur={() => {
                    setIsItemCatAdded(false);
                    handlePosCategory(posData["catName"], null);
                  }}
                  onKeyDown={(e) =>
                    e.key == "Enter"
                      ? handlePosCategory(posData["catName"], null)
                      : null
                  }
                  name="catName"
                  type="text"
                  className=" bg-gray-50 border border-gray-300 h-[2.8rem] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              ) : (
                <>
                  <Button
                    onClick={() => setIsItemCatAdded(true)}
                    className="flex mt-3 "
                  >
                    <FaPlus className="mr-1 mt-1" />{" "}
                    <span>Add Item Category</span>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>

        {!Boolean(posData["type"]) ||
          (posData["type"] == POS_TYPE[0] && (
            <>
              <Steward
                setPosData={setPosData}
                posData={posData}
                handlePosData={handlePosData}
                setSelectedStewardKey={setSelectedStewardKey}
                selectedStewardKey={selectedStewardKey}
                isStewardAdded={isStewardAdded}
                setIsStewardAdded={setIsStewardAdded}
              />
            </>
          ))}
      </div>
      <Button
        className="bg-primary text-white float-right asbolute bottom-0 mt-3"
        loading={posApiLoading}
        onClick={submitPosData}
      >
        Save
      </Button>
    </form>
  );
};

export default PosForms;
