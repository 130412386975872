import {
  saveAllRoomType,
  putRoomTypeService,
  deleteRoomTypeService,
} from "../services/roomType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { updateHotelDetailsByRoomType } from "./loginReducer";
import { updateRoomByRoomResponse, resetInitialRoomData } from "./roomsReducer";
import { all } from "axios";
import { values } from "lodash";

export const saveAllRoomTypeAsync = createAsyncThunk(
  "roomType",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let hotelDetails = getState()?.login?.hotelDetails;
      let allRooms = getState()?.rooms?.response;

      let response = await saveAllRoomType(payload);

      // Rooms inside hotel

      if (
        typeof hotelDetails === "object" &&
        !Array.isArray(hotelDetails) &&
        hotelDetails !== null
      ) {
        dispatch(
          updateHotelDetailsByRoomType({
            ...hotelDetails,
            roomTypes: response?.data?.roomTypes,
          })
        );
      }

      // update all new rooms inside room reducer

      if (Array.isArray(response?.data?.rooms)) {
        dispatch(
          updateRoomByRoomResponse([...allRooms, ...response?.data?.rooms])
        );
      }

      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message);
      } else {
        message.error(error.message);
      }

      throw rejectWithValue(error.message);
    }
  }
);

export const putRoomTypeAsyncThunk = createAsyncThunk(
  "updateRoom/rooms",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let hotelDetails = getState()?.login?.hotelDetails;
      let allRooms = getState()?.rooms?.response;
      let editedRoomType = getState()?.rooms?.singleRoomData?.name;

      const response = await putRoomTypeService(payload);

      let createdRooms = response?.data?.rooms;

      let uniqueRooms = [];

      createdRooms?.forEach((item) => {
        if (allRooms.map((item) => item.name).indexOf(+item?.name) == -1) {
          uniqueRooms.push(item.name);
        }
      });

      if (
        typeof hotelDetails === "object" &&
        !Array.isArray(hotelDetails) &&
        hotelDetails !== null
      ) {
        dispatch(
          updateHotelDetailsByRoomType({
            ...hotelDetails,
            roomTypes: response?.data?.roomTypes,
          })
        );
      }

      if (Array.isArray(response?.data?.rooms)) {
        const updatedRoomsList = allRooms
          ?.filter((room) => room.type !== editedRoomType)
          ?.concat(response?.data.rooms);
        dispatch(updateRoomByRoomResponse(updatedRoomsList));
      }
      dispatch(resetInitialRoomData());

      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message);
      } else {
        message.error(error.message);
      }

      throw rejectWithValue(error.message);
    }
  }
);

export const getRoomTypeAsyncThunk = createAsyncThunk(
  "getRoom/rooms",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let hotelDetails = getState()?.login?.hotelDetails;
      let allRooms = getState()?.rooms?.response;
      let editedRoomType = getState()?.rooms?.singleRoomData?.name;

      const response = await putRoomTypeService(payload);

      let createdRooms = response?.data?.rooms;

      let uniqueRooms = [];

      createdRooms?.forEach((item) => {
        if (allRooms.map((item) => item.name).indexOf(+item?.name) == -1) {
          uniqueRooms.push(item.name);
        }
      });

      if (
        typeof hotelDetails === "object" &&
        !Array.isArray(hotelDetails) &&
        hotelDetails !== null
      ) {
        dispatch(
          updateHotelDetailsByRoomType({
            ...hotelDetails,
            roomTypes: response?.data?.roomTypes,
          })
        );
      }

      if (Array.isArray(response?.data?.rooms)) {
        const updatedRoomsList = allRooms
          ?.filter((room) => room.type !== editedRoomType)
          ?.concat(response?.data.rooms);
        dispatch(updateRoomByRoomResponse(updatedRoomsList));
      }
      dispatch(resetInitialRoomData());

      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message);
      } else {
        message.error(error.message);
      }

      throw rejectWithValue(error.message);
    }
  }
);

export const deleteRoomTypeAsyncThunk = createAsyncThunk(
  "roomTypeDelete",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await deleteRoomTypeService(payload.payload);
      let hotelDetails = getState()?.login?.hotelDetails;
      let allRooms = getState()?.rooms?.response;
      dispatch(updateDisplayDeleteRoomTypeModal());
      if (
        typeof hotelDetails === "object" &&
        !Array.isArray(hotelDetails) &&
        hotelDetails !== null
      ) {
        dispatch(
          updateHotelDetailsByRoomType({
            ...hotelDetails,
            roomTypes: hotelDetails?.roomTypes?.filter(
              (type) => type?._id !== payload?.deletedRoomObject?._id
            ),
          })
        );
      }

      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  deleteLoading: false,
  loading: false, // before api call
  roomTypeResponse: [], // store  room list json data into it
  apiError: false, // check api status
  roomNoArrayOfObj: [],
  currentAndPreViousAmenities: [], //store  amenities
  showAddonsDrawerForCreate: false,
  createAddons: null,
  addonItemsForEdit: [],
  isApplyCssOnClick: false,
  displayDeleteRoomTypeModal: false,
};

export const roomTypeSlice = createSlice({
  name: "roomType",
  initialState,

  reducers: {
    updateDisplayDeleteRoomTypeModal: (state, action) => {
      state.displayDeleteRoomTypeModal = !state.displayDeleteRoomTypeModal;
    },
    showRoomViewData: (state, action) => {
      state.roomNoArrayOfObj = action.payload;
    },
    updateApplyCssOnClick: (state, action) => {
      state.isApplyCssOnClick = action.payload;
    },
    updateCreateAddons: (state, action) => {
      state.createAddons = action.payload;
    },
    updatedAddonItemsForEdit: (state, action) => {
      state.addonItemsForEdit = action.payload;
    },
    updateCurrentAndPreViousAmenities: (state, action) => {
      state.currentAndPreViousAmenities = action.payload;
    },
    openAddonsDrawerForCreate: (state, action) => {
      state.showAddonsDrawerForCreate = !state.showAddonsDrawerForCreate;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(saveAllRoomTypeAsync.pending, (state, action) => {
      state.loading = true;
      state.apiError = false;
    });
    builder.addCase(saveAllRoomTypeAsync.fulfilled, (state, action) => {
      message.success("Data Successfully Updated!...");
      state.loading = false;
      state.apiError = false;
      state.roomTypeResponse = action.payload;
    });
    builder.addCase(saveAllRoomTypeAsync.rejected, (state, action) => {
      state.loading = false;
      state.apiError = true;
      state.apiError = action.error.message;
    });

    builder.addCase(putRoomTypeAsyncThunk.pending, (state, action) => {
      state.loading = true;
      state.apiError = false;
    });
    builder.addCase(putRoomTypeAsyncThunk.fulfilled, (state, action) => {
      message.success("Data Successfully Updated!...");

      state.loading = false;
      state.apiError = false;
      state.roomTypeResponse = action.payload;
    });
    builder.addCase(putRoomTypeAsyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.apiError = action.error.message;
    });

    builder.addCase(deleteRoomTypeAsyncThunk.pending, (state, action) => {
      state.deleteLoading = true;
      state.apiError = false;
    });
    builder.addCase(deleteRoomTypeAsyncThunk.fulfilled, (state, action) => {
      message.success("Room Type deleted successfully");
      state.deleteLoading = false;
      state.apiError = false;
      state.roomTypeResponse = action.payload;
    });
    builder.addCase(deleteRoomTypeAsyncThunk.rejected, (state, action) => {
      state.deleteLoading = false;
      state.apiError = true;
      state.apiError = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  showRoomViewData,
  openAddonsDrawerForCreate,
  updateCreateAddons,
  updateCurrentAndPreViousAmenities,
  updatedAddonItemsForEdit,
  updateApplyCssOnClick,
  updateDisplayDeleteRoomTypeModal,
} = roomTypeSlice.actions;

export default roomTypeSlice.reducer;
