export const gstTreatmentList = [
  "Registered Company - Regular",
  "Unregistered company - Composition",
  "Unregistered Business",
  "Overseas",
  "Special Economic Zone",
  "Deemed Export",
  "Tax Deductor",
  "SEZ Developer",
];

export const tdsList = [10, 9, 8, 7];

export const autocompleteStyling = {
  width: "50%",
  "& label": {
    color: "#0000004D",
    fontFamily: "Poppins",
    fontSize: "15px",
  },
  "& input": {
    fontFamily: "Poppins",
    fontSize: "0.75rem",
    fontWeight: "400",
    color: "#4D4D4D",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
  "& .MuiInputLabel-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "nunito",
    color: "#808080",
    fontSize: "0.75rem", // Font size when label is down
  },
  "& .MuiInputLabel-shrink": {
    fontSize: "0.93rem", // Font size when label is up (focused)
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "nunito",
  },
};

export const textFieldStyling = {
  width: "100%",

  "& label": {
    color: "#0000004D",
    fontFamily: "Poppins",
    fontSize: "15px",
  },
  "& input": {
    fontFamily: "Poppins",
    fontSize: "0.75rem",
    fontWeight: "400",
    color: "#4D4D4D",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
  "& .MuiInputLabel-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "nunito",
    color: "#808080",
    fontSize: "0.75rem", // Font size when label is down
  },
  "& .MuiInputLabel-shrink": {
    fontSize: "0.93rem", // Font size when label is up (focused)
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "nunito",
  },
};

export const partyTypesArray = [
  {
    label: "Vendor",
    value: "vendor",
  },
  {
    label: "Source",
    value: "source",
  },
  {
    label: "Customer",
    value: "customer",
  },
  {
    label: "Staff",
    value: "staff",
  },
  {
    label: "Bank",
    value: "bank",
  },
  {
    label: "Bank Payment Mode",
    value: "bankPaymentMode",
  },
];

export const valueToLabel = {
  vendor: "Vendor",
  source: "Source",
  customer: "Customer",
  staff: "Staff",
  bank: "Bank",
  bankPaymentMode: "Bank Payment Mode",
};

export const partyTransactionObj = {
  amount: "",
  creditPartyBalance: 0,
  creditPartyId: "",
  debitPartyBalance: 0,
  debitPartyId: "",
  description: "",
  hotelId: "",
  createdAt: "",
};

export const partyTransactionMiniObj = {
  creditPartyBalance: 0,
  creditPartyId: "",
  debitPartyBalance: 0,
  debitPartyId: "",
};

export const transactionStyles = { width: "w-[100%]" };

export const partyTypes = [
  { name: "Vendor", key: "vendor" },
  { name: "Source", key: "source" },
  { name: "Staff", key: "staff" },
];

export const partyTypeKeyToName = {
  vendor: "Vendor",
  source: "Source",
  staff: "Staff",
};

export const partyObj = {
  address: "",
  bankDetails: {
    accountNumber: "",
    bankName: "",
    beneficiaryName: "",
    ifscCode: "",
    upiId: "",
  },
  city: "",
  companyDetails: {
    currency: "Indian Rupee (INR)",
    documents: [],
    gstInOrUIN: "",
    gstTreatment: "",
    panNumber: "",
    paymentTerms: 0,
    stateOfSupply: "",
    tds: 0,
  },
  companyName: "",
  country: "India",
  createdBy: "",
  displayName: "",
  emailId: "",
  hotelId: "",
  lastModifiedBy: "",
  mobilePhone: "",
  partyType: null,
  postalCode: "",
  primaryContact: "",
  sourceType: null,
  status: true,
  workPhone: "",
  remarks: "",
};

export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Viet Nam",
  "Wallis and Futuna",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const currencies = [
  "Rupee (INR)",
  "Dollar (USD)",
  "Euro (EUR)",
  "Yen (JPY)",
  "Pound Sterling (GBP)",
  "Australian Dollar (AUD)",
  "Canadian Dollar (CAD)",
  "Swiss Franc (CHF)",
  "Yuan Renminbi (CNY)",
  "Swedish Krona (SEK)",
  "New Zealand Dollar (NZD)",
  "Mexican Peso (MXN)",
  "Singapore Dollar (SGD)",
  "Hong Kong Dollar (HKD)",
  "Norwegian Krone (NOK)",
  "South Korean Won (KRW)",
  "Turkish Lira (TRY)",
  "Russian Ruble (RUB)",
  "Indian Rupee (INR)",
  "Brazilian Real (BRL)",
  "South African Rand (ZAR)",
  "Danish Krone (DKK)",
  "Polish Zloty (PLN)",
  "Thai Baht (THB)",
  "Indonesian Rupiah (IDR)",
  "Hungarian Forint (HUF)",
  "Czech Koruna (CZK)",
  "Israeli Shekel (ILS)",
  "Chilean Peso (CLP)",
  "Philippine Peso (PHP)",
  "United Arab Emirates Dirham (AED)",
  "Colombian Peso (COP)",
  "Saudi Riyal (SAR)",
  "Malaysian Ringgit (MYR)",
  "Romanian Leu (RON)",
  "Nigerian Naira (NGN)",
  "Argentine Peso (ARS)",
  "Taiwan New Dollar (TWD)",
  "Kuwaiti Dinar (KWD)",
  "Egyptian Pound (EGP)",
  "Qatari Riyal (QAR)",
  "Pakistani Rupee (PKR)",
  "Vietnamese Dong (VND)",
  "Ukrainian Hryvnia (UAH)",
  "Bangladeshi Taka (BDT)",
  "Iranian Rial (IRR)",
  "Algerian Dinar (DZD)",
  "Kenyan Shilling (KES)",
  "Moroccan Dirham (MAD)",
  "Iraqi Dinar (IQD)",
  "Omani Rial (OMR)",
  "Nepalese Rupee (NPR)",
  "Tanzanian Shilling (TZS)",
  "Tunisian Dinar (TND)",
  "Ugandan Shilling (UGX)",
  "Ghanaian Cedi (GHS)",
  "Sri Lankan Rupee (LKR)",
  "Myanmar Kyat (MMK)",
  "Bahraini Dinar (BHD)",
  "Jordanian Dinar (JOD)",
  "Lebanese Pound (LBP)",
  "Yemeni Rial (YER)",
  "Cambodian Riel (KHR)",
  "Guatemalan Quetzal (GTQ)",
  "Ethiopian Birr (ETB)",
  "Namibian Dollar (NAD)",
  "Libyan Dinar (LYD)",
  "Armenian Dram (AMD)",
  "Bulgarian Lev (BGN)",
  "Central African CFA Franc (XAF)",
  "Macanese Pataca (MOP)",
  "Trinidad and Tobago Dollar (TTD)",
  "Zambian Kwacha (ZMW)",
  "West African CFA Franc (XOF)",
  "Mauritian Rupee (MUR)",
  "East Caribbean Dollar (XCD)",
  "Georgian Lari (GEL)",
  "Albanian Lek (ALL)",
  "CFP Franc (XPF)",
  "Fijian Dollar (FJD)",
  "Malagasy Ariary (MGA)",
  "Icelandic Krona (ISK)",
  "Swazi Lilangeni (SZL)",
  "Liberian Dollar (LRD)",
  "Bermudian Dollar (BMD)",
  "Haitian Gourde (HTG)",
  "Macedonian Denar (MKD)",
  "Djiboutian Franc (DJF)",
  "Solomon Islands Dollar (SBD)",
  "Lesotho Loti (LSL)",
  "Seychellois Rupee (SCR)",
  "Surinamese Dollar (SRD)",
  "Bahamian Dollar (BSD)",
  "Netherlands Antillean Guilder (ANG)",
  "Turkmenistan New Manat (TMT)",
  "Azerbaijan Manat (AZN)",
  "Cape Verdean Escudo (CVE)",
  "Salvadoran Colon (SVC)",
  "Afghan Afghani (AFN)",
  "North Korean Won (KPW)",
  "Mozambican Metical (MZN)",
  "Samoan Tala (WST)",
  "Tajikistani Somoni (TJS)",
  "Kyrgyzstani Som (KGS)",
  "SDR (XDR)",
  "Tuvaluan Dollar (TVD)",
];

export const constPaymentTermsList = [
  { name: "10 Days", value: 10 },
  { name: "15 Days", value: 15 },
  { name: "30 Days", value: 30 },
  { name: "60 Days", value: 60 },
  { name: "90 Days", value: 90 },
  { name: "180 Days", value: 180 },
  { name: "365 Days", value: 365 },
];

export const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
