import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "antd";

import housekeepingReducer, {
  updateIsOngoingFilter,
  updateIsUpcomingFilter,
  updateIsCheckoutFilter,
  updateSelectedRoomsId,
  uncheckAllRooms,
  checkAllRooms,
  resetFilters,
} from "../../../reducer/housekeepingReducer";
import { TranslateText } from "../../../utils/translation";

import { ReactComponent as HouseKeepingUnchecked } from "../../../assets/icons/houseKeepingUnchecked.svg";
import { ReactComponent as HouseKeepingChecked } from "../../../assets/icons/houseKeepingChecked.svg";

const RoomsGrid = ({
  groupedRoomsByType,
  upcomingBookingsRoomsId,
  checkedInRoomId,
  completedRoomId,
  combinedFilterArray,
  roomsArray,
}) => {
  const [roomCount, setRoomCount] = useState({});
  const [isAllChecked, setIsAllChecked] = useState({});

  const {
    isUpcomingFilter,
    isOngoingFilter,
    isCheckoutFilter,
    selectedRoomsId,
    isOutOfOrderActive,
    selectedRoomStatus,
    selectedHousekeeperName,
  } = useSelector((store) => store.housekeeping);
  const dispatch = useDispatch();

  useEffect(() => {
    const combinedRoomIds = Object.values(groupedRoomsByType).reduce(
      (acc, curr) => acc.concat(curr),
      []
    );

    const countElementsInCombinedRoomIds = (idsArray) => {
      return idsArray.reduce((count, roomId) => {
        const foundRoom = combinedRoomIds.find((room) => room.id === roomId);
        return foundRoom ? count + 1 : count;
      }, 0);
    };
    const updatedRoomCount = {
      upcoming: countElementsInCombinedRoomIds(upcomingBookingsRoomsId),
      ongoing: countElementsInCombinedRoomIds(checkedInRoomId),
      completed: countElementsInCombinedRoomIds(completedRoomId),
    };
    setRoomCount(updatedRoomCount);
  }, [groupedRoomsByType]);

  useEffect(() => {
    function checkAllRoomsSelected(selectedRoomsId, groupedRoomsByType) {
      const result = {};

      for (const roomType in groupedRoomsByType) {
        const roomsOfType = groupedRoomsByType[roomType];
        const allRoomsSelected = roomsOfType.every((room) =>
          selectedRoomsId.includes(room.id)
        );
        result[roomType] = allRoomsSelected;
      }

      return result;
    }
    setIsAllChecked(checkAllRoomsSelected(selectedRoomsId, groupedRoomsByType));
  }, [selectedRoomsId, groupedRoomsByType]);

  const getNumberByRoomStatusType = (bookingsArray) => {
    if (!Array.isArray(roomsArray)) return 0;
    const rooms = roomsArray
      ?.filter((room) => room?.houseKeepingStatus === selectedRoomStatus)
      ?.filter((room) => bookingsArray?.includes(room?.id));

    return rooms?.length || 0;
  };

  return (
    <div className="w-full bg-[#fff] rounded-[10px] relative">
      {Object.keys(groupedRoomsByType).length > 0 ? (
        Object.keys(groupedRoomsByType)
          ?.sort((a, b) => a.localeCompare(b))
          ?.map((roomType, idx) => {
            const roomsArray = groupedRoomsByType[roomType];
            return (
              <div className="w-full">
                <div
                  className={`pl-[24px] pr-[20px] flex items-center justify-between ${
                    idx === 0 ? "h-[83px] pt-[24px]" : "h-[55px]"
                  } ${
                    idx < Object.keys(groupedRoomsByType)?.length
                      ? "border-b-[1px] border-b-[#0000001A]"
                      : ""
                  }`}
                >
                  <div className="flex items-center gap-[10px]">
                    <div>
                      {isAllChecked[roomType] ? (
                        <HouseKeepingChecked
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            dispatch(
                              uncheckAllRooms(groupedRoomsByType[roomType])
                            )
                          }
                        />
                      ) : (
                        <HouseKeepingUnchecked
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            dispatch(
                              checkAllRooms(groupedRoomsByType[roomType])
                            )
                          }
                        />
                      )}
                    </div>
                    <div className="flex items-center gap-[15px]">
                      <p className="font-nunito font-[500] text-[20px] leading-[27.28px] tracking-[-0.3px] text-[#000000B2]">
                        {roomType}
                      </p>
                      {idx === 0 && (
                        <p className="text-[12px] leading-[18px] text-[#808080] pt-[3px]">
                          {isOutOfOrderActive
                            ? ""
                            : `Select room to ${
                                selectedRoomStatus === "clean"
                                  ? "mark as dirty"
                                  : "complete"
                              }`}
                        </p>
                      )}
                    </div>
                  </div>
                  {!isOutOfOrderActive && idx === 0 && (
                    <div className="flex gap-[15px] items-center">
                      <Button
                        className={`rounded-[10px] h-[30px] px-[13px] py-[10px] border-[0.5px] flex items-center justify-center ${
                          isUpcomingFilter
                            ? "text-[#2F80ED] border-[#2F80ED]"
                            : "text-[#808080] border-[#00000026]"
                        }`}
                        onClick={() => dispatch(updateIsUpcomingFilter())}
                      >
                        <span className="text-[14px] leading-[21px]">
                          {`Upcoming (${getNumberByRoomStatusType(
                            upcomingBookingsRoomsId
                          )})`}
                        </span>
                      </Button>
                      <Button
                        className={`rounded-[10px] h-[30px] px-[13px] py-[10px] border-[0.5px] flex items-center justify-center ${
                          isOngoingFilter
                            ? "text-[#2F80ED] border-[#2F80ED]"
                            : "text-[#808080] border-[#00000026]"
                        }`}
                        onClick={() => dispatch(updateIsOngoingFilter())}
                      >
                        <span className="text-[14px] leading-[21px]">
                          {`Ongoing (${getNumberByRoomStatusType(
                            checkedInRoomId
                          )})`}
                        </span>
                      </Button>
                      <Button
                        className={`rounded-[10px] h-[30px] px-[13px] py-[10px] border-[0.5px] flex items-center justify-center ${
                          isCheckoutFilter
                            ? "text-[#2F80ED] border-[#2F80ED]"
                            : "text-[#808080] border-[#00000026]"
                        }`}
                        onClick={() => dispatch(updateIsCheckoutFilter())}
                      >
                        <span className="text-[14px] leading-[21px]">
                          {`Checkout (${getNumberByRoomStatusType(
                            completedRoomId
                          )})`}
                        </span>
                      </Button>
                    </div>
                  )}
                </div>
                <div className="px-[34px] py-[20px] flex items-center gap-[25px] flex-wrap">
                  {roomsArray?.length > 0 &&
                    roomsArray?.map((room) => {
                      const { name, id, houseKeeperName } = room;

                      return (
                        <button
                          className={`w-[71px] h-[37px] rounded-[5px] border-[1px] ${
                            houseKeeperName === selectedHousekeeperName
                              ? "border-[#007FFF] text-[#00000080] text-[#007FFF]"
                              : "border-[#00000026] border-dashed text-[#00000080]"
                          }   flex items-center justify-center bg-[#f5f5f5] relative`}
                          onClick={() => dispatch(updateSelectedRoomsId(id))}
                        >
                          <span className="font-[500] text-[16px] leading-[24px]">
                            {name}
                          </span>
                          {selectedRoomsId.includes(id) && (
                            <HouseKeepingChecked className="absolute top-[-11px] left-[-9px]" />
                          )}
                        </button>
                      );
                    })}
                </div>
              </div>
            );
          })
      ) : (
        <div className="h-[500px] w-full bg-[#fafafa] flex items-center justify-center pt-[35px]">
          {isOutOfOrderActive ? (
            <p className="">
             
              <TranslateText
        textKey={"no_out_of_order_rooms_present"}
        namespace="housekeeping"
      />
              </p>
          ) : isUpcomingFilter || isOngoingFilter || isCheckoutFilter ? (
            <p className="">
               <TranslateText
        textKey={"no_rooms_present_for_the_selected_filters_please"}
        namespace="housekeeping"
      />
              {" "}
              <span
                className="cursor-pointer text-[#0000EE]"
                onClick={() => dispatch(resetFilters())}
              >
                <TranslateText
        textKey={"reset"}
        namespace="housekeeping"
      />
                
              </span>{" "}
              
              <TranslateText
        textKey={"the_filters_to_view_rooms"}
        namespace="housekeeping"
      />
            </p>
          ) : (
            <p> 
              <TranslateText
            textKey={"no_rooms_present_to"}
            namespace="housekeeping"
          />
              {`${selectedRoomStatus}.`}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default RoomsGrid;
