const SupplierDetailListItem = ({ title, value }) => {
  return (
    <div className="flex justify-between gap-3 w-full">
      <div className="font-poppins text-left font-[18px] itmes-center">
        {title}
      </div>
      <div className="font-semibold text-right font-[18px] items-center">
        {value === "undefined, undefined" ? "" : value}
      </div>
    </div>
  );
};

export default SupplierDetailListItem;
