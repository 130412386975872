import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import {
  addOnsCalculation,
  bookingDetailsAddOnsCalculation,
} from "../../utils/bookingHelper";
import { message } from "antd";
import { deleteAddOn, postMultipleAddOn } from "../../reducer/addOnReducer";
import { updateinvoice } from "../../services/invoice";

const useAllAddOnFunction = () => {
  const { hotelDetails } = useSelector((store) => store.login);
  const { taxResponse } = useSelector((store) => store.tax);
  const { addOns } = useSelector((store) => store.addOns);
  const dispatch = useDispatch();

  const initializeSettingsAddOnArray = (
    addOnsArray,
    setOriginalData,
    setFilteredData
  ) => {
    let newAddOnsArray = [];

    newAddOnsArray = addOnsArray?.map((Obj) => {
      return {
        ...Obj,
        uniqueIdx: Boolean(Obj?.uniqueIdx) ? Obj?.uniqueIdx : nanoid(),
      };
    });

    setOriginalData(newAddOnsArray);
    setFilteredData(newAddOnsArray);
  };

  const initializeAddOnArray = (
    addOnsArray,
    setOriginalData,
    setFilteredData
  ) => {
    if (!Array.isArray(addOns)) {
      setOriginalData([]);
      setFilteredData([]);
      return;
    }

    const newAddOnsArray = addOns?.map((Obj) => {
      const matchingAddOn = addOnsArray?.find(
        (mainObj) => mainObj?.name === Obj?.name
      );

      return {
        ...Obj,
        count: matchingAddOn?.count || 0,
        uniqueIdx: Obj?.uniqueIdx || nanoid(),
      };
    });

    setOriginalData(newAddOnsArray);
    setFilteredData(newAddOnsArray);
  };

  const handleShowCount = (
    originalData,
    filteredData,
    setOriginalData,
    setFilteredData,
    action,
    id
  ) => {
    const updateCount = (Obj) => ({
      ...Obj,
      count:
        action === "increase"
          ? (Obj?.count || 0) + 1
          : Math.max(Obj?.count - 1, 0),
    });

    const updateData = (data) =>
      data.map((Obj) => (Obj?.uniqueIdx === id ? updateCount(Obj) : Obj));

    const newOriginalData = updateData(originalData);
    const newFilteredData = updateData(filteredData);

    setOriginalData(newOriginalData);
    setFilteredData(newFilteredData);
  };

  const handleInputChange = (
    originalData,
    filteredData,
    setOriginalData,
    setFilteredData,
    value,
    mainIndex,
    fieldName
  ) => {
    const trimmedValue =
      fieldName === "price"
        ? +value.replace(/[^0-9]/g, "").replace(/^0+/, "")
        : value;

    const updateField = (data) =>
      data.map((Obj) =>
        Obj?.uniqueIdx === mainIndex
          ? { ...Obj, [fieldName]: trimmedValue }
          : Obj
      );

    setOriginalData(updateField(originalData));
    setFilteredData(updateField(filteredData));
  };

  const handleAddNewAddons = (setOriginalData, setFilteredData) => {
    const newObj = {
      name: "",
      price: 0,
      type: "AddOns",
      uniqueIdx: nanoid(),
      hotelId: hotelDetails?.id,
    };

    const addNewObj = (prev) => [newObj, ...prev];

    setOriginalData(addNewObj);
    setFilteredData(addNewObj);
  };

  const handleDeleteExistingAddOns = (
    originalData,
    filteredData,
    setOriginalData,
    setFilteredData,
    deleteIdx,
    id
  ) => {
    const filterData = (data) =>
      data?.filter((Obj) => Obj?.uniqueIdx !== deleteIdx);

    if (id) dispatch(deleteAddOn({ id: id, hotelId: hotelDetails?.id }));

    setOriginalData(filterData(originalData));
    setFilteredData(filterData(filteredData));
  };

  const handleAddOnSearch = (originalData, setFilteredData, searchText) => {
    const trimmedSearch = searchText?.trim()?.toLowerCase();

    setFilteredData(
      trimmedSearch
        ? originalData?.filter((obj) =>
            obj?.name?.toLowerCase()?.includes(trimmedSearch)
          )
        : originalData
    );
  };

  const applyAddOnsWalkin = async (
    originalData,
    updateAddOnsArray,
    handleClose
  ) => {
    const filterAndMap = (arr, predicate, mapper) =>
      arr.filter(predicate).map(mapper);

    // const requiredAddOnsArray = filterAndMap(
    //   originalData,
    //   (obj) => obj.name, // Ensure the add-on has a name
    //   ({ name, price, type, id, taxIds, hotelId, createdAt }) => ({
    //     name,
    //     price,
    //     type,
    //     id,
    //     taxIds,
    //     hotelId,
    //     createdAt,
    //   })
    // );

    const response = await dispatch(postMultipleAddOn(originalData));

    if (response?.payload) {
      const newAddOnsArray = response.payload.map((Obj) => {
        const matchingAddOn = originalData.find(
          (mainObj) => mainObj.name === Obj.name
        );

        return {
          ...Obj,
          count: matchingAddOn?.count || 0,
          uniqueIdx: Obj.uniqueIdx || nanoid(),
        };
      });

      const localAddOnsArray = filterAndMap(
        newAddOnsArray,
        (obj) => obj.count && obj.name, // Ensure add-on has a count and name
        (obj) => obj
      );

      dispatch(updateAddOnsArray(localAddOnsArray));
      handleClose();
    }
  };

  const initializeBookingDetailsAddOnArray = (
    invoiceDetails,
    setOriginalData,
    setFilteredData,
    showAddonsDrawer
  ) => {
    if (!showAddonsDrawer) return;

    let roomInvoice = invoiceDetails?.find(
      (Obj) => Obj?.invoiceType === "roomBill"
    );

    const addonBreakup = roomInvoice?.priceBreakup?.find(
      (Obj) => Obj?.type === "Addons"
    );

    let alreadyPresentBookingAddons = [];

    if (addonBreakup?.addOns) {
      alreadyPresentBookingAddons = addonBreakup.addOns.map((Obj) => {
        let currentAddOn = addOns?.find(
          (currObj) => Obj?.name === currObj?.name
        );
        return {
          ...Obj,
          ...currentAddOn, // Spread the properties of the matching add-on, if found
          id: Obj?.addOnId, // Override or add the `id` property
        };
      });
    }

    let finalAddOnArray = addOns.map((addOnObj) => {
      let matchingAddon = alreadyPresentBookingAddons.find(
        (alreadyObj) => alreadyObj?.name === addOnObj?.name
      );

      return {
        ...addOnObj, // Keep the complete object from addOns
        count: matchingAddon ? matchingAddon.count : 0, // Include count from alreadyPresentBookingAddons
      };
    });

    let currentAddOns = [
      ...finalAddOnArray,
      ...(alreadyPresentBookingAddons?.filter(
        (Obj) => !addOns?.some((existingObj) => existingObj?.name === Obj?.name)
      ) || []),
    ];

    // currentAddOns = currentAddOns?.map((Obj) => {});

    // Create a new array with unique IDs for each add-on
    let newAddOnsArray = currentAddOns?.map((Obj) => ({
      ...Obj,
      uniqueIdx: nanoid(),
    }));

    // Update state with the new add-ons array
    setOriginalData(newAddOnsArray);
    setFilteredData(newAddOnsArray);
  };

  const applyAddonsBookingDetais = async (
    setLoading,
    originalData,
    response,
    invoiceDetails,
    updateInvoiceDetails,
    updateBookingListData,
    updateBookingReservationListData,
    updateShowAddonsDrawer
  ) => {
    setLoading(true);

    const filterAndMap = (arr, predicate, mapper) =>
      arr.filter(predicate).map(mapper);

    const addOnsResponse = await dispatch(postMultipleAddOn(originalData));

    if (addOnsResponse?.payload) {
      const newAddOnsArray = addOnsResponse?.payload?.map((Obj) => {
        const matchingAddOn = originalData.find(
          (mainObj) => mainObj.name === Obj.name
        );

        return {
          ...Obj,
          count: matchingAddOn?.count || 0,
          uniqueIdx: Obj.uniqueIdx || nanoid(),
        };
      });

      const localAddOnsArray = filterAndMap(
        newAddOnsArray,
        (obj) => obj.count && obj.name, // Ensure add-on has a count and name
        (obj) => obj
      );

      // First We will remove existing addOnsObject from priceBreakup and aits sum in total
      // and then we will add current Addons

      let roomInvoice = invoiceDetails?.find(
        (Obj) => Obj?.invoiceType === "roomBill"
      );
      let taxObjectArray = roomInvoice?.taxObjects;

      let oldAddOnsArray = roomInvoice?.priceBreakup?.filter(
        (Obj) => Obj?.type === "Addons"
      );

      oldAddOnsArray = Array.isArray(oldAddOnsArray)
        ? oldAddOnsArray?.[0]?.addOns
        : [];

      let oldAddOnsCalcResult = bookingDetailsAddOnsCalculation(
        oldAddOnsArray,
        taxObjectArray
      );

      let newAddOnsArrayFiltered = localAddOnsArray?.filter(
        (Obj) => Boolean(Obj?.count) && Boolean(Obj?.name)
      );

      let newAddOnsCalculation = addOnsCalculation(
        newAddOnsArrayFiltered,
        taxResponse
      );

      let requiredAddOnsArrayFormat = newAddOnsArrayFiltered?.map((Obj) => {
        return {
          ...Obj,
          count: Obj?.count,
          name: Obj?.name,
          price: Obj?.price,
          taxIds: Obj?.taxIds,
          addOnId: Obj?.id,
        };
      });

      let newPriceBreakup = roomInvoice?.priceBreakup?.filter(
        (Obj) => Obj?.type !== "Addons"
      );

      Array.isArray(requiredAddOnsArrayFormat) &&
        requiredAddOnsArrayFormat?.length &&
        newPriceBreakup?.push({
          addOns: requiredAddOnsArrayFormat,
          baseRate: 0,
          rate: newAddOnsCalculation?.addOnCostWithoutTax,
          count: 1,
          type: "Addons",
        });

      let newInvoiceDetails = JSON.parse(JSON.stringify(roomInvoice));

      newInvoiceDetails.totalTax =
        newInvoiceDetails?.totalTax -
        (Boolean(oldAddOnsCalcResult?.totalAddOnsTaxWithCount)
          ? oldAddOnsCalcResult?.totalAddOnsTaxWithCount
          : 0) +
        (Boolean(newAddOnsCalculation?.totalAddOnsTax)
          ? newAddOnsCalculation?.totalAddOnsTax
          : 0);

      newInvoiceDetails.total =
        newInvoiceDetails?.total -
        (Boolean(oldAddOnsCalcResult?.addOnsTotalWithCount)
          ? oldAddOnsCalcResult?.addOnsTotalWithCount
          : 0) +
        (Boolean(newAddOnsCalculation?.addOnsTotal)
          ? newAddOnsCalculation?.addOnsTotal
          : 0);

      newInvoiceDetails.priceBreakup = newPriceBreakup;

      const payload = {
        hotelId: newInvoiceDetails?.hotelId,
        id: newInvoiceDetails?.id,
        body: newInvoiceDetails,
      };

      const data = await updateinvoice(payload);

      if (Boolean(data?.id)) {
        const updatedData = invoiceDetails?.map((item) =>
          item?.id === data?.id ? data : item
        );

        await dispatch(updateInvoiceDetails(updatedData));
        const newResponse = JSON.parse(JSON.stringify(response));

        const invoiceIndex = newResponse?.invoices?.findIndex(
          (Obj) => Obj.id === updatedData?.[0].id
        );

        newResponse.invoices[invoiceIndex] = updatedData?.[0];

        await dispatch(updateBookingListData(newResponse));
        await dispatch(updateBookingReservationListData(newResponse));
        dispatch(updateShowAddonsDrawer());
      } else message.error("Something Went Wrong");
    }

    setLoading(false);
  };

  return {
    initializeSettingsAddOnArray,
    initializeAddOnArray,
    handleShowCount,
    handleInputChange,
    handleAddNewAddons,
    handleDeleteExistingAddOns,
    handleAddOnSearch,
    applyAddOnsWalkin,
    applyAddonsBookingDetais,
    initializeBookingDetailsAddOnArray,
  };
};

export default useAllAddOnFunction;
