import React, { useEffect, useState } from "react";
import PosWrapper from "../wrapper/Index";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateActiveMenu } from "../../../../reducer/appReducer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  createTableOrder,
  updateSelectedRider,
} from "../../../../reducer/posReducer/restaurant";
import { removeCategories } from "../../../../reducer/manageStocksReducer/categoriesReducer";
import { getPartyList } from "../../../../reducer/partyReducer";
import { resetInvoicePostDataAndOrderData } from "../../../../reducer/orderReducer";
import MainContainer from "./MainContainer";
import SideContainer from "./SideContainer";
import AddItemDrawer from "../../drawer/AddItemDrawer";
import usePosStorageLogic from "../../../../hooks/posCustomHook/usePosStorageLogic";

function NewOrderRevamped() {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);
  const { customerDetails, tableOrder } = useSelector((state) => state.pos);

  const categories = useSelector((state) => state.categories.data);
  const [items, setItems] = useState([]);

  const { activePosName } = useSelector((store) => store.posSetting);

  const location = useLocation();

  useEffect(() => {
    dispatch(
      createTableOrder({
        ...tableOrder,
        name: customerDetails?.name,
        customerId: customerDetails?.id,
      })
    );
  }, [customerDetails]);

  useEffect(() => {
    let newOrderPage = location.pathname.split("/")?.[4];
    if (newOrderPage === "new-order") {
      dispatch(
        updateActiveMenu({
          activeMenu: "POS",
          pos: false,
        })
      );
    }

    return () => {
      dispatch(
        updateActiveMenu({
          activeMenu: "POS", // menu name
          pos: false,
        })
      );
    };
  }, [categories]);

  useEffect(() => {
    dispatch(
      getPartyList({
        hotelId: hotelDetails?.id,
        pageSize: 100,
        pageNumber: 0,
      })
    );
  }, []);

  useEffect(() => {
    // will deselect the selected categoris like snack ,beverages
    return () => {
      dispatch(removeCategories());
      dispatch(updateSelectedRider(""));
      dispatch(resetInvoicePostDataAndOrderData());
    };
  }, []);

  usePosStorageLogic();

  return (
    <div className="relative pt-[1.5rem]">
      <PosWrapper
        headerName={activePosName}
        leftSideContent={<MainContainer setItems={setItems} items={items} />}
        rightSideContent={<SideContainer customer={customerDetails} />}
      />
      <AddItemDrawer />
    </div>
  );
}

export default NewOrderRevamped;
