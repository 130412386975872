import { authedAxios } from "../utils/customHttpHeader";

export const editItemService = async (payload) => {
  try {
    const response = await authedAxios().put(
      `items/${payload.itemId}`,
      payload.formData
    );
    return response.data;
  } catch (err) {
    return err;
  }
};
