import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { updateDisplayTakePaymentDrawer } from "../../../../reducer/posReducer/restaurant";

const Footer = ({ setOrderDetailScreenVisible, createBill }) => {
  const dispatch = useDispatch();

  const { invoicePostData } = useSelector((store) => store.order);

  return (
    <>
      {(invoicePostData?.status === "billed" ||
        invoicePostData?.status === "inProgress" ||
        invoicePostData?.status === "paid" ||
        invoicePostData?.status === "due") && (
        <footer className="bg-[#FFF] fixed bottom-0 w-full shadow-mobile-footer flex justify-center items-center px-3 py-6 z-[50]">
          {invoicePostData?.status !== "inProgress" ? (
            <div
              className="w-full bg-[#007FFF] rounded-[8px] p-3 flex justify-center items-center font-outfit font-[500] text-[16px] leading-[24px] text-[#FFF]"
              onClick={() => dispatch(updateDisplayTakePaymentDrawer(true))}
            >
              Take Payment
            </div>
          ) : (
            <div
              className="w-[95%] bg-[#007FFF] rounded-[12px] p-3 flex justify-center items-center font-outfit font-[500] text-[16px] leading-[24px] text-[#FFF]"
              onClick={createBill}
            >
              Bill
            </div>
          )}
        </footer>
      )}
    </>
  );
};

export default Footer;
