import { Progress } from "antd";
import React, { useEffect, useState } from "react";
import { updateShowPrimaryGuestDrawer } from "../../../../reducer/bookingReducer";
import { useSelector, useDispatch } from "react-redux";
import { customerDetailsById } from "../../../../services/customer";
import { Tooltip } from "@mui/material";
import { TranslateText } from "../../../../utils/translation";
const PrimaryGuest = ({ id, idx, total, bookingType }) => {
  const {
    showMainBookingDetailsDrawer,
    bookingDetails,
    customerDetails,
    bookingHotelId,
    invoiceDetails,
  } = useSelector((store) => store.booking);
  const { editBookingAllowed } = useSelector((store) => store.usersRestriction);

  const dispatch = useDispatch();
  const { hotelDetails, isAllHotelsSelected } = useSelector(
    (store) => store.login
  );
  const allowEditingAfterCheckout = hotelDetails?.allowEditingAfterCheckout;

  const [customerData, setCustomerData] = useState({});
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [roomBillInvoiceId, setRoomBillInvoiceId] = useState("");

  useEffect(() => {
    if (bookingHotelId) performApi();
  }, [id, bookingDetails, customerDetails, bookingHotelId, roomBillInvoiceId]);

  const performApi = async () => {
    const payload = {
      hotelId: bookingHotelId,
      id: id,
      invoiceId: roomBillInvoiceId?.id,
    };
    const { data } = await customerDetailsById(payload);
    setCustomerData(data);
    completionChart(data);
  };

  useEffect(() => {
    if (Array.isArray(invoiceDetails)) {
      // finding room bill invoice id
      const upadtedRoomBillInvoiceId = invoiceDetails?.find(
        ({ invoiceType }) => invoiceType == "roomBill"
      );
      setRoomBillInvoiceId(upadtedRoomBillInvoiceId);
    }
  }, [invoiceDetails]);

  const completionChart = (data) => {
    let completed = 0;

    if (data?.phone) completed += 7.7;
    if (data?.name) completed += 7.7;

    if (data?.idImages?.length > 0) completed += 7.7;
    if (data?.email) completed += 7.7;

    if (data?.gender) completed += 7.7;

    if (data?.address) completed += 7.7;
    if (data?.country) completed += 7.7;

    if (data?.city) completed += 7.7;
    if (data?.zipCode) completed += 7.7;

    if (data?.dob) completed += 7.7;
    if (data?.company) completed += 7.7;
    if (data?.gstIN) completed += 7.7;
    if (data?.designation) completed += 7.7;

    setTotalCompleted(completed);
  };

  const handlePrimaryGuest = (type, customerId) => {
    dispatch(
      updateShowPrimaryGuestDrawer({
        visible: true,
        bookingId: showMainBookingDetailsDrawer.bookingId,
        customerType: type,
        customerId: customerId,
      })
    );
  };

  let isBookingAlterationAllowed = Boolean(
    editBookingAllowed &&
      (bookingType !== "completed" || allowEditingAfterCheckout) &&
      !isAllHotelsSelected
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-1 items-center">
        <Progress
          type="circle"
          percent={Math.floor(totalCompleted)}
          size="small"
          strokeWidth={12}
          className="text-[#717171] text-sm bg-transparent ml-2"
        />

        <div className="ml-1 mt-[-5px]">
          <p className="text-[0.75rem] font-[400] text-[#9D9D9D] font-nunito">
            <TranslateText textKey={"primary_guest"} namespace="booking" />
          </p>

          <Tooltip
            title={customerData?.name?.split(0, 15)}
            className={`${
              editBookingAllowed
                ? "cursor-pointer text-[#2F80ED]"
                : "text-[non-editable-text-color]"
            } font-nunito  font-[600] text-[0.875rem] `}
            onClick={
              editBookingAllowed
                ? () => handlePrimaryGuest("existing", id)
                : undefined
            }
          >
            {customerData?.name?.slice(0, 15) ?? ""}
          </Tooltip>
        </div>
      </div>

      {/* {isBookingAlterationAllowed ? (
        idx === total - 1 ? (
          <div className="relative">
            <hr className="w-[100%]  my-3 border-[#C4C4C4] " />

            {editBookingAllowed && !isAllHotelsSelected && (
              <div
                className="absolute top-0 left-[45%] w-[5rem] h-[1.25rem] bg-[#2F80ED] text-[#FFF] font-[Poppins] text-[0.625rem] font-[400] flex justify-center items-center rounded-[0.3125rem] cursor-pointer"
                onClick={() => handlePrimaryGuest("new", "")}
              >
                <TranslateText textKey={"add_new"} namespace="booking" />
              </div>
            )}
          </div>
        ) : (
          <div className="relative">
            <hr className="w-[100%]  my-3 border-[#C4C4C4] " />
          </div>
        )
      ) : (
        <div className="relative">
            <hr className="w-[100%]  my-3 border-[#C4C4C4] " />
          </div>
      )}  */}
    </div>
  );
};

export default PrimaryGuest;
