import React, { useEffect, Suspense, lazy } from "react";
// import TableView from "../../components/pos/tableView";
// import MobileScreenTableView from "../../mobileComponents/mobileScreenPos/tableView/index";
import { POS_TYPE } from "../../utils/posHelper";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../pages/loading";
const TableView = lazy(() => import("../../components/pos/tableView"));
const MobileScreenTableView = lazy(() =>
  import("../../mobileComponents/mobileScreenPos/tableView/index")
);

const TableViews = () => {
  const { activePosType, activePosSlug } = useSelector(
    (store) => store.posSetting
  );
  const navigate = useNavigate();
  const { isMobileScreen } = useSelector((store) => store.appHeader);

  useEffect(() => {
    if (
      activePosType === POS_TYPE[1] ||
      activePosType === POS_TYPE[3] ||
      activePosType === POS_TYPE[4] ||
      activePosType === POS_TYPE[5]
    ) {
      navigate(`/dashboard/${activePosSlug}`);
    }
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <div>{isMobileScreen ? <MobileScreenTableView /> : <TableView />}</div>
    </Suspense>
  );
};

export default TableViews;
