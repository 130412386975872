import { Suspense, lazy } from "react";
import Loading from "../../pages/loading";
const PartyMainPage = lazy(() =>
  import("../../components/party/PartyMainPage")
);

const Index = () => {
  return (
    <Suspense fallback={<Loading />}>
      <PartyMainPage />
    </Suspense>
  );
};

export default Index;
