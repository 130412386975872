import { useDispatch, useSelector } from "react-redux";
import {
  updateBookingListData,
  updateBookingDetails,
  updateInvoiceDetails,
  updateDisplayRoomDrawer,
  updateRoomTotalDrawer,
  updateRoomSectionVisible,
} from "../../reducer/bookingReducer";
import { updateBookingListData as updateBookingReservationListData } from "../../reducer/bookingReservationReducer";
import moment from "moment";
import { message } from "antd";
import { updateinvoice } from "../../services/invoice";

const useEditBookingLogic = (
  setLoading,
  roomCountMap,
  childrens,
  isInitialRender,
  localRoomTotal,
  localDiscount,
  localDiscountInfo,
  localSubTotal,
  localTotal,
  totalTax
) => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);

  const {
    response,
    bookingDetails,
    roomPriceStructure,
    detailedPriceBreakup,
    selectedRoomWTypeAndNum,
    selectedCheckInDateTime,
    selectedCheckOutDateTime,
    nightsCount,
    mealPlan,
    invoiceDetails,
    taxIncludedInPriceLocalState,
    discount,
  } = useSelector((store) => store.booking);

  const roomTypes = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];
  const { taxResponse } = useSelector((store) => store.tax);

  const isValidate = () => {
    if (
      !Boolean(detailedPriceBreakup) &&
      !Array.isArray(detailedPriceBreakup)
    ) {
      message.info("Please select atleast one room");
      return false;
    }

    if (localTotal <= 0) {
      message.info("Something went wrong");
      return false;
    }

    return true;
  };

  // console.log(roomPriceStructure);

  const handleEditRoom = async () => {
    if (!isValidate) {
      return;
    }

    if (Boolean(bookingDetails)) {
      setLoading(true);
      let rooms = [];
      let noOfAdults = 0;

      let roomInvoice = Array.isArray(invoiceDetails)
        ? invoiceDetails?.find((Obj) => Obj?.invoiceType === "roomBill")
        : {};

      let addOnBreakup = Boolean(
        roomInvoice?.priceBreakup?.find((Obj) => Obj?.type === "Addons")
      )
        ? roomInvoice?.priceBreakup?.find((Obj) => Obj?.type === "Addons")
        : null;

      let newPriceBreakup = [];

      let requiredPriceBrekupForDiscount = [];

      if (discount !== 0) {
        requiredPriceBrekupForDiscount = detailedPriceBreakup
          ?.reduce((acc, room) => {
            const existingRoom = acc.find((item) => item.name === room.type);

            if (existingRoom) {
              existingRoom.rate += room.rate;
              existingRoom.count += 1;
            } else {
              acc.push({ name: room.type, rate: room.rate, count: 1 });
            }

            return acc;
          }, [])
          .map((obj) => ({
            name: obj.name,
            rate: obj.rate / obj.count, // Calculate average rate
            count: obj.count,
          }));
      }

      const priceBreakup = detailedPriceBreakup?.map((Obj) => {
        const roomType = roomTypes.find((rt) => rt.name === Obj?.type);
        const roomPrice =
          roomPriceStructure.find((rps) => rps.type === Obj?.type) || {};

        let currentRoomTaxPercentage = 0;

        let currentCmbinedPriceBrekup = requiredPriceBrekupForDiscount?.find(
          (currObj) => Obj?.type === currObj?.name
        );

        taxResponse
          ?.filter((Obj) => roomType?.taxIds?.includes(Obj?.id))
          ?.map((Obj) => Obj?.taxPercentage)
          ?.forEach((Obj) => (currentRoomTaxPercentage += +Obj));

        currentRoomTaxPercentage = currentRoomTaxPercentage
          ? currentRoomTaxPercentage / 100 + 1
          : 1;

        let rate = Boolean(taxIncludedInPriceLocalState)
          ? (Obj?.rate ?? roomPrice?.rate) / currentRoomTaxPercentage
          : Obj?.rate ?? roomPrice?.rate;

        let currentdiscount = (rate / localRoomTotal) * roomInvoice?.discount;

        let discountPerNight =
          currentdiscount / nightsCount / currentCmbinedPriceBrekup?.count;

        discountPerNight = Boolean(currentdiscount) ? currentdiscount : 0;

        let discountedRate = rate - discountPerNight ?? 0;
        noOfAdults += Obj?.noOfPeople || 0;

        console.log(rate);

        return {
          discountedRate: discountedRate ?? Obj?.rate,
          count: nightsCount,
          rate: rate || roomType?.rate || 0,
          type: Obj?.type,
          baseRate: roomPrice?.rate || roomType?.rate || 0,
          taxIds: roomPrice.taxIds || roomType?.taxIds || [],
        };
      });

      console.log(priceBreakup);

      for (let key in selectedRoomWTypeAndNum)
        selectedRoomWTypeAndNum[key]?.forEach((Obj) => rooms?.push(Obj?.id));

      newPriceBreakup = priceBreakup?.filter((item) =>
        Object?.keys(selectedRoomWTypeAndNum)?.includes(item.type)
      );

      if (newPriceBreakup?.length === 0) {
        message.info("Please Select atleast one Room");
        setLoading(false);
        return false;
      }

      newPriceBreakup = Boolean(addOnBreakup)
        ? [...newPriceBreakup, addOnBreakup]
        : newPriceBreakup;

      const newBookingDetails = JSON.parse(JSON.stringify(bookingDetails));
      newBookingDetails.checkIn = moment(selectedCheckInDateTime).valueOf();
      newBookingDetails.checkOut = moment(selectedCheckOutDateTime).valueOf();
      newBookingDetails.roomCount = roomCountMap;
      newBookingDetails.rooms = rooms;
      newBookingDetails.mealPlan = mealPlan;
      newBookingDetails.adults = noOfAdults;
      newBookingDetails.children = childrens;

      isInitialRender.current = true;
      await dispatch(updateBookingDetails(newBookingDetails));

      finalInvoiceCalculation(newPriceBreakup, newBookingDetails);
    } else {
      setLoading(false);
      message.info("Something Went wrong");
    }
  };

  const finalInvoiceCalculation = async (
    newPriceBreakup,
    newBookingDetails
  ) => {
    const newInvoiceDetails = JSON.parse(
      JSON.stringify(
        invoiceDetails?.find((Obj) => Obj?.invoiceType === "roomBill")
      )
    );

    newInvoiceDetails.totalTax = totalTax;

    newInvoiceDetails.discount = localDiscount;
    newInvoiceDetails.discountInfo = localDiscountInfo;

    newInvoiceDetails.subTotal = localSubTotal;
    newInvoiceDetails.total = localTotal;

    newInvoiceDetails.priceBreakup = newPriceBreakup;
    newInvoiceDetails.taxIncludedInPrice = Boolean(
      taxIncludedInPriceLocalState
    );

    const payload = {
      hotelId: newInvoiceDetails?.hotelId,
      id: newInvoiceDetails?.id,
      body: newInvoiceDetails,
    };

    const data = await updateinvoice(payload);

    if (Boolean(data?.id)) {
      const updatedData = invoiceDetails?.map((item) => {
        if (item.id === data.id) {
          return data;
        } else return item;
      });

      await dispatch(updateInvoiceDetails(updatedData));
      message.success("Date and Room change Updated");

      const newResponse = JSON.parse(JSON.stringify(response));

      const invoiceIndex = newResponse?.invoices?.findIndex(
        (Obj) => Obj.id === updatedData?.[0].id
      );
      const bookingIndex = newResponse?.bookings?.findIndex(
        (Obj) => Obj.id === newBookingDetails?.id
      );

      newResponse.invoices[invoiceIndex] = updatedData?.[0];
      newResponse.bookings[bookingIndex] = newBookingDetails;

      await dispatch(updateBookingListData(newResponse));
      await dispatch(updateBookingReservationListData(newResponse));
      dispatch(updateDisplayRoomDrawer());
      await dispatch(updateRoomTotalDrawer(false));
      await dispatch(updateRoomSectionVisible(false));
      setLoading(false);
      isInitialRender.current = true;
    } else message.error("Something Went Wrong");

    setLoading(false);
  };

  return { handleEditRoom };
};

export default useEditBookingLogic;
