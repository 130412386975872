import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getHotelContent } from "./loginReducer";

import {
  getBanksService,
  postBankService,
  putBankService,
  deleteBankService,
} from "../services/bank";

export const getBanks = createAsyncThunk(
  "getBanks",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "bank";
      const response = await getBanksService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const createBank = createAsyncThunk(
  "createBank",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "bank";
      const response = await postBankService(endpoint, payload);

      message.success("Bank has been successfully created.");

      dispatch(updateBankObject({}));
      dispatch(getHotelContent({ hotelId: getState().login.hotelDetails.id }));

      return fulfillWithValue(response);
    } catch (error) {
      message.error(
        "An error occurred while creating the bank: " + error.message
      );
      return rejectWithValue(error.message);
    }
  }
);

export const updateBank = createAsyncThunk(
  "updateBank",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "bank";
      const response = await putBankService(endpoint, payload);

      message.success("Bank has been successfully updated.");

      dispatch(updateBankObject({}));
      dispatch(getHotelContent({ hotelId: getState().login.hotelDetails.id }));

      return fulfillWithValue(response);
    } catch (error) {
      message.error(
        "An error occurred while updating the bank: " + error.message
      );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteBank = createAsyncThunk(
  "deleteBank",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "bank";
      const response = await deleteBankService(endpoint, payload);
      message.success("Bank has been successfully deleted.");
      dispatch(updateBankObject({}));
      dispatch(updateDisplayDeleteBankModal());
      return fulfillWithValue(payload?.id);
    } catch (error) {
      message.error(
        "An error occurred while deleting the bank: " + error.message
      );
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: true,
  response: [],
  apiError: false,

  // isShowAddBankOrCreditCard: false,
  // selectedRadio: "bank",
  // selectedBankingObject: {},

  // // attach party dropdown
  // attachPartyDropdownVisible: false,
  // attachedPartyObject: {},
  // selectedAttachPartyDropdownIndex: -1,

  // // add invoice dropdown
  // addInvoiceDropdownVisible: false,
  // selectedAddInvoiceDropdownIndex: -1,

  // // add expense dropdown
  // addExpenseDropdownVisible: false,
  // selectedAddExpenseDropdownIndex: -1,

  // // add/edit bank tnx drawer
  // showAddEditBankTxnDrawer: false,
  // bankTransactionObject: {},
  // showAddEditBankTxnCalendar: false,
  // partySearchString: "",

  // bank object
  bankObject: {},

  // banks list
  banksResponse: [],
  banksResponseLoading: false,
  banksResponseError: null,

  // create bank
  createBankLoading: false,
  createBankError: null,

  // update bank
  updateBankLoading: false,
  updateBankError: null,

  // delete bank
  deleteBankLoading: false,
  deleteBankError: null,
  displayDeleteBankModal: false,
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_11: () => initialState,

    updateDisplayDeleteBankModal: (state, action) => {
      state.displayDeleteBankModal = !state.displayDeleteBankModal;
    },

    updateBankObject: (state, action) => {
      state.bankObject = action.payload;
    },

    openAddBankOrCreditCard: (state, action) => {
      state.isShowAddBankOrCreditCard = action.payload;
    },
    closeAllBank: (state, action) => {
      state.isShowAddBankOrCreditCard = false;
    },

    updateSelectedRadio: (state, action) => {
      state.selectedRadio = action.payload;
    },
    updateSelectedBankingObject: (state, action) => {
      state.selectedBankingObject = action.payload;
    },

    // attach party dropdown
    updateAttachPartyDropdownVisible: (state, action) => {
      state.attachPartyDropdownVisible = action.payload;
    },
    updateAttachedPartyObject: (state, action) => {
      state.attachedPartyObject =
        state.attachedPartyObject.id === action.payload.id
          ? {}
          : action.payload;
    },
    updateSelectedAttachPartyDropdownIndex: (state, action) => {
      state.selectedAttachPartyDropdownIndex = action.payload;
    },

    // add invoice dopdown
    updateAddInvoiceDropdownVisible: (state, action) => {
      state.addInvoiceDropdownVisible = action.payload;
    },
    updateSelectedAddInvoiceDropdownIndex: (state, action) => {
      state.selectedAddInvoiceDropdownIndex = action.payload;
    },

    // add expense dopdown
    updateAddExpenseDropdownVisible: (state, action) => {
      state.addExpenseDropdownVisible = action.payload;
    },
    updateSelectedAddExpenseDropdownIndex: (state, action) => {
      state.selectedAddExpenseDropdownIndex = action.payload;
    },

    // add/edit bank tnx drawer
    updateShowAddEditBankTxnDrawer: (state, action) => {
      state.showAddEditBankTxnDrawer = action.payload;
    },
    updateBankTransactionObject: (state, action) => {
      state.bankTransactionObject = action.payload;
    },
    updateShowAddEditBankTxnCalendar: (state, action) => {
      state.showAddEditBankTxnCalendar = action.payload;
    },
    updatePartySearchString: (state, action) => {
      state.partySearchString = action.payload;
    },
    addPayment: (state, action) => {
      state.bankTransactionObject = {
        ...state.bankTransactionObject,
        txnNumAndAmt: [
          ...state.bankTransactionObject.txnNumAndAmt,
          {
            variable: "",
            amount: "",
          },
        ],
      };
    },
    removePayment: (state, action) => {
      const updatedTxnNumAndAmt =
        state.bankTransactionObject.txnNumAndAmt?.filter(
          (_, idx) => idx !== action.payload
        );
      state.bankTransactionObject = {
        ...state.bankTransactionObject,
        txnNumAndAmt: updatedTxnNumAndAmt,
      };
    },
  },
  extraReducers: (builder) => {
    // get banks
    builder.addCase(getBanks.pending, (state, action) => {
      state.banksResponseLoading = true;
    });
    builder.addCase(getBanks.fulfilled, (state, action) => {
      state.banksResponseLoading = false;
      state.banksResponse = action.payload.data;
    });
    builder.addCase(getBanks.rejected, (state, action) => {
      state.banksResponseLoading = false;
      state.banksResponseError = action.payload;
    });

    // create bank
    builder.addCase(createBank.pending, (state, action) => {
      state.createBankLoading = true;
    });
    builder.addCase(createBank.fulfilled, (state, action) => {
      state.createBankLoading = false;
      state.banksResponse = [action.payload.data, ...state.banksResponse];
    });
    builder.addCase(createBank.rejected, (state, action) => {
      state.createBankLoading = false;
      state.createBankError = action.payload;
    });

    // update bank
    builder.addCase(updateBank.pending, (state, action) => {
      state.updateBankLoading = true;
    });
    builder.addCase(updateBank.fulfilled, (state, action) => {
      state.updateBankLoading = false;
      state.banksResponse = state.banksResponse?.map((bank) =>
        bank.id === action.payload.data.id
          ? action.payload.data
          : {
              ...bank,
              defaultBankName: action.payload.data.defaultBankName
                ? false
                : true,
            }
      );
    });
    builder.addCase(updateBank.rejected, (state, action) => {
      state.updateBankLoading = false;
      state.updateBankError = action.payload;
    });

    // delete bank
    builder.addCase(deleteBank.pending, (state, action) => {
      state.deleteBankLoading = true;
    });
    builder.addCase(deleteBank.fulfilled, (state, action) => {
      state.deleteBankLoading = false;
      state.banksResponse = state.banksResponse?.filter(
        (bank) => bank.id !== action.payload
      );
    });
    builder.addCase(deleteBank.rejected, (state, action) => {
      state.deleteBankLoading = false;
      state.deleteBankError = action.payload;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_11,
  openAddBankOrCreditCard,
  closeAllBank,
  updateSelectedRadio,
  updateSelectedBankingObject,
  updateAttachPartyDropdownVisible,
  updateAttachedPartyObject,
  updateSelectedAttachPartyDropdownIndex,
  updateAddInvoiceDropdownVisible,
  updateSelectedAddInvoiceDropdownIndex,
  updateAddExpenseDropdownVisible,
  updateSelectedAddExpenseDropdownIndex,
  updateShowAddEditBankTxnDrawer,
  updateBankTransactionObject,
  updateShowAddEditBankTxnCalendar,
  updatePartySearchString,
  addPayment,
  removePayment,
  updateBankObject,
  updateDisplayDeleteBankModal,
} = bankSlice.actions;

export default bankSlice.reducer;
