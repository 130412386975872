import React, { useContext, useEffect, useRef, useState } from "react";
// import { ArrowDownDropdown } from "../../assets/Icons";
import { Button } from "antd";
import {
  clearSelectedItem,
  updateAction,
  updateSelectedItem,
} from "../../../reducer/getItemReducer";
// import { WalkinRupee } from "../../../walkin/assets/Icons";
import { useDispatch, useSelector } from "react-redux";
// import { getAllItems } from "../../../../reducer/getItemReducer";
// import { SelectedItemContext } from "../../Index";
import { Select, Option } from "@material-tailwind/react";
import { event } from "jquery";
import SelectInput from "./SelectInput";

import { Input } from "./Input";
import ShimmerLoader from "../../common/ShimmerLoader";
import { GET_ITEM_PRICE, ITEM_STATUS } from "../../../utils/posHelper";
import { CURRENCY_FORMAT } from "../../../utils/helper";
import { TranslateText } from "../../../utils/translation";

function Items({ itemslist, loading, error }) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchCat, setSearchCat] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const { selectedItem } = useSelector((store) => store.getItems);

  const handleChange = (event) => {
    setSelectedValue(event);
  };

  const handleCategory = (event) => {
    setSearchCat(event);
  };

  const scrollableDivRef = useRef(null);

  const { response, activePosSlug, activePosType } = useSelector(
    (store) => store.posSetting
  );

  const { tableOrder } = useSelector((store) => store.pos);

  const categories = response?.filter(
    (item) => item.slug === activePosSlug
  )?.[0]?.itemCategories;

  // const itemslist = useSelector((state) => {
  //   return state.getItems.data;
  // });
  const [touched, setTouched] = useState({ id: 0, status: false });
  //   const { setSelectedItem, setSelectedPreview } =
  //     useContext(SelectedItemContext);

  const handleClick = (item) => {
    if (Boolean(selectedItem?.id)) {
      if (selectedItem?.id === item.id) {
        dispatch(clearSelectedItem());
        dispatch(updateAction(""));
      } else {
        dispatch(updateSelectedItem(item));
      }
    } else {
      dispatch(updateAction("edit"));
      dispatch(updateSelectedItem(item));
    }
  };

  let FilterCat = Boolean(searchCat)
    ? itemslist?.filter((item) => {
        return item.category === searchCat;
      })
    : itemslist;

  let isItemActive = Boolean(searchStatus)
    ? searchStatus === ITEM_STATUS[0]
      ? true
      : false
    : false;

  let FilterItemStatus = Boolean(searchStatus)
    ? FilterCat?.filter((item) => {
        return item.active === isItemActive;
      })
    : FilterCat;

  const filterItems = Boolean(searchText)
    ? FilterItemStatus?.filter((item) => {
        return item?.name?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : FilterItemStatus;

  let isClearFilter =
    Boolean(searchText) || Boolean(searchCat) || Boolean(searchStatus)
      ? true
      : false;

  let onClear = () => {
    setSearchText("");
    setSearchCat("");
    setSearchStatus("");
  };

  return (
    <div className="w-full flex flex-col ">
      <div className=" p-3 items-center m-1">
        <div className="lg:flex justify-between">
          <div className="w-full lg:w-72 mr-3 mb-10">
            <Input
              className="h-2"
              label={"Search by item name"}
              value={Boolean(searchText) ? searchText : ""}
              handleChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div className="flex mt-4">
            <div className="w-48 mr-3 ">
              <SelectInput
                label={"Filter By Category"}
                value={Boolean(searchCat) ? searchCat : []}
                multiple={false}
                menuItem={categories}
                name="category"
                handleChange={(e) => handleCategory(e.target.value)}
              />
            </div>
            <div className="w-48">
              <SelectInput
                label={"Filter By Status"}
                value={Boolean(searchStatus) ? searchStatus : []}
                multiple={false}
                menuItem={ITEM_STATUS}
                name="status"
                handleChange={(e) => setSearchStatus(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {isClearFilter && (
        <p
          onClick={() => onClear()}
          className="text-primary text-right mr-5 cursor-pointer"
        >
          
          <TranslateText textKey={"clear_filter"} namespace="pos" />

        </p>
      )}

      <div
        ref={scrollableDivRef}
        className="relative max-h-screen overflow-y-auto mb-10"
      >
        {loading ? (
          <div className="px-4">
            {[...Array(15)].map(() => {
              return <ShimmerLoader className="h-10 mb-2" />;
            })}
          </div>
        ) : error ? (
          <div className="text-center py-5">
            <p className="text-red-600">
            <TranslateText textKey={"something_went_wrong"} namespace="pos" />

            </p>
          </div>
        ) : filterItems?.length > 0 ? (
          <ul className="w-full p-3 mb-[10rem]">
            {filterItems?.map((item, key) => {
              return (
                <li
                  key={item.id}
                  className={`${
                    selectedItem?.id === item.id
                      ? "bg-[#F4F4F4] text-black"
                      : ""
                  }  flex justify-between items-center p-3 border m-2 rounded shadow cursor-pointer ${
                    touched.id === item._id && touched.status
                      ? "bg-[#f7f7f7]"
                      : ""
                  }`}
                  onClick={() => handleClick(item)}
                >
                  <p>
                    <span className="mr-2"> {key + 1}.</span>
                    <span>{item.name}</span>
                  </p>

                  <span className="flex items-center">
                    {/* <WalkinRupee /> */}
                    {CURRENCY_FORMAT(
                      GET_ITEM_PRICE(tableOrder.type, activePosType, item)
                    )}
                  </span>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="text-center py-5 shadow-md rounded">
            <p className="text-2xl">
              
              <TranslateText textKey={"this_is_no_item_found"} namespace="pos" />

              </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Items;
