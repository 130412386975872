import React from "react";

const NavigatingButtons = ({ currentId, incrementId, decrementId }) => {
  return (
    <div className="w-[100%] bg-[#FFF] h-[7rem] fixed flex items-center  justify-end left-0 bottom-0 right-0 py-5  shadow-custom">
      <div className="flex gap-8 mr-[4%]">
        {currentId !== 1 && (
          <button
            type="submit"
            onClick={decrementId}
            className="w-[15rem] h-[3.5rem] flex justify-center items-center  text-[#007FFF] font-[600] rounded-[0.5rem] shadow-custom uppercase text-[1.125rem] cursor-pointer"
          >
            back
          </button>
        )}

        <button
          type="submit"
          onClick={incrementId}
          className="bg-[#007FFF] w-[15rem] h-[3.5rem] flex justify-center items-center  shadow-custom rounded-[0.5rem] font-[600] font-[Poppins] text-[1.125rem] uppercase text-[#FFF] cursor-pointer"
        >
          continue
        </button>
      </div>
    </div>
  );
};

export default NavigatingButtons;
