import React from "react";
import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateShowRefundPaymentDrawer } from "../../../reducer/bookingReducer";
import { useEffect } from "react";
import CustomTextField from "../../common/InputField/Input";
import useAllBookingFunction from "../../../hooks/bookingCustomHook/useAllBookingFunction";
import { IoIosCloseCircle } from "react-icons/io";

export default function RefundDrawer() {
  const { showRefundDrawer } = useSelector((store) => store.booking);
  const { paymentModesResponse } = useSelector((store) => store.payments);

  const dispatch = useDispatch();

  const [amount, setAmount] = React.useState(Boolean);
  const [paymentMode, setPaymentMode] = React.useState("cash");
  const [reason, setReason] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [methodToPartyId, setMethodToPartyId] = useState({});

  useEffect(() => {
    if (Array.isArray(paymentModesResponse) && paymentModesResponse?.length) {
      let updatedObj = {};
      paymentModesResponse?.forEach((mode) => {
        const { method, partyId } = mode;
        updatedObj[method] = partyId;
      });
      setMethodToPartyId(updatedObj);
    }
  }, [paymentModesResponse]);

  const { refund } = useAllBookingFunction(setLoading);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "800px",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",
          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",
          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={showRefundDrawer}
      onClose={() => {
        setAmount("");
        setReason("");

        setLoading(false);
        dispatch(updateShowRefundPaymentDrawer());
      }}
    >
      <div className="m-0 p-0  ">
        <div className="flex w-[100%] justify-between items-center px-3 py-3 ">
          <h2 className="font-[600] text-[1rem] text-[#000] font-outfit">
            Refund
          </h2>

          <div
            onClick={() => {
              setAmount("");
              setReason("");
              setLoading(false);
              dispatch(updateShowRefundPaymentDrawer());
            }}
            className="cursor-pointer"
          >
            <IoIosCloseCircle />
          </div>
        </div>

        <div className=" flex w-[100%] p-4  gap-4 justify-start border-t-[10px] border-t-[#EDF1F7]">
          <div className="w-[50%]">
            <CustomTextField
              label="Refund Amount"
              isManadatory={true}
              value={amount}
              onChange={(value) => setAmount(value)}
              type="text"
              inputPadding={true}
              inputPaddingValue={"3px"}
              labelTextColor={"#8F9BB3"}
            />
          </div>

          <div className="w-[50%]">
            <CustomTextField
              label="Reason for Refund"
              isManadatory={false}
              value={reason}
              onChange={(value) => setReason(value)}
              type="text"
              inputPadding={true}
              inputPaddingValue={"3px"}
              labelTextColor={"#8F9BB3"}
            />
          </div>
        </div>

        <div className="p-4 pb-[6rem]">
          <FormControl sx={{ width: "49%", color: "#0000004D" }} size="small">
            <InputLabel
              id="paymentMode"
              sx={{ color: "#0000004D", fontSize: "15px" }}
            >
              Payment Mode
            </InputLabel>

            <Select
              labelId="SelectPaymentLabel"
              id="selectPayment"
              label="Payment Mode "
              value={Boolean(paymentMode) ? paymentMode : ""}
              onChange={(e) => setPaymentMode(e.target.value)}
              sx={{
                borderRadius: "6px",
                fontSize: "15px",
                "& .MuiSelect-icon": {
                  color: "#2F80ED",
                },
              }}
            >
              {paymentModesResponse?.map((mode) => {
                const { method, partyId, paymentMode } = mode;
                return (
                  <MenuItem
                    value={method}
                    sx={{
                      fontSize: "14px",
                      color: "#00000098",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                    }}
                  >
                    {paymentMode}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div
          className={`bg-[#FFF] flex justify-end items-center p-2 py-3 absolute border-t-[2px] border-t-[#EDF1F7] bottom-0  w-full`}
          onClick={() =>
            refund(
              amount,
              setAmount,
              setReason,
              reason,
              paymentMode,
              methodToPartyId
            )
          }
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <div className=" font-[500] cursor-pointer py-[12px] px-[24px] rounded-[8px] font-outfit  text-[#FFF] bg-[#007FFF]  text-[18px] leading-[18px] ">
              Initiate Refund
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
}
