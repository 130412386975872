import axios from "axios";
import { getServerProps } from "./server";

let authedAxios = (token = null, userApiKey = null) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")} `;

  axios.defaults.headers.common["user-api-key"] = userApiKey;

  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${
      token !== null ? token : `${localStorage.getItem("token")}`
    } `;

    return config;
  });

  return axios.create({
    baseURL: userApiKey
      ? getServerProps().channex_server
      : getServerProps().base_url,
  });
};
export { authedAxios };
