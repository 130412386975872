import * as React from "react";
import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateShowApplyDiscountDrawer } from "../../../reducer/bookingReducer";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DiscountButton from "../components/DiscountButton";
import CustomTextField from "../../common/InputField/Input";
import useAllBookingFunction from "../../../hooks/bookingCustomHook/useAllBookingFunction";

export default function ApplyDiscountDrawer() {
  const [discountType, setDiscountType] = useState(null);
  const [discountValue, setDiscountValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  const { showApplyDiscountDrawer, invoiceDetails } = useSelector(
    (store) => store.booking
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!showApplyDiscountDrawer || !Array.isArray(invoiceDetails)) return;

    let roomInvoice = invoiceDetails?.find(
      (Obj) => Obj?.invoiceType === "roomBill"
    );

    let currentDiscountType = roomInvoice?.discountInfo?.type || "percentage";
    let currentDiscountValue = roomInvoice?.discountInfo?.value || 0;

    setDiscountType(currentDiscountType);
    setDiscountValue(currentDiscountValue);
  }, [invoiceDetails, showApplyDiscountDrawer]);

  useEffect(() => {
    if (!showApplyDiscountDrawer || !Array.isArray(invoiceDetails)) return;

    if (initialRender) {
      setInitialRender(false);
      return;
    }

    let roomInvoice = invoiceDetails?.find(
      (Obj) => Obj?.invoiceType === "roomBill"
    );

    let taxIncludedInPrice = roomInvoice?.taxIncludedInPrice;
    let discount = Boolean(roomInvoice?.discount) ? roomInvoice?.discount : 0;

    let roomTotal = Boolean(taxIncludedInPrice)
      ? roomInvoice?.total + discount
      : roomInvoice?.subTotal + discount;

    if (Boolean(discountValue)) {
      let value = "";

      if (discountType === "flat") value = (roomTotal * discountValue) / 100;
      else value = (discountValue * 100) / roomTotal;

      setDiscountValue(value);
    }
  }, [discountType]);

  const handleDiscountTypeChange = (e) => setDiscountType(e);
  const handleDiscountValue = (value) => {
    const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numeric values
    const trimmedValue = numericValue.replace(/^0+/, "");

    if (discountType === "percentage") {
      if (trimmedValue < 0) setDiscountValue(+0);
      else if (trimmedValue > 100) setDiscountValue(+100);
      else setDiscountValue(+trimmedValue);
    } else setDiscountValue(+trimmedValue);
  };

  const { updateInvoicePricesAsDiscount } = useAllBookingFunction(setLoading);

  const handleClose = () => {
    setInitialRender(true);
    setDiscountType(null);
    setDiscountValue(null);
    dispatch(updateShowApplyDiscountDrawer());
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "800px",
          height: "30%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={showApplyDiscountDrawer}
      onClose={loading ? undefined : handleClose}
    >
      <div className="flex flex-col mx-3 gap-5 items-start my-3 mb-6 ">
        <div className="flex w-[100%] justify-between items-center ">
          <h2 className="font-[500] text-[1rem] text-[#4D4D4D] font-nunito">
            Select Discount Type
          </h2>

          <div className="flex  gap-5 items-center">
            <DiscountButton
              discountType={discountType}
              handleDiscountTypeChange={handleDiscountTypeChange}
            />
            <IconButton
              onClick={
                loading
                  ? undefined
                  : () => dispatch(updateShowApplyDiscountDrawer())
              }
              sx={{ borderRadius: "5px", border: "1px solid #A7A7A7" }}
            >
              <CloseIcon sx={{ fontSize: "medium", color: "#A7A7A7" }} />
            </IconButton>
          </div>
        </div>

        <div className="w-[100%] mb-2 mt-2 flex items-center justify-center">
          <div className="w-[90%]">
            <CustomTextField
              label={
                discountType === "flat"
                  ? "Enter Amount"
                  : "Enter Discount Percentage"
              }
              isManadatory={false}
              value={Boolean(discountValue) ? discountValue?.toFixed(0) : "0"}
              onChange={(value) => handleDiscountValue(value)}
              type="number"
            />
          </div>
        </div>
      </div>

      {loading ? (
        <button
          className="bg-[#007FFF] text-[#FFF] w-full h-[3rem] uppercase absolute bottom-0"
          disabled={true}
        >
          <CircularProgress size={16} color="inherit" />
        </button>
      ) : (
        <button
          className="bg-[#007FFF] text-[#FFF] w-full h-[3rem] uppercase absolute bottom-0"
          onClick={() =>
            updateInvoicePricesAsDiscount(
              discountType,
              discountValue,
              setInitialRender
            )
          }
        >
          Apply Discount
        </button>
      )}
    </Drawer>
  );
}
