import React from "react";
import { useTranslation } from "react-i18next";

const TranslateText = ({ textKey, namespace, values = {}, className }) => {
  const { t } = useTranslation(namespace || undefined);

  return <span className={`${className} mr-1`}>{t(textKey, values)}</span>;
};

export { TranslateText };
