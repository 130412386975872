import ManageStocksWrapper from "../wrapper/Index";
import { Button, Dropdown, List, Space, message } from "antd";
import { SortAlphaUp, SortUp } from "../../../Icon1";
import { DownOutlined } from "@ant-design/icons";
import SupplierDetailListItem from "./supplierDetailListItem/Index";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import NameInitial from "./nameInitials/Index";
import AddEditSupplierDetails from "./addEditSupplier/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSupplier,
  getAllSuppliers,
} from "../../../../reducer/manageStocksReducer/suppliersReducer";

const items = [
  {
    label: "1st menu item",
    key: "0",
  },
  {
    label: "2nd menu item",
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

const MainContainer = ({
  selected,
  setSelected,
  allSuppliersData,
  setSuppliersSorted,
}) => {
  const handleSortAplha = () => {
    const sortedData = allSuppliersData.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
    message.success("Sorted in ascending order by name..");
    setSuppliersSorted([...sortedData]);
  };

  return (
    <div className="px-6">
      <div className="flex flex-row p-4 justify-between">
        <div className="flex gap-2">
          <SortUp />
          <div onClick={() => handleSortAplha()} className="cursor-pointer">
            <SortAlphaUp />
          </div>
        </div>
        <div>
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            className="border rounded-md p-2"
          >
            <Link onClick={(e) => e.preventDefault()}>
              <Space className="text-[12px]">
                Recently Added
                <DownOutlined />
              </Space>
            </Link>
          </Dropdown>
        </div>
      </div>
      <div>
        <List
          dataSource={allSuppliersData}
          pagination={{ align: "center", pageSize: 9 }}
          renderItem={(item, index) => (
            <div
              className="items-center gap-2"
              onClick={() =>
                setSelected(allSuppliersData.filter((el) => el.id === item.id))
              }
            >
              <List.Item>
                <List.Item.Meta
                  avatar={<NameInitial itemObj={item} />}
                  title={<p className="font-semibold pt-1">{item.name}</p>}
                  description={item.phone}
                  className={`${
                    selected[0]?.id === item.id
                      ? "bg-[#0000000D] shadow-custom-stocks"
                      : "shadow-custom-stocks"
                  }`}
                />
              </List.Item>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const SideContainer = ({ selected, setEdit, handleDeleteSupplier }) => {
  const detailsData = [
    ["Suppliers's Name", selected[0]?.name],
    ["Address", selected[0]?.addressOne, selected[0]?.addressTwo],
    ["Phone No.", selected[0]?.phone],
    ["Email Address", selected[0]?.email],
    ["Primary Contact", selected[0]?.primaryContact],
    ["PAN Number", selected[0]?.panNumber],
  ];

  return (
    <section className="flex flex-col gap-6 justify-center h-full">
      <div className="h-full justify-between">
        <div>
          <Space>
            <p className="text-[24px]">Supplier Details</p>
          </Space>
        </div>
        <div className="pt-7">
          <List
            itemLayout="horizontal"
            dataSource={detailsData}
            renderItem={(item, index) => (
              <List.Item>
                <SupplierDetailListItem
                  title={item[0]}
                  value={
                    item[0] === "Address" ? item[1] + ", " + item[2] : item[1]
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <div className="flex gap-6">
          <Button
            type="secondary"
            className="bg-[#FFFFFF] text-[18px] text-[#007FFF] font-semibold w-[310px] h-[56px] shadow-custom-stocks"
            onClick={() => handleDeleteSupplier(selected)}
          >
            DELETE
          </Button>
          <Button
            type="primary"
            className="bg-[#007FFF] text-[18px] font-semibold w-[310px] h-[56px] drop-shadow-[9px_5px_10px_#c2c1c1]"
            onClick={() => setEdit(true)}
          >
            EDIT
          </Button>
        </div>
      </div>
    </section>
  );
};

const SuppliersMain = () => {
  const dispatch = useDispatch();
  const allSuppliersData = useSelector((state) => state.suppliers.data);

  const [suppliersSorted, setSuppliersSorted] = useState([]);
  const [selected, setSelected] = useState({});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setSuppliersSorted([...allSuppliersData]);
  }, [allSuppliersData]);

  const handleDeleteSupplier = async (selectedSupplier) => {
    await dispatch(deleteSupplier(selectedSupplier[0]?.id)).then(
      dispatch(getAllSuppliers())
    );
    setSuppliersSorted((prevSuppliersData) =>
      prevSuppliersData.filter(
        (supplier) => supplier.id !== selectedSupplier[0]?.id
      )
    );
  };

  return (
    <ManageStocksWrapper
      headerName="Suppliers"
      // previewTitle="Supplier Details"
      leftSideContent={
        <MainContainer
          setSelected={setSelected}
          selected={selected}
          allSuppliersData={suppliersSorted}
          setSuppliersSorted={setSuppliersSorted}
        />
      }
      rightSideContent={
        edit ? (
          <AddEditSupplierDetails
            data={selected}
            setData={setSelected}
            setEdit={setEdit}
          />
        ) : (
          <SideContainer
            selected={selected}
            setEdit={setEdit}
            handleDeleteSupplier={handleDeleteSupplier}
          />
        )
      }
    ></ManageStocksWrapper>
  );
};

export default SuppliersMain;
