import { Button, message, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "../InputField/Input";
import { postTax } from "../../../reducer/taxReducer";
import { updateDisplayEditRoomTypeCustomTaxModal } from "../../../reducer/settingsReducer";
import { FaAngleDown } from "react-icons/fa";

const CustomTaxModal = ({
  data,
  handleSaveChanges,
  setAllInputData,
  isAddNewTax,
  mainIndex,
  setOriginalData,
  setFilteredData,
  isBookingTaxModal,
  isDispatchUsedInsteadOfLoaclState = false,
}) => {
  const { taxResponse } = useSelector((store) => store.tax);
  const dispatch = useDispatch();
  const { hotelDetails } = useSelector((store) => store.login);

  const { displayEditRoomTypeCustomTaxModal } = useSelector(
    (store) => store.settings
  );

  const [requiredTaxResponseList, setRequiredTaxResponseList] = useState([]);
  const [currentTaxIds, setCurrentTaxIds] = useState("");
  const [newTax, setNewTax] = useState({ name: "", rate: 0 });

  useEffect(() => {
    let currentTaxIds = Array.isArray(data?.taxIds) ? data.taxIds : [];

    let requiredList = taxResponse?.map((Obj) => {
      let isTaxPresent = currentTaxIds.includes(Obj?.id);
      return {
        ...Obj,
        isPresent: isTaxPresent,
      };
    });

    setRequiredTaxResponseList(requiredList);
  }, [data?.taxIds, taxResponse]);

  const handleTaxChange = (value, name) => {
    let newList = requiredTaxResponseList?.map((Obj) =>
      Obj?.taxName?.toUpperCase() === name?.toUpperCase()
        ? { ...Obj, isPresent: !Obj?.isPresent }
        : Obj
    );

    setRequiredTaxResponseList(newList);
  };

  const handleNewTax = async () => {
    message.destroy();

    if (!newTax?.name) {
      message.warning("Please enter tax name");
      return false;
    }

    if (!newTax?.rate) {
      message.warning("Please enter tax rate");
      return false;
    }

    const taxExists = taxResponse?.some(
      (obj) => obj?.taxName?.toUpperCase() === newTax?.name?.toUpperCase()
    );

    if (taxExists) {
      message.warning("This tax already exists");
      return false;
    }

    if (!hotelDetails?.id) {
      message.warning("Something went wrong");
      return false;
    }

    const payload = {
      taxName: newTax?.name,
      taxPercentage: newTax?.rate,
      hotelId: hotelDetails?.id,
    };

    try {
      let response = await dispatch(postTax(payload));

      if (response?.payload?.id) {
        setNewTax({ name: "", rate: "" });
        message.success("New tax added");
      } else message.info("Something went wrong");
    } catch (error) {
      message.error("An error occurred while adding the tax");
    }
  };

  const handleChanges = (e) => {
    const selectedTaxResponse = requiredTaxResponseList
      ?.filter((obj) => Boolean(obj?.isPresent))
      ?.map((obj) => obj?.id);

    const newInputData = { ...data, taxIds: selectedTaxResponse };

    isDispatchUsedInsteadOfLoaclState
      ? dispatch(setAllInputData(newInputData))
      : setAllInputData(newInputData);
    handleSaveChanges(e, true, newInputData);
    dispatch(updateDisplayEditRoomTypeCustomTaxModal(false));
    e.stopPropagation();
  };

  const handleBookingAddOnsChanges = (e) => {
    const selectedTaxResponse = requiredTaxResponseList
      .filter((obj) => obj?.isPresent)
      .map((obj) => obj?.id);

    const updateTaxIds = (arr) =>
      arr.map((Obj) =>
        Obj?.uniqueIdx === mainIndex
          ? { ...Obj, taxIds: selectedTaxResponse }
          : Obj
      );

    setOriginalData((prev) => updateTaxIds(prev));
    setFilteredData((prev) => updateTaxIds(prev));

    dispatch(updateDisplayEditRoomTypeCustomTaxModal(false));
    e.stopPropagation();
  };

  useEffect(() => {
    let taxLabels = "";
    let count = 0;

    for (let i = 0; i < taxResponse.length; i++) {
      const currentTaxResp = taxResponse[i];

      if (
        Array.isArray(data?.taxIds) &&
        data?.taxIds.includes(currentTaxResp.id)
      ) {
        if (count < 2) {
          taxLabels += `${count > 0 ? ", " : ""}${currentTaxResp.taxName} (${
            currentTaxResp.taxPercentage
          }%)`;
        }
        count++;

        if (count === 3 && i < taxResponse.length - 1) {
          taxLabels += ", ...";
          break;
        }
      }
    }

    setCurrentTaxIds(taxLabels);
  }, [data?.taxIds]);

  return (
    <div
      className="relative w-[100%] "
      onClick={(e) => {
        dispatch(
          updateDisplayEditRoomTypeCustomTaxModal(data?.uniqueIdx || true)
        );
        e.stopPropagation();
      }}
    >
      <CustomTextField
        label="Select/Add Tax"
        isManadatory={true}
        value={currentTaxIds}
        onChange={() => {}}
        isInputAdornmentAllowed={true}
        Icon={FaAngleDown}
        type="text"
      />

      {(
        isBookingTaxModal
          ? displayEditRoomTypeCustomTaxModal === data?.uniqueIdx
          : displayEditRoomTypeCustomTaxModal
      ) ? (
        <div className="absolute  top-[3rem] shadow-xl  px-2  z-[1000] left-0 right-0 h-[314px] rounded-[8px] bg-[#FFF]">
          <div className=" absolute top-[0px] left-0 right-[5px] h-[60%]  flex flex-col overflow-y-auto">
            {requiredTaxResponseList?.map((Obj) => {
              return (
                <div className="w-[100%] flex justify-between items-center p-2 px-5">
                  <div className="flex gap-2 items-center">
                    <div>
                      <Checkbox
                        checked={Obj?.isPresent}
                        onChange={(value) =>
                          handleTaxChange(value, Obj?.taxName)
                        }
                      />
                    </div>
                    <div className="font-outfit font-[400] text-[14px] leading-[18px] text-[#8F9BB3]">
                      {Obj?.taxName}
                    </div>
                  </div>

                  <div className="font-outfit font-[400] text-[14px] leading-[18px] text-[#8F9BB3]">
                    {Obj?.taxPercentage}%
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex flex-col gap-2 absolute bottom-2 p-2 left-0 right-0">
            {isAddNewTax ? (
              <div className="flex gap-3 items-center">
                <CustomTextField
                  label="Tax Name"
                  value={newTax?.name}
                  onChange={(value) => {
                    setNewTax((prev) => ({
                      ...prev,
                      name: value,
                    }));
                  }}
                  className="w-[20%]"
                  isInputAdornmentAllowed={false}
                  type="text"
                />

                <CustomTextField
                  label="Tax Value"
                  value={newTax?.rate}
                  onChange={(value) => {
                    setNewTax((prev) => ({
                      ...prev,
                      rate: +value,
                    }));
                  }}
                  isInputAdornmentAllowed={false}
                  type="number"
                />

                <div
                  className="w-[50px] bg-[#EDF1F7] border-[1px] border-[#EDF1F7] 
          text-[#007FFF] font-outfit cursor-pointer font-[400] px-3 py-[6px] text-[14px] leading-[20px] tracking-wider rounded-[6px] flex justify-center items-center"
                  onClick={() => handleNewTax()}
                >
                  Add
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="w-[100%] flex justify-center items-center">
              <Button
                className="w-[90%] bg-[#007AFF]  font-outfit font-[500] text-[#FFF] rounded-[6px] "
                onClick={
                  isBookingTaxModal ? handleBookingAddOnsChanges : handleChanges
                }
              >
                {" "}
                Update
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomTaxModal;
