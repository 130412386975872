import React, { useEffect, useState } from "react";
import ManageStocksWrapper from "../wrapper/Index";
import { decodeTimestamp, latestDayFormatted } from "../../../../utils/dates";
import { useSelector } from "react-redux";
import { Button, List, Table } from "antd";
import { Outlet, useNavigate } from "react-router-dom";

const MainContainer = ({ onRecountClick }) => {
  const navigate = useNavigate();
  const recounts = useSelector((state) => state.recount.data); // use when data is found else use mockdata
  const [recountsData, setRecountsData] = useState(recounts); //currently using mock data;
  const [selectedRecount, setSelectedRecount] = useState(null);

  useEffect(() => {
    setRecountsData(recounts);
  }, [recounts]);

  const handleStartRecountClick = () => {
    navigate("start-recount");
  };
  const handleItemClick = (recount) => {
    setSelectedRecount(recount.id);
    onRecountClick(recount);
  };

  return (
    <section className="h-full px-4 flex flex-col gap-3">
      <List
        dataSource={recountsData}
        size="large"
        bordered={false}
        className="shadow-custom-stocks"
        pagination={{ position: "bottom", align: "center" }}
        renderItem={(recount, index) => (
          <List.Item
            key={recount.id}
            className={`${
              selectedRecount && selectedRecount === recount.id
                ? "bg-[#F4F4F4] rounded "
                : "bg-[ffffff]"
            } mb-2 shadow-md`}
            onClick={() => handleItemClick(recount)}
          >
            <div className="text-[16px] font-normal">
              {decodeTimestamp(recount.createdAt)}
            </div>
            <div className="text-[#4F4F4F] text-[14px] font-normal">
              {recount?.recounts?.length} <span>Items recounted</span>
            </div>
          </List.Item>
        )}
      />
      <div className="w-full flex justify-end py-4">
        <Button
          onClick={handleStartRecountClick}
          type="primary"
          className="bg-[#007FFF] text-[18px] font-semibold w-[310px] h-[56px]"
        >
          START RECOUNT
        </Button>
      </div>
    </section>
  );
};

const SideContainer = ({ selectedRecount }) => {
  const columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Updated Count",
      dataIndex: "updatedCount",
      key: "updatedCount",
    },
    {
      title: "Previous Count",
      dataIndex: "previousCount",
      key: "previousCount",
    },
    {
      title: "Difference",
      dataIndex: "differenceCount",
      key: "differenceCount",
    },
  ];
  return (
    <section className="flex h-full">
      {selectedRecount && (
        <div className="flex-grow">
          <Table
            className="py-4"
            pagination={false}
            columns={columns}
            dataSource={selectedRecount?.recounts}
            size="small"
            rowKey={(selectedRecount) => `${selectedRecount.id}`}
          />
          <div className="w-full bg-[#ffffff] p-2 flex gap-1 text-[#4F4F4F] text-[14px] font-nunito font-normal">
            <i>{selectedRecount?.recounts?.length} </i>
            <span>
              <i>Items Recounted</i>
            </span>
          </div>
        </div>
      )}
    </section>
  );
};

function RecountItems() {
  const [selectedRecount, setSelectedRecount] = useState(null);

  const handleItemClick = (item) => {
    setSelectedRecount(item);
  };

  return (
    <>
      <ManageStocksWrapper
        headerName="Recount Items"
        previewTitle={latestDayFormatted}
        leftSideContent={<MainContainer onRecountClick={handleItemClick} />}
        rightSideContent={<SideContainer selectedRecount={selectedRecount} />}
      ></ManageStocksWrapper>
      <Outlet />
    </>
  );
}

export default RecountItems;
