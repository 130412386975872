import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import {
  fetchAllIngredient,
  postNewIngredient,
  putExistingIngredient,
  deleteExistingIngredient,
  postRestockService,
  postRecountService,
  removeIngredientService,
} from "../services/ingredients";

export const postRecount = createAsyncThunk(
  "postRecount",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "recount";
      const response = await postRecountService(endpoint, payload);
      message.success("Recount successful");
      return fulfillWithValue(response);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const postRestock = createAsyncThunk(
  "postRestock",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "restock";
      const response = await postRestockService(endpoint, payload);
      message.success("Restock successful");
      return fulfillWithValue(response);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getAllIngredients = createAsyncThunk(
  "getIngredients",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "ingredients";
      const response = await fetchAllIngredient(endpoint, payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const addNewIngredient = createAsyncThunk(
  "addIngredient",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postNewIngredient(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const updateExistingIngredient = createAsyncThunk(
  "updateIngredient",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await putExistingIngredient(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const removeIngredient = createAsyncThunk(
  "removeIngredient",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await removeIngredientService(payload?.payload);
      message.success("Ingredient deleted successfully");
      return fulfillWithValue(payload?.ingredientId);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const removeExistingIngredient = createAsyncThunk(
  "deleteIngredient",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await deleteExistingIngredient(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
  selectedCategory: "",
  isAdding: false,
  isEditing: false,
  ingredientIdToEdit: null,
  addNewIngredientLoading: false,
  deleteIngredientLoading: false,
};

const ingredientsSlice = createSlice({
  name: "ingredients",
  initialState,

  reducers: {
    CLEAR_REDUX_STORE_26: () => initialState,

    setIsAdding: (state, action) => {
      state.isAdding = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setIngredientIdToEdit: (state, action) => {
      state.ingredientIdToEdit = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllIngredients.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(getAllIngredients.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(getAllIngredients.rejected, (state) => {
      message.error("Fetch ingredients failed");
      state.error = true;
      state.loading = false;
      state.data = [];
    });

    builder.addCase(addNewIngredient.pending, (state) => {
      state.addNewIngredientLoading = true;
      state.error = null;
      // state.data = [];
    });
    builder.addCase(addNewIngredient.fulfilled, (state, action) => {
      message.success("Ingredient added successfully");
      state.data = [...state.data, action.payload];
      state.addNewIngredientLoading = false;
      state.error = false;
    });
    builder.addCase(addNewIngredient.rejected, (state) => {
      message.error("Something went wrong");
      state.error = true;
      state.addNewIngredientLoading = false;
    });

    builder.addCase(updateExistingIngredient.pending, (state) => {
      state.addNewIngredientLoading = true;
      state.error = null;
    });
    builder.addCase(updateExistingIngredient.fulfilled, (state, action) => {
      message.success("Ingredient updated successfully");
      const updatedData = state.data.map((ingredient) =>
        ingredient.id === action.payload.id ? action.payload : ingredient
      );
      state.data = updatedData;
      state.addNewIngredientLoading = false;
      state.error = false;
    });
    builder.addCase(updateExistingIngredient.rejected, (state) => {
      message.error("Something went wrong");
      state.error = true;
      state.addNewIngredientLoading = false;
    });

    builder.addCase(removeExistingIngredient.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(removeExistingIngredient.fulfilled, (state) => {
      message.success("Ingredient deleted successfully");
      const updatedData = state.data.filter(
        (ingredient) => ingredient.id !== state.ingredientIdToEdit
      );
      state.data = updatedData;
      state.loading = false;
      state.error = false;
      state.isEditing = false;
      state.ingredientIdToEdit = null;
    });
    builder.addCase(removeExistingIngredient.rejected, (state) => {
      message.error("Something went wrong");
      state.error = true;
      state.loading = false;
    });

    builder.addCase(removeIngredient.pending, (state, action) => {
      state.deleteIngredientLoading = true;
    });
    builder.addCase(removeIngredient.fulfilled, (state, action) => {
      state.deleteIngredientLoading = false;
      state.data = state.data.filter(
        (ingredient) => ingredient.id !== action.payload
      );
    });
    builder.addCase(removeIngredient.rejected, (state, action) => {
      state.deleteIngredientLoading = false;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_26,
  setIsAdding,
  setIsEditing,
  setIngredientIdToEdit,
  setSelectedCategory,
} = ingredientsSlice.actions;
export default ingredientsSlice.reducer;
