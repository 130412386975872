import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const tempSlice = createSlice({
  name: "singleOrder",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_41: () => initialState,

    addSingleOrder: (state, action) => {
      return void (state.data = action.payload);
    },
  },
});
export const { CLEAR_REDUX_STORE_41, addSingleOrder } = tempSlice.actions;
export default tempSlice.reducer;
