import React from "react";
import "./Index.css";

const Index = () => {
  return (
    <div className=" flex  items-center gap-3 py-2">
      {Array(5)
        .fill(null)
        .map((_, idx) => (
          <div key={idx} className="skeleton-box short" />
        ))}
    </div>
  );
};

export default Index;
