import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteSingleTax,
  getAllTax,
  postMultipleTax,
  postSingleTax,
  putSingleTax,
} from "../services/taxService";
import { message } from "antd";

const initialState = {
  loading: false,
  taxResponse: [],
  taxRateError: false,

  postTaxLoading: false,
  postTaxError: false,

  putTaxLoding: false,
};

export const getTax = createAsyncThunk(
  "tax/getTax",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      
      const response = await getAllTax(payload, `/tax/findAll`);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const postTax = createAsyncThunk(
  "tax/postTax",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postSingleTax(payload, `/tax`);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const postAllTax = createAsyncThunk(
  "tax/postAllTax",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postMultipleTax(payload, `/tax/upsert`);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const updateSingleTax = createAsyncThunk(
  "tax/updateSingleTax",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let url = `/tax/${payload?.id}`;
      const response = await putSingleTax(payload, url);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const deleteTax = createAsyncThunk(
  "tax/deleteTax",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let url = `/tax/${payload.taxId}`;
      const response = await deleteSingleTax(url);

      return payload.taxId;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const taxSlice = createSlice({
  name: "tax",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTax.pending, (state, action) => {
      state.loading = true;
      state.taxResponse = [];
      state.taxRateError = false;
    });
    builder.addCase(getTax.fulfilled, (state, action) => {
      state.loading = false;
      state.taxResponse = action.payload;
      state.taxRateError = false;
    });
    builder.addCase(getTax.rejected, (state, action) => {
      state.loading = false;
      state.taxResponse = [];
      state.taxRateError = true;
    });

    builder.addCase(postTax.pending, (state, action) => {
      state.postTaxLoading = true;
      state.postTaxError = false;
    });
    builder.addCase(postTax.fulfilled, (state, action) => {
      state.postTaxLoading = false;
      state.taxResponse = Array.isArray(state.taxResponse)
        ? [action.payload, ...state.taxResponse]
        : [action.payload];
      state.postTaxError = false;
    });
    builder.addCase(postTax.rejected, (state, action) => {
      state.postTaxLoading = false;
      state.postTaxError = true;
    });

    builder.addCase(postAllTax.pending, (state, action) => {
      state.postTaxLoading = true;
      state.postTaxError = false;
    });
    builder.addCase(postAllTax.fulfilled, (state, action) => {
      state.postTaxLoading = false;
      state.taxResponse = action.payload;
      state.postTaxError = false;
    });
    builder.addCase(postAllTax.rejected, (state, action) => {
      state.postTaxLoading = false;
      state.postTaxError = true;
    });

    builder.addCase(updateSingleTax.pending, (state, action) => {
      state.putTaxLoding = true;
    });
    builder.addCase(updateSingleTax.fulfilled, (state, action) => {
      state.putTaxLoding = false;

      state.taxResponse = state.taxResponse?.map((Obj) =>
        Obj?.id === action?.payload?.id ? action.payload : Obj
      );
    });
    builder.addCase(updateSingleTax.rejected, (state, action) => {
      state.putTaxLoding = false;
    });

    builder.addCase(deleteTax.fulfilled, (state, action) => {
      state.taxResponse = state.taxResponse?.filter(
        (Obj) => Obj?.id !== action.payload
      );
      message.destroy();
      message.success("Tax removed successfully");
    });
    builder.addCase(deleteTax.rejected, (state, action) => {
      message.info("Something went wrong");
    });
  },
});

// export const { updateWalkinDetails } = taxSlice.actions;

export default taxSlice.reducer;
