import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  selectedRoomStatus: "dirty",
  upcomingBookingsRoomsId: [],
  checkedInRoomId: [],
  isUpcomingFilter: false,
  isOngoingFilter: false,
  isCheckoutFilter: false,
  selectedRoomsId: [],
  housekeeperCountMap: {},
  dirtyInspectCleanCountMap: {},
  isOutOfOrderActive: false,
  displayAddHousekeeperDrawer: false,
  selectedHousekeeperName: "",
  housekeeperRooms: {},
  displayResetDynamicPricingModal: false,
};

const housekeepingSlice = createSlice({
  name: "housekeeping",
  initialState,

  reducers: {
    CLEAR_REDUX_STORE_25: () => initialState,
    updateDisplayResetDynamicPricingModal: (state, action) => {
      state.displayResetDynamicPricingModal = action.payload;
    },
    updateHousekeeperRooms: (state, action) => {
      state.housekeeperRooms = action.payload;
    },
    // updateSelectedHousekeeperName: (state, action) => {
    //   if (state.selectedHousekeeperName === action.payload) {
    //     state.selectedRoomsId = [];
    //     state.selectedHousekeeperName = null;
    //   } else {
    //     state.selectedHousekeeperName = action.payload;
    //     state.selectedRoomsId = Boolean(
    //       state?.housekeeperRooms?.[action.payload]
    //     )
    //       ? state?.housekeeperRooms?.[action.payload]
    //       : [];
    //   }
    // },
    updateSelectedHousekeeperName: (state, action) => {
      if (state.selectedHousekeeperName === action.payload) {
        state.selectedHousekeeperName = "";
      } else {
        state.selectedHousekeeperName = action.payload;
      }
    },
    updateSelectedRoomStatus: (state, action) => {
      state.selectedRoomStatus = action.payload;
    },
    setUpcomingBookingsRoomsId: (state, action) => {
      state.upcomingBookingsRoomsId = action.payload;
    },
    setCheckedInRoomId: (state, action) => {
      state.checkedInRoomId = action.payload;
    },
    updateIsUpcomingFilter: (state, action) => {
      state.isUpcomingFilter = !state.isUpcomingFilter;
    },
    updateIsOngoingFilter: (state, action) => {
      state.isOngoingFilter = !state.isOngoingFilter;
    },
    updateIsCheckoutFilter: (state, action) => {
      state.isCheckoutFilter = !state.isCheckoutFilter;
    },
    updateSelectedRoomsId: (state, action) => {
      if (state.selectedRoomsId.includes(action.payload)) {
        const updatedSelectedRoomsId = state.selectedRoomsId.filter(
          (selectedId) => selectedId !== action.payload
        );
        state.selectedRoomsId = updatedSelectedRoomsId;
      } else {
        state.selectedRoomsId = [...state.selectedRoomsId, action.payload];
      }
    },

    checkAllRooms: (state, action) => {
      const roomIdsToAdd = action.payload.map((room) => room.id);
      state.selectedRoomsId = [...state.selectedRoomsId, ...roomIdsToAdd];
    },
    uncheckAllRooms: (state, action) => {
      const roomIdsToRemove = action.payload.map((room) => room.id);
      const updatedSelectedRoomsId = state.selectedRoomsId.filter(
        (roomId) => !roomIdsToRemove.includes(roomId)
      );
      state.selectedRoomsId = updatedSelectedRoomsId;
    },
    resetStatus: (state, action) => {
      state.selectedHousekeeperName = "";
    },

    resetSelectedRoomsData: (state, action) => {
      state.checkedRoomTypes = [];
      state.selectedRoomsId = [];
    },
    updateHousekeeperCountMap: (state, action) => {
      state.housekeeperCountMap = action.payload;
    },
    updateDirtyInspectCleanCountMap: (state, action) => {
      state.dirtyInspectCleanCountMap = action.payload;
    },
    updateIsOutOfOrderActive: (state, action) => {
      state.isOutOfOrderActive = action.payload;
    },

    updateDisplayAddHousekeeperDrawer: (state, action) => {
      state.displayAddHousekeeperDrawer = action.payload;
    },
    resetFilters: (state, action) => {
      state.isUpcomingFilter = false;
      state.isOngoingFilter = false;
      state.isCheckoutFilter = false;
    },
  },

  extraReducers: (builder) => {},
});

export const {
  CLEAR_REDUX_STORE_25,
  updateSelectedRoomStatus,
  setUpcomingBookingsRoomsId,
  setCheckedInRoomId,
  updateIsUpcomingFilter,
  updateIsOngoingFilter,
  updateIsCheckoutFilter,
  updateSelectedRoomsId,
  resetSelectedRoomsData,
  updateHousekeeperCountMap,
  updateDirtyInspectCleanCountMap,
  updateIsOutOfOrderActive,
  updateDisplayAddHousekeeperDrawer,
  resetFilters,
  checkAllRooms,
  uncheckAllRooms,
  updateSelectedHousekeeperName,
  resetStatus,
  updateHousekeeperRooms,
  updateDisplayResetDynamicPricingModal,
} = housekeepingSlice.actions;
export default housekeepingSlice.reducer;
