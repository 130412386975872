import React, { useState } from "react";
import { Dropdown, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { TranslateText } from "../../../../utils/translation";
const GuestDropDown = ({ adults, setAdults, childrens, setChildrens }) => {
  const dispatch = useDispatch();

  const [adultVisible, setAdultVisible] = useState(true);
  const [childrenVisible, setChildrenVisible] = useState(false);

  const handleAdult = (e, value) => {
    value = String(value);
    const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numeric values
    const trimmedValue = numericValue.replace(/^0+/, ""); // Remove leading zeros
    const numericResult = Number(trimmedValue);

    e.stopPropagation();
    e.preventDefault();

    if (numericResult <= 0) setAdults(0);
    else setAdults(numericResult);
  };

  const handleAdultsButton = (e, value) => {
    if (value < 0) {
      setAdultVisible(false);
      setAdults(0);
      return;
    }
    if (value === 0) return;

    const numericResult = Number(value);

    e.stopPropagation();
    e.preventDefault();

    if (numericResult <= 0) setAdults(0);
    else setAdults(numericResult);
  };

  const handleChildren = (e, value) => {
    value = String(value);
    const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numeric values
    const trimmedValue = numericValue.replace(/^0+/, ""); // Remove leading zeros
    const numericResult = Number(trimmedValue);

    e.stopPropagation();
    e.preventDefault();

    setChildrens(numericResult);
  };

  const handleChildrenButton = (e, value) => {
    if (value < 0) {
      setChildrenVisible(false);
      setChildrens(0);
      return;
    }
    if (value === 0) setChildrenVisible(false);

    const numericResult = Number(value);

    e.stopPropagation();
    e.preventDefault();

    setChildrens(numericResult);
  };

  const items = [
    {
      label: (
        <div
          className="w-[20rem] mb-3 mt-3"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between items-center mt-4">
            <div className="text-[#4D4D4D] font-nunito text-[0.875rem] font-[500]">
              <TranslateText textKey={"adult"} namespace="booking" />
            </div>
            <div>
              {adultVisible ? (
                <div className=" flex gap-1  h-[1.5rem]">
                  <Button
                    className="border-[0.5px] border-[#808080]  text-[#808080] rounded-lg  px-2 h-[1.5rem] w-[2rem] flex justify-center items-center  hover:bg-[#2F80ED] hover:font-[400]"
                    onClick={(e) => handleAdultsButton(e, adults - 1)}
                  >
                    -
                  </Button>
                  <Input
                    value={Boolean(adults) ? String(adults) : ""}
                    style={{
                      textAlign: "center",
                    }}
                    onChange={(e) => handleAdult(e, e.target.value)}
                    className="border-[0.5px] border-[#808080]  text-[#808080]   w-[3.2rem] h-[1.5rem] 
             flex justify-center items-center "
                  />

                  <Button
                    className="border-[0.5px] border-[#808080]  text-[#808080] rounded-lg  px-2 h-[1.5rem] w-[2rem] flex justify-center items-center  hover:bg-[#2F80ED] hover:font-[400]"
                    onClick={(e) => handleAdultsButton(e, adults + 1)}
                  >
                    +
                  </Button>
                </div>
              ) : (
                <Button
                  className="border-[0.5px] border-[#808080]  text-[#808080] rounded-md h-[2rem] w-[4.5rem]  hover:bg-[#2F80ED] hover:font-[400] "
                  onClick={(e) => {
                    setAdultVisible(true);
                    handleAdultsButton(e, 1);
                  }}
                >
                  <TranslateText textKey={"add"} namespace="booking" />
                </Button>
              )}
            </div>
          </div>
        </div>
      ),
      key: 1,
    },

    {
      label: (
        <div
          className="flex justify-between mb-3 mt-3 items-center"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="text-[#4D4D4D] font-nunito text-[0.875rem] font-[500]">
            Children
          </div>
          <div>
            {childrenVisible ? (
              <div className=" flex gap-1 h-[1.5rem]">
                <Button
                  className="border-[0.5px] border-[#808080]  text-[#808080] rounded-lg  px-2 h-[1.5rem] w-[2rem] flex justify-center items-center hover:text-[#FFF]    hover:bg-[#2F80ED] hover:font-[400] "
                  onClick={(e) => handleChildrenButton(e, childrens - 1)}
                >
                  -
                </Button>

                <Input
                  value={Boolean(childrens) ? String(childrens) : ""}
                  style={{
                    textAlign: "center",
                  }}
                  onChange={(e) => handleChildren(e, e.target.value)}
                  className="border-[0.5px] border-[#808080]  text-[#808080]   w-[3.2rem] h-[1.5rem] 
             flex justify-center items-center "
                />

                <Button
                  className="border-[0.5px] border-[#808080]  text-[#808080] rounded-lg  px-2 h-[1.5rem] w-[2rem] flex justify-center items-center hover:text-[#FFF]    hover:bg-[#2F80ED] hover:font-[400] "
                  onClick={(e) => handleChildrenButton(e, childrens + 1)}
                >
                  +
                </Button>
              </div>
            ) : (
              <Button
                className="border-[0.5px] border-[#808080]  text-[#808080] rounded-md h-[2rem] w-[4.5rem] hover:text-[#FFF]    hover:bg-[#2F80ED] hover:font-[400]  "
                onClick={(e) => {
                  setChildrenVisible(true);
                  handleChildren(e, 1);
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ),
      key: 2,
    },
  ];

  const handleOpenChange = () => {
    if (adults === 0) {
      dispatch(setAdults(1));
      setAdultVisible(true);
    }
    if (childrens === 0) setChildrenVisible(false);
  };

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      arrow={true}
      placement="bottom"
      onOpenChange={handleOpenChange}
    >
      <div className="flex border justify-center border-gray-400 m-2 rounded-lg  h-[2.375rem] w-[10rem]">
        <div className=" items-center  cursor-pointer relative flex">
          <div>
            <h1 className="absolute top-[-13.5px] left-[25px] bg-[#FFF] px-[3px] ">
              <span className="font-nunito text-[0.625rem] text-[#808080] font-[400] ">
                Guests<span className="text-[blue] mr-1 text-xs">*</span>
              </span>
            </h1>
            <div className="flex   text-[#000033] font-[Poppins] text-[0.7rem] font-[400] px-1 whitespace-nowrap">
              {adults} Adults & {childrens} Childrens
            </div>
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default GuestDropDown;
