import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  updatedData: [],
  orderData: [],
  loading: false,
  error: null,
};

export const parkedOrderSlice = createSlice({
  name: "parkedOrder",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_32: () => initialState,

    updateParkedOrder: (state, action) => {
      state.data = action.payload;
      return state;
    },
    parkedOrderDetails: (state, action) => {
      state.orderData = action.payload;
    },
    addUpdatedItem: (state, action) => {
      state.updatedData = [...state.updatedData, action.payload];
      return state;
    },
    updateItem: (state, action) => {
      const itemIndex = state.updatedData.findIndex(
        (item) => item._id === action.payload._id
      );
      if (itemIndex >= 0) {
        state.updatedData[itemIndex] = action.payload;
        return void state;
      } else {
        return void state.updatedData.push(action.payload);
      }
    },
    removeItem: (state, action) => {
      const itemIndex = state.updatedData.findIndex(
        (item) => item._id === action.payload._id
      );
      if (itemIndex >= 0) {
        if (state.updatedData[itemIndex]?.count > 1) {
          state.updatedData[itemIndex] = action.payload;
        } else {
          state.updatedData.splice(itemIndex, 1);
        }
      }
    },
  },
});

export const {
  CLEAR_REDUX_STORE_32,
  updateParkedOrder,
  addUpdatedItem,
  updateItem,
  removeItem,
  parkedOrderDetails,
} = parkedOrderSlice.actions;
export default parkedOrderSlice.reducer;
