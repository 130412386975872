import React, { useState } from "react";
import Select from "react-select";
import { Checkbox, Switch } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  updateAvailableRooms,
  updateCmInvCode,
  updateRate,
  updateRoomType,
} from "../../reducer/channelManagerReducer";

const Rooms = () => {
  const { selectedRoomType, availableRoom, rate } = useSelector(
    (store) => store.channelManager
  );
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);

  const options = [];

  hotelDetails?.roomTypes?.forEach((item) => {
    if (Boolean(item.cmInvCode)) {
      options.push({
        label: item.name,
        value: item.name,
        cmInvCode: item.cmInvCode,
      });
    }
  });

  const changeRoomType = (e) => {
    // Rooms and store data into redux channel manager
    dispatch(updateRoomType(e.value));

    // update cmInvCode type and store data into redux channel manager
    dispatch(updateCmInvCode(e.cmInvCode));
  };

  return (
    <div className="w-full">
      <div className="">
        <div className="relative w-[100%]">
          <label className="text-[#00000080] text-[16px] absolute top-[-14px] left-[15px] bg-[#ffffff] z-10">
            Room Type
          </label>
          <Select
            onChange={changeRoomType}
            placeholder={<div>Select room type</div>}
            className="w-full"
            options={options}
            styles={{
              control: (provided) => ({
                ...provided,
                boxShadow: "none",
                border: "1px solid #00000019",
                borderRadius: "6px",
                height: "44px",
              }),
            }}
          />
        </div>

        <div className="flex mt-4 relative ">
          <h1 className="mt-[1rem] text-[#00000080] text-[16px] absolute top-[-16px] left-[15px] bg-[#ffffff]">
            Available Rooms
          </h1>
          <input
            min={0}
            type="number"
            onChange={(e) => dispatch(updateAvailableRooms(e.target.value))}
            name="discount"
            placeholder="Enter room availability"
            className="mt-3 text-gray-900 rounded-lg p-2.5 cursor-pointer w-full border"
          />
        </div>

        {/* <div className="flex mt-4 relative">
            <input
              onChange={(e) => dispatch(updateRate(e.target.value))}
              min={0}
              type="number"
              name="discount"
              placeholder="Enter Amount"
              className="mt-3 text-gray-900 rounded-lg p-2.5 cursor-pointer w-[50%] border"
            />
            <h1 className="mt-[1rem] text-[#00000080] text-[16px] absolute top-[-16px] left-[15px] bg-[#ffffff]">
              Rate
            </h1>
          </div> */}

        {/* <div className="mt-3">
            <label className="mt-1 text-[#00000080] text-[16px]">
              Auto Pricing
            </label>
            <Checkbox defaultChecked className="ml-2" />
          </div> */}
      </div>
    </div>
  );
};
export default Rooms;
