import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPayment,
  deleteAll,
  getPaymentById,
  getPayments,
  multiPaymentUpdate,
  getPaymentModesService,
  putPaymentService,
  deletePaymentService,
} from "../services/payment";
import { message } from "antd";

export const getAllPayments = createAsyncThunk(
  "getAllPayments",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const data = {
      hotelId: payload.hotelId,
      searchText: Boolean(payload.searchText) ? payload.searchText : "",

      pageNumber: payload.pageNumber,
      pageSize: payload.pageSize,
      sortingDirection: payload.sortingDirection,
      sortingField: payload.sortingField,
      invoiceId: payload.invoiceId,
    };

    try {
      const response = await getPayments({ ...payload, ...data });

      return fulfillWithValue({
        pageNumber: payload?.pageNumber,
        data: response?.data,
        totalPages: response?.data?.totalPages,
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error.message);
    }
  }
);

export const postPayment = createAsyncThunk(
  "postPayment",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await createPayment(payload);
      const allPayments = getState()?.payments?.allPayments;

      let modifiedPayments = [response?.data, ...allPayments];
      return fulfillWithValue(modifiedPayments);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const multiplePaymentUpdate = createAsyncThunk(
  "multiplePaymentUpdate",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await multiPaymentUpdate(payload);
      const allPayments = getState()?.payments?.allPayments;

      let modifiedPayments = allPayments?.map((obj) => {
        if (response?.data?.some((dataObj) => dataObj.id === obj?.id)) {
          // Find the object in response.data that matches the id of obj
          return response.data.find((dataObj) => dataObj.id === obj.id);
        } else {
          return obj;
        }
      });
      message.success("Payment Updated Successfully");

      return fulfillWithValue(modifiedPayments);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const multipleDeletePayment = createAsyncThunk(
  "multipleDeletePayment",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await deleteAll(payload);
      const allPayments = getState()?.payments?.allPayments;
      let newArray = allPayments?.filter(
        (Obj) => !payload?.paymentIds?.includes(Obj?.id)
      );
      message.success("Payment Deleted");

      return fulfillWithValue(newArray);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const getSinglePayment = createAsyncThunk(
  "getSinglePayment",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getPaymentById(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const getPaymentModes = createAsyncThunk(
  "getPaymentModes",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "bank/paymentModes";
      const response = await getPaymentModesService(payload, endpoint);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const updatePayment = createAsyncThunk(
  "updatePayment",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "/payments";

      const response = await putPaymentService(endpoint, payload);
      message.success(payload.message);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const deletePayment = createAsyncThunk(
  "deletePayment",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "/payments";

      const response = await deletePaymentService(endpoint, payload);
      message.success(payload.message);
      return fulfillWithValue({ data: response.data, id: payload?.id });
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: false, // before api call
  allPayments: [],
  apiError: false, // check api status

  pageNumber: 0,

  displayAddPaymentDrawer: false,
  displayPaymentPrintDrawer: {
    display: false,
    paymentObject: {},
  },
  paymentObject: {},
  paymentObjectLoading: false,
  paymentObjectApiError: false,

  initialDate: "",
  finalDate: "",
  visibleCalendar: "",
  selectedInitialDate: "",
  selectedFinalDate: "",

  selectedStateArray: [],

  displayInvoiceDropDown: { display: false, invoiceArray: [] },
  displayDeletePaymentModal: false,

  displayBulUpdateDropDown: false,

  displayPartyFilter: false,
  selectedPartyFilter: [],

  infinateScrollLoading: false,

  // get payment modes
  paymentModesResponse: [],
  paymentModesLoading: false,
  paymentModesError: null,

  // update a payment
  updatePaymentLoading: false,
  updatePaymentError: null,

  // deletePayment
  deletePaymentLoading: false,
  deletePaymentError: false,

  editPaymentDrawer: {
    display: false,
    paymentObj: {},
  },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_34: () => initialState,

    updateDisplayAddPaymentDrawer: (state, action) => {
      state.displayAddPaymentDrawer = action.payload;
    },
    updateDisplayPaymentPrintDrawer: (state, action) => {
      state.displayPaymentPrintDrawer = action.payload;
    },
    updateInitialDate: (state, action) => {
      state.initialDate = action.payload;
    },
    updateFinalDate: (state, action) => {
      state.finalDate = action.payload;
    },
    updateSelectedInitialDate: (state, action) => {
      state.selectedInitialDate = action.payload;
    },
    updateSelectedFinalDate: (state, action) => {
      state.selectedFinalDate = action.payload;
    },
    updateVisibleCalendar: (state, action) => {
      state.visibleCalendar = action.payload;
    },
    updateSelectedStateArray: (state, action) => {
      state.selectedStateArray = action.payload;
    },
    updateDisplayInvoiceDropDown: (state, action) => {
      state.displayInvoiceDropDown = action.payload;
    },
    updateDisplayDeletePaymentModal: (state, action) => {
      state.displayDeletePaymentModal = action.payload;
    },
    updateDisplayBulkUpdateDropDown: (state, action) => {
      state.displayBulUpdateDropDown = action.payload;
    },
    updateDisplayPartyFilter: (state, action) => {
      state.displayPartyFilter = action.payload;
    },
    updateSelectedPartyFilter: (state, action) => {
      state.selectedPartyFilter = action.payload;
    },
    updatePageNumber: (state, action) => {
      state.pageNumber = state.pageNumber + 1;
    },
    updateInFinateScrollLoading: (state, action) => {
      state.infinateScrollLoading = action.payload;
    },
    updateEditPaymentDrawer: (state, action) => {
      state.editPaymentDrawer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPayments.pending, (state, action) => {
      state.loading = state.infinateScroll ? false : true;
      state.apiError = false;
    });
    builder.addCase(getAllPayments.fulfilled, (state, action) => {
      state.loading = false;
      state.infinateScrollLoading = false;
      state.infinateScroll = action.payload?.data?.length > 0 ? true : false;
      state.infinateScrollLoading =
        action.payload?.data?.length > 0 ? true : false;

      state.allPayments = Boolean(action.payload?.pageNumber)
        ? [...state.allPayments, ...(action.payload.data || [])]
        : action.payload.data || [];

      state.apiError = false;
    });
    builder.addCase(getAllPayments.rejected, (state, action) => {
      state.loading = false;
      state.infinateScrollLoading = false;
      state.allPayments = [];
      state.apiError = true;
    });
    builder.addCase(postPayment.pending, (state, action) => {
      state.loading = true;
      state.apiError = false;
    });
    builder.addCase(postPayment.fulfilled, (state, action) => {
      state.loading = false;
      state.allPayments = action?.payload;
      state.apiError = false;
    });
    builder.addCase(postPayment.rejected, (state, action) => {
      state.loading = false;
      state.apiError = true;
    });
    builder.addCase(getSinglePayment.pending, (state, action) => {
      state.paymentObjectLoading = true;
      state.paymentObjectApiError = false;
    });
    builder.addCase(getSinglePayment.fulfilled, (state, action) => {
      state.paymentObjectLoading = false;
      state.paymentObject = action.payload;
      state.paymentObjectApiError = false;
    });
    builder.addCase(getSinglePayment.rejected, (state, action) => {
      state.paymentObjectLoading = false;
      state.paymentObject = {};
      state.paymentObjectApiError = true;
    });
    builder.addCase(multipleDeletePayment.pending, (state, action) => {
      state.loading = true;
      state.apiError = false;
    });
    builder.addCase(multipleDeletePayment.fulfilled, (state, action) => {
      state.loading = false;
      state.allPayments = action.payload;
      state.apiError = false;
    });
    builder.addCase(multipleDeletePayment.rejected, (state, action) => {
      state.loading = false;
      state.allPayments = [];
      state.apiError = true;
    });
    builder.addCase(multiplePaymentUpdate.pending, (state, action) => {
      state.loading = true;
      state.apiError = false;
    });
    builder.addCase(multiplePaymentUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.allPayments = action.payload;
      state.apiError = false;
    });
    builder.addCase(multiplePaymentUpdate.rejected, (state, action) => {
      state.loading = false;
      state.allPayments = [];
      state.apiError = true;
    });

    builder.addCase(getPaymentModes.pending, (state, action) => {
      state.paymentModesLoading = true;
      state.paymentModesError = false;
    });
    builder.addCase(getPaymentModes.fulfilled, (state, action) => {
      state.paymentModesResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
      state.paymentModesLoading = false;
      state.paymentModesError = false;
    });
    builder.addCase(getPaymentModes.rejected, (state, action) => {
      state.paymentModesLoading = false;
      state.paymentModesResponse = [];
      state.paymentModesError = true;
    });

    // update payment
    builder.addCase(updatePayment.pending, (state, action) => {
      state.updatePaymentLoading = true;
      state.updatePaymentError = null;
    });
    builder.addCase(updatePayment.fulfilled, (state, action) => {
      state.updatePaymentLoading = false;
      state.updatePaymentError = null;
      state.allPayments = state.allPayments.map((payment) =>
        action.payload.id === payment.id ? action.payload : payment
      );
    });
    builder.addCase(updatePayment.rejected, (state, action) => {
      state.updatePaymentLoading = false;
      state.updatePaymentError = action.payload;
    });

    builder.addCase(deletePayment.pending, (state, action) => {
      state.deletePaymentLoading = true;
      state.deletePaymentError = null;
    });
    builder.addCase(deletePayment.fulfilled, (state, action) => {
      state.deletePaymentLoading = false;
      state.deletePaymentError = null;

      state.allPayments = state.allPayments.filter(
        (payment) => action.payload.id !== payment.id
      );
    });
    builder.addCase(deletePayment.rejected, (state, action) => {
      state.deletePaymentLoading = false;
      state.deletePaymentError = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_34,
  updateDisplayAddPaymentDrawer,
  updateDisplayPaymentPrintDrawer,
  updateInitialDate,
  updateFinalDate,
  updateVisibleCalendar,
  updateSelectedInitialDate,
  updateSelectedFinalDate,
  updateSelectedStateArray,
  updateDisplayInvoiceDropDown,
  updateDisplayDeletePaymentModal,
  updateDisplayBulkUpdateDropDown,
  updateDisplayPartyFilter,
  updateSelectedPartyFilter,
  updatePageNumber,
  updateInFinateScrollLoading,
  updateEditPaymentDrawer,
} = paymentSlice.actions;

export default paymentSlice.reducer;
