import { authedAxios } from "../utils/customHttpHeader";

export const getItemByIdService = async (itemId) => {
  const response = await authedAxios().get(`items/${itemId}`);
  return response.data;
};

export const getAllItemsService = async (hotelId) => {
  const response = await authedAxios().get("items", {
    params: { hotelId },
  });
  return response.data;
};

export const deleteOrder = async (id) => {
  const response = await authedAxios().delete(`orders/${id}`);
  return response.data;
};
