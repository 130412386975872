import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { message, Button, Modal } from "antd";
import CircularProgress from "@mui/material/CircularProgress";

import {
  updateAllRoomsAsyncThunk,
  saveAllRoomAsync,
  getAllRoomsAsyncThunk,
  getInActiveRoomsAsyncThunk,
} from "../../../../reducer/roomsReducer";
import {
  resetStatus,
  resetSelectedRoomsData,
  updateDisplayAddHousekeeperDrawer,
  updateSelectedHousekeeperName,
  updateDisplayResetDynamicPricingModal,
} from "../../../../reducer/housekeepingReducer";
import { resetDynamicPricing } from "../../../../reducer/loginReducer";

import AddHousekeeperDrawer from "../../drawers/addHousekeeperDrawer";
import { TranslateText } from "../../../../utils/translation";

const RightView = () => {
  const { hotelDetails } = useSelector((store) => store.login);

  const { data, inActiveRooms } = useSelector((store) => store.rooms);
  const {
    selectedRoomsId,
    housekeeperCountMap,
    selectedRoomStatus,
    isOutOfOrderActive,
    selectedHousekeeperName,
    displayResetDynamicPricingModal,
  } = useSelector((store) => store.housekeeping);

  const [resetDPLoading, setResetDPLoading] = useState(false);

  const dispatch = useDispatch();

  const handleAssignRoomApiCall = async () => {
    if (selectedRoomsId.length === 0) {
      message.warning("No rooms selected");
      return;
    } else if (!selectedHousekeeperName) {
      message.warning("No housekeeper selected");
      return;
    } else {
      let payload = [];

      for (const selectedRoomId of selectedRoomsId) {
        const roomPayload = data.find((room) => room.id === selectedRoomId);
        payload.push({
          ...roomPayload,
          houseKeeperName: selectedHousekeeperName,
          lastHouseKeeperAssignedAt: Date.now(),
        });
      }
      dispatch(
        updateAllRoomsAsyncThunk({
          payload,
          data,
          selectedRoomsId,
          successMessage: "Housekeeper assigned successfully",
        })
      );
      dispatch(resetSelectedRoomsData());
      dispatch(resetStatus());
    }
  };

  const handleChangeHousekeepingStatus = () => {
    if (selectedRoomsId.length === 0) {
      message.warning("No rooms selected");
      return;
    } else {
      let payload = [];

      const updatedStatus =
        selectedRoomStatus === "dirty"
          ? "inspect"
          : selectedRoomStatus === "inspect"
          ? "clean"
          : selectedRoomStatus === "clean"
          ? "dirty"
          : "";
      selectedRoomsId.forEach((selectedRoomId) => {
        const roomPayload = data.find((room) => room.id === selectedRoomId);

        payload.push({
          ...roomPayload,
          houseKeepingStatus: updatedStatus,
        });
      });

      dispatch(
        updateAllRoomsAsyncThunk({
          payload,
          data,
          selectedRoomsId,
          successMessage: `Housekeeping status changed to ${updatedStatus}`,
        })
      );

      dispatch(resetSelectedRoomsData());
      dispatch(resetStatus());
    }
  };

  const handleMarkOutOfOrder = async () => {
    if (selectedRoomsId.length === 0) {
      message.warning("No rooms selected");
      return;
    } else {
      let payload = [];

      data?.forEach((room) => {
        const { id } = room;
        if (selectedRoomsId?.includes(id)) {
          payload.push({ ...room, status: "inActive" });
        }
      });

      const res = await dispatch(saveAllRoomAsync(payload));

      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getAllRoomsAsyncThunk({ hotelId: hotelDetails?.id }));
        dispatch(
          getInActiveRoomsAsyncThunk({
            hotelId: hotelDetails?.id,
          })
        );
        dispatch(resetSelectedRoomsData());
        dispatch(resetStatus());
        dispatch(updateDisplayResetDynamicPricingModal(true));
      }
    }
  };

  const handleMarkInOrder = async () => {
    if (selectedRoomsId.length === 0) {
      message.warning("No rooms selected");
      return;
    } else {
      let payload = [];

      data?.forEach((room) => {
        const { id } = room;
        if (selectedRoomsId?.includes(id)) {
          payload.push({ ...room, status: "active" });
        }
      });

      const res = await dispatch(saveAllRoomAsync(payload));

      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getAllRoomsAsyncThunk({ hotelId: hotelDetails?.id }));
        dispatch(
          getInActiveRoomsAsyncThunk({
            hotelId: hotelDetails?.id,
          })
        );
        dispatch(resetSelectedRoomsData());
        dispatch(resetStatus());
        dispatch(updateDisplayResetDynamicPricingModal(true));
      }
    }
  };

  const handleResetDynamicPricing = async () => {
    setResetDPLoading(true);
    await dispatch(resetDynamicPricing(hotelDetails?.id));
    setResetDPLoading(false);
    dispatch(updateDisplayResetDynamicPricingModal(false));
  };

  return (
    <div className="w-[30%] h-[85vh] bg-[#ffffff] relative shadow-cm-right py-[25px] overflow-y-hidden">
      <div className="px-[10px]">
        {!isOutOfOrderActive && (
          <div className="w-full border-[0.5px] border-[#00000026] rounded-[10px] py-[20px] px-[15px] relative flex flex-col items-center">
            <p className="font-nunito font-[500] text-[14px] leading-[19.1px] text-[#808080] bg-[#fff] absolute top-[-10px] left-[10px]">
              <TranslateText
                textKey={"select_housekeeping_staff"}
                namespace="housekeeping"
              />
            </p>
            <div className="flex flex-wrap w-full gap-[21px] max-h-[55vh] overflow-y-auto no-scrollbar">
              {hotelDetails?.housekeepingStaff?.map((houseKeeper) => (
                <Button
                  className={`rounded-[10px] text-[14px] leading-[20px] border-[1px] py-[5.5px] px-[12px] cursor-pointer hover:bg-[#fff] ${
                    selectedHousekeeperName === houseKeeper
                      ? "text-[#007FFF] bg-[#fff] border-[#2F80ED]"
                      : "text-[#808080] bg-[#f2f2f2] border-[#f2f2f2]"
                  }`}
                  onClick={() =>
                    dispatch(updateSelectedHousekeeperName(houseKeeper))
                  }
                >
                  {`${houseKeeper} - ${
                    Boolean(housekeeperCountMap[houseKeeper])
                      ? housekeeperCountMap[houseKeeper]
                      : 0
                  }`}
                </Button>
              ))}
              <div
                className={`rounded-[10px] text-[14px] leading-[20px] border-[1px] py-[5.5px] px-[12px] cursor-pointer bg-[#F6F7FF] border-[#F6F7FF] text-[#007fff]`}
                onClick={() =>
                  dispatch(updateDisplayAddHousekeeperDrawer(true))
                }
              >
                <TranslateText textKey={"add_new"} namespace="housekeeping" />
              </div>
            </div>
            <button
              className="bg-[#007FFF] text-[#ffffff] leading-[20px] text-[14px] py-[5.5px] px-[12px] rounded-[5px] mt-[15px] cursor-pointer min-w-[115px] flex items-center justify-center"
              onClick={handleAssignRoomApiCall}
            >
              <TranslateText textKey={"assign_room"} namespace="housekeeping" />
            </button>
          </div>
        )}
      </div>
      <div className="absolute bottom-[13px] w-full">
        {!isOutOfOrderActive && (
          <div className="h-[53px] w-full flex items-center justify-center border-[0.5px] border-[#00000026] border-b-[0px] rounded-t-[10px]">
            <button
              className="w-[44%] h-[31px] rounded-[5px] bg-[#526EFF0D] font-[500] text-[14px] leading-[20px] text-[#F36960]"
              onClick={handleMarkOutOfOrder}
              disabled={isOutOfOrderActive}
            >
              <TranslateText
                textKey={"mark_out_of_order"}
                namespace="housekeeping"
              />
            </button>
          </div>
        )}

        <button
          className={`h-[53px] w-full flex items-center justify-center
          bg-[#007FFF]
        `}
          onClick={
            isOutOfOrderActive
              ? handleMarkInOrder
              : handleChangeHousekeepingStatus
          }
        >
          <span
            className={`font-[600] text-[18px] leading-[18px] tracking-[-0.5px] 
         text-[#ffffff]
          `}
          >
            {!isOutOfOrderActive
              ? `${
                  selectedRoomStatus === "clean" ? "MARK AS DIRTY"  : "COMPLETE"
                }`
              : "MARK AS READY"}
          </span>
        </button>
      </div>
      <AddHousekeeperDrawer />
      {/*MODAL OPENS WHEN WE CLICK ON MARK A ROOM IN-ORDER OR OUT-OF-ORDER*/}
      <Modal
        open={displayResetDynamicPricingModal && hotelDetails?.dynamicPricing}
        centered
        title=""
        width={"auto"}
        styles={{ mask: { backdropFilter: "blur(2px)" } }}
        onCancel={() => dispatch(updateDisplayResetDynamicPricingModal(false))}
        footer={
          <div className="w-full flex gap-[30px] justify-center mt-5">
            <Button
              className="text-[1.1rem]  w-[11rem] h-[3rem] border-[#D0D5DD] text-[#808080] rounded-[0.5rem] font-[Poppins] font-[600] "
              onClick={() =>
                dispatch(updateDisplayResetDynamicPricingModal(false))
              }
            >
              <TranslateText textKey={"no"} namespace="housekeeping" />
            </Button>
            <Button
              className="text-[#FFF] text-[1.1rem] rounded-xl  bg-[#F36960] w-[11rem] h-[3rem]"
              onClick={handleResetDynamicPricing}
            >
              <div>
                {resetDPLoading ? <CircularProgress size={16} /> : "YES"}
              </div>
            </Button>
          </div>
        }
      >
        <div className="font-nunito text-[1.125rem] font-[600] text-[#4D4D4D] pt-6">
          <TranslateText
            textKey={
              "do_you_want_to_update_dynamic_pricing_based_on_recent_changes"
            }
            namespace="housekeeping"
          />
        </div>
        <div className="text-[#808080] font-nunito text-[0.875rem] font-[400]">
          <TranslateText
            textKey={
              "by_clicking_yes_dynamic_pricing_will_be_updated_and_it_will_take_some_time"
            }
            namespace="housekeeping"
          />
        </div>
      </Modal>
    </div>
  );
};

export default RightView;
