import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalDetails: {}, // store single data

  visibleClientModal: false, // after clicking on the edit button in client details page , modal will model
  selectedDob: null,

  identityOptions: [
    { value: "aadhar_card", label: "Aadhar Card" },
    { value: "pan_card", label: "PAN Card" },
    { value: "license", label: "License" },
    { value: "passport", label: "Passport" },
    { value: "voter_id", label: "Voter ID" },
  ],
};

const editClientModelReducerSlice = createSlice({
  name: "editClientModel",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_19: () => initialState,

    updatePersonalDetails: (state, action) => {
      state.personalDetails = action.payload;
    },

    updateShowingClientModal: (state, action) => {
      state.visibleClientModal = action.payload.modal;
    },

    updateDates: (state, action) => {
      if (action.payload.dob) {
        state.selectedDob = action.payload.dob;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_19,
  updatePersonalDetails,
  updateShowingClientModal,
  updateDates,
  setUpdateIdType,
} = editClientModelReducerSlice.actions;
export default editClientModelReducerSlice.reducer;
