import { authedAxios } from "../utils/customHttpHeader";

export const getNetSalesAndDiscountService = async (payload) => {
  const { hotelId, startDate, endDate, posName, posId } = payload;
  const endpoint = "/posDashboard/netSaleAndDiscount";
  let queryString = `?hotelId=${hotelId}`;
  if (startDate) {
    queryString += `&startDate=${startDate}`;
  }
  if (endDate) {
    queryString += `&endDate=${endDate}`;
  }

  if (posName) {
    queryString += `&posName=${posName}`;
  }
  if (posId) {
    queryString += `&posId=${posId}`;
  }

  const response = await authedAxios().get(endpoint + queryString);
  return response;
};

export const getRepeatingGuestsService = async (payload) => {
  const { hotelId, startDate, endDate, posName, posId } = payload;
  const endpoint = "/posDashboard/repeatingGuests";
  let queryString = `?hotelId=${hotelId}`;
  if (startDate) {
    queryString += `&startDate=${startDate}`;
  }
  if (endDate) {
    queryString += `&endDate=${endDate}`;
  }

  if (posName) {
    queryString += `&posName=${posName}`;
  }
  if (posId) {
    queryString += `&posId=${posId}`;
  }

  const response = await authedAxios().get(endpoint + queryString);
  return response;
};

export const getRefundService = async (payload) => {
  const { hotelId, startDate, endDate, posName, posId } = payload;
  const endpoint = "/posDashboard/refund";
  let queryString = `?hotelId=${hotelId}`;
  if (startDate) {
    queryString += `&startDate=${startDate}`;
  }
  if (endDate) {
    queryString += `&endDate=${endDate}`;
  }

  if (posName) {
    queryString += `&posName=${posName}`;
  }
  if (posId) {
    queryString += `&posId=${posId}`;
  }
  const response = await authedAxios().get(endpoint + queryString);
  return response;
};

export const getOrdersCountService = async (payload) => {
  const { hotelId, startDate, endDate, posName, posId } = payload;
  const endpoint = "/posDashboard/ordersCount";
  let queryString = `?hotelId=${hotelId}`;
  if (startDate) {
    queryString += `&startDate=${startDate}`;
  }
  if (endDate) {
    queryString += `&endDate=${endDate}`;
  }

  if (posName) {
    queryString += `&posName=${posName}`;
  }
  if (posId) {
    queryString += `&posId=${posId}`;
  }

  const response = await authedAxios().get(endpoint + queryString);
  return response;
};

export const getOrderSourcesService = async (payload) => {
  const { hotelId, startDate, endDate, posName, posId } = payload;
  const endpoint = "/posDashboard/orderSources";
  let queryString = `?hotelId=${hotelId}`;
  if (startDate) {
    queryString += `&startDate=${startDate}`;
  }
  if (endDate) {
    queryString += `&endDate=${endDate}`;
  }

  if (posName) {
    queryString += `&posName=${posName}`;
  }

  if (posId) {
    queryString += `&posId=${posId}`;
  }

  const response = await authedAxios().get(endpoint + queryString);
  return response;
};

export const getItemsCountService = async (payload) => {
  const { hotelId, startDate, endDate, posName, posId } = payload;
  const endpoint = "/posDashboard/itemsCount";
  let queryString = `?hotelId=${hotelId}`;
  if (startDate) {
    queryString += `&startDate=${startDate}`;
  }
  if (endDate) {
    queryString += `&endDate=${endDate}`;
  }

  if (posName) {
    queryString += `&posName=${posName}`;
  }
  if (posId) {
    queryString += `&posId=${posId}`;
  }

  const response = await authedAxios().get(endpoint + queryString);
  return response;
};

export const getPaymentsByTypeService = async (payload) => {
  const { hotelId, startDate, endDate, posName, posId } = payload;
  const endpoint = "/posDashboard/paymentsByType";
  let queryString = `?hotelId=${hotelId}`;
  if (startDate) {
    queryString += `&startDate=${startDate}`;
  }
  if (endDate) {
    queryString += `&endDate=${endDate}`;
  }

  if (posName) {
    queryString += `&posName=${posName}`;
  }
  if (posId) {
    queryString += `&posId=${posId}`;
  }

  const response = await authedAxios().get(endpoint + queryString);
  return response;
};
