import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getHotelDashboardService } from "../services/dashboard";
import { message } from "antd";

export const getHotelDashboard = createAsyncThunk(
  "getHotelDashboard/dashboard",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getHotelDashboardService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) localStorage.removeItem("token");

      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: false,
  data: {},
  error: null,
  title: "Today",
  totalBookingsAmount: 0,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_18: () => initialState,
    updateActiveTab: (state, action) => {
      state.title = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHotelDashboard.pending, (state) => {
      state.loading = true;
      state.data = {};
      state.totalBookingsAmount = 0;
      state.error = null;
    });
    builder.addCase(getHotelDashboard.fulfilled, (state, action) => {
      const data = action.payload;
      const bookingSources = data?.bookingSources;

      state.loading = false;
      state.data = data;
      state.error = false;

      if (Boolean(bookingSources)) {
        const updatedTotalBookings = Object?.values(bookingSources)?.reduce(
          (total, bookings) => total + bookings,
          0
        );
        state.totalBookingsAmount = updatedTotalBookings;
      }
    });
    builder.addCase(getHotelDashboard.rejected, (state) => {
      state.loading = false;
      state.data = {};
      state.totalBookingsAmount = 0;
      state.error = true;
    });
  },
});

export const { CLEAR_REDUX_STORE_18, updateActiveTab } = dashboardSlice.actions;
export default dashboardSlice.reducer;
