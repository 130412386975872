import { useEffect, Suspense, lazy } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../pages/loading";
const RatesAndAvailabilityMainPage = lazy(() =>
  import("../../components/ratesAndAvailability/RatesAndAvailabilityMainPage")
);

const Index = () => {
  const { loggedInUserPermissions } = useSelector(
    (store) => store.usersRestriction
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !(
        loggedInUserPermissions?.includes("RATES_AVAILABILITY_VIEW") ||
        loggedInUserPermissions?.includes("RATES_AVAILABILITY_UPDATE")
      )
    )
      navigate("/dashboard");
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <div>
        <RatesAndAvailabilityMainPage />
      </div>
    </Suspense>
  );
};

export default Index;
