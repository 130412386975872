import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, ConfigProvider, message } from "antd";
import CloseIcon from "../../../assets/images/svgs/CloseIcon.svg";
import {
  updateShowRoomNumberModal,
  saveTempSelectedRoomWTypeAndNum,
  updateTempSelectedRoomWTypeAndNum,
  updateRoomPriceStructure,
} from "../../../reducer/bookingReducer";
import { CloseOutlined } from "@ant-design/icons";

const Room = () => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);
  const rooms = Boolean(hotelDetails?.roomTypes) ? hotelDetails?.roomTypes : []; // room type

  const {
    tempSelectedRoomWTypeAndNum,
    selectedRoomWTypeAndNum,
    showRoomNumberModal,
    roomList,
    roomPriceStructure,
  } = useSelector((store) => store.booking);

  let updatedRoomAvailability = [];
  const roomAvailability = roomList?.map((avail) => {
    const roomDetails = rooms?.filter((room) => room.name === avail._id)[0];
    const availObj = {
      name: avail._id,
      availability: avail.availability,
      rooms: avail.rooms,
    };
    availObj.rate = roomDetails?.rate;
    return availObj;
  });

  const namesToFilter = rooms.map((item) => item.name);

  updatedRoomAvailability = roomAvailability.filter((item) =>
    namesToFilter.includes(item.name)
  );

  const isValidate = () => {
    let atleastOneRoom = false;

    for (let key in tempSelectedRoomWTypeAndNum) {
      let arrayLength = tempSelectedRoomWTypeAndNum[key]?.length;
      if (arrayLength > 0) {
        atleastOneRoom = true;
        break;
      }
    }

    return atleastOneRoom;
  };

  const handleApplyChanges = () => {
    if (isValidate()) {
      let newLocalRoomPriceStructure = JSON.parse(
        JSON.stringify(roomPriceStructure)
      );

      newLocalRoomPriceStructure?.forEach((Obj, index) => {
        let value = tempSelectedRoomWTypeAndNum[Obj.type]?.length;
        if (Boolean(value)) newLocalRoomPriceStructure[index].count = value;
        else newLocalRoomPriceStructure[index].count = 0;
      });

      dispatch(updateRoomPriceStructure(newLocalRoomPriceStructure));

      dispatch(updateShowRoomNumberModal());
      dispatch(saveTempSelectedRoomWTypeAndNum());
    } else {
      message.info("Please Select atleast one room");
    }
  };

  const handleSelectedRoomChange = (
    selectedStatus,
    roomType,
    roomNum,
    roomArray
  ) => {
    //selectedStatus gives alrady present State of room

    if (selectedStatus) {
      // updateTempSelectedRoomWTypeAndNum

      const newLocalRoomPriceStructure = JSON.parse(
        JSON.stringify(roomPriceStructure)
      );
      const currentIndex = newLocalRoomPriceStructure.findIndex(
        (obj) => obj.type === roomType
      );
      newLocalRoomPriceStructure[currentIndex].count =
        newLocalRoomPriceStructure[currentIndex].count - 1;

      dispatch(updateRoomPriceStructure(newLocalRoomPriceStructure));

      let removeSelectedRoom = tempSelectedRoomWTypeAndNum[roomType]?.filter(
        (item) => item.name !== roomNum
      );

      dispatch(
        updateTempSelectedRoomWTypeAndNum({
          ...tempSelectedRoomWTypeAndNum,
          [roomType]: removeSelectedRoom,
        })
      );
    } else {
      // if room limit is not 0
      const newLocalRoomPriceStructure = JSON.parse(
        JSON.stringify(roomPriceStructure)
      );
      const currentIndex = newLocalRoomPriceStructure.findIndex(
        (obj) => obj.type === roomType
      );
      newLocalRoomPriceStructure[currentIndex].count =
        newLocalRoomPriceStructure[currentIndex].count + 1;

      dispatch(updateRoomPriceStructure(newLocalRoomPriceStructure));

      if (Boolean(tempSelectedRoomWTypeAndNum[roomType])) {
        let selectedRoomArray = tempSelectedRoomWTypeAndNum[roomType];

        dispatch(
          updateTempSelectedRoomWTypeAndNum({
            ...tempSelectedRoomWTypeAndNum,
            [roomType]: [...selectedRoomArray, roomArray],
          })
        );
      } else {
        dispatch(
          updateTempSelectedRoomWTypeAndNum({
            ...tempSelectedRoomWTypeAndNum,
            [roomType]: [roomArray],
          })
        );
      }
    }
  };

  const handleCancel = () => {
    const newLocalRoomPriceStructure = JSON.parse(
      JSON.stringify(roomPriceStructure)
    );

    for (let key in selectedRoomWTypeAndNum) {
      newLocalRoomPriceStructure.forEach((Obj) => {
        if (Obj.type === key) {
          Obj.count = selectedRoomWTypeAndNum[key]?.length;
        }
      });
    }

    dispatch(updateRoomPriceStructure(newLocalRoomPriceStructure));
    dispatch(updateTempSelectedRoomWTypeAndNum(selectedRoomWTypeAndNum));
    dispatch(updateShowRoomNumberModal());
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          margin: 0,
          padding: 2,
        },
      }}
    >
      <Modal
        title={
          <div className="w-[100%] flex justify-between items-center mr-3">
            <div className="font-outfit font-[600] ">Select Room</div>{" "}
            <div className="flex gap-2 items-center">
              <div className="w-[full]  bottom-[25%] left-[40%] bg-[#FFF] flex justify-center">
                <button
                  className="border-[#2F80ED] border-[1px] text-[#2F80ED]   text-[1.125rem] font-[400] 
                  leading-[1.6875rem]  font-outfit  rounded-[0.625rem] cursor-pointer flex justify-center items-center p-2 px-3"
                  onClick={handleApplyChanges}
                >
                  Apply Changes
                </button>
              </div>
              <CloseOutlined
                onClick={handleCancel}
                className=" customCloseIconContainer"
              />
            </div>
          </div>
        }
        closeIcon={null}
        open={showRoomNumberModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={null}
        width={"45rem"}
        style={{ height: "65vh", overflowY: "auto" }}
        // closeIcon={<img src={CloseIcon} alt="" />}
      >
        <div className=" overflow-y-auto">
          <div className="flex flex-col gap-3  items-start justify-center  text-center">
            {updatedRoomAvailability?.map((val, index) => {
              return (
                <div key={index} className="">
                  <div className="flex gap-2 items-center">
                    <h1 className="relative font-[500] font-[Poppins] text-[1rem] text-[#000000b3]">
                      {val.name}{" "}
                    </h1>
                    <p className="font-[300] text-[0.75rem] font-[Poppins] text-[#000000b3]">
                      Select any {val.availability} room
                    </p>
                  </div>

                  <div
                    className="flex flex-wrap"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {val.rooms?.map((item, index) => {
                      return tempSelectedRoomWTypeAndNum[val.name]
                        ?.map((pd) => pd.name)
                        ?.indexOf(item.name) > -1 ? (
                        <div
                          onClick={() =>
                            handleSelectedRoomChange(
                              true,
                              val.name,
                              item.name,
                              item
                            )
                          }
                          key={index}
                          className=""
                        >
                          <div className="border-[1px] text-white py-[3px] px-2 rounded-lg bg-primary m-2 cursor-pointer">
                            {item.name}
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            handleSelectedRoomChange(
                              false,
                              val.name,
                              item.name,
                              item
                            )
                          }
                          key={index}
                          className=""
                        >
                          <div className="border-[1px] bg-[#F9F9F9] py-[3px] px-2 rounded-lg text-[gray] m-2 cursor-pointer ">
                            {item.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default Room;
