import { useDispatch } from "react-redux";

import { Modal, Button } from "antd";

const ConfirmationModal = ({
  isOpen,
  toggleModalVisibility,
  disabled,
  onConfirm,
  content,
}) => {
  const dispatch = useDispatch();
  return (
    <Modal
      sx={{ zIndex: 100000 }}
      zIndex={100000}
      open={isOpen}
      centered
      title=""
      width="auto"
      styles={{ mask: { backdropFilter: "blur(2px)" } }}
      onCancel={() => dispatch(toggleModalVisibility())}
      footer={
        <div className="w-full flex gap-2 justify-center mt-5">
          <Button
            className="text-[1.1rem] w-[11rem] h-[3rem] border-[#D0D5DD] text-[#808080] rounded-[0.5rem] font-[Poppins] font-[600]"
            onClick={() => dispatch(toggleModalVisibility())}
          >
            No
          </Button>
          <Button
            className="text-[#FFF] text-[1.1rem] rounded-xl bg-[#F36960] w-[11rem] h-[3rem]"
            onClick={onConfirm}
            disabled={disabled || false}
          >
            <div>Yes</div>
          </Button>
        </div>
      }
    >
      <div className="font-nunito text-[1.125rem] font-[600] text-[#4D4D4D]">
        {content.heading}
      </div>
      <div className="text-[#808080] font-nunito text-[0.875rem] font-[400]">
        {content.warning}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
