import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updatesShowingAddTableModel } from "../../../../reducer/posReducer/posSettingReducer";
import { Button, Modal, message } from "antd";
import { createTables } from "../../../../reducer/posReducer/restaurant";
import { TranslateText } from "../../../../utils/translation";

const AddTableModal = () => {
  const dispatch = useDispatch();
  const { hotelDetails } = useSelector((store) => store.login);
  const [loading, setLoading] = useState(false);
  const { visibleAddTableModal } = useSelector((store) => store.posSetting);
  const {
    loadingTableData,
    allTables: allTablesData,
    tableDataApiError: tableApiError,
  } = useSelector((store) => store.pos);

  const { activePosId } = useSelector((store) => store.posSetting);

  const [addTable, setAddTable] = useState({});

  const getData = (e) => {
    setAddTable({
      ...addTable,
      [e.target.name]: e.target.value,
    });
  };

  const handleOk = async (e) => {
    let isTableExist =
      allTablesData?.map((item) => item.name)?.indexOf(addTable?.name) === -1;

    if (isTableExist) {
      setLoading(true);
      const payload = {
        hotelId: hotelDetails?.id,
        name: addTable && addTable?.name,
        posId: activePosId,
      };
      setTimeout(async () => {
        setLoading(false);
        e.preventDefault();

        let response = await dispatch(createTables(payload));

        if (response?.payload) {
          dispatch(
            updatesShowingAddTableModel({
              modal: false,
            })
          );
          setAddTable({ ...addTable, name: "" });
        }
      }, 1000);
    } else {
      message.info(`Table ${addTable?.name} is already present!`);
    }
  };

  return (
    <>
      <Modal
        title="Add Table"
        width={300}
        open={visibleAddTableModal}
        onOk={handleOk}
        onCancel={() =>
          dispatch(
            updatesShowingAddTableModel({
              modal: false,
            })
          )
        }
        footer={[
          <Button
            key="back"
            onClick={() =>
              dispatch(
                updatesShowingAddTableModel({
                  modal: false,
                })
              )
            }
            className="border border-red-400 text-red-400 hover:border-red-500 hover:text-red-500 w-[5rem]"
          >
            
            <TranslateText textKey={"cancel"} namespace="pos" />
            </Button>,
          <Button
            key="submit"
            type="primary"
            // type="primary"
            loading={loading}
            onClick={handleOk}
            className="bg-blue-400 w-[5rem]"
          >
            
            <TranslateText textKey={"save"} namespace="pos" />

          </Button>,
        ]}
      >
        <div className="flex items-center mb-6">
          <div className="">
            <label className="block text-gray-500  mb-1 pr-4">
            <TranslateText textKey={"table"} namespace="pos" />

            </label>
          </div>
          <div className="">
            <input
              required
              className="bg-gray-100 appearance-none border-[1px] border-gray-300 rounded w-full py-2 px-4 text-gray-700 
                              leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              placeholder="Enter Table name"
              type="text"
              name={"name"}
              value={addTable["name"]}
              onChange={getData}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddTableModal;
