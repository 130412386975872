import { authedAxios } from "../utils/customHttpHeader";

const hotelDetails = async (payload = null, endpoint = null, token = null) => {
  const response = await authedAxios().get(endpoint);
  return response;
};

const createhotel = async (payload) => {
  try {
    const response = await authedAxios().post("/hotels", payload);

    return response.data;
  } catch (error) {
    return null;
  }
};

const updatehotel = async (payload) => {
  const response = await authedAxios().put(
    `/hotels/${payload.hotelId}`,
    payload.body
  );
  return response;
};

// it will call after login , if by any change login get fails then call the hotel api and fetch hotel data if hotels there
const hotel = async (payload = null, endpoint = null, token = null) => {
  const response = await authedAxios(token).get(endpoint);
  return response.data;
};

const getTables = async (payload) => {
  let url = "tables" + `?hotelId=${payload.hotelId}&posId=${payload.posId}`;
  const response = await authedAxios().get(url);
  return response;
};

const setNightAudit = async (payload) => {
  const response = await authedAxios().post(
    `/report/completeNightAudit?hotelId=${payload.hotelId}`
  );
  return response;
};

const getNightAuditDate = async (payload) => {
  const response = await authedAxios().get(
    `/night-audit/current-date?hotelId=${payload.hotelId}`
  );
  return response;
};

const hotelContentDetails = async (
  payload = null,
  endpoint = null,
  token = null
) => {
  let queryString = `${endpoint}?hotelId=${payload.hotelId}`;
  const response = await authedAxios().get(queryString);
  return response;
};

const updateHtlContent = async (
  payload = null,
  endpoint = null,
  token = null
) => {
  let queryString = `${endpoint}/${payload?.id}`;
  const response = await authedAxios().put(queryString, payload.body);
  return response;
};

const postHotelContent = async (
  payload = null,
  endpoint = null,
  token = null
) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

const postOccupancyRate = async (
  payload = null,
  endpoint = null,
  token = null
) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

const postResetDynamicPricingService = async (endpoint, payload) => {
  let url = `${endpoint}/${payload}/resetDynamicPricing`;
  const response = await authedAxios().post(url);
  return response;
};

export {
  hotelDetails,
  hotel,
  getTables,
  createhotel,
  updatehotel,
  setNightAudit,
  hotelContentDetails,
  updateHtlContent,
  postHotelContent,
  postResetDynamicPricingService,
  postOccupancyRate,
  getNightAuditDate,
};
