import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllOrders } from "../../../../reducer/orderReducer";
import { TranslateText } from "../../../../utils/translation";
const DeleteOrderModal = ({ deletePopUp, setDeletePopUp }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const { data: order, invoicePostData } = useSelector((store) => store.order);
  const { hotelDetails } = useSelector((store) => store.login);
  const dispatch = useDispatch();

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setDeletePopUp(false);
      setConfirmLoading(false);
      let payload = {
        invoiceId: invoicePostData?.id,
        orders: order,
        hotelId: hotelDetails?.id,
      };
      dispatch(deleteAllOrders(payload));
      setDeletePopUp(false);
    }, 2000);
  };
  const handleCancel = () => {
    setDeletePopUp(false);
  };
  return (
    <>
      <Modal
        title="Are you sure"
        open={deletePopUp}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Yes"
        footer={[
          <Button onClick={handleCancel} key="1">
            
            <TranslateText textKey={"cancel"} namespace="pos" />

          </Button>,
          <Button
            key="2"
            onClick={handleOk}
            className="border border-red-500 hover:border-red-500 bg-red-500 text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:bg-red-400 focus:ring-opacity-50"
          >
            
            <TranslateText textKey={"yes"} namespace="pos" />

          </Button>,
        ]}
      >
        <p>
        <TranslateText textKey={"you_want_to_clear_all_orders"} namespace="pos" />
        </p>
      </Modal>
    </>
  );
};
export default DeleteOrderModal;
