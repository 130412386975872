import { authedAxios } from "../utils/customHttpHeader";

const getExpenseService = async (payload, url) => {
  let endpoint = url + "?hotelId=" + payload?.hotelId;

  if (Boolean(payload?.partyId)) {
    endpoint += "&partyId=" + payload?.partyId;
  }
  if (payload.startDate) endpoint += `&startDate=${payload.startDate}`;
  if (payload.endDate) endpoint += `&endDate=${payload.endDate}`;
  if (payload.dueStartDate) endpoint += `&dueStartDate=${payload.dueStartDate}`;
  if (payload.dueEndDate) endpoint += `&dueEndDate=${payload.dueEndDate}`;
  if (Boolean(payload?.pageNumber)) {
    endpoint += "&pageNumber=" + payload?.pageNumber;
  }
  if (Boolean(payload?.pageSize)) {
    endpoint += "&pageSize=" + payload?.pageSize;
  }
  if (Boolean(payload?.searchText)) {
    endpoint += "&searchText=" + payload?.searchText;
  }

  const response = await authedAxios().get(endpoint);
  return response;
};

const postExpenseService = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

const deleteMultipleExpenseService = async (payload, url) => {
  let endpoint = url + "?" + "hotelId=" + payload?.hotelId;
  endpoint += "&expenseIds=" + payload?.expenseIds.toString();

  const response = await authedAxios().delete(endpoint);
  return response;
};

const updateMultipleExpenseService = async (payload, endpoint) => {
  const response = await authedAxios().put(endpoint, payload);
  return response;
};

const putUpdateExpense = async (payload, endpoint) => {
  const response = await authedAxios().put(endpoint, payload);
  return response;
};

export {
  getExpenseService,
  postExpenseService,
  deleteMultipleExpenseService,
  updateMultipleExpenseService,
  putUpdateExpense,
};
