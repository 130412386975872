import React, { useEffect, useState } from "react";
import { BOOKING_DETAILS_TIME_FORMET } from "../../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { message, Progress } from "antd";
import Room from "../Modal/Room";
import { TranslateText } from "../../../utils/translation";

import {
  updateDisplayIdDrawer,
  updateDisplayOTARoomModal,
  updateDisplayRoomDrawer,
  updateShowPrimaryGuestDrawer,
} from "../../../reducer/bookingReducer";
import PrimaryGuest from "./PrimaryGuest/PrimaryGuest";
import {
  guestLength,
  roomCountArrayObject,
  totalGuestNumber,
  totalRoomsArray,
} from "../../../utils/bookingDetailsHelper";
import { guestObj } from "../DrawerPages/NewIdDrawer";
import moment from "moment";

const CheckinDetails = ({ bookingType }) => {
  const {
    loadingDetails,
    bookingDetails,
    showRoomNumberModal,
    nightsCount,
    showMainBookingDetailsDrawer,
  } = useSelector((store) => store.booking);
  const { hotelDetails, isAllHotelsSelected } = useSelector(
    (store) => store.login
  );
  const allowEditingAfterCheckout = hotelDetails?.allowEditingAfterCheckout;
  const dispatch = useDispatch();
  const { response } = useSelector((store) => store.rooms); // get room list

  const [primaryGuestArray, setPrimaryGuestArray] = useState([]);
  const { editBookingAllowed } = useSelector((store) => store.usersRestriction);

  const roomCountArray = roomCountArrayObject(bookingDetails, response);

  const totalRooms = totalRoomsArray(roomCountArray);

  const totalGuest = totalGuestNumber(
    bookingDetails?.adults,
    bookingDetails?.children
  );
  const guestIdLength = guestLength(bookingDetails, totalGuest);

  const [roomToGuest, setRoomToGuest] = useState({});
  const [total_Guest, setTotalGuest] = useState(0);
  const [guestId_Length, setGuestIdLength] = useState(0);

  useEffect(() => {
    if (
      bookingDetails &&
      bookingDetails.hasOwnProperty("roomIdByGuestNameIdMap")
    ) {
      const updatedRoomToGuest = {};
      Object.entries(bookingDetails?.roomIdByGuestNameIdMap || {})
        ?.sort((a, b) => a[0].localeCompare(b[0]))
        ?.forEach((room) => {
          const [id, arr] = room;
          updatedRoomToGuest[id] = arr?.length ? arr : [guestObj];
        });
      setRoomToGuest(updatedRoomToGuest);
    }
  }, [bookingDetails]);

  useEffect(() => {
    let updatedTotalGuest = 0;
    let updatedGuestIdLength = 0;

    Object.values(roomToGuest)?.forEach((guestsArray) => {
      // to calculate total number of guests
      updatedTotalGuest += 1;
      if (Array.isArray(guestsArray) && guestsArray?.length > 1) {
        updatedTotalGuest += guestsArray?.length - 1;
      }

      // to calculate number of guests whose ID is uploaded
      if (Array.isArray(guestsArray)) {
        guestsArray?.forEach((guest) => {
          const { idImages } = guest;
          if (Array.isArray(idImages) && idImages?.length) {
            updatedGuestIdLength += 1;
          }
        });
      }
    });

    setTotalGuest(updatedTotalGuest);
    setGuestIdLength(updatedGuestIdLength);
  }, [roomToGuest]);

  useEffect(() => {
    if (bookingDetails) {
      let primaryArray = Array.isArray(bookingDetails?.guests)
        ? bookingDetails?.guests?.filter((item) => Boolean(item))
        : [];
      setPrimaryGuestArray(primaryArray);
    }
  }, [bookingDetails]);

  const handleEditRoom = () => {
    if (!isBookingAlterationAllowed) return;

    if (isCmIdPresent) {
      message.destroy();
      dispatch(updateDisplayOTARoomModal());
    } else dispatch(updateDisplayRoomDrawer());
  };

  const isRoomWisePersonWise =
    hotelDetails?.hasOwnProperty("showRoomWisePersonWiseIdForGuests") &&
    Boolean(hotelDetails?.showRoomWisePersonWiseIdForGuests);

  function ProgressComponent() {
    if (isRoomWisePersonWise) {
      return (
        <Progress
          type="circle"
          percent={(guestId_Length / total_Guest) * 100}
          format={(percent) => `${guestId_Length} /${total_Guest}`}
          size="small"
          strokeWidth={12}
          strokeColor={
            (guestId_Length / total_Guest) * 100 < 50 ? "#F36960" : "#2F80ED"
          }
          className="text-[#D9D9D9] text-sm bg-transparent ml-2"
        />
      );
    } else {
      return (
        <Progress
          type="circle"
          percent={(guestIdLength / totalGuest) * 100}
          format={(percent) => `${guestIdLength} /${totalGuest}`}
          size="small"
          strokeWidth={12}
          strokeColor={"#2F80ED"}
          className="text-[#D9D9D9] text-sm bg-transparent ml-2"
        />
      );
    }
  }

  const handleIdDrawer = async () => dispatch(updateDisplayIdDrawer());

  let isCmIdPresent = Boolean(bookingDetails?.cmId);

  let isBookingAlterationAllowed = Boolean(
    editBookingAllowed &&
      (bookingType !== "completed" || allowEditingAfterCheckout) &&
      !isAllHotelsSelected
  );

  return (
    <div className="  no-scrollbar no-scrollbar::-webkit-scrollbar">
      {loadingDetails ? (
        <div className="flex items-center justify-center">
          <CircularProgress size={32} sx={{ color: "#007FFF" }} />
        </div>
      ) : (
        <div className="no-scrollbar::-webkit-scrollbar no-scrollbar flex flex-col gap-[30px]">
          <div className="p-4 border rounded-xl border-[#DCE2ED4D] flex flex-col gap-[16px]">
            <div className="flex justify-between items-center">
              <div className="font-outfit font-[500] uppercase text-[16px] leading-[22.68px] tracking-[1px] text-[#222B45]">
                Booking details
              </div>

              {isBookingAlterationAllowed ? (
                <div
                  onClick={handleEditRoom}
                  className="cursor-pointer font-outfit font-[500] text-[14px] leading-[20px] text-[#007FFF]"
                >
                  <TranslateText textKey={"edit_upgrade"} namespace="booking" />
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="flex flex-col">
              <div className="text-[12px] leading-[18px] tracking-[1px] font-[600] font-outfit text-[#8F9BB3] flex justify-between ">
                <p>Checkin</p>
                <p>Check Out</p>
              </div>

              <div
                className="flex justify-between  text-[16px] font-[500] font-outfit 
            leading-[28px] text-[#222B45] "
              >
                <div>
                  {" "}
                  {Boolean(bookingDetails?.checkIn)
                    ? moment(bookingDetails?.checkIn)?.format(
                        "ddd, DD MMM YYYY"
                      )
                    : ""}
                </div>

                <div className="flex justify-center items-center  w-[33%]">
                  <div className="w-[5rem] flex justify-center items-center h-[2rem]  rounded-lg bg-[#F4F4F4] text-[0.75rem] text-[#4D4D4D] font-[500] font-nunito ">
                    {nightsCount} Night
                  </div>
                </div>

                <div>
                  {" "}
                  {Boolean(bookingDetails?.checkOut)
                    ? moment(bookingDetails?.checkOut)?.format(
                        "ddd, DD MMM YYYY"
                      )
                    : ""}
                </div>
              </div>

              <div className="flex justify-between  items-center text-[12px] font-[400] text-[#222B45] font-outfit leading-[4s8px]">
                <p>
                  From {BOOKING_DETAILS_TIME_FORMET(bookingDetails?.checkIn)}
                </p>

                <p>
                  By {BOOKING_DETAILS_TIME_FORMET(bookingDetails?.checkOut)}
                </p>
              </div>
            </div>

            <hr className="w-[100%]  my-1 border-[#DCE2ED4D] " />

            <div className="flex justify-between items-center">
              <div className="flex flex-col items-start w-[33%]">
                <div className="flex flex-col items-start justify-center   ">
                  {roomCountArray?.map((room) => (
                    <div
                      key={room.type}
                      className="font-outfit text-[16px] font-[500] text-[#222B45]"
                    >
                      {room.type} &nbsp; X {room.count}
                    </div>
                  ))}
                </div>

                <Room open={showRoomNumberModal} />
              </div>

              <div className="flex  gap-2  w-[33%] justify-center">
                {totalRooms &&
                  totalRooms?.slice(0, 2)?.map((roomNum, index) => (
                    <div
                      key={index}
                      className="bg-[#F4F4F4] text-[#808080] p-2 rounded-md font-[600] text-[0.625rem] font-nunito"
                    >
                      {roomNum}
                    </div>
                  ))}

                {totalRooms?.length > 2 && (
                  <div
                    className="bg-[#F4F4F4] text-[#007FFF] p-2 rounded-md text-[0.625rem] font-[600] font-nunito cursor-pointer"
                    onClick={handleEditRoom}
                  >
                    + {totalRooms.length - 2} more
                  </div>
                )}
              </div>

              <div className="w-[33%]" />
            </div>
          </div>

          <div className="p-4 border rounded-xl border-[#DCE2ED4D] flex flex-col gap-[16px]">
            <div className="flex justify-between items-center">
              <div className="font-outfit font-[500] uppercase text-[16px] leading-[22.68px] tracking-[1px] text-[#222B45]">
                Guest Details
              </div>

              {editBookingAllowed && !isAllHotelsSelected && (
                <div
                  className="  text-[#2F80ED] font-outfit text-[14px] font-[400] flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    dispatch(
                      updateShowPrimaryGuestDrawer({
                        visible: true,
                        bookingId: showMainBookingDetailsDrawer.bookingId,
                        customerType: "new",
                        customerId: "",
                      })
                    )
                  }
                >
                  Add New Guest
                </div>
              )}
            </div>

            <div className="flex flex-col gap-2">
              {primaryGuestArray &&
                primaryGuestArray.map((item, index) => (
                  <PrimaryGuest
                    key={index}
                    id={item}
                    idx={index}
                    total={primaryGuestArray?.length}
                    bookingType={bookingType}
                  />
                ))}
            </div>

            <div className="flex justify-between items-center">
              <div
                className="flex gap-2 items-center w-[25%]"
                style={{ visibility: `${isAllHotelsSelected && "hidden"}` }}
              >
                {ProgressComponent()}
                <div className=" mt-[-5px] whitespace-nowrap">
                  <p className="text-[0.75rem] font-[500] font-nunito text-[#808080] whitespace-nowrap">
                    Other Guest{" "}
                    <span className="text-[#4D4D4D] text-[0.75rem] font-nunito font-[600]">
                      ({isRoomWisePersonWise ? total_Guest : totalGuest})
                    </span>
                  </p>

                  {isBookingAlterationAllowed ? (
                    <button
                      type="link"
                      className={` ${
                        editBookingAllowed
                          ? "cursor-pointer text-[#2F80ED]"
                          : "non-editable-text-color"
                      } flex justify-center items-center   font-nunito font-[400] text-[0.75rem]  whitespace-nowrap outline-none`}
                      onClick={() => handleIdDrawer()}
                    >
                      Upload ID & signatures
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CheckinDetails;
