import React from "react";
import { useDispatch } from "react-redux";
import { FaChevronRight } from "react-icons/fa";
import { updateShowAddonsDrawer } from "../../../reducer/bookingReducer";

const AddOnsContainer = () => {
  const dispatch = useDispatch();

  return (
    <div
      className="flex p-[16px] cursor-pointer justify-between items-center border-[1px] border-[#DCE2ED4D] rounded-[12px]"
      onClick={() => dispatch(updateShowAddonsDrawer(true))}
    >
      <div className="font-outfit font-[500] text-[18px] leading-[22.68px] text-[#222B45]">
        Add Add-ons
      </div>

      <FaChevronRight className="text-[20px]" />
    </div>
  );
};
export default AddOnsContainer;
