import * as React from "react";
import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";

import { useDispatch, useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";

import { updateDisplayDiscountDrawer } from "../../../../reducer/posReducer/restaurant";

import { message } from "antd";
import DiscountButton from "../DiscountButton";
import CustomTextField from "../../../../components/common/InputField/Input";
import { CircularProgress } from "@mui/material";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";

export default function ApplyDiscountDrawer({ discountInfo, itemPrice }) {
  const [loading, setLoading] = useState(false);
  const [discountAmount, setDiscountAmount] = useState();
  const [discountType, setDiscountType] = useState("percentage");

  const { displayDiscountDrawer } = useSelector((state) => state.pos);

  const dispatch = useDispatch();
  const handleDiscountTypeChange = (e) => setDiscountType(e);

  const { handleDiscount } = useAllPosFunctions();

  const handleDiscountValue = (value) => {
    // Parse the value to ensure it's a number
    let numericValue = parseFloat(value);
    numericValue = Boolean(numericValue) ? numericValue : 0;

    if (discountType === "percentage") {
      if (numericValue < 0) setDiscountAmount(0);
      else if (numericValue > 100) setDiscountAmount(100);
      else setDiscountAmount(numericValue);
    } else setDiscountAmount(numericValue);
  };

  const handleDiscountButton = () => {
    if (discountType !== "flat" && discountType !== "percentage") {
      message.info("Please Select Discount type");
      return;
    }

    handleDiscount(discountType, discountAmount, setLoading);
  };

  useEffect(() => {
    if (Boolean(discountInfo)) {
      setDiscountType(discountInfo?.type);
      setDiscountAmount(discountInfo?.value);
    }
  }, [displayDiscountDrawer]);

  useEffect(() => {
    if (Boolean(itemPrice && discountAmount)) {
      if (discountType === "flat") {
        let value = (itemPrice * discountAmount) / 100;
        setDiscountAmount(value);
      } else if (discountType === "percentage") {
        let discountPercent = (discountAmount * 100) / itemPrice;
        setDiscountAmount(discountPercent);
      }
    }
  }, [discountType]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "100%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",

          "& .MuiDrawer-root": {
            position: "absolute",
            marginleft: "auto",
            right: 0,
          },
          "& .MuiPaper-root": {
            position: "absolute",
            marginLeft: "auto",
          },
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={displayDiscountDrawer}
      CloseIcon={null}
      onClose={() => dispatch(updateDisplayDiscountDrawer(false))}
    >
      <div className="w-[100%] flex flex-col justify-center items-center bg-[#F2F4FC] ">
        <header className="flex  py-4 px-5 w-[100%] bg-[#FFF] justify-between items-center ">
          <h2 className="font-outfit font-[600] text-[1rem] text-[#000000]">
            Apply Discount
          </h2>

          <div className="flex gap-4 items-center">
            <div
              className="bg-[#4D4D4D] w-[16px] h-[16px] rounded-[50%] flex justify-center items-center "
              onClick={() => dispatch(updateDisplayDiscountDrawer(false))}
            >
              <IoCloseSharp className="text-[10px] text-[#FFF] " />
            </div>
          </div>
        </header>

        <main className=" w-[90%] mt-[2rem] mb-[7rem] bg-[#FFF] p-2 py-4 rounded-[12px]">
          <div className="mx-5  relative flex flex-col gap-5 justify-between">
            <div className="font-outfit font-[500] text-[16px] leading-[24px] text-[#000]">
              Select Discount type
            </div>

            <DiscountButton
              discountType={discountType}
              handleDiscountTypeChange={handleDiscountTypeChange}
            />

            <CustomTextField
              placeholder="Payment"
              label=""
              value={Boolean(discountAmount) ? Math.ceil(discountAmount) : ""}
              onChange={(value) => handleDiscountValue(value)}
              type="number"
              inputPadding={true}
              labelTextColor={"#8F9BB3"}
            />
          </div>
        </main>

        <footer className="bg-[#FFF] shadow-lg w-[100%] fixed bottom-0 py-4 flex justify-center shadow-mobile-footer items-center">
          {loading ? (
            <button
              className="bg-[#007FFF] text-[#FFF] w-[80%]  text-[16px] leading-[24px] font-outfit  
          h-[3rem] font-[500] flex justify-center items-center rounded-[8px]"
            >
              <CircularProgress size={16} color="inherit" />
            </button>
          ) : (
            <button
              className="bg-[#007FFF] text-[#FFF] w-[80%]  text-[16px] leading-[24px] font-outfit  
          h-[3rem] font-[500] flex justify-center items-center rounded-[8px]"
              onClick={handleDiscountButton}
            >
              Apply
            </button>
          )}
        </footer>
      </div>
    </Drawer>
  );
}
