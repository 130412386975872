import React from "react";
import { FaTimes } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineError } from "react-icons/md";

import { useSelector, useDispatch } from "react-redux";
import {
  refreshApiSuccessModalData,
  updateApiSuccessModal,
} from "../../reducer/channelManagerReducer";
import {
  CHANNEL_MANAGER_DATE_FORMAT,
  CURRENCY_FORMAT,
} from "../../utils/helper";
const ShowHistory = () => {
  const dispatch = useDispatch();
  const {
    apiSuccssModal,
    showAvailability,
    showRevisedRate,
    showRoomType,
    showCheckInDate,
    showCheckOutDate,
    showChannel,
    showChannelType,
    apiError,
  } = useSelector((store) => store.channelManager);

  return (
    <div
      className={`${
        !apiSuccssModal ? "hidden" : "block"
      } absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-20 h-screen w-full z-50 `}
    >
      <div
        className={`${
          !apiSuccssModal ? "-bottom-[10000px]" : "bottom-0"
        } top-0 bg-white shadow-lg w-[40%] fixed   right-0  h-[100%] p-10  rounded z-50`}
      >
        <FaTimes
          className="cursor-pointer"
          onClick={() =>
            dispatch(updateApiSuccessModal(), refreshApiSuccessModalData())
          }
        />
        {!apiError ? (
          <div className="text-center m-auto flex justify-center flex-col	">
            <div className="mt-[8rem]">
              <h1 className="text-2xl font-semibold">Changes Published!</h1>
              <IoMdCheckmarkCircleOutline className="text-7xl mt-5 text-center m-uato text-green-500 w-full" />
              <div className="flex justify-between w-[50%] m-auto mt-20">
                <h6 className="text-slate-400 text-1xl">Revised Rate</h6>
                <p>
                  {Boolean(showRevisedRate)
                    ? "Rs " + CURRENCY_FORMAT(+showRevisedRate)
                    : "No Change"}
                </p>
              </div>

              <div className="flex justify-between w-[50%] m-auto mt-3">
                <h6 className="text-slate-400 text-1xl">Availability</h6>
                <p>
                  {showAvailability !== null && showAvailability !== undefined
                    ? showAvailability
                    : "No Change"}
                </p>
              </div>

              <div className="flex justify-between w-[50%] m-auto mt-3">
                <h6 className="text-slate-400 text-1xl">Room type</h6>
                <p>{showRoomType}</p>
              </div>

              <div className="flex justify-between w-[50%] m-auto mt-3">
                <h6 className="text-slate-400 text-1xl">From</h6>
                <p>{CHANNEL_MANAGER_DATE_FORMAT(showCheckInDate)}</p>
              </div>

              <div className="flex justify-between w-[50%] m-auto mt-3">
                <h6 className="text-slate-400 text-1xl">To</h6>
                <p>{CHANNEL_MANAGER_DATE_FORMAT(showCheckOutDate)}</p>
              </div>
              <div>
                <hr className="my-5" />
                <p className=" text-slate-400">Published On</p>
                {showChannelType === "all" ? (
                  <h6 className="font-semibold mt-3 text-2xl">All Channels</h6>
                ) : (
                  <div>
                    {showChannel.map((item) => {
                      return (
                        <button
                          className="mt-2 mr-2 bg-slate-400 text-white font-bold py-2 px-4 rounded"
                          key={item}
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center m-auto 	">
            <div className="mt-[8rem]">
              <h1 className="text-2xl font-semibold">Error!</h1>
              <MdOutlineError className="text-7xl mt-5 text-center m-uato text-red-500 w-full" />
              <div className="mt-2">
                <h1 className="text-center text-1xl text-red-500 ">
                  Opps, Something went wrong! Please try again later
                </h1>
              </div>

              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowHistory;
