import React from "react";
import moment from "moment";
import {
  FOLIO_FORMAT,
  INVOICE_DATE_AND_TIME_FORMAT,
} from "../../../utils/helper";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

import styles from "./styles"; // Import your styles object
import mapICON from "../../../assetsV2/invoice/map.png";
import phoneICON from "../../../assetsV2/invoice/phone.png";
import siteICON from "../../../assetsV2/invoice/site.png";
import emailICON from "../../../assetsV2/invoice/email.png";

// Invoice Document Component
const StatementDocument = (props) => {
  const invoiceFilter = props?.invoiceFilter;
  const hotelDetails = props?.hotelDetails;
  const bankDetails = props?.hotelContentDetails?.bankDetails;

  const cancellationPolicyArray = Array.isArray(
    props?.hotelContentDetails?.cancellationPoliciesText
  )
    ? props?.hotelContentDetails?.cancellationPoliciesText?.slice(0, 3)
    : [];

  const showBankDataInInvoice = Boolean(hotelDetails?.showBankDetailsInInvoice);

  const invoiceId = props?.invoiceId;

  const response = props?.response;

  const customerObject = props?.customerDetails;
  const bookingObject = props?.bookingDetails;
  const paymentArray = Array.isArray(props?.paymentDetails)
    ? props?.paymentDetails
    : [];

  const invoiceArray = props?.invoiceDetails;
  const roomDetails = props?.roomDetails;

  const hotelInformation = [
    {
      title: Boolean(hotelDetails && hotelDetails?.name)
        ? hotelDetails?.name
        : null,
      Logo: hotelDetails?.logo,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.address)
        ? hotelDetails?.address
        : null,
      Logo: mapICON,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.contactNumber)
        ? hotelDetails?.contactNumber
        : null,
      Logo: phoneICON,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.emailAddress)
        ? hotelDetails?.emailAddress
        : null,
      Logo: siteICON,
    },
    {
      title: Boolean(hotelDetails && hotelDetails?.website)
        ? hotelDetails?.website
        : null,
      Logo: emailICON,
    },
  ];

  const statementTitleInformation = [
    {
      title: "Statement",
    },
    {
      title: Boolean(invoiceId) ? invoiceId : "",
    },
    {
      title: Boolean(hotelDetails?.gstIN)
        ? `GSTIN: ${hotelDetails?.gstIN}`
        : "",
    },
  ];

  const bankSection = [
    {
      title: "Account Holder Name",
      value: Boolean(bankDetails?.accountHolderName)
        ? bankDetails?.accountHolderName?.toUpperCase()
        : null,
    },
    {
      title: "Bank Account Number",
      value: Boolean(bankDetails?.accountNumber)
        ? bankDetails?.accountNumber
        : null,
    },
    {
      title: "Bank Name",
      value: Boolean(bankDetails?.bankName)
        ? bankDetails?.bankName?.toUpperCase()
        : null,
    },
    {
      title: "IFSC Code",
      value: Boolean(bankDetails?.ifscCode)
        ? bankDetails?.ifscCode?.toUpperCase()
        : null,
    },
  ];

  const customerSection = [
    {
      title: "Name",
      value: Boolean(customerObject?.name) ? customerObject?.name : null,
    },
    {
      title: "Phone No",
      value: Boolean(customerObject?.phone) ? customerObject?.phone : null,
    },
    {
      title: "Email",
      value: Boolean(customerObject?.email) ? customerObject?.email : null,
    },
    {
      title: "Company Name",
      value: Boolean(customerObject?.company) ? customerObject?.company : null,
    },
    {
      title: "GSTIN",
      value: Boolean(customerObject?.gstIN) ? customerObject?.gstIN : null,
    },
    {
      title: "Address ",
      value: Boolean(customerObject?.address) ? customerObject?.address : null,
    },
  ];

  const priceBreakupHead = [
    { title: "Date" },
    { title: "BILL/RECEIPT" },
    { title: "DESCRIPTION" },
    { title: "DEBBIT" },
    { title: "CREDIT" },
  ];

  let bookingSection = Boolean(
    props?.bookingDetails && Object?.keys(props?.bookingDetails)?.length > 0
  )
    ? [
        {
          title: "Room No / Plan",
          value: Boolean(roomDetails)
            ? `${roomDetails?.name} X ${roomDetails?.type} / ${bookingObject?.mealPlan}`
            : null,
        },
        {
          title: "Adult-Child",
          value: Boolean(bookingObject?.adults)
            ? `${bookingObject?.adults} - ${bookingObject?.children} `
            : null,
        },
        {
          title: "Check-in/Check-out",
          value: Boolean(bookingObject?.checkIn && bookingObject?.checkOut)
            ? `${moment(bookingObject?.checkIn)?.format(
                "D-MM-YYYY"
              )} | ${moment(bookingObject?.checkOut)?.format("D-MM-YYYY")}`
            : null,
        },
        {
          title: "Booking Source",
          value: Boolean(bookingObject?.source) ? bookingObject?.source : null,
        },
      ]
    : [];

  let combinedPriceBreakup = [];

  let combinedArray =
    invoiceFilter == "all"
      ? [...invoiceArray, ...paymentArray]
      : [...invoiceArray];

  const combinedFilteredArray =
    invoiceFilter == "roomBill"
      ? combinedArray?.filter((item) => item?.invoiceType == "roomBill")
      : invoiceFilter == "pos"
      ? combinedArray?.filter((item) => item?.invoiceType !== "roomBill")
      : combinedArray;

  combinedArray.sort((a, b) => {
    const dateA = a.createdAt || a.updatedAt || a.invoiceBilledDate || 0;
    const dateB = b.createdAt || a.updatedAt || b.invoiceBilledDate || 0;
    return dateA - dateB;
  });

  if (invoiceFilter == "all") {
    combinedFilteredArray?.forEach((mainObj) => {
      // Check weather array is invoice or payment

      if (
        !mainObj?.hasOwnProperty("amount") &&
        !mainObj?.hasOwnProperty("paymentId") &&
        !mainObj?.hasOwnProperty("paymentNumber") &&
        mainObj?.hasOwnProperty("invoiceType")
      ) {
        let invoice = mainObj;

        let posName =
          invoice?.invoiceType === "pos"
            ? response
                ?.filter((Obj) => Obj?.id === invoice?.posId)?.[0]
                ?.name?.substring(0, 15)
            : "";
        let obj = {
          date: Boolean(invoice?.invoiceBilledDate)
            ? INVOICE_DATE_AND_TIME_FORMAT(invoice?.invoiceBilledDate)
                .formattedDate
            : "-",
          billVoucher: Boolean(invoice?.invoiceId)
            ? invoice?.invoiceId
            : "Tentative",
          description: Boolean(invoice?.invoiceType)
            ? Boolean(posName)
              ? `${invoice?.invoiceType?.toUpperCase()} (${posName})`
              : `${invoice?.invoiceType?.toUpperCase()}`
            : "-",
          debit: Boolean(invoice?.total) ? invoice?.total?.toFixed(2) : "-",
          credit: "N/A",
        };
        combinedPriceBreakup?.push(obj);
      } else {
        let payment = mainObj;
        let obj = {
          date: Boolean(payment.createdAt)
            ? INVOICE_DATE_AND_TIME_FORMAT(payment?.createdAt).formattedDate
            : "-",
          billVoucher: payment?.paymentId,
          description: Boolean(payment?.amount < 0)
            ? "Refund"
            : Boolean(payment?.method)
            ? `${payment?.method?.toUpperCase()}  ${
                Boolean(payment?.transactionNo)
                  ? `(${payment?.transactionNo})`
                  : ""
              } `
            : "-",
          debit: payment?.amount < 0 ? -payment?.amount : "N/A",
          credit: payment?.amount < 0 ? "N/A" : payment?.amount,
        };
        combinedPriceBreakup?.push(obj);
      }
    });
  } else {
    combinedFilteredArray?.forEach((mainObj) => {
      // Check weather array is invoice or payment

      let invoice = mainObj;

      let posName =
        invoice?.invoiceType === "pos"
          ? response
              ?.filter((Obj) => Obj?.id === invoice?.posId)?.[0]
              ?.name?.substring(0, 15)
          : "";

      let obj = {
        date: Boolean(invoice?.invoiceBilledDate)
          ? INVOICE_DATE_AND_TIME_FORMAT(invoice?.invoiceBilledDate)
              .formattedDate
          : "-",
        billVoucher: Boolean(invoice?.invoiceId)
          ? invoice?.invoiceId
          : "Tentative",
        description: Boolean(invoice?.invoiceType)
          ? Boolean(posName)
            ? `${invoice?.invoiceType?.toUpperCase()} (${posName})`
            : `${invoice?.invoiceType?.toUpperCase()}`
          : "-",
        debit: Boolean(invoice?.total) ? invoice?.total?.toFixed(2) : "-",
        credit: "N/A",
      };
      combinedPriceBreakup?.push(obj);
    });
  }

  let paymentReceived = 0;
  let totalBill = 0;
  let totalRefund = 0;
  let balanceDue = 0;

  Array.isArray(invoiceArray) &&
    invoiceArray
      ?.map((Obj) => Obj?.total)
      ?.filter((Obj) => Boolean(Obj))
      ?.map((total) => (totalBill += total));

  Array.isArray(paymentArray) &&
    paymentArray
      ?.map((obj) => obj.amount)
      ?.filter((Obj) => Obj < 0)
      .map((obj) => (totalRefund += -obj));

  Array.isArray(paymentArray) &&
    paymentArray
      ?.map((obj) => obj.amount)
      ?.filter((obj) => Boolean(obj) && obj > 0)
      ?.map((obj) => (paymentReceived += obj));

  balanceDue = totalBill - paymentReceived - totalRefund;

  let hotelLogo = hotelDetails?.logo;

  let posName = Boolean(props?.posObj) ? props?.posObj?.name : "";

  const { checkInTime, checkOutTime } = hotelDetails;

  const isTotalRefund = totalRefund ? styles.col5 : styles.col3;

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col6}>
              {hotelInformation?.map((Obj, index) => {
                return index === 0 ? (
                  <View style={styles.row}>
                    <View>
                      {Boolean(hotelLogo) ? (
                        <View style={styles.logoDiv}>
                          <Image src={hotelLogo} style={styles.logo} alt="" />
                        </View>
                      ) : (
                        <></>
                      )}
                    </View>
                    <View>
                      <Text style={styles.hotelName}>{hotelDetails?.name}</Text>
                    </View>
                  </View>
                ) : (
                  Boolean(Obj?.title) && (
                    <View
                      style={{ ...styles.row, marginTop: "8px" }}
                      key={Obj.title}
                    >
                      <View>
                        <Image src={Obj.Logo} style={styles.icon} alt="icon" />
                      </View>
                      <View>
                        <Text> {Obj?.title}</Text>
                      </View>
                    </View>
                  )
                );
              })}
            </View>

            <View style={[styles.col6, styles.headerRight]}>
              {statementTitleInformation?.map((Obj, index) => {
                return (
                  <View
                    style={
                      index === 0
                        ? styles.invoiceTitle
                        : index === 3
                        ? styles.titleDanger
                        : styles.defaultStyle
                    }
                  >
                    <Text>{Obj?.title}</Text>
                  </View>
                );
              })}

              {posName && (
                <Text className="mt-[10px] font-outfit text-[10px] leading-[10.08px] text-[#4d4d4d] bg-[#EBEBEB] px-[18px] py-[6px]">{`POS - ${posName}`}</Text>
              )}
            </View>
          </View>

          <View
            style={{
              ...styles.row,
              marginTop: "20px 0px",
              justifyContent: "flex-end",
            }}
          >
            <View
              style={{
                ...isTotalRefund,
                justifyContent: "flex-end",
              }}
            >
              <View
                style={{
                  ...styles.row,
                  display: "flex",
                  backgroundColor: "#7B8794",
                  color: "#fff",
                  padding: "4px",
                  textAlign: "center",
                  margin: "auto",
                  justifyContent: "center",
                }}
              >
                <View>
                  <Text
                    style={{
                      textAlign: "center",
                    }}
                  >
                    TOTAL
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {FOLIO_FORMAT(totalBill)}
                  </Text>
                </View>
                <View
                  style={{
                    height: "20px",
                    width: "2px",
                    backgroundColor: "#fff",
                    margin: "0 8px 0",
                  }}
                />

                <View>
                  <Text
                    style={{
                      textAlign: "center",
                    }}
                  >
                    PAID
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {FOLIO_FORMAT(paymentReceived)}
                  </Text>
                </View>

                <View
                  style={{
                    height: "20px",
                    width: "2px",
                    backgroundColor: "#fff",
                    margin: "0 8px 0",
                  }}
                />

                {totalRefund && (
                  <View
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                      }}
                    >
                      REFUND
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {FOLIO_FORMAT(totalRefund)}
                    </Text>
                  </View>
                )}

                {totalRefund && (
                  <View
                    style={{
                      height: "20px",
                      width: "2px",
                      backgroundColor: "#fff",
                      margin: "0 8px 0",
                    }}
                  />
                )}

                <View
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                    }}
                  >
                    DUE
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {FOLIO_FORMAT(balanceDue)}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ ...styles.row, marginTop: "30px" }}>
            <View
              style={{
                ...styles.col2,
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Bill To :
              </Text>
            </View>
            <View
              style={{
                ...styles.col8,
                width: "100%",
                height: "1px",
                backgroundColor: "#EBEBEB",
                marginTop: "7px",
              }}
            ></View>
          </View>

          <View>
            <View
              style={{
                ...styles.row,
                marginTop: "10px",
              }}
            >
              <View style={styles.row}>
                <View style={styles.col6}>
                  {customerSection?.map((Obj, index) => {
                    return (
                      Boolean(Obj?.value) && (
                        <View style={{ ...styles.row, marginTop: "6px" }}>
                          <View style={styles.col5}>
                            <Text> {Obj?.title}</Text>
                          </View>
                          <View style={styles.col1}>
                            <Text> :</Text>
                          </View>
                          <View style={styles.col6}>
                            <Text> {Obj?.value}</Text>
                          </View>
                        </View>
                      )
                    );
                  })}
                </View>

                {bookingSection?.length > 0 && (
                  <View style={{ ...styles.col6 }}>
                    {bookingSection?.map((Obj, index) => {
                      return (
                        Boolean(Obj?.value) && (
                          <View style={{ ...styles.row, marginTop: "6px" }}>
                            <View style={styles.col5}>
                              <Text>{Obj?.title}</Text>
                            </View>
                            <View style={styles.col1}>
                              <Text>:</Text>
                            </View>
                            <View style={styles.col6}>
                              <Text>{Obj?.value}</Text>
                            </View>
                          </View>
                        )
                      );
                    })}
                  </View>
                )}
              </View>
            </View>
          </View>

          <View>
            <View
              style={{
                ...styles.row,
                marginTop: "20px",
                borderTop: "1px solid #717171",
                borderBottom: "1px solid #717171",
              }}
            >
              {priceBreakupHead?.map((Obj, idx) => {
                const dynamicStyle = styles.col4;
                return (
                  <View style={{ ...dynamicStyle, padding: "10px 0px" }}>
                    <Text>{Obj?.title}</Text>
                  </View>
                );
              })}
            </View>
            <View>
              {combinedPriceBreakup?.map((Obj, index) => {
                return (
                  <View
                    style={{
                      ...styles.row,
                      paddingTop: "10px",
                    }}
                  >
                    <View style={{ ...styles.col4, ...styles.row }}>
                      <Text
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        {index + 1}.
                      </Text>{" "}
                      <Text>{Obj?.date}</Text>
                    </View>

                    <View style={styles.col4}>
                      <Text>{Obj?.billVoucher}</Text>
                    </View>

                    <View style={styles.col4}>
                      <Text>{Obj?.description}</Text>
                    </View>
                    <View style={styles.col4}>
                      <Text>{Obj?.debit}</Text>
                    </View>
                    <View style={styles.col4}>
                      <Text>{Obj?.credit}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>

          {showBankDataInInvoice ? (
            <View
              style={{
                marginTop: "10px",
              }}
            >
              <View
                style={{
                  ...styles.row,
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <View
                  style={{
                    ...styles.col2,
                  }}
                >
                  <Text
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Bank Info :
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.col8,
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#EBEBEB",
                    marginTop: "7px",
                  }}
                ></View>
              </View>

              <View>
                {bankSection?.map((Obj, index) => {
                  return (
                    Boolean(Obj?.value) && (
                      <View style={{ ...styles.row, margin: "6px 0" }}>
                        <View style={styles.col3}>
                          <Text> {Obj?.title} </Text>
                        </View>
                        <View>
                          <Text> :</Text>
                        </View>
                        <View>
                          <Text>{Obj?.value}</Text>
                        </View>
                      </View>
                    )
                  );
                })}
              </View>

              <View
                style={{
                  marginTop: "10px",
                }}
              >
                {Boolean(props?.qrCodUrl) ? (
                  <Image src={props?.qrCodUrl} style={styles.qrImage} alt="" />
                ) : (
                  <> </>
                )}
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>

        <View style={styles.footer}>
          <View>
            <Text
              style={{
                fontSize: "10px",
              }}
            >
              Disclaimer: Checkin time {checkInTime} & Checkout time{" "}
              {checkOutTime} Noon | T&C Applied
            </Text>
          </View>

          <View style={styles.row}>
            <View style={styles.col8}>
              <Text
                style={{
                  textAlign: "center",
                  marginTop: "8px",
                  fontSize: "10px",
                }}
              >
                This is a computer generated Invoice, No Signature Needed
              </Text>
            </View>

            <View style={styles.col4}>
              <View
                style={{ ...styles.row, marginTop: "8px", fontSize: "10px" }}
              >
                <Text>Powered By revivo</Text>
                <Text
                  style={{
                    color: "#0A78FB",
                    marginLeft: "2px",
                  }}
                >
                  HOTEL-X
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default StatementDocument;
