import { authedAxios } from "../utils/customHttpHeader";

const updateTables = async (payload) => {
  let url = `tables/${payload.id}`;
  const response = await authedAxios().put(url, payload);
  return response;
};

const addTables = async (payload) => {
  const response = await authedAxios().post(`tables`, payload);
  return response;
};

const deleteTables = async (payload) => {
  const response = await authedAxios().delete(
    `tables/${payload?.id}?hotelId=${payload?.hotelId}`
  );
  return response;
};

export { updateTables, addTables, deleteTables };
