import React, { useRef, useState } from "react";
import { Button, Modal, Popconfirm, Popover } from "antd";
import { useSelector, useDispatch } from "react-redux";
import waiter from "../assets/icons/waiter.png";
import moment from "moment";

import { updateKotModal } from "../../../reducer/posReducer/restaurant";
import ItemCard from "./ItemCard";
import { deleteOrders } from "../../../reducer/orderReducer";
import { GET_ITEM_PRICE } from "../../../utils/posHelper";
import useAllPosFunctions from "../../../hooks/posCustomHook/useAllPosFunctions";
import { TranslateText } from "../../../utils/translation";
import GenerateKOT from "../components/newOrderRevamped/GenerateKOT";
import { useReactToPrint } from "react-to-print";

const KOTModal = ({ runningOrders, runningInvoice }) => {
  const { hotelDetails } = useSelector((store) => store.login);
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);
  const dispatch = useDispatch();

  const componentRef = useRef(null);

  const { loading, invoicePostData } = useSelector((store) => store.order);

  const { activePosType } = useSelector((store) => store.posSetting);
  const { selectedOrderItemCountMap, selectedOrderItem, kotModal } =
    useSelector((store) => store.pos);
  const { data: allItems } = useSelector((store) => store.getItems);

  const [printKOT, setPrintKOT] = useState(false);

  let itemCountMap = {};
  let selectedItemWithQuantity = [];
  let priceBreakUp = [];
  let selectedItemObject = {}; // selected item to get the price for service type

  Object.keys(selectedOrderItemCountMap)?.forEach((id) => {
    allItems?.forEach((item) => {
      if (item.id === id && selectedOrderItemCountMap[id].count !== 0) {
        selectedItemObject = item;

        selectedItemWithQuantity.push({
          ...item,
          quantity: selectedOrderItemCountMap[id].count,
        });

        itemCountMap[id] = {
          count: selectedOrderItemCountMap[id].count,
          instruction: Boolean(selectedOrderItemCountMap[id].instruction)
            ? selectedOrderItemCountMap[id].instruction
            : null,
        };

        let addOn = [];

        // calculate item addon price
        item.addOns?.forEach((addonItem) => {
          if (
            Boolean(selectedOrderItemCountMap[id]?.addOns?.[addonItem.name])
          ) {
            itemCountMap[id] = {
              ...itemCountMap[id],
              addOns: {
                [addonItem?.name]:
                  selectedOrderItemCountMap[id]?.addOns?.[addonItem.name],
              },
            };

            addOn.push({
              name: addonItem.name,
              price: addonItem.price,
              count: selectedOrderItemCountMap[id]?.addOns?.[addonItem.name],
            });
          }
        });

        priceBreakUp.push({
          id: item.id,
          type: item.name,
          count: +selectedOrderItemCountMap[id].count,
          rate: GET_ITEM_PRICE(
            tableOrderDetails.type,
            activePosType,
            selectedItemObject
          ),
          instruction: item.instruction,
          addOns: addOn.length > 0 ? addOn : null,
        });
      }
    });
  });

  const { handleKOTEdit } = useAllPosFunctions();
  const handleOk = () => {
    handleKOTEdit(selectedOrderItem, priceBreakUp, itemCountMap, handleCancel);
  };

  const handleCancel = () => dispatch(updateKotModal(false));

  const confirmDelete = (id, item = null) => {
    const payload = {
      isInvoiceDelete: runningOrders?.length > 1 ? false : true,
      orderId: id,
      deletedOrderObect: item,
      runningOrders: runningOrders,
      runningInvoice: runningInvoice,
      hotelId: hotelDetails?.id,
      status: item.status,
    };

    dispatch(deleteOrders(payload));

    handleCancel();
  };

  const content = (steward) => {
    return (
      <div>
        <hr />
        <ul className="mt-1">
          {steward?.map((pd) => {
            return <li className="capitalize">{pd}</li>;
          })}
        </ul>
      </div>
    );
  };

  const print = async () => {
    setPrintKOT(true);
    setTimeout(() => {
      printPDF();
      setPrintKOT(false);
    }, 0);
  };

  const printPDF = useReactToPrint({
    onAfterPrint: () => setPrintKOT(false),
    content: () => componentRef.current,
    documentTitle: "Kot",
  });

  const { numberOfPeople, stewardNames, createdAt, id, status } =
    selectedOrderItem || {};

  return (
    <Modal
      width={350}
      open={kotModal}
      title={
        <div className="flex gap-3 items-center font-outfit">
          <span> Table - {tableOrderDetails?.tableNumber} </span>
          <span
            className="text-primary cursor-pointer"
            onClick={() => {
              print();
            }}
          >
            Print
          </span>{" "}
        </div>
      }
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <>
          {invoicePostData?.status == "inProgress" ? (
            <>
              {status == "inProgress" ? (
                <>
                  <Popconfirm
                    title="Hold on"
                    description="Are you sure to delete this order?"
                    onConfirm={() => confirmDelete(id, selectedOrderItem)}
                    type="primary"
                    okType="danger"
                    okText="Yes"
                    cancelText="No"
                    className="border border-primary text-primary"
                  >
                    <Button danger>
                      <TranslateText textKey={"delete"} namespace="pos" />
                    </Button>
                  </Popconfirm>

                  <Button
                    key="link"
                    type="primary"
                    className="bg-primary text-white"
                    loading={loading}
                    onClick={handleOk}
                  >
                    <TranslateText textKey={"save"} namespace="pos" />
                  </Button>
                </>
              ) : status === "held" ? (
                <>
                  <Popconfirm
                    title="Hold on"
                    description="Are you sure to delete this order?"
                    onConfirm={() => confirmDelete(id, selectedOrderItem)}
                    okText="Yes"
                    cancelText="No"
                    className="border border-primary text-primary"
                  >
                    <Button danger>
                      <TranslateText textKey={"delete"} namespace="pos" />
                    </Button>
                  </Popconfirm>

                  <Button
                    key="link"
                    type="primary"
                    className="bg-primary text-white"
                    loading={loading}
                    onClick={handleOk}
                  >
                    <TranslateText textKey={"resume"} namespace="pos" />
                  </Button>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>,
      ]}
    >
      <div className="bg-[#FFF] z-50 w-full overflow-auto  rounded ">
        <div>
          <div className="flex justify-between">
            <div className="text-1xl text-semibold" />
            <p className="text-sm text-slate-200">
              {" "}
              {moment(Boolean(createdAt) ? createdAt : null).format(" h:mm a ")}
            </p>
          </div>

          <div className="flex justify-between mt-2 mb-2">
            <h1 className="text-xs text-slate-200">
              <TranslateText textKey={"for"} namespace="pos" />

              {numberOfPeople || 1}
              <TranslateText textKey={"people"} namespace="pos" />
            </h1>
            <div className="flex">
              {" "}
              <img className="h-4" src={waiter} alt="waiter" />
              <span className="text-xs ml-1">
                {stewardNames?.length > 0 ? stewardNames?.[0] : <></>}
                {stewardNames?.length > 1 ? (
                  <Popover
                    content={() => content(stewardNames)}
                    title="All assigned Steward"
                    trigger="click"
                  >
                    <>
                      <span className="text-blue-700 ml-1">
                        <TranslateText textKey={"show_all"} namespace="pos" />
                      </span>
                    </>
                  </Popover>
                ) : (
                  <></>
                )}{" "}
              </span>{" "}
            </div>
          </div>
          <hr />

          {selectedItemWithQuantity?.map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              status={status}
              handleIsAddOnVisible={(id, value) => () => {}}
            />
          ))}

          <div className="flex justify-between my-3 font-outfit font-[600]">
            <span className="font-[500]">
              <TranslateText textKey={"order"} namespace="pos" />-{" "}
            </span>{" "}
            {status}
          </div>

          {printKOT && (
            <GenerateKOT
              print={printKOT}
              val={componentRef}
              tableOrderDetails={tableOrderDetails}
              order={selectedOrderItem}
              latestOrder={selectedOrderItem}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
export default KOTModal;
