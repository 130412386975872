import { Button, Input, Select, Space } from "antd";
import React, { useState } from "react";
const categories = [
  { value: "Select Category", label: "Select Category", disabled: true },
  { value: "Main Course", label: "Main Course" },
  { value: "Starter", label: "Starter" },
  { value: "Beverages", label: "Beverages" },
  { value: "Desert", label: "Desert" },
];
const units = [
  { value: "Select Category", label: "Select Category", disabled: true },
  { value: "Plate", label: "Plate" },
  { value: "Piece", label: "Piece" },
  { value: "Packet", label: "Packet" },
];
const hotelId = localStorage.getItem("hotelid");

function AddItem() {
  const [itemData, setItemData] = useState({
    createdAt: new Date(),
    hotelId: `${hotelId}`,
    category: categories[0].label,
    unit: units[0].label,
    name: "",
    price: "",
    sku: "",
    stock: "",
    updatedAt: new Date(),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItemData((prevItemData) => ({
      ...prevItemData,
      [name]: value,
    }));
  };
  const handleCategoryChange = (value) => {
    setItemData((prevItemData) => ({
      ...prevItemData,
      category: value,
    }));
  };
  const handleUnitChange = (value) => {
    setItemData((prevItemData) => ({
      ...prevItemData,
      unit: value,
    }));
  };

  const handleSubmit = () => {
    //call api here
    setItemData({
      createdAt: new Date(),
      hotelId: `${hotelId}`,
      category: categories[0].label,
      unit: units[0].label,
      name: "",
      price: "",
      sku: "",
      stock: "",
      updatedAt: new Date(),
    });
  };

  return (
    <div className="flex flex-col gap-4 h-full">
      <h1 className="font-nunito text-[22px]">Add Item</h1>
      <div className="w-full flex flex-col gap-8 h-full">
        <div className="flex flex-col gap-4">
          <Input
            className="font-nunito"
            size="large"
            placeholder="Enter Item Name"
            onChange={handleChange}
            name="name"
            value={itemData.name}
          />
          <Select
            size="large"
            defaultValue={itemData.category}
            onChange={handleCategoryChange}
            className="w-full font-nunito font-normal"
            options={categories}
            name="category"
            value={itemData.category}
          />
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <h3>Pricing details</h3>
            <hr></hr>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span>Unit</span>
              <Select
                size="large"
                defaultValue={units[0].label}
                onChange={handleUnitChange}
                className="font-nunito w-[50%]"
                options={units}
                name="unit"
                value={itemData.unit}
              />
            </div>
            <div className="flex justify-between items-center">
              <span>SKU</span>
              <Input
                className="font-nunito w-[50%]"
                size="large"
                placeholder="sku"
                onChange={handleChange}
                name="sku"
                value={itemData.sku}
              />
            </div>
            <div className="flex justify-between items-center">
              <span>Stock</span>
              <Input
                className="w-[50%] font-nunito"
                size="large"
                placeholder="stock"
                onChange={handleChange}
                type="number"
                name="stock"
                value={itemData.stock}
              />
            </div>
            <div className="flex justify-between items-center">
              <span>Price</span>
              <Input
                className="font-nunito w-[50%]"
                size="large"
                placeholder="price"
                onChange={handleChange}
                type="number"
                name="price"
                value={itemData.price}
              />
            </div>
          </div>
        </div>
      </div>
      <Button
        size="large"
        className="bg-[#007FFF] h-[52px] text-[#ffffff] text-[18px] font-semibold"
        block
        onClick={handleSubmit}
      >
        SAVE
      </Button>
    </div>
  );
}

export default AddItem;
