import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addNewCategoryService,
  getCategoriesService,
  removeCategoryService,
  fetchCategories,
  addCategoryService,
} from "../../services/manageStocksServices/categories";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (payload) => {
    try {
      const response = await getCategoriesService(payload);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const addNewCategory = createAsyncThunk(
  "categories/addNewCategory",
  async (payload) => {
    try {
      const response = await addNewCategoryService(payload);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (payload) => {
    try {
      const response = await removeCategoryService(payload);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const getCategoriesAsync = createAsyncThunk(
  "getCategoriesAsync",
  async (payload) => {
    try {
      const response = await fetchCategories(payload);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const addCategory = createAsyncThunk(
  "categories/addCategory",
  async (payload) => {
    try {
      const response = await addCategoryService(payload);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: false,
  categoriesListData: [],
  selectedCategories: [],
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_1: () => initialState,

    removeCategories: (state) => {
      state.data = [];
    },
    setSelectedCategories: (state, action) => {
      if (state.selectedCategories.includes(action.payload)) {
        const updatedSelectedCategories = state.selectedCategories.filter(
          (selectedCategory) => selectedCategory !== action.payload
        );
        state.selectedCategories = updatedSelectedCategories;
      } else {
        state.selectedCategories = [
          ...state.selectedCategories,
          action.payload,
        ];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.data = Boolean(action.payload?.categories)
          ? [...state.data, ...action.payload?.categories]
          : [];
        state.error = false;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    builder
      .addCase(addNewCategory.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addNewCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = Boolean(action.payload?.categories)
          ? [...state.data, ...action.payload?.categories]
          : [];
        state.error = false;
      })
      .addCase(addNewCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    builder
      .addCase(deleteCategory.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [...state.data, ...action.payload?.categories];
        state.error = false;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    builder
      .addCase(getCategoriesAsync.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.categoriesListData = action.payload.categories;
        state.error = false;
      })
      .addCase(getCategoriesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    builder
      .addCase(addCategory.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.categoriesListData = action.payload.categories;
        state.error = false;
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { removeCategories, setSelectedCategories, CLEAR_REDUX_STORE_1 } =
  categoriesSlice.actions;
export default categoriesSlice.reducer;
