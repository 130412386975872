import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllTableReservations,
  createTableReservation,
} from "../services/reservation";
import { message } from "antd";

export const getTableReservation = createAsyncThunk(
  "order/getOrder",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getAllTableReservations(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const createTableReservations = createAsyncThunk(
  "order/createOrder",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await createTableReservation(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  createTableReservationLoading: false,
  createTableReservationError: null,
  createTableReservationData: {},

  loading: false,
  data: [],
  error: false,

  isReservationModalVisible: false, // open close reservation modal
};

const reservationReducer = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_37: () => initialState,

    updateReservationModalStatus: (state, action) => {
      state.isReservationModalVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTableReservations.pending, (state, action) => {
      state.createTableReservationLoading = true;
      state.createTableReservationError = false;
      state.createTableReservationData = {};
    });
    builder.addCase(createTableReservations.fulfilled, (state, action) => {
      message.success("Table Has been reserved..");
      state.createTableReservationLoading = false;
      state.createTableReservationError = false;
      state.createTableReservationData = action.payload;
      state.isReservationModalVisible = false;
    });
    builder.addCase(createTableReservations.rejected, (state, action) => {
      state.createTableReservationLoading = true;
      state.createTableReservationError = true;
      state.createTableReservationData = {};
    });

    builder.addCase(getTableReservation.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      state.error = false;
    });
    builder.addCase(getTableReservation.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    });
    builder.addCase(getTableReservation.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
  },
});

export const { CLEAR_REDUX_STORE_37, updateReservationModalStatus } =
  reservationReducer.actions;
export default reservationReducer.reducer;
