import { Button, Input, List, Table } from "antd";
import React, { useEffect, useState } from "react";
import ManageStocksWrapper from "../../wrapper/Index";
import { useDispatch, useSelector } from "react-redux";
import { latestDayFormatted } from "../../../../../utils/dates";
import { ArrowDownDropdown, FluentPreview } from "../../../../pos/assets/Icons";
import {
  createRecount,
  getRecount,
} from "../../../../../reducer/manageStocksReducer/recountReducer";
import { useNavigate } from "react-router-dom";

const MainContainer = ({ itemsData, handleKeyUp, handleItemStockChange }) => {
  const handleChange = (e, item) => {
    handleItemStockChange(e, item);
  };

  const handleKeyChange = (e, item) => {
    handleKeyUp(e, item);
  };

  return (
    <section className="h-full px-6 flex flex-col gap-3">
      <div className="w-full px-6 flex items-center justify-between">
        <div className="flex items-center w-[138px] h-[44px] ">
          All categories{" "}
          <span>
            <ArrowDownDropdown />
          </span>
        </div>
        <div>{latestDayFormatted}</div>
      </div>
      <div className="px-6 flex justify-between items-center font-nunito text-[16px] font-normal">
        <span>Item Name</span>
        <span>New</span>
      </div>
      <List
        dataSource={itemsData}
        size="large"
        className="shadow-custom-stocks"
        pagination={{
          showSizeChanger: false,
          position: "bottom",
          align: "center",
          pageSize: 9,
        }}
        renderItem={(item, index) => (
          <List.Item key={item.id} className={`mb-2 shadow-md`}>
            <div className="text-[#4F4F4F] text-[16px] font-normal font-nunito">
              {item.name}
            </div>
            <Input
              type="number"
              className="w-[15%]"
              placeholder={item?.stock}
              onChange={(e) => handleChange(e, item)}
              onKeyUp={(e) => handleKeyChange(e, item)}
            />
          </List.Item>
        )}
      />
    </section>
  );
};
const SideContainer = ({
  itemsData,
  recountedItems,
  handleUndoRecount,
  handleSaveRecount,
}) => {
  const dispatch = useDispatch();
  const [recounts, setRecounts] = useState([]);
  const columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Updated Count",
      dataIndex: "updatedCount",
      key: "updatedCount",
    },
    {
      title: "Previous Count",
      dataIndex: "previousCount",
      key: "previousCount",
    },
    {
      title: "Difference",
      dataIndex: "differenceCount",
      key: "differenceCount",
    },
  ];

  useEffect(() => {
    const newArr = recountedItems.map((item) => {
      const correspondentItem = itemsData.find(
        (itemsObj) => itemsObj._id === item._id
      );
      if (correspondentItem) {
        return {
          itemName: item.name,
          updatedCount: item.stock,
          previousCount: correspondentItem.stock,
          differenceCount: item.stock - correspondentItem.stock,
        };
      }
    });
    setRecounts(newArr);
  }, [recountedItems]);

  const handleSave = () => {
    const saveRecountData = {
      recounts: recounts,
      createedAt: new Date(),
      updatedAt: new Date(),
      hotelId: localStorage.getItem("hotelid"),
    };
    dispatch(createRecount(saveRecountData));
    handleSaveRecount();
  };

  return (
    <div className="flex flex-col justify-between h-full">
      {recounts.length !== 0 ? (
        <div className="flex-grow">
          {" "}
          <Table
            className="py-4"
            pagination={false}
            columns={columns}
            dataSource={recounts}
            size="small"
            rowKey={(record) => `${record.createedAt}`}
          />
        </div>
      ) : (
        <div className="flex flex-1 justify-center items-center">
          <FluentPreview />
        </div>
      )}
      <div className="flex justify-around">
        <Button
          disabled={recounts.length === 0}
          onClick={() => handleUndoRecount()}
          className={`${
            recounts.length !== 0
              ? "bg-[#FFFFFF] text-[#007FFF]"
              : "bg-[#ffffff] text-[#7E8082]"
          } text-[18px] w-[157px] h-[52px]`}
        >
          UNDO
        </Button>
        <Button
          onClick={handleSave}
          className={`${
            recounts.length !== 0
              ? "bg-[#007FFF] text-[#FFFFFF]"
              : "bg-[#7E8082] text-[#ffffff]"
          } text-[18px] w-[157px] h-[52px]`}
          disabled={recounts.length === 0}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

function StartRecount() {
  const dispatch = useDispatch();
  const itemsData = useSelector((state) => state.getItems.data);
  const [recountedItems, setRecountedItems] = useState([]);
  const [updatedStockItem, setUpdatedStockItem] = useState({});

  const handleItemStockChange = (e, item) => {
    setUpdatedStockItem({
      ...item,
      stock: e.target.value,
    });
  };

  const handleKeyUp = (e, current) => {
    if (e.keyCode === 13) {
      let currentItem = [...recountedItems];
      let newItems = currentItem?.find((item) => item.name === current.name);
      if (newItems) {
        newItems.stock = e.target.valueAsNumber;
        setRecountedItems([...currentItem]);
      } else {
        setRecountedItems((prev) => [
          ...prev,
          { ...current, stock: e.target.valueAsNumber },
        ]);
      }
    }
  };

  const handleUndoRecount = () => {
    setRecountedItems([]);
  };
  const handleSaveRecount = () => {
    setRecountedItems([]);
    dispatch(getRecount());
  };

  return (
    <ManageStocksWrapper
      headerName="Start Recount"
      previewTitle="Recount Published"
      leftSideContent={
        <MainContainer
          itemsData={itemsData}
          recountedItems={recountedItems}
          handleItemStockChange={handleItemStockChange}
          handleKeyUp={handleKeyUp}
          updatedStockItem={updatedStockItem}
        />
      }
      rightSideContent={
        <SideContainer
          itemsData={itemsData}
          recountedItems={recountedItems}
          handleUndoRecount={handleUndoRecount}
          handleSaveRecount={handleSaveRecount}
        />
      }
    ></ManageStocksWrapper>
  );
}

export default StartRecount;
