import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronDown } from "react-icons/fa";
import CustomTextField from "../../../../components/common/InputField/Input";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import {
  getCustomerByPhone,
  putCustomerById,
  saveCustomer,
} from "../../../../reducer/customerReducer";
import { POS_TYPE } from "../../../../utils/posHelper";

import {
  createTableOrder,
  updateCustomerDetails,
} from "../../../../reducer/posReducer/restaurant";
import useDebounce from "../../../../components/useHook/useDebounce";
import moment from "moment";
import StewardDrawer from "./StewardDrawer";
import AddStewardDrawer from "./AddStewardDrawer";

const CustomerTableDetails = ({ showTableDetails = true, mb = "8rem" }) => {
  const dispatch = useDispatch();

  const { response: posData, activePosId } = useSelector(
    (store) => store.posSetting
  );

  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);
  const { hotelDetails } = useSelector((store) => store.login);
  const [customer, setCustomer] = useState({});

  const [stewardDrawerVisible, setStewardDrawerVisible] = useState(false);
  const [addStewardDrawerVisible, setAddStewardDrawerVisible] = useState(false);

  const { customerDetails, selectedSteward } = useSelector(
    (store) => store.pos
  );

  const { activePosType } = useSelector((store) => store.posSetting);

  useEffect(() => {
    if (tableOrderDetails?.reservation) getReserveCustomer();

    if (hotelDetails?.id && tableOrderDetails?.customerNumber) {
      dispatch(
        getCustomerByPhone({
          hotelId: hotelDetails?.id,
          phone: tableOrderDetails?.customerNumber,
        })
      );
    }

    const data = {
      ...customerDetails,
      name: customerDetails?.name,
      contactDetails: customerDetails?.contactDetails,
      phoneValue:
        customerDetails?.contactDetails?.countryCode +
        customerDetails?.contactDetails?.phoneNumber,
      address: customerDetails?.address,
      day: Boolean(customerDetails?.dob)
        ? moment(customerDetails.dob).format("D")
        : null,
      month: Boolean(customerDetails?.dob)
        ? moment(customerDetails.dob).format("M")
        : null,
      year: Boolean(customerDetails?.dob)
        ? moment(customerDetails.dob).format("YYYY")
        : null,
    };

    setCustomer(data);
  }, []);

  const getReserveCustomer = async () => {
    if (hotelDetails?.id && tableOrderDetails?.customerNumber) {
      let response = await dispatch(
        getCustomerByPhone({
          hotelId: hotelDetails?.id,
          phone: tableOrderDetails?.customerNumber,
        })
      );
      if (response?.payload) setCustomer(response?.payload);
    }
  };

  useEffect(() => {
    if (Object?.keys(customerDetails)?.length > 0) {
      const data = {
        ...customerDetails,
        name: customerDetails?.name,
        contactDetails: customerDetails?.contactDetails,
        phoneValue:
          customerDetails?.contactDetails?.countryCode +
          customerDetails?.contactDetails?.phoneNumber,
        address: customerDetails?.address,
        day: Boolean(customerDetails?.dob)
          ? moment(customerDetails.dob).format("D")
          : null,
        month: Boolean(customerDetails?.dob)
          ? moment(customerDetails.dob).format("M")
          : null,
        year: Boolean(customerDetails?.dob)
          ? moment(customerDetails.dob).format("YYYY")
          : null,
      };

      setCustomer(data);
    }
  }, [customerDetails]);

  const handleNumOfPersonChange = (value) => {
    const payload = { ...tableOrderDetails, numberOfPeople: value };
    dispatch(createTableOrder(payload));
  };

  const handleCustomerDetails = async (name, value) => {
    setCustomer({ ...customer, [name]: value });
  };

  const handleCustomeByphoneDebounced = useDebounce(async (obj) => {
    if (obj?.phone) {
      let customerDetails = await dispatch(
        getCustomerByPhone({
          hotelId: hotelDetails?.id,
          phone: obj?.phone,
          countryCode: obj?.countryCode,
        })
      );

      // store customer details into pos customer
      if (Boolean(customerDetails?.payload?.name)) {
        dispatch(updateCustomerDetails(customerDetails?.payload));
        //  setIsCustomerDetailsVisible(false);
      } else {
        dispatch(updateCustomerDetails(customerDetails?.payload));
      }
    }
  }, 500); // Adjust delay as needed

  const submitCustomerDetails = async (e) => {
    e.preventDefault();

    const dateString = `${customer?.year}-${customer?.month}-${customer?.day}`;
    const dateInMilliSecond = moment(dateString).valueOf();
    const updatedDate = moment(dateInMilliSecond)?.format("YYYY-MM-DD");

    let payload = {
      ...customer,
      dob: Boolean(dateInMilliSecond) ? updatedDate : null,
      hotelId: hotelDetails.id,
    };

    if (customerDetails?.name) {
      // customer exist, update customer

      let customerUpdatedDetails = await dispatch(putCustomerById(payload));

      if (Boolean(customerUpdatedDetails?.payload)) {
        dispatch(updateCustomerDetails(customerUpdatedDetails?.payload));
        // message.success("Customer details successfully updated");
      }
    } else {
      // create customer
      let customer = await dispatch(saveCustomer(payload));
      if (Boolean(customer?.payload)) {
        dispatch(updateCustomerDetails(customer?.payload));
        // message.success("Customer details successfully saved");
      }
    }
  };

  return (
    <div
      className={`w-[95%] flex flex-col gap-3 items-start mx-auto  mb-[${mb}]  `}
    >
      {/* Table Details */}
      {showTableDetails ? (
        <div className="bg-[#FFF] w-[98%] flex flex-col gap-3  mx-auto rounded-[12px] p-3">
          <div className="upperPart flex justify-between items-center ">
            <div className="font-outfit font-[500] text-[14px] leading-[21px] text-[#222] w-[50%]">
              Number Of Person
            </div>
            <div className="w-[50%]">
              <CustomTextField
                placeholder="Name"
                isManadatory={false}
                value={tableOrderDetails.numberOfPeople}
                onChange={(value) => handleNumOfPersonChange(value)}
                type="text"
                inputPadding={true}
                labelTextColor={"#007FFF"}
              />
            </div>
          </div>

          {activePosType === POS_TYPE[0] && (
            <div
              className="bottomPart flex justify-between rounded-[8px] items-center p-3 border-[1px] border-[#EDF1F7] "
              onClick={() => setStewardDrawerVisible(true)}
            >
              <div className="font-outfit font-[400] text-[16px] leading-[24px] text-[#8F9BB3]">
                {" "}
                {Array.isArray(selectedSteward) && selectedSteward?.length > 0
                  ? selectedSteward?.[0]
                  : "Select Steward"}
              </div>
              <div>
                <FaChevronDown className="text-[#007FFF] text-[12px]" />
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}

      {/* Customer Details */}
      <div className="font-outfit font-[500] text-[16px] leading-[20.16px] text-[#222] my-2 pl-2">
        Customer Details
      </div>

      <div className="bg-[#FFF] w-[98%] flex flex-col gap-[16px]  mx-auto rounded-[12px] p-4">
        <div className="upperPart flex justify-between items-center w-[100%]  ">
          <PhoneInput
            defaultCountry="in"
            value={
              Boolean(customer["phoneValue"]) ? customer["phoneValue"] : ""
            }
            className="w-[100%]"
            onChange={(value, country) => {
              const parts = country.inputValue.split(" ");
              let countryCode = parts?.[0];

              const joinedString = parts
                ?.slice(1)
                ?.map((str) => str?.replace(/\D/g, "")) // Remove non-numeric characters
                ?.join("");

              setCustomer({
                ...customer,
                phoneNumber: joinedString,
                countryCode: countryCode,
                phoneValue: value,
                contactDetails: {
                  countryCode: countryCode,
                  phoneNumber: joinedString,
                },
              });

              handleCustomeByphoneDebounced({
                phone: joinedString,
                countryCode: countryCode,
              });
            }}
            required={true}
          />
        </div>

        <div className="flex flex-col gap-[6px]">
          <div className="font-outfit font-[400] text-[12px] leading-[24px] tracking-wider  text-[#8F9BB3]">
            {" "}
            GUEST NAME
          </div>

          <div className="w-[100%]">
            <CustomTextField
              placeholder="Name"
              isManadatory={false}
              value={Boolean(customer["name"]) ? customer["name"] : ""}
              onChange={(value) => handleCustomerDetails("name", value)}
              type="text"
              inputPadding={true}
              labelTextColor={"#007FFF"}
            />
          </div>
        </div>

        <div className="flex gap-3 w-full">
          <div className="flex flex-col gap-[6px] w-full">
            <div className="font-outfit font-[400] text-[12px] leading-[24px] tracking-wider  text-[#8F9BB3]">
              {" "}
              COMPANY NAME
            </div>

            <div className="w-[100%]">
              <CustomTextField
                placeholder="Company Name"
                isManadatory={false}
                value={Boolean(customer["company"]) ? customer["company"] : ""}
                onChange={(value) => handleCustomerDetails("company", value)}
                type="text"
                inputPadding={true}
                labelTextColor={"#007FFF"}
              />
            </div>
          </div>

          <div className="flex flex-col gap-[6px] w-full">
            <div className="font-outfit font-[400] text-[12px] leading-[24px] tracking-wider  text-[#8F9BB3]">
              {" "}
              GSTIN
            </div>

            <div className="w-[100%]">
              <CustomTextField
                placeholder="GSTIN"
                isManadatory={false}
                value={Boolean(customer["gstIN"]) ? customer["gstIN"] : ""}
                onChange={(value) => handleCustomerDetails("gstIN", value)}
                type="text"
                inputPadding={true}
                labelTextColor={"#007FFF"}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[6px]">
          <div className="font-outfit font-[400] text-[12px] leading-[24px] tracking-wider  text-[#8F9BB3] uppercase">
            {" "}
            Date of Birth
          </div>

          <div className="flex w-[100%] gap-3">
            <div className="w-[30%]">
              <CustomTextField
                placeholder="Day"
                isManadatory={false}
                value={Boolean(customer["day"]) ? customer["day"] : ""}
                onChange={(value) => handleCustomerDetails("day", value)}
                type="text"
                inputPadding={true}
                labelTextColor={"#007FFF"}
              />
            </div>
            <div className="w-[30%]">
              <CustomTextField
                placeholder="Month"
                isManadatory={false}
                value={Boolean(customer["month"]) ? customer["month"] : ""}
                onChange={(value) => handleCustomerDetails("month", value)}
                type="text"
                inputPadding={true}
                labelTextColor={"#007FFF"}
              />
            </div>
            <div className="w-[40%]">
              <CustomTextField
                placeholder="Year"
                isManadatory={false}
                value={Boolean(customer["year"]) ? customer["year"] : ""}
                onChange={(value) => handleCustomerDetails("year", value)}
                type="text"
                inputPadding={true}
                labelTextColor={"#007FFF"}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[6px]">
          <div className="font-outfit font-[400] text-[12px] leading-[24px] tracking-wider  text-[#8F9BB3] uppercase">
            {" "}
            Address
          </div>

          <div className="w-[100%]">
            <CustomTextField
              placeholder="Address"
              isManadatory={false}
              value={Boolean(customer["address"]) ? customer["address"] : ""}
              onChange={(value) => handleCustomerDetails("address", value)}
              type="text"
              inputPadding={true}
              labelTextColor={"#8F9BB3"}
            />
          </div>
        </div>

        <div
          className="font-outfit font-[500] text-[14px] leading-[17.64px] text-[#007FFF] p-3 bg-[#E9F4FF] flex 
        justify-center items-center rounded-[12px]"
          onClick={submitCustomerDetails}
        >
          Save Details
        </div>
      </div>

      <StewardDrawer
        stewardDrawerVisible={stewardDrawerVisible}
        setStewardDrawerVisible={setStewardDrawerVisible}
        setAddStewardDrawerVisible={setAddStewardDrawerVisible}
      />

      <AddStewardDrawer
        addStewardDrawerVisible={addStewardDrawerVisible}
        setAddStewardDrawerVisible={setAddStewardDrawerVisible}
        setStewardDrawerVisible={setStewardDrawerVisible}
        selectedPos={posData?.filter((Obj) => Obj?.id === activePosId)?.[0]}
      />
    </div>
  );
};

export default CustomerTableDetails;
