import Header from "./components/header";
import LeftView from "./components/leftView/leftView";
import RightView from "./components/rightView/rightView";

const HousekeepingMainPage = () => {
  return (
    <div className="bg-[#F2F4FC] mt-[3rem] w-full h-[93.7vh] overflow-hidden">
      <Header />
      <div className="flex">
        <LeftView />
        <RightView />
      </div>
    </div>
  );
};

export default HousekeepingMainPage;
