import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  selectedPos: {},
  manageStocksDropdownVisible: false,
  activeView: "list",
};

const manageStocksSlice = createSlice({
  name: "manageStocks",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.selectedPos = {};
      state.manageStocksDropdownVisible = false;
      state.activeView = "list";
    },
    updateSelectedPos: (state, action) => {
      state.selectedPos = action.payload;
    },
    updateManageStocksDropdownVisible: (state, action) => {
      state.manageStocksDropdownVisible = action.payload;
    },
    updateActiveView: (state, action) => {
      state.activeView = action.payload;
    },
  },
});

export default manageStocksSlice.reducer;
export const {
  resetState,
  updateSelectedPos,
  updateManageStocksDropdownVisible,
  updateActiveView,
} = manageStocksSlice.actions;
