import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateShowFolioDrawer,
  updateShowTakePaymentDrawer,
  updateDisplayInvoiceDrawer,
  updateDisplayPaymentPrintDrawer,
} from "../../../reducer/bookingReducer";
import { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  INVOICE_DATE_AND_TIME_FORMAT,
  FOLIO_FORMAT,
} from "../../../utils/helper";
import { ReactComponent as PrinterIcon } from "../../../assets/icons/printerIcon.svg";
import { usePDF, Resolution, Margin } from "react-to-pdf";
import { CircularProgress } from "@mui/material";
import InvoiceStatement from "../../common/Template/InvoiceStatement";
import { BsDownload } from "react-icons/bs";
import { IoIosArrowRoundBack } from "react-icons/io";
import NewInvoiceStructure from "../../common/Template/NewInvoiceStructure";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceDocument from "../../common/Template/InvoiceDocument";
import StatementDocument from "../../common/Template/StatementDocument";
import { bookingDetailsDrawerWidth } from "../../../utils/bookingDetailsHelper";
import { getCurrentCurrency } from "../../../utils/customCurrencyHelper";
import { BiEdit } from "react-icons/bi";
import { updateEditPaymentDrawer } from "../../../reducer/paymentReducer";

const customHeaderStyles = {
  border: "none",
  paddingBottom: "6px",
};
const customBodyStyles = {
  padding: "0",
  paddingBottom: "50px",
};

const ViewFolioDrawer = () => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const { hotelDetails, hotelContentDetails, isAllHotelsSelected } =
    useSelector((store) => store.login);

  const { banksResponse } = useSelector((store) => store.bank);
  const bankDetails = Array.isArray(banksResponse)
    ? banksResponse?.filter((Obj) => Boolean(Obj?.defaultBankName))?.[0]
    : {};
  const qrCodUrl = bankDetails?.qrCodeUrl;

  const {
    responseDetails,
    showFolio,
    paymentDetails: updatedPaymentDetails,
    customerDetails,
    invoiceDetails: updatedInvoiceDetails,
  } = useSelector((store) => store.booking);

  const { bookingDetails } = responseDetails;
  const { response } = useSelector((store) => store.rooms);
  const { response: posData } = useSelector((store) => store.posSetting);

  const [print, setPrint] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [filter, setFilter] = useState("all");
  const [combinedInvoices, setCombinedInvoices] = useState([]);

  const [posNames, setPosNames] = useState();
  const [roomDetails, setRoomDetails] = useState({});
  const [statemnetPrint, setStatementPrint] = useState(false);

  const { editBookingAllowed, editPaymentAllowed } = useSelector(
    (store) => store.usersRestriction
  );

  const customCloseIconContainer = {
    top: "4.5%",
    right: "3%",
    fontSize: "10px",
    color: "#4D4D4D",
    width: "20px", // Adjust width and height as needed
    height: "20px",
    border: "1px solid #4D4D4D",
    borderRadius: "5px", // Adjust the border radius for rounded corners
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const { toPDF: toInvoicePDF, targetRef } = usePDF({
    filename: "Invoice.pdf",
    scale: 1, // Adjust the scale as needed
    options: {
      format: "A4", // Specify the page size (e.g., "letter", "A4")
    },

    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.EXTREME,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "landscape",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/jpeg",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  });

  const printInvoicePDF = useReactToPrint({
    onAfterPrint: () => setPrint(false),
    content: () => componentRef.current,
    documentTitle: "Invoice",
  });

  const printInvoice = async () => {
    setPrint(true);
    setTimeout(() => {
      printInvoicePDF();
      setPrint(false);
    }, 0);
  };

  const printStatement = useReactToPrint({
    onAfterPrint: () => setStatementPrint(false),
    content: () => componentRef.current,
    documentTitle: "Invoice Statement",
  });

  const printStatementFun = async () => {
    setStatementPrint(true);
    setTimeout(() => {
      printStatement();
      setStatementPrint(false);
    }, 0);
  };

  // Precompute values using useMemo to avoid unnecessary recalculations
  const invoiceAmount = useMemo(() => {
    return (
      updatedInvoiceDetails?.reduce(
        (acc, curr) => acc + (curr?.total || 0),
        0
      ) || 0
    );
  }, [updatedInvoiceDetails]);

  const totalRefund = useMemo(() => {
    return (
      updatedPaymentDetails?.reduce(
        (acc, curr) => (curr.amount < 0 ? acc - curr.amount : acc),
        0
      ) || 0
    );
  }, [updatedPaymentDetails]);

  const paymentReceived = useMemo(() => {
    return (
      updatedPaymentDetails?.reduce(
        (acc, curr) => (curr.amount > 0 ? acc + curr.amount : acc),
        0
      ) || 0
    );
  }, [updatedPaymentDetails]);

  const dueAmount = useMemo(() => {
    return invoiceAmount - paymentReceived + totalRefund;
  }, [invoiceAmount, paymentReceived, totalRefund]);

  useEffect(() => {
    if (Object.keys(responseDetails).length > 0) {
      const combinedArray = Array.isArray(filteredInvoices)
        ? [...filteredInvoices]
        : [];

      combinedArray.sort((a, b) => {
        const dateA = a.createdAt || a.updatedAt || a.invoiceBilledDate || 0;
        const dateB = b.createdAt || a.updatedAt || b.invoiceBilledDate || 0;
        return dateA - dateB;
      });

      let dueAmount = 0;

      const arr = combinedArray.map((item) => {
        let newObj = {
          debit: 0,
          credit: 0,
          dueAmount: 0,
        };
        if (item.hasOwnProperty("cgst") || item?.amount < 0) {
          newObj.debit = Boolean(item.total) ? item?.total : -item.amount;
          newObj.credit = 0;
          newObj.dueAmount = Boolean(item?.total)
            ? dueAmount + item.total
            : dueAmount + -item.amount;
        } else {
          newObj.credit = item.amount;
          newObj.debit = 0;
          newObj.dueAmount = dueAmount - item.amount;
        }

        dueAmount = newObj.dueAmount; // Update dueAmount for the next iteration
        return newObj;
      });

      const updatedRoomDetails = response.find(
        (room) => room.id === responseDetails?.bookingDetails?.rooms?.[0]
      );

      let requiredCombinedArray = combinedArray?.map((Obj, idx) => {
        return {
          date: Boolean(Obj?.createdAt)
            ? INVOICE_DATE_AND_TIME_FORMAT(Obj.createdAt).formattedDate
            : INVOICE_DATE_AND_TIME_FORMAT(Obj.createdAt).formattedDate,

          time: Boolean(Obj?.createdAt)
            ? INVOICE_DATE_AND_TIME_FORMAT(Obj.createdAt).formattedTime
            : INVOICE_DATE_AND_TIME_FORMAT(Obj.createdAt).formattedTime,

          type: Boolean(Obj.hasOwnProperty("amount")) ? "payment" : "invoice",

          amount: Boolean(Obj?.amount) ? Obj?.amount : "",

          paymentPartRecordList: Boolean(Obj?.amount)
            ? Obj?.paymentPartRecordList
            : null,

          transactionNo: Boolean(Obj?.amount) ? Obj?.transactionNo : null,

          method: Boolean(Obj?.amount) ? Obj?.method : null,

          id: Boolean(Obj.hasOwnProperty("amount"))
            ? Obj?.paymentId
            : Boolean(Obj?.invoiceId)
            ? Obj?.invoiceId
            : "Tentative",

          invoiceRefrenceId: Boolean(Obj?.amount) ? null : Obj?.id,

          description: Boolean(Obj?.invoiceType)
            ? Obj?.invoiceType === "roomBill"
              ? `Room Bill`
              : posNames[Obj.posId]
            : Boolean(Obj?.amount < 0)
            ? "Refund"
            : `${Obj?.method?.toUpperCase()}  ${
                Boolean(Obj?.transactionNo) ? `(${Obj?.transactionNo})` : ""
              } `,

          detailedDescription: Boolean(Obj?.invoiceType)
            ? Obj?.priceBreakup?.[0]?.type
            : "",

          debit: Boolean(arr[idx].debit) ? FOLIO_FORMAT(arr[idx].debit) : "NA",
          credit: Boolean(arr[idx].credit)
            ? FOLIO_FORMAT(arr[idx].credit)
            : "NA",
          due: Boolean(arr[idx]?.dueAmount || arr[idx]?.dueAmount == 0)
            ? FOLIO_FORMAT(arr[idx]?.dueAmount)
            : "NA",
        };
      });

      setRoomDetails(updatedRoomDetails);
      setCombinedInvoices(requiredCombinedArray);
    }
  }, [responseDetails, response, filteredInvoices, updatedPaymentDetails]);

  // effect to store pos id: pos name
  useEffect(() => {
    if (posData) {
      const obj = {};
      posData?.forEach((pos) => (obj[pos.id] = pos.type));
      setPosNames(obj);
    }
  }, [posData]);

  // effect to filter out invoices based on user selection
  useEffect(() => {
    if (updatedInvoiceDetails && updatedInvoiceDetails?.length) {
      if (filter === "all") {
        setFilteredInvoices([
          ...updatedInvoiceDetails,
          ...updatedPaymentDetails,
        ]);
      } else if (filter === "roomBill") {
        const updatedFilteredInvoices = updatedInvoiceDetails.filter(
          (invoice) => invoice?.invoiceType === "roomBill"
        );
        setFilteredInvoices(updatedFilteredInvoices);
      } else {
        const updatedFilteredInvoices = updatedInvoiceDetails.filter(
          (invoice) => invoice?.invoiceType !== "roomBill"
        );
        setFilteredInvoices(updatedFilteredInvoices);
      }
    }
  }, [filter, responseDetails, updatedInvoiceDetails, updatedPaymentDetails]);

  const handleFolioClose = () => {
    dispatch(updateShowFolioDrawer());
    setFilter("all");
  };

  const roomInvoice = Array.isArray(updatedInvoiceDetails)
    ? updatedInvoiceDetails.find((Obj) => Obj?.invoiceType === "roomBill")
    : {};

  const DownloadInVoice = () => {
    return (
      <InvoiceDocument
        targetRef={targetRef}
        hotelDetails={hotelDetails}
        bookingDetails={bookingDetails}
        invoiceDetails={filteredInvoices?.filter(
          (obj) => !Boolean("amount" in obj)
        )}
        bankDetails={bankDetails}
        invoiceFilter={filter}
        combinedInvoices={combinedInvoices}
        hotelContentDetails={hotelContentDetails}
        paymentDetails={updatedPaymentDetails}
        invoiceId={roomInvoice?.invoiceId}
        invoiceNum={roomInvoice?.invoiceNum}
        roomInvoice={roomInvoice}
        customerDetails={customerDetails?.[0]}
        response={response}
        roomDetails={roomDetails}
        posNames={posNames}
        val={componentRef}
        print={true}
        download={false}
        posData={posData}
        isSingleInvoice={filter === "all" ? false : true}
        qrCodUrl={qrCodUrl}
        isSummarized={
          hotelDetails?.posComponentsFormatInRoomBill === "summarized"
        }
      />
    );
  };

  const DownloadStatement = () => {
    return (
      <StatementDocument
        targetRef={targetRef}
        hotelDetails={hotelDetails}
        bookingDetails={bookingDetails}
        invoiceDetails={updatedInvoiceDetails}
        invoiceFilter={filter}
        combinedInvoices={combinedInvoices}
        hotelContentDetails={hotelContentDetails}
        paymentDetails={updatedPaymentDetails}
        invoiceId={roomInvoice?.invoiceId}
        invoiceNum={roomInvoice?.invoiceNum}
        roomInvoice={roomInvoice}
        customerDetails={customerDetails?.[0]}
        response={response}
        roomDetails={roomDetails}
        posNames={posNames}
        val={componentRef}
        print={true}
        download={false}
        posData={posData}
        bankDetails={bankDetails}
        isSingleInvoice={filter === "all" ? false : true}
        qrCodUrl={qrCodUrl}
        isSummarized={
          hotelDetails?.posComponentsFormatInRoomBill === "summarized"
        }
      />
    );
  };

  const { symbol, position } = getCurrentCurrency(hotelDetails?.currency);

  return (
    <>
      <Drawer
        title={<></>}
        headerStyle={customHeaderStyles}
        bodyStyle={customBodyStyles}
        closeIcon={<></>}
        closable={true}
        onClose={handleFolioClose}
        open={showFolio}
        width={bookingDetailsDrawerWidth}
      >
        <header className="flex flex-col gap-4  mx-5 mb-2 w-[95%]">
          <div className="topSection flex justify-between  items-center">
            <div className="flex gap-2">
              <div>
                <IoIosArrowRoundBack
                  className="text-[30px] cursor-pointer"
                  onClick={handleFolioClose}
                />{" "}
              </div>
              <div className="font-outfit flex justify-center items-center gap-2 font-[400] text-[16px] leading-[24px] text-[#000]">
                Bills
              </div>
            </div>

            <div className="flex gap-5 items-center">
              <div className="flex font-nunito font-500 text-[13px] leading-[20px] bg-[#E9F4FF] gap-6 px-3 p-2 items-center rounded-[5px]">
                Statement{" "}
                <span className="flex gap-3 cursor-pointer ">
                  {statemnetPrint ? (
                    <CircularProgress size={16} />
                  ) : (
                    <PrinterIcon onClick={printStatementFun} />
                  )}
                  <PDFDownloadLink
                    document={<DownloadStatement />}
                    fileName={`Statement`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <CircularProgress size={16} />
                      ) : (
                        <BsDownload className="text-[#007FFF] text-[20px]" />
                      )
                    }
                  </PDFDownloadLink>
                </span>
              </div>

              <div className="flex font-nunito font-500 text-[13px] leading-[20px] bg-[#E9F4FF] gap-6 px-3 p-2 items-center rounded-[5px]">
                Invoice{" "}
                <span className="cursor-pointer flex gap-3">
                  {print ? (
                    <CircularProgress size={16} />
                  ) : (
                    <PrinterIcon onClick={printInvoice} />
                  )}
                  <PDFDownloadLink
                    document={<DownloadInVoice />}
                    fileName={`Invoice`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <CircularProgress size={16} />
                      ) : (
                        <BsDownload className="text-[#007FFF] text-[20px]" />
                      )
                    }
                  </PDFDownloadLink>
                </span>
              </div>

              <CloseOutlined
                style={customCloseIconContainer}
                onClick={handleFolioClose}
              />
            </div>
          </div>

          <div className="bottomSection flex justify-between items-center">
            <div className="bottomLeftSection flex flex-col gap-0">
              <div className="font-outfit font-[400] text-[14px] leading-[21px] text-[#000]">
                {Boolean(bookingDetails?.name)
                  ? bookingDetails?.name?.substring(0, 40)
                  : "Guest"}
              </div>
              <div className="font-outfit font-[400] text-[14px] leading-[21px] text-[#9D9D9D]">
                Total Amount
              </div>
              <div className="font-outfit font-[500] text-[24px] leading-[36px] text-[#000]">
                {`${position === "before" ? symbol : ""} ${
                  Boolean(invoiceAmount) ? FOLIO_FORMAT(invoiceAmount) : "00"
                } ${position === "after" ? ` ${symbol}` : ""}`}{" "}
              </div>
            </div>
            <div className="bottomRightSection flex flex-col gap-1">
              <div className="font-outfit font-[400] text-[14px] leading-[21px] text-[#9D9D9D]">
                Paid:{" "}
                <span className="font-outfit font-[500] text-[14px] leading-[21px] text-[#000]">
                  {`${position === "before" ? symbol : ""} ${
                    Boolean(paymentReceived)
                      ? FOLIO_FORMAT(paymentReceived)
                      : "00"
                  } ${position === "after" ? ` ${symbol}` : ""}`}
                </span>
              </div>
              <div className="font-outfit font-[400] text-[14px] leading-[21px] text-[#9D9D9D]">
                Total Due:{" "}
                <span className="font-outfit font-[500] text-[14px] leading-[21px] text-[#F36960]">
                  {`${position === "before" ? symbol : ""} ${
                    Boolean(dueAmount) ? FOLIO_FORMAT(dueAmount) : "00"
                  } ${position === "after" ? ` ${symbol}` : ""}`}
                </span>
              </div>
            </div>
          </div>
        </header>

        <tab className="w-[100%] flex justify-between">
          <div
            className={`${
              filter === "all"
                ? "border-b-[#007FFF] border-b-[2px] text-[#007FFF] font-[500]"
                : "border-b-[#FFF] border-b-[2px] text-[#575E71] font-[400]"
            } w-[20%] font-outfit  leading-[18px] text-[14px] ] p-2 flex justify-center items-center cursor-pointer`}
            onClick={() => setFilter("all")}
          >
            All
          </div>
          <div
            className={`${
              filter === "roomBill"
                ? "border-b-[#007FFF] border-b-[2px] text-[#007FFF] font-[500]"
                : "border-b-[#FFF] border-b-[2px] text-[#575E71] font-[400]"
            } w-[20%] font-outfit  leading-[18px] text-[14px] ] p-2 flex justify-center items-center cursor-pointer`}
            onClick={() => setFilter("roomBill")}
          >
            Rooms
          </div>
          <div
            className={`${
              filter === "pos"
                ? "border-b-[#007FFF] border-b-[2px] text-[#007FFF] font-[500]"
                : "border-b-[#FFF] border-b-[2px] text-[#575E71] font-[400]"
            } w-[20%] font-outfit  leading-[18px] text-[14px] ] p-2 flex justify-center items-center cursor-pointer`}
            onClick={() => setFilter("pos")}
          >
            POS
          </div>
        </tab>

        <div
          className="h-[50px] w-full pl-4 pr-[20px] flex justify-between items-center
         border-b border-b-[#00000026] bg-[#F9FAFB] font-outfit font-[500] text-[12px] leading-[15px] text-[#667085]"
        >
          <div className="w-[16%] px-3  ml-[4px]">Date</div>
          <div className="w-[20%] px-3 ml-[4px] text-left">Invoice No</div>
          <p className="w-[20%] px-3 ">Description</p>
          <p className="w-[16%] px-3 ">Debit</p>
          <p className="w-[14%] px-3 ">Credit</p>
          <p className="w-[14%] px-3">Due </p>
          <p className="w-[5%] px-3">Action</p>
        </div>

        <div className="absolute w-[100%]  h-[60vh]  overflow-auto">
          {combinedInvoices &&
            combinedInvoices?.map((invoice, index) => {
              let paymentObj = {};
              if (invoice?.type === "payment") {
                paymentObj =
                  updatedPaymentDetails?.find(
                    ({ paymentId }) => paymentId === invoice?.id
                  ) || {};
              }

              return (
                <div
                  onClick={() => {
                    invoice?.type === "payment"
                      ? dispatch(
                          updateDisplayPaymentPrintDrawer({
                            display: true,
                            paymentObject: invoice,
                            invoiceDetails: invoice,
                            bookingDetails: bookingDetails,
                            customerDetails: customerDetails,
                            paymentDetails: updatedPaymentDetails,
                            response: response,
                            roomDetails: roomDetails,
                            posNames: posNames,
                          })
                        )
                      : dispatch(
                          updateDisplayInvoiceDrawer({
                            display: true,
                            bookingDetails: bookingDetails,
                            invoiceDetails: updatedInvoiceDetails,
                            updatedInvoiceDetails: updatedInvoiceDetails,
                            paymentDetails: updatedPaymentDetails,
                            invoiceId: invoice.invoiceRefrenceId,
                            customerDetails: customerDetails,
                            response: response,
                            roomDetails: roomDetails,
                            posNames: posNames,
                          })
                        );
                  }}
                  className=" shadow-sm cursor-pointer  w-[100%] flex justify-between items-center 
                   border-b border-b-[#EAECF0] font-outfit font-[500]  text-[12px]"
                  key={invoice?.id}
                >
                  <div className="w-[16%] p-3 flex flex-col">
                    <p className="font-[500]  text-[#222B45]">
                      {invoice?.date}
                    </p>
                    <p className=" font-[400]  text-[#8F9BB3]">
                      {invoice?.time}
                    </p>
                  </div>

                  <p className="w-[20%] p-3  text-[#2F80ED]  ml-[4px] text-left ">
                    {invoice?.id}
                  </p>

                  <div className="w-[20%] p-3 mb-[2px]">
                    <p className=" text-[#222B45]">{invoice?.description}</p>
                    <p className="font-[400]  text-[#8F9BB3]">
                      {invoice?.detailedDescription}
                    </p>
                  </div>

                  <p className="w-[16%] p-3 font-[400]  text-[#222B45] flex justify-start items-center gap-2">
                    {invoice && invoice?.debit && invoice?.debit === "NA"
                      ? "NA"
                      : `${position === "before" ? symbol : ""} ${
                          invoice.debit
                        } ${position === "after" ? ` ${symbol}` : ""}`}
                  </p>

                  <p className="w-[14%] p-3  font-[400]  text-[#222B45] flex items-center gap-2">
                    {invoice && invoice?.credit && invoice?.credit === "NA"
                      ? "NA"
                      : `${position === "before" ? symbol : ""} ${
                          invoice.credit
                        } ${position === "after" ? ` ${symbol}` : ""}`}
                  </p>

                  <p className="w-[14%] p-3  font-[400]  text-[#222B45] ">
                    {invoice && invoice.due && invoice?.due === "NA"
                      ? "NA"
                      : `${position === "before" ? symbol : ""} ${
                          invoice.due
                        } ${position === "after" ? ` ${symbol}` : ""}`}
                  </p>

                  {invoice?.type === "payment" && editPaymentAllowed ? (
                    <span
                      className="p-3 "
                      onClick={(e) => {
                        dispatch(
                          updateEditPaymentDrawer({
                            display: true,
                            paymentObj: paymentObj,
                          })
                        );
                        e.stopPropagation();
                      }}
                    >
                      <BiEdit className="text-[#8F9BB3] cursor-pointer  h-[14px] w-[14px]" />
                    </span>
                  ) : (
                    <span className="p-3 ">N.A</span>
                  )}
                </div>
              );
            })}
        </div>

        {editBookingAllowed && !isAllHotelsSelected && (
          <div className="absolute bottom-0 left-0 h-[3.3rem] w-[100%] text-[1.125rem] font-[600]">
            <button
              onClick={() => dispatch(updateShowTakePaymentDrawer())}
              className="w-full h-full text-[#FFF] bg-[#007FFF] "
            >
              TAKE PAYMENT
            </button>
          </div>
        )}
      </Drawer>

      {/* Template  */}
      {statemnetPrint && (
        <InvoiceStatement
          hotelDetails={hotelDetails}
          bookingDetails={bookingDetails}
          invoiceDetails={updatedInvoiceDetails}
          paymentDetails={updatedPaymentDetails}
          customerDetails={customerDetails?.[0]}
          response={response}
          roomDetails={roomDetails}
          roomInvoice={roomInvoice}
          bankDetails={bankDetails}
          invoiceId={roomInvoice?.invoiceId}
          val={componentRef}
          print={true}
          download={false}
          posData={posData}
          qrCodUrl={qrCodUrl}
        />
      )}

      {print && (
        <NewInvoiceStructure
          targetRef={targetRef}
          hotelDetails={hotelDetails}
          bookingDetails={bookingDetails}
          invoiceDetails={filteredInvoices?.filter(
            (obj) => !Boolean("amount" in obj)
          )}
          paymentDetails={updatedPaymentDetails}
          invoiceId={roomInvoice?.invoiceId}
          roomInvoice={roomInvoice}
          customerDetails={customerDetails?.[0]}
          roomDetails={roomDetails}
          posNames={posNames}
          val={componentRef}
          bankDetails={bankDetails}
          print={true}
          download={false}
          posData={posData}
          isSingleInvoice={filter === "all" ? false : true}
          qrCodUrl={qrCodUrl}
          isSummarized={
            hotelDetails?.posComponentsFormatInRoomBill === "summarized"
          }
        />
      )}
    </>
  );
};

export default ViewFolioDrawer;
