import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  bookingDetailsAddOnsCalculation,
  bookingPriceCalculation,
} from "../../utils/bookingHelper";

const useBookingCalculation = (
  setLocalRoomTotal,
  setLocaLSubTotal,
  setLocalTax,
  setLocalTotal,
  setLocalDiscount,
  setLocalDiscountInfo,
  setRoomCountMap,
  setAddOnsTotal,
  setTotalTax
) => {
  const {
    selectedRoomWTypeAndNum,
    roomPriceStructure,
    nightsCount,
    detailedPriceBreakup,
    taxIncludedInPriceLocalState,
    invoiceDetails,
  } = useSelector((store) => store.booking);

  const { occupancyPlanRate, hotelDetails } = useSelector(
    (store) => store.login
  );
  const roomTypes = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];
  const { taxResponse } = useSelector((store) => store.tax);

  useEffect(() => {
    if (!Array.isArray(roomPriceStructure) || roomPriceStructure?.length <= 0)
      return;

    let roomInvoice = invoiceDetails?.find(
      (Obj) => Obj?.invoiceType === "roomBill"
    );

    let discountInfo = Boolean(roomInvoice) ? roomInvoice?.discountInfo : {};
    let taxObjectArray = roomInvoice?.taxObjects;

    let alreadyPresentTaxArray = Array.isArray(roomInvoice?.taxObjects)
      ? roomInvoice?.taxObjects
      : [];
    const alreadyPresentTaxSet = new Set(
      Array.isArray(roomInvoice?.taxObjects)
        ? roomInvoice.taxObjects.map((obj) => obj.taxId)
        : []
    );

    const requiredTaxResponse = [
      ...alreadyPresentTaxArray,
      ...taxResponse?.filter((obj) => !alreadyPresentTaxSet.has(obj?.id)),
    ];

    const result = bookingPriceCalculation(
      selectedRoomWTypeAndNum,
      roomPriceStructure,
      roomTypes,
      nightsCount,
      discountInfo,
      [],
      detailedPriceBreakup,
      taxIncludedInPriceLocalState,
      false,
      requiredTaxResponse
    );

    setLocalRoomTotal(result?.roomTotal);
    setLocaLSubTotal(result?.subTotal);
    setLocalTax(result?.totalRoomTax);
    setLocalDiscount(result?.discount);
    setLocalDiscountInfo(discountInfo);
    setRoomCountMap(result?.roomTypeCountMap);

    let localAddOnsArray = roomInvoice?.priceBreakup?.filter(
      (Obj) => Obj?.type === "Addons"
    );

    localAddOnsArray = Array.isArray(localAddOnsArray)
      ? localAddOnsArray?.[0]?.addOns
      : [];

    let addOnsCalcResult = bookingDetailsAddOnsCalculation(
      localAddOnsArray,
      taxObjectArray
    );

    setLocalTotal(result?.total + addOnsCalcResult?.addOnsTotalWithCount);
    setAddOnsTotal(addOnsCalcResult?.addOnsTotalWithCount);
    setTotalTax(
      result?.totalRoomTax + addOnsCalcResult?.totalAddOnsTaxWithCount
    );
  }, [
    invoiceDetails,
    taxIncludedInPriceLocalState,
    detailedPriceBreakup,
    occupancyPlanRate,
  ]);
};

export default useBookingCalculation;
