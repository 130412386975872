import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { useState, useEffect } from "react";

import { Autocomplete } from "@mui/material";
import { TextField, CircularProgress } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../../assets/images/svgs/CloseIcon.svg";

import { updateDisplayAttachPartyDrawer } from "../../../../reducer/posReducer/restaurant";
import { updateAddPartyDropdownVisible } from "../../../../reducer/expenseReducer";
import AddParty from "../../../bookingDetailsV2/components/AddParty";
import { getPartyList } from "../../../../reducer/partyReducer";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";
import { TranslateText } from "../../../../utils/translation";

export default function AttachRoomPartyDrawer({
  setIsCloseOrder,
  total,
  invoice,
  tableOrderDetails,
  hotelId,
  customWidth,
}) {
  const dispatch = useDispatch();

  const [type, setType] = useState("room");
  const [bookingId, setBookingId] = useState("");
  const [roomId, setRoomId] = useState("");
  const [partyId, setPartyId] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [partyName, setPartyName] = useState("");

  const { partyResponse } = useSelector((store) => store.party);

  const { numOfRoomDetails, displayAttachPartyDrawer } = useSelector(
    (store) => store.pos
  );

  const { data: orders } = useSelector((store) => store.order);
  const { menuDrawer } = useSelector((store) => store.app);

  const { addPartyDropdownVisible } = useSelector((store) => store.expense);

  const { allPayments } = useSelector((store) => store.payments);
  const { hotelDetails } = useSelector((store) => store.login);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const payload = {
      hotelId: hotelDetails?.id,
      pageSize: 30,
      searchText: partyName,
      infiniteScrollDisabled: true,
    };
    dispatch(getPartyList(payload));
  }, [partyName]);

  const handleRoomBill = (e, newInputValue) => {
    setSelectedRoom(newInputValue);
    const { bookingId, id } =
      numOfRoomDetails?.find((item) => item?.roomNum === newInputValue) || {};
    setRoomId(id);
    setBookingId(bookingId);
  };

  const { updateInvoiceWithRoomIdAndBookingId, updateInvoiceWithPartyId } =
    useAllPosFunctions(setLoading);

  const handleTypeChange = (value) => {
    setType(value);
    setPartyId("");
    setPartyName("");
    setSelectedRoom("");
    setRoomId("");
    dispatch(updateAddPartyDropdownVisible(false));
  };

  let width = customWidth ? "100%" : menuDrawer ? "33%" : "30%";

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: width,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",

          "& .MuiDrawer-root": {
            position: "absolute",
            marginleft: "auto",
            right: 0,
          },
          "& .MuiPaper-root": {
            position: "absolute",
            marginLeft: "auto",
          },
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",

          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",

          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={displayAttachPartyDrawer}
      onClose={() => dispatch(updateDisplayAttachPartyDrawer(false))}
    >
      <div
        className="flex flex-col mx-3 gap-5 items-start my-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between w-[100%]">
          <div className="flex w-[100%] gap-3 items-center ml-5 ">
            <h2 className="font-[400] text-[0.875rem] text-[#4D4D4D] font-nunito">
              <TranslateText textKey={"attach_bill_to"} namespace="pos" />
            </h2>

            <div>
              <div className="flex items-center justify-center gap-[30px] ">
                <div className="flex items-center gap-[5px]">
                  <div
                    className={`h-[16px] w-[16px] rounded-full border-[1px] flex items-center justify-center ${
                      type === "room" ? "border-[#007FFF]" : "border-[#808080]"
                    } cursor-pointer`}
                    onClick={() => handleTypeChange("room")}
                  >
                    {type === "room" && (
                      <div className="w-[8px] h-[8px] rounded bg-[#007FFF]"></div>
                    )}
                  </div>
                  <span className="font-nunito font-[500px] text-[13px] leading-[17.73px] text-[#4D4D4D]">
                    <TranslateText textKey={"room"} namespace="pos" />
                  </span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <div
                    className={`h-[16px] w-[16px] rounded-full border-[1px] flex items-center justify-center ${
                      type === "party" ? "border-[#007FFF]" : "border-[#808080]"
                    } cursor-pointer`}
                    onClick={() => handleTypeChange("party")}
                  >
                    {" "}
                    {type === "party" && (
                      <div className="w-[8px] h-[8px] rounded bg-[#007FFF]"></div>
                    )}
                  </div>
                  <span className="font-nunito font-[500px] text-[13px] leading-[17.73px] text-[#4D4D4D]">
                    <TranslateText textKey={"party"} namespace="pos" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() => dispatch(updateDisplayAttachPartyDrawer(false))}
            className="cursor-pointer"
          >
            <img src={CloseIcon} alt="" />
          </div>
        </div>

        {type === "room" ? (
          <div className="w-[100%] flex justify-center mb-5">
            <Autocomplete
              id="country-select-demo"
              style={{
                width: "80%",
                padding: 0,
                borderRadius: "10px",
                color: "#808080",
              }}
              value={Boolean(selectedRoom) ? selectedRoom : ""}
              onInputChange={(event, newInputValue) => {
                handleRoomBill(event, newInputValue);
              }}
              options={numOfRoomDetails?.map((item) => item.roomNum)}
              sx={{
                fontSize: "14px",
                color: "#00000098",
                fontFamily: "Poppins",
                fontWeight: 400,
                padding: 0,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Room No"
                  sx={{
                    borderRadius: "10px",
                    "& label.Mui-focused": {
                      marginX: 0,
                      paddingX: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    "& input": {
                      fontFamily: "Poppins",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                      color: "#4D4D4D",
                      // border: "1px solid black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputLabel-root": {
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      fontFamily: "nunito",
                      color: "#808080",
                      fontSize: "0.75rem", // Font size when label is down
                    },
                    "& .MuiInputLabel-shrink": {
                      fontSize: "0.93rem", // Font size when label is up (focused)
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "nunito",
                      paddingTop: "-5px",
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>
        ) : (
          <div className="w-[100%] relative flex justify-center mb-5">
            {addPartyDropdownVisible && (
              <AddParty
                posStyles={true}
                setPartyName={setPartyName}
                setPartyId={setPartyId}
                partyType="source"
              />
            )}
            <Autocomplete
              id="partyAuto"
              style={{
                width: "80%",
                padding: 0,
                borderRadius: "10px",
                color: "#808080",
              }}
              value={Boolean(partyName) ? partyName : ""}
              onInputChange={(event, newInputValue) => {
                if (newInputValue === "ADD PARTY") {
                  dispatch(updateAddPartyDropdownVisible(true));
                  return;
                }
                setPartyName(newInputValue);
                let id = partyResponse
                  ?.filter((Obj) => Obj?.displayName === newInputValue)
                  ?.map((Obj) => Obj?.id)?.[0];
                setPartyId(id);
              }}
              options={[
                "ADD PARTY",
                ...(partyResponse
                  ?.filter((Obj) => Boolean(Obj?.displayName))
                  ?.map((Obj) => Obj?.displayName) ?? []),
              ]}
              sx={{
                fontSize: "14px",
                color: "#00000098",
                fontFamily: "Poppins",
                fontWeight: 400,
                padding: 0,
              }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ color: option === "ADD PARTY" ? "#007FFF" : "#000" }}
                >
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Party Name"
                  sx={{
                    borderRadius: "10px",
                    "& label.Mui-focused": {
                      color: "#007fff",
                      marginX: 0,
                      paddingX: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    "& input": {
                      fontFamily: "Poppins",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                      color: "#4D4D4D",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputLabel-root": {
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      fontFamily: "nunito",
                      color: "#808080",
                      fontSize: "0.75rem",
                    },
                    "& .MuiInputLabel-shrink": {
                      fontSize: "0.93rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "nunito",
                      paddingTop: "-5px",
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>
        )}
      </div>

      {type === "room" ? (
        <button
          className="bg-[#007FFF] text-[#FFF] w-full h-[3rem]"
          onClick={() =>
            updateInvoiceWithRoomIdAndBookingId(
              roomId,
              hotelId,
              bookingId,
              invoice,
              orders,
              selectedRoom,
              updateDisplayAttachPartyDrawer,
              updateAddPartyDropdownVisible,
              allPayments
            )
          }
          disabled={loading}
        >
          {loading ? <CircularProgress size={16} /> : "Save Details"}
        </button>
      ) : (
        <>
          <button
            className="bg-[#007FFF] text-[#FFF] w-full h-[3rem]"
            onClick={() =>
              updateInvoiceWithPartyId(
                partyId,
                allPayments,
                hotelId,
                total,
                invoice,
                orders,
                partyName,
                updateDisplayAttachPartyDrawer,
                updateAddPartyDropdownVisible
              )
            }
            disabled={loading}
          >
            {loading ? <CircularProgress size={16} /> : "Save Details"}
          </button>
        </>
      )}
    </Drawer>
  );
}
