import React from "react";
import { RedCircle } from "../Icon1";

function RoomDetailBar({ item }) {
  return (
    <div className="flex border w-full p-0 items-center bg-[#F9F9F9]">
      <div className="w-[30%] border-r py-2 text-[#000000B2] text-[20px] px-4">
        {item?.roomType}
      </div>
      <div className="w-[90%] flex justify-between items-center pr-2">
        <span className="py-2 px-4 text-[#000000B2] text-[18px]">
          Total Rooms : {item?.bookings + item?.availability || "NA"}
        </span>
        <span className="py-2 px-4 text-[#000000B2] text-[18px]">
          Booked : {item?.bookings || "NA"}
        </span>
        <span className="py-2 px-4 text-[#000000B2] text-[18px]">
          Available : {item?.availability || "NA"}
        </span>
        <span className="py-2 px-4 text-[#000000B2] text-[18px]">
          Rate : Rs {item?.rate || "NA"}
        </span>
        <RedCircle />
      </div>
    </div>
  );
}

export default RoomDetailBar;
