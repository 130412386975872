import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getAllCustomer,
  customerDetailsByPhone,
  putCustomer,
  getAllCustSearch,
} from "../services/customer";
import { message } from "antd";

export const getAllCustomers = createAsyncThunk(
  "customers/getAllCustomers",

  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const res = await getAllCustomer(payload, "customers");

      return fulfillWithValue({
        pageNumber: payload?.pageNumber,
        data: res?.data?.content,
        totalPages: res?.data?.totalPages,
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage?.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getAllCustomersSearch = createAsyncThunk(
  "customers/getAllCustomersSearch",

  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const res = await getAllCustSearch(payload, "customers");

      return res.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage?.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getCustomerByPhone = createAsyncThunk(
  "customers/getCustomerByPhone",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await customerDetailsByPhone(payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const putCustomerById = createAsyncThunk(
  "customers/putCustomer",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await putCustomer(payload);

      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: false, // before api call
  response: [], // store  customers list json data into it
  apiError: false, // check api status

  sortingDirection: "DESC",
  sortingField: "updatedAt",

  uploadedFileUrl: [], // after uploaded the file , store the file url,

  // infinate scrolling
  pageNumber: 0,
  pageSize: 20,
  infinateScroll: false,
  infinateScrollLoading: false,

  data: [],
  error: null,

  //getCustomerByPhone No
  customerApiLoading: false,
  customerData: [],
  customerApiError: null,

  // Edit PersonalDetails Modal
  visibleClientModal: false, // after clicking on the edit button in client details page , modal will model
  selectedDob: null,

  identityOptions: [
    { value: "aadhar_card", label: "Aadhar Card" },
    { value: "pan_card", label: "PAN Card" },
    { value: "license", label: "License" },
    { value: "passport", label: "Passport" },
    { value: "voter_id", label: "Voter ID" },
  ],
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_16: () => initialState,

    updateInFinateScroll: (state, action) => {
      state.infinateScroll = action.payload;
    },
    updateInFinateScrollLoading: (state, action) => {
      state.infinateScrollLoading = action.payload;
    },

    updatePageNo: (state, action) => {
      state.pageNumber = state.pageNumber + 1;
    },
    resetPageNo: (state, action) => {
      state.pageNumber = 0;
    },
    updateSortingDirection: (state, action) => {
      state.sortingDirection = action.payload;
    },
    updateSortingField: (state, action) => {
      state.sortingField = action.payload;
    },

    updateUploadId: (state, action) => {
      if (state.uploadedFileUrl.indexOf(action.payload) === -1) {
        state.uploadedFileUrl.push(action.payload);
      }
    },
    removeUploadedUrl: (state, action) => {
      if (state.uploadedFileUrl.indexOf(action.payload) > -1) {
        let index = state.uploadedFileUrl.indexOf(action.payload);
        state.uploadedFileUrl.splice(index, 1);
      }
    },

    updateShowingClientModal: (state, action) => {
      state.visibleClientModal = action.payload.modal;
    },

    updateDates: (state, action) => {
      if (action.payload?.dob) {
        state.selectedDob = action.payload?.dob;
      }
    },
    updateResetState: (state, action) => {
      state.loading = false;
      state.response = [];
      state.apiError = false;

      state.sortingDirection = "DESC";
      state.sortingField = "updatedAt";
      state.uploadedFileUrl = [];

      state.pageNumber = 0;
      state.pageSize = 20;
      state.infinateScroll = false;
      state.infinateScrollLoading = false;

      state.data = [];
      state.error = null;

      state.customerApiLoading = false;
      state.customerData = [];
      state.customerApiError = null;

      state.visibleClientModal = false;
      state.selectedDob = null;

      state.identityOptions = [
        { value: "aadhar_card", label: "Aadhar Card" },
        { value: "pan_card", label: "PAN Card" },
        { value: "license", label: "License" },
        { value: "passport", label: "Passport" },
        { value: "voter_id", label: "Voter ID" },
      ];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllCustomers.pending, (state, action) => {
      state.loading = state.infinateScroll ? false : true;
      state.apiError = false;
    });
    builder.addCase(getAllCustomers.fulfilled, (state, action) => {
      state.loading = false;
      state.infinateScrollLoading = false;

      state.infinateScroll = action.payload?.data?.length > 0 ? true : false;

      state.infinateScrollLoading =
        action.payload?.data?.length > 0 ? true : false;

      state.response = Boolean(action.payload?.pageNumber)
        ? [...state.response, ...(action.payload?.data || [])]
        : action.payload?.data || [];

      state.apiError = false;
    });
    builder.addCase(getAllCustomers.rejected, (state, action) => {
      state.infinateScrollLoading = false;

      state.loading = false;
      state.apiError = true;
      state.response = [];
    });

    builder.addCase(getCustomerByPhone.pending, (state, action) => {
      state.customerApiLoading = true;
      state.customerApiError = false;
    });
    builder.addCase(getCustomerByPhone.fulfilled, (state, action) => {
      state.customerApiLoading = false;
      state.customerData = action?.payload?.data;
      state.customerApiError = false;
    });
    builder.addCase(getCustomerByPhone.rejected, (state, action) => {
      state.customerApiLoading = false;
      state.customerApiError = true;
    });

    builder.addCase(putCustomerById.pending, (state, action) => {
      state.loading = true;
      state.apiError = null;
    });
    builder.addCase(putCustomerById.fulfilled, (state, action) => {
      message.success("Client updated successfully");

      state.loading = false;

      const updatedResponse = state.response?.map((item) =>
        item?.id === action.payload?.id ? action.payload : item
      );
      state.response = updatedResponse;
      state.apiError = false;
    });
    builder.addCase(putCustomerById.rejected, (state, action) => {
      state.loading = false;
      state.response = [];
      state.apiError = true;
    });

    builder.addCase(getAllCustomersSearch.pending, (state, action) => {
      state.infinateScrollLoading = false;

      state.loading = true;
      state.apiError = false;
      state.response = [];
    });
    builder.addCase(getAllCustomersSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.response = Boolean(action.payload) ? action.payload : [];

      state.apiError = false;
    });
    builder.addCase(getAllCustomersSearch.rejected, (state, action) => {
      state.loading = false;
      state.apiError = true;
      state.response = [];
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_16,
  // updateClientsList,
  updateUploadId,
  removeUploadedUrl,
  updateInFinateScroll,
  updateInFinateScrollLoading,
  updatePageNo,
  updateSortingField,
  updateSortingDirection,
  updateBookingDetailsStateAfterCustomerUpdate,
  updateShowingClientModal,
  updateDates,
  updateSearchText,
  resetPageNo,
  updateResetState,
} = clientsSlice.actions;

export default clientsSlice.reducer;
