import React, { useState, useEffect } from "react";
import { updateShowApplyDiscountDrawer } from "../../../reducer/bookingReducer";
import { useDispatch, useSelector } from "react-redux";
import { CURRENCY_FORMAT } from "../../../utils/helper";
import { CircularProgress } from "@mui/material";
import { BsInfoCircleFill } from "react-icons/bs";
import { TranslateText } from "../../../utils/translation";

const Payments = ({ bookingType, isCmIdPresent }) => {
  const { paymentDetails, invoiceDetails, loadingDetails } = useSelector(
    (store) => store.booking
  );
  const dispatch = useDispatch();

  const [roomInvoice, setRoomInvoice] = useState({});
  const [posTotal, setPosTotal] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [refund, setRefund] = useState(0);
  const [roomTax, setRoomTax] = useState(0);
  const [balanceDue, setBalanceDue] = useState(0);
  const [addOnsTotal, setAddonsTotal] = useState(0);
  const { editBookingAllowed } = useSelector((store) => store.usersRestriction);

  const { isAllHotelsSelected, hotelDetails } = useSelector(
    (store) => store.login
  );
  const allowEditingAfterCheckout = hotelDetails?.allowEditingAfterCheckout;

  let isBookingAlterationAllowed = Boolean(
    editBookingAllowed &&
      (bookingType !== "completed" || allowEditingAfterCheckout) &&
      !isAllHotelsSelected
  );

  useEffect(() => {
    if (Array.isArray(invoiceDetails)) {
      let localRoomInvoice = invoiceDetails?.filter(
        (Obj) => Obj?.invoiceType === "roomBill"
      )?.[0];

      const cgst = Boolean(localRoomInvoice?.cgst) ? +localRoomInvoice.cgst : 0;
      const sgst = Boolean(localRoomInvoice?.sgst) ? +localRoomInvoice.sgst : 0;
      const tax = Boolean(localRoomInvoice?.totalTax)
        ? +localRoomInvoice.totalTax
        : 0;

      let localAddOnRateObject = localRoomInvoice?.priceBreakup?.find(
        (Obj) => Obj?.type === "Addons"
      );

      let localAddOnRate = Boolean(
        localAddOnRateObject && localAddOnRateObject?.rate
      )
        ? localAddOnRateObject?.rate
        : 0;

      let localRoomTax = Boolean(tax) ? tax : cgst + sgst;

      const localPayemnts = paymentDetails
        ?.map((item) => item.amount)
        ?.filter((Obj) => Boolean(Obj))
        ?.filter((Obj) => Obj > 0)
        ?.reduce((total, num) => total + num, 0);

      const localRefund = paymentDetails
        ?.map((item) => item.amount)
        ?.filter((Obj) => Boolean(Obj))
        ?.filter((Obj) => Obj < 0)
        ?.reduce((total, num) => total + -num, 0);

      let localPosTotal = invoiceDetails
        ?.filter((item) => item.invoiceType !== "roomBill")
        ?.map((item) => item.total)
        ?.filter((Obj) => Boolean(Obj))
        ?.reduce((total, num) => total + num, 0);

      let allTotal = invoiceDetails
        ?.map((item) => item.total)
        ?.filter((Obj) => Boolean(Obj))
        ?.reduce((total, num) => total + num, 0);

      let remainigBalance = allTotal - localPayemnts + localRefund;

      setAddonsTotal(localAddOnRate);
      setRoomInvoice(localRoomInvoice);
      setRoomTax(localRoomTax);
      setTotalPayments(localPayemnts);
      setRefund(localRefund);
      setPosTotal(localPosTotal);
      setBalanceDue(remainigBalance);
    }
  }, [invoiceDetails, paymentDetails]);

  return (
    <div className="border w-[95%] border-[#00000015] rounded-xl mt-4 py-2 px-2 flex justify-between  text-[10px] mx-3">
      {loadingDetails ? (
        <div className="flex w-[100%] items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            className="flex flex-col items-start cursor-pointer"
            // onClick={() => dispatch(updateDisplayDetailedBreakupModal(true))}
          >
            <div className="flex gap-1 items-center">
              <div className="text-[#808080]">Room Amount</div>
              {/* <BsInfoCircleFill className="text-[#808080] text-[12px]" /> */}
            </div>

            <div className="font-medium  text-[14px] leading-[21px] cursor-pointer">
              {Boolean(roomInvoice)
                ? Boolean(roomInvoice?.subTotal && roomInvoice?.discount)
                  ? CURRENCY_FORMAT(
                      roomInvoice?.subTotal + roomInvoice?.discount
                    )
                  : CURRENCY_FORMAT(roomInvoice?.subTotal)
                : "00"}
            </div>
          </div>

          <div className="flex flex-col items-start">
            <div className="text-[#808080]">
              <TranslateText textKey={"room_discount"} namespace="booking" />
            </div>

            {!isBookingAlterationAllowed ? (
              <div className="font-medium  text-[14px] leading-[21px] ">
                {Boolean(roomInvoice)
                  ? Boolean(roomInvoice?.discount)
                    ? CURRENCY_FORMAT(roomInvoice?.discount)
                    : "00"
                  : "00"}
              </div>
            ) : (
              <div
                className={` ${
                  editBookingAllowed
                    ? "text-[#007FFF] cursor-pointer"
                    : "non-editable-text-color"
                } font-medium text-[14px] leading-[21px] `}
                onClick={() => dispatch(updateShowApplyDiscountDrawer())}
              >
                {Boolean(roomInvoice)
                  ? Boolean(roomInvoice?.discount)
                    ? CURRENCY_FORMAT(roomInvoice?.discount)
                    : "00"
                  : "00"}
              </div>
            )}
          </div>

          <div className="flex flex-col items-start">
            <div className="text-[#808080]">
              <TranslateText textKey={"sub_total"} namespace="booking" />
            </div>
            <div className="font-medium text-[14px] leading-[21px]">
              {Boolean(invoiceDetails)
                ? Boolean(roomInvoice?.subTotal)
                  ? CURRENCY_FORMAT(roomInvoice?.subTotal)
                  : "00"
                : "00"}
            </div>
          </div>

          {Boolean(addOnsTotal) ? (
            <div className="flex flex-col items-start">
              <div className="text-[#808080]">
                <TranslateText textKey={"AddOns"} namespace="booking" />
              </div>
              <div className="font-medium text-[14px] leading-[21px]">
                {Boolean(addOnsTotal) ? CURRENCY_FORMAT(addOnsTotal) : "00"}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="flex flex-col items-start">
            <div className="text-[#808080]">
              <TranslateText textKey={"room_tax"} namespace="booking" />
            </div>
            <div className="font-medium text-[14px] leading-[21px]">
              {Boolean(roomTax) ? CURRENCY_FORMAT(roomTax) : "00"}
            </div>
          </div>

          <div className="flex flex-col items-start">
            <div className="text-[#808080]">
              <TranslateText textKey={"room_total"} namespace="booking" />
            </div>
            <div className="font-medium text-[14px] leading-[21px]">
              {Boolean(roomInvoice?.total)
                ? CURRENCY_FORMAT(roomInvoice?.total)
                : "00"}
            </div>
          </div>

          <div className="flex flex-col items-start">
            <div className="text-[#808080]">
              <TranslateText textKey={"pos_total"} namespace="booking" />
            </div>
            <div className="font-medium text-[14px] leading-[21px]">
              {Boolean(posTotal) ? CURRENCY_FORMAT(posTotal) : "00"}
            </div>
          </div>

          <div className="flex flex-col items-start text-[#808080]">
            <div className="">
              <TranslateText textKey={"total_deposit"} namespace="booking" />
            </div>
            <div className="font-medium text-[14px] leading-[21px]">
              {Boolean(totalPayments) ? CURRENCY_FORMAT(totalPayments) : "00"}
            </div>
          </div>

          <div className="flex flex-col items-start text-[#808080]">
            <div className="">
              <TranslateText textKey={"refund"} namespace="booking" />
            </div>
            <div className="font-medium text-[14px] leading-[21px]">
              {Boolean(refund) ? CURRENCY_FORMAT(refund) : "00"}
            </div>
          </div>

          <div className="flex flex-col items-start ">
            <div className="text-[#808080]">
              <TranslateText textKey={"balance_due"} namespace="booking" />
            </div>
            <div className="font-medium text-[#F36960] text-[14px] leading-[21px]">
              {Boolean(balanceDue) ? CURRENCY_FORMAT(balanceDue) : "00"}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Payments;
