import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  customerDetailsById,
  customerDetailsByPhone,
  getAllCustomer,
  getCustomerService,
  postCustomer,
  putCustomer,
} from "../services/customer";
import { message } from "antd";

export const getAllCustomers = createAsyncThunk(
  "customers/getAllCustomers",
  async (payload) => {
    try {
      const response = await getAllCustomer(payload);
      return response.data?.content;
    } catch (error) {
      return null;
    }
  }
);

export const getCustomer = createAsyncThunk(
  "customers/getCustomer",
  async (payload) => {
    try {
      const response = await getCustomerService(payload);
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getPosCustomerDetails = createAsyncThunk(
  "customers/getPosCustomerDetails",
  async (payload) => {
    try {
      const response = await customerDetailsById(payload);
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const putCustomerById = createAsyncThunk(
  "customers/putCustomer",
  async (payload) => {
    try {
      const response = await putCustomer(payload);
      return response;
    } catch (error) {
      return null;
    }
  }
);

export const saveCustomer = createAsyncThunk(
  "customers/saveCustomer",
  async (payload) => {
    try {
      const response = await postCustomer(payload);
      return response;
    } catch (error) {
      return null;
    }
  }
);

export const getCustomerByPhone = createAsyncThunk(
  "customers/getCustomerByPhone",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await customerDetailsByPhone(payload);

      return fulfillWithValue(response.data?.customer);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  // all customer

  allCustomerLoading: false,
  allCustomerData: [],
  allCustomerError: null,

  loading: false,
  data: [],
  error: null,

  // single customer
  customerApiLoading: false,
  customerData: {},
  customerApiError: null,
};

const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: { CLEAR_REDUX_STORE_17: () => initialState },
  extraReducers: (builder) => {
    builder.addCase(getAllCustomers.pending, (state, action) => {
      state.allCustomerLoading = true;
      state.allCustomerData = [];
      state.allCustomerError = null;
    });

    builder.addCase(getAllCustomers.fulfilled, (state, action) => {
      state.allCustomerLoading = false;
      state.allCustomerData = action.payload;
      state.allCustomerError = null;
    });

    builder.addCase(getAllCustomers.rejected, (state, action) => {
      state.allCustomerLoading = false;
      state.allCustomerData = [];
      state.allCustomerError = true;
    });

    builder.addCase(getCustomer.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.map((customer) => ({
        ...customer,
        value: customer.name,
      }));
      state.error = false;
    });
    builder.addCase(getCustomer.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });

    builder.addCase(getCustomerByPhone.pending, (state, action) => {
      state.customerApiLoading = true;
      state.customerData = {};
      state.customerApiError = false;
    });
    builder.addCase(getCustomerByPhone.fulfilled, (state, action) => {
      if (action.payload?.name) {
        message.success("Customer Found!");
      }
      state.customerApiLoading = false;
      state.customerData = action.payload;
      state.customerApiError = false;
    });
    builder.addCase(getCustomerByPhone.rejected, (state, action) => {
      state.customerApiLoading = false;
      state.customerData = {};
      state.customerApiError = true;
    });

    builder.addCase(getPosCustomerDetails.pending, (state, action) => {
      state.customerApiLoading = true;
      state.customerData = {};
      state.customerApiError = false;
    });
    builder.addCase(getPosCustomerDetails.fulfilled, (state, action) => {
      state.customerApiLoading = false;
      state.customerData = action.payload;
      state.customerApiError = false;
    });
    builder.addCase(getPosCustomerDetails.rejected, (state, action) => {
      state.customerApiLoading = false;
      state.customerData = {};
      state.customerApiError = true;
    });

    builder.addCase(saveCustomer.pending, (state, action) => {
      state.customerApiLoading = true;
      state.customerData = {};
      state.customerApiError = false;
    });
    builder.addCase(saveCustomer.fulfilled, (state, action) => {
      state.customerApiLoading = false;
      state.customerData = action.payload;
      state.customerApiError = false;
    });
    builder.addCase(saveCustomer.rejected, (state, action) => {
      state.customerApiLoading = false;
      state.customerData = {};
      state.customerApiError = true;
    });

    builder.addCase(putCustomerById.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(putCustomerById.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
    });
    builder.addCase(putCustomerById.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
  },
});

export const { CLEAR_REDUX_STORE_17 } = customerSlice.actions;
export default customerSlice.reducer;
