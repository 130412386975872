import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updatedHotelRooms,
  updateNumOfRoomDetails,
} from "../../reducer/posReducer/restaurant";
import { bookingList } from "../../services/booking";

const usePosStorageLogic = () => {
  const { hotelDetails } = useSelector((store) => store.login);

  const dispatch = useDispatch();
  const { response } = useSelector((store) => store.rooms);

  useEffect(() => {
    performRoomApi();
  }, []);

  const performRoomApi = async () => {
    const payload = {
      statuses: "checkedIn",
      hotelId: hotelDetails.id,
      searchText: "",
    };

    dispatch(
      updatedHotelRooms({
        loading: true,
        data: [],
        apiError: false,
      })
    );

    let data = await bookingList(payload, "bookings");
    if (data !== null) {
      getRoomNumber(data?.data?.bookings);
    } else {
      // api response failed phase
      dispatch(
        updatedHotelRooms({
          loading: false,
          data: [],
          apiError: true,
        })
      );
    }
  };

  const getRoomNumber = (bookingList) => {
    let roomIdWithCustomerId = []; // all room ids with customer id
    bookingList?.forEach((item) => {
      item.rooms?.forEach((pd) => {
        if (
          roomIdWithCustomerId?.map((data) => data.roomId).indexOf(pd) == -1
        ) {
          roomIdWithCustomerId.push({
            roomId: pd,
            customerId: item.guests?.[0],
            bookingId: item.id,
          });
        }
      });
    });

    let RoomNumbersWithCustomerId = [];

    response?.forEach((item) => {
      roomIdWithCustomerId?.forEach((pd) => {
        if (item.id === pd.roomId) {
          RoomNumbersWithCustomerId.push({
            roomNum: item.name,
            customerId: pd.customerId,
            id: pd.roomId,
            bookingId: pd.bookingId,
          });
        }
      });
    });

    dispatch(
      updatedHotelRooms({
        loading: false,
        data: RoomNumbersWithCustomerId,
        apiError: false,
      })
    );

    dispatch(updateNumOfRoomDetails(RoomNumbersWithCustomerId));
  };
};

export default usePosStorageLogic;
