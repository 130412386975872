import React from "react";

const Index = () => {
  return (
    <div className="bg-gray-200 relative ">
      <div className="absolute left-0 right-0 top-0 bottom-0">
        <div className="h-full flex justify-center mt-[25rem]">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              Oops, You are Offline!
            </h1>
            <p className="text-lg text-gray-600">
              Please check your internet connection and try again.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
