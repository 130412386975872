import React from "react";
import PrimaryInfo from "./primaryInfo";
import DocumentsVerification from "./documentsVerification";
import AccomodationDetails from "./accomodationDetails";
import Capacity from "./capacity";
import Addons from "./addons";
import Amenities from "./amenities";
import Images from "./images";
import Policy from "./policy";

const Main = ({ currentID, setCurrentID }) => {
  const decrementId = () => {
    if (currentID > 1) {
      setCurrentID((currentID) => currentID - 1);
    }
  };

  const incrementId = () => {
    if (currentID !== 3) setCurrentID((prevID) => prevID + 1);
  };

  return (
    <div className="main-field p-[50px]">
      <div>
        {currentID === 1 && (
          <PrimaryInfo incrementId={incrementId} decrementId={decrementId} />
        )}

        {currentID === 2 && (
          <AccomodationDetails
            incrementId={incrementId}
            decrementId={decrementId}
          />
        )}
        {currentID === 3 && (
          <Capacity incrementId={incrementId} decrementId={decrementId} />
        )}
      </div>
    </div>
  );
};

export default Main;
