import React from "react";

function PosWrapper({ headerName, leftSideContent, rightSideContent }) {
  return (
    <div className="flex h-full relative">
      <div className="w-[65%]">{leftSideContent}</div>
      <div
        className={`w-[35%] ${
          headerName === "Cafe" ? "bg-[#FFFFFF]" : "bg-[#F4F4F4]"
        } h-full `}
      >
        <div className="flex flex-col ">
          <div>{rightSideContent}</div>
        </div>
      </div>
    </div>
  );
}

export default PosWrapper;
