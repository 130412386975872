import React from "react";
import { Button } from "antd";
import { TranslateText } from "../../../utils/translation";
const GenderButton = ({ gender, setGender }) => {
  return (
    <div
      buttonStyle="outlined"
      className="flex flex-wrap gap-5"
      onClick={(e) => e.stopPropagation()}
    >
      <Button
        className={` ${
          gender === "male"
            ? "text-[#007FFF] bg-[#F4F8FE] border border-[#007FFF]"
            : "text-[#808080] bg-[#F2F2F2]  "
        } font-[Poppins] text-[0.875rem] font-[400] leading-[1.25rem] 
         flex justify-center items-center rounded-[0.625rem]`}
        onClick={(e) => setGender("male")}
      >
        <TranslateText textKey={"male"} namespace="booking" />
      </Button>

      <Button
        className={` ${
          gender === "female"
            ? "text-[#007FFF] bg-[#F4F8FE] border border-[#007FFF]"
            : "text-[#808080] bg-[#F2F2F2]  "
        } font-[Poppins] text-[0.875rem] font-[400] leading-[1.25rem] 
         flex justify-center items-center rounded-[0.625rem]`}
        onClick={(e) => setGender("female")}
      >
        <TranslateText textKey={"female"} namespace="booking" />
      </Button>

      <Button
        className={` ${
          gender === "others"
            ? "text-[#007FFF] bg-[#F4F8FE] border border-[#007FFF]"
            : "text-[#808080] bg-[#F2F2F2]  "
        } font-[Poppins] text-[0.875rem] font-[400] leading-[1.25rem] 
         flex justify-center items-center rounded-[0.625rem]`}
        onClick={(e) => setGender("others")}
      >
        <TranslateText textKey={"others"} namespace="booking" />
      </Button>
    </div>
  );
};

export default GenderButton;
