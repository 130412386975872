import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getExpenseService,
  postExpenseService,
  deleteMultipleExpenseService,
  updateMultipleExpenseService,
  putUpdateExpense,
} from "../services/expense";
import { message } from "antd";
import moment from "moment";

export const getExpenseList = createAsyncThunk(
  "expenseList",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getExpenseService(payload, `/expense`);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const createExpense = createAsyncThunk(
  "createExpense",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postExpenseService(payload, `/expense`);
      message.success("Expense Added");
      dispatch(addExpenseReset());
      dispatch(updateExpenseObject({}));
      dispatch(updateItemsList([]));
      dispatch(updateRecordExpenseDrawerVisible(false));
      dispatch(updateSelectedExpenses([]));
      dispatch(updateSelectedExpenseObjects([]));
      dispatch(updateIsEditingExpense(false));
      dispatch(updateSelectedExpenseObject({}));
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const deleteMultipleExpenses = createAsyncThunk(
  "deleteExpenses",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await deleteMultipleExpenseService(
        payload,
        `/expense/deleteAll`
      );
      message.success("Deleted");
      dispatch(updateDisplayDeleteExpenseModal());
      dispatch(updateExpenseObject({}));
      dispatch(updateItemsList([]));
      dispatch(updateRecordExpenseDrawerVisible(false));
      dispatch(updateSelectedExpenses([]));
      dispatch(updateSelectedExpenseObjects([]));
      dispatch(updateIsEditingExpense(false));
      dispatch(updateSelectedExpenseObject({}));

      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const updateMultipleExpenses = createAsyncThunk(
  "updateExpenses",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await updateMultipleExpenseService(
        payload.payload,
        `/expense/updateAll`
      );
      message.success("Expenses Updated");
      dispatch(updateExpenseObject({}));
      dispatch(updateItemsList([]));
      dispatch(updateRecordExpenseDrawerVisible(false));
      dispatch(updateSelectedExpenses([]));
      dispatch(updateSelectedExpenseObjects([]));
      dispatch(updateIsEditingExpense(false));
      dispatch(updateSelectedExpenseObject({}));
      return fulfillWithValue({
        response,
        expenseList: payload.expenseList,
        partyName: payload.partyName,
      });
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const updateExpense = createAsyncThunk(
  "updateExpense",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await putUpdateExpense(
        payload.payload,
        `/expense/${payload.payload.id}`
      );
      message.success("Expense updated");
      dispatch(updateExpenseObject({}));
      dispatch(updateItemsList([]));
      dispatch(updateRecordExpenseDrawerVisible(false));
      dispatch(updateSelectedExpenses([]));
      dispatch(updateSelectedExpenseObjects([]));
      dispatch(updateIsEditingExpense(false));
      dispatch(updateSelectedExpenseObject({}));
      return fulfillWithValue({
        response,
        expenseList: payload.expenseResponse,
      });
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

const initialState = {
  recordExpenseDrawerVisible: false,
  accountType: "vendor",
  dueDateDropdownVisible: false,
  dueDateFilter: "",
  duesDropdownVisible: false,
  duesFilter: "",
  bulkUpdateDropdownVisible: false,
  viewExpanseDrawerVisible: false,
  selectItemDropdownVisible: false,

  expenseObject: {},

  // range picker
  initialDate: "",
  finalDate: "",
  visibleCalendar: "",
  selectedInitialDate: "",
  selectedFinalDate: "",

  // due date filter
  dueStartDate: "",
  dueEndDate: "",
  dueDateFilter: "",

  selectedPartyFilter: "",
  displayPartyFilter: false,

  bulkUpdateFields: [
    {
      id: Math.random(),
      bulkUpdateLeftField: "expenseAccount",
      bulkUpdateRightField: "",
    },
  ],

  accountSearchString: "",
  partySearchString: "",

  expenseDateCalendarVisible: false,
  expenseDate: Date.now(),

  dueDateCalendarVisible: false,
  dueDateValue: Date.now(),

  uploadedFileUrl: [],

  selectedExpenses: [],
  selectedExpenseObjects: [],

  // paid through dropdown
  paidThroughDropdownVisible: false,
  paymentList: [
    {
      id: Math.random(),
      paymentAmount: "",
      paymentMode: "cash",
      transactionDetails: "",
    },
  ],

  // add party
  addPartyDropdownVisible: false,
  addPartyDropdownVisibleNew:false,
  newPartyName: "",

  // add expense account
  addExpenseAccountDropdownVisible: false,
  newExpenseAccountName: "",

  // get expense list
  expenseResponse: [],
  expenseLoading: false,
  expenseError: null,

  // create expense
  createExpenseResponse: [],
  createExpenseLoading: false,
  createExpenseError: null,

  // delete expense
  deleteExpenseLoading: false,
  deleteExpenseError: null,

  // update expense
  updateExpenseLoading: false,
  updateExpenseError: null,

  // select items dropdown

  selectedExpenseObject: {},
  selectedPartyName: "",
  displayDeleteExpenseModal: false,
  yearMonthCalendarVisible: "",
  yearMonthValue: Date.now(),

  bulkUpdatePartyName: "",
  showExpenseDateCalendar: false,
  showDueDateCalendar: false,

  // infinite scrolling
  pageNumber: 0,
  pageSize: 20,
  infinateScroll: false,
  infinateScrollLoading: false,

  // items list
  itemsList: [],

  isAddPayment: false,
  isEditingExpense: false,

  addStaffDropdownVisible: false,
  newStaffName: "",
  addStaffIndex: "",

  searchText: "",
};

const expenseSlice = createSlice({
  name: "expenseSlice",
  initialState,

  reducers: {
    CLEAR_REDUX_STORE_21: () => initialState,

    updateAddStaffIndex: (state, action) => {
      state.addStaffIndex = action.payload;
    },

    updateIsEditingExpense: (state, action) => {
      state.isEditingExpense = action.payload;
    },

    updateIsAddPayment: (state, action) => {
      state.isAddPayment = action.payload;
    },

    updateItemsList: (state, action) => {
      state.itemsList = action.payload;
    },

    // Infinte Scrolling
    updateInFinateScroll: (state, action) => {
      state.infinateScroll = action.payload;
    },
    updateInFinateScrollLoading: (state, action) => {
      state.infinateScrollLoading = action.payload;
    },

    updatePageNo: (state, action) => {
      if (action.payload) {
        state.pageNumber = action.payload;
        return;
      }
      state.pageNumber = state.pageNumber + 1;
    },

    resetInfiniteScrolling: (state, action) => {
      state.pageNumber = 0;
      state.infinateScroll = false;
      state.infinateScrollLoading = false;
    },

    updateDueDates: (state, action) => {
      const { startDate, endDate } = action.payload;
      state.dueStartDate = startDate;
      state.dueEndDate = endDate;
    },
    resetDueDates: (state, action) => {
      state.dueStartDate = "";
      state.dueEndDate = "";
      state.dueDateFilter = "";
    },
    updateDueDateFilter: (state, action) => {
      state.dueDateFilter = action.payload;
    },

    updateShowExpenseDateCalendar: (state, action) => {
      state.showExpenseDateCalendar = action.payload;
    },
    updateShowDueDateCalendar: (state, action) => {
      state.showDueDateCalendar = action.payload;
    },
    updateBulkUpdatePartyName: (state, action) => {
      state.bulkUpdatePartyName = action.payload;
    },
    addExpenseReset: (state, action) => {
      state.recordExpenseDrawerVisible = false;
      state.expenseObject = {};
      state.itemsList = [];
    },
    updateSelectedPartyName: (state, action) => {
      state.selectedPartyName = action.payload;
    },

    updateSelectedExpenseObjects: (state, action) => {
      state.selectedExpenseObjects = action.payload;
    },

    updateSelectedExpenseObject: (state, action) => {
      state.selectedExpenseObject = action.payload;
    },

    // select items dropdown
    updateSelectItemDropdownVisible: (state, action) => {
      state.selectItemDropdownVisible = action.payload;
    },

    // paid through dropdown
    updatePaymentList: (state, action) => {
      const { id, key, value } = action.payload;
      state.paymentList = state.paymentList.map((payment) =>
        payment.id === id ? { ...payment, [key]: value } : payment
      );
    },

    addPayment: (state, action) => {
      state.paymentList = [
        ...state.paymentList,
        {
          id: Math.random(),
          paymentAmount: "",
          paymentMode: "cash",
          transactionDetails: "",
        },
      ];
    },

    removePayment: (state, action) => {
      state.paymentList = state.paymentList.filter(
        ({ id }) => id !== action.payload
      );
    },

    resetPaymentList: (state, action) => {
      state.paymentList = [
        {
          id: Math.random(),
          paymentAmount: "",
          paymentMode: "cash",
          transactionDetails: "",
        },
      ];
    },

    updatePaidThroughDropdownVisible: (state, action) => {
      state.paidThroughDropdownVisible = action.payload;
    },
    updateNewExpenseAccountName: (state, action) => {
      state.newExpenseAccountName = action.payload;
    },
    updateNewPartytName: (state, action) => {
      state.newPartyName = action.payload;
    },
    updateNewStafftName: (state, action) => {
      state.newStaffName = action.payload;
    },
    updateRecordExpenseDrawerVisible: (state, action) => {
      state.recordExpenseDrawerVisible = action.payload;
    },
    updateAccountType: (state, action) => {
      state.accountType = action.payload;
    },
    updateDueDateDropdownVisible: (state, action) => {
      state.dueDateDropdownVisible = action.payload;
    },
    updateDuesDropdownVisible: (state, action) => {
      state.duesDropdownVisible = action.payload;
    },
    updateBulkUpdateDropdownVisible: (state, action) => {
      state.bulkUpdateDropdownVisible = action.payload;
    },
    updateDuesFilter: (state, action) => {
      state.duesFilter = action.payload;
    },
    updateVisibleCalendar: (state, action) => {
      state.visibleCalendar = action.payload;
    },
    updateSelectedInitialDate: (state, action) => {
      state.selectedInitialDate = action.payload;
    },
    updateSelectedFinalDate: (state, action) => {
      state.selectedFinalDate = action.payload;
    },
    updateInitialDate: (state, action) => {
      state.initialDate = action.payload;
    },
    updateFinalDate: (state, action) => {
      state.finalDate = action.payload;
    },
    updateDisplayPartyFilter: (state, action) => {
      state.displayPartyFilter = action.payload;
    },
    updateSelectedPartyFilter: (state, action) => {
      state.selectedPartyFilter = action.payload;
    },
    updateBulkUpdateFields: (state, action) => {
      const { id, name, value } = action.payload;
      state.bulkUpdateFields = state.bulkUpdateFields.map((item) =>
        item.id === id ? { ...item, [name]: value } : item
      );
    },
    resetBulkUpdateFields: (state, action) => {
      state.bulkUpdateFields = [
        {
          id: Math.random(),
          bulkUpdateLeftField: "expenseAccount",
          bulkUpdateRightField: "",
        },
      ];
    },
    addRemoveBuldUpdateFields: (state, action) => {
      const { actionType, id } = action.payload;
      if (actionType === "add") {
        state.bulkUpdateFields = [
          ...state.bulkUpdateFields,
          {
            id: Math.random(),
            bulkUpdateLeftField: "expenseAccount",
            bulkUpdateRightField: "",
          },
        ];
      } else if (actionType === "remove") {
        state.bulkUpdateFields = state.bulkUpdateFields?.filter(
          (item) => item.id !== id
        );
      }
    },
    updateViewExpanseDrawerVisible: (state, action) => {
      state.viewExpanseDrawerVisible = action.payload;
    },
    updateExpenseObject: (state, action) => {
      state.expenseObject = action.payload;
    },
    updateAccountSearchString: (state, action) => {
      state.accountSearchString = action.payload;
    },
    updatePartySearchString: (state, action) => {
      state.partySearchString = action.payload;
    },

    //dates
    updateExpenseDateCalendarVisible: (state, action) => {
      state.expenseDateCalendarVisible = action.payload;
    },
    updateExpenseDate: (state, action) => {
      state.expenseDate = action.payload;
    },
    updateDueDateCalendarVisible: (state, action) => {
      state.dueDateCalendarVisible = action.payload;
    },
    updateDueDate: (state, action) => {
      state.dueDateValue = action.payload;
    },

    updateYearMonthCalendarVisible: (state, action) => {
      state.yearMonthCalendarVisible =
        state.yearMonthCalendarVisible === action.payload ? "" : action.payload;
    },
    updateYearMonthDate: (state, action) => {
      state.yearMonthValue = action.payload;
    },

    //file upload

    resetUploadedFileUrl: (state, action) => {
      state.uploadedFileUrl = [];
    },

    removeUploadedUrl: (state, action) => {
      if (state.uploadedFileUrl.indexOf(action.payload) > -1) {
        let index = state.uploadedFileUrl.indexOf(action.payload);
        state.uploadedFileUrl.splice(index, 1);
      }
    },
    updateUploadedUrl: (state, action) => {
      state.uploadedFileUrl = [...state.uploadedFileUrl, action.payload];
    },
    updateDisplayIdModal: (state, action) => {
      state.displayIdModal = action.payload;
    },

    updateSelectedExpenses: (state, action) => {
      state.selectedExpenses = action.payload;
    },
    updateAddExpenseAccountDropdownVisible: (state, action) => {
      state.addExpenseAccountDropdownVisible = action.payload;
    },
    updateAddPartyDropdownVisible: (state, action) => {
      state.addPartyDropdownVisible = action.payload;
    },
    updateAddPartyDropdownVisibleNew: (state, action) => {
      state.addPartyDropdownVisibleNew = action.payload;
    },
    updateAddStaffDropdownVisible: (state, action) => {
      state.addStaffDropdownVisible = action.payload;
    },
    updateDisplayDeleteExpenseModal: (state, action) => {
      state.displayDeleteExpenseModal = !state.displayDeleteExpenseModal;
    },
    resetVendorExpenseState: (state, action) => {
      state.expenseObject = {};
      state.selectedExpenses = [];
      state.selectedExpenseObjects = [];
      state.initialDate = "";
      state.finalDate = "";
      state.visibleCalendar = "";
      const today = moment();
      state.selectedInitialDate = today.format("YYYY-MM-DD");
      state.selectedFinalDate = moment().add(1, "days").format("YYYY/MM/DD");
      state.selectedPartyFilter = "";
      state.dueStartDate = "";
      state.dueEndDate = "";
      state.dueDateFilter = "";
      state.recordExpenseDrawerVisible = false;
    },
    updateSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get expense list
    builder.addCase(getExpenseList.pending, (state, action) => {
      state.expenseLoading = state.infinateScroll ? false : true;
      state.expenseResponse = state.infinateScroll
        ? [...state.expenseResponse]
        : [];
      state.expenseError = false;
    });
    builder.addCase(getExpenseList.fulfilled, (state, action) => {
      state.expenseLoading = false;

      state.infinateScroll = action.payload?.length > 0 ? true : false;

      state.infinateScrollLoading = action.payload?.length > 0 ? true : false;

      const resData = Boolean(action.payload)
        ? [...state.expenseResponse, ...action.payload]
        : [];

      state.expenseResponse = Boolean(state?.pageNumber)
        ? resData
        : action.payload || [];

      state.expenseError = false;
    });
    builder.addCase(getExpenseList.rejected, (state, action) => {
      state.expenseLoading = false;
      state.expenseError = action.payload;
      state.expenseResponse = [];
    });

    // create expense
    builder.addCase(createExpense.pending, (state, action) => {
      state.createExpenseLoading = true;
      state.createExpenseError = false;
    });
    builder.addCase(createExpense.fulfilled, (state, action) => {
      state.createExpenseLoading = false;
      state.expenseResponse = [
        {
          ...action.payload?.data,
          partyName: state.selectedPartyName,
        },
        ...state.expenseResponse,
      ];
      state.createExpenseError = null;
    });
    builder.addCase(createExpense.rejected, (state, action) => {
      state.createExpenseLoading = false;
      state.createExpenseError = action.payload;
    });

    // delete expenses
    builder.addCase(deleteMultipleExpenses.pending, (state, action) => {
      state.deleteExpenseLoading = true;
    });
    builder.addCase(deleteMultipleExpenses.fulfilled, (state, action) => {
      state.deleteExpenseLoading = false;
      state.expenseResponse = state.expenseResponse.filter(
        ({ id }) => !state.selectedExpenses.includes(id)
      );
      state.selectedExpenses = [];
    });
    builder.addCase(deleteMultipleExpenses.rejected, (state, action) => {
      state.deleteExpenseLoading = false;
    });

    // update multiple expenses
    builder.addCase(updateMultipleExpenses.pending, (state, action) => {
      state.updateExpenseLoading = true;
    });
    builder.addCase(updateMultipleExpenses.fulfilled, (state, action) => {
      state.updateExpenseLoading = false;
      let updatedExpenseResponse = action.payload.expenseList.filter(
        ({ id }) => !state.selectedExpenses.includes(id)
      );
      updatedExpenseResponse = [
        ...updatedExpenseResponse,
        ...action.payload?.response?.data?.map((expense) => {
          return { ...expense, partyName: action.payload.partyName };
        }),
      ].sort((a, b) => b.expenseNumber - a.expenseNumber);

      // resetting fields
      state.bulkUpdateFields = [
        {
          id: Math.random(),
          bulkUpdateLeftField: "expenseAccount",
          bulkUpdateRightField: "",
        },
      ];
      state.bulkUpdateDropdownVisible = false;
      state.bulkUpdatePartyName = "";
      state.expenseResponse = updatedExpenseResponse;
      state.selectedExpenses = [];
      state.paidThroughDropdownVisible = false;
      state.paymentList = [
        {
          id: Math.random(),
          paymentAmount: "",
          paymentMode: "",
          transactionDetails: "",
        },
      ];
    });
    builder.addCase(updateMultipleExpenses.rejected, (state, action) => {
      state.updateExpenseLoading = false;
    });

    builder.addCase(updateExpense.pending, (state, action) => {
      state.createExpenseLoading = true;
      state.createExpenseError = false;
    });
    builder.addCase(updateExpense.fulfilled, (state, action) => {
      const { response, expenseList } = action.payload;
      state.createExpenseLoading = false;
      state.createExpenseError = false;
      state.expenseResponse = expenseList.map((expense) =>
        expense.id === response.data.id ? response.data : expense
      );
    });
    builder.addCase(updateExpense.rejected, (state, action) => {
      state.createExpenseLoading = false;
      state.createExpenseError = action.payload;
    });
  },
});

export default expenseSlice.reducer;
export const {
  CLEAR_REDUX_STORE_21,
  updateRecordExpenseDrawerVisible,
  updateAccountType,
  updateDueDateDropdownVisible,
  updateDuesDropdownVisible,
  updateDuesFilter,
  updateBulkUpdateDropdownVisible,
  updateVisibleCalendar,
  updateSelectedInitialDate,
  updateSelectedFinalDate,
  updateInitialDate,
  updateFinalDate,
  updateDisplayPartyFilter,
  updateSelectedPartyFilter,
  updateBulkUpdateFields,
  addRemoveBuldUpdateFields,
  updateViewExpanseDrawerVisible,
  updateExpenseObject,
  updateAccountSearchString,
  updatePartySearchString,
  updateExpenseDateCalendarVisible,
  updateExpenseDate,
  removeUploadedUrl,
  updateUploadedUrl,
  updateDisplayIdModal,
  updateDueDateCalendarVisible,
  updateDueDate,
  updateSelectedExpenses,
  updateAddExpenseAccountDropdownVisible,
  updateAddPartyDropdownVisible,
  updateAddPartyDropdownVisibleNew,
  
  updateNewExpenseAccountName,
  updateNewPartytName,
  updatePaidThroughDropdownVisible,
  addPayment,
  removePayment,
  updatePaymentList,
  resetPaymentList,
  updateSelectItemDropdownVisible,
  updateSelectedExpenseObject,
  updateSelectedExpenseObjects,
  updateSelectedPartyName,
  updateDisplayDeleteExpenseModal,
  updateYearMonthCalendarVisible,
  updateYearMonthDate,
  addExpenseReset,
  updateBulkUpdatePartyName,
  updateShowExpenseDateCalendar,
  updateShowDueDateCalendar,
  resetBulkUpdateFields,
  resetVendorExpenseState,
  updateDueDates,
  resetDueDates,
  updateDueDateFilter,
  updateInFinateScroll,
  updateInFinateScrollLoading,
  updatePageNo,
  resetInfiniteScrolling,
  updateItemsList,
  updateIsAddPayment,
  updateIsEditingExpense,
  updateAddStaffDropdownVisible,
  updateNewStafftName,
  updateAddStaffIndex,
  resetUploadedFileUrl,
  updateSearchText,
} = expenseSlice.actions;
