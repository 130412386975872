import React, { useRef, useState } from "react";

import { Checkbox, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { updateChannel } from "../../reducer/channelManagerReducer";

const Channels = () => {
  const { selectedRoomType, selectedChannel, selectedChannelType } =
    useSelector((store) => store.channelManager);
  const { hotelDetails } = useSelector((store) => store.login);
  const dispatch = useDispatch();

  const [showSelectChannels, setShowSelectChannels] = useState(false);

  const [channel, setChannel] = useState("Select Channels");

  const channelRef = useRef();

  // store all channels based on selected room type
  let allChannel = [];
  hotelDetails?.roomTypes?.forEach((item) => {
    if (item.name === selectedRoomType) {
      if (Boolean(item.channels)) {
        allChannel = item.channels;
      }
    }
  });

  const changeChannel = (channel) => {
    // update redux store
    // if select all or select individual

    if (channel === "all") {
      setChannel("All Channels");
      dispatch(
        updateChannel({
          roomType: selectedRoomType,
          channelType: "all",
          channel: allChannel,
        })
      );
    } else {
      setChannel(channel);
      dispatch(
        updateChannel({
          roomType: selectedRoomType,
          channelType: "specific",
          channel: channel,
        })
      );
    }
  };

  const handleBlur = (event) => {
    if (!channelRef.current.contains(event.relatedTarget)) {
      setShowSelectChannels(false);
    }
  };

  return (
    <>
      <div
        ref={channelRef}
        tabIndex={0}
        onClick={() => {
          setShowSelectChannels(true);
        }}
        onBlur={handleBlur}
        className="w-full border h-[2.8rem] rounded-md bg-[#ffffff] relative"
      >
        <div className="flex items-center p-2 justify-between h-full cursor-pointer text-[#00000080]">
          {channel}
          <DownOutlined style={{ color: "#00000080" }} />
        </div>
        {showSelectChannels && (
          <div className="w-full absolute top-[2.9rem] p-2 bg-[#ffffff] rounded-md border z-10">
            {allChannel.length > 0 ? (
              <>
                <div className="mb-2">
                  <Checkbox
                    checked={selectedChannelType === "all" ? true : false}
                    onChange={() => changeChannel("all")}
                  >
                    All Channel
                  </Checkbox>
                </div>
                {allChannel.map((item) => {
                  return (
                    <div key={item} className="mb-2">
                      <>
                        {selectedChannelType === "all" ? (
                          <Checkbox
                            checked={true}
                            onChange={() => changeChannel(item)}
                          >
                            {item}
                          </Checkbox>
                        ) : (
                          <Checkbox
                            checked={selectedChannel.indexOf(item) > -1}
                            onChange={() => changeChannel(item)}
                          >
                            {item}
                          </Checkbox>
                        )}
                      </>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="mb-2">
                  <p>No Channel present</p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <Select
        size="large"
        placeholder={
          <div className="text-[16px] font-normal text-[#2F80ED]">
            Advance Settings
          </div>
        }
        suffixIcon={
          <DownOutlined style={{ color: "#2F80ED", pointerEvents: "none" }} />
        }
        className="mt-[2rem]"
        bordered={false}
      ></Select>
    </>
  );
};
export default Channels;
