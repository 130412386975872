import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "",
  message: "",
  isVisible: false,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_8: () => initialState,
    updateAlert: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.isVisible = action.payload.isVisible;
    },
  },
});

export const { updateAlert, CLEAR_REDUX_STORE_8 } = alertSlice.actions;
export default alertSlice.reducer;
