import React, { useEffect, useState } from "react";
import ItemCard from "./itemsCard/ItemCard";
import { useDispatch, useSelector } from "react-redux";
import AllKOT from "../../allKOT";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlus } from "react-icons/fa6";
import { TranslateText } from "../../../../utils/translation";
import {
  addItems,
  updateItemSearch,
} from "../../../../reducer/posReducer/restaurant";
import HeaderRevamped from "./posRevampedHeader/Index";
import {
  clearSelectedItem,
  getAllItems,
  updateAction,
} from "../../../../reducer/getItemReducer";
import { updateIsAddItemDrawerOpen } from "../../../../reducer/getItemReducer";
import { POS_SERVICE_TYPE, POS_TYPE } from "../../../../utils/posHelper";
import { Button, Input } from "antd";

function MainContainer({ items, setItems }) {
  const dispatch = useDispatch();
  const { tableOrder } = useSelector((state) => state.pos);
  const { activePosId, activePosType } = useSelector(
    (store) => store.posSetting
  );
  const { hotelDetails } = useSelector((store) => store.login);

  let allItems = useSelector((state) =>
    Boolean(state?.getItems?.data) ? state?.getItems.data : []
  );

  const { loading: itemLoading, error: itemError } = useSelector(
    (store) => store.getItems
  );

  useEffect(() => {
    let serviceTypes =
      tableOrder?.type === "table" ? POS_SERVICE_TYPE[0] : tableOrder?.type;

    dispatch(
      getAllItems({
        hotelId: hotelDetails.id,
        posId: activePosId,
        serviceTypes: activePosType === POS_TYPE[0] ? serviceTypes : null,
      })
    );
  }, [hotelDetails?.id, activePosId]);

  allItems = Array.isArray(allItems)
    ? allItems?.filter((item) => item.active)
    : [];

  const { activePosSlug, response, activePosName } = useSelector(
    (store) => store.posSetting
  );

  const categories = response?.find(
    (item) => item.slug === activePosSlug
  )?.itemCategories;

  const [activeMenu, setActiveMenu] = useState("All Menu");
  let Categories = categories?.length > 0 ? ["All Menu", ...categories] : [];

  const { searchItemText, filteredFoodType } = useSelector(
    (store) => store.pos
  );

  let categoryFilteredItems =
    activeMenu === "All Menu"
      ? allItems
      : allItems?.filter((item) => item?.category === activeMenu);

  let filterItems = Boolean(searchItemText)
    ? categoryFilteredItems?.filter((item) => {
        return (
          item.name?.toLowerCase().includes(searchItemText.toLowerCase()) ||
          item.category?.toLowerCase().includes(searchItemText.toLowerCase())
        );
      })
    : categoryFilteredItems;

  filterItems = Boolean(filteredFoodType)
    ? filterItems.filter((item) => {
        if (filteredFoodType === "veg") {
          return item.cuisineType === "veg";
        } else {
          return item.cuisineType === "nonVeg";
        }
      })
    : filterItems;

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const handleMenu = (menu) => {
    setActiveMenu(menu);

    const filteredItemsByCategory = allItems?.filter(
      (item) => item.category?.toLowerCase() === menu?.toLowerCase()
    );

    dispatch(
      menu?.toLowerCase() === "all menu"
        ? addItems(allItems)
        : addItems(filteredItemsByCategory)
    );
  };

  const { data } = useSelector((store) => store.order);

  let isKotExist = data?.length > 0 ? true : false;

  let numOfKot = data?.length;

  const handleAddItem = () => {
    dispatch(updateAction("add"));
    dispatch(clearSelectedItem());
    dispatch(updateIsAddItemDrawerOpen(true));
  };

  return (
    <div
      className={`bg-[#FAFAFA]  p-0 m-0  relative min-h-screen overflow-hidden `}
    >
      <HeaderRevamped headerName={activePosName} />

      <div className="px-6 py-6">
        <div className="rounded mb-4">
          <div className="w-full overflow-hidden rounded  py-1 flex">
            <Input
              type="text"
              className="w-[100%] bg-gray-50 border border-gray-300 text-gray-900 text-sm  rounded focus:outline-none	  block mr-2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white mb-2"
              placeholder="Search item by Category and Name"
              value={searchItemText}
              onChange={(e) => dispatch(updateItemSearch(e.target.value))}
            />
          </div>

          <Slider {...settings} className="mt-[5px] flex overflow-hidden pr-10">
            {Categories?.map((item) => {
              return (
                <div
                  onClick={() => handleMenu(item)}
                  className={`${
                    activeMenu === item
                      ? "bg-[#2F80ED] text-white"
                      : "text-[#808080]"
                  } border border-[#DEDEDE]  p-[3px] px-[10px]  rounded text-sm  mr-3 text-center mb-[5px] cursor-pointer`}
                >
                  {item?.length > 14 ? item?.substring(0, 15) + ".." : item}
                </div>
              );
            })}
          </Slider>
        </div>

        <div
          className={`grid grid-cols-2 lg:grid-cols-3 gap-5 pb-[10rem]  overflow-y-auto ${
            isKotExist
              ? numOfKot > 3
                ? "max-h-[55vh]"
                : "max-h-[50vh]"
              : "max-h-[50vh]"
          } `}
        >
          {itemLoading ? (
            <>
              {[...Array(8)]?.map((_, index) => {
                return (
                  <div
                    key={index}
                    className={`h-[130px] bg-blue-100 rounded-[12px] flex justify-end border overflow-hidden`}
                  >
                    <div className="w-[96.4%] h-full bg-white rounded-r-none text-center flex justify-center items-center">
                      <h1 className="text-center mt-2 text-slate-500 text-sm">
                        loading..
                      </h1>
                    </div>
                  </div>
                );
              })}
            </>
          ) : itemError == false ? (
            <div className="text-center py-5">
              <h1 className="text-red-500">
                <TranslateText
                  textKey={"something_went_wrong"}
                  namespace="common"
                />
              </h1>
            </div>
          ) : (
            <>
              {filterItems?.length > 0 ? (
                <>
                  {filterItems?.map((item) => {
                    return <ItemCard key={item.id} item={item} />;
                  })}

                  <div
                    className={`h-[80px] rounded-r-[10px]  overflow-hidden flex justify-center items-center `}
                  >
                    <div
                      onClick={() => handleAddItem()}
                      className="flex cursor-pointer"
                    >
                      <FaPlus className="mt-1 text-primary" />
                      <h1 className="text-primary font-semibold">
                        <TranslateText textKey={"add_item"} namespace="pos" />
                      </h1>
                    </div>
                  </div>
                </>
              ) : (
                <div className="w-full absolute flex justify-center items-center h-72">
                  <div className="flex">
                    <Button onClick={() => handleAddItem()} className="flex">
                      <FaPlus className="mt-1 text-primary" />
                      <h1 className="text-primary font-semibold">
                        <TranslateText textKey={"add_item"} namespace="pos" />
                      </h1>
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <AllKOT />
      </div>
    </div>
  );
}

export default MainContainer;
