import React, { createContext, useEffect, useState } from "react";
// import PosOrderContainer from "./../posContainer/Index";
import Items from "./Items";
import PreviewContainer from "./OrderPreview";

// import { getAllItems } from "../../reducer/getItemReducer";

import PosHeader from "../posContainer/header";
import {
  clearSelectedItem,
  getAllItems,
} from "../../../reducer/getItemReducer";
import AddItemPreview from "./AddItem";
import AddItem from "./AddItem";
import EditItem from "./EditItem";
import { useDispatch, useSelector } from "react-redux";

const Index = () => {
  const dispatch = useDispatch();
  const { hotelDetails } = useSelector((store) => store.login);
  const { action, loading, error } = useSelector((store) => store.getItems);
  const { activePosId, activePosName } = useSelector(
    (store) => store.posSetting
  );

  useEffect(() => {
    let payload = {
      hotelId: hotelDetails?.id,
      posId: activePosId,
    };

    dispatch(getAllItems(payload));

    return () => {
      dispatch(clearSelectedItem());
    };
  }, [hotelDetails?.id, activePosId]);

  // const [selectedItem, setSelectedItem] = useState({});
  // const [selectedPreview, setSelectedPreview] = useState({
  //   NoItemPreview: false,
  //   AddItemPreview: false,
  //   EditItemPreview: false,
  // });
  // const [preview, setPreview] = useState({
  //   // newOrder: true,
  //   allOrders: true,
  //   items: false,
  // });
  // const [reviewOrderPreview, setReviewOrderPreview] = useState({
  //   addDiscount: true,
  //   serviceCharge: false,
  //   customCharge: false,
  //   applyTaxes: false,
  // });

  const allitems = useSelector((state) => state.getItems.data);
  return (
    <div className="flex  ">
      <div className="w-[20%] sm:w-[35%] md:w-[45%] lg:w-[50%] xl:w-[54%] fixed m-4">
        <PosHeader posHeader="All Items" from="itemList" />
        <Items itemslist={allitems} loading={loading} error={error} />
      </div>
      <div className="bg-[#F4F4F4] fixed right-0 w-[18%] sm:w-[20%] md:w-[28%] lg:w-[28%] overflow-y-scroll">
        {action === "" ? (
          <PreviewContainer />
        ) : action === "add" ? (
          <AddItem />
        ) : (
          <EditItem />
        )}
      </div>
    </div>
  );
};

export default Index;
