import { authedAxios } from "../utils/customHttpHeader";

export const fetchAllIngredient = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelId=${payload?.hotelId}`;

  if (payload?.posId) url += `&posId=${payload?.posId}`;
  if (payload?.name) url += `&name=${payload?.name}`;
  if (payload?.suppliersIds) url += `&suppliersIds=${payload?.suppliersIds}`;

  const response = await authedAxios().get(url);
  return response;
};

export const postNewIngredient = async (ingredientObject) => {
  const response = await authedAxios().post(`ingredients`, ingredientObject);
  return response;
};

export const putExistingIngredient = async (payload) => {
  const { ingredientIdToEdit, ingredientObject } = payload;
  const response = await authedAxios().put(
    `ingredients/${ingredientIdToEdit}`,
    { ...ingredientObject, id: ingredientIdToEdit }
  );
  return response;
};

export const deleteExistingIngredient = async (payload) => {
  const { id, hotelId } = payload;
  const response = await authedAxios().delete(
    `ingredients/${id}?hotelId=${hotelId}`
  );
  return response;
};

export const postRestockService = async (endpoint, payload) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

export const postRecountService = async (endpoint, payload) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

export const removeIngredientService = async (payload) => {
  const { hotelId, id } = payload;
  const url = `ingredients/${id}?hotelId=${hotelId}`;

  const response = await authedAxios().delete(url);
  return response;
};
