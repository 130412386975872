import React, { useState } from "react";
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai";

const CustomInput = ({ time, setTime, min, max }) => {
  const onChange = (value) => {
    // if (value >= min && value <= max) setTime(value);
    // else if (value > max) setTime(max);
    // else if (value <= min) setTime(min);
    setTime(value);
  };
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="h-[2rem]">
      <div
        className={`flex justify-center items-center p-2 gap-1 h-[2.5rem] py-[6px] w-[6ch] bg-[#F0F3F7] text-[#4D4D4D] rounded-lg`}
      >
        <input
          type="number"
          className={` ${
            isFocused ? "border border-[#2F80ED80] rounded-md" : ""
          } focus:outline-none  w-[1.7rem] bg-[#F0F3F7] px-[5px] h-[100%]`}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={time}
          // min={min}
          // max={max}
          onChange={(e) => onChange(e.target.value)}
        />
        <div
          className="
              h-[100%]"
        >
          <AiOutlineCaretUp
            className="cursor-pointer text-[#808080] "
            onClick={() =>
              setTime((state) => (state < max ? state + 1 : state))
            }
          />
          <AiOutlineCaretDown
            className="cursor-pointer text-[#808080]"
            onClick={() =>
              setTime((state) => (state > min ? state - 1 : state))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CustomInput;
