import React, { useState } from "react";
import { ReactComponent as Tray } from "../../../../assets/images/svgs/tray.svg";
import {
  updatePriceBreakUp,
  updateSelectedOderItem,
  updateSelectedOrderItemCountMap,
} from "../../../../reducer/posReducer/restaurant";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronDown } from "react-icons/fa";
import EditKotDrawer from "./EditKotDrawer";

const Main = () => {
  const { data, invoicePostData } = useSelector((store) => store.order);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  return (
    <main className="pt-[7rem] pb-[1rem]">
      <div className="font-outfit font-[500] text-[16px] leading-[20.16px] text-[#222] mb-3 pl-3">
        Order KOT
      </div>

      <EditKotDrawer
        open={open}
        setOpen={setOpen}
        runningOrders={data}
        runningInvoice={invoicePostData}
      />

      <div className="flex flex-col gap-4 items-center">
        {data?.map((Obj, idx) => {
          let itemCount = Obj?.itemCountMap;
          let totalCount = 0;

          for (const key in itemCount) {
            if (itemCount.hasOwnProperty(key)) {
              totalCount += itemCount[key].count;
            }
          }

          const status = Obj?.status;

          return (
            <div
              className="rounded-[12px] flex flex-col justify-between  bg-[#FFF]  w-[95%] mx-auto p-3 "
              key={Obj?.id}
            >
              <div className="flex w-[100%] justify-between items-center">
                <div className="leftPart flex flex-col gap-2">
                  <div className="font-outfit font-[500] text-[16px] leading-[20px] tracking-wider text-[#B84F00]  flex justify-center items-center bg-[#FFF7DB] rounded-[5px] p-1 px-2">
                    KOT NO - {Obj?.tokenNum}
                  </div>

                  <div className="font-outfit font-[400] text-[12px] leading-[18px] text-[#8F9BB3] flex justify-center items-center gap-2">
                    {Obj?.stewardNames?.[0]}{" "}
                    {Obj?.stewardNames?.[0] && <Tray />}
                  </div>
                </div>

                <div className="rightPart flex flex-col items-end gap-2">
                  <div className="text-[#000] font-outfit font-[500] text-[14px] leading-[21px] uppercase">
                    {status === "inProgress" ? (
                      <span className=" rounded-[5px] px-3 bg-[#F9A63A] py-2 text-[#FFF] font-outfit font-[700] text-[10px] leading-[20px]  bg-[#F9A63A]tracking-wider">
                        In Progress
                      </span>
                    ) : status === "billed" ? (
                      <span className="rounded-[5px] px-3 py-2  bg-[#F36960] text-[#FFF] font-outfit font-[700] text-[10px] leading-[20px] tracking-wider">
                        Billed
                      </span>
                    ) : status === "paid" ? (
                      <span className="rounded-[5px] px-3 py-2 bg-[#509508] text-[#FFF] font-outfit font-[700] text-[10px] leading-[20px] tracking-wider">
                        Paid
                      </span>
                    ) : (
                      <span className="rounded-[5px] px-3 py-2 bg-[#F36960] text-[#FFF] font-outfit font-[700] text-[10px] leading-[20px] tracking-wider">
                        {status}
                      </span>
                    )}
                  </div>

                  <div
                    className="flex gap-1 justify-center items-center"
                    onClick={() => {
                      dispatch(updateSelectedOderItem(Obj));
                      dispatch(updatePriceBreakUp());

                      let payload = {
                        type: "whole",
                        data: Obj?.itemCountMap,
                      };

                      dispatch(updateSelectedOrderItemCountMap(payload));

                      setOpen(true);
                    }}
                  >
                    <div className="font-outfit text-[12px] font-[500] leading-[18px] text-[#222222] ">
                      {Boolean(totalCount > 1)
                        ? `${totalCount} Items`
                        : `${totalCount} Item`}{" "}
                    </div>
                    <FaChevronDown className="text-[#007FFF] text-[12px]" />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default Main;
