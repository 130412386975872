import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import { GET_ISO_FORMAT } from "../../../../utils/helper";

import {
  getAllRoomsAsyncThunk,
  getInActiveRoomsAsyncThunk,
} from "../../../../reducer/roomsReducer";

import {
  updateSelectedRoomStatus,
  updateHousekeeperCountMap,
  updateDirtyInspectCleanCountMap,
  updateIsOutOfOrderActive,
  resetFilters,
  updateHousekeeperRooms,
} from "../../../../reducer/housekeepingReducer";
import { TranslateText } from "../../../../utils/translation";

import { getBookingList } from "../../../../reducer/bookingReducer";

import RoomsGrid from "../roomsGrid";

const LeftView = () => {
  const [groupedRoomsByType, setGroupedRoomsByType] = useState({});
  const [upcomingBookingsRoomsId, setUpcomingBookingsRoomsId] = useState([]);
  const [checkedInRoomId, setCheckedInRoomId] = useState([]);
  const [completedRoomId, setCompletedRoomId] = useState([]);
  const [combinedFilterArray, setCombinedFilterArray] = useState([]);

  const { hotelDetails } = useSelector((store) => store.login);
  const { data, inActiveRooms } = useSelector((store) => store.rooms);
  const {
    selectedRoomStatus,
    isUpcomingFilter,
    isOngoingFilter,
    isCheckoutFilter,
    dirtyInspectCleanCountMap,
    isOutOfOrderActive,
  } = useSelector((store) => store.housekeeping);
  const { response } = useSelector((store) => store.booking);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOutOfOrderActive) {
      const updatedCombinedFilterArray = [];
      if (isUpcomingFilter) {
        updatedCombinedFilterArray.push(...upcomingBookingsRoomsId);
      }
      if (isOngoingFilter) {
        updatedCombinedFilterArray.push(...checkedInRoomId);
      }
      if (isCheckoutFilter) {
        updatedCombinedFilterArray.push(...completedRoomId);
      }
      setCombinedFilterArray(updatedCombinedFilterArray);
    }
  }, [isUpcomingFilter, isOngoingFilter, isCheckoutFilter, response?.bookings]);

  useEffect(() => {
    if (hotelDetails?.id) {
      dispatch(getAllRoomsAsyncThunk({ hotelId: hotelDetails?.id }));
      dispatch(
        getInActiveRoomsAsyncThunk({
          hotelId: hotelDetails?.id,
        })
      );
      let payload = {
        hotelId: hotelDetails?.id,
        statuses: "confirmed,checkedIn,completed",
        pageSize: 1000,
        startDate: GET_ISO_FORMAT(moment().startOf("day")),
        endDate: GET_ISO_FORMAT(moment().endOf("day")),
      };
      dispatch(getBookingList(payload));
    }
  }, [hotelDetails?.id]);

  useEffect(() => {
    const updatedGroupedRooms = (
      isOutOfOrderActive
        ? inActiveRooms
        : data?.filter(({ status }) => status === "active")
    )
      ?.filter(({ houseKeepingStatus }) =>
        isOutOfOrderActive
          ? true
          : selectedRoomStatus.length
          ? selectedRoomStatus === houseKeepingStatus
          : true
      )
      ?.filter(({ id }) =>
        isOutOfOrderActive
          ? true
          : isUpcomingFilter || isOngoingFilter || isCheckoutFilter
          ? combinedFilterArray.includes(id)
          : true
      )
      ?.reduce((result, room) => {
        if (!result[room.type]) {
          result[room.type] = [];
        }
        result[room.type].push(room);
        return result;
      }, {});
    setGroupedRoomsByType(updatedGroupedRooms);

    // Functionality to calculate housekeeper assigned rooms count and clean,inspect and dirty room count
    const housekeeperCounts = {};
    const statusCount = {};
    const updatedHousekeeperRooms = {};
    data
      ?.filter(({ status }) => status === "active")
      ?.forEach((item) => {
        if (
          item.houseKeepingStatus === selectedRoomStatus &&
          item.houseKeeperName
        ) {
          const { id, houseKeeperName } = item;
          if (updatedHousekeeperRooms.hasOwnProperty(houseKeeperName)) {
            updatedHousekeeperRooms[houseKeeperName].push(id);
          } else {
            updatedHousekeeperRooms[houseKeeperName] = [id];
          }
        }
        if (
          item.houseKeepingStatus === selectedRoomStatus &&
          item.houseKeeperName
        ) {
          const housekeeperName = item.houseKeeperName;
          housekeeperCounts[housekeeperName] =
            (housekeeperCounts[housekeeperName] || 0) + 1;
        }

        if (item.houseKeepingStatus) {
          const status = item.houseKeepingStatus;
          statusCount[status] = (statusCount[status] || 0) + 1;
        }
      });

    dispatch(updateHousekeeperRooms(updatedHousekeeperRooms));
    dispatch(updateHousekeeperCountMap(housekeeperCounts));
    dispatch(updateDirtyInspectCleanCountMap(statusCount));
  }, [
    data,
    selectedRoomStatus,
    combinedFilterArray,
    isOutOfOrderActive,
    inActiveRooms,
  ]);

  useEffect(() => {
    if (response.bookings) {
      let roomIds = [];
      let checkedInRoomIds = [];
      let completedRoomIds = [];

      response.bookings
        .filter((booking) => {
          return booking.status === "confirmed";
        })
        .forEach((booking, idx) => {
          const rooms = booking.rooms;
          if (Array.isArray(rooms)) {
            roomIds = roomIds.concat(rooms);
          }
        });

      setUpcomingBookingsRoomsId([...new Set(roomIds)]);

      response.bookings
        .filter((booking) => booking.status === "checkedIn")
        .forEach((booking) => {
          const rooms = booking.rooms;
          if (Array.isArray(rooms)) {
            checkedInRoomIds = checkedInRoomIds.concat(rooms);
          }
        });
      setCheckedInRoomId([...new Set(checkedInRoomIds)]);

      response.bookings
        .filter((booking) => booking.status === "completed")
        .forEach((booking) => {
          const rooms = booking.rooms;
          if (Array.isArray(rooms)) {
            completedRoomIds = completedRoomIds.concat(rooms);
          }
        });
      setCompletedRoomId(completedRoomIds);
    }
  }, [response?.bookings]);

  return (
    <div className="w-[70%] px-[30px] pt-[50px] pb-[25px] max-h-[85vh] overflow-y-auto no-scrollbar relative">
      {
        <div
          className={`h-[50px] w-[54%] bg-[#f4f4f4] rounded-[10px] flex mx-auto absolute top-[23.5px] left-[22.5%] z-10`}
        >
          <button
            className={`w-1/4 border-r-[1px] border-r-[#e2e2e2] h-full flex items-center justify-center rounded-l-[10px] flex-nowrap ${
              selectedRoomStatus === "dirty"
                ? "text-[#2F80ED] bg-[#DFEEFD]"
                : "text-[#7a7a7a]"
            }`}
            onClick={() => {
              dispatch(updateIsOutOfOrderActive(false));
              dispatch(updateSelectedRoomStatus("dirty"));
            }}
          >
            <span className="font-[500] text-[16px] leading-[24px]">
              {/* <TranslateText textKey={"dirty"} namespace="housekeeping" /> */}
              {`Dirty (${
                Boolean(dirtyInspectCleanCountMap["dirty"])
                  ? dirtyInspectCleanCountMap["dirty"]
                  : 0
              })`}
            </span>
          </button>
          <button
            className={`w-1/4 border-r-[1px] border-r-[#e2e2e2] h-full flex items-center justify-center ${
              selectedRoomStatus === "inspect"
                ? "text-[#2F80ED] bg-[#DFEEFD]"
                : "text-[#7a7a7a]"
            }`}
            onClick={() => {
              dispatch(updateIsOutOfOrderActive(false));
              dispatch(updateSelectedRoomStatus("inspect"));
            }}
          >
            <span className="font-[500] text-[16px] leading-[24px]">
              {/* <TranslateText textKey={"inspect"} namespace="housekeeping" /> */}
              {`Inspect (${
                Boolean(dirtyInspectCleanCountMap["inspect"])
                  ? dirtyInspectCleanCountMap["inspect"]
                  : 0
              })`}
            </span>
          </button>
          <button
            className={`w-1/4 h-full flex items-center justify-center border-r-[1px] border-r-[#e2e2e2] flex-nowrap ${
              selectedRoomStatus === "clean"
                ? "text-[#2F80ED] bg-[#DFEEFD]"
                : "text-[#7a7a7a]"
            }`}
            onClick={() => {
              dispatch(updateIsOutOfOrderActive(false));
              dispatch(updateSelectedRoomStatus("clean"));
            }}
          >
            <span className="font-[500] text-[16px] leading-[24px] ">
              {/* <TranslateText textKey={"clean"} namespace="housekeeping" /> */}
              {`Clean (${
                Boolean(dirtyInspectCleanCountMap["clean"])
                  ? dirtyInspectCleanCountMap["clean"]
                  : 0
              })`}
            </span>
          </button>
          <button
            className={`min-w-[155px] px-[10px] py-[12.5px] h-full flex items-center justify-center rounded-r-[10px] flex-nowrap ${
              isOutOfOrderActive
                ? "text-[#2F80ED] bg-[#DFEEFD]"
                : "text-[#7a7a7a]"
            }`}
            onClick={() => {
              dispatch(resetFilters());
              dispatch(updateSelectedRoomStatus(""));
              dispatch(updateIsOutOfOrderActive(true));
            }}
          >
            <span className="font-[500] text-[16px] leading-[24px] ">
              {/* <TranslateText
                textKey={"out_of_order"}
                namespace="housekeeping"
              /> */}
              {`Out of order (${
                Boolean(inActiveRooms?.length) ? inActiveRooms?.length : 0
              })`}
            </span>
          </button>
        </div>
      }
      {/* <button
        className={`rounded-[13px] text-[12px] leading-[18px] py-[4px] px-[12px] absolute z-10 right-[10%] transform -translate-y-1/2  w-[96px] ${
          isOutOfOrderActive
            ? "bg-[#DFEEFD] text-[#2F80ED]"
            : "bg-[#f4f4f4] text-[#7a7a7a]"
        }`}
        onClick={() => {
          dispatch(resetFilters());
          dispatch(updateIsOutOfOrderActive(isOutOfOrderActive ? false : true));
          dispatch(updateSelectedRoomStatus(""));
        }}
      >
        Out of order
      </button> */}

      <RoomsGrid
        groupedRoomsByType={groupedRoomsByType}
        upcomingBookingsRoomsId={upcomingBookingsRoomsId}
        checkedInRoomId={checkedInRoomId}
        completedRoomId={completedRoomId}
        combinedFilterArray={combinedFilterArray}
        roomsArray={data?.filter(({ status }) => status === "active")}
      />
    </div>
  );
};

export default LeftView;
