import { authedAxios } from "../utils/customHttpHeader";

export const getItemByIdService = async (itemId) => {
  const response = await authedAxios().get(`items/${itemId}`);
  return response;
};

export const getAllItemsService = async (hotelId) => {
  const response = await authedAxios().get("items", {
    params: { hotelId },
  });
  return response.data;
};

export const getItems = async (payload) => {
  let path = `items?hotelId=${payload.hotelId}&posId=${payload.posId}`;

  if (Boolean(payload?.serviceTypes)) {
    path += `&serviceTypes=${payload?.serviceTypes}`;
  }

  const response = await authedAxios().get(path);
  return response;
};

export const postItem = async (payload) => {
  const response = await authedAxios().post("/items", payload);
  return response;
};

export const patchItem = async (payload) => {
  const response = await authedAxios().patch(`/items/${payload.id}`, payload);
  return response;
};
