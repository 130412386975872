import React from "react";
import ItemCard from "../itemsCard/ItemCard";
import { useSelector } from "react-redux";

const RunningOrder = () => {
  const { selectedItem } = useSelector((store) => store.pos);

  const { data: allItems } = useSelector((store) => store.getItems);

  let selectedItemWithQuantity = [];
  let itemCountMap = {};

  Object.keys(selectedItem)?.forEach((id) => {
    allItems?.forEach((item) => {
      if (item.id === id && selectedItem[id].count !== 0) {
        selectedItemWithQuantity.push({
          ...item,
          quantity: selectedItem[id].count,
        });

        itemCountMap[id] = {
          count: selectedItem[id].count,
          instruction: Boolean(selectedItem[id].instruction)
            ? selectedItem[id].instruction
            : null,
        };

        // calculate item addon price
        item.addOns?.forEach((addonItem) => {
          if (Boolean(selectedItem[id][addonItem.name])) {
            itemCountMap[id] = {
              ...itemCountMap[id],
              addOns: {
                ...itemCountMap?.addOns,
                [addonItem?.name]: selectedItem[id][addonItem.name],
              },
            };
          }
        });
      }
    });
  });

  return (
    <div className="w-[100%] pt-[6.5rem] p-3  flex flex-col gap-3">
      <div className="font-outfit font-[500] text-[16px] leading-[20.16px] text-[#222222] pl-2">
        Orders
      </div>

      <div className="w-[98%] mx-auto bg-[#FFF] rounded-[12px]">
        {selectedItemWithQuantity?.map((item, idx) => {
          return (
            <div className="flex flex-col items-center justify-center">
              <ItemCard
                key={item.id}
                item={item}
                start={Boolean(idx === 0)}
                end={Boolean(idx === selectedItemWithQuantity?.length - 1)}
              />
              {idx !== selectedItemWithQuantity?.length - 1 && (
                <div className="border-[1px] border-dashed border-[#EBEBEB] w-[100%] mx-3" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RunningOrder;
