import { Suspense, lazy } from "react";
import Loading from "../../pages/loading";
const Payments = lazy(() => import("../../components/payments/Index"));

const Index = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Payments />
    </Suspense>
  );
};

export default Index;
