import React from "react";
import "./Index.css";

const Index = ({
  width = "8rem",
  height = "9.5rem",
  noOfContainer = 5,
  flexCol = false,
  gap = 8,
  marginTop = "0",
}) => {
  const gapClass = `gap-${gap}`;
  const marginTopClass = `mt-[${marginTop}]`;

  return (
    <div
      className={`flex ${
        flexCol ? "flex-col" : "flex-wrap"
      } ${gapClass} ${marginTopClass} px-4`}
    >
      {[...Array(noOfContainer)].map((_, index) => (
        <div
          key={index}
          className="rounded-[10px] skeleton-box"
          style={{ width: `${width}`, height: `${height}` }}
        />
      ))}
    </div>
  );
};

export default Index;
