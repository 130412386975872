import React from "react";
import PosHeader from "../posContainer/header";
import PosForms from "./PosForms";

export const NewPos = () => {
  return (
    <div className="p-4">
      <PosHeader from="newPos" posHeader="Create New Pos" />
      <PosForms />
    </div>
  );
};
