import React from "react";

export const Rooms = (props) => {
  const { rooms, roomType, deleteRooms, selectDeleteRooms } = props;

  return (
    <div className="flex flex-wrap gap-[25px] mt-[30px]">
      {rooms?.map((room, index) => {
        return (
          <div
            key={`${roomType}/${index}`}
            className={`border border-[#4F4F4F] rounded-[5px] ${
              deleteRooms.includes(room)
                ? "text-[#ffffff] bg-[#A3A8AE] border-[#A3A8AE]"
                : "text-[#4F4F4F] bg-[#f4f4f4] border-[#4f4f4f]"
            }`}
          >
            <input
              type="checkbox"
              id={`${roomType}/${index}`}
              className="hidden"
              name={roomType}
              defaultValue={roomType}
              onClick={(e) => selectDeleteRooms(e, room)}
              // value={room}
            />
            <label
              htmlFor={`${roomType}/${index}`}
              className="text-lg  py-[14px] border border-[#4F4F4F] px-[35px] rounded-[5px] block  cursor-pointer"
            >
              {room}
            </label>
          </div>
        );
      })}
    </div>
  );
};
