import React from "react";
import { Button, Input, message } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedItem } from "../../../../../reducer/posReducer/restaurant";
import { GET_ITEM_PRICE } from "../../../../../utils/posHelper";
import { getCurrentCurrency } from "../../../../../utils/customCurrencyHelper";
function ItemCard({ item, loading, error }) {
  const dispatch = useDispatch();

  const { selectedItem, tableOrder } = useSelector((store) => store.pos);

  const { activePosType } = useSelector((store) => store.posSetting);

  const { hotelDetails } = useSelector((store) => store.login);

  let price = GET_ITEM_PRICE(tableOrder.type, activePosType, item);

  const inc = (selectedItem) => {
    dispatch(
      updateSelectedItem({
        id: selectedItem.id,
        itemCountType: "increment",
      })
    );
  };

  const dnc = (selectedItem) => {
    dispatch(
      updateSelectedItem({
        id: selectedItem.id,
        itemCountType: "decrement",
      })
    );
  };

  const handleChange = (selectedItem, customValue) => {
    let trimmedValue = customValue;

    // Trim initial zero
    if (trimmedValue.length > 1 && trimmedValue.startsWith("0")) {
      trimmedValue = trimmedValue.slice(1);
    }

    if (customValue < 0 || customValue == "-") {
      message.warning("Please enter value greater than zero ");
      return;
    }
    if (trimmedValue === "") {
      trimmedValue = 0;
    }

    dispatch(
      updateSelectedItem({
        id: selectedItem.id,
        itemCountType: "custom",
        count: trimmedValue,
      })
    );
  };

  const { symbol, position } = getCurrentCurrency(hotelDetails?.currency);

  return (
    <div
      className={` ${
        item.foodChoice === "veg" ? "border-[#41C588]" : "border-[#F48B84]"
      } border-l-[4px] border-y-[1px] border-r-[1px] border-y-[#0000004D] border-r-[#0000004D] rounded-r-[10px] py-[6px] px-[15px] bg-white  flex flex-col gap-[15px]`}
    >
      <div className=" font-outfit text-[#4D4D4D] font-medium text-[12px] leding-[18px] ">
        {item.name}
      </div>

      <div className="flex items-center justify-between w-[100%] ">
        <span className="flex whitespace-nowrap text-[#00000080] font-outfit font-medium text-[12px] leading-[18px]">
          {`${position === "before" ? symbol : ""} ${price} ${
            position === "after" ? ` ${symbol}` : ""
          }`}{" "}
        </span>

        <div className="w-full h-full flex gap-1 justify-end  items-center ">
          <Button
            className="flex justify-center items-center"
            size="small"
            shape=""
            icon={<MinusOutlined />}
            onClick={() => dnc(item)}
            style={{ borderRadius: "8px" }}
          />

          <Input
            type="number"
            style={{
              width: selectedItem[item.id]?.count > 0 ? "7ch" : "4ch",
              borderColor: "#808080",
              outline: "none",
              textAlign: "center",

              "&:focus": {
                borderColor: "#2F80ED",
              },
              "&:outline": {
                borderColor: "#2F80ED",
              },
            }}
            className="   whitespace-nowrap max-w-[auto]  border-[0.5px] rounded-md border-[#808080] flex  font-[Poppins]  text-[#4D4D4D] font-[500] "
            value={
              selectedItem[item.id] !== undefined
                ? selectedItem[item.id].count
                : 0
            }
            onChange={(e) => handleChange(item, e.target.value)}
          />

          <Button
            className="flex justify-center items-center"
            size="small"
            icon={<PlusOutlined />}
            onClick={() => inc(item)}
            style={{ borderRadius: "8px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default ItemCard;
