const getServerProps = () => {
  let channex_server;
  let base_url;
  let pathPrefix;

  if (process.env.REACT_APP_SERVER_MODE == "dev") {
    channex_server = process.env.REACT_APP_CHANNEX_DEV_SERVER;
    base_url = process.env.REACT_APP_DEV_BASE_URL;
    pathPrefix = "/apps/hotelx/";
  } else {
    channex_server = process.env.REACT_APP_CHANNEX_APP_SERVER;
    base_url = process.env.REACT_APP_BASE_URL;
    pathPrefix = "/hotelx/";
  }
  return { channex_server, base_url, pathPrefix };
};

export { getServerProps };
