import { authedAxios } from "../utils/customHttpHeader";

const getAllPosSetting = async (payload) => {
  const response = await authedAxios().get(`/posSetting?hotelId=${payload}`);
  return response;
};

const createPosSetting = async (payload) => {
  const response = await authedAxios().post(`posSetting`, payload);
  return response;
};

const updatePosSetting = async (payload) => {
  let url = `posSetting/${payload.id}`;
  const response = await authedAxios().put(url, payload);
  return response;
};

const deletePos = async (payload) => {
  let url = `/posSetting/${payload.id}?hotelId=${payload.hotelId}`;
  const response = await authedAxios().delete(url);
  return response;
};

export { getAllPosSetting, createPosSetting, updatePosSetting, deletePos };
