import { useDispatch, useSelector } from "react-redux";
import {
  updateRoomDetails,
  updateMealPlan,
  updateSelectedRoomNumberForModify,
  updateRoomPriceStructure,
  updateSelectedCheckInDateTime,
  updateSelectedCheckOutDateTime,
} from "../../reducer/bookingReducer";
import { useEffect } from "react";
import {
  CURRENCY_FORMAT,
  GET_ISO_FORMAT,
  GET_TODAY_DATE_WITH_ISO_FORMAT,
  GET_TOMORROW_DATE,
} from "../../utils/helper";
import { getRoomsAvailability } from "../../services/walkin";

const useRoomDrawerLogic = (
  initialRender,
  setLoading,
  setAdults,
  setChildrens,
  setInitialRender,
  setOriginalRoomPriceStructureState
) => {
  const dispatch = useDispatch();

  const { hotelDetails, occupancyPlanRate } = useSelector(
    (store) => store.login
  );

  const {
    bookingDetails,
    selectedCheckInDateTime,
    selectedCheckOutDateTime,
    changeDateBoolean,
    displayRoomDrawer,
    invoiceDetails,
  } = useSelector((store) => store.booking);

  const rooms = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];
  const { response } = useSelector((store) => store.rooms);

  useEffect(() => {
    if (displayRoomDrawer) InitialDataAPi();
  }, [displayRoomDrawer]);

  useEffect(() => {
    if (!initialRender) dateChangeApi();
  }, [changeDateBoolean]);

  const InitialDataAPi = async () => {
    setLoading(true);

    const payload = {
      bookingId: bookingDetails?.id,
      startDate: Boolean(bookingDetails?.checkIn)
        ? GET_ISO_FORMAT(bookingDetails?.checkIn)
        : GET_TODAY_DATE_WITH_ISO_FORMAT(),
      endDate: Boolean(bookingDetails?.checkOut)
        ? GET_ISO_FORMAT(bookingDetails?.checkOut)
        : GET_ISO_FORMAT(GET_TOMORROW_DATE()),
      hotelId: hotelDetails?.id,
    };

    let currentPriceBreakup = Array.isArray(invoiceDetails)
      ? invoiceDetails?.find((Obj) => Obj?.invoiceType === "roomBill")
          ?.priceBreakup
      : [];

    let data = await getRoomsAvailability(payload, "bookings/availability");

    if (Boolean(data)) dispatch(updateRoomDetails({ response: data }));

    const namesToFilter = Array.isArray(rooms)
      ? rooms?.map((item) => item.name)
      : [];

    const updatedData = Array.isArray(data)
      ? data?.filter((item) => namesToFilter?.includes(item._id))
      : [];

    let localRoomPriceStructure = updatedData.map((obj) => {
      const roomDetails = rooms.find((room) => room?.name === obj._id);

      const currentPriceBreakupObj = currentPriceBreakup?.find(
        (Obj) => Obj?.type === obj?._id
      );

      let defaultOccupancy = Boolean(roomDetails?.defaultOccupancy)
        ? roomDetails?.defaultOccupancy
        : 2;

      let rate = Boolean(currentPriceBreakupObj && currentPriceBreakupObj?.rate)
        ? currentPriceBreakupObj?.baseRate || currentPriceBreakupObj?.rate
        : occupancyPlanRate?.[roomDetails?._id]?.[bookingDetails?.mealPlan]?.[
            defaultOccupancy
          ];

      const roomStructureObject = {
        type: obj?._id,
        rate: Boolean(rate)
          ? CURRENCY_FORMAT(rate)
          : Boolean(roomDetails?.rate)
          ? CURRENCY_FORMAT(roomDetails?.rate)
          : 0,
        rooms: obj?.rooms,
        availability: obj?.availability,
        breakfastPrice: roomDetails ? Number(roomDetails?.breakfastPrice) : 0,
        mealPrice: roomDetails ? Number(roomDetails?.mealPrice) : 0,
        defaultOccupancy: Boolean(roomDetails?.defaultOccupancy)
          ? roomDetails?.defaultOccupancy
          : 2,
      };

      return roomStructureObject;
    });

    // let filteredObj = {};

    let selectedRoomTypeWithRoom = {};
    response.forEach((item) => {
      let rooms = bookingDetails?.rooms;
      rooms?.map((room) => {
        if (item.id === room) {
          selectedRoomTypeWithRoom[item.type] =
            selectedRoomTypeWithRoom[item.type] !== undefined
              ? [...selectedRoomTypeWithRoom[item.type], item]
              : [item];
        }
      });
    });

    dispatch(updateSelectedRoomNumberForModify(selectedRoomTypeWithRoom));

    setAdults(bookingDetails?.adults);
    setChildrens(bookingDetails?.children);

    dispatch(updateSelectedCheckInDateTime(bookingDetails?.checkIn));
    dispatch(updateSelectedCheckOutDateTime(bookingDetails?.checkOut));

    dispatch(updateMealPlan(bookingDetails?.mealPlan ?? "EP"));
    dispatch(updateRoomPriceStructure(localRoomPriceStructure));
    setOriginalRoomPriceStructureState(localRoomPriceStructure);
    setLoading(false);
    setInitialRender(false);
  };

  const dateChangeApi = async () => {
    setLoading(true);

    const payload = {
      bookingId: bookingDetails?.id,
      startDate: Boolean(selectedCheckInDateTime)
        ? GET_ISO_FORMAT(selectedCheckInDateTime)
        : GET_TODAY_DATE_WITH_ISO_FORMAT(),
      endDate: Boolean(selectedCheckOutDateTime)
        ? GET_ISO_FORMAT(selectedCheckOutDateTime)
        : GET_ISO_FORMAT(GET_TOMORROW_DATE()),
      hotelId: hotelDetails?.id,
    };

    let data = await getRoomsAvailability(payload, "bookings/availability");

    if (Boolean(data)) dispatch(updateRoomDetails({ response: data }));

    const namesToFilter = rooms.map((item) => item.name);
    const updatedData = data?.filter((item) =>
      namesToFilter?.includes(item._id)
    );

    const localRoomPriceStructure = updatedData.map((obj) => {
      const roomDetails = rooms?.find((room) => room?.name === obj._id);

      const roomStructureObject = {
        count: 0,
        type: obj._id,
        rate: roomDetails?.rate,
        rooms: obj.rooms,
        availability: obj.availability,
        breakfastPrice: roomDetails ? Number(roomDetails.breakfastPrice) : 0,
        mealPrice: roomDetails ? Number(roomDetails.mealPrice) : 0,
        defaultOccupancy: Boolean(roomDetails?.defaultOccupancy)
          ? roomDetails?.defaultOccupancy
          : 2,
      };
      return roomStructureObject;
    });

    setAdults(0);
    setChildrens(0);

    dispatch(updateMealPlan("EP"));
    dispatch(updateSelectedRoomNumberForModify({}));
    dispatch(updateRoomPriceStructure(localRoomPriceStructure));
    setLoading(false);
  };
};

export default useRoomDrawerLogic;
