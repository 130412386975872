import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Dropdown, message } from "antd";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  updateAddPartyDropdownVisible,
  updateNewPartytName,
  updateExpenseObject,
} from "../../../reducer/expenseReducer";
import { createParty } from "../../../reducer/partyReducer";
import {
  updatePartyDropdownType,
  updatePartyTransactionObject,
  updateSelectedDebitPartyName,
  updateSelectedCreditPartyName,
} from "../../../reducer/partyTransactionsReducer";

import {
  updateBookingSourceName,
  updateBookingSourcePartyId,
  updateBookingSourceType,
  updateCompanyDetails,
  updateCompanyName,
} from "../../../reducer/walkinReducer";
import { partyObj } from "../../party/utils/utils";

import { ReactComponent as BoxCross } from "../../../assets/icons/boxCross.svg";

const AddPartyDropdown = ({
  transactionStyles,
  partyType,
  sourceType,
  walkinStyles,
  selectedBookingSources,
  inGuestDetails,
  inPos,
  setCustomer,
  customer,
  inCustomerDetails,
  setCompanyName,
}) => {
  const [newSourceType, setNewSourceType] = useState("company");

  const { hotelDetails } = useSelector((store) => store.login);
  const { addPartyDropdownVisible, newPartyName } = useSelector(
    (store) => store.expense
  );
  const { createPartyLoading } = useSelector((store) => store.party);
  const { expenseObject } = useSelector((store) => store.expense);
  const { partyDropdownType, partyTransactionObject } = useSelector(
    (store) => store.partyTransactions
  );

  const dispatch = useDispatch();

  const resetPartyDropdown = () => {
    dispatch(updateAddPartyDropdownVisible(false));
    dispatch(updatePartyDropdownType(""));
    dispatch(updateNewPartytName(""));
  };

  const handleAddPartyDropdownChange = (visible) => {
    if (!visible) {
      resetPartyDropdown();
    } else {
      dispatch(updateAddPartyDropdownVisible(true));
    }
  };

  const handleAddParty = async () => {
    if (!newPartyName?.trim()?.length) {
      message.warning("Display Name cannot be empty");
      return;
    }
    let updatedPartyObj = JSON.parse(JSON.stringify(partyObj));
    updatedPartyObj.displayName = newPartyName;
    updatedPartyObj.partyType = Boolean(partyType) ? partyType : null;
    updatedPartyObj.sourceType = Boolean(sourceType) ? sourceType : null;

    // in case of party is being added from create booking
    if (selectedBookingSources === "OTA") {
      updatedPartyObj.sourceType = "ota";
    } else if (selectedBookingSources === "company") {
      updatedPartyObj.sourceType = "company";
    } else if (selectedBookingSources === "travelAgency") {
      updatedPartyObj.sourceType = "travelAgency";
    }

    updatedPartyObj.hotelId = hotelDetails?.id;
    const res = await dispatch(createParty(updatedPartyObj));

    if (Boolean(res?.payload?.status === 200)) {
      // update party name in autocomplete field of add transaction
      if (partyDropdownType) {
        let updatedPartyTransactionObject = JSON.parse(
          JSON.stringify(partyTransactionObject)
        );
        updatedPartyTransactionObject[partyDropdownType] =
          res?.payload?.data?.id;
        dispatch(updatePartyTransactionObject(updatedPartyTransactionObject));
        partyDropdownType === "debitPartyId"
          ? dispatch(
              updateSelectedDebitPartyName(res?.payload?.data?.displayName)
            )
          : dispatch(
              updateSelectedCreditPartyName(res?.payload?.data?.displayName)
            );
        resetPartyDropdown();
        return;
      }

      if (walkinStyles) {
        dispatch(updateBookingSourceName(res?.payload?.data?.displayName));
        dispatch(updateBookingSourcePartyId(res?.payload?.data?.id));
        dispatch(updateBookingSourceType(res?.payload?.data?.sourceType));
        dispatch(
          updateCompanyDetails({
            name: res?.payload?.data?.displayName,
            gst: null,
          })
        );
        resetPartyDropdown();
        return;
      }

      if (inGuestDetails) {
        dispatch(updateCompanyName(res?.payload?.data?.displayName));
        resetPartyDropdown();
        return;
      }

      if (inPos) {
        setCustomer({
          ...customer,
          ["company"]: res?.payload?.data?.displayName,
        });
        resetPartyDropdown();
        return;
      }

      if (inCustomerDetails) {
        setCompanyName(res?.payload?.data?.displayName);
        resetPartyDropdown();
        return;
      }

      // update party name in autocomplete field of add expense
      let updatedExpenseObject = JSON.parse(JSON.stringify(expenseObject));
      updatedExpenseObject.partyId = res?.payload?.data?.id;

      dispatch(updateExpenseObject(updatedExpenseObject));
      resetPartyDropdown();
    }
  };

  return (
    <Dropdown
      trigger={["click"]}
      onOpenChange={handleAddPartyDropdownChange}
      open={addPartyDropdownVisible}
      dropdownRender={() => (
        <div
          className={`${
            Boolean(transactionStyles)
              ? transactionStyles?.width
              : walkinStyles
              ? "w-[105%]"
              : "w-[190%]"
          } ${
            Boolean(walkinStyles) ? "mt-[-320px]" : "mt-[-85px]"
          } rounded-[10px] border-[#f2f2f2] border-[0.5px] shadow-dropdown`}
        >
          <div className="h-[43px] w-full bg-[#f2f2f2] rounded-t-[10px] flex items-center justify-between px-[15px]">
            <span className="text-[14px] leading-[21px] text-[#00000080]">
              {inPos || walkinStyles || inCustomerDetails || inGuestDetails
                ? "Add Company"
                : "Add Party"}
            </span>
            <BoxCross
              className="cursor-pointer"
              onClick={() => handleAddPartyDropdownChange(false)}
            />
          </div>
          <div className="w-full rounded-b-[10px] bg-[#fff]">
            <div className="pt-[20px]">
              <div className="mb-[10px] px-[10px] flex flex-col gap-[12px]">
                <TextField
                  label={
                    <p>
                      Display Name <span className="text-[#007FFF]">*</span>
                    </p>
                  }
                  id="expenseAccount"
                  size="small"
                  onChange={(e) =>
                    dispatch(updateNewPartytName(e.target.value))
                  }
                  value={newPartyName}
                  type="email"
                  autoComplete="new-password"
                  sx={{
                    width: "100%",

                    "& label": {
                      color: "#0000004D",
                      fontFamily: "Poppins",
                      fontSize: "15px",
                    },
                    "& input": {
                      fontFamily: "Poppins",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputLabel-root": {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "nunito",
                      color: "#808080",
                      fontSize: "0.75rem", // Font size when label is down
                    },
                    "& .MuiInputLabel-shrink": {
                      fontSize: "0.93rem", // Font size when label is up (focused)
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "nunito",
                    },
                  }}
                />
              </div>

              {walkinStyles && selectedBookingSources === "corporate" && (
                <div className="flex items-center justify-center gap-[30px] mb-[14px]">
                  <div className="flex items-center gap-[5px]">
                    <div
                      className={`h-[16px] w-[16px] rounded-full border-[1px] flex items-center justify-center ${
                        newSourceType === "company"
                          ? "border-[#007FFF]"
                          : "border-[#808080]"
                      } cursor-pointer`}
                      onClick={() => setNewSourceType("company")}
                    >
                      {newSourceType === "company" && (
                        <div className="w-[8px] h-[8px] rounded bg-[#007FFF]"></div>
                      )}
                    </div>
                    <span className="font-nunito font-[500px] text-[13px] leading-[17.73px] text-[#4d4d4d]">
                      Company
                    </span>
                  </div>
                  <div className="flex items-center gap-[5px]">
                    <div
                      className={`h-[16px] w-[16px] rounded-full border-[1px] flex items-center justify-center ${
                        newSourceType === "travelAgency"
                          ? "border-[#007FFF]"
                          : "border-[#808080]"
                      } cursor-pointer`}
                      onClick={() => setNewSourceType("travelAgency")}
                    >
                      {newSourceType === "travelAgency" && (
                        <div className="w-[8px] h-[8px] rounded bg-[#007FFF]"></div>
                      )}
                    </div>
                    <span className="font-nunito font-[500px] text-[13px] leading-[17.73px] text-[#4d4d4d]">
                      Travel Agency
                    </span>
                  </div>
                </div>
              )}

              <p className="h-[0.5px] w-full bg-[#00000033] my-[10px]"></p>
              <div className="pr-[20px] flex items-center justify-end pb-[10px]">
                <div className="flex items-center gap-[15px]">
                  <button
                    className="w-[76px] h-[31px] rounded-[5px] bg-[#526EFF0D] flex items-center justify-center"
                    onClick={() => handleAddPartyDropdownChange(false)}
                  >
                    <span className="font-[500] text-[14px] leading-[20px] text-[#007FFF]">
                      Cancel
                    </span>
                  </button>
                  <button
                    className="w-[76px] h-[31px] rounded-[5px] bg-[#007FFF] flex items-center justify-center"
                    onClick={handleAddParty}
                    disabled={createPartyLoading}
                  >
                    {createPartyLoading ? (
                      <CircularProgress
                        style={{ color: "#ffffff" }}
                        size={19}
                      />
                    ) : (
                      <span className="font-[500] text-[14px] leading-[20px] text-[#FFFFFF]">
                        Add
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    >
      <span></span>
    </Dropdown>
  );
};

export default AddPartyDropdown;
