import React, { lazy, Suspense } from "react";
import Loader from "../../pages/loading";
const ManageStockContainer = lazy(() =>
  import("../../components/manageStocks/Index")
);
function ManageStocks() {
  return (
    <Suspense fallback={<Loader />}>
      <ManageStockContainer />
    </Suspense>
  );
}

export default ManageStocks;
