import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createRecountService,
  getAllRecountItemService,
} from "../../services/manageStocksServices/recounts";
import { message } from "antd";

export const getRecount = createAsyncThunk(
  "recount/getAllRecount",
  async () => {
    try {
      const response = await getAllRecountItemService();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createRecount = createAsyncThunk(
  "recount/createRecount",
  async (payload) => {
    try {
      const response = await createRecountService(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  data: [],
  recountData: [],
  loading: false,
  error: null,
};

const recountSlice = createSlice({
  name: "recount",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_2: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRecount.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(getRecount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getRecount.rejected, (state, action) => {
      state.data = [];
      state.error = true;
    });
    builder.addCase(createRecount.fulfilled, (state, action) => {
      message.success("Recount published successfully");
      state.recountData = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(createRecount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createRecount.rejected, (state, action) => {
      message.error("Error in publishing recount");
      state.recountData = [];
      state.error = true;
    });
  },
});

export const { CLEAR_REDUX_STORE_2 } = recountSlice.actions;
export default recountSlice.reducer;
