import React, { useState, useCallback } from "react";
import { Button, Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UPLOADED_IMAGE_SIZE } from "../../../utils/helper";
import { upload } from "../../../reducer/uploadReducer";
import { CircularProgress } from "@mui/material";
import { TranslateText } from "../../../utils/translation";
function UploadOtherId({
  handleUpload = null,
  handleRemoveUrl = null,
  type = null,
}) {
  const dispatch = useDispatch();
  const { loading } = useSelector((store) => store.upload);

  const handleBeforeUpload = (file) => {
    if (file.size > UPLOADED_IMAGE_SIZE) {
      message.error("File size must be lesser than 10 MB");
      return false;
    }
    return true;
  };

  const handleChange = useCallback((info) => {
    let File = info.file;

    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }, []);

  const uploadFile = async (options) => {
    const { onSuccess, onError, file } = options;
    const frmData = new FormData();
    frmData.append("file", file);

    try {
      const getFileObject = (progress) => {
        return {
          name: file.name,
          uid: file.uid,
          progress: progress,
        };
      };

      if (file.size <= UPLOADED_IMAGE_SIZE) {
        // upload file

        if (handleUpload) {
          let data = await dispatch(upload(frmData));
          handleUpload(data?.payload, type);
        } else {
          message.error("something went wrong!");
        }
      }
    } catch (error) {
      message.error("something went wrong!");
    }
  };

  return (
    <div>
      {loading ? (
        <div
          className="rounded-[0.3125rem] flex justify-center items-center 
          px-[0.875rem] py-[0.4375rem] 
      text-[#FFF] cursor-pointer"
        >
          <CircularProgress size={16} />
        </div>
      ) : (
        <button
          className=" bg-[#007FFF] rounded-[0.3125rem] flex justify-center items-center 
          px-[0.875rem] py-[0.4375rem] 
      text-[#FFF] cursor-pointer whitespace-nowrap"
        >
          <Upload
            multiple={true}
            customRequest={uploadFile}
            onChange={handleChange}
            beforeUpload={handleBeforeUpload}
            showUploadList={false}
            accept=".png,.jpeg"
          >
            <span className="text-[#FFF] cursor-pointer">
              <TranslateText textKey={"add_new_id"} namespace="booking" />
            </span>
          </Upload>
        </button>
      )}
    </div>
  );
}

export default UploadOtherId;
