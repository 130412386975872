import React, { useEffect, useState, useRef } from "react";
import { Progress, message } from "antd";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";

import Drawer from "@mui/material/Drawer";
import { FaPlus } from "react-icons/fa6";
import { useReactToPrint } from "react-to-print";

import { useDispatch, useSelector } from "react-redux";
import {
  updateDisplayIdDrawer,
  updateBookingDetails,
  updateRemoveRoomModalVisible,
} from "../../../reducer/bookingReducer";

import { ReactComponent as BackGuestId } from "../../../assets/icons/backGuestId.svg";
import { ReactComponent as Close } from "../../../assets/icons/closeCircle.svg";
import { uploadFiles } from "../../../services/scanUpload";
import { isFileSizeValid } from "../../profile/utils";
import GuestIdSection from "../components/GuestIdSection";
import GuestIdModal from "../Modal/GuestIdModal";
import ConfirmationModal from "../../common/ConfirmationModal";
import GuestIdStructure2 from "../../common/Template/GuestIdStructure2";

export const guestObj = {
  guestName: "",
  idImages: [],
};

const NewIdDrawer = () => {
  const [roomToGuest, setRoomToGuest] = useState({});
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [selectedGuestIdx, setSelectedGuestIdx] = useState(null);
  const [totalGuest, setTotalGuest] = useState(0);
  const [guestIdLength, setGuestIdLength] = useState(0);
  const [saveLoading, setSaveLoading] = useState(false);
  const [print, setPrint] = useState(false);

  // for removing a room and modal logic
  const [roomToDelete, setRoomToDelete] = useState(null);
  const [guestToDelete, setGuestToDelete] = useState(null);

  const componentRef = useRef(null);

  const {
    showMainBookingDetailsDrawer,
    bookingDetails,
    displayIdDrawer,
    responseDetails,
    removeRoomModalVisible,
  } = useSelector((store) => store.booking);
  const { loading } = useSelector((store) => store.upload);
  const { response } = useSelector((store) => store.rooms);

  const dispatch = useDispatch();

  const printInvoicePDF = useReactToPrint({
    onAfterPrint: () => setPrint(false),
    content: () => componentRef.current,
    documentTitle: "GuestID",
  });

  const printInvoice = async () => {
    setPrint(true);
    setTimeout(() => {
      printInvoicePDF();
      setPrint(false);
    }, 0);
  };

  useEffect(() => {
    if (
      bookingDetails &&
      bookingDetails.hasOwnProperty("roomIdByGuestNameIdMap")
    ) {
      const updatedRoomToGuest = {};
      Object.entries(bookingDetails?.roomIdByGuestNameIdMap || {})
        ?.sort((a, b) => a[0].localeCompare(b[0]))
        ?.forEach((room) => {
          const [id, arr] = room;
          updatedRoomToGuest[id] = arr?.length ? arr : [guestObj];
        });
      setRoomToGuest(updatedRoomToGuest);
    }
  }, [bookingDetails, displayIdDrawer]);

  useEffect(() => {
    let updatedTotalGuest = 0;
    let updatedGuestIdLength = 0;

    Object.values(roomToGuest)?.forEach((guestsArray) => {
      // to calculate total number of guests
      updatedTotalGuest += 1;
      if (Array.isArray(guestsArray) && guestsArray?.length > 1) {
        updatedTotalGuest += guestsArray?.length - 1;
      }

      // to calculate number of guests whose ID is uploaded
      if (Array.isArray(guestsArray)) {
        guestsArray?.forEach((guest) => {
          const { idImages } = guest;
          if (Array.isArray(idImages) && idImages?.length) {
            updatedGuestIdLength += 1;
          }
        });
      }
    });

    setTotalGuest(updatedTotalGuest);
    setGuestIdLength(updatedGuestIdLength);
  }, [roomToGuest]);

  const handleClose = () => {
    dispatch(updateDisplayIdDrawer());
  };

  const handlePhotoSectionImageUpload = async (item, roomId, guestIdx) => {
    const file = item.file;
    const data = new FormData();
    data.append("file", file);

    let uploadedFile = await uploadFiles(data);

    if (uploadedFile !== null) {
      const updatedRoomToGuest = JSON.parse(JSON.stringify(roomToGuest));
      updatedRoomToGuest[roomId][guestIdx]["idImages"] = Array.isArray(
        updatedRoomToGuest[roomId][guestIdx]["idImages"]
      )
        ? [...updatedRoomToGuest[roomId][guestIdx]["idImages"], uploadedFile]
        : [uploadedFile];

      setRoomToGuest(updatedRoomToGuest);
      message.success("ID Uploaded");
    }
  };

  const handleImageBeforeUpload = async (file, fileSize) => {
    const fileSizeInKb = fileSize * 1000;

    if (
      !isFileSizeValid(
        file,
        fileSize,
        `File size must be smaller than ${fileSizeInKb} kb`
      )
    ) {
      message.error(`File size must be lesser than ${fileSizeInKb} kb`);
      return false;
    } else return true;
  };

  const removeImage = (url, roomId, guestIdx) => {
    const updatedRoomToGuest = JSON.parse(JSON.stringify(roomToGuest));
    updatedRoomToGuest[roomId][guestIdx]["idImages"] = updatedRoomToGuest[
      roomId
    ][guestIdx]["idImages"]?.filter((fileUrl) => fileUrl !== url);
    setRoomToGuest(updatedRoomToGuest);
  };

  const handleAddGuest = (roomId) => {
    const updatedRoomToGuest = JSON.parse(JSON.stringify(roomToGuest));
    updatedRoomToGuest[roomId]?.push(guestObj);

    setRoomToGuest(updatedRoomToGuest);
  };

  const handleChangeGuestName = (roomId, guestIdx, name) => {
    const updatedRoomToGuest = JSON.parse(JSON.stringify(roomToGuest));
    updatedRoomToGuest[roomId][guestIdx]["guestName"] = name;
    setRoomToGuest(updatedRoomToGuest);
  };

  const handleDeleteGuest = (roomId, guestIdx) => {
    const updatedRoomToGuest = JSON.parse(JSON.stringify(roomToGuest));
    updatedRoomToGuest[roomId] = updatedRoomToGuest[roomId]?.filter(
      (_, idx) => idx !== guestIdx
    );
    setRoomToGuest(updatedRoomToGuest);
  };

  const getFirstGuestName = () => {
    // Get the keys of the object
    const keys = Object.keys(roomToGuest);
    // Get the first key
    const firstKey = keys[0];
    // Get the first element of the array associated with the first key
    const firstElement = roomToGuest[firstKey][0];
    // Get the guestName of the first element
    const guestName = firstElement.guestName;

    return guestName;
  };

  const handleSaveGuestDetails = async () => {
    const payload = {
      ...responseDetails,
      ...bookingDetails,
      // name: getFirstGuestName(),
      roomIdByGuestNameIdMap: roomToGuest,
    };
    setSaveLoading(true);
    await dispatch(updateBookingDetails(payload));
    setSaveLoading(false);

    handleClose();
  };

  const handleConfirmDelete = () => {
    if (roomToDelete !== null && guestToDelete !== null) {
      handleDeleteGuest(roomToDelete, guestToDelete);
      dispatch(updateRemoveRoomModalVisible());
    }
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: "800px",
            top: "auto",
            bottom: 0,
          },
        }}
        sx={{
          "& .MuiDrawer-root": {
            position: "fixed",
            left: 0,
            right: 0,
            width: "70%",
          },
          "& .MuiPaper-root": {
            position: "absolute",
            marginLeft: "auto",
          },
        }}
        anchor={"right"}
        open={displayIdDrawer}
        onClose={handleClose}
      >
        <main className="w-full h-full relative">
          <header className="w-full h-[140px] bg-[#FBFBFB] border-b-[2px] border-b-[#F2F4FC] flex justify-between items-center px-[24px] mb-[34px]">
            <div className="flex gap-[34px] items-center">
              <BackGuestId className="cursor-pointer" onClick={handleClose} />
              <div className="flex items-center gap-[12px]">
                <Progress
                  type="circle"
                  percent={(guestIdLength / totalGuest) * 100}
                  format={(percent) => `${guestIdLength} /${totalGuest}`}
                  size="small"
                  strokeWidth={12}
                  strokeColor={
                    (guestIdLength / totalGuest) * 100 < 50
                      ? "#F36960"
                      : "#2F80ED"
                  }
                  className="text-[#D9D9D9] text-sm bg-transparent ml-2"
                />{" "}
                <p className="font-outfit text-[20px] leading-[30px] text-[#222222]">
                  Guest ID Uploaded
                </p>
              </div>
            </div>

            <div className="flex items-center gap-[34px]">
              <div className="flex flex-col gap-[6px]">
                <p className="font-outfit text-[16px] leading-[24px] font-[500] text-[#222b45]">
                  Booking Id:{" "}
                  <span className="font-outfit text-[16px] leading-[24px] font-[600] text-[#222b45]">
                    {showMainBookingDetailsDrawer.newBookingId}
                  </span>
                </p>
                <p className="font-outfit text-[14px] leading-[24px] font-[500] text-[#222b45] text-right">
                  Source:{" "}
                  <span className="font-outfit text-[14px] leading-[24px] font-[600] text-[#007FFF]">
                    {bookingDetails?.source?.toUpperCase()}
                  </span>
                </p>
              </div>
              <div></div>
            </div>
          </header>
          <section className="px-[24px] flex flex-col gap-[24px] max-h-[62vh] overflow-y-scroll">
            {Object.entries(roomToGuest || {})?.map((room, roomIdx) => {
              const [roomId, guestArray] = room || [];
              return (
                <div className="w-full flex flex-col gap-[24px]">
                  {roomIdx === 0 && <p className="w-full h-[13px]"></p>}
                  {guestArray.map((guest, guestIdx) => {
                    const { guestName, idImages } = guest || {};
                    return (
                      <div className="flex items-center justify-between relative">
                        <div className="flex gap-[45px] items-center">
                          <div className="flex gap-[12px] items-center">
                            <Tooltip
                              title="Add more guest"
                              placement="right-end"
                            >
                              <button
                                className="h-[38px] w-[38px] rounded-[8px] border-[1px] border-[#007fff] flex items-center justify-center"
                                onClick={() => handleAddGuest(roomId)}
                              >
                                <FaPlus color="#007fff" />
                              </button>
                            </Tooltip>
                            <div className="flex items-center justify-center rounded-[12px] bg-[#8F9BB31A] w-[112px] h-[50px]">
                              <span className="font-outfit font-[600] text-[18px] leading-[22.68px] text-[#222B45]">
                                {Boolean(roomId)
                                  ? response?.find(({ id }) => id === roomId)
                                      ?.name
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="w-[274px] h-[56px] relative inline-block">
                            {/* {Boolean(bookingDetails?.name) &&
                              Boolean(guestName) &&
                              bookingDetails?.name === guestName && (
                                <p class="absolute top-0 right-0 tracking-[2px] bg-[#2F80ED] text-[10px] font-[700] font-outfit leading-[20px] text-[#FFF] px-2 py-0.5 rounded-[12px] uppercase">
                                  Primary Guest
                                </p>
                              )} */}
                            <input
                              className="w-full h-full border-[1px] border-[#EDF1F7] rounded-[12px] outline-none px-[15px] font-outfit text-[16px] leading-[24px] tracking-[0.5px] text-[#616161]"
                              placeholder="Guest name"
                              value={guestName}
                              // readOnly={
                              //   Boolean(bookingDetails?.name) &&
                              //   Boolean(guestName) &&
                              //   bookingDetails?.name === guestName
                              // }
                              onChange={(e) =>
                                handleChangeGuestName(
                                  roomId,
                                  guestIdx,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="flex items-center gap-[14.5px]">
                          <GuestIdSection
                            imageArray={Array.isArray(idImages) ? idImages : []}
                            handlePhotoSectionImageUpload={
                              handlePhotoSectionImageUpload
                            }
                            removeImage={removeImage}
                            roomId={roomId}
                            guestIdx={guestIdx}
                            setSelectedRoomId={setSelectedRoomId}
                            setSelectedGuestIdx={setSelectedGuestIdx}
                            handleImageBeforeUpload={handleImageBeforeUpload}
                          />
                          {guestArray?.length > 1 ? (
                            <div className="pb-[6px]">
                              <Close
                                className="cursor-pointer"
                                onClick={() => {
                                  setRoomToDelete(roomId);
                                  setGuestToDelete(guestIdx);
                                  dispatch(updateRemoveRoomModalVisible());
                                }}
                              />
                            </div>
                          ) : (
                            <Close className="invisible" />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </section>
          <footer className="w-full h-[96px] absolute bottom-0 bg-[#FFFFFF] custom-shadow px-[24px] flex items-center justify-end">
            <div className="flex items-center gap-[10px]">
              <button
                className="w-[263px] h-[48px] rounded-[8px] flex items-center justify-center border-[1px] border-[#007FFF]"
                onClick={printInvoice}
                disabled={print}
              >
                {print ? (
                  <CircularProgress style={{ color: "#007FFF" }} size={21} />
                ) : (
                  <span className="font-outfit font-[500] text-[16px] leading-[24px] text-[#007FFF]">
                    Print
                  </span>
                )}
              </button>
              <button
                className="h-[48px] w-[263px] bg-[#007FFF] rounded-[8px] flex justify-center items-center"
                onClick={handleSaveGuestDetails}
                disabled={saveLoading}
              >
                {saveLoading ? (
                  <CircularProgress style={{ color: "#fff" }} size={21} />
                ) : (
                  <span className="font-[500] font-outfit text-[16px] leading-[24px] text-[#fff]">
                    Save
                  </span>
                )}
              </button>
            </div>
          </footer>
        </main>
      </Drawer>

      <GuestIdModal
        roomToGuest={roomToGuest}
        selectedRoomId={selectedRoomId}
        setSelectedRoomId={setSelectedRoomId}
        selectedGuestIdx={selectedGuestIdx}
        setSelectedGuestIdx={setSelectedGuestIdx}
        imageArray={
          roomToGuest?.[selectedRoomId]?.[selectedGuestIdx]?.["idImages"] || []
        }
        guestName={
          roomToGuest?.[selectedRoomId]?.[selectedGuestIdx]?.["guestName"] || ""
        }
        removeImage={removeImage}
        response={response}
        handlePhotoSectionImageUpload={handlePhotoSectionImageUpload}
        handleImageBeforeUpload={handleImageBeforeUpload}
      />

      <ConfirmationModal
        isOpen={removeRoomModalVisible}
        toggleModalVisibility={updateRemoveRoomModalVisible}
        onConfirm={handleConfirmDelete}
        content={{
          heading: "Remove Guest ?",
          warning: "By Clicking Yes selected Guest will be deleted.",
        }}
      />

      {/* {print && (
        <GuestIdStructure
          val={componentRef}
          print={true}
          download={false}
          roomToGuest={roomToGuest}
          response={response}
          showMainBookingDetailsDrawer={showMainBookingDetailsDrawer}
          bookingDetails={bookingDetails}
        />
      )} */}
      {print && (
        <GuestIdStructure2
          val={componentRef}
          print={true}
          download={false}
          roomToGuest={roomToGuest}
          response={response}
          showMainBookingDetailsDrawer={showMainBookingDetailsDrawer}
          bookingDetails={bookingDetails}
          guestIdLength={guestIdLength}
          totalGuest={totalGuest}
        />
      )}
    </>
  );
};

export default NewIdDrawer;
