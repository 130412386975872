import { authedAxios } from "../utils/customHttpHeader";

const getBanksService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelId=${payload?.hotelId}`;

  const response = await authedAxios().get(url);
  return response;
};

const postBankService = async (endpoint, payload) => {
  let url = endpoint;

  const response = await authedAxios().post(url, payload);
  return response;
};

const putBankService = async (endpoint, payload) => {
  let url = endpoint;
  url += `/${payload?.id}`;

  const response = await authedAxios().put(url, payload);
  return response;
};

const deleteBankService = async (endpoint, payload) => {
  let url = endpoint;
  url += `/${payload?.id}`;
  url += `?hotelId=${payload?.hotelId}`;

  const response = await authedAxios().delete(url);
  return response;
};

export { getBanksService, postBankService, putBankService, deleteBankService };
