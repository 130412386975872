import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateDisplayFilter,
  clearFilterData,
  updateBookingListData,
  updateActiveTab,
  getBookingList,
  updateInFinateScroll,
  resetState,
  resetBookingDetails,
  updateCommonBookingListData,
  updateUpcomingBookingListData,
  updateUpcomingBookingStartIndex,
} from "../../reducer/bookingReducer";
import { updateGloabalSearchText } from "../../reducer/appHeaderReducer";
import {
  CURRENCY_FORMAT,
  DEBBOUNCING_TIME,
  GET_ISO_FORMAT,
  GET_THREE_MONTHS_EARLIER_DATE_WITH_ISO_FORMAT,
  GET_TODAY_DATE_WITH_ISO_FORMAT,
} from "../../utils/helper";
import { calculateNumberOfNights } from "../../utils/bookingDetailsHelper";
import moment from "moment";

const useGetBookingListLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hotelDetails, selectedHotels } = useSelector((store) => store.login);
  const { globalsearchText } = useSelector((store) => store.appHeader);
  const { response: allRooms } = useSelector((store) => store.rooms);

  const {
    title,
    checkIn,
    checkOut,
    selectedRoomType,
    selectedBookingSource,
    infinateScroll,
    pageNumber,
    pageSize,
    response,
  } = useSelector((store) => store.booking);

  const token = window.localStorage.getItem("token");

  useEffect(() => {
    return () => {
      dispatch(resetBookingDetails());
      dispatch(resetState());
      dispatch(updateBookingListData({}));
      dispatch(clearFilterData());
      dispatch(updateGloabalSearchText(""));
      dispatch(updateActiveTab("upcoming"));
    };
  }, []);

  useEffect(() => {
    dispatch(resetState());
    var time;
    time = setTimeout(() => {
      search();
    }, DEBBOUNCING_TIME);

    return () => clearTimeout(time);
  }, [globalsearchText, title]);

  useEffect(() => {
    if (infinateScroll) search();
  }, [pageNumber]);

  useEffect(() => {
    reloadBookingAfterHotelChange();
  }, [hotelDetails?.id]);

  useEffect(() => {
    if (!Boolean(token)) navigate("/");
  }, [token, navigate, title]);

  useEffect(() => {
    storeBookingList();
  }, [response, allRooms]);

  const search = async () => {
    let roomType = "";
    let sources = "";

    let startDate = checkIn ? GET_ISO_FORMAT(checkIn) : null;
    let endDate = checkOut ? GET_ISO_FORMAT(checkOut) : null;

    let sortingDirection = "";
    let sortingField = "";

    let createdAtStartDate = "";
    let createdAtEndDate = "";

    selectedRoomType?.forEach((item, index) => {
      if (selectedRoomType.length - 1 === index) roomType += item;
      else roomType += item + ",";
    });

    selectedBookingSource?.forEach((item, index) => {
      if (selectedBookingSource.length - 1 === index) sources += item;
      else sources += item + ",";
    });

    if (
      title === "completed" ||
      title === "enquiry" ||
      title === "ongoing" ||
      title === "noShow"
    ) {
      sortingDirection = "DESC";
      sortingField = "updatedAt";
    }

    if (title === "new") {
      createdAtStartDate = GET_THREE_MONTHS_EARLIER_DATE_WITH_ISO_FORMAT();
      createdAtEndDate = GET_TODAY_DATE_WITH_ISO_FORMAT();
      sortingField = "createdAt";
      sortingDirection = "DESC";
    }

    let payload = {
      hotelIds: Boolean(selectedHotels && selectedHotels?.length)
        ? selectedHotels.toString()
        : hotelDetails?.id,
      roomType: roomType,
      statuses:
        title === "completed"
          ? "completed"
          : title === "upcoming"
          ? "confirmed"
          : title === "ongoing"
          ? "checkedIn"
          : title === "enquiry"
          ? "enquiry"
          : title === "noShow"
          ? ["noShow", "cancelled"]
          : title === "new"
          ? ["upcoming", "confirmed"]
          : "",
      startDate: startDate,
      endDate: endDate,

      sources: sources,
      pageNumber: pageNumber,
      pageSize: pageSize,

      infinateScroll: infinateScroll,

      sortingDirection: sortingDirection,
      sortingField: sortingField,

      createdAtStartDate: createdAtStartDate,
      createdAtEndDate: createdAtEndDate,

      searchText: globalsearchText,
    };

    dispatch(getBookingList(payload));
    dispatch(updateDisplayFilter(false));
  };

  const reloadBookingAfterHotelChange = async () => {
    dispatch(updateBookingListData({}));
    dispatch(clearFilterData());
    dispatch(updateGloabalSearchText(""));
    dispatch(resetState());

    dispatch(updateInFinateScroll(false));
    search();
  };

  let getTotalBookingAmountAndDue = (bookingId) => {
    let totalAmount = 0;
    let totalBalanceDue = 0;
    let totalPayment = 0;

    totalPayment = response?.payments
      ?.filter((Obj) => Obj?.bookingId === bookingId)
      ?.reduce((acc, Obj) => acc + Obj.amount, 0);

    totalAmount = response?.invoices
      ?.filter((Obj) => Obj?.bookingId === bookingId)
      ?.reduce((acc, Obj) => acc + Obj.total, 0);

    totalBalanceDue = totalAmount - totalPayment;

    return [totalAmount, totalBalanceDue];
  };

  let getBookedRooms = (rooms) => {
    let bookedRoomNumber = [];
    let bookedRoomMapBreakup = {};

    allRooms?.forEach((room) => {
      rooms?.forEach((id) => {
        if (room.id == id) {
          bookedRoomMapBreakup[room.type] = Boolean(
            bookedRoomMapBreakup[room.type]
          )
            ? [...bookedRoomMapBreakup[room.type], room.name]
            : [room.name];

          bookedRoomNumber.push(room.name);
        }
      });
    });

    return {
      bookedRoomNumber: bookedRoomNumber,
      bookedRoomMapBreakup: bookedRoomMapBreakup,
    };
  };

  const storeBookingList = () => {
    let localBookingListData = response?.bookings
      ? response.bookings?.map((item) => {
          return {
            id: item.id,
            bookingId: item.bookingId,

            checkIn: item?.checkIn,
            checkOut: item?.checkOut,
            guest: {
              A: item.adults,
              C: item.children,
            },
            source: item.source,
            bookedRooms: getBookedRooms(item.rooms),
            service: item.bookedRooms,

            amount: CURRENCY_FORMAT(getTotalBookingAmountAndDue(item?.id)?.[0]),
            due: CURRENCY_FORMAT(getTotalBookingAmountAndDue(item?.id)?.[1]),
            status: item?.status,

            night: calculateNumberOfNights(
              item?.checkIn,
              item?.checkOut,
              hotelDetails
            ),
            hotelID: item.hotelId,
            name: item?.primaryGuestName || item?.name,
            phone: item?.primaryGuestPhoneNumber || item?.phone,
            expressCheckInComplete: item?.expressCheckInComplete,
          };
        })
      : [];

    let newbookingDetailsArray = [
      { title: "Previous", arr: [] },
      { title: "Today", arr: [] },
      { title: "Tomorrow", arr: [] },
    ];

    let restBookingsMap = {}; // Map to store rest bookings grouped by dates

    const currentDate = moment().startOf("day");
    const tomorrowDate = moment().startOf("day").add(1, "day"); // Start of tomorrow's day

    Array.isArray(localBookingListData) &&
      localBookingListData?.forEach((Obj) => {
        let currentObjCheckInDate = moment(Obj?.checkIn).startOf("day"); // Start of the check-in day

        if (currentObjCheckInDate.isBefore(currentDate)) {
          newbookingDetailsArray[0]["arr"].push(Obj);
        } else if (currentObjCheckInDate.isSame(currentDate)) {
          newbookingDetailsArray[1]["arr"].push(Obj);
        } else if (currentObjCheckInDate.isSame(tomorrowDate, "day")) {
          newbookingDetailsArray[2]["arr"].push(Obj);
        } else {
          let dateKey = currentObjCheckInDate.format("D MMM, YYYY"); // Format date as "Date  Apr, 2024"
          if (!restBookingsMap[dateKey]) {
            restBookingsMap[dateKey] = [];
          }
          restBookingsMap[dateKey].push(Obj);
        }
      });

    // Convert restBookingsMap into array format for rendering
    Object.keys(restBookingsMap).forEach((dateKey) => {
      newbookingDetailsArray.push({
        title: dateKey,
        arr: restBookingsMap[dateKey],
      });
    });

    let startIndex = 0;
    for (let i = 0; i < newbookingDetailsArray.length; i++) {
      if (newbookingDetailsArray[i]["arr"]?.length > 0) {
        startIndex = i;
        break;
      }
    }

    dispatch(updateCommonBookingListData(localBookingListData));
    dispatch(updateUpcomingBookingListData(newbookingDetailsArray));
    dispatch(updateUpcomingBookingStartIndex(startIndex));
  };
};

export default useGetBookingListLogic;
