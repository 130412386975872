import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import moment from "moment";
import { GET_ISO_FORMAT } from "../utils/helper";

import {
  getPartyTransactionsService,
  postPartyTransactionService,
  putPartyTransactionService,
} from "../services/partyTransaction";

export const getPartyTransactions = createAsyncThunk(
  "getPartyTransactions",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "party/transactions";
      const response = await getPartyTransactionsService(payload, endpoint);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getLatestPartyTransaction = createAsyncThunk(
  "getLatestPartyTransaction",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "party/transactions";
      const response = await getPartyTransactionsService(payload, endpoint);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const postPartyTransaction = createAsyncThunk(
  "postPartyTransaction",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const state = getState();
    try {
      const endpoint = "party/transactions";
      const response = await postPartyTransactionService(payload, endpoint);
      dispatch(
        getPartyTransactions({
          hotelId: state?.login?.hotelDetails?.id,
          partyId: state?.party?.initialPartyObject?.id,
          startDate: GET_ISO_FORMAT(moment().startOf("month")),
          endDate: GET_ISO_FORMAT(moment().endOf("day")),
        })
      );
      dispatch(resetAddTransactionState("drawerClose"));
      message.success("Transaction added successfully");
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const putPartyTransaction = createAsyncThunk(
  "putPartyTransaction",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const state = getState();
    try {
      const endpoint = "party/transactions";
      const response = await putPartyTransactionService(payload, endpoint);
      dispatch(
        getPartyTransactions({
          hotelId: state?.login?.hotelDetails?.id,
          partyId: state?.party?.initialPartyObject?.id,
          startDate: GET_ISO_FORMAT(moment().startOf("month")),
          endDate: GET_ISO_FORMAT(moment().endOf("day")),
        })
      );
      dispatch(resetAddTransactionState("drawerClose"));
      message.success("Transaction details changed successfully");
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  // to get list of party transactions
  transactions: [],
  transactionsLoading: false,
  transactionsError: null,

  // add payment
  addPaymentDrawerVisible: false,
  debitPartySearchString: "",
  creditPartySearchString: "",
  partyTransactionObject: {},
  showTransactionDateCalendar: false,
  selectedDebitPartyName: "",
  selectedCreditPartyName: "",

  // to get latest party transaction
  transaction: [],
  transactionLoading: false,
  transactionError: null,

  // to post a transaction
  transactionPostResponse: null,
  transactionPostLoading: false,
  transactionPostError: null,

  // to put a transaction
  transactionPutResponse: null,
  transactionPutLoading: false,
  transactionPutError: null,

  // add party dropdown
  partyDropdownType: "",

  // range picker
  initialDate: moment().startOf("month"),
  finalDate: moment().endOf("day"),
  visibleCalendar: "",
  // selectedInitialDate: moment().startOf("month"),
  // selectedFinalDate: moment().endOf("day"),
  selectedInitialDate: "",
  selectedFinalDate: "",
};

const partyTransactionsSlice = createSlice({
  name: "partyTransaction",
  initialState,
  reducers: {
    resetAddTransactionState: (state, action) => {
      state.addPaymentDrawerVisible = false;
      state.debitPartySearchString = "";
      state.creditPartySearchString = "";
      state.partyTransactionObject = {};
      state.showTransactionDateCalendar = false;
      state.partyDropdownType = "";
      state.selectedDebitPartyName = "";
      state.selectedCreditPartyName = "";

      if (action.payload === "drawerClose") return;
      
      state.visibleCalendar = "";
      state.initialDate = moment().startOf("month");
      state.finalDate = moment().endOf("day");
      // state.selectedInitialDate = moment().startOf("month");
      // state.selectedFinalDate = moment().endOf("day");
      state.selectedInitialDate = "";
      state.selectedFinalDate = "";
    },

    updateVisibleCalendar: (state, action) => {
      state.visibleCalendar = action.payload;
    },
    updateSelectedInitialDate: (state, action) => {
      state.selectedInitialDate = action.payload;
    },
    updateSelectedFinalDate: (state, action) => {
      state.selectedFinalDate = action.payload;
    },
    updateInitialDate: (state, action) => {
      state.initialDate = action.payload;
    },
    updateFinalDate: (state, action) => {
      state.finalDate = action.payload;
    },

    updatePartyDropdownType: (state, action) => {
      state.partyDropdownType = action.payload;
    },
    updateAddPaymentDrawerVisible: (state, action) => {
      state.addPaymentDrawerVisible = action.payload;
    },
    updateDebitPartySearchString: (state, action) => {
      state.debitPartySearchString = action.payload;
    },
    updateCreditPartySearchString: (state, action) => {
      state.creditPartySearchString = action.payload;
    },
    updatePartyTransactionObject: (state, action) => {
      state.partyTransactionObject = action.payload;
    },
    updateShowTransactionDateCalendar: (state, action) => {
      state.showTransactionDateCalendar = action.payload;
    },
    updateSelectedDebitPartyName: (state, action) => {
      state.selectedDebitPartyName = action.payload;
    },
    updateSelectedCreditPartyName: (state, action) => {
      state.selectedCreditPartyName = action.payload;
    },
  },

  extraReducers: (builder) => {
    // to get list of party transactions
    builder.addCase(getPartyTransactions.pending, (state, action) => {
      state.transactionsLoading = true;
    });
    builder.addCase(getPartyTransactions.fulfilled, (state, action) => {
      state.transactionsLoading = false;
      state.transactions = action.payload?.data;
    });
    builder.addCase(getPartyTransactions.rejected, (state, action) => {
      state.transactionsLoading = false;
      state.transactionsError = action.payload;
    });

    // to get latest party transaction
    builder.addCase(getLatestPartyTransaction.pending, (state, action) => {
      state.transactionLoading = true;
    });
    builder.addCase(getLatestPartyTransaction.fulfilled, (state, action) => {
      state.transactionLoading = false;
      state.transaction = action.payload?.data;
    });
    builder.addCase(getLatestPartyTransaction.rejected, (state, action) => {
      state.transactionLoading = false;
      state.transactionError = action.payload;
    });

    // to post a transaction
    builder.addCase(postPartyTransaction.pending, (state, action) => {
      state.transactionPostLoading = true;
    });
    builder.addCase(postPartyTransaction.fulfilled, (state, action) => {
      // state.transactions = [action.payload.data, ...state.transactions];
      state.transactionPostLoading = false;
    });
    builder.addCase(postPartyTransaction.rejected, (state, action) => {
      state.transactionPostLoading = false;
      state.transactionPostError = action.payload;
    });

    // to put a transaction
    builder.addCase(putPartyTransaction.pending, (state, action) => {
      state.transactionPutLoading = true;
    });
    builder.addCase(putPartyTransaction.fulfilled, (state, action) => {
      state.transactionPutLoading = false;
    });
    builder.addCase(putPartyTransaction.rejected, (state, action) => {
      state.transactionPutLoading = false;
      state.transactionPutError = action.payload;
    });
  },
});

export default partyTransactionsSlice.reducer;
export const {
  updateAddPaymentDrawerVisible,
  updateDebitPartySearchString,
  updateCreditPartySearchString,
  updatePartyTransactionObject,
  updateShowTransactionDateCalendar,
  resetAddTransactionState,
  updatePartyDropdownType,
  updateSelectedDebitPartyName,
  updateSelectedCreditPartyName,
  updateVisibleCalendar,
  updateSelectedInitialDate,
  updateSelectedFinalDate,
  updateInitialDate,
  updateFinalDate,
} = partyTransactionsSlice.actions;
