import { authedAxios } from "../utils/customHttpHeader";

const sendOtp = async (payload, endpoint) => {
  try {
    const response = await authedAxios().post(endpoint, payload);
    return response;
  } catch (error) {
    return null;
  }
};

const verifyOTP = async (payload, endpoint) => {
  try {
    const response = await authedAxios().put(endpoint, payload);
    return response?.data;
  } catch (error) {
    return null;
  }
};

export { sendOtp, verifyOTP };
