import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updatesShowingAddStewardModel,
  createPosSettingForm,
} from "../../../../reducer/posReducer/posSettingReducer";
import { Button, Modal } from "antd";
import { TranslateText } from "../../../../utils/translation";

const AddStewardModel = () => {
  const dispatch = useDispatch();
  const { hotelDetails } = useSelector((store) => store.login);
  const [loading, setLoading] = useState(false);
  const { visibleAddStewardModal, response } = useSelector(
    (store) => store.posSetting
  );
  const [addSteward, setAddSteward] = useState([]);

  const getData = (e) => {
    setAddSteward({
      ...addSteward,
      [e.target.name]: e.target.value,
    });
  };

  const handleOk = () => {
    setLoading(true);
    const payload = {
      ...addSteward,
      hotelId: hotelDetails?.id,
      // name: addSteward && addSteward?.name,
    };
    setTimeout(() => {
      setLoading(false);
      dispatch(
        updatesShowingAddStewardModel({
          modal: false,
        })
      );
      const newPayload = {
        payload,
        existingPayload: [...response],
      };

      dispatch(createPosSettingForm(payload));
    }, 1000);
  };

  return (
    <>
      <Modal
        title="Add Steward"
        width={300}
        open={visibleAddStewardModal}
        onOk={handleOk}
        onCancel={() =>
          dispatch(
            updatesShowingAddStewardModel({
              modal: false,
            })
          )
        }
        footer={[
          <Button
            key="back"
            onClick={() =>
              dispatch(
                updatesShowingAddStewardModel({
                  modal: false,
                })
              )
            }
            className="border border-red-400 text-red-400 hover:border-red-500 hover:text-red-500 w-[5rem]"
          >
            
            <TranslateText textKey={"cancel"} namespace="pos" />

          </Button>,
          <Button
            key="submit"
            type="primary"
            // type="primary"
            loading={loading}
            onClick={handleOk}
            className="bg-blue-400 w-[5rem]"
          >
            
            <TranslateText textKey={"save"} namespace="pos" />

          </Button>,
        ]}
      >
        <form className="w-full max-w-sm mt-[1.5rem]">
          <div className="flex items-center mb-6">
            <div className="">
              <label className="block text-gray-500  mb-1 pr-4">Steward</label>
            </div>
            <div className="">
              <input
                className="bg-gray-100 appearance-none border-[1px] border-gray-300 rounded w-full py-2 
                px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                placeholder="Enter Steward name"
                type="text"
                name="stewards"
                onChange={getData}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddStewardModel;
