import { authedAxios } from "../utils/customHttpHeader";

export const getOrdersService = async (queryParams) => {
  const response = await authedAxios().get("orders", {
    params: {
      hotelId: Boolean(queryParams.hotelId) ? queryParams.hotelId : null,
      pageNumber: Boolean(queryParams.pageNumber)
        ? queryParams.pageNumber
        : null,
      pageSize: Boolean(queryParams.pageSize) ? queryParams.pageSize : null,
      statusList: Boolean(queryParams.statusList)
        ? queryParams.statusList
        : null,
      roomId: Boolean(queryParams.roomId) ? queryParams.roomId : null,
      tableId: Boolean(queryParams.tableId) ? queryParams.tableId : null,
      posId: Boolean(queryParams.posId) ? queryParams.posId : null,
      ids: Boolean(queryParams.ids) ? queryParams.ids : null,
    },
  });
  return response.data;
};

export const updateOrderService = async (payload) => {
  const { id } = payload;
  const response = await authedAxios().put(`orders/${id}`, payload);
  return response.data;
};
