import React from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import moment from "moment";
import { CURRENCY_FORMAT } from "../../../../utils/helper";
import { getCurrentCurrency } from "../../../../utils/customCurrencyHelper";
import { TranslateText } from "../../../../utils/translation";

const GenerateInvoice = ({ tableOrderDetails, val, customer }) => {
  const { invoicePostData } = useSelector((store) => store.order);
  const { hotelDetails } = useSelector((store) => store.login);
  const { name, hsnSac, address, gstIN, contactNumber, emailAddress } =
    hotelDetails || {};
  const { activePosName, activePosId, response } = useSelector(
    (store) => store.posSetting
  );

  const posGstIn = response?.filter((item) => item.id == activePosId)?.[0]
    ?.gstIn;

  const GSTIN = Boolean(posGstIn) ? posGstIn : gstIN;

  const {
    priceBreakup,
    createdAt,
    invoiceBilledDate,
    total,
    subTotal,
    discount,
    serviceCharge,
    invoiceId,
    taxObjects,
  } = invoicePostData || {};

  let itemPrice = 0;

  let date = invoiceBilledDate ? invoiceBilledDate : createdAt;

  priceBreakup?.forEach((item) => {
    itemPrice += +item.rate * +item.count;

    item.addOns?.forEach((addOn) => {
      itemPrice += +addOn.price * addOn.count;
    });
  });

  const { symbol, position } = getCurrentCurrency(hotelDetails?.currency);

  return (
    <div className="relative">
      <div className="left-0 right-0 w-full px-8 top-8  absolute " ref={val}>
        <div className="text-center">
          <div className="w-full flex justify-center">
            <img
              className="h-[80px] w-[80px] rounded-[20px]"
              src={hotelDetails?.logo}
              alt=""
            />
          </div>
          <p className="text-semibold text-2xl font-semibold capitalize">
            {name}
          </p>

          <p className="text-semibold font-semibold capitalize">
            {activePosName}
          </p>
          <p className="text-sm">{address}</p>
          <p className="text-sm">
            {Boolean(contactNumber) && (
              <span className="text-sm">
              <TranslateText textKey={"phone"} namespace="pos" />

               {contactNumber}</span>
            )}
          </p>

          {Boolean(emailAddress) && (
            <p className="text-sm">
                          <TranslateText textKey={"email"} namespace="pos" />
                          {emailAddress}</p>
          )}

          {Boolean(GSTIN) && <p className="text-sm">
            <TranslateText textKey={"gstin"} namespace="pos" />

             {GSTIN}</p>}

          {/* {Boolean(FSSAI) && <p>GSTIN: {FSSAI}</p>} */}

          {Boolean(hsnSac) && <p className="text-sm">
            <TranslateText textKey={"hsn_sac"} namespace="pos" />
             {hsnSac}</p>}

             <p className="text-sm">
          <TranslateText textKey={"invoice"} namespace="pos" />
          {invoiceId}</p>
          <p className="text-1xl">
          <TranslateText textKey={"dine_in"} namespace="pos" />

          </p>
          
        </div>

        {tableOrderDetails?.name && (
          <div>
            <p> {tableOrderDetails?.name}</p>
          </div>
        )}

        {customer?.phoneNumber && (
          <div>
            <p>  
            <TranslateText textKey={"mobile_no"} namespace="pos" />

              {customer?.phoneNumber}</p>
          </div>
        )}

        {customer?.company && (
          <div>
            <p>  
            <TranslateText textKey={"company"} namespace="pos" />

              {customer?.company}</p>
          </div>
        )}

        {customer?.gstIN && (
          <div>
            <p> 
            <TranslateText textKey={"company_gstin"} namespace="pos" />
               {customer?.gstIN}</p>
          </div>
        )}

        <hr className="border-dashed border-b-2 border-slate-600 my-2" />
        {tableOrderDetails.tableNumber && (
          <p>  
          <TranslateText textKey={"table_no"} namespace="pos" />

            {tableOrderDetails.tableNumber}</p>
        )}

        {tableOrderDetails.numberOfPeople && (
          <p>  
           <TranslateText textKey={"no_of_pax"} namespace="pos" />

            {tableOrderDetails.numberOfPeople}</p>
        )}

        <div className="flex justify-between">
          <p className="text-sm">
            {moment(date).format("Do MMM, YYYY h:mm a")}
          </p>
        </div>
        <hr className="border-dashed border-b-2 border-slate-600 my-2" />

        <div className="flex justify-between">
          <p className="w-[40%]">
          <TranslateText textKey={"item"} namespace="pos" />

          </p>
          <p className="w-[20%] text-center">
          <TranslateText textKey={"qty"} namespace="pos" />

          </p>
          <p className="w-[20%] text-center">
          <TranslateText textKey={"rate"} namespace="pos" />

          </p>
          <p className="w-[40%] text-right">
          <TranslateText textKey={"amt"} namespace="pos" />

          </p>
        </div>
        <div>
          {priceBreakup?.map((item, index) => {
            return (
              <div key={index} className="mb-2">
                <div className="flex justify-between">
                  <p className="w-[40%]">{item.type}</p>
                  <p className="w-[20%] text-center">{item.count}</p>
                  <p className="w-[20%] text-center">
                    {CURRENCY_FORMAT(+item.rate)}
                  </p>

                  <p className="w-[40%] text-right">
                    {CURRENCY_FORMAT(+item.rate * +item.count)}
                  </p>
                </div>
                {item.addOns?.map((addon) => {
                  return (
                    <div key={addon.name} className="flex justify-between">
                      <div className="flex">
                        <span className="mt-1">
                          <BsArrowReturnRight className="mr-[2px]" />
                        </span>
                        <span>
                          {addon.name}
                          <TranslateText textKey={"x"} namespace="pos" />

                          {addon.count}
                        </span>
                      </div>
                      <span>
                        {CURRENCY_FORMAT(+addon.price * +addon.count)}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <hr className="border-dashed border-b-2 border-slate-600 my-2" />

        <div className="w-full">
          <div className="flex justify-between mb-3 w-full">
            <p>
            <TranslateText textKey={"amount"} namespace="pos" />

            </p>
            <p>{CURRENCY_FORMAT(itemPrice)}</p>
          </div>
          {Boolean(discount) && (
            <div className="flex justify-between mb-2">
              <p>
              <TranslateText textKey={"discount"} namespace="pos" />

              </p>
              <p>{CURRENCY_FORMAT(discount)}</p>
            </div>
          )}

          <div className="flex justify-between mb-1 w-full">
            <p>
            <TranslateText textKey={"sub_total"} namespace="pos" />

            </p>
            <p>{CURRENCY_FORMAT(subTotal)}</p>
          </div>

          {taxObjects?.map((Obj) => {
            if (Obj?.taxAmount) {
              return (
                <div
                  className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1"
                  key={Obj?.id}
                >
                  <div className="flex gap-2">
                    <p className="font-outfit text-[14px]">
                      {Obj?.taxName || "Tax"} ({Obj?.taxPercentage}%)
                    </p>
                  </div>
                  <p className="font-outfit text-[14px]">
                    {CURRENCY_FORMAT(Obj?.taxAmount)}
                  </p>
                </div>
              );
            }
            return <></>;
          })}

          {Boolean(+serviceCharge) && (
            <div className="flex justify-between mb-2 w-full">
              <p>
              <TranslateText textKey={"extra_charge"} namespace="pos" />

              </p>
              <p>{CURRENCY_FORMAT(serviceCharge)}</p>
            </div>
          )}

          <div className="flex justify-between mb-2 text-2xl w-full">
            <p>
            <TranslateText textKey={"bill_total"} namespace="pos" />

            </p>
            <p>
              {`${position === "before" ? symbol : ""} ${CURRENCY_FORMAT(
                total
              )} ${position === "after" ? `${symbol}` : ""}`}
            </p>
          </div>

          <hr className="border-dashed border-b-2 border-slate-600 my-2" />
        </div>

        <div className="flex justify-between">
          <p className="text-sm">
          <TranslateText textKey={"thank_you"} namespace="pos" />

          </p>
          <p className="text-sm">
          <TranslateText textKey={"powered_by_revivo"} namespace="pos" />

          </p>
        </div>
      </div>
    </div>
  );
};

export default GenerateInvoice;
