import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Drawer, message, Input } from "antd";
import CircularProgress from "@mui/material/CircularProgress";

import { updateDisplayAddHousekeeperDrawer } from "../../../reducer/housekeepingReducer";
import { updateHotelDetailsAsync } from "../../../reducer/loginReducer";

import { ReactComponent as CloseIcon } from "../../../assets/images/svgs/CloseIcon.svg";
import { TranslateText } from "../../../utils/translation";
const AddHousekeeperDrawer = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const { displayAddHousekeeperDrawer } = useSelector(
    (store) => store.housekeeping
  );
  const { hotelDetails } = useSelector((store) => store.login);

  const dispatch = useDispatch();

  const handleAddNewHousekeeper = async () => {
    if (!name.length) {
      message.warning("Name field cannot be empty");
      return;
    }
    let updatedHousekeepingStaff;
    if (hotelDetails?.hasOwnProperty("housekeepingStaff")) {
      if (hotelDetails?.housekeepingStaff?.includes(name)) {
        message.warning(
          "Same housekeeper name already exists, try different one"
        );
        return;
      }
      updatedHousekeepingStaff = [...hotelDetails?.housekeepingStaff, name];
    } else {
      updatedHousekeepingStaff = [name];
    }
    const payload = {
      ...hotelDetails,
      housekeepingStaff: updatedHousekeepingStaff,
    };
    setLoading(true);
    const res = await dispatch(
      updateHotelDetailsAsync({ hotelId: hotelDetails?.id, body: payload })
    );
    setLoading(false);
    if (res?.type === "login/updateHotelDetails/fulfilled") {
      dispatch(updateDisplayAddHousekeeperDrawer(false));
      setName("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleAddNewHousekeeper();
  };

  return (
    <Drawer
      title={null}
      placement="right"
      onClose={() => {
        dispatch(updateDisplayAddHousekeeperDrawer(false));
        setName("");
      }}
      open={displayAddHousekeeperDrawer}
      width={"26.1%"}
      closeIcon={null}
      bodyStyle={{ padding: "0px" }}
    >
      <div className="w-full h-full relative p-[10px]">
        <div className="w-full flex items-center justify-between">
          <p className="font-[600] text-[14px] leading-[19.1px] text-[#4d4d4d] mb-[12px]">
            
            <TranslateText
        textKey={"add_new_houseKeeper"}
        namespace="housekeeping"
      />
          </p>
          <CloseIcon
            className="mt-[-14px] cursor-pointer"
            onClick={() => dispatch(updateDisplayAddHousekeeperDrawer(false))}
          />
        </div>

        <Input
          type="text"
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Name"
          onKeyDown={handleKeyDown}
        />
        <button
          className={`w-full ${
            Boolean(name.length)
              ? "text-[#fff] bg-[#007FFF]"
              : "text-[#a8a8a8] bg-[#f9f9f9]"
          } absolute h-[53px] left-0 bottom-0 font-[600] text-[18px] leading-[18px] tracking-[-0.5px]`}
          onClick={handleAddNewHousekeeper}
        >
          {loading ? (
            <CircularProgress style={{ color: "#ffffff" }} size={18} />
          ) : (
      
            <TranslateText
            textKey={"save_housekeeper"}
            namespace="housekeeping"
          />
          )}
        </button>
      </div>
    </Drawer>
  );
};
export default AddHousekeeperDrawer;
