import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  _id: "string",
  createdAt: "2023-07-10T05:54:47.004Z",
  customer: "Sandeep Yadav",
  hotelId: "string",
  invoices: ["string"],
  itemCounts: [],
  items: [],
  payments: ["string"],
  status: "string",
  tag: "Corporate",
  updatedAt: "2023-07-10T05:54:47.005Z",
};

const userItemSlice = createSlice({
  name: "userItem",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_44: () => initialState,

    addItem: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item._id === action.payload._id
      );
      if (itemIndex >= 0) {
        state.items[itemIndex] = action.payload;
        return void state;
      } else {
        return void state.items.push(action.payload);
      }
    },
    removeItem: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item._id === action.payload._id
      );
      if (itemIndex >= 0) {
        if (state.items[itemIndex]?.count > 1) {
          state.items[itemIndex] = action.payload;
        } else {
          state.items.splice(itemIndex, 1);
        }
      }
    },
    resetState: (state, action) => {
      state = initialState;
      return state;
    },
  },
});

export const { CLEAR_REDUX_STORE_44, addItem, removeItem, resetState } =
  userItemSlice.actions;
export default userItemSlice.reducer;
