import React from "react";
import { withTranslation } from "react-i18next";
import Router from "./Router/Router";
import { Provider } from "react-redux";
import store from "./store";
import ErrorBoundary from "./components/ErrorBoundary";
const App = () => {
  return (
    <Provider store={store}>
      <div className="py-5">
        <ErrorBoundary fallbback="There was an error">
          <Router />
        </ErrorBoundary>
      </div>
    </Provider>
  );
};

export default withTranslation()(App);
