import React from "react";
import { Link } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { connect } from "react-redux";
import { updateActiveFooter } from "../../reducer/appHeaderReducer";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  resetError = () => {
    this.setState({ hasError: false });
    this.props.updateActiveFooter("Dashboard");
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="h-screen flex justify-center items-center">
          <div className="text-center flex flex-col items-center justify-center">
            <h1 className="text-3xl text-[#222850] font-bold">Oops</h1>
            <h2 className="text-danger mt-4 text-2xl font-bold">
              Something went Wrong
            </h2>
            <p className="text-[#222850] w-[80%]  m-auto mt-3">
              Don't worry our team is here to help.{" "}
              <a
                href="https://revivotechsupport.freshdesk.com/support/home"
                target="_black"
                className="text-primary cursor-pointer"
              >
                Click here
              </a>{" "}
              to create ticket and get instant support from our fastest support
              team
            </p>

            <Link
              onClick={this.resetError}
              to="/dashboard"
              className="bg-primary absolute top-[62vh] text-white font-bold py-2 px-4 rounded"
            >
              <BsArrowLeftCircleFill className="mr-1 inline-block" />
              <span> Go back</span>
            </Link>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateActiveFooter: (value) => dispatch(updateActiveFooter(value)),
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
