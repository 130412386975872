import { authedAxios } from "../utils/customHttpHeader";

const bookingList = async (payload, endpoint) => {
  let url = `${endpoint}/?hotelId=${payload.hotelId}`;

  if (payload.startDate) url += `&startDate=${payload.startDate}`;

  if (payload.endDate) url += `&endDate=${payload.endDate}`;

  if (payload.statuses) url += `&statuses=${payload.statuses}`;

  const response = await authedAxios().get(url);
  return response.data;
};

export { bookingList };
