import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateinvoice } from "../services/invoice";
import { message } from "antd";

export const updateInvoice = createAsyncThunk(
  "updateInvoice",
  async (payload) => {
    try {
      const response = await updateinvoice(payload);
      return response;
    } catch (error) {
      message.error("Something went wrong!");
      return error;
    }
  }
);

const initialState = {
  loading: false,
  response: {},
  apiError: false,
  paymentResponse: false,
  activeDetails: {
    type: "",
    id: "",
  }, //  store id with type like {type:'recepit','id':'12312312'}
  // when click on any specific receipt or payment ,the details appears on the right side
};

const invoiceAndPaymentSlice = createSlice({
  name: "invoiceAndPayment",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_27: () => initialState,

    updateBookingDetails: (state, action) => {
      state.loading = action.payload.loading;
      state.response = action.payload.response;
      state.apiError = action.payload.apiError;
    },
    updateActiveReceiptOrPaymentDetails: (state, action) => {
      let activeDetails = state.activeDetails;
      if (
        activeDetails.type === action.payload.type &&
        activeDetails.id === action.payload.id
      ) {
        state.activeDetails = {
          type: "",
          id: "",
        };
      } else {
        state.activeDetails = action.payload;
      }
    },

    refreshActiveReceiptOrPaymentBtn: (state) => {
      state.activeDetails = {
        type: "",
        id: "",
      };
    },
    updatePaymentResponse: (state, action) => {
      // after post api call , update payment response
      state.paymentResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateInvoice.pending, (state, action) => {
      state.loading = true;
      state.response = {};
      state.apiError = false;
    });
    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.apiError = false;
    });
    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.loading = false;
      state.response = {};
      state.apiError = false;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_27,
  updateBookingDetails,
  updateActiveReceiptOrPaymentDetails,
  refreshActiveReceiptOrPaymentBtn,
  updatePaymentResponse,
} = invoiceAndPaymentSlice.actions;
export default invoiceAndPaymentSlice.reducer;
