import { message } from "antd";
import { CALCULATE_GST_FOR_ROOMS_MODIFIED, FOLIO_FORMAT } from "./helper";
import { SORT_SELECTED_ROOM_TYPE_AND_NUMBER_OBJECT_BY_ROOM_PRICE } from "./roomOccupency";

export const mealPlanChangeCalculation = (
  roomPriceStructure,
  roomTypes,
  mealPlan,
  occupancyPlanRate
) => {
  if (!Array.isArray(roomPriceStructure) || !Array.isArray(roomTypes)) return;

  const roomTypeMap = roomTypes?.reduce((acc, room) => {
    const defaultOccupancy = room?.defaultOccupancy || 2;

    let rate = occupancyPlanRate?.[room?._id]?.[mealPlan]?.[defaultOccupancy];
    rate = Boolean(rate) ? rate : room?.rate;
    acc[room.name] = rate;
    return acc;
  }, {});

  return roomPriceStructure?.map((obj) => ({
    ...obj,
    rate: +roomTypeMap[obj?.name ?? obj?.type],
  }));
};

export const roomAssigneFunction = (
  adults,
  children,
  mealPlan,
  selectedRoomWTypeAndNum,
  roomTypes,
  roomPriceStructure,
  occupancyPlanRate,
  dispatch,
  updateAdults,
  updateDetailedPriceBreakup,
  type = null
) => {
  // Validation
  let noOfRooms = 0;

  if (selectedRoomWTypeAndNum) {
    Object.keys(selectedRoomWTypeAndNum).forEach((key) => {
      if (selectedRoomWTypeAndNum[key])
        noOfRooms += Boolean(selectedRoomWTypeAndNum[key].length)
          ? selectedRoomWTypeAndNum[key].length
          : 0;
    });
  }

  if (noOfRooms === 0) dispatch(updateDetailedPriceBreakup([]));

  if (
    !Boolean(selectedRoomWTypeAndNum) &&
    Object?.keys(selectedRoomWTypeAndNum)?.length <= 0 &&
    !Boolean(noOfRooms) &&
    noOfRooms <= 0
  )
    return;

  let combinedDefaultOccupancyOfAllRooms = 0;
  let combinedMaxOccupancyOfAllRooms = 0;
  let localDetailedBreakup = [];

  // let totalGuest = adults + children;
  let totalGuest = adults;

  // start---> sort object from lowest room price to higest room price  to occupy

  const { sortedSelectedRoomWTypeAndNum } =
    SORT_SELECTED_ROOM_TYPE_AND_NUMBER_OBJECT_BY_ROOM_PRICE(
      roomPriceStructure,
      selectedRoomWTypeAndNum,
      type
    );
  // end---> sort object from lowest room price to higest room price  to occupy

  Object.keys(selectedRoomWTypeAndNum).forEach((Obj) => {
    const currentRoomType = roomTypes?.find(
      (roomType) => roomType?.name === Obj
    );

    combinedDefaultOccupancyOfAllRooms +=
      (currentRoomType?.defaultOccupancy || 2) *
      selectedRoomWTypeAndNum[Obj]?.length;

    combinedMaxOccupancyOfAllRooms +=
      (currentRoomType?.guestsAllowed || 3) *
      selectedRoomWTypeAndNum[Obj]?.length;
  });

  if (totalGuest <= noOfRooms) {
    Object.keys(selectedRoomWTypeAndNum).forEach((Obj) => {
      const currentRoomTypeObj = roomTypes?.find(
        (roomType) => roomType?.name === Obj
      );

      const currentBackendObj = occupancyPlanRate[currentRoomTypeObj?._id];

      sortedSelectedRoomWTypeAndNum[Obj]?.forEach((room, index) => {
        let currentObjStr = {
          roomName: room?.name,
          noOfPeople: 1,
          rate: currentBackendObj?.[mealPlan]?.[1],
          type: currentRoomTypeObj?.name,
          roomTypeId: currentRoomTypeObj?._id,
        };

        localDetailedBreakup.push(currentObjStr);
      });
    });
  } else if (totalGuest === combinedDefaultOccupancyOfAllRooms) {
    Object.keys(selectedRoomWTypeAndNum).forEach((Obj) => {
      const currentRoomTypeObj = roomTypes?.find(
        (roomType) => roomType?.name === Obj
      );

      const currentBackendObj = occupancyPlanRate[currentRoomTypeObj?._id];

      sortedSelectedRoomWTypeAndNum[Obj]?.forEach((room, index) => {
        let currentObjStr = {
          roomName: room?.name,
          noOfPeople: currentRoomTypeObj?.defaultOccupancy,
          rate: currentBackendObj?.[mealPlan]?.[
            currentRoomTypeObj?.defaultOccupancy
          ],
          type: currentRoomTypeObj?.name,
          roomTypeId: currentRoomTypeObj?._id,
        };

        localDetailedBreakup.push(currentObjStr);
      });
    });
  } else if (totalGuest === combinedMaxOccupancyOfAllRooms) {
    Object.keys(selectedRoomWTypeAndNum).forEach((Obj) => {
      const currentRoomTypeObj = roomTypes?.find(
        (roomType) => roomType?.name === Obj
      );

      const currentBackendObj = occupancyPlanRate[currentRoomTypeObj?._id];

      sortedSelectedRoomWTypeAndNum[Obj]?.forEach((room, index) => {
        let currentObjStr = {
          roomName: room?.name,
          noOfPeople: currentRoomTypeObj?.guestsAllowed,
          rate: currentBackendObj?.[mealPlan]?.[
            currentRoomTypeObj?.guestsAllowed
          ],
          type: currentRoomTypeObj?.name,
          roomTypeId: currentRoomTypeObj?._id,
        };

        localDetailedBreakup.push(currentObjStr);
      });
    });
  } else if (totalGuest > combinedMaxOccupancyOfAllRooms) {
    Object.keys(selectedRoomWTypeAndNum).forEach((Obj) => {
      const currentRoomTypeObj = roomTypes?.find(
        (roomType) => roomType?.name === Obj
      );

      const currentBackendObj = occupancyPlanRate[currentRoomTypeObj?._id];

      sortedSelectedRoomWTypeAndNum[Obj]?.forEach((room, index) => {
        let currentObjStr = {
          roomName: room?.name,
          noOfPeople: currentRoomTypeObj?.guestsAllowed,
          rate: currentBackendObj?.[mealPlan]?.[
            currentRoomTypeObj?.guestsAllowed
          ],
          type: currentRoomTypeObj?.name,
          roomTypeId: currentRoomTypeObj?._id,
        };

        localDetailedBreakup.push(currentObjStr);
      });
    });

    message.destroy();
    noOfRooms &&
      message.info("Guest Limit Exceeded: Please Book Additional Rooms");
  } else {
    let guest = totalGuest;

    // Filling 1 guest in each room
    Object.keys(sortedSelectedRoomWTypeAndNum).forEach((Obj) => {
      const currentRoomTypeObj = roomTypes?.find(
        (roomType) => roomType?.name === Obj
      );
      const currentBackendObj = occupancyPlanRate[currentRoomTypeObj?._id];

      sortedSelectedRoomWTypeAndNum?.[Obj]?.forEach((room, index) => {
        let currentObjStr = {
          roomName: room?.name,
          noOfPeople: 1,
          rate: currentBackendObj?.[mealPlan]?.[1],
          type: currentRoomTypeObj?.name,
          roomTypeId: currentRoomTypeObj?._id,
        };

        localDetailedBreakup.push(currentObjStr);
        guest -= 1;
      });
    });

    let rateMapping = {};
    roomPriceStructure.forEach((rateObj) => {
      rateMapping[rateObj.roomTypeId] = rateObj.rate;
    });

    localDetailedBreakup.sort((a, b) => {
      return rateMapping[a.roomTypeId] - rateMapping[b.roomTypeId];
    });

    // Fill default occupancy of rooms in order of sorted array
    localDetailedBreakup.forEach((Obj) => {
      const currentRoomTypeObj = roomTypes?.find(
        (roomType) => roomType?.name === Obj?.type
      );

      const currentBackendObj = occupancyPlanRate[currentRoomTypeObj?._id];
      let defaultOccupancy = currentRoomTypeObj?.defaultOccupancy || 2;

      defaultOccupancy = defaultOccupancy - Obj?.noOfPeople;

      let guestToBeFilled = Math.min(defaultOccupancy, guest);

      localDetailedBreakup = localDetailedBreakup?.map((currentBreakup) =>
        currentBreakup?.roomName === Obj?.roomName
          ? {
              ...currentBreakup,
              noOfPeople: currentBreakup?.noOfPeople + guestToBeFilled,
              rate: currentBackendObj?.[mealPlan]?.[
                currentBreakup?.noOfPeople + guestToBeFilled
              ],
            }
          : currentBreakup
      );

      guest -= guestToBeFilled;
    });

    // Fill rooms with maximum occupancy in order of sorted object
    localDetailedBreakup.forEach((Obj) => {
      const currentRoomTypeObj = roomTypes?.find(
        (roomType) => roomType?.name === Obj?.type
      );

      const currentBackendObj = occupancyPlanRate[currentRoomTypeObj?._id];
      let maxOccupancy = currentRoomTypeObj?.guestsAllowed || 3;

      maxOccupancy = maxOccupancy - Obj?.noOfPeople;

      let guestToBeFilled = Math.min(maxOccupancy, guest);

      localDetailedBreakup = localDetailedBreakup?.map((currentBreakup) =>
        currentBreakup?.roomName === Obj?.roomName
          ? {
              ...currentBreakup,
              noOfPeople: currentBreakup?.noOfPeople + guestToBeFilled,
              rate: currentBackendObj?.[mealPlan]?.[
                currentBreakup?.noOfPeople + guestToBeFilled
              ],
            }
          : currentBreakup
      );

      guest -= guestToBeFilled;
    });
  }

  dispatch(updateDetailedPriceBreakup(localDetailedBreakup));
};

export const bookingPriceCalculation = (
  selectedRoomWTypeAndNum = {},
  roomPriceStructure = [],
  roomTypes = [],
  nightsCount = 1,
  discountInfo = {},
  paymentBreakUps = [],
  detailedPriceBreakup = [],
  isTaxInclusive = false,
  isDetailPriceBreakupAlreadyPresent = false,
  taxResponse = []
) => {
  const detailedBreakupRequiredFormat = isDetailPriceBreakupAlreadyPresent
    ? detailedPriceBreakup
    : detailedPriceBreakup
        ?.reduce((acc, room) => {
          const existingRoom = acc?.find((item) => item.name === room.type);

          if (existingRoom) {
            existingRoom.rate += room.rate;
            existingRoom.count += 1;
          } else {
            acc.push({ name: room.type, rate: room.rate, count: 1 });
          }

          return acc;
        }, [])
        ?.map((obj) => ({
          name: obj.name,
          rate: obj.rate / obj.count, // Calculate average rate
          count: obj.count,
        }));

  // Return the result of the appropriate price calculation function
  return isTaxInclusive
    ? priceCalculationTaxInclusive(
        selectedRoomWTypeAndNum,
        roomPriceStructure,
        roomTypes,
        nightsCount,
        discountInfo,
        paymentBreakUps,
        detailedBreakupRequiredFormat,
        taxResponse
      )
    : priceCalculationTaxExclusive(
        selectedRoomWTypeAndNum,
        roomPriceStructure,
        roomTypes,
        nightsCount,
        discountInfo,
        paymentBreakUps,
        detailedBreakupRequiredFormat,
        taxResponse
      );
};

const priceCalculationTaxInclusive = (
  selectedRoomWTypeAndNum = {},
  roomPriceStructure = [],
  roomTypes = [],
  nightsCount = 1,
  discountInfo = {},
  paymentBreakUps = [],
  detailedBreakupRequiredFormat = [],
  taxResponse = []
) => {
  // step to calculate total, subtotal, tax  & discount for inclusive tax room

  // step 1
  // Calculate the Exclusive Price
  // room price / exclusive tax formulla
  // for example room price 2000 and tax 12% => 2000/ 1+ (12/100)

  // step 2
  // Apply the  Discount Proportionally:
  // (Exclusive room price / total exclusive price) / total discount

  // step 3
  // After applying the discounts:
  // roomPriceAfterDiscount =  original room price - discountedRoomPrice

  // step 4
  // Calculate the Tax
  // roomPriceAfterDiscount * Tax Rate /  1+ (12/100)

  if (
    !roomPriceStructure?.length ||
    !Object?.keys(selectedRoomWTypeAndNum)?.length ||
    !roomTypes?.length ||
    !detailedBreakupRequiredFormat?.length
  ) {
    return {
      roomTotal: 0,
      subTotal: 0,
      total: 0,
      discount: 0,
      totalRoomTax: 0,
      totalDeposit: 0,
      balanceLeft: 0,
    };
  }
  let roomTotal = 0;
  let roomTotalWithInclusiveTax = 0;
  let originalRoomTotal = 0;
  let discount = 0;
  let totalPaidAmount = 0;
  let totalTax = 0;
  let totalDiscount = 0;
  let subTotal = 0;

  const roomTypeCountMap = {};
  const roomTypePriceMap = {};

  detailedBreakupRequiredFormat.forEach((item) => {
    const roomName = item?.name || item?.type;
    roomTypeCountMap[roomName] = selectedRoomWTypeAndNum[roomName]?.length || 0;
    roomTypePriceMap[roomName] = item?.rate || 0;

    // Calculate total for the room in this loop to avoid a second pass
  });

  const taxObject = CALCULATE_GST_FOR_ROOMS_MODIFIED(
    roomTypeCountMap,
    roomTypes,
    taxResponse
  );

  // calculate room amount
  detailedBreakupRequiredFormat.forEach((item) => {
    const roomName = item?.name || item?.type;
    // Calculate total for the room in this loop to avoid a second pass

    const tax = taxObject?.taxRoomTypeWise[roomName];
    const singleRoomPrice =
      roomTypePriceMap[roomName] * roomTypeCountMap[roomName] * nightsCount;

    const inclusiveTaxFormula = 1 + +tax / 100;
    roomTotalWithInclusiveTax += singleRoomPrice / inclusiveTaxFormula;
    originalRoomTotal += singleRoomPrice;
  });

  // if (discountInfo?.type === "percentage")
  //   discount = (roomTotal * discountInfo?.value) / 100;
  // else discount = parseFloat(discountInfo?.value) || 0;

  if (discountInfo?.type === "percentage") discount = discountInfo?.value;
  else discount = parseFloat(discountInfo?.value) || 0;

  discount = Boolean(discount) ? discount : 0;

  const roomPriceCalculatedBaseOnFlatDiscount = (roomName) => {
    const singleRoomPrice =
      roomTypePriceMap[roomName] * roomTypeCountMap[roomName] * nightsCount;

    const taxRate = taxObject?.taxRoomTypeWise[roomName];

    const inclusiveTaxFormula = 1 + +taxRate / 100;

    const singleRoomDiscount =
      (singleRoomPrice / originalRoomTotal) * +discountInfo?.value; // 480 + 240

    // apply the flat discount

    const originalRoomPriceAfterDiscount = singleRoomPrice - singleRoomDiscount; // 4000 - 480 = 3520

    const exclusivePriceAfterDiscount =
      originalRoomPriceAfterDiscount / inclusiveTaxFormula; // 1760 / 1.12 = 1571.43

    const taxAmount =
      originalRoomPriceAfterDiscount - exclusivePriceAfterDiscount;

    const inclusiveDiscount = singleRoomDiscount / inclusiveTaxFormula;

    const roomTotal = exclusivePriceAfterDiscount;
    const discount = inclusiveDiscount;
    const tax = taxAmount;

    return {
      singleRoomTotal: roomTotal,
      singleRoomDiscount: discount,
      singleRoomTax: tax,
    };
  };

  const roomPriceCalculatedBaseOnPerCentageDiscounnt = (roomName) => {
    const originalRoomPrice =
      roomTypePriceMap[roomName] * roomTypeCountMap[roomName] * nightsCount;

    const taxRate = taxObject?.taxRoomTypeWise[roomName];

    const inclusiveTaxFormula = 1 + +taxRate / 100;

    //const roomTotal = singleRoomPrice / inclusiveTaxFormula;

    const roomPriceWithInclusiveTax = originalRoomPrice / inclusiveTaxFormula;

    //  discount calculation on  original room  price

    let discountedRoomPrice = 0;

    discountedRoomPrice =
      roomPriceWithInclusiveTax * (+discountInfo?.value / 100);

    // Price after Discount (Exclusive of Tax)

    const roomPriceAfterDiscounnt =
      roomPriceWithInclusiveTax - discountedRoomPrice;

    // calculate innclusive tax

    const inclusiveTax = roomPriceAfterDiscounnt * (+taxRate / 100);

    const roomTotal = roomPriceAfterDiscounnt;
    const discount = discountedRoomPrice;
    const tax = inclusiveTax;

    return {
      singleRoomTotal: roomTotal,
      singleRoomDiscount: discount,
      singleRoomTax: tax,
    };
  };

  // calculate discount and tax amount
  detailedBreakupRequiredFormat.forEach((item) => {
    const roomName = item?.name || item?.type;

    // Calculate total for the room in this loop to avoid a second pass

    if (discountInfo?.type === "percentage") {
      const { singleRoomTotal, singleRoomDiscount, singleRoomTax } =
        roomPriceCalculatedBaseOnPerCentageDiscounnt(roomName);
      roomTotal += singleRoomTotal + singleRoomDiscount;
      totalDiscount += singleRoomDiscount;
      totalTax += singleRoomTax;
    } else {
      const { singleRoomTotal, singleRoomDiscount, singleRoomTax } =
        roomPriceCalculatedBaseOnFlatDiscount(roomName);
      roomTotal += singleRoomTotal + singleRoomDiscount;
      totalDiscount += singleRoomDiscount;
      totalTax += singleRoomTax;
    }
  });

  // Calculate final room price after discount per room
  const totalRooms = Object.values(roomTypeCountMap)?.reduce(
    (sum, count) => sum + count,
    0
  );

  const discountPerRoom =
    totalRooms > 0 ? discount / totalRooms / nightsCount : 0;

  const roomTypePriceMapAfterDiscount = {};
  for (const key in roomTypeCountMap) {
    roomTypePriceMapAfterDiscount[key] =
      roomTypePriceMap?.[key] - discountPerRoom;
  }

  totalPaidAmount = paymentBreakUps.reduce(
    (sum, payment) => sum + Number(payment.customerPaidAmount || 0),
    0
  );
  subTotal = roomTotal - totalDiscount;
  const total = subTotal + totalTax;
  const balanceLeft = total - totalPaidAmount;

  //const roomTotal

  return {
    roomTotal: roomTotal || 0.0,
    subTotal: subTotal || 0.0,
    total: total || 0.0,
    discount: totalDiscount,
    totalRoomTax: totalTax || 0.0,
    totalDeposit: totalPaidAmount || 0.0,
    balanceLeft: balanceLeft || 0.0,
  };
};

const priceCalculationTaxExclusive = (
  selectedRoomWTypeAndNum = {},
  roomPriceStructure = [],
  roomTypes = [],
  nightsCount = 1,
  discountInfo = {},
  paymentBreakUps = [],
  detailedBreakupRequiredFormat = [],
  taxResponse = []
) => {
  if (
    !roomPriceStructure?.length ||
    !Object.keys(selectedRoomWTypeAndNum)?.length ||
    !roomTypes?.length ||
    !detailedBreakupRequiredFormat?.length
  ) {
    return {
      roomTotal: 0,
      subTotal: 0,
      total: 0,
      discount: 0,
      totalRoomTax: 0,
      totalDeposit: 0,
      balanceLeft: 0,
    };
  }

  let roomTotal = 0;
  let totalRoomTax = 0;

  const roomTypeCountMap = {};
  const roomTypePriceMap = {};

  // Populate roomTypeCountMap and roomTypePriceMap
  detailedBreakupRequiredFormat.forEach((item) => {
    const roomName = item?.name || item?.type;
    roomTypeCountMap[roomName] =
      selectedRoomWTypeAndNum?.[roomName]?.length || 0;
    roomTypePriceMap[roomName] = item?.rate || 0;
  });

  const totalRooms = Object.values(roomTypeCountMap).reduce(
    (sum, count) => sum + count,
    0
  );

  for (const roomName in roomTypeCountMap) {
    const roomCount = roomTypeCountMap[roomName];
    const roomRate = roomTypePriceMap[roomName];

    roomTotal += roomRate * roomCount * nightsCount;
  }

  const discount =
    discountInfo?.type === "percentage"
      ? (roomTotal * discountInfo?.value) / 100
      : parseFloat(discountInfo?.value) || 0;

  const discountPerRoom =
    totalRooms > 0 ? discount / totalRooms / nightsCount : 0;

  const taxResult = CALCULATE_GST_FOR_ROOMS_MODIFIED(
    selectedRoomWTypeAndNum,
    roomTypes,
    taxResponse
  );

  const taxObject = taxResult?.taxRoomTypeWise || {};

  for (const roomName in roomTypeCountMap) {
    const roomCount = roomTypeCountMap[roomName];
    const roomRate = roomTypePriceMap[roomName];
    const roomRateAfterDiscount = roomRate - discountPerRoom;

    totalRoomTax +=
      (roomRateAfterDiscount *
        roomCount *
        nightsCount *
        (taxObject[roomName] || 0)) /
      100;
  }

  const totalPaidAmount = paymentBreakUps?.reduce(
    (sum, payment) => sum + Number(payment?.customerPaidAmount || 0),
    0
  );

  // Final calculations
  const subTotal = roomTotal - discount;
  const total = subTotal + totalRoomTax;
  const balanceLeft = total - totalPaidAmount;

  return {
    roomTotal: roomTotal || 0.0,
    subTotal: subTotal || 0.0,
    total: total || 0.0,
    discount: discount || 0.0,
    totalRoomTax: totalRoomTax || 0.0,
    totalDeposit: totalPaidAmount || 0.0,
    balanceLeft: balanceLeft || 0.0,
  };
};

export const invoicePriceBreakupCalculation = (priceBreakupObj, taxArray) => {
  const result = bookingDetailsAddOnsCalculation([priceBreakupObj], taxArray);

  return {
    taxRate: FOLIO_FORMAT(result?.totalAddOnsTax),
    totalRate: FOLIO_FORMAT(result?.discountedAddOnsTotal),
    baseRate: FOLIO_FORMAT(result?.addOnsTotal - result?.totalAddOnsTax),
  };
};

// Addons Calulation
export const bookingDetailsAddOnsCalculation = (addOnsArray, taxArray) => {
  if (!Array.isArray(taxArray && addOnsArray)) {
    return {
      addOnsTotal: 0,
      totalAddOnsTax: 0,

      addOnsDiscountedTotalWithoutTax: 0,
      addOnsTotalWithoutTax: 0,

      addOnsTotalWithCount: 0,
      discountedAddOnsTotalWithCount: 0,
      totalAddOnsTaxWithCount: 0,

      discountedAddOnsTotal: 0,
    };
  }

  let totalAddonTaxPercentage = 0;
  let addOnsTotalWithoutTax = 0;
  let addOnsDiscountedTotalWithoutTax = 0;

  let addOnsTotalWithoutTaxWithCount = 0;
  let addOnsDiscountedTotalWithoutTaxWithCount = 0;

  // Preprocess taxArray into a dictionary for quick lookup
  const taxLookup = taxArray.reduce((acc, taxObj) => {
    acc[taxObj.taxId] = taxObj.taxPercentage;
    return acc;
  }, {});

  addOnsArray?.forEach((Obj) => {
    const currentObjTaxArray = Obj?.taxIds;

    currentObjTaxArray?.forEach((taxId) => {
      totalAddonTaxPercentage += taxLookup?.[taxId] ?? 0; // Add tax amount if found, else 0
    });

    let currentRate = Boolean(Obj?.discountedRate)
      ? Obj?.discountedRate
      : Boolean(Obj?.rate)
      ? Obj?.rate
      : Obj?.price; // Corrected closing parenthesis

    addOnsDiscountedTotalWithoutTax += currentRate;
    addOnsTotalWithoutTax += Obj?.rate ?? Obj?.price;

    addOnsTotalWithoutTaxWithCount += currentRate * (Obj?.count ?? 1); // Updated to use currentRate
    addOnsDiscountedTotalWithoutTaxWithCount +=
      (Obj?.rate ?? Obj?.price) * (Obj?.count ?? 1);
  });

  let totalAddOnsTax =
    (addOnsDiscountedTotalWithoutTax * totalAddonTaxPercentage) / 100;

  let totalAddOnsTaxWithCount =
    (addOnsDiscountedTotalWithoutTaxWithCount * totalAddonTaxPercentage) / 100;

  totalAddOnsTax = Boolean(totalAddOnsTax) ? totalAddOnsTax : 0;

  return {
    addOnsTotalWithCount:
      totalAddOnsTaxWithCount + addOnsTotalWithoutTaxWithCount,

    discountedAddOnsTotalWithCount:
      totalAddOnsTaxWithCount + addOnsDiscountedTotalWithoutTaxWithCount,
    totalAddOnsTaxWithCount: totalAddOnsTaxWithCount,

    addOnsTotal: Boolean(addOnsTotalWithoutTax)
      ? totalAddOnsTax + addOnsTotalWithoutTax
      : 0,
    discountedAddOnsTotal: Boolean(addOnsDiscountedTotalWithoutTax)
      ? totalAddOnsTax + addOnsDiscountedTotalWithoutTax
      : 0,
    totalAddOnsTax: totalAddOnsTax,
  };
};

export const addOnsCalculation = (addOnsArray, taxResponse) => {
  if (!Array.isArray(taxResponse && addOnsArray)) {
    return {
      addOnCostWithoutTax: 0,
      totalAddOnsTax: 0,
      addOnsTotal: 0,
    };
  }

  // Preprocess taxResponse into a dictionary for quick lookup
  const taxLookup = taxResponse?.reduce((acc, tax) => {
    acc[tax.id] = tax;
    return acc;
  }, {});

  let addOnCostWithoutTax = 0;
  let totalAddOnsTax = 0;

  (addOnsArray || []).forEach((Obj) => {
    const itemCost = Obj.price * Obj.count;
    addOnCostWithoutTax += itemCost;

    const addOnTaxPercentage = addOnTax(Obj.taxIds || [], taxLookup);
    totalAddOnsTax += addOnTaxPercentage * itemCost;
  });

  return {
    addOnCostWithoutTax: Boolean(addOnCostWithoutTax) ? addOnCostWithoutTax : 0,
    totalAddOnsTax: Boolean(totalAddOnsTax) ? totalAddOnsTax : 0,
    addOnsTotal:
      (Boolean(addOnCostWithoutTax) ? addOnCostWithoutTax : 0) +
      (Boolean(totalAddOnsTax) ? totalAddOnsTax : 0),
  };
};

export const addOnTax = (taxArray = [], taxLookup = {}) => {
  if (!Array.isArray(taxArray) || !taxArray.length) return 0;

  let cumulativeTax = taxArray?.reduce((total, taxId) => {
    const tax = taxLookup?.[taxId];
    return tax ? total + +tax.taxPercentage : total;
  }, 0);

  return cumulativeTax > 0 ? cumulativeTax / 100 : 0;
};
