import { authedAxios } from "../../utils/customHttpHeader";

export const getAllSuppliersService = async () => {
  const response = await authedAxios().get("suppliers");
  return response;
};

export const deleteSupplierByIDService = async (params) => {
  const response = await authedAxios().delete(`suppliers/${params}`);
  return response;
};

export const updateSupplierByIdService = async (payload) => {
  const response = await authedAxios().put(`suppliers/${payload.id}`, payload);
  return response;
};
