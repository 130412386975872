import React from "react";
import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePaymentDetails,
  updateBookingListData,
} from "../../../../reducer/bookingReducer";
import {
  updatePayment,
  updateEditPaymentDrawer,
  getAllPayments,
} from "../../../../reducer/paymentReducer";
import { IoIosCloseCircle } from "react-icons/io";
import { useEffect } from "react";
import { Button, message, Modal } from "antd";
import CustomTextField from "../../InputField/Input";
import { GoTrash } from "react-icons/go";

import AddParty from "./AddParty";
import { updateAddPartyDropdownVisible } from "../../../../reducer/expenseReducer";
import { getPartyById, getPartyList } from "../../../../reducer/partyReducer";
import useAllBookingFunction from "../../../../hooks/bookingCustomHook/useAllBookingFunction";
import { getInvoiceById } from "../../../../reducer/orderReducer";

export default function EditPaymentDrawer({
  isBookingListDrawer,
  drawerWidth,
  isPosComponent,
}) {
  const { hotelDetails } = useSelector((store) => store.login);
  const { paymentDetails, response } = useSelector((store) => store.booking);
  const { paymentModesResponse, editPaymentDrawer } = useSelector(
    (store) => store.payments
  );
  const { deletePaymentAllowed, editPaymentAllowed } = useSelector(
    (store) => store.usersRestriction
  );

  const dispatch = useDispatch();

  const [amount, setAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("cash");
  const [transactionNo, setTransactionNo] = useState(null);
  const [methodToPartyId, setMethodToPartyId] = useState({});
  const [displayDeletePaymentModal, setDisplayDeletePaymnetModal] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPaymentObj, setCurrentPaymentObj] = useState({});

  const [partyNames, setPartyNames] = useState([]);
  const [currentAttachedParty, setCurrentAttachedParty] = useState({});
  const [attachPartyString, setAttachPartyString] = useState("");
  const [attachPartyValue, setAttachPartyValue] = useState("");

  const [paymentModePartyId, setPaymentModePartyId] = useState("");

  const { addPartyDropdownVisible } = useSelector((store) => store.expense);
  const { partyResponse, partyLoading } = useSelector((store) => store.party);

  useEffect(() => {
    if (Array.isArray(paymentModesResponse) && paymentModesResponse?.length) {
      let updatedObj = {};
      paymentModesResponse?.forEach((mode) => {
        const { method, partyId } = mode;
        updatedObj[method] = partyId;
      });
      setMethodToPartyId(updatedObj);
      setPaymentModePartyId(updatedObj["cash"]);
    }
  }, [paymentModesResponse]);

  useEffect(() => {
    if (!editPaymentDrawer?.display) return;

    if (editPaymentDrawer?.paymentObj) {
      let obj = editPaymentDrawer?.paymentObj;

      setCurrentPaymentObj(obj);
      setAmount(Math.abs(obj?.amount) || "");
      setPaymentMode(obj?.method || "cash");
      setTransactionNo(obj?.transactionNo || "");
    }
  }, [editPaymentDrawer]);

  useEffect(() => {
    const payload = {
      hotelId: hotelDetails?.id,
      pageSize: 30,
      searchText: attachPartyString,
      infiniteScrollDisabled: true,
    };
    dispatch(getPartyList(payload));
  }, [attachPartyString]);

  useEffect(() => {
    const updatedPartyNames = [];
    partyResponse?.forEach((party) => {
      const { displayName } = party;
      if (Boolean(displayName)) {
        updatedPartyNames.push(displayName);
      }
    });
    setPartyNames(updatedPartyNames);
  }, [partyResponse]);

  useEffect(() => {
    if (
      (!currentPaymentObj?.paymentModePartyId && paymentMode !== "btc") ||
      !editPaymentDrawer?.display
    )
      return;

    async function performApi() {
      const res = await dispatch(
        getPartyById({
          hotelId: hotelDetails?.id,
          id: currentPaymentObj?.paymentModePartyId,
        })
      );
      if (res?.payload?.status === 200) {
        setCurrentAttachedParty(res?.payload?.data);

        let currentDisplayName = res?.payload?.data?.displayName;
        if (!partyNames?.includes(currentDisplayName)) {
          let updatePartyNames = [...partyNames, currentDisplayName];
          setPartyNames(updatePartyNames);
        }

        setAttachPartyValue(res?.payload?.data?.displayName);
      }
    }

    performApi();
  }, [editPaymentDrawer, currentPaymentObj]);

  const editPayment = async () => {
    if (!Boolean(amount)) {
      message.info("Please enter amount");
      return;
    }

    if (!editPaymentAllowed) {
      return;
    }

    setLoading(true);

    let newPaymentObj = {
      ...editPaymentDrawer?.paymentObj,
      amount: editPaymentDrawer?.paymentObj?.amount < 0 ? -amount : amount,
      transactionNo: transactionNo,
      method: paymentMode,
      paymentModePartyId: currentAttachedParty?.id,
    };

    const payload = {
      hotelId: hotelDetails?.id,
      paymentObj: newPaymentObj,
      message:
        editPaymentDrawer?.paymentObj?.amount < 0
          ? "Refund updated Successfully"
          : "Payment  updated successfully !",
    };

    const updatedPayment = await dispatch(updatePayment(payload));

    if (isBookingListDrawer) {
      const newResponsePaymentArray = response?.payments?.map((Obj) =>
        Obj?.id === updatedPayment?.payload?.id ? updatedPayment?.payload : Obj
      );

      const newResponse = { ...response, payments: newResponsePaymentArray };

      const newPaymentArray = paymentDetails?.map((Obj) =>
        Obj?.id === updatedPayment?.payload?.id ? updatedPayment?.payload : Obj
      );

      dispatch(updatePaymentDetails(newPaymentArray));
      dispatch(updateBookingListData(newResponse));
    }

    if (isPosComponent) {
      const payload = {
        hotelId: hotelDetails?.id,
        invoiceId: updatedPayment?.payload?.invoiceId,
        excludePaymentMethods: [""],
      };

      if (updatedPayment?.payload?.invoiceId) {
        dispatch(getAllPayments(payload));
        dispatch(
          getInvoiceById({
            hotelId: hotelDetails?.id,
            id: updatedPayment?.payload?.invoiceId,
          })
        );
      }
    }

    dispatch(updateEditPaymentDrawer({ display: false, paymentObj: {} }));
    setLoading(false);
  };

  const { handleDeletePayment } = useAllBookingFunction(setLoading);

  const handleDeleteOnClick = async () => {
    await handleDeletePayment(
      currentPaymentObj?.id,
      isBookingListDrawer,
      "Payment deleted successfully"
    );

    if (isPosComponent && currentPaymentObj?.invoiceId) {
      dispatch(
        getInvoiceById({
          hotelId: hotelDetails?.id,
          id: currentPaymentObj?.invoiceId,
        })
      );
    }

    dispatch(updateEditPaymentDrawer({ display: false, paymentObj: {} }));
    setDisplayDeletePaymnetModal(false);
    setLoading(false);
  };

  const handleClose = () => {
    dispatch(updateEditPaymentDrawer({ display: false, paymentObj: {} }));
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: drawerWidth,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",
          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",
          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={editPaymentDrawer?.display}
      onClose={loading ? undefined : handleClose}
    >
      <Modal
        open={displayDeletePaymentModal}
        centered
        title=""
        width={"auto"}
        zIndex={5000}
        styles={{ mask: { backdropFilter: "blur(2px)" } }}
        onCancel={() => setDisplayDeletePaymnetModal(false)}
        footer={
          <div className="w-full flex gap-2 justify-center mt-5">
            <Button
              className="text-[1.1rem]  w-[11rem] h-[3rem] border-[#D0D5DD] text-[#808080] rounded-[0.5rem] font-[Poppins] font-[600] "
              onClick={() => setDisplayDeletePaymnetModal(false)}
            >
              NO
            </Button>
            <Button
              className="text-[#FFF] text-[1.1rem] rounded-xl  bg-[#007FFF] w-[11rem] h-[3rem]"
              onClick={handleDeleteOnClick}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <div>YES</div>
              )}
            </Button>
          </div>
        }
      >
        <div className="font-nunito text-[1.125remrem] font-[600] text-[#4D4D4D]">
          Delete Payment?
        </div>
        <div className="text-[#808080] font-nunito text-[0.875rem] font-[400]">
          By clicking 'Yes,' the payment will be deleted and removed from the
          system.
        </div>
      </Modal>

      <div className="flex flex-col gap-5 items-start my-2 font-outfit pb-[5rem]  ">
        <header className="flex w-[100%] justify-between items-center px-3  ">
          <h2 className="font-[500] text-[18px] text-[#222B45] ">
            {editPaymentDrawer?.paymentObj?.amount < 0
              ? "Edit Refund"
              : "Edit Payment"}
          </h2>

          <div className="flex items-center gap-2 ">
            {deletePaymentAllowed ? (
              <GoTrash
                onClick={() => setDisplayDeletePaymnetModal(true)}
                className="text-[#FE594E] cursor-pointer"
              />
            ) : (
              <></>
            )}

            <IoIosCloseCircle
              onClick={loading ? undefined : handleClose}
              className="cursor-pointer text-[18px] text-[#4D4D4D]"
            />
          </div>
        </header>

        <div className="flex flex-col mb-3 gap-3 items-center pt-5 w-[100%] border-t-[10px] border-t-[#F2F4FC]">
          <div className="flex  w-[90%] mx-5 gap-7 justify-center">
            <div className="w-[50%]">
              <CustomTextField
                label="Amount"
                isManadatory={true}
                value={amount}
                onChange={(value) => setAmount(value)}
                type="text"
                inputPadding={true}
                inputPaddingValue={paymentMode !== "btc" ? "2.5px" : "8px"}
              />
            </div>

            <div className="w-[50%]">
              {paymentMode !== "btc" ? (
                <FormControl
                  sx={{ width: "100%", color: "#0000004D" }}
                  size="small"
                >
                  <InputLabel
                    id="paymentMode"
                    sx={{ color: "#0000004D", fontSize: "15px" }}
                  >
                    Payment Mode
                  </InputLabel>

                  <Select
                    labelId="SelectPaymentLabel"
                    id="selectPayment"
                    label="Payment Mode "
                    value={Boolean(paymentMode) ? paymentMode : ""}
                    onChange={(e) => {
                      setPaymentMode(e.target.value);
                      setPaymentModePartyId(methodToPartyId[e.target.value]);
                    }}
                    sx={{
                      borderRadius: "6px",
                      fontSize: "15px",
                      "& .MuiSelect-icon": {
                        color: "#2F80ED",
                      },
                    }}
                  >
                    {paymentModesResponse?.map((mode) => {
                      const { method, partyId, paymentMode } = mode;

                      return (
                        <MenuItem
                          value={method}
                          sx={{
                            fontSize: "14px",
                            color: "#00000098",
                            fontFamily: "Outfit",
                            fontWeight: 400,
                          }}
                        >
                          {paymentMode}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <div className="relative">
                  {addPartyDropdownVisible && (
                    <AddParty
                      updateAttachPartyValue={setAttachPartyValue}
                      partyType="source"
                      inBookingDetails={true}
                      isLocalStateUsed={true}
                    />
                  )}
                  <Autocomplete
                    id="party-select"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        padding: "9px !important",
                        borderRadius: "10px",
                      },

                      "& label": {
                        color: "#0000004D",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      },
                      "& input": {
                        fontFamily: "Poppins",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      },
                      "& .MuiInputLabel-root": {
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: 200,
                        alignItems: "center",
                        fontFamily: "nunito",
                        color: "#808080",
                        fontSize: "12px", // Font size when label is down
                        lineHeight: "16.37px",
                      },
                      "& .MuiInputLabel-shrink": {
                        fontSize: "0.93rem", // Font size when label is up (focused)
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "nunito",
                      },
                    }}
                    options={
                      partyNames?.length
                        ? ["ADD PARTY", ...partyNames]
                        : ["ADD PARTY"]
                    }
                    value={attachPartyValue}
                    onChange={(event, newValue) => {
                      if (newValue === "ADD PARTY") {
                        dispatch(updateAddPartyDropdownVisible(true));
                        return;
                      }
                      let currentParty = partyResponse?.find(
                        (Obj) => Obj?.displayName === newValue
                      );
                      setCurrentAttachedParty(currentParty);
                      setAttachPartyValue(newValue);
                    }}
                    inputValue={attachPartyString}
                    onInputChange={(event, newInputValue) => {
                      setAttachPartyString(newInputValue);
                    }}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{
                          color:
                            option === "ADD PARTY" ? "#007FFF" : "#000000B2",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "18px",
                          fontStyle: "Outfit",
                        }}
                      >
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <p>
                            Select Party{" "}
                            <span className="text-[#007FFF]">*</span>
                          </p>
                        }
                        sx={{ borderRadius: 10 }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        loading={partyLoading}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="w-[89%]">
            <CustomTextField
              label={paymentMode === "cash" ? "Cashier" : "Transaction No"}
              isManadatory={false}
              value={transactionNo}
              onChange={(value) => setTransactionNo(value)}
              type="text"
              inputPadding={true}
              inputPaddingValue={"3px"}
            />
          </div>
        </div>
      </div>

      <div
        className={`bg-[#FFF] flex justify-end items-center p-2 py-3 absolute border-t-[2px] border-t-[#EDF1F7] bottom-0  w-full`}
        onClick={() => editPayment()}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <div className=" font-[500] cursor-pointer py-[12px] px-[24px] rounded-[8px] font-outfit  text-[#FFF] bg-[#007FFF]  text-[18px] leading-[18px] ">
            Save Details
          </div>
        )}
      </div>
    </Drawer>
  );
}
