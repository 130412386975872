import React, { useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  getInvoiceById,
  getTableOrders,
  resetInvoicePostDataAndOrderData,
} from "../../../reducer/orderReducer";
import KOTModal from "./KOTModal";
import {
  updateKotModal,
  updatePriceBreakUp,
  updateSelectedOderItem,
  updateSelectedOrderItemCountMap,
} from "../../../reducer/posReducer/restaurant";
import KOTLoader from "../../ShimmerLoader/PosLoader/KotLoader/Index";
import { TranslateText } from "../../../utils/translation";

const Input = () => {
  const { hotelDetails } = useSelector((store) => store.login);
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);
  const { activePosId } = useSelector((store) => store.posSetting);
  const dispatch = useDispatch();

  const { loading, data, invoicePostData, invoicePostLoading } = useSelector(
    (store) => store.order
  );
  const { isMobileAndTabletScreen } = useSelector((store) => store.appHeader);
  const { menuDrawer } = useSelector((store) => store.app);

  useEffect(() => {
    if (Boolean(tableOrderDetails.invoiceId)) {
      dispatch(
        getInvoiceById({
          hotelId: hotelDetails?.id,
          id: tableOrderDetails?.invoiceId,
        })
      );
    } else dispatch(resetInvoicePostDataAndOrderData());
  }, [tableOrderDetails?.tableNumber, tableOrderDetails?.invoiceId]);

  useEffect(() => {
    let OrderLength = invoicePostData?.orders?.length;

    let IDS = "";

    invoicePostData?.orders?.forEach((item, i) => {
      if (i === OrderLength - 1) {
        IDS += item;
      } else {
        IDS += item + ",";
      }
    });

    let payload = {
      hotelId: hotelDetails?.id,
      roomId: tableOrderDetails?.roomId,
      tableId: tableOrderDetails?.tableId,
      ids: IDS,
      posId: activePosId,
    };

    if (OrderLength > 0) {
      dispatch(getTableOrders(payload));
    } else {
      // if order is not exist , clear redux store

      dispatch(resetInvoicePostDataAndOrderData());
    }
  }, [
    invoicePostData?.id,
    tableOrderDetails?.tableNumber,
    tableOrderDetails?.invoiceId,
    activePosId,
  ]);

  const handleSelectedItem = (item) => {
    dispatch(updateSelectedOderItem(item));

    let payload = {
      type: "whole",
      data: item?.itemCountMap,
    };

    dispatch(updateSelectedOrderItemCountMap(payload));
    dispatch(updatePriceBreakUp());
    dispatch(updateKotModal(true));
  };

  return (
    <div
      className={` ${
        isMobileAndTabletScreen
          ? " left-[1.7rem] w-[60%] "
          : menuDrawer
          ? " left-[6.5rem] w-[55%] "
          : " left-[14.6em] w-[46%] "
      }  fixed   bottom-0  left-0 right-0 max-h-[10rem] overflow-y-auto`}
    >
      {loading || invoicePostLoading ? (
        <div className="w-[100%] flex justify-center gap-3 items-center font-outfit">
          <KOTLoader />
        </div>
      ) : (
        <div className="bg-[#FAFAFA] flex flex-wrap  ">
          {data?.map((item, index) => {
            return (
              <div
                onClick={() => handleSelectedItem(item)}
                className="flex flex-col bg-[#2F80ED1A] p-2 border border-[#0000001a] 
               cursor-pointer relative rounded shadow-sm max-w-[160px]"
              >
                <div className="flex justify-between items-center ">
                  <div className="font-outfit text-[#000000B2] text-[12px] whitespace-nowrap">
                  <TranslateText textKey={"kot_no"} namespace="pos" />

                     - {data?.length - index}
                  </div>

                  <button className="flex  rounded-md text-[#000000] text-xs py-1 px-2 whitespace-nowrap font-outfit">
                    {item?.status === "inProgress" ? (
                      <span>
                      <TranslateText textKey={"in_progress"} namespace="pos" />
                      </span>
                    ) : item?.status === "billed" ? (
                      <span>
                      <TranslateText textKey={"billed"} namespace="pos" />

                      </span>
                    ) : (
                      <span>{item?.status}</span>
                    )}
                  </button>
                </div>

                <div className="flex  justify-between">
                  <div className="text-[#000000b3] text-[12px]">
                    {moment(item?.createdAt).format("h:mm a")}
                  </div>

                  <div className="flex text-[#00000080] text-[12px]">
                    {Boolean(item?.itemCountMap)
                      ? Object.keys(item?.itemCountMap)?.length
                      : 0}{" "}
                     
                    <TranslateText textKey={"items"} namespace="pos" />

                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <KOTModal runningOrders={data} runningInvoice={invoicePostData} />
    </div>
  );
};

export default Input;
