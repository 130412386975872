import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authedAxios } from "../utils/customHttpHeader";
import { message } from "antd";

export const postFormData = createAsyncThunk(
  "addItems/addItemPostData",
  async (payload) => {
    try {
      const response = await authedAxios()().post(
        `items?hotelId=${localStorage.getItem("hotelid")}`,
        payload
      );
      return response.data;
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      return err;
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const addItemSlice = createSlice({
  name: "addItems",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_7: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(postFormData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postFormData.fulfilled, (state, action) => {
      message.success("Item Added Successfully");
      state.loading = false;
      state.data = [...state.data, action.payload];
      state.error = null;
    });
    builder.addCase(postFormData.rejected, (state, action) => {
      message.error("Something went wrong!");
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export const { CLEAR_REDUX_STORE_7, addItem, removeItem } =
  addItemSlice.actions;

export default addItemSlice.reducer;
