import moment from "moment";
import React, { useState, useEffect } from "react";
import { ConfigProvider, DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { GET_ISO_FORMAT } from "../../../../../utils/helper";
import CustomFooter from "./CustomFooter";
import { updateVisibleBookingDetailsCalendar } from "../../../../../reducer/bookingReducer";
import { calculateNumberOfNights } from "../../../../../utils/bookingDetailsHelper";
import { getHotelCheckInOutTime } from "../../../../../utils/hotelHelper";
import { TranslateText } from "../../../../../utils/translation";
const CustomCalendar = () => {
  const { RangePicker } = DatePicker;

  const {
    selectedCheckInDateTime,
    selectedCheckOutDateTime,
    visibleBookingDetailsCalendar,
  } = useSelector((store) => store.booking);
  const { hotelDetails } = useSelector((store) => store.login);

  const dispatch = useDispatch();

  const [selectedCheckInDate, setSelectedCheckInDate] = useState("");
  const [selectedCheckOutDate, setSelectedCheckOutDate] = useState("");

  const { CheckInTime, CheckOutTime } = getHotelCheckInOutTime(
    hotelDetails || {}
  );

  useEffect(() => {
    if (selectedCheckInDateTime) {
      setSelectedCheckInDate(selectedCheckInDateTime);
      setSelectedCheckOutDate(selectedCheckOutDateTime);
    } else {
      setSelectedCheckInDate(moment().format("YYYY/MM/DD"));

      setSelectedCheckOutDate(moment().add(1, "days").format("YYYY/MM/DD"));
    }
  }, []);

  const onSelectDate = (momentObj, dateString) => {
    setSelectedCheckInDate(GET_ISO_FORMAT(momentObj[0].$d));
    setSelectedCheckOutDate(GET_ISO_FORMAT(momentObj[1].$d));
  };

  const boxStyle = {
    boxShadow: "0px 2.5px 0.2px 0px rgba(0, 0, 0, 0.05)",
  };

  return (
    <div
      className="flex items-center  border border-gray-400 rounded-lg gap-2 m-2 h-[2.375rem] justify-between px-2 cursor-pointer "
      onClick={(e) => {
        dispatch(
          updateVisibleBookingDetailsCalendar(!visibleBookingDetailsCalendar)
        );
        e.stopPropagation();
      }}
    >
      <div onClick={(e) => e.stopPropagation()} className="absolute">
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                cellHeight: 25,
                cellWidth: 45,
              },
            },
          }}
        >
          <RangePicker
            picker="date"
            style={{
              border: "none",
              width: "0",
              height: "0",
              position: "absolute",
              padding: "0",
            }}
            showNow={false}
            suffixIcon={<></>}
            format="MMMMYYYY"
            open={visibleBookingDetailsCalendar}
            value={[dayjs(selectedCheckInDate), dayjs(selectedCheckOutDate)]}
            renderExtraFooter={() =>
              CustomFooter({
                selectedCheckInDate,
                setSelectedCheckInDate,
                selectedCheckOutDate,
                setSelectedCheckOutDate,
              })
            }
            separator={false}
            onChange={onSelectDate}
            placement="bottomRight"
            allowClear={false}
          />
        </ConfigProvider>
      </div>

      <div className="flex items-center  relative  pl-3">
        <div className="flex">
          <h1 className="flex  whitespace-nowrap absolute top-[-20px] left-2 px-[5px] bg-[#FFF]">
            <span className=" text-[0.625rem] font-[400] text-[#808080] font-nunito ">
              <TranslateText textKey={"check-in"} namespace="booking" />
              <span className="text-[blue]  text-xs">*</span>
            </span>
          </h1>

          <div className="  ">
            <div className="flex ">
              <span className="text-[#4D4D4D] font-[Poppins] text-[0.75rem] font-[400] leading-[1.125rem] ">
                {selectedCheckInDateTime
                  ? moment(selectedCheckInDateTime).format("D")
                  : moment().format("D")}
              </span>
              <span className="ml-[2px] text-[#4D4D4D] font-[Poppins] text-[0.75rem] font-[400] leading-[1.125rem]">
                {selectedCheckInDateTime
                  ? moment(selectedCheckInDateTime).format("MMM")
                  : moment().format("MMM")}
              </span>
              <span className=" ml-[2px] text-[#4D4D4D] font-[Poppins] text-[0.75rem] font-[400] leading-[1.125rem]">
                {selectedCheckInDateTime
                  ? moment(selectedCheckInDateTime).format("YYYY")
                  : moment().format("YYYY")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className=" text-[#00000080] flex justify-center items-center h-[1rem] w-[2rem] bg-[#F1F1F1]
       rounded-[0.1875rem] font-[400] text-[0.625rem]"
      >
        {calculateNumberOfNights(
          selectedCheckInDateTime,
          selectedCheckOutDateTime,
          hotelDetails
        ) || 1}
        N
      </div>

      <div className="flex items-center cursor-pointer relative  ">
        <div className="flex">
          <h1 className="flex  absolute top-[-20px] left-1 px-[5px] bg-[#FFF]">
            <span className="whitespace-nowrap  text-[0.625rem] font-[400] text-[#808080] font-nunito  ">
              <TranslateText textKey={"check-out"} namespace="booking" />
              <span className="text-[blue]  text-xs">*</span>
            </span>
          </h1>

          <div className="font-Poppins  text-[#000000B2] font-normal text-sm pr-2">
            <div className="flex ">
              <span className="text-[#4D4D4D] font-[Poppins] text-[0.75rem] font-[400] leading-[1.125rem]">
                {selectedCheckOutDateTime
                  ? moment(selectedCheckOutDateTime).add("days").format("D")
                  : moment().add(1, "days").format("D")}
              </span>
              <span className="ml-[3px] text-[#4D4D4D] font-[Poppins] text-[0.75rem] font-[400] leading-[1.125rem]">
                {selectedCheckOutDateTime
                  ? moment(selectedCheckOutDateTime).format("MMM")
                  : moment().format("MMM")}
              </span>
              <span className=" ml-[3px] text-[#4D4D4D] font-[Poppins] text-[0.75rem] font-[400] leading-[1.125rem]">
                {selectedCheckOutDateTime
                  ? moment(selectedCheckOutDateTime).format("YYYY")
                  : moment().format("YYYY")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-2 items-center">
        <div className="h-[1.375rem] w-[0.1px] bg-[#000]" />

        <div
          style={boxStyle}
          className="bg-[#FFF] rounded-[0.25rem] py-[0.2rem] px-[0.5rem] text-[0.625rem] font-[Poppins] 
        font-[400] text-[#808080]   border-[0.5px] border-[#CCCCCC]  "
        >
          {selectedCheckInDateTime
            ? moment(selectedCheckInDateTime).format("H")
            : moment().set("hour", CheckInTime).set("minute", 0).format("H")}
        </div>
        <div className="text-[#808080] font-[Poppins] text-[0.625rem] font-[500]">
          :
        </div>

        <div
          style={boxStyle}
          className="bg-[#FFF] rounded-[0.25rem] py-[0.2rem] px-[0.5rem] border-[0.5px] border-[#CCCCCC] text-[0.625rem] font-[Poppins] 
        font-[400] text-[#808080]  "
        >
          {selectedCheckInDateTime
            ? moment(selectedCheckInDateTime).format("m")
            : moment().set("hour", CheckInTime).set("minute", 0).format("m")}
        </div>
        <div className="text-[#808080] font-[Poppins] text-[0.5rem] font-[300]">
          <TranslateText textKey={"to"} namespace="booking" />
        </div>
        <div
          style={boxStyle}
          className="bg-[#FFF] rounded-[0.25rem] py-[0.2rem] px-[0.5rem] border-[0.5px] border-[#CCCCCC] text-[0.625rem] font-[Poppins] 
        font-[400] text-[#808080] "
        >
          {selectedCheckOutDateTime
            ? moment(selectedCheckOutDateTime).format("H")
            : moment().set("hour", CheckOutTime).set("minute", 0).format("H")}
        </div>
        <div className="text-[#808080] font-[Poppins] text-[0.625rem] font-[500]">
          :
        </div>
        <div
          style={boxStyle}
          className="bg-[#FFF] rounded-[0.25rem] py-[0.2rem] px-[0.5rem] border-[0.5px] border-[#CCCCCC] text-[0.625rem] font-[Poppins] 
        font-[400] text-[#808080] "
        >
          {selectedCheckOutDateTime
            ? moment(selectedCheckOutDateTime).format("m")
            : moment().set("hour", CheckOutTime).set("minute", 0).format("m")}
        </div>
      </div>
    </div>
  );
};

export default CustomCalendar;
