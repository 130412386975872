import React from "react";

const ShimmerLoader = ({ className }) => {
  return (
    <div className={className}>
      <div className="shimmer-container">
        <div className="shimmer" />
      </div>
    </div>
  );
};

export default ShimmerLoader;
