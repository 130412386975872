import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import { Box, Autocomplete, CircularProgress } from "@mui/material";

import {
  updateShowAttachToPartyDrawer,
  updateAttachPartyString,
  updateAttachPartyValue,
} from "../../../reducer/bookingReducer";
import { getPartyList } from "../../../reducer/partyReducer";
import { updateAddPartyDropdownVisible } from "../../../reducer/expenseReducer";
import AddParty from "../components/AddParty";

import CloseIcon from "../../../assets/images/svgs/CloseIcon.svg";
import useAllBookingFunction from "../../../hooks/bookingCustomHook/useAllBookingFunction";

const AttachToPartyDrawer = ({ width = "800px" }) => {
  const [partyNames, setPartyNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [balanceDue, setBalanceDue] = useState("");

  const { response } = useSelector((store) => store.booking);

  const { hotelDetails } = useSelector((store) => store.login);
  const {
    showAttachToPartyDrawer,
    attachPartyString,
    attachPartyValue,
    invoiceDetails,
    paymentDetails,
  } = useSelector((store) => store.booking);
  const { partyResponse, partyLoading } = useSelector((store) => store.party);
  const { addPartyDropdownVisible } = useSelector((store) => store.expense);

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      hotelId: hotelDetails?.id,
      pageSize: 30,
      searchText: attachPartyString,
      infiniteScrollDisabled: true,
    };
    dispatch(getPartyList(payload));
  }, [attachPartyString]);

  useEffect(() => {
    const updatedPartyNames = [];
    partyResponse?.forEach((party) => {
      const { displayName } = party;
      if (Boolean(displayName)) {
        updatedPartyNames.push(displayName);
      }
    });
    setPartyNames(updatedPartyNames);
  }, [partyResponse]);

  useEffect(() => {
    if (Array.isArray(invoiceDetails) && Array.isArray(paymentDetails)) {
      const localPayemnts = paymentDetails
        ?.map((item) => item.amount)
        ?.filter((Obj) => Boolean(Obj))
        ?.filter((Obj) => Obj > 0)
        ?.reduce((total, num) => total + num, 0);

      const localRefund = paymentDetails
        ?.map((item) => item.amount)
        ?.filter((Obj) => Boolean(Obj))
        ?.filter((Obj) => Obj < 0)
        ?.reduce((total, num) => total + -num, 0);

      let totalAmount = invoiceDetails
        ?.map((item) => item.total)
        ?.filter((Obj) => Boolean(Obj))
        ?.reduce((total, num) => total + num, 0);

      let balanceDue = totalAmount - localPayemnts + localRefund;

      if (balanceDue >= 0) {
        let updatedBalanceDue = balanceDue;
        setBalanceDue(updatedBalanceDue);
      } else setBalanceDue(0);
    }
  }, [paymentDetails, invoiceDetails]);

  const { handleAttachToParty } = useAllBookingFunction();

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: width,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "absolute",
          marginLeft: "auto",
          right: 0,
        },
        "& .MuiPaper-root": {
          position: "absolute",
          marginLeft: "auto",
        },
      }}
      anchor={"bottom"}
      open={showAttachToPartyDrawer}
      onClose={() => {
        dispatch(updateShowAttachToPartyDrawer(false));
        dispatch(updateAttachPartyString(""));
        dispatch(updateAttachPartyValue(""));
      }}
    >
      <div className="w-full px-[10px] pt-[10px] relative">
        <div className="flex w-[100%] justify-between items-center px-3 py-3 mb-[10px]">
          <h2 className="font-[500] text-[1rem] text-[#4D4D4D] font-nunito">
            Attach to Party
          </h2>

          <div
            onClick={() => dispatch(updateShowAttachToPartyDrawer(false))}
            className="cursor-pointer"
          >
            <img src={CloseIcon} alt="" />
          </div>
        </div>

        <div className="mb-[80px] relative">
          {addPartyDropdownVisible && (
            <AddParty
              updateAttachPartyValue={updateAttachPartyValue}
              partyType="source"
              inBookingDetails={true}
            />
          )}
          <Autocomplete
            id="party-select"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                padding: "9px !important",
                borderRadius: "10px",
              },

              "& label": {
                color: "#0000004D",
                fontFamily: "Poppins",
                fontSize: "15px",
              },
              "& input": {
                fontFamily: "Poppins",
                fontSize: "0.75rem",
                fontWeight: "400",
                color: "#4D4D4D",
              },
              "& .MuiInputLabel-root": {
                display: "flex",
                justifyContent: "center",
                fontWeight: 200,
                alignItems: "center",
                fontFamily: "nunito",
                color: "#808080",
                fontSize: "12px", // Font size when label is down
                lineHeight: "16.37px",
              },
              "& .MuiInputLabel-shrink": {
                fontSize: "0.93rem", // Font size when label is up (focused)
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "nunito",
              },
            }}
            options={
              partyNames?.length ? ["ADD PARTY", ...partyNames] : ["ADD PARTY"]
            }
            value={attachPartyValue}
            onChange={(event, newValue) => {
              if (newValue === "ADD PARTY") {
                dispatch(updateAddPartyDropdownVisible(true));
                return;
              }
              dispatch(updateAttachPartyValue(newValue));
            }}
            inputValue={attachPartyString}
            onInputChange={(event, newInputValue) => {
              dispatch(updateAttachPartyString(newInputValue));
            }}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  color: option === "ADD PARTY" ? "#007FFF" : "#000000B2",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  fontStyle: "Poppins",
                }}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <p>
                    Select Party <span className="text-[#007FFF]">*</span>
                  </p>
                }
                sx={{ borderRadius: 10 }}
                // style: inputStyle,
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                loading={partyLoading}
              />
            )}
          />
        </div>

        <button
          className="w-full h-[53px] flex justify-center items-center bg-[#007fff] absolute bottom-0 left-0"
          onClick={() =>
            handleAttachToParty(
              response,
              balanceDue,
              partyResponse,
              attachPartyValue,
              setLoading
            )
          }
          disabled={loading || partyLoading || !attachPartyValue}
        >
          {loading ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <span className="text-[#fff] font-[600] text-[18px] leading-[18px]">
              SAVE DETAILS
            </span>
          )}
        </button>
      </div>
    </Drawer>
  );
};

export default AttachToPartyDrawer;
