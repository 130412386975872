import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronDown } from "react-icons/fa";
import "react-international-phone/style.css";
import {
  updateDisplayAttachPartyDrawer,
  updateDisplayDiscountDrawer,
} from "../../../../reducer/posReducer/restaurant";
import Heading from "./Heading";
import Footer from "./Footer";
import CustomerTableDetails from "../orderDetails/CustomerTableDetails";
import Main from "./Main";
import { ITEMS_TOTAL_AMOUNT } from "../../../../utils/posHelper";
import { FaChevronUp } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { CURRENCY_FORMAT } from "../../../../utils/helper";
import { paymentModeTypes } from "../../../../utils/helper";
import { updateEditPaymentDrawer } from "../../../../reducer/paymentReducer";
import { ReactComponent as MobileUp } from "../../../../assets/icons/mobileUp.svg";
import { FaChevronRight } from "react-icons/fa";
import EditPaymentDrawer from "../../../../components/common/components/UpdatePaymentDrawer/EditPaymentDrawer";
import AttachRoomPartyDrawer from "../../../../components/pos/components/newOrderRevamped/AttachRoomPartyDrawer";
import AttachRoomParty from "./AttachToRoomParty";
import { GoTrash } from "react-icons/go";
import { Button, Modal } from "antd";
import { CircularProgress } from "@mui/material";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";

const MultipleKOTScreen = ({
  multipleKOTScreenVisible,
  setMultipleKOTScreenVsisible,
  setOrderDetailScreenVisible,
  createBill,
}) => {
  const dispatch = useDispatch();

  const { invoicePostData } = useSelector((store) => store.order);
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);

  const [showAttachToRoomParty, setShowAttachToRoomParty] = useState(false);
  const [paymentModesExpanded, setPaymentModesExpanded] = useState(false);
  const [deletePartyModal, setDeletePartyModal] = useState(false);

  const {
    status,
    paid,
    discount,
    subTotal,
    total,
    serviceCharge,
    priceBreakup,
  } = invoicePostData || {};

  const { allRooms } = useSelector((store) => store.pos);
  const { hotelDetails } = useSelector((store) => store.login);

  const { allPayments } = useSelector((store) => store.payments);

  const { editPaymentAllowed, deletePaymentAllowed } = useSelector(
    (store) => store.usersRestriction
  );
  let itemPrice = ITEMS_TOTAL_AMOUNT(priceBreakup);
  const { partyResponse } = useSelector((store) => store.party);

  let currentTaxObject = invoicePostData?.taxObjects ?? [];

  let updatedTaxObject = [];
  let taxMap = new Map();

  currentTaxObject?.forEach((Obj) => {
    const existingTax = taxMap.get(Obj?.taxName);
    if (existingTax) {
      existingTax.taxAmount += Obj?.taxAmount;
    } else {
      taxMap.set(Obj?.taxName, { ...Obj });
    }
  });

  currentTaxObject = currentTaxObject?.map((Obj) => Obj?.taxAmount);
  updatedTaxObject = Array.from(taxMap.values())?.filter((Obj) => Obj?.taxName);

  let finalTaxObject = updatedTaxObject?.map((Obj) => {
    return {
      title: `${Obj?.taxName} (${Obj?.taxPercentage}%)`,
      amount: Obj?.taxAmount,
    };
  });

  const paymentArray = [
    { title: "Amount", amount: Boolean(itemPrice) ? itemPrice : 0 },
    { title: "Discount", amount: Boolean(discount) ? discount : 0 },
    { title: "Sub Total", amount: Boolean(subTotal) ? subTotal : 0 },
    ...finalTaxObject,
    {
      title: "Extra Charge",
      amount: Boolean(serviceCharge) ? serviceCharge : 0,
    },
    { title: "Total", amount: Boolean(total) ? total : 0 },
    { title: "Paid", amount: Boolean(paid) ? paid : 0 },
  ];

  let partyName = Array.isArray(partyResponse)
    ? partyResponse
        ?.filter((Obj) => Obj?.id === invoicePostData?.partyId)
        ?.map((Obj) => Obj?.displayName)?.[0]
    : "";

  let roomName = Array.isArray(allRooms)
    ? allRooms
        ?.filter((Obj) => Obj?.id === invoicePostData?.roomId)
        ?.map((Obj) => Obj?.roomNum)?.[0]
    : "";

  const { deletePartyAndRoomFromPosInvoice } = useAllPosFunctions();

  return (
    <div className="w-[100%] overflow-y-auto  bg-[#F2F4FC] h-[100%]">
      <Heading
        setMultipleKOTScreenVsisible={setMultipleKOTScreenVsisible}
        setOrderDetailScreenVisible={setOrderDetailScreenVisible}
      />

      <div className=" h-[100vh]  overflow-y-auto pb-[5rem]">
        <Main />

        {status !== "billed" && status !== "paid" && status !== "due" && (
          <CustomerTableDetails showTableDetails={false} mb={"1rem"} />
        )}

        {(status === "billed" || status === "paid" || status === "due") && (
          <section
            className={` ${
              status !== "billed" &&
              Boolean(invoicePostData?.roomId || invoicePostData?.partyId)
                ? " pb-[4rem]"
                : ""
            } w-[95%] mx-auto rounded-[12px] 
              mt-3 bg-[#FFF] p-3 relative`}
          >
            <div className="flex flex-col gap-2  my-2 pr-2  ">
              {paymentArray?.map((Obj, index) => {
                return (
                  <div className="flex w-[100%] justify-between items-center">
                    <div className="font-outfit font-[400] text-[14px] leading-[21px] text-[#8F9BB3]">
                      {Obj?.title}
                    </div>
                    <div
                      className={` ${
                        index === 1 ? "text-[#60B707]" : "text-[#000]"
                      } font-outfit font-[400] text-[14px] leading-[21px] `}
                    >
                      {Boolean(Obj?.amount)
                        ? `${"\u20B9"}  ${CURRENCY_FORMAT(Obj?.amount)}`
                        : "00"}
                    </div>
                  </div>
                );
              })}
            </div>

            {status === "billed" ? (
              <div
                className="w-[100%] border-[1px] border-dashed border-[#AEAEAE] flex justify-center items-center 
            rounded-[12px] p-2 my-2"
              >
                <div
                  className="font-outfit font-[500] text-[14px] leading-[20px] text-[#007FFF] border-0"
                  onClick={() => dispatch(updateDisplayDiscountDrawer(true))}
                >
                  {Boolean(discount) ? "Edit Discount " : "Apply Discount"}
                </div>{" "}
              </div>
            ) : (
              <></>
            )}

            <div className="flex w-[100%] justify-between items-center">
              <div className="font-outfit font-[400] text-[14px] leading-[21px] text-[#000]">
                Due
              </div>
              <div
                className="text-[#60B707]
              font-outfit font-[400] text-[14px] leading-[21px] "
              >
                {Boolean(paid)
                  ? CURRENCY_FORMAT(total - paid)
                  : CURRENCY_FORMAT(total)}
              </div>
            </div>

            {status !== "billed" &&
              Boolean(invoicePostData?.roomId || invoicePostData?.partyId) && (
                <div
                  className="absolute left-0 bottom-0  bg-[#E5F3F3] rounded-b-[12px] 
                p-2 px-4 w-[100%] mx-auto flex justify-between items-center gap-5"
                >
                  <div className="flex items-center gap-1">
                    <FaLink className="text-[#119199] text-[26px]" />
                    <div className="text-[#119199] font-outfit font-[500] text-[16px] leading-[20.16px]  cursor-pointer flex justify-center items-center">
                      {Boolean(invoicePostData?.roomId)
                        ? ` Bill attached to Room ${roomName} `
                        : Boolean(invoicePostData?.partyId)
                        ? `  Bill attached to ${
                            partyName ? partyName : ""
                          } (Party)`
                        : ""}
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <span
                      className="font-outfit font-[500] text-[12px] leading-[24px] text-[#007FFF] cursor-pointer"
                      onClick={() =>
                        dispatch(updateDisplayAttachPartyDrawer(true))
                      }
                    >
                      EDIT
                    </span>

                    {deletePaymentAllowed && (
                      <GoTrash
                        className="cursor-pointer text-[#FE594E] h-[18px] w-[18px]"
                        onClick={() => setDeletePartyModal(true)}
                      />
                    )}
                  </div>
                </div>
              )}
          </section>
        )}

        {status === "billed" && (
          <>
            <div
              className={` ${
                showAttachToRoomParty
                  ? "rounded-t-[12px] border-b-0"
                  : "rounded-[12px]"
              } flex w-[95%]  justify-between items-center mx-auto  px-3 p-2  mt-[1rem] 
              border-[1.5px] bg-[#E9F4FF] border-[#007FFF]`}
              onClick={() => setShowAttachToRoomParty(!showAttachToRoomParty)}
            >
              <div className="font-outfit font-[400] text-[16px] leading-[24px] text-[#8F9BB3]">
                {" "}
                Attach to Room/Party
              </div>
              {showAttachToRoomParty ? (
                <FaChevronUp className="text-[#007FFF]" />
              ) : (
                <FaChevronDown className="text-[#007FFF]" />
              )}
            </div>

            {showAttachToRoomParty && <AttachRoomParty />}
          </>
        )}

        {(status === "billed" || status === "paid" || status === "due") &&
          allPayments?.filter((payment) => payment.method !== "btc").length >
            0 && (
            <div
              className="mt-[10px] bg-[#FFF] cursor-pointer w-[95%] mx-auto 
      rounded-[12px] p-2 px-3"
            >
              <div
                className="w-full flex justify-between items-center"
                onClick={() => setPaymentModesExpanded(!paymentModesExpanded)}
              >
                <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                  All Payments
                </span>
                {paymentModesExpanded ? <MobileUp /> : <FaChevronRight />}
              </div>

              {paymentModesExpanded && (
                <div className="mt-[12px] flex flex-col gap-[8px]">
                  {allPayments
                    ?.filter((payment) => payment.method !== "btc")
                    .map((payment) => {
                      const { id, amount, method, transactionNo } = payment;
                      return (
                        <div
                          key={id}
                          className="flex w-full justify-between items-center px-[12px] h-[40px] rounded-[8px]
                           border-[1px] border-[#EDF1F7]"
                        >
                          <div className="flex items-center gap-[6px] w-full">
                            <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                              {paymentModeTypes?.[method]}
                            </span>
                            {transactionNo && (
                              <div className="font-outfit font-[500] text-[10px] text-[#222222]">
                                ({transactionNo?.slice(-25)})
                              </div>
                            )}

                            {editPaymentAllowed && (
                              <span
                                onClick={(e) => {
                                  dispatch(
                                    updateEditPaymentDrawer({
                                      display: true,
                                      paymentObj: payment,
                                    })
                                  );
                                  e.stopPropagation();
                                }}
                                className="font-outfit font-[500] text-[12px] leading-[24px] text-[#007FFF] cursor-pointer"
                              >
                                (Edit)
                              </span>
                            )}
                          </div>
                          <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                            {CURRENCY_FORMAT(amount)}
                          </span>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          )}
      </div>

      <Footer
        setMultipleKOTScreenVsisible={setMultipleKOTScreenVsisible}
        createBill={createBill}
      />

      <EditPaymentDrawer
        isBookingListDrawer={false}
        drawerWidth="100%"
        isPosComponent={true}
      />

      <AttachRoomPartyDrawer
        total={total}
        invoice={invoicePostData}
        tableOrderDetails={tableOrderDetails}
        hotelId={hotelDetails?.id}
        customWidth={true}
      />

      <Modal
        open={deletePartyModal}
        centered
        title=""
        width={"auto"}
        styles={{ mask: { backdropFilter: "blur(2px)" } }}
        onCancel={() => setDeletePartyModal(false)}
        closable={false} // Hides the close (cancel) icon
        footer={
          <div className="w-full flex gap-2 justify-center mt-5">
            <Button
              className="text-[1.1rem]  w-[11rem] h-[3rem] border-[#D0D5DD] text-[#808080] rounded-[0.5rem] font-[Poppins] font-[600] "
              onClick={() => setDeletePartyModal(false)}
            >
              NO
            </Button>
            <Button
              className="text-[#FFF] text-[1.1rem] rounded-xl  bg-[#007FFF] w-[11rem] h-[3rem]"
              onClick={() =>
                deletePartyAndRoomFromPosInvoice(
                  invoicePostData,
                  allPayments,
                  setDeletePartyModal
                )
              }
            >
              {false ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <div>YES</div>
              )}
            </Button>
          </div>
        }
      >
        <div className="text-[#222B45] font-outfit font-[500] text-[18px]  ">
          Are you sure you want to remove Party/Booking from this Invoice?
        </div>
      </Modal>
    </div>
  );
};

export default MultipleKOTScreen;
