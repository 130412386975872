import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CountNoOfRooms from "./CountNoOfRooms";
import { TranslateText } from "../../../../utils/translation";
import { CircularProgress } from "@mui/material";

import { updateRoomPriceStructure } from "../../../../reducer/bookingReducer";
import { Input } from "antd";
import useRoomDrawerLogic from "../../../../hooks/bookingCustomHook/useRoomDrawerLogic";
import { getCurrentCurrency } from "../../../../utils/customCurrencyHelper";

const EditRoomDetails = ({
  isInitialRender,
  setAdults,
  setChildrens,
  finalLoading,
  adults,
  setOriginalRoomPriceStructureState,
}) => {
  const { roomPriceStructure, selectedRoomWTypeAndNum } = useSelector(
    (store) => store.booking
  );
  const { hotelDetails } = useSelector((store) => store.login);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  const handleRoomRateChange = (roomType, newRate) => {
    const existingData = roomPriceStructure;

    const updatedData = existingData?.map((obj) => {
      if (obj.type === roomType) return { ...obj, rate: newRate };

      return { ...obj };
    });

    dispatch(updateRoomPriceStructure(updatedData));
  };

  const RoomDetails_TABLE_HEAD = [
    <TranslateText textKey={"room_type"} namespace="booking" />,
    <TranslateText textKey={"availability"} namespace="booking" />,
    <TranslateText textKey={"Room Rate"} namespace="booking" />,
    <TranslateText textKey={"quantity"} namespace="booking" />,
  ];

  const { symbol } = getCurrentCurrency(hotelDetails?.currency);
  useRoomDrawerLogic(
    initialRender,
    setLoading,
    setAdults,
    setChildrens,
    setInitialRender,
    setOriginalRoomPriceStructureState
  );

  return (
    <div className="flex flex-col pb-[7rem]">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 rounded-lg  ">
        <div className="inline-block min-w-full pys-2 sm:px-6 lg:px-8 rounded-lg">
          {finalLoading || loading ? (
            <div className="w-[100%] flex justify-center items-center">
              <CircularProgress size={32} />
            </div>
          ) : (
            <table className="overflow-hidden min-w-full text-center text-sm font-light dark:border-neutral-500 rounded-lg">
              <thead
                className="border-b text-[#0.8125rem] font-[500]  bg-[#FFFFFF] rounded-lg "
                align="left"
              >
                <tr>
                  {RoomDetails_TABLE_HEAD?.map((head, index) => {
                    return (
                      <td
                        className="px-6 py-4  text-[#222B45] 
                    text-[18px] leading-[22.68px]   font-outfit font-[500]"
                        key={index}
                      >
                        {head}
                      </td>
                    );
                  })}
                </tr>
              </thead>

              <tbody
                className="rounded-b-lg divide-y  divide-gray-300 "
                align="left"
              >
                {roomPriceStructure?.map((roomData, index) => {
                  return (
                    <tr className=" rounded-b-xl bg-[#FFF] " key={index}>
                      <td className="whitespace-nowrap  px-6  py-4 font-medium dark:border-neutral-500 ">
                        <span
                          className="cursor-pointer   text-[#222B45] font-outfit font-[400] 
                        text-[16px] leading-[20.16px] "
                        >
                          {roomData.type}
                        </span>
                      </td>

                      <td
                        className="whitespace-nowrap   px-6 py-4 dark:border-neutral-500  cursor-pointer   text-[#222B45] font-outfit font-[400] 
                        text-[16px] leading-[20.16px] "
                      >
                        {roomData.availability -
                          (Boolean(
                            selectedRoomWTypeAndNum[roomData.type]?.length
                          )
                            ? selectedRoomWTypeAndNum[roomData.type]?.length
                            : 0)}
                      </td>

                      <td className="text-left">
                        <div className="relative">
                          <Input
                            type="number"
                            style={{
                              width: "10ch",
                            }}
                            className="focus:outline-none whitespace-nowrap max-w-[auto] pl-5 py-1 border rounded-md border-blue-400 flex    text-[#222B45] font-outfit font-[400]   text-[16px] leading-[20.16px] "
                            value={Number(roomData?.rate)}
                            onChange={(e) =>
                              handleRoomRateChange(
                                roomData.type,
                                +e.target.value
                              )
                            }
                          />
                          {/* <span className="absolute top-[6px] left-1">
                            {symbol}
                          </span> */}
                        </div>
                      </td>

                      <td className="px-4">
                        <CountNoOfRooms
                          roomData={roomData}
                          adults={adults}
                          setAdults={setAdults}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditRoomDetails;
