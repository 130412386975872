import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

import {
  getChannelsListService,
  postTestConnectionService,
  putChannelService,
  postChannelService,
  deleteChannelService,
  getChannelManagerListService,
  getChannelMappingService,
  postFirstTimeMappingService,
  getIFrameTokenService,
} from "../services/channelManagerSettings";

export const getHotelChannelsList = createAsyncThunk(
  "channelsList",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getChannelsListService(
        payload,
        `/cm/${payload}/channels`
      );
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error);
    }
  }
);

export const getChannelManagerList = createAsyncThunk(
  "channelManagerList",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getChannelManagerListService(
        `/cm/${payload}/channels/list`
      );
      return fulfillWithValue(response?.data);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error);
    }
  }
);

export const getChannelMapping = createAsyncThunk(
  "channelMapping",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getChannelMappingService(
        payload,
        `/cm/channels/${payload}`
      );
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
    }
  }
);

export const addChannel = createAsyncThunk(
  "addChannel",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postChannelService(payload, "/cm/channels");
      dispatch(updateSelectedChannel(response.channelDetails));
      dispatch(updateSelectedSettings("mapping"));
      dispatch(getChannelMapping(response.channelDetails.id));
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error);
    }
  }
);

export const deleteChannel = createAsyncThunk(
  "deleteChannel",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await deleteChannelService(
        `/cm/channels/${payload?.channelId}`
      );
      dispatch(updateSelectedChannel({}));
      dispatch(updateShowGeneralAndMappingDrawer(false));
      return fulfillWithValue({
        channelId: payload?.channelId,
        list: payload?.list,
      });
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error);
    }
  }
);

export const updateChannel = createAsyncThunk(
  "updateChannel",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await putChannelService(
        payload.payloadObject,
        `/cm/channels/${payload.payloadObject.id}`
      );
      dispatch(updateShowGeneralAndMappingDrawer(false));
      dispatch(updateSelectedChannel({}));
      dispatch(updateSelectedSettings("general"));

      return fulfillWithValue({ responseObject: response, list: payload.list });
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error);
    }
  }
);

export const firstTimeMapping = createAsyncThunk(
  "firstTimeMapping",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postFirstTimeMappingService(
        payload.payloadObject,
        `/cm/${payload.payloadId}/mappingDetails`
      );
      dispatch(updateSelectedChannel({}));
      // dispatch(updateShowGeneralAndMappingDrawer(false));
      return fulfillWithValue({ responseObject: response, list: payload.list });
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error);
    }
  }
);

export const testConnection = createAsyncThunk(
  "testConnection",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postTestConnectionService(
        payload,
        "/cm/testConnection"
      );
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error);
    }
  }
);

export const getiFrameToken = createAsyncThunk(
  "getiFrameToken",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getIFrameTokenService(payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  //hotel channels list
  channelsListLoading: false,
  channelsListResponse: [],
  channelsListError: null,

  //available channels list
  channels: [],

  //update channel
  updateChannelLoading: false,
  updateChannelError: null,

  //delete channel
  deleteChannelLoading: false,
  deleteChannelError: null,

  selectedChannel: {},
  showGeneralAndMappingDrawer: false,

  selectedSettings: "general",

  //channel mapping
  channelMappingResponse: {},
  channelMappingLoading: false,
  channelMappingError: null,

  roomTypeDropdownVisible: -1,
  planTypeDropdownVisible: {
    roomId: "",
    planId: "",
  },

  //iFrame token
  iFrameToken: "",
  iFrameTokenLoading: false,
  iFrameTokenError: null,
};

const channelManagerSettingsSlice = createSlice({
  name: "channelManagerSettings",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_15: () => initialState,

    updateSelectedChannel: (state, action) => {
      if (action.payload.hasOwnProperty("edit")) {
        delete action.payload["edit"];
        state.selectedChannel = action.payload;
        return;
      } else {
        state.selectedChannel = action.payload;
      }
    },

    updateShowGeneralAndMappingDrawer: (state, action) => {
      state.showGeneralAndMappingDrawer = action.payload;
    },

    updateSelectedSettings: (state, action) => {
      state.selectedSettings = action.payload;
    },
    updateRoomTypeDropdownVisible: (state, action) => {
      state.roomTypeDropdownVisible = action.payload;
    },
    updatePlanTypeDropdownVisible: (state, action) => {
      state.planTypeDropdownVisible = action.payload;
    },

    resetCmSettingsState: (state, action) => {
      state.channelsListResponse = [];
      state.selectedChannel = {};
      state.showGeneralAndMappingDrawer = false;
      state.selectedSettings = "general";
      state.channelMappingResponse = {};
      state.iFrameToken = "";
      state.iFrameTokenLoading = false;
      state.iFrameTokenError = null;
    },

    resetMapping: (state, action) => {
      state.channelMappingResponse = {};
      state.roomTypeDropdownVisible = -1;
      state.planTypeDropdownVisible = {
        roomId: "",
        planId: "",
      };
    },
  },
  extraReducers: (builder) => {
    //get hotel channels
    builder.addCase(getHotelChannelsList.pending, (state, action) => {
      state.channelsListLoading = true;
      state.channelsListError = false;
    });
    builder.addCase(getHotelChannelsList.fulfilled, (state, action) => {
      state.channelsListLoading = false;
      state.channelsListResponse = action.payload;
      state.channelsListError = false;
    });
    builder.addCase(getHotelChannelsList.rejected, (state, action) => {
      state.channelsListLoading = false;
      state.channelsListError = true;
    });

    //get channel mapping
    builder.addCase(getChannelMapping.pending, (state, action) => {
      state.channelMappingLoading = true;
      state.channelMappingError = false;
    });
    builder.addCase(getChannelMapping.fulfilled, (state, action) => {
      state.channelMappingLoading = false;
      state.channelMappingResponse = action.payload;
      state.channelMappingError = false;
    });
    builder.addCase(getChannelMapping.rejected, (state, action) => {
      state.channelMappingLoading = false;
      state.channelMappingError = true;
    });

    //get available channels
    builder.addCase(getChannelManagerList.fulfilled, (state, action) => {
      state.channels = action.payload.data;
    });

    //update channel
    builder.addCase(updateChannel.pending, (state, action) => {
      state.updateChannelLoading = true;
      state.updateChannelError = false;
    });
    builder.addCase(updateChannel.fulfilled, (state, action) => {
      message.success("Channel updated");
      state.channelsListResponse = action.payload.list.map((channel) =>
        channel.id === action.payload.responseObject.channel.id
          ? action.payload.responseObject.channel
          : channel
      );
      state.updateChannelLoading = false;
      state.updateChannelError = false;
    });
    builder.addCase(updateChannel.rejected, (state, action) => {
      state.updateChannelLoading = false;
      state.updateChannelError = true;
    });

    //delete channel
    builder.addCase(deleteChannel.pending, (state, action) => {
      state.deleteChannelLoading = true;
      state.deleteChannelError = false;
    });
    builder.addCase(deleteChannel.fulfilled, (state, action) => {
      message.success("Channel deleted");
      state.channelsListResponse = action.payload.list.filter(
        (channel) => channel.id !== action.payload.channelId
      );
      state.deleteChannelLoading = false;
      state.deleteChannelError = false;
    });
    builder.addCase(deleteChannel.rejected, (state, action) => {
      state.deleteChannelLoading = false;
      state.deleteChannelError = true;
    });

    //add channel
    builder.addCase(addChannel.pending, (state, action) => {
      state.updateChannelLoading = true;
      state.updateChannelError = false;
    });
    builder.addCase(addChannel.fulfilled, (state, action) => {
      message.success("Channel added successfully");
      state.channelsListResponse = [
        ...state.channelsListResponse,
        action.payload.channelDetails,
      ];
      state.updateChannelLoading = false;
      state.updateChannelError = false;
    });
    builder.addCase(addChannel.rejected, (state, action) => {
      state.updateChannelLoading = false;
      state.updateChannelError = true;
    });

    // iFrame Token
    builder.addCase(getiFrameToken.pending, (state, action) => {
      state.iFrameTokenLoading = true;
    });
    builder.addCase(getiFrameToken.fulfilled, (state, action) => {
      state.iFrameTokenLoading = false;
      state.iFrameToken = action.payload?.data?.data?.token;
    });
    builder.addCase(getiFrameToken.rejected, (state, action) => {
      state.iFrameTokenLoading = false;
      state.iFrameTokenError = action.payload;
    });
  },
});

export default channelManagerSettingsSlice.reducer;
export const {
  CLEAR_REDUX_STORE_15,
  updateSelectedChannel,
  updateShowGeneralAndMappingDrawer,
  updateSelectedSettings,
  resetCmSettingsState,
  updateRoomTypeDropdownVisible,
  updatePlanTypeDropdownVisible,
  resetMapping,
} = channelManagerSettingsSlice.actions;
