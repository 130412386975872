import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { TranslateText } from "../../../../utils/translation";
import {
  updateSelectedRoomWTypeAndNum,
  updateShowRoomNumberModal,
} from "../../../../reducer/bookingReducer";

import { Button, message } from "antd";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";

const CountNoOfRooms = ({ roomData, adults, setAdults }) => {
  const dispatch = useDispatch();

  const { selectedRoomWTypeAndNum } = useSelector((store) => store.booking);

  const value = Boolean(selectedRoomWTypeAndNum[roomData.type])
    ? selectedRoomWTypeAndNum[roomData.type]?.length
    : 0;

  const handleCurrentValue = (currentVal, type) => {
    if (currentVal > roomData.availability) {
      message.destroy();
      message.info("No more rooms available for this room type");
      return;
    }

    let localAdults = adults;

    if (!Object?.keys(selectedRoomWTypeAndNum)?.length) {
      localAdults = 0;
      setAdults(0);
    }

    if (type === "inc") {
      let currentAdults = localAdults + roomData?.defaultOccupancy;
      currentAdults = Boolean(currentAdults) ? currentAdults : adults;
      setAdults(currentAdults);
    } else {
      let currentAdults = localAdults - roomData?.defaultOccupancy;
      currentAdults = Boolean(currentAdults) ? currentAdults : 0;
      setAdults(currentAdults ?? 0);
    }

    let selectedRoom = [];

    for (let i = 0; i < currentVal; i++)
      selectedRoom.push(roomData?.rooms?.[i]);

    dispatch(
      updateSelectedRoomWTypeAndNum({
        selectedRoomType: roomData?.type,
        selectedRoomNumber: selectedRoom,
      })
    );
  };

  return (
    <>
      {" "}
      {Boolean(value) ? (
        <div className="flex flex-col justify-center  items-center  h-[4rem] w-[7rem]">
          <div className="  gap-2  py-[2px] px-[4px] rounded-[6px] flex justify-center items-center bg-[#007FFF] ">
            <Button
              icon={<FaMinus className="text-[16px]" />}
              type="primary"
              className="border-0  text-[#FFF]    flex justify-center items-center  bg-[#2F80ED] 
              text-[20px] font-outfit"
              onClick={() => handleCurrentValue(value - 1, "dec")}
            />

            <div className="font-outfit font-[500] text-[#FFF] text-[14px]">
              {value}
            </div>

            <Button
              icon={<FaPlus className="text-[16px]" />}
              type="primary"
              className="border-0  text-[#FFF]    flex justify-center items-center  bg-[#2F80ED] 
              text-[20px] font-outfit"
              onClick={() => handleCurrentValue(value + 1, "inc")}
            />
          </div>
          <div
            className="font-[Poppins] text-[#808080] text-[0.625rem] font-[400] leading-[0.9375rem] cursor-pointer"
            onClick={() => dispatch(updateShowRoomNumberModal())}
          >
            <TranslateText textKey={"view_rooms"} namespace="booking" />
          </div>
        </div>
      ) : (
        <div className="w-[7rem] h-[4rem] flex justify-center items-center">
          <Button
            className="   rounded-md h-[2rem] w-[6rem]  
             font-outfit font-[500] text-[14px] border-[1px] border-[#007FFF] leading-[21px] text-[#007FFF] bg-[#007FFF0F] "
            onClick={() => handleCurrentValue(1, "inc")}
          >
            Select Room
          </Button>
        </div>
      )}
    </>
  );
};

export default CountNoOfRooms;
