import React, { useState, useEffect } from "react";
import { updateExpandedHotelDetailScreen } from "../../reducer/appHeaderReducer";
import { useDispatch } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";

import Service from "./Service";
import Settings from "./Setting";
import LogoutSymbol from "../../assets/images/svgs/LogoutSymbol.svg";
import { useNavigate } from "react-router-dom";

import useResetState from "../../components/useHook/useResetState";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetState } = useResetState();

  const handleLogOut = () => {
    resetState();
    window.localStorage.clear("hotelId");
    window.localStorage.clear("token");
    navigate("/");
  };

  return (
    <div className="w-[100%] h-[100%] bg-[#F2F4FC] ">
      <header className="fixed top-0 w-[100%] bg-[#FFF] flex  p-3 justify-between items-center  gap-2 shadow-md">
        <div className="flex  justify-start items-center flex-nowrap gap-3">
          <div onClick={() => dispatch(updateExpandedHotelDetailScreen(false))}>
            <GiHamburgerMenu className="text-[#000] text-[20px]" />
          </div>
          <div className="font-outfit font-[500] text-[18px] leading-[27px] text-[#222222] flex gap-2 items-center whitespace-nowrap">
            HMS Menu
          </div>
        </div>

        {/* <div
          className="w-[100%] flex justify-center my-2 items-center cursor-pointer"
          onClick={handleLogOut}
        >
          <img src={LogoutSymbol} alt="" />
        </div> */}
      </header>

      <div className="w-[100%] min-h-screen h-[100%] flex flex-col gap-5  pt-[5rem]">
        <Service />
        {/* <Settings /> */}
      </div>
    </div>
  );
};

export default Index;
