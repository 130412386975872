import { authedAxios } from "../utils/customHttpHeader";

const getAllAvailableRoles = async (payload) => {
  let queryString = `?hotelId=${payload.hotelId}`;

  if (payload?.searchText !== "") {
    queryString += `&searchText=${payload?.searchText}`;
  }
  if (payload?.permissions?.length > 0) {
    queryString += `&permissions=${payload?.permissions}`;
  }
  const response = await authedAxios().get("roles" + queryString);
  return response;
};
const getAllPossRoles = async (payload) => {
  const queryString = `?hotelId=${payload.hotelId}`;

  const response = await authedAxios().get("roles" + queryString);
  return response;
};

const getAllAvailableUser = async (payload) => {
  let queryString = `?hotelId=${payload.hotelId}`;

  if (payload?.searchText !== "") {
    queryString += `&searchText=${payload?.searchText}`;
  }
  if (payload?.roles?.length > 0) {
    queryString += `&roles=${payload?.roles}`;
  }

  const response = await authedAxios().get("user" + queryString);
  return response;
};

const getCurrentUser = async (payload) => {
  let queryString = `?hotelId=${payload.hotelId}`;

  queryString += `&searchText=${payload?.searchText}`;

  const response = await authedAxios().get("user" + queryString);
  return response;
};

const createRole = async (payload) => {
  const response = await authedAxios().post("roles", payload);
  return response;
};
const updateRole = async (payload) => {
  const response = await authedAxios().put(
    `roles/${payload?.path}`,
    payload?.body
  );
  return response;
};
const deleteRole = async (payload) => {
  const response = await authedAxios().delete(`roles/${payload}`);
  return response;
};

const createUser = async (payload) => {
  const response = await authedAxios().post("user", payload);
  return response;
};
const updateUser = async (payload) => {
  const response = await authedAxios().put(
    `user/${payload?.path}`,
    payload?.body
  );
  return response;
};
const deleteUser = async (payload) => {
  const response = await authedAxios().delete(`user/${payload}`);
  return response;
};

const loggedInUserPermissionsService = async (payload) => {
  const response = await authedAxios().get(
    `/user/permissions?hotelId=${payload?.hotelId}`
  );
  return response;
};

const loggedInUserHotelWisePermissionsService = async (payload) => {
  const response = await authedAxios().get(
    `/user?hotelIds=${payload?.hotelIds}&phone=${payload?.phone}`
  );
  return response;
};

export {
  getAllAvailableRoles,
  getAllAvailableUser,
  createRole,
  updateRole,
  deleteRole,
  createUser,
  updateUser,
  deleteUser,
  getAllPossRoles,
  loggedInUserPermissionsService,
  getCurrentUser,
  loggedInUserHotelWisePermissionsService,
};
