import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createRole,
  createUser,
  deleteRole,
  deleteUser,
  getAllAvailableRoles,
  getAllAvailableUser,
  getAllPossRoles,
  updateRole,
  updateUser,
} from "../services/usersRole";
import { message } from "antd";

const initialState = {
  loading: false, // before api call
  response: [], // store  room list json data into it
  apiError: false, // check api status
  title: "users",

  allRolesloading: false,
  allRoles: [],

  allPossibleRoles: [],

  allUsersLoading: false,
  allUsers: [],

  inactiveUsers: [],

  displayCreateUserDrawer: false,

  roleDrawer: {
    displayEditRoleDrawer: false,
    currentRole: null,
  },
  editUserDrawer: {
    displayEditUserDrawer: false,
    currentUser: null,
  },

  newRolesLoading: false,

  displayFilter: false,
  roleFilteredArray: [],

  searchText: "",
};

export const getAllRoles = createAsyncThunk(
  "getAllRoles",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getAllAvailableRoles(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const getAllPossibleRoles = createAsyncThunk(
  "getAllPossibleRoles",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getAllPossRoles(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const getAllUser = createAsyncThunk(
  "getAllUser",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getAllAvailableUser(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const createNewRole = createAsyncThunk(
  "createNewRole",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await createRole(payload);
      dispatch(
        updateDisplayEditRoleDrawer({
          displayEditRoleDrawer: false,
          currentRole: null,
        })
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);
export const updateExistingRole = createAsyncThunk(
  "updateExistingRole",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await updateRole(payload);

      let state = getState();
      let allrole = state?.usersRole?.allRoles;
      let updatedData = allrole?.map((Obj) => {
        if (Obj?.id === payload?.body?.id) {
          return response.data;
        } else return Obj;
      });

      dispatch(
        updateDisplayEditRoleDrawer({
          displayEditRoleDrawer: false,
          currentRole: null,
        })
      );

      return fulfillWithValue(updatedData);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);
export const deleteExistingRole = createAsyncThunk(
  "deleteExistingRole",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      await deleteRole(payload);

      let state = getState();

      let allrole = state?.usersRole?.allRoles;
      let updatedData = allrole?.filter((Obj) => Obj?.id !== payload);

      dispatch(
        updateDisplayEditRoleDrawer({
          displayEditRoleDrawer: false,
          currentRole: null,
        })
      );
      return fulfillWithValue(updatedData);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);
export const createNewUser = createAsyncThunk(
  "createNewUser",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await createUser(payload);
      dispatch(updateDisplayCreateUserDrawer(false));
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);
export const updateExistingUser = createAsyncThunk(
  "updateExistingUser",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await updateUser(payload);

      let state = getState();
      let alluser = state?.usersRole?.allUsers;
      let updatedData = alluser?.map((Obj) => {
        if (Obj?.id === payload?.body?.id) {
          return response.data;
        } else return Obj;
      });
      dispatch(
        updateDisplayEditUserDrawer({
          displayEditUserDrawer: false,
          currentUser: "",
        })
      );
      return fulfillWithValue(updatedData);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);
export const deleteExistingUser = createAsyncThunk(
  "deleteExistingUser",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      await deleteUser(payload);
      dispatch(
        updateDisplayEditUserDrawer({
          displayEditUserDrawer: false,
          currentUser: "",
        })
      );
      let state = getState();
      let title = state?.usersRole?.title;
      let inactiveUsers = state?.usersRole?.inactiveUsers;
      let alluser = state?.usersRole?.allUsers;
      let updatedData = [];

      if (title === "users") {
        updatedData = alluser?.filter((Obj) => Obj?.id !== payload);
      } else {
        updatedData = inactiveUsers?.filter((Obj) => Obj?.id !== payload);
      }
      let newData = {
        data: updatedData,
        title: title,
      };

      return fulfillWithValue(newData);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const usersRoleSlice = createSlice({
  name: "usersRole",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_45: () => initialState,

    updateTitle: (state, action) => {
      state.title = action.payload;
    },
    updateDisplayCreateUserDrawer: (state, action) => {
      state.displayCreateUserDrawer = action.payload;
    },
    updateDisplayEditUserDrawer: (state, acion) => {
      state.editUserDrawer.displayEditUserDrawer =
        acion.payload.displayEditUserDrawer;
      state.editUserDrawer.currentUser = acion.payload.currentUser;
    },

    updateDisplayEditRoleDrawer: (state, action) => {
      state.roleDrawer = action.payload;
    },

    updateDisplayFilter: (state, action) => {
      state.displayFilter = action.payload;
    },
    updateRoleFilteredArray: (state, action) => {
      state.roleFilteredArray = action.payload;
    },

    updateSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRoles.pending, (state, action) => {
      state.allRolesloading = true;
      state.allRoles = [];
      state.apiError = false;
    });
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.allRolesloading = false;
      state.allRoles = action.payload;
      state.apiError = false;
    });
    builder.addCase(getAllRoles.rejected, (state, action) => {
      state.allRolesloading = false;
      state.allRoles = [];
      state.apiError = true;
    });
    builder.addCase(getAllPossibleRoles.pending, (state, action) => {
      state.allPossibleRoles = [];
      state.apiError = false;
    });
    builder.addCase(getAllPossibleRoles.fulfilled, (state, action) => {
      state.allPossibleRoles = action.payload;
      state.apiError = false;
    });
    builder.addCase(getAllPossibleRoles.rejected, (state, action) => {
      state.allPossibleRoles = [];
      state.apiError = true;
    });
    builder.addCase(getAllUser.pending, (state, action) => {
      state.allUsersLoading = true;
      state.allUsers = [];
      state.apiError = false;
    });
    builder.addCase(getAllUser.fulfilled, (state, action) => {
      state.allUsersLoading = false;

      let activeUser = [];
      let inactiveUser = [];
      action?.payload?.map((Obj) => {
        if (Obj?.status === false) {
          inactiveUser.push(Obj);
        } else activeUser.push(Obj);
      });

      state.allUsers = activeUser;
      state.inactiveUsers = inactiveUser;
      state.apiError = false;
    });
    builder.addCase(getAllUser.rejected, (state, action) => {
      state.allUsersLoading = false;
      state.allUsers = [];
      state.apiError = true;
    });
    builder.addCase(createNewRole.pending, (state, action) => {
      state.newRolesLoading = true;
      state.apiError = false;
    });
    builder.addCase(createNewRole.fulfilled, (state, action) => {
      state.newRolesLoading = false;
      state.allRoles = [...state.allRoles, action.payload];
      state.apiError = false;
    });
    builder.addCase(createNewRole.rejected, (state, action) => {
      state.newRolesLoading = false;
      state.allRoles = [];
      state.apiError = true;
    });
    builder.addCase(updateExistingRole.pending, (state, action) => {
      state.newRolesLoading = true;
      state.apiError = false;
    });
    builder.addCase(updateExistingRole.fulfilled, (state, action) => {
      state.newRolesLoading = false;
      state.allRoles = action.payload;
      state.apiError = false;
    });
    builder.addCase(updateExistingRole.rejected, (state, action) => {
      state.newRolesLoading = false;
      state.allRoles = [];
      state.apiError = true;
    });
    builder.addCase(deleteExistingRole.pending, (state, action) => {
      state.newRolesLoading = true;
      state.apiError = false;
    });
    builder.addCase(deleteExistingRole.fulfilled, (state, action) => {
      state.newRolesLoading = false;
      state.allRoles = action.payload;
      state.apiError = false;
    });
    builder.addCase(deleteExistingRole.rejected, (state, action) => {
      state.newRolesLoading = false;
      state.allRoles = [];
      state.apiError = true;
    });
    builder.addCase(createNewUser.pending, (state, action) => {
      state.allUsersLoading = true;
      state.apiError = false;
    });
    builder.addCase(createNewUser.fulfilled, (state, action) => {
      state.allUsersLoading = false;
      state.allUsers = [...state.allUsers, action.payload];
      state.apiError = false;
    });
    builder.addCase(createNewUser.rejected, (state, action) => {
      state.allUsersLoading = false;
      state.allUsers = [];
      state.apiError = true;
    });
    builder.addCase(updateExistingUser.pending, (state, action) => {
      state.allUsersLoading = true;
      state.apiError = false;
    });
    builder.addCase(updateExistingUser.fulfilled, (state, action) => {
      state.allUsersLoading = false;
      state.allUsers = action.payload;
      state.apiError = false;
    });
    builder.addCase(updateExistingUser.rejected, (state, action) => {
      state.allUsersLoading = false;
      state.allUsers = [];
      state.apiError = true;
    });
    builder.addCase(deleteExistingUser.pending, (state, action) => {
      state.allUsersLoading = true;
      state.apiError = false;
    });
    builder.addCase(deleteExistingUser.fulfilled, (state, action) => {
      state.allUsersLoading = false;

      if (action.payload.title === "users")
        state.allUsers = action.payload.data;
      else state.inactiveUsers = action.payload.data;

      state.apiError = false;
    });
    builder.addCase(deleteExistingUser.rejected, (state, action) => {
      state.allUsersLoading = false;
      state.allUsers = [];
      state.apiError = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_45,
  updateTitle,
  updateDisplayCreateUserDrawer,
  updateDisplayEditUserDrawer,
  updateDisplayFilter,
  updateRoleFilteredArray,
  updateSearchText,
  updateDisplayEditRoleDrawer,
} = usersRoleSlice.actions;

export default usersRoleSlice.reducer;
