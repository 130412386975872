import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { allInvoices } from "../services/invoice";

const initialState = {
  loading: false, // before api call
  allInvoices: [], // store  room list json data into it
  apiError: false, // check api status

  pageNumber: 0,

  initialDate: "",
  finalDate: "",
  visibleCalendar: "",
  selectedInitialDate: "",
  selectedFinalDate: "",

  infinateScroll: false,

  selectedStateArray: [],

  selectedServiceArray: [],

  displayInvoiceDropDown: { display: false, invoiceArray: [] },

  displayGSTFilter: false,
  gstSelectedArray: [],

  displayServiceFilter: false,
  serviceSelectedArray: [],

  displayDueDateFilter: false,

  infinateScrollLoading: false,

  displayAddInvoiceDrawer: false,
  displayViewInvoiceDrawer: { display: false, obj: {} },
};

export const getAllInvoices = createAsyncThunk(
  "getAllInvoices",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const data = {
      hotelId: payload.hotelId,
      searchText: Boolean(payload.searchText) ? payload.searchText : "",
      pageNumber: payload.pageNumber,
      pageSize: payload.pageSize,
      sortingDirection: payload.sortingDirection,
      sortingField: payload.sortingField,
    };

    try {
      const response = await allInvoices({ ...payload, ...data });
      return fulfillWithValue({
        pageNumber: payload?.pageNumber,
        data: data?.searchText ? response?.data?.content : response?.data,
        totalPages: response?.data?.totalPages,
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error.message);
    }
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_28: () => initialState,

    updateInitialDate: (state, action) => {
      state.initialDate = action.payload;
    },
    updateFinalDate: (state, action) => {
      state.finalDate = action.payload;
    },
    updateSelectedInitialDate: (state, action) => {
      state.selectedInitialDate = action.payload;
    },
    updateSelectedFinalDate: (state, action) => {
      state.selectedFinalDate = action.payload;
    },
    updateVisibleCalendar: (state, action) => {
      state.visibleCalendar = action.payload;
    },
    updateSelectedStateArray: (state, action) => {
      state.selectedStateArray = action.payload;
    },
    updateDisplayInvoiceDropDown: (state, action) => {
      state.displayInvoiceDropDown = action.payload;
    },

    updateDisplayPartyFilter: (state, action) => {
      state.displayPartyFilter = action.payload;
    },
    updateSelectedPartyFilter: (state, action) => {
      state.selectedPartyFilter = action.payload;
    },
    updatePageNumber: (state, action) => {
      state.pageNumber = state.pageNumber + 1;
    },
    updateInFinateScrollLoading: (state, action) => {
      state.infinateScrollLoading = action.payload;
    },

    updateGSTFilter: (state, action) => {
      state.displayGSTFilter = action.payload;
    },
    updateSelectedGSTArray: (state, action) => {
      state.gstSelectedArray = action.payload;
    },
    updateServiceFilter: (state, action) => {
      state.displayServiceFilter = action.payload;
    },
    updateSelectedServiceArray: (state, action) => {
      state.serviceSelectedArray = action.payload;
    },
    updateDueDateFilter: (state, action) => {
      state.displayDueDateFilter = action.payload;
    },
    updateDisplayAddInvoiceDrawer: (state, action) => {
      state.displayAddInvoiceDrawer = action.payload;
    },
    updateDisplayViewInvoiceDrawer: (state, action) => {
      state.displayViewInvoiceDrawer = action.payload;
    },
    updateResetState: (state, action) => {
      state.loading = false;
      state.allInvoices = [];
      state.apiError = false;
      state.pageNumber = 0;

      state.initialDate = "";
      state.finalDate = "";
      state.visibleCalendar = "";
      state.selectedInitialDate = "";
      state.selectedFinalDate = "";

      state.infinateScroll = false;

      state.selectedStateArray = [];

      state.selectedServiceArray = [];

      state.displayInvoiceDropDown = { display: false, invoiceArray: [] };

      state.displayGSTFilter = false;
      state.gstSelectedArray = [];

      state.displayServiceFilter = false;
      state.serviceSelectedArray = [];

      state.displayDueDateFilter = false;

      state.infinateScrollLoading = false;

      state.displayAddInvoiceDrawer = false;
      state.displayViewInvoiceDrawer = { display: false, obj: {} };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInvoices.pending, (state, action) => {
      state.loading = state.infinateScroll ? false : true;
      state.apiError = false;
    });
    builder.addCase(getAllInvoices.fulfilled, (state, action) => {
      state.loading = false;
      state.infinateScrollLoading = false;
      state.infinateScroll = action.payload?.data?.length > 0 ? true : false;

      state.infinateScrollLoading =
        action.payload?.data?.length > 0 ? true : false;

      state.allInvoices = Boolean(action.payload?.pageNumber)
        ? [...state.allInvoices, ...(action.payload.data || [])]
        : action.payload.data || [];

      state.apiError = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_28,
  updateInitialDate,
  updateFinalDate,
  updateVisibleCalendar,
  updateSelectedInitialDate,
  updateSelectedFinalDate,
  updateSelectedStateArray,
  updateDisplayInvoiceDropDown,

  updatePageNumber,
  updateInFinateScrollLoading,
  updateGSTFilter,
  updateSelectedGSTArray,
  updateServiceFilter,
  updateSelectedServiceArray,
  updateDueDateFilter,
  updateDisplayAddInvoiceDrawer,

  updateDisplayViewInvoiceDrawer,
  updateResetState,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
