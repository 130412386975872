import {
  bookingDetailsAddOnsCalculation,
  invoicePriceBreakupCalculation,
} from "./bookingHelper";
import { FOLIO_FORMAT } from "./helper";

export const requiredRoomInvoiceBreakupFormat = (
  invoiceDetails,
  isCmIdPresent,
  nightsCount
) => {
  const roomInvoice = invoiceDetails?.find(
    (obj) => obj?.invoiceType === "roomBill"
  );

  if (isCmIdPresent) {
    if (!roomInvoice) return invoiceDetails;

    const updatedRoomInvoiceBreakup = roomInvoice?.priceBreakup?.map((Obj) => ({
      ...Obj,
      count: Obj.type === "Addons" ? Obj.count : Obj.count / (nightsCount || 1), // Ensure count is divided correctly, but skip "Addons"
    }));

    const updatedRoomInvoice = {
      ...roomInvoice,
      priceBreakup: updatedRoomInvoiceBreakup,
    };

    return invoiceDetails?.map((obj) =>
      obj?.invoiceType === "roomBill" ? updatedRoomInvoice : obj
    );
  }

  if (!roomInvoice) return invoiceDetails;

  const priceBreakup = roomInvoice?.priceBreakup ?? [];
  const roomMap = new Map();

  // Iterate through priceBreakup and aggregate data in the Map
  for (const { type, rate, taxIds, addOns, discountedRate } of priceBreakup) {
    if (type === "Addons") {
      roomMap.set(type, {
        type,
        rate,
        count: 1,
        discountedRate,
        taxIds,
        addOns,
      }); // Directly add "Addons" without alteration
    } else {
      if (roomMap.has(type)) {
        const existingRoom = roomMap.get(type);
        existingRoom.rate += rate;
        existingRoom.discountedRate += discountedRate;
        existingRoom.count += 1;
      } else
        roomMap.set(type, { type, rate, count: 1, taxIds, discountedRate });
    }
  }

  const updatedPriceBreakup = Array.from(roomMap.values()).map(
    ({ type, rate, count, taxIds, addOns, discountedRate }) => ({
      type,
      rate: type === "Addons" ? rate : rate / count,
      count,
      taxIds,
      addOns,
      discountedRate: discountedRate / count,
    })
  );

  const updatedRoomInvoice = {
    ...roomInvoice,
    priceBreakup: updatedPriceBreakup,
  };

  return invoiceDetails?.map((obj) =>
    obj?.invoiceType === "roomBill" ? updatedRoomInvoice : obj
  );
};

export const constructCombinedPriceBreakup = (
  invoiceArray,
  posData,
  nightsCount
) => {
  if (!Array.isArray(invoiceArray)) return [];

  let combinedPriceBreakup = [];

  invoiceArray?.forEach((mainObj) => {
    const {
      invoiceType,
      taxObjects: taxObjectArray,
      priceBreakup,
      taxIncludedInPrice,
    } = mainObj;

    if (!Array.isArray(priceBreakup)) return;

    priceBreakup.forEach((Obj) => {
      let obj = null;

      if (invoiceType === "roomBill")
        obj = invoiceRoomBillCalulation(
          Obj,
          taxObjectArray,
          taxIncludedInPrice,
          nightsCount
        );
      else
        obj = invoicePosBillCalculation(Obj, taxObjectArray, posData, mainObj);

      combinedPriceBreakup.push({ ...obj });
    });
  });

  return combinedPriceBreakup;
};

const invoiceRoomBillCalulation = (
  Obj,
  taxObjectArray,
  taxIncludedInPrice,
  nightsCount
) => {
  let taxRate;
  let totalRate;
  let baseRate;
  let obj;

  if (Obj?.type === "Addons") {
    let result = bookingDetailsAddOnsCalculation(Obj?.addOns, taxObjectArray);

    taxRate = FOLIO_FORMAT(result?.totalAddOnsTax);
    totalRate = FOLIO_FORMAT(result?.addOnsTotal);
    baseRate = FOLIO_FORMAT(result?.addOnsTotal - result?.totalAddOnsTax);

    let count = 0;
    Obj?.addOns
      ?.map((item) => item?.count)
      ?.filter(Boolean)
      ?.forEach((item) => {
        count += item;
      });

    obj = {
      discount: 0,
      description: Obj?.type,
      count: count,
      rate: Boolean(baseRate) ? FOLIO_FORMAT(baseRate) : "-",
      taxRate: Boolean(taxRate) ? FOLIO_FORMAT(taxRate * count) : "-",
      amount: Boolean(totalRate) ? FOLIO_FORMAT(totalRate * count) : "-",
      hsn: 996311,
      invoiceType: "roomBill",
      nightsCount: `-`,
    };
  } else {
    let result = invoicePriceBreakupCalculation(Obj, taxObjectArray);

    if (taxIncludedInPrice) {
      taxRate = FOLIO_FORMAT(result?.taxRate);
      totalRate = FOLIO_FORMAT(result?.totalRate);
      baseRate = FOLIO_FORMAT(result?.baseRate);
    } else {
      taxRate = FOLIO_FORMAT(result?.taxRate);
      totalRate = FOLIO_FORMAT(result?.totalRate);
      baseRate = FOLIO_FORMAT(result?.baseRate);
    }
    let currentDiscount =
      (+baseRate + +taxRate - +totalRate) * nightsCount * Obj?.count;

    obj = {
      discount: currentDiscount ? FOLIO_FORMAT(currentDiscount) : 0,
      description: Obj?.type,
      count: Obj?.count,
      rate: Boolean(baseRate) ? FOLIO_FORMAT(baseRate) : "-",
      taxRate: Boolean(taxRate)
        ? FOLIO_FORMAT(taxRate * nightsCount * Obj?.count)
        : "-",
      amount: Boolean(totalRate)
        ? FOLIO_FORMAT(totalRate * nightsCount * Obj?.count)
        : "-",
      hsn: 996311,
      invoiceType: "roomBill",
      nightsCount: `${nightsCount}`,
    };
  }

  return obj;
};

const invoicePosBillCalculation = (Obj, taxObjectArray, posData, mainObj) => {
  let result = invoicePriceBreakupCalculation(Obj, taxObjectArray);

  let taxRate = FOLIO_FORMAT(result?.taxRate);
  let totalRate = FOLIO_FORMAT(result?.totalRate);
  let baseRate = FOLIO_FORMAT(result?.baseRate);

  const posSacCode = posData?.find((Obj) => Obj?.id === mainObj?.posId)?.sac;
  let currentDiscount = (+baseRate + +taxRate - +totalRate) * Obj?.count;

  let obj = {
    description: Obj?.type,
    count: Obj?.count,
    rate: Boolean(baseRate) ? FOLIO_FORMAT(baseRate) : "-",
    taxRate: Boolean(taxRate) ? FOLIO_FORMAT(taxRate * Obj?.count) : "-",
    amount: Boolean(totalRate) ? FOLIO_FORMAT(totalRate * Obj?.count) : "-",
    hsn: Boolean(posSacCode) ? posSacCode : "-",
    invoiceType: "pos",
    discount: FOLIO_FORMAT(currentDiscount),
    nightsCount: `-`,
  };

  return obj;
};
