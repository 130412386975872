import { useState, useEffect } from "react";

function useScreen() {
  const [screen, setScreen] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let screenName = "";

      if (width <= 768) {
        screenName = "mobile";
      } else if (width <= 1024) {
        screenName = "tablet";
      } else {
        screenName = "desktop";
      }

      setScreen(screenName);
    };

    // Initial call
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean-up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screen;
}

export default useScreen;
