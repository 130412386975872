import React, { useEffect, useState } from "react";
import ManageStocksWrapper from "../wrapper/Index";
import { Button, Input, List, Radio, Space, message } from "antd";
import { NotSelectedRadio, SelectedRadio } from "../../../Icon1";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewUnit,
  deleteUnit,
} from "../../../../reducer/manageStocksReducer/unitsReducer";
import { useNavigate } from "react-router-dom";

const MainContainer = ({ unitsData, setUnitsData, dispatch }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const deleteHandler = async () => {
    let updatedUnits = {
      units: unitsData.filter((item) => item !== value),
    };
    const response = await dispatch(deleteUnit(updatedUnits));
    if (response.meta.requestStatus === "fulfilled") {
      message.success("Unit deleted successfully");
    } else {
      message.error("Error in deleting unit");
    }
    setUnitsData(unitsData.filter((item) => item !== value));
  };

  const handleSaveAndBack = () => {
    message.success("Units Saved!");
    navigate(-1);
  };

  return (
    <section className="px-4 flex flex-col gap-3 text-[14px] h-full">
      <Space className="mt-19 mb-3 text-[#4F4F4F] px-2">
        <p>All Units (Tap to select/unselect)</p>
      </Space>
      <Radio.Group onChange={onChange} value={value}>
        <List
          dataSource={unitsData}
          size="large"
          bordered={false}
          className="shadow-custom-stocks"
          pagination={{ position: "bottom", align: "center", pageSize: 9 }}
          renderItem={(item, index) =>
            item && (
              <List.Item
                key={item + index}
                className={`${""} mb-2 shadow-md bg-[#F4F4F4]`}
              >
                <div
                  className="flex items-center"
                  onClick={(e) => setValue(e.target.value)}
                >
                  {value !== item && <NotSelectedRadio />}
                  {value === item && <SelectedRadio />}
                  <Radio.Button
                    type="text"
                    className="active:bg-[#f4f4f4] bg-[#f4f4f4] border-none overflow-hidden"
                    value={item}
                  >
                    {item}
                  </Radio.Button>
                </div>
              </List.Item>
            )
          }
        />
      </Radio.Group>

      <div className="w-full flex justify-around py-4">
        <Button
          type="secondary"
          className="bg-[#FFFFFF] text-[18px] text-[#007FFF] font-semibold w-[310px] h-[56px] shadow-custom-stocks"
          onClick={() => deleteHandler()}
        >
          DELETE
        </Button>
        <Button
          type="primary"
          className="bg-[#007FFF] text-[18px] font-semibold w-[310px] h-[56px] drop-shadow-[9px_5px_10px_#c2c1c1]"
          onClick={handleSaveAndBack}
        >
          SAVE & BACK
        </Button>
      </div>
    </section>
  );
};

const SideContainer = ({ setUnitsData, unitsData, dispatch }) => {
  const [inputUnit, setInputUnit] = useState("");

  const addUnit = async () => {
    if (inputUnit !== "") {
      let updatedUnits = {
        units: [...unitsData, inputUnit],
      };

      const response = await dispatch(addNewUnit(updatedUnits));
      if (response.meta.requestStatus === "fulfilled") {
        message.success("Unit added successfully");
      } else {
        message.error("Error in adding unit");
      }

      setUnitsData([...unitsData, inputUnit]);
      setInputUnit("");
    }
  };

  return (
    <section className="flex flex-col gap-6 justify-center ">
      <Space className="mt-19 mb-3">
        <p>Add new unit</p>
      </Space>
      <div className="w-full bg-[#FFFFFF] rounded-md flex flex-col gap-6 px-6 py-10 items-center">
        <Input
          name="category"
          className="h-[44px]"
          value={inputUnit}
          placeholder="Enter unit name"
          onChange={(e) => {
            setInputUnit(e.target.value);
          }}
        />
        <Button
          onClick={() => addUnit()}
          type="primary"
          block
          className="bg-[#007FFF] text-[18px] font-semibold w-[310px] h-[46px] rounded-lg"
        >
          ADD
        </Button>
      </div>
    </section>
  );
};

const UnitsMain = () => {
  const dispatch = useDispatch();
  const units = useSelector((state) => state.units.data);
  const [unitsData, setUnitsData] = useState(units);

  useEffect(() => {
    setUnitsData(units);
  }, [units]);

  return (
    <ManageStocksWrapper
      headerName="Units"
      previewTitle={false}
      leftSideContent={
        <MainContainer
          unitsData={unitsData}
          setUnitsData={setUnitsData}
          dispatch={dispatch}
        />
      }
      rightSideContent={
        <SideContainer
          unitsData={unitsData}
          setUnitsData={setUnitsData}
          dispatch={dispatch}
        />
      }
    ></ManageStocksWrapper>
  );
};

export default UnitsMain;
