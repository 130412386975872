import { useDispatch, useSelector } from "react-redux";
import { POS_TYPE } from "../../../utils/posHelper";
import { useNavigate } from "react-router-dom";
import BookingAddSymbol from "../../../assets/images/svgs/BookingAddSymbol.svg";
import { updateDisplayOverLayingHamburgerMenu } from "../../../reducer/appHeaderReducer";
import { Tooltip } from "@mui/material";
import { TranslateText } from "../../../utils/translation";
const NavigationItem = ({
  navigationOptions,
  dashboardSelectHandler,
  posMenu,
  handleBanquetOrder,
}) => {
  const { activeMenu, menuDrawer } = useSelector((store) => store.app);
  const { createBookingAllowed } = useSelector(
    (store) => store.usersRestriction
  );
  const { displayOverlayingHamburgerMenu } = useSelector(
    (store) => store.appHeader
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { activePosSlug, activePosType } = useSelector(
    (store) => store.posSetting
  );

  let icon = navigationOptions?.img;

  const { hotelDetails, isAllHotelsSelected } = useSelector(
    (store) => store.login
  );

  let link = Boolean(posMenu)
    ? `/dashboard/${activePosSlug}${navigationOptions?.link}`
    : navigationOptions?.title?.toLowerCase() === "pos"
    ? `/dashboard/${activePosSlug}/`
    : "/dashboard/" + navigationOptions?.link;

  const isBanquetNewOrder =
    activePosType == POS_TYPE[1] ||
    activePosType == POS_TYPE[3] ||
    activePosType == POS_TYPE[4] ||
    activePosType == POS_TYPE[5]
      ? navigationOptions.title?.toLowerCase() == "new order"
      : null;

  const redirectPage = () => {
    if (navigationOptions.link == "booking-engine") {
      redirectToPage(`/website/${hotelDetails?.id}`);
    }

    // if banquet or spa or salon , new order will open directly
    if (isBanquetNewOrder) handleBanquetOrder();
    else navigate(link);
  };

  const redirectToPage = (url) => window.open(url, "_newtab");

  return (
    <Tooltip
      title={
        menuDrawer && !displayOverlayingHamburgerMenu
          ? navigationOptions?.title
          : ""
      }
      placement="right"
      onClick={redirectPage}
    >
      <div key={navigationOptions?.id}>
        <div
          onClick={() => dashboardSelectHandler(navigationOptions)}
          className={`text-white leading-7 text-md font-normal pt-[10px] pb-[10px] pl-[30px] flex items-center gap-3 cursor-pointer hover:cursor-pointer  ${
            activeMenu?.toLowerCase() ===
              navigationOptions.title?.toLowerCase() && "bg-[#00000026]"
          }`}
        >
          <div>
            {navigationOptions?.type !== "category" && (
              <>
                {navigationOptions?.icon ? (
                  <>{icon()}</>
                ) : (
                  <img
                    src={navigationOptions?.img}
                    alt={navigationOptions?.title}
                  />
                )}
              </>
            )}
          </div>

          <div className={`${!menuDrawer ? "w-[100%]" : ""}`}>
            {(!menuDrawer || displayOverlayingHamburgerMenu) && (
              <div className="flex justify-between items-center pr-[12px] w-[100%]">
                <span className="font-outfit text-xs sm:text-sm md:text-1xl">
                  {navigationOptions?.translatedTitle}
                </span>

                {navigationOptions?.link === "bookings" && (
                  <div
                    className="w-[1.7rem] h-[1.7rem]"
                    onClick={(e) => {
                      navigate("walkin");
                      dispatch(updateDisplayOverLayingHamburgerMenu(false));
                      e.stopPropagation();
                    }}
                  >
                    {createBookingAllowed && !isAllHotelsSelected && (
                      <img
                        src={BookingAddSymbol}
                        alt=""
                        className="w-[100%] h-[100%] mt-[5px]"
                      />
                    )}
                  </div>
                )}

                {navigationOptions?.link === "all-orders" &&
                  !isAllHotelsSelected && (
                    <div
                      className="w-[1.7rem] h-[1.7rem] "
                      onClick={(e) => {
                        navigate(`/dashboard/${activePosSlug}/table-view`);
                        dispatch(updateDisplayOverLayingHamburgerMenu(false));
                        // dispatch(updateMenuDrawer(true));
                        e.stopPropagation();
                      }}
                    >
                      <img
                        src={BookingAddSymbol}
                        alt=""
                        className="w-[100%] h-[100%] mt-[5px]"
                      />
                    </div>
                  )}

                {/* {(navigationOptions?.link === "party" ||
                  navigationOptions?.link === "expense" ||
                  navigationOptions?.link === "manageStocks" ||
                  navigationOptions?.link === "payments" ||
                  navigationOptions?.link === "invoice") && (
                  <div
                    className="font-nunito font-[700] text-[10px] leading-[13.64px] text-[#007FFF] 
      tracking-[1px] bg-gradient-to-r from-white to-blue-200 px-[9px] py-[1px] rounded-[8px] uppercase"
                  >
                    <TranslateText textKey={"beta"} namespace="menu" />
                  </div>
                )} */}
              </div>
            )}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default NavigationItem;
