import axios from "axios";
import { authedAxios } from "../utils/customHttpHeader";

const getAllRooms = async (payload) => {
  let queryString = `rooms?`;
  if (payload.hotelId) {
    queryString += `hotelId=${payload.hotelId}`;
  } else {
    queryString += `hotelIds=${payload.hotelIds}`;
  }

  const response = await authedAxios().get(queryString);
  return response;
};

const getInactiveRooms = async (payload, endpoint) => {
  const queryString = `rooms?hotelId=${payload.hotelId}&status=inActive`;
  const response = await authedAxios().get(queryString);
  return response;
};

const saveRoom = async (payload) => {
  const response = await authedAxios().post("/rooms", payload);
  return response;
};

const updateRoomService = async (payload) => {
  const queryString = `hotelId=${payload.hotelId}`;
  const { roomPayload, endpoint } = payload;

  const response = await authedAxios().put(
    endpoint + "?" + queryString,
    roomPayload
  );
  return response;
};

const saveAllRooms = async (payload) => {
  const response = await authedAxios().post("/rooms/saveAll", payload);
  return response;
};

const updateRoom = async (payload) => {
  const queryString = `?hotelId=${payload.hotelId}`;

  const response = await authedAxios().put(
    `/rooms/${payload?.id}/${queryString}`,
    payload
  );
  return response;
};

const deleteMultipleRooms = async (payload) => {
  const response = await axios.delete(
    `https://dev.revivotech.in/hotelx/rooms?hotelId=${payload?.hotelId}&&roomIds=${payload?.data}`
  );
  return response;
};

// const getRoomByIdService = async (endpoint, payload) => {
//   let url = endpoint;

//   url += `/${payload.id}`;
//   url += `?hotelId=${payload?.hotelId}`;

//   const res = await authedAxios.get(url);
//   return res;
// };

export {
  getAllRooms,
  getInactiveRooms,
  updateRoomService,
  saveRoom,
  saveAllRooms,
  deleteMultipleRooms,
  updateRoom,
  // getRoomByIdService,
};
