import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStaffService, postStaffService } from "../services/staff";
import { message } from "antd";

export const getStaffList = createAsyncThunk(
  "getStaffList",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getStaffService(payload, `/staff`);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

export const addStaffList = createAsyncThunk(
  "addStaffList",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await postStaffService(payload, `/staff`);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      return error.message;
    }
  }
);

const initialState = {
  staffResponse: [],
  staffResponseLoading: false,
  staffResponseError: null,
  staffAddLoading: false,
  staffAddError: null,
};

const staffSlice = createSlice({
  name: "staffSlice",
  initialState,
  reducers: { CLEAR_REDUX_STORE_42: () => initialState },
  extraReducers: (builder) => {
    builder.addCase(getStaffList.pending, (state, action) => {
      state.staffResponseLoading = true;
      state.staffResponseError = false;
    });
    builder.addCase(getStaffList.fulfilled, (state, action) => {
      state.staffResponse = action.payload;
      state.staffResponseLoading = false;
      state.staffResponseError = false;
    });
    builder.addCase(getStaffList.rejected, (state, action) => {
      state.staffResponseLoading = false;
      state.staffResponseError = action.payload;
    });
    builder.addCase(addStaffList.pending, (state, action) => {
      state.staffAddLoading = true;
      state.staffAddError = false;
    });
    builder.addCase(addStaffList.fulfilled, (state, action) => {
      state.staffResponse = [action.payload, ...state.staffResponse];
      state.staffAddLoading = false;
      state.staffAddError = false;
    });
    builder.addCase(addStaffList.rejected, (state, action) => {
      state.staffAddLoading = false;
      state.staffAddError = action.payload;
    });
  },
});

export default staffSlice.reducer;
export const { CLEAR_REDUX_STORE_42 } = staffSlice.actions;
