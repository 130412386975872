import React, { useState, useEffect } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { updateExpandedHotelDetailScreen } from "../../reducer/appHeaderReducer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateHotel,
  updateIsAllHotelsSelected,
  updateSelectedHotels,
} from "../../reducer/loginReducer";
import { MdOutlineLogout } from "react-icons/md";
import useResetState from "../../components/useHook/useResetState";
import { Tooltip } from "@mui/material";

import { BsSearch } from "react-icons/bs";
import { Input } from "antd";
import Apartments from "../../assets/images/pngs/apartments.png";

const Index = () => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);

  const navigate = useNavigate();
  const { resetState } = useResetState();

  const { allHotelDetails } = useSelector((store) => store.login);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearchText = (e) => {
    let value = e.target.value?.trim();

    setSearchText(value);

    if (value === "") setFilteredData(allHotelDetails);
    else {
      const filteredResult = Array.isArray(allHotelDetails)
        ? allHotelDetails.filter((hotel) =>
            hotel?.name?.toLowerCase().includes(value.toLowerCase())
          )
        : [];
      setFilteredData(filteredResult);
    }
  };

  const handleLogOut = () => {
    resetState();
    window.localStorage.clear("hotelId");
    window.localStorage.clear("token");
    navigate("/");
  };

  useEffect(() => {
    setFilteredData(allHotelDetails);
  }, [allHotelDetails, hotelDetails]);

  const handleHotelChange = (e, hotel) => {
    e.stopPropagation();
    localStorage.removeItem("hotelId");
    localStorage.setItem("hotelId", hotel?.id);

    dispatch(updateIsAllHotelsSelected(false));
    dispatch(updateSelectedHotels([hotel?.id]));

    dispatch(updateHotel(hotel));
    dispatch(updateExpandedHotelDetailScreen(false));
  };

  const getInitials = (name) => {
    const parts = name?.split(" ");
    let initials = "";

    if (Array.isArray(parts)) {
      if (parts?.length === 1) {
        // If only one part, take the first character
        initials = parts?.[0].charAt?.(0);
      } else if (parts?.length > 1) {
        // If multiple parts, take the first character of the first part
        // and the first character of the last part
        initials = Boolean(parts?.[1]?.charAt?.(0))
          ? parts?.[0].charAt?.(0) + parts?.[1]?.charAt?.(0)
          : parts?.[0].charAt?.(0);
      }

      return Boolean(initials) ? initials?.toUpperCase() : ""; // Convert to uppercase if needed
    } else return "";
  };

  const handleClickAllHotels = () => {
    dispatch(updateIsAllHotelsSelected(true));
    dispatch(updateExpandedHotelDetailScreen(false));
  };

  return (
    <div className="w-[100%] h-screen bg-[#F2F4FC] ">
      <header className="fixed top-0 w-[100%] bg-[#FFF] flex flex-col p-3 justify-start items-start gap-2 shadow-md pt-7">
        <div className="flex  justify-start items-center">
          <div onClick={() => dispatch(updateExpandedHotelDetailScreen(false))}>
            <IoIosArrowRoundBack className="text-[#000] text-[28px]" />
          </div>
          <div className="font-outfit font-[400] text-[16px] leading-[24px] text-[#000] flex gap-2 items-center">
            {Boolean(hotelDetails?.name)
              ? hotelDetails?.name?.substring(0, 20)
              : "Hotel"}
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center">
          <div className="flex  w-[90%] relative items-center rounded-[32px] my-3">
            <BsSearch className="z-[10] text-1xl absolute top-[13px] left-[20px] text-[#8F9BB3]" />

            <Input
              value={searchText}
              onChange={(e) => handleSearchText(e)}
              placeholder="Search"
              className="bg-[#F5F7FA]   w-full pl-[43px] p-3  text-sm  rounded-[32px]"
            />
          </div>
        </div>
      </header>

      <div className="pt-[10rem] w-[100%] p-2  ">
        {" "}
        <div className=" rounded-[16px] bg-[#FFF] mx-3 flex flex-col gap-2">
          {allHotelDetails?.length > 1 && (
            <div
              className={`flex justify-between items-center p-4 cursor-pointer rounded-t-[16px]`}
              onClick={handleClickAllHotels}
            >
              <div className="flex gap-4 items-center">
                <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
                  <img
                    src={Apartments}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>

                <Tooltip
                  title="All Properties"
                  className="font-[Poppins] font-[600] text-[1rem] leading-[24px] text-[#4D4D4D] flex flex-wrap flex-col  gap-2 items-center "
                >
                  All Properties
                </Tooltip>
              </div>

              <div className="font-[Poppins] font-[500] text-[12px] leading-[18px] text-[#4D4D4D]"></div>
            </div>
          )}
          {Array.isArray(filteredData) &&
            filteredData?.map((hotel, index) => {
              const words = hotel?.name?.split(" ");

              const firstLetters = words
                ?.slice(0, 2)
                ?.map((word) => word?.charAt(0));

              const hotelAcronym =
                firstLetters?.join("") + "-" + hotel?.hotelNo;

              const hotelInitials = getInitials(hotel?.name);

              return (
                <div
                  className={`flex justify-between items-center p-4 cursor-pointer ${
                    index === 0 && allHotelDetails?.length === 1
                      ? "rounded-t-[16px]"
                      : ""
                  } ${
                    index === filteredData?.length - 1 ? "rounded-b-[16px]" : ""
                  } `}
                  onClick={(e) => handleHotelChange(e, hotel)}
                >
                  <div className="flex gap-4 items-center">
                    {Boolean(hotel?.logo) ? (
                      <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
                        <img
                          src={hotel?.logo}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                    ) : (
                      <>
                        {" "}
                        <button
                          className="w-[36px] h-[36px] rounded-full whitespace-nowrap
                          border border-black text-black mr-3  text-[12px] font-semibold uppercase"
                        >
                          {hotelInitials}
                        </button>
                      </>
                    )}

                    <Tooltip
                      title={hotel?.name}
                      className="font-[Poppins] font-[600] text-[1rem] leading-[24px] text-[#4D4D4D] flex flex-wrap flex-col  gap-2 items-center "
                    >
                      {hotel?.name?.substring(0, 15)}
                    </Tooltip>
                  </div>

                  <div className="font-[Poppins] font-[500] text-[12px] leading-[18px] text-[#4D4D4D]">
                    {hotelAcronym}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="w-[100%] p-2">
        <div
          className="mx-2 bg-[#FFF] rounded-[16px]  flex gap-2 justify-start items-center p-4"
          onClick={handleLogOut}
        >
          <MdOutlineLogout className="text-[#F36960] text-[16px]" />
          <div className="font-outfit font-[500] text-[14px] leading-[21px] text-[#F36960]">
            {" "}
            Log Out
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
