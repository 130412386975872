import "./categories.css";

import React, { useEffect, useState } from "react";
import { Button, Input, List, Radio, Space, message } from "antd";
import { NotSelectedRadio, SelectedRadio } from "../../../Icon1";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewCategory,
  deleteCategory,
  getCategoriesAsync,
  setSelectedCategories,
  addCategory,
} from "../../../../reducer/manageStocksReducer/categoriesReducer";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";

const MainContainer = ({ categoriesData, setCategoriesData, dispatch }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const deleteHandler = async () => {
    let updatedCategories = {
      categories: categoriesData.filter((item) => item !== value),
    };

    const response = await dispatch(deleteCategory(updatedCategories));
    if (response.meta.requestStatus === "fulfilled") {
      message.success("Category deleted successfully");
    } else {
      message.error("Error in deleting category");
    }

    setCategoriesData(categoriesData.filter((item) => item !== value));
  };

  const handleSaveAndBack = () => {
    message.success("Categories Saved!");
    navigate(-1);
  };

  return (
    <section className="px-4 flex flex-col gap-3 text-[14px] h-full">
      <Space className="mt-19 mb-3 text-[#4F4F4F] px-2">
        <p>All Categories (Tap to select/unselect)</p>
      </Space>
      <Radio.Group onChange={onChange} value={value}>
        <List
          dataSource={categoriesData}
          size="large"
          bordered={false}
          className="shadow-custom-stocks"
          pagination={{ position: "bottom", align: "center", pageSize: 9 }}
          renderItem={(item, index) =>
            item && (
              <List.Item
                key={item + index}
                className={`${""} mb-2 shadow-md bg-[#F4F4F4]`}
              >
                <div
                  className="flex items-center"
                  onClick={(e) => setValue(e.target.value)}
                >
                  {value !== item && <NotSelectedRadio />}
                  {value === item && <SelectedRadio />}
                  <Radio.Button
                    type="text"
                    className="active:bg-[#f4f4f4] bg-[#f4f4f4] border-none overflow-hidden"
                    value={item}
                  >
                    {item}
                  </Radio.Button>
                </div>
              </List.Item>
            )
          }
        />
      </Radio.Group>

      <div className="w-full flex justify-around py-4">
        <Button
          type="secondary"
          className="bg-[#FFFFFF] text-[18px] text-[#007FFF] font-semibold w-[310px] h-[56px] shadow-custom-stocks"
          onClick={() => deleteHandler()}
        >
          DELETE
        </Button>
        <Button
          type="primary"
          className="bg-[#007FFF] text-[18px] font-semibold w-[310px] h-[56px] drop-shadow-[9px_5px_10px_#c2c1c1]"
          onClick={handleSaveAndBack}
        >
          SAVE & BACK
        </Button>
      </div>
    </section>
  );
};

const SideContainer = ({ setCategoriesData, categoriesData, dispatch }) => {
  const [inputCategory, setInputCategory] = useState("");

  const addCategory = async () => {
    if (inputCategory !== "") {
      let updatedCategories = {
        categories: [...categoriesData, inputCategory],
      };

      const response = await dispatch(addNewCategory(updatedCategories));
      if (response.meta.requestStatus === "fulfilled") {
        message.success("Category added successfully");
      } else {
        message.error("Error in adding category");
      }

      setCategoriesData([...categoriesData, inputCategory]);
      setInputCategory("");
    }
  };

  return (
    <section className="flex flex-col gap-6 justify-center ">
      <Space className="mt-19 mb-3">
        <p>Add new category</p>
      </Space>
      <div className="w-full bg-[#FFFFFF] rounded-md flex flex-col gap-6 px-6 py-10 items-center">
        <Input
          name="category"
          className="h-[44px]"
          value={inputCategory}
          placeholder="Enter category name"
          onChange={(e) => {
            setInputCategory(e.target.value);
          }}
        />
        <Button
          onClick={() => addCategory()}
          type="primary"
          block
          className="bg-[#007FFF] text-[18px] font-semibold w-[310px] h-[46px] rounded-lg"
        >
          ADD
        </Button>
      </div>
    </section>
  );
};

const CategoriesMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.data);
  const [categoriesData, setCategoriesData] = useState(categories);

  useEffect(() => {
    setCategoriesData(categories);
  }, [categories]);

  const [newCategory, setNewCategory] = useState("");
  const handleNavigateToPreviousPage = () => navigate(-1);
  const { hotelDetails } = useSelector((store) => store.login);
  const { categoriesListData, selectedCategories } = useSelector(
    (store) => store.categories
  );
  useEffect(() => {
    dispatch(getCategoriesAsync(hotelDetails?.id));
  }, [hotelDetails?.id]);

  const handleSelectedCategory = (category) =>
    dispatch(setSelectedCategories(category));

  const handleNewCategory = (event) => setNewCategory(event.target.value);

  const handleAddNewCategory = () => {
    setNewCategory("");
    dispatch(
      addCategory({
        ...hotelDetails,
        categories: [...categoriesListData, newCategory],
      })
    );
  };

  return (
    <div className="flex pr-[10px] justify-between">
      <div className="pl-10 pr-4 pt-[40px] w-8/12 h-[100vh]">
        <div className="flex h-[48px] w-[600px] mb-[30px]">
          <div
            onClick={handleNavigateToPreviousPage}
            className="flex h-[48px] w-[48px] justify-center items-center cursor-pointer"
          >
            <BsArrowLeft className="text-[#007DFE] text-3xl font-bold mr-2" />
          </div>
          <div className="font-semibold text-[#545454] text-[32px]">
            Categories
          </div>
        </div>
        <div className="h-[89.2%] w-full pb-[px] flex flex-col justify-between items-start">
          <div className="w-full flex flex-col">
            <p className="text-base font-normal leading-6 text-[#4f4f4f]">
              All Categories{" "}
              <span className="text-[14px] font-normal leading-[21px] text-[#4f4f4f]">
                (Tap to select/unselect)
              </span>
            </p>
            <div className="flex flex-col gap-[12px]">
              {categoriesListData?.map((category) => {
                return (
                  <div
                    key={category}
                    id={category}
                    className="flex items-center justify-start gap-[12px] w-full h-[50px] py-[20px] pr-[20px] pl-[16px] rounded-[4px] bg-[#f9f9f9]"
                  >
                    <div
                      className="w-[21px] h-[21px] rounded-full bg-[#c4c4c4] cursor-pointer flex items-center justify-center"
                      onClick={() => handleSelectedCategory(category)}
                    >
                      {selectedCategories.includes(category) && (
                        <div className="h-[65%] w-[65%] rounded-full bg-[#007fff]"></div>
                      )}
                    </div>
                    <p className="c-text">{category}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="btns">
            <button className="btn-del">DELETE</button>
            <button className="btn-save">SAVE & BACK</button>
          </div>
        </div>
      </div>
      <div className="py-[38px] px-[26px] w-4/12 h-[95vh] bg-[#F7F7F7]">
        <div className="right">
          <p className="right-heading">Add new category</p>
          <div className="add-c-box">
            <input
              className="c-i"
              onChange={handleNewCategory}
              value={newCategory}
              placeholder="Enter category name"
            />
            <button className="c-b" onClick={handleAddNewCategory}>
              ADD
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesMain;
