import React, { useEffect, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateActiveMenu } from "../../reducer/appReducer";
import Loading from "../../pages/loading";
import { POS_TYPE } from "../../utils/posHelper";
import { resetPos } from "../../reducer/posReducer/restaurant";
const NewOrderRevamped = lazy(() =>
  import("../../components/pos/components/newOrderRevamped/Index")
);

const NewOrderRevampedMobileScreen = lazy(() =>
  import("../../mobileComponents/mobileScreenPos/components/NewOrder/Index")
);

// in case of below pos types, we need to check tableOrder
const posTypesWithoutTable = [POS_TYPE[0], POS_TYPE[2]];

const NewOrder = () => {
  const { tableOrder } = useSelector((store) => store.pos);
  const { activePosType } = useSelector((store) => store.posSetting);

  const nagivate = useNavigate();

  const dispatch = useDispatch();
  const { isMobileScreen } = useSelector((store) => store.appHeader);

  useEffect(() => {
    if (
      Object?.keys(tableOrder)?.length === 0 &&
      posTypesWithoutTable?.includes(activePosType)
    ) {
      nagivate(`/dashboard/`);
      dispatch(
        updateActiveMenu({
          activeMenu: "home",
          pos: false,
        })
      );
    }

    return () => {
      dispatch(resetPos());
    };
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <div className="h-[100vh] overflow-y-hidden">
        {isMobileScreen ? (
          <NewOrderRevampedMobileScreen />
        ) : (
          <NewOrderRevamped />
        )}
      </div>
    </Suspense>
  );
};

export default NewOrder;
