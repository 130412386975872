import { useEffect, useState } from "react";

const useStopRefreshLogic = () => {
  const [showModal, setShowModal] = useState(false);
  const [confirmRefresh, setConfirmRefresh] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!confirmRefresh) {
        event.preventDefault();
        event.returnValue = ""; // This triggers the browser's confirmation dialog
        setShowModal(true);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [confirmRefresh]);

  const handleConfirm = () => {
    setConfirmRefresh(true);
    setShowModal(false);
    window.location.reload(); // Manually trigger the reload
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return {
    showModal,
    handleConfirm,
    handleCancel,
  };
};

export default useStopRefreshLogic;
