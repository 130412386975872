import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, ConfigProvider, message } from "antd";
import {
  updateDisplayOTARoomModal,
  updateBookingDetails,
  updateBookingListData,
} from "../../../reducer/bookingReducer";
import {
  GET_ISO_FORMAT,
  GET_TODAY_DATE_WITH_ISO_FORMAT,
  GET_TOMORROW_DATE,
} from "../../../utils/helper";
import { getRoomsAvailability } from "../../../services/walkin";
import { CircularProgress } from "@mui/material";

const OTARoomModal = () => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);
  const rooms = Boolean(hotelDetails?.roomTypes) ? hotelDetails?.roomTypes : []; // room type

  const [loaclRoomListDetails, setLocalRoomListDetails] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [localSelectedRoomTypeAndNum, setLocalSelectedRoomTypeAndNum] =
    useState({});

  const {
    tempSelectedRoomWTypeAndNum,
    selectedRoomWTypeAndNum,
    displayOTARoomModal,
    bookingDetails,
    selectedCheckInDateTime,
    selectedCheckOutDateTime,
    response: bookingList,
  } = useSelector((store) => store.booking);

  const roomAvailability = loaclRoomListDetails?.map((avail) => {
    const availObj = {
      name: avail._id,
      availability: avail.availability,
      rooms: avail.rooms,
    };
    return availObj;
  });

  const isValid = () => {
    let result = true;

    for (const Obj of Object.keys(selectedRoomWTypeAndNum)) {
      const initialRoomTypeLength = selectedRoomWTypeAndNum[Obj]?.length || 0;
      const currentLength = localSelectedRoomTypeAndNum[Obj]?.length || 0;

      const roomDiff = initialRoomTypeLength - currentLength;

      if (roomDiff) {
        message.destroy();
        message.info(
          `You need to select ${roomDiff} more ${
            roomDiff > 1 ? "rooms" : "room"
          } from the ${Obj} category.`
        );
        result = false;
        return result; // Exit early if a room difference is found
      }
    }

    return result;
  };

  const handleApplyChanges = async () => {
    if (!isValid()) return;

    setApiLoading(true);

    let rooms = [];

    for (let key in localSelectedRoomTypeAndNum)
      localSelectedRoomTypeAndNum[key]?.forEach((Obj) => rooms?.push(Obj?.id));

    const newBookingDetails = JSON.parse(JSON.stringify(bookingDetails));
    newBookingDetails.rooms = rooms;

    const response = await dispatch(updateBookingDetails(newBookingDetails));

    if (response?.payload?.id) {
      message.success("Rooms updated successfully.");

      const newResponse = JSON.parse(JSON.stringify(bookingList));

      const bookingIndex = newResponse?.bookings?.findIndex(
        (Obj) => Obj.id === newBookingDetails?.id
      );

      newResponse.bookings[bookingIndex] = newBookingDetails;

      dispatch(updateBookingListData(newResponse));

      setApiLoading(false);
      dispatch(updateDisplayOTARoomModal());
    } else {
      message.info("Oops! Something went wrong. Please try again.");
      setApiLoading(false);
      dispatch(updateDisplayOTARoomModal());
    }

    setApiLoading(false);
  };

  const handleSelectedRoomChange = (
    selectedStatus,
    roomType,
    roomNum,
    roomArray
  ) => {
    if (selectedStatus) {
      let removeSelectedRoom = localSelectedRoomTypeAndNum[roomType]?.filter(
        (item) => item.name !== roomNum
      );

      setLocalSelectedRoomTypeAndNum({
        ...localSelectedRoomTypeAndNum,
        [roomType]: removeSelectedRoom,
      });
    } else {
      // if room limit is not 0

      if (Boolean(localSelectedRoomTypeAndNum[roomType])) {
        const allowedRoomTypeLimit = selectedRoomWTypeAndNum[roomType]?.length;
        const currentRoomTypeLength =
          localSelectedRoomTypeAndNum[roomType]?.length;

        //  First we deal where only on room is there originally
        if (allowedRoomTypeLimit === 1) {
          setLocalSelectedRoomTypeAndNum({
            ...localSelectedRoomTypeAndNum,
            [roomType]: [roomArray],
          });
          return;
        }

        if (currentRoomTypeLength === allowedRoomTypeLimit) {
          message.destroy();
          message.info(
            "First deselct the room and then select the required room of given room type"
          );
          return;
        }

        let selectedRoomArray = localSelectedRoomTypeAndNum[roomType];

        setLocalSelectedRoomTypeAndNum({
          ...localSelectedRoomTypeAndNum,
          [roomType]: [...selectedRoomArray, roomArray],
        });
      }
    }
  };

  const handleCancel = () => {
    dispatch(updateDisplayOTARoomModal());
  };

  useEffect(() => {
    InitialDataAPi();
  }, [displayOTARoomModal]);

  const InitialDataAPi = async () => {
    setInitialLoading(true);

    const payload = {
      bookingId: bookingDetails?.id,
      startDate: Boolean(selectedCheckInDateTime)
        ? GET_ISO_FORMAT(selectedCheckInDateTime)
        : GET_TODAY_DATE_WITH_ISO_FORMAT(),
      endDate: Boolean(selectedCheckOutDateTime)
        ? GET_ISO_FORMAT(selectedCheckOutDateTime)
        : GET_ISO_FORMAT(GET_TOMORROW_DATE()),
      hotelId: hotelDetails?.id,
    };

    let data = await getRoomsAvailability(payload, "bookings/availability");

    let originalSelectedRoomType = Object?.keys(selectedRoomWTypeAndNum)?.map(
      (Obj) => Obj
    );

    const namesToFilter = Array.isArray(rooms)
      ? rooms?.map((item) => item.name)
      : [];

    const updatedData = Array.isArray(data)
      ? data
          ?.filter((item) => namesToFilter?.includes(item?._id))
          ?.filter((item) => originalSelectedRoomType?.includes(item?._id))
      : [];

    setLocalSelectedRoomTypeAndNum(tempSelectedRoomWTypeAndNum);
    setLocalRoomListDetails(updatedData);
    setInitialLoading(false);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          margin: 0,
          padding: 2,
        },
      }}
    >
      <Modal
        title={
          initialLoading ? (
            <></>
          ) : (
            <div className="w-[100%] flex justify-between items-center mr-3">
              <div className="font-outfit font-[600] "></div>{" "}
              <div className="flex  gap-2 items-center">
                <div className="w-[full] flex gap-3 items-end  bottom-[25%] left-[40%] bg-[#FFF]  justify-center">
                  <button
                    className="border-[#2F80ED] border-[1px] text-[#2F80ED]   text-[1.125rem] font-[400] 
                  leading-[1.6875rem]  font-outfit  rounded-[0.625rem] cursor-pointer flex justify-center items-center p-2 px-3"
                    onClick={apiLoading ? undefined : handleCancel}
                  >
                    {apiLoading ? <CircularProgress size={16} /> : "Close"}
                  </button>
                  <button
                    className="border-[#2F80ED] border-[1px] text-[#2F80ED]   text-[1.125rem] font-[400] 
                  leading-[1.6875rem]  font-outfit  rounded-[0.625rem] cursor-pointer flex justify-center items-center p-2 px-3"
                    onClick={apiLoading ? undefined : handleApplyChanges}
                  >
                    {apiLoading ? <CircularProgress size={16} /> : "Apply"}
                  </button>
                </div>
              </div>
            </div>
          )
        }
        closeIcon={null}
        open={displayOTARoomModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={null}
        width={"45rem"}
        zIndex={1000}
        style={{ height: "65vh", overflowY: "auto" }}
      >
        {initialLoading ? (
          <div className="w-[100%] h-[100%] flex justify-center items-center">
            <CircularProgress size={30} />
          </div>
        ) : (
          <div className=" overflow-y-auto">
            <div className="flex flex-col gap-3  items-start justify-center  text-center">
              {roomAvailability?.map((val, index) => {
                return (
                  <div key={index} className="">
                    <div className="flex gap-2 items-center">
                      <h1 className="relative font-[500] font-[Poppins] text-[1rem] text-[#000000b3]">
                        {val.name}{" "}
                      </h1>
                      {/* <p className="font-[300] text-[0.75rem] font-[Poppins] text-[#000000b3]">
                        Select any{" "}
                        {localSelectedRoomTypeAndNum[val?.name]?.length} room
                      </p> */}
                    </div>

                    <div
                      className="flex flex-wrap"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {val.rooms?.map((item, index) => {
                        return localSelectedRoomTypeAndNum[val.name]
                          ?.map((pd) => pd.name)
                          ?.indexOf(item.name) > -1 ? (
                          <div
                            onClick={() =>
                              handleSelectedRoomChange(
                                true,
                                val.name,
                                item.name,
                                item
                              )
                            }
                            key={index}
                            className=""
                          >
                            <div className="border-[1px] text-white py-[3px] px-2 rounded-lg bg-primary m-2 cursor-pointer">
                              {item.name}
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              handleSelectedRoomChange(
                                false,
                                val.name,
                                item.name,
                                item
                              )
                            }
                            key={index}
                            className=""
                          >
                            <div className="border-[1px] bg-[#F9F9F9] py-[3px] px-2 rounded-lg text-[gray] m-2 cursor-pointer ">
                              {item.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Modal>
    </ConfigProvider>
  );
};

export default OTARoomModal;
