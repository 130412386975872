import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  selectedSettingType: "",
  expandedPosId: "",
  selectedPosSettingType: "",
  showStewardDrawer: false,
  stewardName: "",
  isExistingSteward: false,
  showAddTableDrawer: false,
  isExistingTable: null,
  tableName: "",
  activeMenuManagementTab: "menu",
  menuActiveInactiveFilter: true,
  selectedItem: {},
  displayDeletePosModal: false,

  showAddIngredientDrawer: false,
  isExistingIngredient: null,
  ingredientDetails: "",

  //while creating new POS
  stewardsList: [],

  addUnitDropdownVisible: false,
  newUnitName: "",
  addCategoryDropdownVisible: false,
  newCategoryName: "",
  addRoomType: null,
  addIngredientCategoryDropdownVisible: false,

  addIngredientUnitDropdownVisible: false,
  newIngUnitName: "",
  displayEditRoomTypeCustomTaxModal : false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_40: () => initialState,

    updateAddUnitDropdownVisible: (state, action) => {
      state.addUnitDropdownVisible = action.payload;
    },
    updateNewUnitName: (state, action) => {
      state.newUnitName = action.payload;
    },

    updateAddCategoryDropdownVisible: (state, action) => {
      state.addCategoryDropdownVisible = action.payload;
    },

    updateAddIngredientCategoryDropdownVisible: (state, action) => {
      state.addIngredientCategoryDropdownVisible = action.payload;
    },
    updateNewCategoryName: (state, action) => {
      state.newCategoryName = action.payload;
    },

    updateAddIngredientUnitDropdownVisible: (state, action) => {
      state.addIngredientUnitDropdownVisible = action.payload;
    },
    updateNewIngUnitName: (state, action) => {
      state.newIngUnitName = action.payload;
    },

    updateSelectedSettingType: (state, action) => {
      // state.selectedSettingType =
      //   action.payload !== state.selectedSettingType ? action.payload : "";
      state.selectedSettingType = action.payload;
    },

    updateSelectedPosId: (state, action) => {
      if (state.expandedPosId === "") {
        state.selectedPosSettingType = "General Settings";
        state.expandedPosId = action.payload;
      } else if (state.expandedPosId === "add") {
        if (action.payload === "add") {
          state.selectedPosSettingType = "";
          state.expandedPosId = "";
        } else {
          state.selectedPosSettingType = "General Settings";
          state.expandedPosId = action.payload;
        }
      } else {
        state.selectedPosSettingType = "General Settings";
        state.expandedPosId = action.payload;
      }
    },
    updateSelectedPosSettingType: (state, action) => {
      state.selectedPosSettingType =
        action.payload !== state.selectedPosSettingType
          ? action.payload
          : "General Settings";
    },
    updateShowStewardDrawer: (state, action) => {
      state.showStewardDrawer = action.payload;
    },
    updateStewardName: (state, action) => {
      state.stewardName = action.payload;
    },
    updateTableName: (state, action) => {
      state.tableName = action.payload;
    },
    updateIngredientDetails: (state, action) => {
      state.ingredientDetails = action.payload;
    },
    updateIsExistingSteward: (state, action) => {
      state.isExistingSteward = action.payload;
    },
    updateIsExistingTable: (state, action) => {
      state.isExistingTable = action.payload;
    },
    updateIsExistingIngredient: (state, action) => {
      state.isExistingIngredient = action.payload;
    },
    updateShowAddTableDrawer: (state, action) => {
      state.showAddTableDrawer = action.payload;
    },
    updateShowAddIngredientDrawer: (state, action) => {
      state.showAddIngredientDrawer = action.payload;
    },
    //
    updateActiveMenuManagementTab: (state, action) => {
      state.activeMenuManagementTab = action.payload;
    },
    updateMenuActiveInactiveFilter: (state, action) => {
      state.menuActiveInactiveFilter = action.payload;
    },
    updateSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },

    updateStewardsList: (state, action) => {
      state.stewardsList = action.payload;
    },
    updateDisplayDeletePosModal: (state, action) => {
      state.displayDeletePosModal = !state.displayDeletePosModal;
    },
    updateDisplayEditRoomTypeCustomTaxModal : (state,action) => {
      state.displayEditRoomTypeCustomTaxModal = action.payload
    },

    resetAll: (state, action) => {
      // state.selectedSettingType = "";
      state.selectedPosSettingType = "";
      state.expandedPosId = "";
      state.showStewardDrawer = false;
      state.stewardName = "";
      state.isExistingSteward = false;
      state.showAddTableDrawer = false;
      state.isExistingSteward = false;
      state.isExistingTable = null;
      state.tableName = "";
      state.selectedItem = {};
      state.activeMenuManagementTab = "menu";
    },
  },
});

export const {
  CLEAR_REDUX_STORE_40,
  updateSelectedSettingType,

  updateSelectedPosId,
  updateSelectedPosSettingType,
  updateShowStewardDrawer,
  updateStewardName,
  updateIsExistingSteward,
  updateShowAddTableDrawer,
  updateIsExistingTable,
  updateTableName,
  updateShowAddIngredientDrawer,
  updateIsExistingIngredient,
  updateIngredientDetails,
  updateActiveMenuManagementTab,
  updateMenuActiveInactiveFilter,
  updateSelectedItem,
  updateStewardsList,
  updateDisplayDeletePosModal,
  resetAll,
  updateAddUnitDropdownVisible,
  updateNewUnitName,
  updateAddCategoryDropdownVisible,
  updateNewCategoryName,
  updateAddIngredientCategoryDropdownVisible,
  updateAddIngredientUnitDropdownVisible,
  updateNewIngUnitName,

  updateDisplayEditRoomTypeCustomTaxModal
} = settingsSlice.actions;

export default settingsSlice.reducer;
