import { useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";

import { CURRENCY_FORMAT } from "../../../utils/helper";
import { HEADER_ITEMS } from "../utils";

const ListView = () => {
  const { selectedPos } = useSelector((store) => store.manageStocks);
  const { data: items, loading: itemsLoading } = useSelector(
    (store) => store.getItems
  );
  const { data: ingredients, loading: ingredientsLoading } = useSelector(
    (store) => store.ingredients
  );

  // if pos type is restaurant, then show list of ingredients, otherwise show items
  let list = [];
  let isRestaurant = selectedPos?.type === "Restaurant";
  list = isRestaurant ? ingredients : items;

  if (itemsLoading || ingredientsLoading) {
    return (
      <div className="w-full h-full pr-[50px] pl-[20px] pb-[20px] bg-[#fff] flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-full h-full pr-[50px] pl-[20px] pb-[20px]">
      <div className="w-full rounded-[10px] bg-[#fff]">
        <div className="w-full h-[60px] bg-[#fff] border-b-[#7E8082] border-b-[1px] rounded-t-[10px] flex items-center">
          {HEADER_ITEMS?.map((heading, idx) => {
            // if (idx === 0 && isRestaurant) heading = "Ingredient Name";

            // <TranslateText textKey={"ingredient_name"}namespace="manageStocks"/>
            return (
              <div className="w-1/6 h-full flex items-center pl-[20px]">
                <span className="font-medium text-gray-500  tracking-wider leading-[1rem] text-[0.9rem]">
                  {heading}
                </span>
              </div>
            );
          })}
        </div>
        <div className="max-h-[70vh] overflow-y-scroll">
          {Array.isArray(list) && list?.length ? (
            list?.map((obj, idx) => {
              const isLastRow = idx === list?.length - 1;
              const columns = isRestaurant
                ? [
                    obj?.name,
                    obj?.type,
                    obj?.unit,
                    Boolean(obj?.price) ? obj?.price : 0,
                    Boolean(obj?.stockCount) ? obj?.stockCount : 0,
                    (Boolean(obj?.stockCount) ? obj?.stockCount : 0) *
                      (Boolean(obj?.price) ? obj?.price : 0),
                  ]
                : [
                    obj?.name,
                    obj?.category,
                    obj?.unit,
                    Boolean(obj?.price) ? obj?.price : 0,
                    Boolean(obj?.stock) ? obj?.stock : 0,
                    (Boolean(obj?.stock) ? obj?.stock : 0) *
                      (Boolean(obj?.price) ? obj?.price : 0),
                  ];

              return (
                <div
                  className={`w-full h-[40px] bg-[#fff] border-b-[#00000040] border-b-[1px] flex items-center ${
                    isLastRow && "rounded-b-[10px]"
                  }`}
                >
                  {columns?.map((value, idx) => {
                    if (idx === 3 || idx === 5 || idx === 4)
                      value = CURRENCY_FORMAT(value);
                    return (
                      <div className="w-1/6 h-full flex items-center pl-[20px]">
                        <span className="font-nunito font-[500] text-[15px] leading-[21px] text-[#4D4D4D]">
                          {value}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div
              className={`w-full h-[40px] bg-[#fff] border-b-[#00000040] border-b-[1px] flex items-center`}
            >
              <div className="w-full h-full flex pl-[20px] items-center justify-center">
                <span className="font-nunito font-[500] text-[15px] leading-[21px] text-[#4D4D4D]">
                  {`No ${isRestaurant ? "Ingredients" : "Items"} present`}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListView;
