import { createSlice } from "@reduxjs/toolkit";
import { GET_TOMORROW_DATE } from "../utils/helper";

const initialState = {
  selectedRoomType: "", // data will come from channel manager page when select room type from select box
  selectedChannel: [], // data will come from channel manager page when select channels
  selectedChannelType: "", // type will be "All" or "Specific"
  selectedCheckInDate: null,
  selectedCheckOutDate: null,
  availableRoom: null,
  rate: null,
  dynamicPricing: null,
  cmInvCode: "",

  apiSuccssModal: false, // it will show when submit the data
  // data will store here to show the api success modal
  showRevisedRate: null,
  showRoomType: "",
  showCheckInDate: null,
  showCheckOutDate: null,
  showChannel: [],
  showChannelType: "",
  showAvailability: null,
  apiLoading: false, // while submitting
  apiError: false, // when api failure
  errorMsg: "",
};

export const channelManagerSlice = createSlice({
  name: "channelManager",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_14: () => initialState,

    updateRoomType: (state, action) => {
      state.selectedRoomType = action.payload;
      // if change the room type, channel will be refresh
      state.selectedChannel = [];
      state.selectedChannelType = "";
    },
    updateCmInvCode: (state, action) => {
      state.cmInvCode = action.payload;
    },
    updateChannel: (state, action) => {
      // if room type same push channel to existing array
      // if room type is not same push channel in new array

      // if all channel selected ,
      // if click in the same btn again, deselect the item from array

      if (
        state.selectedChannelType === "all" &&
        action.payload.channelType === "all"
      ) {
        state.selectedChannel = [];
        state.selectedChannelType = "";
      } else if (action.payload.channelType === "all") {
        state.selectedChannel = action.payload.channel;
        state.selectedChannelType = action.payload.channelType;
      } else {
        // if select any specific channel type after select all channel , remove all item first
        if (state.selectedChannelType !== action.payload.channelType) {
          state.selectedChannel = [];
        }

        state.selectedChannelType = action.payload.channelType;

        // if selected channel is already there in array, remove it else push it

        if (state.selectedChannel.indexOf(action.payload.channel) !== -1) {
          state.selectedChannel.splice(
            state.selectedChannel.indexOf(action.payload.channel),
            1
          );
        } else {
          state.selectedChannel.push(action.payload.channel);
        }
      }
    },
    updateDates: (state, action) => {
      if (action.payload.checkIn) {
        state.selectedCheckInDate = action.payload.checkIn;
        state.selectedCheckOutDate = action.payload.checkOut;
      } else if (action.payload.checkOut) {
        state.selectedCheckOutDate = action.payload.checkOut;
      }
    },
    updateAvailableRooms: (state, action) => {
      state.availableRoom = action.payload;
    },
    updateRate: (state, action) => {
      state.rate = action.payload;
    },
    refreshState: (state) => {
      state.selectedRoomType = "";
      state.selectedChannel = [];
      state.selectedChannelType = "";
      state.selectedCheckInDate = null;
      state.selectedCheckOutDate = null;
      state.availableRoom = null;
      state.rate = null;
    },
    updateApiSuccessModal: (state) => {
      state.apiSuccssModal = !state.apiSuccssModal;
    },
    updateApiSuccessModalData: (state, action) => {
      state.showRevisedRate = action.payload.showRevisedRate;
      state.showRoomType = action.payload.showRoomType;
      state.showCheckInDate = action.payload.showCheckInDate;
      state.showCheckOutDate = action.payload.showCheckOutDate;
      state.showChannel = action.payload.showChannel;
      state.showChannelType = action.payload.showChannelType;
      state.showAvailability = action.payload.showAvailability;
    },
    refreshApiSuccessModalData: (state) => {
      state.showRevisedRate = "";
      state.showRoomType = "";
      state.showCheckInDate = null;
      state.showCheckOutDate = null;
      state.showChannel = [];
      state.showChannelType = "";
    },
    updateApiStatus: (state, action) => {
      state.apiLoading = action.payload.loading; // while submitting
      state.apiError = action.payload.apiError; // when api failure
      state.errorMsg = action.payload.errorMsg;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_14,
  updateRoomType,
  updateChannel,
  updateDates,
  updateAvailableRooms,
  updateRate,
  refreshState,
  updateCmInvCode,
  updateApiSuccessModal,
  updateApiSuccessModalData,
  refreshApiSuccessModalData,
  updateApiStatus,
} = channelManagerSlice.actions;

export default channelManagerSlice.reducer;
