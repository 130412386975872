import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TranslateText } from "../../../utils/translation";
import { updateShowExtraOptionDrawer } from "../../../reducer/bookingReducer";

const ExtraOption = ({ bookingType }) => {
  const dispatch = useDispatch();

  const { bookingDetails } = useSelector((store) => store.booking);
  const { isAllHotelsSelected,hotelDetails } = useSelector((store) => store.login);
  const allowEditingAfterCheckout = hotelDetails?.allowEditingAfterCheckout;

  const handleEdit = () => dispatch(updateShowExtraOptionDrawer(true));

  const { editBookingAllowed } = useSelector((store) => store.usersRestriction);
 
  let isBookingAlterationAllowed = Boolean(
    editBookingAllowed &&
      (bookingType !== "completed" || !allowEditingAfterCheckout) &&
      !isAllHotelsSelected
  );

  return (
    <div className="shadow  relative p-4 mt-4 mb-[100px] border rounded-xl border-[#00000015]">
      { isBookingAlterationAllowed && (
          <div
            className="absolute top-[-10px] font-[600]  right-[2rem] bg-[#FFF] px-2 text-primary cursor-pointer font-nunito text-[0.75rem]"
            onClick={() => handleEdit()}
          >
            <TranslateText textKey={"edit"} namespace="common" />
          </div>
        )}

      <div className="flex  gap-3 flex-wrap text-[#4D4D4D] font-nunito font-500 ">
        <div className="flex gap-2 mb-2 bg-[#41C58821] p-2 rounded-md ">
          <div className="text-[0.75rem] font-nunito">
            <TranslateText textKey={"food_choice"} namespace="booking" />:
          </div>
          <div className="text-[0.75rem] font-nunito capitalize">
            {bookingDetails?.foodChoice}
          </div>
        </div>

        <div className="flex justify-between mb-2 gap-2 bg-[#41C58821] p-2 rounded-md">
          <div className="text-[0.75rem] font-nunito">
            <TranslateText textKey={"travel_mode"} namespace="booking" />:
          </div>
          <div className="text-[0.75rem] font-nunito capitalize">
            {bookingDetails?.modeOfTravel}
          </div>
        </div>

        <div className="flex justify-between  gap-2 mb-2 bg-[#41C58821] p-2 rounded-md">
          <div className="text-[0.75rem] font-nunito">
            <TranslateText textKey={"referred_by"} namespace="booking" />:{" "}
          </div>
          <div className="text-[0.75rem] font-nunito capitalize">
            {bookingDetails?.referenceNo}{" "}
          </div>
        </div>

        <div className="flex justify-between  gap-2 mb-2 bg-[#41C58821] p-2 rounded-md">
          <div className="text-[0.75rem] font-nunito">
            <TranslateText textKey={"arrival"} namespace="booking" />:{" "}
          </div>
          <div className="text-[0.75rem] font-nunito  capitalize">
            {bookingDetails?.arrival}
          </div>
        </div>

        <div className="flex justify-between gap-2 mb-2 bg-[#41C58821] p-2 rounded-md">
          <div className="text-[0.75rem] font-nunito">
            <TranslateText textKey={"destination"} namespace="booking" />:
          </div>
          <div className="text-[0.75rem] font-nunito capitalize">
            {bookingDetails?.destination}
          </div>
        </div>

        <div className="flex justify-between gap-2 mb-2 bg-[#41C58821] p-2 rounded-md">
          <div className="text-[0.75rem] font-nunito">
            <TranslateText textKey={"purpose_of_visit"} namespace="booking" />:
          </div>
          <div className="text-[0.75rem] font-nunito capitalize">
            {bookingDetails?.purposeOfVisit}
          </div>
        </div>

        <div className="flex justify-between gap-2 mb-2 bg-[#41C58821] p-2 rounded-md">
          <div className="text-[0.75rem] font-nunito">
            <TranslateText textKey={"remarks"} namespace="booking" />:
          </div>
          <div className="text-[0.75rem] font-nunito capitalize">
            {bookingDetails?.remarks ?? ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraOption;
