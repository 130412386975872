import { useState, useRef } from "react";
import Drawer from "@mui/material/Drawer";

import { useDispatch, useSelector } from "react-redux";
import { updateDisplayPaymentPrintDrawer } from "../../../reducer/bookingReducer";

import { ReactComponent as PrinterIcon } from "../../../assets/icons/printerIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/downloadIcon.svg";
import { CloseOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import { usePDF } from "react-to-pdf";
import { CircularProgress } from "@mui/material";
import PaymentStructure from "../../common/Template/PaymentStructure";
import { bookingDetailsDrawerWidth } from "../../../utils/bookingDetailsHelper";

export default function ViewPaymentDrawer() {
  const dispatch = useDispatch();
  const componentRef = useRef(null);

  const { displayPaymentPrintDrawer } = useSelector((store) => store.booking);
  const { hotelDetails } = useSelector((store) => store.login);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [print, setPrint] = useState(false);
  const { editBookingAllowed } = useSelector((store) => store.usersRestriction);

  const printPDF = useReactToPrint({
    onAfterPrint: () => setPrint(false),
    content: () => componentRef.current,
    documentTitle: "Payment",
  });

  const action = async () => {
    setPrint(true);
    setTimeout(() => {
      printPDF();
      setPrint(false);
    }, 0);
  };

  const { toPDF, targetRef } = usePDF({
    filename: "Payment.pdf",
    scale: 1, // Adjust the scale as needed
    options: {
      format: "A4", // Specify the page size (e.g., "letter", "A4")
    },
  });

  const handleGeneratePDF = async () => {
    await setPdfLoading(true);
    await toPDF();
    await setPdfLoading(false);
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "800px",
          height: "100vh",
          top: "auto",
          bottom: 0,
        },
      }}
      sx={{
        "& .MuiDrawer-root": {
          position: "fixed",
          left: 0,
          right: 0,
          width: "30%",
        },
        "& .MuiPaper-root": {
          position: "absolute",
          marginLeft: "auto",
        },
      }}
      anchor={"right"}
      open={displayPaymentPrintDrawer?.display}
      onClose={() => {
        dispatch(
          updateDisplayPaymentPrintDrawer({ display: false, paymentId: "" })
        );
      }}
    >
      <div className="flex justify-between items-center p-3 border border-b-[1px]">
        <div></div>
        <div className="flex gap-3 items-center">
          {editBookingAllowed && (
            <>
              {" "}
              <PrinterIcon onClick={action} className="cursor-pointer" />
              {pdfLoading ? (
                <CircularProgress size={16} />
              ) : (
                <DownloadIcon
                  onClick={handleGeneratePDF}
                  className="cursor-pointer"
                />
              )}
            </>
          )}

          <CloseOutlined
            onClick={() =>
              dispatch(
                updateDisplayPaymentPrintDrawer({
                  display: false,
                  paymentId: "",
                })
              )
            }
            className="cursor-pointer customCloseIconContainer"
          />
        </div>
      </div>

      <PaymentStructure
        paymentDetails={displayPaymentPrintDrawer?.paymentObject}
        customerDetails={displayPaymentPrintDrawer?.customerDetails?.[0]}
        bookingDetails={displayPaymentPrintDrawer?.bookingDetails}
        roomDetails={displayPaymentPrintDrawer?.roomDetails}
        hotelDetails={hotelDetails}
        isComponent={true}
        print={false}
        download={false}
      />

      {print && (
        <PaymentStructure
          paymentDetails={displayPaymentPrintDrawer?.paymentObject}
          customerDetails={displayPaymentPrintDrawer?.customerDetails?.[0]}
          bookingDetails={displayPaymentPrintDrawer?.bookingDetails}
          roomDetails={displayPaymentPrintDrawer?.roomDetails}
          hotelDetails={hotelDetails}
          val={componentRef}
          isComponent={false}
          print={true}
          download={false}
        />
      )}

      {pdfLoading && (
        <PaymentStructure
          paymentDetails={displayPaymentPrintDrawer?.paymentObject}
          customerDetails={displayPaymentPrintDrawer?.customerDetails?.[0]}
          bookingDetails={displayPaymentPrintDrawer?.bookingDetails}
          roomDetails={displayPaymentPrintDrawer?.roomDetails}
          hotelDetails={hotelDetails}
          targetRef={targetRef}
          isComponent={false}
          print={false}
          download={true}
        />
      )}
    </Drawer>
  );
}
