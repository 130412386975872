import { CALCULATE_NIGHTS_COUNT } from "./helper";

export const bookingDetailsDrawerWidth = "800px";

export const customCloseIconContainer = {
  position: "absolute",
  top: "4.5%",
  right: "3%",
  fontSize: "10px",
  color: "#4D4D4D",
  width: "20px", // Adjust width and height as needed
  height: "20px",
  border: "1px solid #4D4D4D",
  borderRadius: "5px", // Adjust the border radius for rounded corners
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const roomCountArrayObject = (bookingDetails, response) => {
  // room object [room type, room count , room number]
  const roomObject = {};
  response?.forEach((item) => {
    // bookingDetails.rooms?.map((pd) => {});
    bookingDetails?.rooms?.forEach((id) => {
      if (item.id === id) {
        roomObject[item.type] =
          roomObject[item.type] !== undefined
            ? {
                count: roomObject[item.type].count + 1,
                roomNum: [...roomObject[item.type].roomNum, item.name],
              }
            : {
                count: 1,
                roomNum: [item.name],
              };
      }
    });
  });

  const roomCountArray = Object.entries(roomObject).map(([key, value]) => {
    return { type: key, count: value.count, roomNum: value.roomNum };
  });

  return roomCountArray;
};

export const totalRoomsArray = (roomCountArray) => {
  let totalRooms = [];
  Array.isArray(roomCountArray) &&
    roomCountArray.map(
      (room) =>
        Array.isArray(room?.roomNum) &&
        room?.roomNum?.map((roomNumber) => totalRooms.push(roomNumber))
    );
  return totalRooms;
};

export const totalGuestNumber = (adults, children, bookingDetails) => {
  const newAdults = Boolean(adults) ? Number(adults) : 0;
  const newChildren = Boolean(children) ? Number(children) : 0;

  return newAdults + newChildren;
};

export const guestLength = (bookingDetails, totalGuest) => {
  if (bookingDetails?.idImages?.length > totalGuest) {
    return totalGuest;
  } else if (bookingDetails?.idImages?.length) {
    return bookingDetails?.idImages?.length;
  } else return 0;
};

export const calculateNumberOfNights = (checkIn, checkOut, hotelDetails) => {
  return CALCULATE_NIGHTS_COUNT(checkIn, checkOut, hotelDetails);
};
