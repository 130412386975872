import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrdersService } from "../services/getOrders";

import {
  generateInvoice,
  placeOrParkOrder,
  updateOrderInvoice,
  updateOrderService,
  getInvoice,
  getOrderService,
  getInvoiceByID,
} from "../services/createOrder";
import { message } from "antd";

import { resetPos } from "./posReducer/restaurant";
import { deleteOrder } from "../services/orders";
import { invoiceDeletion, postPayment } from "../services/invoice";
import { updateLoading } from "./appReducer";
import { bookingDetailsById } from "../services/booking";

export const createNewOrder = createAsyncThunk(
  "order/createNewOrder",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let invoiceResponse = {};
      dispatch(getLatestOrder({}));

      const res = await placeOrParkOrder(payload.orderPayload);
      dispatch(getLatestOrder(res.data));
      let priceBreakUp = payload.invoicePayload?.priceBreakup?.map((item) => {
        if (!Boolean(item.orderId)) {
          return { ...item, orderId: res.data.id };
        }
        return item;
      });

      if (Boolean(payload.status == "update")) {
        invoiceResponse = await dispatch(
          updateInvoice({
            ...payload.invoicePayload,
            orderId: res.data.id,
            priceBreakup: priceBreakUp,
            orders: [...payload.invoicePayload.orders, res.data.id],
          })
        );
      } else {
        invoiceResponse = await dispatch(
          createNewInvoice({
            ...payload.invoicePayload,
            orderId: res.data.id,
            priceBreakup: priceBreakUp,
            orders: [res.data.id],
          })
        );
      }

      return fulfillWithValue({
        orderData: res.data,
        invoiceData: invoiceResponse,
      });
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const updateAllOrdersByTableId = createAsyncThunk(
  "order/updateAllOrdersByTableId",
  async (payload, action) => {
    try {
      payload.runningOrders?.forEach(async (item) => {
        let response = null;

        response = await updateOrderService({
          ...item,
          tableId: payload.tableId,
        });

        if (!Boolean(response)) {
          return response;
        }
      });
    } catch (error) {
      return error;
    }
  }
);

export const updateAllOrdersByStatus = createAsyncThunk(
  "order/updateAllOrders",
  async (payload, action) => {
    try {
      payload.runningOrders?.forEach(async (item) => {
        let response = null;

        if (!(item.status == "held" || item.status == "cancelled")) {
          response = await updateOrderService({
            ...item,
            status: payload.status,
          });
        }

        if (!Boolean(response)) {
          return response;
        }
      });
    } catch (error) {
      return error;
    }
  }
);

export const updateOrderAndInvoice = createAsyncThunk(
  "order/updateNewOrder",
  async (payload, action) => {
    let orderResponse;
    let invoiceResponse;
    try {
      orderResponse = await updateOrderService(payload.orderPayload);
      if (payload.invoicePayload) {
        invoiceResponse = await action.dispatch(
          updateInvoice(payload.invoicePayload)
        );
      }
      return {
        orderData: orderResponse,
        invoiceData: invoiceResponse,
      };
    } catch (error) {
      return error;
    }
  }
);

export const getTableOrders = createAsyncThunk(
  "allOrders/getTableOrders",
  async (queryParams) => {
    const response = await getOrdersService(queryParams);
    return response;
  }
);

export const takePayment = createAsyncThunk(
  "payment/takepayment",
  async (payload, action) => {
    try {
      const response = await postPayment(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const multipleTakePayment = createAsyncThunk(
  "payment/multipleTakepayment",
  async (payload, action) => {
    try {
      const response = await postPayment(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createNewInvoice = createAsyncThunk(
  "order/createNewInvoice",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await generateInvoice(payload);
      dispatch(resetPos());
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getLatestOrder = createAsyncThunk(
  "order/getLatestOrder",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = payload;
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "order/updateOrderInvoice",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    // stop screen to do anything

    dispatch(updateLoading(true));

    try {
      const response = await updateOrderInvoice(payload);

      if (payload?.isBilled) {
        message.success("Bill has been created ...");
      }
      if (!payload?.isOrderDeleted) {
        dispatch(resetPos());
      }

      dispatch(updateLoading(false));
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getRunningOrderInvoices = createAsyncThunk(
  "order/getInvoice",
  async (payload, action) => {
    try {
      const response = await getInvoice(payload);
      return response.data?.length > 0 ? response?.data?.[0] : {};
    } catch (err) {
      return err;
    }
  }
);

export const getInvoices = createAsyncThunk(
  "order/getAllInvoice",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getInvoice(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getInvoiceById = createAsyncThunk(
  "order/getInvoiceById",
  async (payload, action) => {
    try {
      const response = await getInvoiceByID(payload);
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export const deleteAllOrders = createAsyncThunk(
  "order/deleteAllOrders",

  async (payload, action) => {
    payload.orders?.forEach(async (item) => {
      const response = await deleteOrder(item.id);
    });

    action.dispatch(
      deleteInvoice({
        id: payload?.invoiceId,
        hotelId: payload?.hotelId,
      })
    );

    return [];
  }
);

export const deleteOrders = createAsyncThunk(
  "order/deleteOrders",
  async (payload, action) => {
    if (Boolean(payload.isInvoiceDelete)) {
      const response = await deleteOrder(payload?.orderId);

      if (response) {
        action.dispatch(
          deleteInvoice({
            id: payload?.runningInvoice?.id,
            hotelId: payload?.hotelId,
          })
        );
      }

      return [];
    } else {
      // if delete the hold item

      if (payload?.state === "held") {
        const response = await deleteOrder(payload?.orderId);
        return response;
      } else {
        let priceBreakUp = payload?.runningInvoice?.priceBreakup?.filter(
          (pd) => pd.orderId !== payload?.orderId
        );

        // calculate invoice
        let totalSubstractPrice = 0;

        let subTotalAmount = 0;

        payload?.runningInvoice?.priceBreakup?.forEach((item) => {
          subTotalAmount += +item.rate * +item.count;

          item?.addOns?.forEach((addOn) => {
            subTotalAmount += +addOn.price * +addOn.count;
          });
        });

        payload?.runningInvoice?.priceBreakup?.forEach((item) => {
          if (item.orderId === payload?.orderId) {
            totalSubstractPrice += +item.rate * +item.count;

            item?.addOns?.forEach((addOn) => {
              totalSubstractPrice += +addOn.price * +addOn.count;
            });
          }
        });

        let SubTotalAmount = subTotalAmount - totalSubstractPrice;

        let invoicePayload = {
          ...payload.runningInvoice,
          priceBreakup: priceBreakUp,
          subTotal: SubTotalAmount,
          total: SubTotalAmount,
          orders: payload?.runningInvoice.orders?.filter(
            (item) => item !== payload.orderId
          ),
          isOrderDeleted: true, // added for update invoice after order deletion [not saved in database]
        };

        const response = await deleteOrder(payload?.orderId);
        if (response) {
          action.dispatch(updateInvoice(invoicePayload));
          return payload?.runningOrders?.filter(
            (item) => item.id !== payload?.orderId
          );
        }
      }
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "order/getInvoice",
  async (payload, action) => {
    try {
      const response = await invoiceDeletion(payload);
      if (response) {
        return {};
      }
    } catch (err) {
      return err;
    }
  }
);

export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async (payload) => {
    try {
      const response = updateOrderService(payload);
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export const getOrders = createAsyncThunk(
  "order/getOrders",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getOrderService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getBookingDetailsByItsIdAndRedirect = createAsyncThunk(
  "getBookingDetailsByItsIdAndRedirect",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await bookingDetailsById(payload, "bookings");
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) localStorage.removeItem("token");

      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  // all order
  allOrderloadingApi: false,
  allOrdersData: [],
  allOrderApiError: null,
  // all on individual table
  loading: false,
  data: [],
  error: null,

  // latest order on a perticular table

  latestOrder: {}, // after creating order, store latest order data for print kot

  // all invoice

  invoiceLoading: true,
  invoiceData: [],
  invoiceError: null,

  // create invoice

  invoicePostLoading: false,
  invoicePostData: {},
  invoicePostError: null,

  tableOrderApiloading: false,
  tableOrderData: [],
  tableOrderError: null,

  // take payment

  paymentApiLoading: false,
  paymentData: [],
  paymentApiError: null,

  // infinate scrolling

  infinateScroll: false,
  infinateScrolling: true,
  pageNumber: 0,
  pageSize: 20,
  isStopScrolling: false,

  bookingDetails: {},
  bookingDetailsLoading: false,
  bookingDetailsApiError: false,

  showPaymentModeDropdown: false,

  isCompanyInputFocused: false,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_31: () => initialState,

    updateShowPaymentModeDropdown: (state, action) => {
      state.showPaymentModeDropdown = action.payload;
    },

    updateInvoiceById(state, action) {
      let updatedInvoice = state.invoiceData.map((item) => {
        if (item.id === action.payload.id) {
          return { ...action.payload };
        }
        return item;
      });

      state.invoiceData = updatedInvoice;
    },

    updatePageNumber: (state, action) => {
      state.pageNumber = action.payload;
      state.isStopScrolling = false;
    },

    resetInvoicePostDataAndOrderData(state, action) {
      state.invoicePostData = {};
      state.data = [];
    },

    updateInFinateScrolling: (state, action) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber += action.payload.pageNumber;
      state.infinateScrolling = true;
    },

    updatePageNo: (state, action) => {
      state.pageNumber = +state.pageNumber + 1;
    },
    updateInFinateScroll: (state, action) => {
      state.infinateScroll = action.payload;
    },

    updateIsCompanyInputFocused: (state, action) => {
      state.isCompanyInputFocused = action.payload;
    },

    resetState: (state, action) => {
      state.pageNumber = 0;
      state.loading = false;
      state.bookingDetails = {};
      state.allOrdersData = [];
      state.invoiceData = [];
      state.paymentData = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createNewOrder.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createNewOrder.fulfilled, (state, action) => {
      message.success("Order Placed/Parked...");
      state.loading = false;
      state.data = [...state.data, action.payload.orderData];
      state.error = null;
    });
    builder.addCase(createNewOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.data = [];
    });

    builder.addCase(getLatestOrder.pending, (state, action) => {
      state.latestOrder = {};
    });

    builder.addCase(getLatestOrder.fulfilled, (state, action) => {
      state.latestOrder = action.payload;
    });

    builder.addCase(getLatestOrder.rejected, (state, action) => {
      state.latestOrder = {};
    });

    builder.addCase(updateOrderAndInvoice.fulfilled, (state, action) => {
      message.success("Order has been updated...");

      let orders = state.data.map((item) => {
        if (item.id === action.payload?.id) {
          return action.payload;
        }
        return item;
      });

      state.data = orders;
    });

    builder.addCase(getTableOrders.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      state.error = false;
    });

    builder.addCase(getTableOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    });
    builder.addCase(getTableOrders.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });

    builder.addCase(getRunningOrderInvoices.pending, (state, action) => {
      state.invoicePostLoading = true;
      state.invoicePostError = null;
    });
    builder.addCase(getRunningOrderInvoices.fulfilled, (state, action) => {
      state.invoicePostLoading = false;
      state.invoicePostError = null;
      state.invoicePostData = action.payload;
    });
    builder.addCase(getRunningOrderInvoices.rejected, (state, action) => {
      state.invoicePostLoading = false;
      state.invoicePostError = action.error.message;
    });

    builder.addCase(getInvoiceById.pending, (state, action) => {
      state.invoicePostLoading = true;
      state.invoicePostError = false;
    });
    builder.addCase(getInvoiceById.fulfilled, (state, action) => {
      state.invoicePostLoading = false;
      state.invoicePostError = false;
      state.invoicePostData = action.payload;
    });
    builder.addCase(getInvoiceById.rejected, (state, action) => {
      state.invoicePostLoading = false;
      state.invoicePostError = true;
    });

    builder.addCase(updateAllOrdersByStatus.fulfilled, (state, action) => {
      let updatedOrder = state.data?.map((item) => {
        if (item.id === action.payload) {
          return action.payload;
        }
        return item;
      });
      state.data = updatedOrder;
    });

    builder.addCase(updateAllOrdersByTableId.fulfilled, (state, action) => {
      let updatedOrder = state.data?.map((item) => {
        if (item.id === action.payload) {
          return action.payload;
        }
        return item;
      });
      state.data = updatedOrder;
    });

    builder.addCase(createNewInvoice.pending, (state, action) => {
      state.invoicePostLoading = true;
      state.invoicePostData = {};
      state.invoicePostError = false;
    });

    builder.addCase(createNewInvoice.fulfilled, (state, action) => {
      state.invoicePostLoading = false;
      state.invoicePostData = action.payload;
      state.invoicePostError = false;
    });

    builder.addCase(createNewInvoice.rejected, (state, action) => {
      state.invoicePostLoading = false;
      state.invoicePostData = {};
      state.invoicePostError = action.error.message;
    });

    builder.addCase(getInvoices.pending, (state, action) => {
      if (!state.pageNumber) {
        state.invoiceData = [];
        state.invoiceLoading = true;
        state.infinateScrolling = false;
      }
      state.invoiceError = null;
    });

    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.invoiceLoading = false;

      if (action.payload?.length > 0) {
        // let invoiceList = [];

        // action.payload?.forEach((item) => {
        //   let IDS = state.invoiceData?.map((item) => item.id);
        //   let uniqueID = IDS.indexOf(item.id);

        //   if (uniqueID === -1) invoiceList.push(item);
        // });

        if (action.payload?.length >= state.pageSize)
          state.infinateScroll = true;
        else state.infinateScroll = false;

        state.invoiceData = [...state.invoiceData, ...action.payload];
      } else {
        state.infinateScroll = false;
        state.isStopScrolling = true;
        state.infinateScrolling = false;
      }

      state.invoiceError = null;
    });

    builder.addCase(getInvoices.rejected, (state, action) => {
      state.invoiceLoading = false;
      state.infinateScrolling = false;
      state.isStopScrolling = true;
      state.invoiceError = true;
    });

    builder.addCase(updateInvoice.pending, (state, action) => {
      state.invoicePostLoading = true;
      state.invoicePostData = {};
      state.invoicePostError = false;
    });

    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      state.invoicePostLoading = false;
      state.invoicePostError = false;
      state.invoicePostData = action.payload;

      let invoice = state.invoiceData?.map((item) => {
        if (item.id === action.payload?.id) {
          return action.payload;
        }
        return item;
      });

      state.invoiceData = invoice;

      if (Boolean(action.payload.navigate)) {
        action.payload.navigate(action.payload.navigateTo);
      }
    });

    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.invoicePostLoading = false;
      state.invoicePostError = action.error.message;
    });

    builder.addCase(getOrders.pending, (state, action) => {
      state.allOrderloadingApi = true;
      state.allOrderApiError = null;
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.allOrderloadingApi = false;
      state.allOrdersData = action.payload;
      state.allOrderApiError = null;
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      state.allOrderloadingApi = false;
      state.error = action.error.message;
      state.allOrderApiError = [];
    });

    builder.addCase(deleteOrders.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      message.success("Order deleted successfully");
    });
    builder.addCase(deleteOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteAllOrders.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteAllOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.data = [];
      state.invoicePostData = {};
      state.error = null;
      message.success("Order deleted successfully");
    });
    builder.addCase(deleteAllOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(takePayment.pending, (state, action) => {
      state.paymentApiLoading = true;
      state.paymentApiError = null;
      state.paymentData = [];
    });
    builder.addCase(takePayment.fulfilled, (state, action) => {
      state.paymentApiLoading = false;
      state.paymentApiError = null;
      state.paymentData = action.payload;
    });
    builder.addCase(takePayment.rejected, (state, action) => {
      state.paymentApiLoading = false;
      state.paymentApiError = true;
      state.paymentData = [];
    });
    builder.addCase(multipleTakePayment.pending, (state, action) => {
      state.paymentApiLoading = true;
      state.paymentApiError = null;
      state.paymentData = [];
    });
    builder.addCase(multipleTakePayment.fulfilled, (state, action) => {
      state.paymentApiLoading = false;
      state.paymentApiError = null;
      state.paymentData = [...state.paymentData, action.payload];
    });
    builder.addCase(multipleTakePayment.rejected, (state, action) => {
      state.paymentApiLoading = false;
      state.paymentApiError = true;
      state.paymentData = [];
    });

    builder.addCase(
      getBookingDetailsByItsIdAndRedirect.pending,
      (state, action) => {
        state.bookingDetailsLoading = true;
        state.bookingDetails = {};
        state.bookingDetailsApiError = null;
      }
    );
    builder.addCase(
      getBookingDetailsByItsIdAndRedirect.fulfilled,
      (state, action) => {
        state.bookingDetailsLoading = false;
        state.bookingDetails = action?.payload?.bookingDetails;
        state.bookingDetailsApiError = null;

        //  const status = action?.payload?.status;
        //  let statusEnum = "";
        //  if (status === "checkedIn") statusEnum = "ongoing";
        //  else statusEnum = "completed";

        //  dispatch(updateBookingActiveTab(statusEnum));
        //  message.success("Redirecting to " + roomNumber);
        //  navigate("/dashboard/bookings");
        //  e.stopPropagation();
      }
    );
    builder.addCase(
      getBookingDetailsByItsIdAndRedirect.rejected,
      (state, action) => {
        state.bookingDetailsLoading = false;
        state.bookingDetailsApiError = action.error.message;
        state.bookingDetails = {};
      }
    );
  },
});

export const {
  CLEAR_REDUX_STORE_31,
  updateInvoiceById,
  resetInvoicePostDataAndOrderData,
  updateInFinateScrolling,
  updatePageNumber,
  updatePageNo,
  updateInFinateScroll,
  updateShowPaymentModeDropdown,
  resetState,
  updateIsCompanyInputFocused,
} = orderSlice.actions;

export default orderSlice.reducer;
