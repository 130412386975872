import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllCustomer } from "../services/customer";

export const getAllCustomers = createAsyncThunk(
  "customers/getAllCustomers",

  async (payload, action) => {
    try {
      const res = await getAllCustomer(payload, "salesReport");

      return res;
    } catch (error) {
      return null;
    }
  }
);

const initialState = {
  loading: false,
  response: {},
  apiError: null,
};

const grcSlice = createSlice({
  name: "grc",
  initialState,

  reducers: { CLEAR_REDUX_STORE_24: () => initialState },

  extraReducers: (builder) => {
    builder.addCase(getAllCustomers.pending, (state, action) => {
      state.loading = true;
      state.apiError = false;
      state.response = {};
    });
    builder.addCase(getAllCustomers.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.apiError = false;
    });
    builder.addCase(getAllCustomers.rejected, (state, action) => {
      state.loading = false;
      state.apiError = true;
      state.response = {};
    });
  },
});

export const { CLEAR_REDUX_STORE_24 } = grcSlice.actions;

export default grcSlice.reducer;
