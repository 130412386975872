import React from "react";
import { Rings } from "react-loader-spinner";

const index = () => {
  return (
    <>
      <div className="w-full h-screen bg-[#F2F2F2] flex justify-center items-center">
        <div className="loader-div ">
          <Rings
            visible={true}
            height="80"
            width="80"
            color="#007DFE"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </div>
    </>
  );
};

export default index;
