import { TranslateText } from "../../utils/translation";
// export const HEADER_ITEMS = [
//   "Item Name",
//   "Category",
//   "Unit",
//   "Price",
//   "Quantity",
//   "Total Value",
// ];


export const HEADER_ITEMS = [
  <TranslateText textKey={"item_name_c"}namespace="manageStocks"/>,
  <TranslateText textKey={"category"}namespace="manageStocks"/>,
  <TranslateText textKey={"unit"}namespace="manageStocks"/>,
  <TranslateText textKey={"price"}namespace="manageStocks"/>,
  <TranslateText textKey={"quantity"}namespace="manageStocks"/>,
  <TranslateText textKey={"total_value"}namespace="manageStocks"/>
];

