import { authedAxios } from "../utils/customHttpHeader";

const getStaffService = async (payload, url) => {
  let endpoint = url + "?hotelId=" + payload?.hotelId;
  const response = await authedAxios().get(endpoint);
  return response;
};

const postStaffService = async (payload, url) => {
  let endpoint = url;
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

export { getStaffService, postStaffService };
