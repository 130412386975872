import React from "react";
import { useSelector } from "react-redux";
import { BiPrinter } from "react-icons/bi";
import { MdOutlineFileDownload } from "react-icons/md";
import { TranslateText } from "../../../utils/translation";
const Footer = ({ handlePrint, setShowPrint }) => {
  const { menuDrawer } = useSelector((store) => store.app);
  const { isMobileAndTabletScreen } = useSelector((store) => store.appHeader);

  return (
    <footer
      className={` fixed   ${
        isMobileAndTabletScreen
          ? "left-[1px] "
          : menuDrawer
          ? "left-[5rem] "
          : "left-[13rem] "
      } flex justify-between items-center px-[24px] py-[16px]  bottom-0  z-[30] right-0  border-[0.5px] border-[#D9D9D9]   shadow-mobile-footer  bg-[#FFF] text-[10px] `}
    >
      <div className="flex items-center gap-[18px]">
        <div className="flex items-center gap-[6px]">
          <div className="rounded-[50%]  h-[12px] w-[12px] bg-[#9747FF] "></div>
          <div className=" font-outfit font-[500] text-[12px] leading-[15.12px] tracking-[wider] text-[#8F9BB3] uppercase">
            <TranslateText textKey={"upcoming"} namespace="home" />{" "}
          </div>
        </div>

        <div className="flex items-center gap-[6px]">
          <div className="rounded-[50%]  h-[12px] w-[12px] bg-[#F36960] "></div>
          <div className=" font-outfit font-[500] text-[12px] leading-[15.12px] tracking-[wider] text-[#8F9BB3] uppercase">
            <TranslateText textKey={"ongoing"} namespace="home" />{" "}
          </div>
        </div>

        <div className="flex items-center gap-[6px]">
          <div className="rounded-[50%]  h-[12px] w-[12px] bg-[#717171] "></div>
          <div className=" font-outfit font-[500] text-[12px] leading-[15.12px] tracking-[wider] text-[#8F9BB3] uppercase">
            <TranslateText textKey={"completed"} namespace="home" />{" "}
          </div>
        </div>

        <div className="flex items-center gap-[6px]">
          <div className="rounded-[50%] h-[12px] w-[12px] bg-[#FEF6E7] "></div>
          <div className="font-outfit font-[500] text-[12px] leading-[15.12px] tracking-[wider] text-[#8F9BB3] uppercase">
            <TranslateText textKey={"enquiry"} namespace="home" />
          </div>
        </div>
      </div>

      <div className="flex gap-[12px] items-center">
        {/* <div
          className="flex items-center gap-[6px] cursor-pointer"
          onMouseEnter={() => setShowPrint(true)}
          onClick={handlePrint}
          onMouseLeave={() => setShowPrint(false)}
        >
          <BiPrinter className="text-[18px] text-[#007FFF]" />
          <span className="text-[#007FFF] font-outfit font-[500] text-[13px] leading-[25px] ">
            Print
          </span>
        </div>

        <div className="h-[18px] w-[0.5px] bg-[#EBEBEB]"></div>

        <div className="flex items-center gap-[6px]">
          <MdOutlineFileDownload className="text-[18px] text-[#007FFF]" />
          <span className="text-[#007FFF] font-outfit font-[500] text-[13px] leading-[25px] ">
            Download
          </span>
        </div> */}
      </div>
    </footer>
  );
};
export default Footer;
