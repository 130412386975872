import React from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as RemoveImg } from "../../../assets/icons/removeImg.svg";
import { FiUpload } from "react-icons/fi";
import { Upload } from "antd";
import { updateDisplayGuestIdModal } from "../../../reducer/bookingReducer";
import { TranslateText } from "../../../utils/translation";
const GuestIdSection = ({
  roomId,
  guestIdx,
  imageArray = [],
  handlePhotoSectionImageUpload,
  removeImage,
  setSelectedRoomId,
  setSelectedGuestIdx,
  handleImageBeforeUpload,
}) => {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setSelectedGuestIdx(guestIdx);
    setSelectedRoomId(roomId);
    dispatch(updateDisplayGuestIdModal());
  };

  return (
    <div>
      {" "}
      <div className="photosSection flex flex-col gap-[24px]">
        <div className="w-[100%] flex justify-between items-center gap-[24px] mb-[10px]">
          {imageArray?.slice(0, 2)?.map((image, index) => (
            <div
              key={index}
              className="flex flex-col gap-[24px] items-start justify-start w-[147px] h-[101.22px] relative rounded-[8px] cursor-pointer image-shadow"
              onClick={handleOpenModal}
            >
              <img
                src={image}
                alt="rect"
                id="viewer"
                className="w-[100%] h-[100%] rounded-[8px]"
              />

              <div
                className="rounded-[50%] flex justify-center items-center absolute top-[-6px] right-[3px] z-[10] cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  removeImage(image, roomId, guestIdx);
                }}
              >
                <RemoveImg className="text-[12px] text-[#F36960] " />
              </div>
            </div>
          ))}

          <div
            className={`${
              Array.isArray(imageArray) && imageArray?.length
                ? "w-[65px] h-[50px] "
                : "w-[137px] h-[56px] "
            } rounded-[12px] border-[1px] bg-[#F9FBFD] border-[#EDF1F7] flex flex-col justify-center items-center cursor-pointer`}
          >
            <Upload
              multiple={true}
              customRequest={(item) =>
                handlePhotoSectionImageUpload(item, roomId, guestIdx)
              }
              beforeUpload={(file) => handleImageBeforeUpload(file, 10)}
              showUploadList={false}
              accept=".png,.jpeg,"
              className="w-[100%]"
            >
              <div
                className={`${
                  Array.isArray(imageArray) && imageArray?.length
                    ? "w-[65px] h-[50px] "
                    : "w-[137px] h-[56px] "
                } rounded-[12px] bg-[#E9F4FF] flex items-center justify-center`}
              >
                <div className="flex items-center gap-[6px]">
                  <FiUpload color="#007FFF" />
                  <span className="font-outfit font-[500] text-[14px] leading-[17.64px] text-[#007FFF]">
                    {Array.isArray(imageArray) && imageArray?.length ? (
                      <TranslateText textKey={"id"} namespace="booking" />
                    ) : (
                      <TranslateText
                        textKey={"upload_id"}
                        namespace="booking"
                      />
                    )}
                  </span>
                </div>
              </div>
            </Upload>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestIdSection;
