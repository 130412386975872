import React from "react";
import { Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import {
  completeNightAuditDate,
  getCurrentNightAuditDate,
  updateNightAuditModalDisplay,
} from "../../reducer/appHeaderReducer";
import { TranslateText } from "../../utils/translation";

const NightAuditConfirmationModal = () => {
  const { nightAuditModalDisplay, nightAuditDateLoading } = useSelector(
    (store) => store.appHeader
  );

  const { hotelDetails } = useSelector((store) => store.login);

  const dispatch = useDispatch();

  const handleOhk = () => {
    dispatch(completeNightAuditDate({ hotelId: hotelDetails?.id }));
    dispatch(getCurrentNightAuditDate({ hotelId: hotelDetails?.id }));
    dispatch(updateNightAuditModalDisplay(false));
  };

  return (
    <Modal
      open={nightAuditModalDisplay}
      centered
      title=""
      mask={true}
      styles={{ mask: { backdropFilter: "blur(2px)" } }}
      width={"450px"}
      onCancel={() => dispatch(updateNightAuditModalDisplay(false))}
      footer={
        <div className="w-full flex gap-2 justify-center mt-5">
          <Button
            className="text-[1.1rem]  w-[11rem] h-[3rem] rounded-[0.5rem] font-[Poppins] font-[600] text-[#808080] border-[#D0D5DD]"
            onClick={() => dispatch(updateNightAuditModalDisplay(false))}
          >
            
            <TranslateText
            textKey={"no"}
            namespace="common"
            />
          </Button>

          {nightAuditDateLoading ? (
            <Button className="text-[#FFF] text-[1.1rem] rounded-[0.5rem]  bg-[#007FFF] w-[11rem] h-[3rem]">
              <CircularProgress size={24} color="inherit" />
            </Button>
          ) : (
            <Button
              className="text-[#FFF] text-[1.1rem] rounded-[0.5rem]  bg-[#007FFF] w-[11rem] h-[3rem]"
              onClick={handleOhk}
            >
              
              <TranslateText
            textKey={"yes"}
            namespace="common"
            />
            </Button>
          )}
        </div>
      }
    >
      <div className="font-nunito text-[18px] leading-[28px] font-[600] mb-2 text-[#4D4D4D]">
        
        <TranslateText
            textKey={"complete_audit"}
            namespace="common"
            />
      </div>
      <div className="text-[#808080] font-nunito text-[0.875remrem] font-[400] leading-[20px]">
      
        <TranslateText
            textKey={"by_clicking_yes_this_billing_cycle_will_be_closed_and_new_billing_cycle_will_be_started"}
            namespace="common"
            />
      </div>
    </Modal>
  );
};

export default NightAuditConfirmationModal;
