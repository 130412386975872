import React, { useState, useEffect, useRef } from "react";
import { Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  updateDisplayRoomDrawer,
  updateMealPlan,
  updateRoomPriceStructure,
  updatetaxIncludedInPriceLocalState,
  updateDetailedPriceBreakup,
} from "../../../reducer/bookingReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CustomCalendar from "../components/EditRoom/CustomCalendar/CustomCalendar";
import EditRoomDetails from "../components/EditRoom/EditRoomDetails";
import { Switch } from "antd";
import GuestDropDown from "../components/EditRoom/GuestDropDown";
import {
  mealPlanChangeCalculation,
  roomAssigneFunction,
} from "../../../utils/bookingHelper";
import useBookingCalculation from "../../../hooks/bookingCustomHook/useBookingCalculation";
import {
  bookingDetailsDrawerWidth,
  customCloseIconContainer,
} from "../../../utils/bookingDetailsHelper";
import useEditBookingLogic from "../../../hooks/bookingCustomHook/useEditRoomLogic";
import useGetMealPlanRate from "../../../hooks/bookingCustomHook/useGetPlanRate";

const RoomDrawer = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    hotelDetails,
    occupancyPlanRate,
    occupancyPlanRateLoading,
    occupancyPlanRateError,
    isAllHotelsSelected,
  } = useSelector((store) => store.login);

  const roomTypes = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];
  const isInitialRender = useRef(true);

  const [localRoomTotal, setLocalRoomTotal] = useState(0);
  const [localSubTotal, setLocaLSubTotal] = useState(0);
  const [localTotal, setLocalTotal] = useState(0);
  const [localTax, setLocalTax] = useState(0);
  const [localDiscount, setLocalDiscount] = useState();
  const [localDiscountInfo, setLocalDiscountInfo] = useState({});
  const [totalTax, setTotalTax] = useState(0);

  const [addOnsTotal, setAddOnsTotal] = useState(0);

  const [roomCountMap, setRoomCountMap] = useState({});

  const [adults, setAdults] = useState(null);
  const [childrens, setChildrens] = useState(0);

  const [originalRoomPriceStructureState, setOriginalRoomPriceStructureState] =
    useState([]);

  const {
    displayRoomDrawer,
    showMainBookingDetailsDrawer,
    bookingDetails,
    mealPlan,
    roomPriceStructure,
    selectedRoomWTypeAndNum,
    taxIncludedInPriceLocalState,
  } = useSelector((store) => store.booking);

  const { getPlanRate } = useGetMealPlanRate();

  const handleClose = () => {
    dispatch(updateDisplayRoomDrawer());
    dispatch(updateRoomPriceStructure(originalRoomPriceStructureState));
    dispatch(updateMealPlan(bookingDetails?.mealPlan));
    getPlanRate(
      roomTypes,
      originalRoomPriceStructureState,
      bookingDetails?.mealPlan
    );
    isInitialRender.current = true;
  };

  useEffect(() => {
    let localAdults = Boolean(bookingDetails?.adults)
      ? bookingDetails?.adults
      : null;

    setAdults(localAdults);
  }, [bookingDetails]);

  useEffect(() => {
    if (!displayRoomDrawer) return;

    const currentRoomPriceStructure = mealPlanChangeCalculation(
      roomPriceStructure,
      roomTypes,
      mealPlan,
      occupancyPlanRate
    );

    dispatch(updateRoomPriceStructure(currentRoomPriceStructure));
  }, [mealPlan]);

  useEffect(() => {
    roomAssigneFunction(
      adults,
      childrens,
      mealPlan,
      selectedRoomWTypeAndNum,
      roomTypes,
      roomPriceStructure,
      occupancyPlanRate,
      dispatch,
      setAdults,
      updateDetailedPriceBreakup,
      "bookingDetails"
    );
  }, [roomPriceStructure, selectedRoomWTypeAndNum, occupancyPlanRate, adults]);

  const { handleEditRoom } = useEditBookingLogic(
    setLoading,
    roomCountMap,
    childrens,
    isInitialRender,
    localRoomTotal,
    localDiscount,
    localDiscountInfo,
    localSubTotal,
    localTotal,
    totalTax
  );

  useBookingCalculation(
    setLocalRoomTotal,
    setLocaLSubTotal,
    setLocalTax,
    setLocalTotal,
    setLocalDiscount,
    setLocalDiscountInfo,
    setRoomCountMap,
    setAddOnsTotal,
    setTotalTax
  );

  return (
    <Drawer
      title={
        <div className="flex flex-col px-[-2]">
          <div>
            <span className="text-[#808080] font-nunito  text-[1rem] leading-[1.5rem] font-[500]">
              Booking Id:
            </span>
            {showMainBookingDetailsDrawer?.newBookingId ? (
              <span className="text-[#4D4D4D] font-[600]  font-nunito text-[1rem]">
                {" "}
                {showMainBookingDetailsDrawer?.newBookingId}
              </span>
            ) : (
              <span className="text-[#808080] font-[600]  font-[Poppins] ">
                {" "}
                Id not generated
              </span>
            )}
          </div>

          <div>
            <span className="text-[#808080] font-nunito  text-[1rem] leading-[1.5rem] font-[500]">
              Source:
            </span>{" "}
            <span className="text-[#4D4D4D] font-[600] font-nunito text-[1rem] leading-[1.5rem] capitalize">
              {bookingDetails?.source}
            </span>
          </div>

          <div className="flex w-[100%]  justify-around items-center gap-3 mt-[10px]">
            <CustomCalendar />

            <GuestDropDown
              adults={adults}
              setAdults={setAdults}
              childrens={childrens}
              setChildrens={setChildrens}
            />

            <div className="w-[5.2rem] h-[2.47rem] ">
              <FormControl
                sx={{
                  width: "100%",
                  color: "#0000004D",
                  "& .MuiSelect-icon": {
                    color: "#2F80ED",
                  },
                }}
                size="small"
              >
                <InputLabel
                  id="sourceInputLabel"
                  sx={{
                    color: "#808080",
                    fontSize: "0.75rem",
                    fontWeight: "300",
                    fontFamily: "nunito",
                  }}
                >
                  Meal Plan
                </InputLabel>
                <Select
                  labelId="bookingSourceSelectLabel"
                  id="bookingSource"
                  label="Meal Plan"
                  sx={{
                    width: "100%",
                    borderRadius: "6px",
                    color: "#808080",
                    fontSize: "0.75rem",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                  }}
                  onChange={(e) => dispatch(updateMealPlan(e.target.value))}
                  value={mealPlan}
                >
                  {["EP", "CP", "MAP", "AP"]?.map((Obj) => {
                    return (
                      <MenuItem
                        key={Obj}
                        value={Obj}
                        sx={{
                          fontSize: "14px",
                          color: "#00000098",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                        }}
                      >
                        {Obj}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      }
      closeIcon={<CloseOutlined style={customCloseIconContainer} />}
      closable={true}
      onClose={() => handleClose()}
      open={displayRoomDrawer}
      width={bookingDetailsDrawerWidth}
    >
      <EditRoomDetails
        isInitialRender={isInitialRender}
        setAdults={setAdults}
        setChildrens={setChildrens}
        finalLoading={loading}
        adults={adults}
        setOriginalRoomPriceStructureState={setOriginalRoomPriceStructureState}
      />

      {loading ? (
        <button
          className="text-[#FFF] text-[1.125rem] py-4  bg-[#007FFF] uppercase absolute  right-0 bottom-0 flex justify-center  w-[100%] font-[600] px-auto  z-10 font-[Poppins] "
          disabled={true}
        >
          <CircularProgress size={24} color="inherit" />
        </button>
      ) : (
        <div
          className=" absolute  right-[-1px] bottom-[-1px] flex flex-col gap-2 w-[100%] items-center text-[1.125rem] font-[600]
         bg-[#FFF] border border-t-[#00000015] rounded-t-lg border-l-0"
        >
          <div className="flex justify-between  w-[100%] px-[1rem]">
            <div className="flex gap-2   items-center  flex-wrap w-[100%]">
              <Switch
                className="bg-[#00000040]"
                checked={Boolean(taxIncludedInPriceLocalState)}
                onChange={(e) =>
                  dispatch(
                    updatetaxIncludedInPriceLocalState(
                      Boolean(taxIncludedInPriceLocalState) ? false : true
                    )
                  )
                }
              />

              <label className="font-medium  leading-[21px] text-[10px] md:text-[12px] lg-[text-[14px]">
                {" "}
                Price Incl. Taxes
              </label>
            </div>
            {occupancyPlanRateError ? (
              <div className="font-outfit font-[500] text-[red] text-[12px] flex  justify-center items-center ">
                There seems to be an issue with the server. &nbsp; Please try
                refreshing the page. If the problem continues, please contact
                Revivo
              </div>
            ) : (
              <div className="w-[100%]  flex justify-end gap-6  pt-2">
                <div className="flex flex-col items-start">
                  <div className="flex flex-col items-start cursor-pointer">
                    <div className="flex gap-1 items-center">
                      <div className="text-[#808080] font-[Poppins] text-[0.625rem] font-[300] ">
                        Room Total :{" "}
                      </div>
                      {/* <BsInfoCircleFill className="text-[#808080] text-[12px]" /> */}
                    </div>

                    <div className="font-medium font-outfit text-[14px] leading-[21px]">
                      {Boolean(occupancyPlanRateLoading) ? (
                        <CircularProgress size={16} />
                      ) : Boolean(localRoomTotal) ? (
                        localRoomTotal?.toFixed(2)
                      ) : (
                        "00"
                      )}{" "}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-start">
                  <div className="text-[#808080] font-[Poppins] text-[0.625rem] font-[300] ">
                    Discount:
                  </div>
                  <div className="text-[#4D4D4D] text-[0.875rem] font-[500] font-[Poppins]">
                    {Boolean(localDiscount) ? localDiscount?.toFixed(2) : "00"}
                  </div>
                </div>

                <div className="flex flex-col items-start">
                  <div className="text-[#808080] font-[Poppins] text-[0.625rem] font-[300] ">
                    Add-ons
                  </div>
                  <div className="text-[#4D4D4D] text-[0.875rem] font-[500] font-[Poppins]">
                    {Boolean(addOnsTotal) ? addOnsTotal?.toFixed(2) : "00"}{" "}
                  </div>
                </div>

                <div className="flex flex-col items-start ">
                  <div className="text-[#808080] font-[Poppins] text-[0.625rem] font-[300] ">
                    Tax:
                  </div>
                  <div className="text-[#4D4D4D] text-[0.875rem] font-[500] font-[Poppins]">
                    {Boolean(localTax) ? localTax?.toFixed(2) : "00"}{" "}
                  </div>
                </div>

                <div className="flex flex-col items-start ">
                  <div className="text-[#808080] font-[Poppins] text-[0.625rem] font-[300] ">
                    Total:
                  </div>
                  <div className="text-[#4D4D4D] text-[0.875rem] font-[500] font-[Poppins]">
                    {Boolean(localTotal) ? localTotal?.toFixed(2) : "00"}{" "}
                  </div>
                </div>
              </div>
            )}{" "}
          </div>

          {!isAllHotelsSelected && (
            <div className=" w-[100%] ">
              {!occupancyPlanRateError && (
                <button
                  className="text-[#FFF] text-[1.125rem] py-4  bg-[#007FFF] uppercaseflex justify-center  w-[100%] font-[600] px-auto  
              z-10 font-[Poppins] "
                  onClick={handleEditRoom}
                >
                  Upgrade / edit rooms
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
};
export default RoomDrawer;
