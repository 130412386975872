import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearSelectedSteward,
  updateSelectedRider,
  updateSelectedSteward,
} from "../../../../../reducer/posReducer/restaurant";

import Restaurant from "./PosHeader/Restaurant";
import { POS_TYPE } from "../../../../../utils/posHelper";

function HeaderRevamped({ headerName }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { hotelDetails } = useSelector((store) => store.login);
  const [isSteward, setIsSteward] = useState(false);
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);
  const { selectedSteward, selectedRider } = useSelector((store) => store.pos);

  const { data, allOrdersData } = useSelector((store) => store.order);

  const { response, activePosSlug, activePosType } = useSelector(
    (store) => store.posSetting
  );

  let stewardsList = response?.filter(
    (item) => item.slug === activePosSlug
  )?.[0]?.stewards;

  let stewards = Array.isArray(stewardsList) ? stewardsList : [];

  useEffect(() => {
    dispatch(clearSelectedSteward());

    if (data?.length > 0) {
      assignStewardFromRunningOrders();
    } else {
      assignStewardFromAllOrders();
    }

    //  dispatch(updateSelectedSteward(Object.keys(sortableSteward)?.[0]));
  }, [data, allOrdersData]);
  //  least assigned steward (from all order) will be automatically assign

  const assignStewardFromAllOrders = () => {
    // auto steward assign
    let assignStwerd = {};

    stewards?.forEach((element) => {
      assignStwerd[element] = Boolean(assignStwerd[element])
        ? assignStwerd[element] + 1
        : 0;
    });

    allOrdersData?.forEach((item) => {
      let assignedSteward = item?.stewardNames;
      assignedSteward?.forEach((item) => {
        assignStwerd[item] = assignStwerd[item] + 1;
      });
    });

    const sortableSteward = Object.fromEntries(
      Object.entries(assignStwerd).sort(([, a], [, b]) => a - b)
    );

    dispatch(updateSelectedSteward(Object.keys(sortableSteward)?.[0]));
  };

  //  least assigned steward (from running order) will be automatically assign
  const assignStewardFromRunningOrders = () => {
    // auto steward assign
    let assignStwerd = {};
    data?.forEach((item) => {
      let assignedSteward = item?.stewardNames;
      assignedSteward?.forEach((item) => {
        assignStwerd[item] = Boolean(assignStwerd[item])
          ? assignStwerd[item] + 1
          : 1;
      });
    });

    const sortableSteward = Object.fromEntries(
      Object.entries(assignStwerd).sort(([, a], [, b]) => a - b)
    );
    dispatch(updateSelectedSteward(Object.keys(sortableSteward)?.[0]));
  };

  const dispatch = useDispatch();

  const handleRider = (e) => dispatch(updateSelectedRider(e));

  const styles = {
    fontSize: 12,
  };

  return (
    <div className="w-full   lg:flex justify-between   font-semibold p-6 gap-2 shadow-md h-[7rem] px-8">
      <div className="text-[32px] text-[#545454] col-span-1 capitalize">
        {headerName?.length > 20
          ? headerName?.substring(0, 20) + "..."
          : headerName}
      </div>

      {activePosType === POS_TYPE[0] && (
        <Restaurant
          stewardList={stewards}
          tableOrderDetails={tableOrderDetails}
          selectedSteward={selectedSteward}
          isSteward={isSteward}
          setIsSteward={setIsSteward}
          hotelDetails={hotelDetails}
          handleRider={handleRider}
          styles={styles}
          selectedRider={selectedRider}
        />
      )}
    </div>
  );
}

export default HeaderRevamped;
