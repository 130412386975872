import React from "react";
import PosHeader from "../posContainer/header";

const Header = () => {
  return (
    <>
      <div className="w-full m-4">
        <PosHeader from="posSetting" posHeader="Pos Setting" />
      </div>
    </>
  );
};

export default Header;
