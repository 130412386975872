import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "antd";

import { updateActiveView } from "../../../reducer/manageStocksReducer";

import ChangeSelectedPosDropdown from "../dropdowns/ChangeSelectedPosDropdown";

import { ReactComponent as BackArrow } from "../../../assets/icons/backArrow.svg";
import { TranslateText } from "../../../utils/translation";

const Header = () => {
  const { selectedPos, activeView } = useSelector(
    (store) => store.manageStocks
  );

  const dispatch = useDispatch();

  return (
    <div className="bg-[#FFF] px-5 w-[100%] h-[4rem] shadow-md flex justify-between items-center">
      {activeView === "list" ? (
        <ChangeSelectedPosDropdown />
      ) : (
        <div className="flex items-center justify-start gap-[10px]">
          <BackArrow
            className="cursor-pointer"
            onClick={() => dispatch(updateActiveView("list"))}
          />
          <p className="font-[600] text-[22px] leading-[33px] text-[#545454]">
            {activeView === "recount" ? "Recount" : "Restock"}
            <span className="font-[600] text-[22px] leading-[33px] text-[#545454]">
              {` (${selectedPos?.name})`}
            </span>
          </p>
        </div>
      )}

      {activeView === "list" && (
        <div className="flex justify-start items-center gap-[10px]">
          <Button
            className="h-[31px]"
            onClick={() => dispatch(updateActiveView("recount"))}
          >
            <TranslateText textKey={"recount"} namespace="manageStocks" />
          </Button>
          <Button
            className="h-[31px]"
            onClick={() => dispatch(updateActiveView("restock"))}
          >
            <TranslateText textKey={"restock"} namespace="manageStocks" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Header;
