import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addNewUnitService,
  getUnitsService,
  removeUnitService,
} from "../../services/manageStocksServices/units";

export const getUnits = createAsyncThunk("units/getUnits", async () => {
  try {
    const response = await getUnitsService();
    return response.data;
  } catch (error) {
    return error;
  }
});

export const addNewUnit = createAsyncThunk(
  "units/addNewUnit",
  async (payload) => {
    try {
      const response = await addNewUnitService(payload);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteUnit = createAsyncThunk(
  "units/deleteUnit",
  async (payload) => {
    try {
      const response = await removeUnitService(payload);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: false,
};

const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: { CLEAR_REDUX_STORE_4: () => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(getUnits.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUnits.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.units;
        state.error = false;
      })
      .addCase(getUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    builder
      .addCase(addNewUnit.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addNewUnit.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.units;
        state.error = false;
      })
      .addCase(addNewUnit.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    builder
      .addCase(deleteUnit.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUnit.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.units;
        state.error = false;
      })
      .addCase(deleteUnit.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { CLEAR_REDUX_STORE_4 } = unitsSlice.actions;
export default unitsSlice.reducer;
